import { Form, Input } from "antd";
import { useTranslation } from "react-i18next";

const CheckByEmail = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Form.Item
        name="email"
        label={t("enter_email")}
        rules={[
          {
            required: true,
            message: t("email_message"),
          },
        ]}
      >
        <Input />
      </Form.Item>
    </div>
  );
};

export default CheckByEmail;
