const mediaInitialValues = {
    // Details
    type: "movie",
    description: "",

    // Monetization
    content_monetization_type: "free",

    // Restriction
    pg: 0,
    is_protected: false,

    // Stream
    duration: 0,
    credits_time: 0,
    intro_start_time: 0,
    intro_end_time: 0,
};

export default mediaInitialValues;
