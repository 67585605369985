import { useEffect, useState } from "react";

import { Form, Input, notification, Switch, Tag } from "antd";

import REQUESTS from "../../../../api/requests";

import ImageUpload from "../../../../components/ImageUpload";

import ButtonComponent from "../../../../_components/button/ButtonComponent";
import Upploader from "../../../../_components/uploader/Uploader";
import Check from "../../../../_components/table/Check";
import { useTranslation } from "react-i18next";

export default function GoogleConfig({ data, selectedCurrency }) {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const [icon, setIcon] = useState({
    url: null,
    file: null,
  });

  const onChangeJson = (info) => {
    form.setFields([{ name: "google_config_json", value: info?.file?.originFileObj }]);
  };

  const onFinish = (values) => {
    setIsLoading(true);

    const formData = new FormData();

    if (!values.google_enabled) {
      formData.append("google_enabled", values.google_enabled);
    } else {
      if (icon.file) {
        formData.append("google_icon", icon.file);
      }

      formData.append("id", data.id);
      formData.append("currency_id", selectedCurrency);
      formData.append("google_enabled", values.google_enabled);
      formData.append("google_package_name", values.google_package_name || "");

      if (values?.google_config_json) {
        formData.append("google_config_json", values.google_config_json);
      }
    }

    REQUESTS.PAYMENTS.CONFIGS.UPDATE(formData)
      .then((response) => {
        setIsLoading(false);

        if (response.error) {
          notification.error({
            message: t("error"),
            description: response.message,
          });

          return;
        }

        notification.success({
          message: t("success"),
          description: t("google_pay_config_change_message"),
        });
      })
      .catch((error) => {
        setIsLoading(false);
        notification.error({
          message: t("error"),
          description: error,
        });
      });
  };

  const onChangeIcon = (value) => {
    setIcon(value);

    form.setFieldsValue({
      google_icon: value.file,
    });
  };

  useEffect(() => {
    if (data) {
      setIcon({
        url: data.google_icon,
        file: null,
      });

      form.setFields([
        { name: "google_icon", value: data.google_icon },
        { name: "google_enabled", value: data.google_enabled },
        { name: "google_package_name", value: data.google_package_name },
      ]);

      setDisabled(data.google_enabled);
    }
  }, [data]);

  return (
    <Form
      form={form}
      name="google-pay-config"
      layout="vertical"
      onFinish={onFinish}
      style={{ maxWidth: 350 }}
    >
      <Form.Item label={t("icon")} name="google_icon">
        <ImageUpload image={icon} setImage={onChangeIcon} />
      </Form.Item>

      <Form.Item label={t("enabled")} name="google_enabled" valuePropName="checked">
        <Switch
          onChange={(value) => {
            setDisabled(value);
          }}
        />
      </Form.Item>

      <Form.Item shouldUpdate noStyle>
        {() => {
          const { google_enabled } = form.getFieldsValue();

          return (
            <Form.Item
              label={t("package_name")}
              name="google_package_name"
              rules={[
                {
                  required: google_enabled,
                  message: t("package_name_message"),
                },
              ]}
            >
              <Input disabled={!disabled} />
            </Form.Item>
          );
        }}
      </Form.Item>

      <Form.Item shouldUpdate noStyle>
        {() => {
          const { google_enabled } = form.getFieldsValue();

          return (
            <Form.Item
              label={t("service_account")}
              name="google_config_json"
              rules={[
                {
                  required: google_enabled,
                  message: t("select_json_file_message"),
                },
              ]}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Upploader
                  accept=".json"
                  onChange={onChangeJson}
                  style={{ width: 320 }}
                  disabled={!disabled}
                />

                {data?.google_config ? <Check value={true} /> : <Check value={false} />}
              </div>
            </Form.Item>
          );
        }}
      </Form.Item>

      <ButtonComponent
        title={t("save")}
        actionType="save"
        isLoading={isLoading}
        onClick={() => form.submit()}
        // disabled={!disabled}
      />
    </Form>
  );
}
