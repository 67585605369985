import { useEffect, useState } from "react";
import TableButtons from "../../../_components/table/TableButtons";
import TableComponent from "../../../_components/table/TableComponent";
import { useTranslation } from "react-i18next";
import icons from "../../../config/icons";
import TableImage from "../../../_components/table/TableImage";
import Check from "../../../_components/table/Check";
import { Button, Dropdown, Typography } from "antd";
import ButtonComponent from "../../../_components/button/ButtonComponent";
import {
  getAtLocal,
  getAtSession,
  setInLocal,
  setInSession,
} from "../../../helpers/storages";
import { getColumnSearchProps } from "../../../config/config";
import { filterOPtions } from "./utils/utils";
import { asc_desc } from "../../../helpers/ascDesc";
import { useNavigate } from "react-router";
import PATHS from "../../../config/paths";
import dayjs from "dayjs";

const CustomAdsTable = ({
  getDataSource,
  handleMenuClick,
  bulkAction,
  getAgain,
  loading,
  onUpdate,
}) => {
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [dataSource, setDataSource] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [currentPage, setCurrentPage] = useState(
    getAtSession("custom_ads_page")?.page || 1
  );
  const [limit, setLimit] = useState(getAtSession("pageLimit_custom_ads")?.limit || 10);
  const [total, setTotal] = useState(5);

  const [sort, setSort] = useState(
    getAtSession("custom_ads_page")?.sort || ["id", "DESC"]
  );
  const [search, setSearch] = useState(
    getAtSession("custom_ads_page")?.search || {
      title: "",
      description: "",
      ad_phone: "",
      ad_email: "",
      ad_address: "",
      ads_campaign: "",
      archived: false,
    }
  );

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      fixed: "left",
      ellipsis: true,
      align: "center",
      width: 60,
      defaultSortOrder:
        getAtSession("custom_ads_page")?.sort?.[1] === "id" &&
        asc_desc(getAtSession("custom_ads_page")?.sort?.[1]),
      sorter: true,
    },
    {
      title: t("title"),
      dataIndex: "title",
      key: "title",
      align: "center",
      width: 150,
      ellipsis: true,
      defaultFilteredValue: getAtSession("custom_ads_page")?.search?.title,
      filteredValue: getAtSession("custom_ads_page")?.search?.title,
      ...getColumnSearchProps(),
    },
    {
      title: t("is_running"),
      dataIndex: "is_running",
      key: "is_running",
      align: "center",
      ellipsis: true,
      width: 100,
      defaultSortOrder:
        getAtSession("custom_ads_page")?.sort?.[1] === "is_running" &&
        asc_desc(getAtSession("custom_ads_page")?.sort?.[1]),
      sorter: true,
      render: (_, record) => {
        return <Check value={record.is_running} />;
      },
    },
    {
      title: t("background_image"),
      dataIndex: "bg_image",
      key: "bg_image",
      align: "center",
      width: 100,
      ellipsis: true,
      render: (url) => <TableImage src={url} alt="bg_image" style={{ width: 100 }} />,
    },
    {
      title: t("logo_image"),
      dataIndex: "logo_image",
      key: "logo_image",
      align: "center",
      width: 100,
      ellipsis: true,
      render: (url) => <TableImage src={url} alt="logo_image" style={{ width: 50 }} />,
    },
    {
      title: t("campaign_id"),
      dataIndex: ["ads_campaign", "id"],
      key: "campaign_id",
      align: "center",
      width: 70,
      ellipsis: true,
    },
    {
      title: t("ad_type"),
      dataIndex: "ad_type",
      key: "ad_type",
      align: "center",
      width: 100,
      ellipsis: true,
      defaultFilteredValue: getAtSession("custom_ads_page")?.search?.ad_type,
      filteredValue: getAtSession("custom_ads_page")?.search?.ad_type,
      filters: filterOPtions.adsTypeFilterOptions(t),
      onFilter: (value, record) => true,
    },

    {
      title: t("description"),
      dataIndex: "description",
      key: "description",
      align: "center",
      width: 200,
      ellipsis: true,
      defaultFilteredValue: getAtSession("custom_ads_page")?.search?.description,
      filteredValue: getAtSession("custom_ads_page")?.search?.description,
      ...getColumnSearchProps(),
    },
    {
      title: t("ad_phone"),
      dataIndex: "ad_phone",
      key: "ad_phone",
      align: "center",
      width: 100,
      ellipsis: true,
      defaultFilteredValue: getAtSession("custom_ads_page")?.search?.ad_phone,
      filteredValue: getAtSession("custom_ads_page")?.search?.ad_phone,
      ...getColumnSearchProps(),
    },
    {
      title: t("ad_email"),
      dataIndex: "ad_email",
      key: "ad_email",
      align: "center",
      width: 200,
      ellipsis: true,
      defaultFilteredValue: getAtSession("custom_ads_page")?.search?.ad_email,
      filteredValue: getAtSession("custom_ads_page")?.search?.ad_email,
      ...getColumnSearchProps(),
    },
    {
      title: t("ad_address"),
      dataIndex: "ad_address",
      key: "ad_address",
      align: "center",
      width: 200,
      ellipsis: true,
      defaultFilteredValue: getAtSession("custom_ads_page")?.search?.ad_address,
      filteredValue: getAtSession("custom_ads_page")?.search?.ad_address,
      ...getColumnSearchProps(),
    },
    {
      title: t("pre_role"),
      dataIndex: "pre_role",
      key: "pre_role",
      align: "center",
      width: 100,
      ellipsis: true,
      defaultFilteredValue: getAtSession("custom_ads_page")?.search?.pre_role,
      filteredValue: getAtSession("custom_ads_page")?.search?.pre_role,
      filters: filterOPtions.roleOPtions(t),
      onFilter: (value, record) => true,

      render: (preRole) => (preRole ? t("Enabled") : t("Disabled")),
    },
    {
      title: t("mid_role"),
      dataIndex: "mid_role",
      key: "mid_role",
      align: "center",
      width: 100,
      defaultFilteredValue: getAtSession("custom_ads_page")?.search?.pre_role,
      filteredValue: getAtSession("custom_ads_page")?.search?.pre_role,
      filters: filterOPtions.roleOPtions(t),
      onFilter: (value, record) => true,
      ellipsis: true,
      render: (midRole) => (midRole ? t("Enabled") : t("Disabled")),
    },
    {
      title: t("post_role"),
      dataIndex: "post_role",
      key: "post_role",
      align: "center",
      width: 100,
      defaultFilteredValue: getAtSession("custom_ads_page")?.search?.post_role,
      filteredValue: getAtSession("custom_ads_page")?.search?.post_role,
      filters: filterOPtions.roleOPtions(t),
      onFilter: (value, record) => true,
      ellipsis: true,
      render: (postRole) => (postRole ? t("Enabled") : t("Disabled")),
    },
    {
      title: t("skipable"),
      dataIndex: "skipable",
      key: "skipable",
      align: "center",
      width: 90,
      defaultSortOrder:
        getAtSession("custom_ads_page")?.sort?.[0] === "skipable" &&
        asc_desc(getAtSession("custom_ads_page")?.sort?.[1]),
      sorter: true,
      ellipsis: true,
      render: (skipable) => <Check checked={skipable} />,
    },
    {
      title: t("duration_in_seconds"),
      dataIndex: "duration_in_seconds",
      key: "duration_in_seconds",
      align: "center",
      width: 100,
      ellipsis: true,
    },
    {
      title: t("skip_after_seconds"),
      dataIndex: "skip_after_seconds",
      key: "skip_after_seconds",
      align: "center",
      width: 100,
      ellipsis: true,
    },
    {
      title: t("conversion_url"),
      dataIndex: "conversion_url",
      key: "conversion_url",
      align: "center",
      width: 200,
      ellipsis: true,
      defaultSortOrder:
        getAtSession("custom_ads_page")?.sort?.[0] === "conversion_url" &&
        asc_desc(getAtSession("custom_ads_page")?.sort?.[1]),
      sorter: true,

      render: (url) => (
        <Typography.Paragraph
          ellipsis={true}
          style={{ margin: 0 }}
          copyable={{
            tooltips: false,
          }}
        >
          {url}
        </Typography.Paragraph>
      ),
    },
    {
      title: t("archived"),
      dataIndex: "archived",
      key: "archived",
      align: "center",
      width: 110,
      sorter: true,
      defaultFilteredValue: getAtSession("custom_ads_page")?.search?.archived,
      filteredValue: getAtSession("custom_ads_page")?.search?.archived,
      filters: filterOPtions.archiveFilterOPtions(t),
      ellipsis: true,
      render: (archived) => <Check checked={archived} />,
    },
    {
      title: t("company_title"),
      dataIndex: ["ads_campaign", "title"],
      key: "campaign_title",
      align: "center",
      width: 200,
      ellipsis: true,
      defaultFilteredValue: getAtSession("custom_ads_page")?.search?.ads_campaign,
      filteredValue: getAtSession("custom_ads_page")?.search?.ads_campaign,
      ...getColumnSearchProps(),
    },
    {
      title: t("max_budget"),
      dataIndex: ["ads_campaign", "max_budget"],
      key: "max_budget",
      align: "center",
      ellipsis: true,
      width: 70,
    },
    {
      title: t("earnings"),
      dataIndex: ["ads_campaign", "earning"],
      key: "earning",
      align: "center",
      width: 70,
      ellipsis: true,
    },
    {
      title: t("client_id"),
      dataIndex: ["ads_campaign", "client_id"],
      key: "client_id",
      align: "center",
      width: 70,
      ellipsis: true,
    },
    {
      title: t("start_date"),
      dataIndex: ["ads_campaign", "start_date"],
      key: "start_date",
      align: "center",
      width: 100,
      ellipsis: true,
      render: (startDate) => {
        return dayjs(startDate).format("YYYY-MM-DD");
      },
    },
    {
      title: t("end_date"),
      dataIndex: ["ads_campaign", "end_date"],
      key: "end_date",
      align: "center",
      width: 100,
      ellipsis: true,
      render: (endDate) => {
        return dayjs(endDate).format("YYYY-MM-DD");
      },
    },
    {
      title: t("notes"),
      dataIndex: ["ads_campaign", "notes"],
      key: "notes",
      align: "center",
      width: 90,
      ellipsis: true,
      render: (notes) => {
        return notes ? notes : "N/A";
      },
    },
    {
      title: "",
      key: "action",
      dataIndex: "action",
      fixed: "right",
      align: "center",
      width: 70,
      render: (text, record) => (
        <TableButtons
          handleMenuClick={(key) => handleClick(key, record)}
          items={[
            {
              key: "edit",
              name: t("edit"),
              icon: icons.EDIT,
              onConfirm: (e) => {
                handleMenuClick(e, record);
              },
            },
            {
              key: "is_running",
              name: t("is_running"),
              icon: !record?.is_running ? icons.PLAY : icons.PAUSE,
              onConfirm: (e) => {
                console.log(e);
                handleClick(e, record);
              },
            },
            {
              key: "delete",
              name: t("delete"),
              icon: icons.DELETE,
              question: t("are_you_sure", {
                action: t("delete"),
                name: t("ads"),
              }),
              onConfirm: (e) => {
                handleMenuClick(e, record);
              },
            },
          ]}
        />
      ),
    },
  ];

  const handleClick = ({ key }, record) => {
    if (key === "edit") {
      const searchParams = new URLSearchParams();

      const editableType = record.ad_type;

      let types;

      if (editableType === "player banner") {
        types = "player_banner";
      } else if (editableType === "splash screen") {
        types = "splash_screen";
      } else if (editableType === "volume") {
        types = "volume";
      } else if (editableType === "collection") {
        types = "collection";
      }

      searchParams.append("type", types);

      searchParams.append("id", record.id);

      navigate(`${PATHS.CUSTOM_ADS_CONTENT}?${searchParams.toString()}`);
    } else if (key === "delete") {
      handleMenuClick(key, record);
    } else if (key === "is_running") {
      onUpdate(record.id, { is_running: !record.is_running });
    }
  };

  const handleTableChange = (data) => {
    console.log(data.search);

    const custom_ads_page = {
      page: data.page,
      sort: data.sort,
      search: data.search,
    };

    setInLocal("pageLimit_custom_ads", data.limit);
    setInSession("custom_ads_page", custom_ads_page);

    setCurrentPage(data.page);
    setLimit(data.limit);
    setSort(data.sort);

    if (data.search) {
      setSearch(data.search);
    }
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const adMenuItems = [
    {
      key: "collection_ads",
      label: t("collection_ads"),
      icon: icons.COLLECTION,
      onClick: () => {
        navigate(`${PATHS.CUSTOM_ADS_CONTENT}?type=collection`);
      },
    },

    {
      key: "volume_ads",
      label: t("volume_ads"),
      icon: icons.VOLUME,
      onClick: () => {
        navigate(`${PATHS.CUSTOM_ADS_CONTENT}?type=volume`);
      },
    },
    {
      key: "video_banner_ads",
      label: t("video_banner_ads"),
      icon: icons.BANNER,
      onClick: () => {
        navigate(`${PATHS.CUSTOM_ADS_CONTENT}?type=player_banner`);
      },
    },
    {
      key: "splash_screen_ads",
      label: t("splash_screen_ads"),
      icon: icons.SPLASH,
      onClick: () => {
        navigate(`${PATHS.CUSTOM_ADS_CONTENT}?type=splash_screen`);
      },
    },
  ];

  useEffect(() => {
    const query = {
      page: currentPage,
    };

    if (sort[1]) {
      query.sort = sort;
    }

    if (search?.ad_type) {
      query.filter = {
        ad_type: search.ad_type,
      };
    }

    if (search?.pre_role) {
      query.filter = {
        pre_role: search.pre_role,
      };
    }

    if (search?.mid_role) {
      query.filter = {
        mid_role: search.mid_role,
      };
    }

    if (search?.post_role) {
      query.filter = {
        post_role: search.post_role,
      };
    }

    if (search?.archived) {
      query.filter = {
        archived: search.archived,
      };
    }

    if (search?.title) {
      query.search = {
        title: search.title,
      };
    }

    if (search?.description) {
      query.search = {
        description: search.description,
      };
    }

    if (search?.ad_phone) {
      query.search = {
        ad_phone: search.ad_phone,
      };
    }

    if (search?.ad_email) {
      query.search = {
        ad_email: search.ad_email,
      };
    }

    if (search?.ad_address) {
      query.search = {
        ad_address: search.ad_address,
      };
    }

    if (search?.campaign_title) {
      query.search = {
        campaign_title: search.campaign_title,
      };
    }

    const success = (data) => {
      // setLimit(data?.limit);
      setTotal(data?.total);
      setCurrentPage(data?.page);
      setDataSource(data?.rows);
    };

    const error = (e) => {
      console.log(e);
    };

    getDataSource(query, success, error);
  }, [limit, sort, search, currentPage, getAgain]);

  return (
    <div>
      <TableComponent
        header={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            {selectedRowKeys.length > 0 && (
              <ButtonComponent
                icon={icons.DELETE}
                title={t("delete")}
                type="default"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() => {
                  bulkAction("delete", selectedRowKeys, (response) => {
                    if (response == getAgain) {
                      setSelectedRowKeys([]);
                    }
                  });
                }}
              />
            )}

            <Dropdown
              menu={{
                items: adMenuItems,
              }}
              handleItemClick={(key) => {
                console.log(key);
              }}
            >
              <Button
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
                type="primary"
                icon={icons.ADD}
              >
                {t("add")}
              </Button>
            </Dropdown>
          </div>
        }
        columns={columns}
        dataSource={dataSource}
        onChange={handleTableChange}
        rowSelection={dataSource.length > 1 && rowSelection}
        isLoading={loading}
        pagination={{
          page: currentPage,
          limit,
          total,
        }}
        rowKey="id"
        scroll={{ x: 2500 }}
      />
    </div>
  );
};

export default CustomAdsTable;
