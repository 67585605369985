import React from "react";
import { inoToast } from "../components/toast/Toast";
import { notification } from "antd";

export const betweenTime = (date) => {
  let d = new Date(date);
  let year = d.getFullYear();
  let hours = d.getHours();
  let month = d.getMonth() + 1;
  let day = d.getDate();

  if (day < 10) {
    day = "0" + day;
  }

  if (month < 10) {
    month = "0" + month;
  }
  if (hours < 10) {
    hours = "0" + hours;
  }

  return `${year}-${month}-${day} 00:00`;
};

export const getDate = () => {
  let today = new Date();
  let formatted_date =
    today.getFullYear() +
    "-" +
    String(today.getDate()).padStart(2, 0) +
    " " +
    String(today.getMonth() + 1).padStart(2, 0) +
    "-" +
    today.getHours() +
    ":" +
    today.getMinutes() +
    ":" +
    today.getSeconds();
  return formatted_date;
};

export const converterMSToYMD = (date) => {
  let d = new Date(date);
  let year = d.getFullYear();
  let hours = d.getHours();
  let month = d.getMonth() + 1;
  let day = d.getDate();

  if (day < 10) {
    day = "0" + day;
  }

  if (month < 10) {
    month = "0" + month;
  }
  if (hours < 10) {
    hours = "0" + hours;
  }
  return `${year}-${month}-${day}`;
};

export const formatDateEN = (date) => {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  // let ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  let strTime = hours + ":" + minutes;
  return (
    date.getDate() + "/" + date.getMonth() + 1 + "/" + date.getFullYear() + " " + strTime
  );
};

export const getLastMonthDate = () => {
  let today = new Date();
  let formatted_date =
    today.getFullYear() +
    "-" +
    String(today.getMonth()).padStart(2, 0) +
    "-" +
    String(today.getDate()).padStart(2, 0) +
    " " +
    "00:00:00";
  return formatted_date;
};

export const unixToDate = (UNIX_timestamp) => {
  let a = new Date(UNIX_timestamp);
  let months = ["01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12"];
  let year = a.getFullYear();
  let month = months[a.getMonth()];
  let date = ("0" + a.getDate()).slice(-2);
  let time = date + "-" + month + "-" + year;
  return time;
};

export const unixToTime = (UNIX_timestamp) => {
  let date = new Date(UNIX_timestamp);
  let hours = date.getHours();
  let minutes = "0" + date.getMinutes();
  let formattedTime = hours + ":" + minutes.substr(-2);
  return formattedTime;
};

export const capitalize = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

export const parseDate = (data) => {
  let d = new Date(data);
  let year = d.getFullYear();
  let hours = d.getHours();
  let month = d.getMonth() + 1;
  let minutes = d.getMinutes();
  let day = d.getDate();

  if (day < 10) {
    day = "0" + day;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (month < 10) {
    month = "0" + month;
  }
  if (hours < 10) {
    hours = "0" + hours;
  }

  return day + "/" + month + "/" + year + " " + hours + ":" + minutes;
};

export const parseFullDate = (date) => {
  let d = new Date(date);
  let year = d.getFullYear();
  let hours = d.getHours();
  let month = d.getMonth() + 1;
  let minutes = d.getMinutes();
  let day = d.getDate();
  let seconds = d.getSeconds();

  if (day < 10) {
    day = "0" + day;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (month < 10) {
    month = "0" + month;
  }
  if (hours < 10) {
    hours = "0" + hours;
  }

  if (seconds < 10) {
    seconds = "0" + seconds;
  }

  return day + "/" + month + "/" + year + " " + hours + ":" + minutes + ":" + seconds;
};

export const parseTime = (date) => {
  let d = new Date(date);
  let year = d.getFullYear();
  let hours = d.getHours();
  let month = d.getMonth() + 1;
  let minutes = d.getMinutes();
  let day = d.getDate();
  let seconds = d.getSeconds();

  if (day < 10) {
    day = "0" + day;
  }
  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (month < 10) {
    month = "0" + month;
  }
  if (hours < 10) {
    hours = "0" + hours;
  }

  if (seconds < 10) {
    seconds = "0" + seconds;
  }

  return year + "-" + month + "-" + day + "T" + hours + ":" + minutes + ":" + seconds;
};

export const parseHours = (data) => {
  let d = new Date(data);
  let hours = d.getHours();
  let minutes = d.getMinutes();

  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (hours < 10) {
    hours = "0" + hours;
  }

  if (hours && minutes) {
    return hours + ":" + minutes;
  }
};

export const renderDate = (data) => {
  let d = new Date(data);
  let year = d.getFullYear();
  let month = d.getMonth() + 1;
  let day = d.getDate();

  if (day < 10) {
    day = "0" + day;
  }
  if (month < 10) {
    month = "0" + month;
  }

  return day + "/" + month + "/" + year;
};

export const parseHourMinuteSecond = (data) => {
  let d = new Date(data);
  let hours = d.getHours();
  let minutes = d.getMinutes();
  let second = d.getSeconds();

  if (minutes < 10) {
    minutes = "0" + minutes;
  }
  if (hours < 10) {
    hours = "0" + hours;
  }
  if (second < 10) {
    second = "0" + second;
  }

  return hours + ":" + minutes + ":" + second;
};

export const minutesToHours = (minute) => {
  if (!minute) return 0;

  if (minute <= 60) return `${minute} min`;

  let result = "";

  let min = minute % 60;

  let hour = parseInt(minute / 60);

  result = `${hour} h`;

  if (min > 0) result += ` ${min} min`;

  return result;
};

export const copy = (input) => {
  let copyText = document.getElementById(input);
  copyText.select();
  copyText.setSelectionRange(0, 99999); /*For mobile devices*/
  document.execCommand("copy");
  // inoToast.success("url is copied");
  notification.success({
    message: "Success",
    description: "Url is copied",
  });
};

export const imageFormData = (imageUrl, name, filename) => {
  let formData = new FormData();
  let request = new XMLHttpRequest();

  request.responseType = "blob";

  request.onloadend = function () {
    formData.append(name, request.response, filename);
  };

  request.open("GET", imageUrl);
  request.send();
};

export function TextOfEmptyTable() {
  return <td colSpan={10}>List is empty</td>;
}

export const Pagination = (props) => {
  const { currentPage, pageCount, onChangePage } = props;

  return (
    <div className="pageination-style">
      <div className="hover-style" onClick={() => onChangePage("prev")}>
        <i className="fa fa-chevron-left" />
      </div>

      <p className="current-page">
        {currentPage} of {pageCount}
      </p>

      <div className="hover-style" onClick={() => onChangePage("next")}>
        <i className="fa fa-chevron-right" />
      </div>
    </div>
  );
};

export const NeedHaveServer = () => {
  return (
    <div className="need-have-server">
      <div>
        <h1>Title</h1>
        <p>
          Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem
          Ipsum has been the industry's standard dummy text ever since the 1500s, when an
          unknown printer took a galley of type and scrambled it to make a type specimen
          book. It has survived not only five centuries, but also the leap into electronic
          typesetting, remaining essentially unchanged. It was popularised in the 1960s
          with the release of Letraset sheets containing Lorem Ipsum passages, and more
          recently with desktop publishing software like Aldus PageMaker including
          versions of Lorem Ipsum.
        </p>
      </div>
    </div>
  );
};

export const showTime = (time) => {
  let hour = parseInt(time / 60 / 60);
  let day = hour / 24;
  let _hour = hour % 24;

  return (
    <p className="text">
      <span style={{ color: "black" }}>Uptime:</span> <b>{parseInt(day)}</b> day{" "}
      <b>{parseInt(_hour)}</b> hour
    </p>
  );
};

//vod, tv, both
export const appType = "both";

// export const setLimit = (limit) => {
//   localStorage.setItem("LIMIT", JSON.stringify(limit));
// };

export const getLimit = () => {
  // return JSON.parse(localStorage.getItem("LIMIT")) || { label: 10, value: 10 };
  return { label: 10, value: 10 };
};

export const limitOption = [
  { label: 10, value: 10 },
  { label: 20, value: 20 },
  { label: 50, value: 50 },
  { label: 100, value: 100 },
];

export const mediaTypesOption = [
  {
    label: "All",
    value: null,
  },
  {
    label: "Movie",
    value: "movie",
  },
  {
    label: "TV-Show",
    value: "tv_show",
  },
];

export const enableOrDisableOption = [
  {
    label: "Enable",
    value: true,
  },
  {
    label: "Disable",
    value: false,
  },
];

export const outputTypes = [
  {
    label: "HLS",
    value: "hls",
  },

  {
    label: "RTMP",
    value: "rtmp",
  },

  {
    label: "UDP",
    value: "udp",
  },
];

export const isValidDate = (d) => {
  return d instanceof Date && !isNaN(d);
};

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};
