import { useState, useContext } from "react";
import styles from "../../style.module.scss";
import { useTranslation } from "react-i18next";
import AdsUpload from "../AdsUpload";
import AdsForm from "../AdsForm";
import { DatePicker, Form, Input, Select } from "antd";
import AdsPreview from "../AdsPreview";

import { useAdsCampaignsOptions } from "../../../../../hooks/selectOptions";
import { AdsContext } from "../../AdsContentPage";

const SplashScreenAdPage = ({ form, loading }) => {
  const { t } = useTranslation();

  const campaignOPtions = useAdsCampaignsOptions();

  const { setCampaignId } = useContext(AdsContext);

  const TexArea = Input.TextArea;
  const RangePicker = DatePicker.RangePicker;

  const handleCampaignChange = (value) => {
    setCampaignId(value);
  };

  return (
    <div className={styles["ads-page-wrapper"]}>
      <AdsForm form={form}>
        <div className={styles["ads-collection-head-wrapper"]}>
          <AdsUpload />
          <div className={styles["ads-page-collection-head-data"]}>
            <Form.Item
              label={t("title")}
              name="title"
              rules={[
                {
                  required: true,
                  message: t("enter_title"),
                },
              ]}
            >
              <Input placeholder={t("enter_title")} />
            </Form.Item>
            <Form.Item
              label={t("description")}
              name="description"
              rules={[
                {
                  required: true,
                  message: t("enter_description"),
                },
              ]}
            >
              <TexArea placeholder={t("enter_description")} />
            </Form.Item>
          </div>
        </div>

        <div className={styles["ads-page-collection-info-data"]}>
          <Form.Item
            label={t("campaign")}
            name="campaign_id"
            rules={[
              {
                required: true,
                message: t("select_campaign"),
              },
            ]}
          >
            <Select
              placeholder={t("select_campaign")}
              options={campaignOPtions}
              showSearch
              onChange={handleCampaignChange}
              filterOption={(input, option) => {
                return option.label.toLowerCase().indexOf(input.toLowerCase());
              }}
            />
          </Form.Item>
          <Form.Item
            label={t("conversation_link")}
            name="conversion_url"
            rules={[
              {
                type: "url",
                message: t("invalid_url"),
              },
              {
                required: true,
                message: t("conversation_link_message"),
              },
            ]}
          >
            <Input placeholder={t("conversation_link")} />
          </Form.Item>
        </div>

        <AdsPreview />
      </AdsForm>
    </div>
  );
};

export default SplashScreenAdPage;
