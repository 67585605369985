import { Descriptions, notification } from "antd";

import { useEffect, useState } from "react";

import REQUESTS from "../../../../api/requests";
import { useTranslation } from "react-i18next";

// const showTime = (time) => {
//   let hour = parseInt(time / 60 / 60);
//   let day = hour / 24;
//   let _hour = hour % 24;

//   return (
//     <p>
//       {parseInt(day)} day {parseInt(_hour)} hour
//     </p>
//   );
// };

export default function ServerStorage() {
  const { t } = useTranslation();

  const [data, setData] = useState({
    cpu: 0,
    inputMBS: 0,
    outputMBS: 0,
    ram: 0,

    storage: { size: 0, available: 0, used: 0, used_percentages: 0 },
    uptime: 0,
  });

  const showTime = (time) => {
    let hour = parseInt(time / 60 / 60);
    let day = hour / 24;
    let _hour = hour % 24;

    return (
      <p>
        {parseInt(day)} {t("day")} {parseInt(_hour)} {t("hour")}
      </p>
    );
  };

  const getData = () => {
    try {
      REQUESTS.VOD.TRANSCODING.STORAGE()
        .then((data) => {
          setData(data);
        })
        .catch((err) => {
          notification.error({
            message: "Error",
            description: err,
          });
        });
    } catch (err) {
      console.log(err);
      notification.error({
        message: t("error"),
        description: t("something_wrong"),
      });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div style={{ display: "flex" }}>
      <Descriptions title={t("server")} style={{ width: "50%" }} column={2}>
        <Descriptions.Item label={t("cpu")}>
          {parseFloat(data?.cpu).toFixed(0) || 0} %
        </Descriptions.Item>
        <Descriptions.Item label={t("ram")}>
          {parseFloat(data?.ram).toFixed(0) || 0} %
        </Descriptions.Item>

        <Descriptions.Item label={t("input")}>
          {parseFloat(data?.inputMBS).toFixed(0) || 0}
        </Descriptions.Item>

        <Descriptions.Item label={t("output")}>
          {parseFloat(data?.outputMBS).toFixed(0) || 0}
        </Descriptions.Item>

        <Descriptions.Item label={t("uptime")}>
          {showTime(data && data?.uptime)}
        </Descriptions.Item>
      </Descriptions>

      <Descriptions title={t("storage")} style={{ width: "50%" }} column={2}>
        <Descriptions.Item label="Size">
          {parseFloat(data?.storage?.size).toFixed(0) || 0} gb
        </Descriptions.Item>

        <Descriptions.Item label={t("used")}>
          {parseFloat(data?.storage?.used).toFixed(0) || 0} gb (
          {parseFloat(data?.storage?.used_percentages).toFixed(0) || 0} %)
        </Descriptions.Item>

        <Descriptions.Item label={t("available")}>
          {parseFloat(data?.storage?.available).toFixed(0) || 0} gb
        </Descriptions.Item>
        <br />
      </Descriptions>
    </div>
  );
}
