import request from "../../../api/request";
import { DEMO_API_HOST } from "../../../config/statics";
import { appHost } from "../../../helpers/appHost";

const host = appHost;

const URL = {
  RESOLUTIONS: `${host}admin/resolutions`,
};

export default {
  GET: (query) => request("GET", URL.RESOLUTIONS, query),

  ADD: (query) => request("POST", URL.RESOLUTIONS, query),

  EDIT: (query) => request("PUT", URL.RESOLUTIONS, query),

  DELETE: (query) => request("DELETE", URL.RESOLUTIONS, query),
};
