import { useEffect, useState } from "react";
import { Divider, Form, Input, Button, notification } from "antd";
import { MinusCircleOutlined, PlusOutlined, RightOutlined } from "@ant-design/icons";

import REQUESTS from "../../../../api/requests";

import ButtonComponent from "../../../../_components/ButtonComponent";
import { useTranslation } from "react-i18next";

export default function LiveTvTab() {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const [isDisabled, setIsDisabled] = useState(true);

  const [form] = Form.useForm();

  const getData = () => {
    try {
      REQUESTS.SETTINGS.CDN.GET("live_tv")
        .then((response) => {
          if (!response.error) {
            form.setFieldsValue({ urls: response.message });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const onFieldsChange = () => {
    setIsDisabled(false);
  };

  const onFinish = (values) => {
    setIsLoading(true);
    setIsDisabled(true);

    const list = values.urls.map((item) => {
      return {
        url: item.url?.trim(),
        replaceable_url: item.replaceable_url?.trim(),
      };
    });

    const body = {
      type: "live_tv",
      data: JSON.stringify(list),
    };

    REQUESTS.SETTINGS.CDN.SAVE(body)
      .then((response) => {
        setIsLoading(false);

        if (response.error) {
          notification.error({
            message: t("error"),
            description: response.message,
          });

          return;
        }

        notification.success({
          message: t("success"),
          description: t("live_tv_url_saved"),
        });

        getData();
      })
      .catch(() => {
        setIsLoading(false);
        setIsDisabled(false);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <Divider orientation="left">{t("live_tv")}</Divider>

      <Form
        form={form}
        name="cdn-live-tv"
        layout="vertical"
        onFinish={onFinish}
        onFieldsChange={onFieldsChange}
        style={{ width: "100%" }}
        // onKeyPress={(e) => {
        //     if (e.key === "Enter") {
        //         form.submit();
        //     }
        // }}
      >
        <Form.List name="urls">
          {(fields, { add, remove }) => {
            return (
              <>
                <Form.Item>
                  <Button
                    type="dashed"
                    onClick={() => add()}
                    block
                    icon={<PlusOutlined />}
                  >
                    {t("add_url")}
                  </Button>
                </Form.Item>

                {fields.map(({ key, name, ...restField }) => {
                  return (
                    <div
                      key={key}
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        gap: 10,
                        marginBottom: 20,
                      }}
                      align="baseline"
                    >
                      <Form.Item {...restField} name={[name, "url"]} noStyle>
                        <Input placeholder={t("url_placeholder")} />
                      </Form.Item>

                      <RightOutlined />

                      <Form.Item {...restField} name={[name, "replaceable_url"]} noStyle>
                        <Input min={0} placeholder={t("new_url")} />
                      </Form.Item>

                      <div style={{ width: 40 }} disabled={true}>
                        <Button
                          icon={<MinusCircleOutlined />}
                          onClick={() => remove(name)}
                        />
                      </div>
                    </div>
                  );
                })}
              </>
            );
          }}
        </Form.List>

        <Form.Item>
          <ButtonComponent
            title={t("save")}
            actionType="save"
            isLoading={isLoading}
            onClick={() => form.submit()}
            disabled={isDisabled}
          />
        </Form.Item>
      </Form>
    </div>
  );
}
