import { useEffect, useState } from "react";
import { Button, Space, Tag, Tooltip, Typography, notification } from "antd";

import icons from "../../../config/icons";

import {
  getColumnDateProps,
  getColumnSearchProps,
  getQueryDate,
  parseDate,
} from "../../../config/config";

import TableButtons from "../../../_components/table/TableButtons";
import TableComponent from "../../../_components/table/TableComponent";
import ButtonComponent from "../../../_components/button/ButtonComponent";
import TableEmpty from "../../../_components/table/TableEmpty";
import {
  getAtLocal,
  getAtSession,
  setInLocal,
  setInSession,
} from "../../../helpers/storages";
import { asc_desc } from "../../../helpers/ascDesc";
import tablesInitialValues from "../../../config/tablesInitialValues";
import Upload from "antd/es/upload/Upload";
import Upploader from "../../../_components/uploader/Uploader";
import { useTranslation } from "react-i18next";

// const statusOptions = [
//   // { text: "All" },
//   { text: "None", value: "none" },
//   { text: "Downloading", value: "downloading" },
//   { text: "Waiting", value: "waiting" },
//   { text: "Started", value: "started" },
//   { text: "Finished", value: "finished" },
//   // { text: "Errored", value: "errored" },
//   { text: "Errored", value: getAtLocal("errored") || "errored" },
// ];

const initialValues = tablesInitialValues.get(tablesInitialValues.keys.vod_transcoding);

export default function VodTranscodingTable({
  getAgain,
  openDrawer,
  getDataSource,
  handleMenuClick,
  openPlayingVideo,
  handle_CSV,
}) {
  const { t } = useTranslation();

  const [dataSource, setDataSource] = useState([]);

  const [limit, setLimit] = useState(getAtLocal("pageLimit_vodTranscoding") || 10);

  const [loading, setLoading] = useState(false);

  const [isEmpty, setIsEmpty] = useState(false);

  const [currentPage, setCurrentPage] = useState(
    getAtSession("vodTranscoding")?.page || 1
  );

  const [sort, setSort] = useState(
    getAtSession("vodTranscoding")?.sort || ["id", "DESC"]
  );

  const [date, setDate] = useState(null);

  const [total, setTotal] = useState(0);

  const [search, setSearch] = useState(
    getAtSession("vodTranscoding")?.search || {
      name: null,
      status: null,
    }
  );

  const statusOptions = [
    // { text: "All" },
    { text: t("none"), value: "none" },
    { text: t("downloading"), value: "downloading" },
    { text: t("waiting"), value: "waiting" },
    { text: t("started"), value: "started" },
    { text: t("finished"), value: "finished" },
    // { text: "Errored", value: "errored" },
    { text: t("errored"), value: getAtLocal("errored") || "errored" },
  ];

  const columns = [
    {
      title: "#",
      width: 60,
      dataIndex: `id`,
      align: "center",
      fixed: "left",
      key: "index",
      render: (record, text, index) => {
        return limit * (currentPage - 1) + index + 1;
      },
    },

    {
      title: t("status"),
      defaultFilteredValue: getAtSession("vodTranscoding")?.search?.name,
      filteredValue: getAtSession("vodTranscoding")?.search?.name,
      dataIndex: `status`,
      align: "center",
      key: "status",
      filters: statusOptions,
      width: 120,
      filterMultiple: false,
      onFilter: (value, record) => true,
      sorter: true,
      render: (text, record, index) => {
        switch (record.status) {
          case "none":
            return <Tag>{t("none")}</Tag>;

          case "finished":
            return <Tag color="success">{t("finished")}</Tag>;

          case "waiting":
            return <Tag color="gold">{t("waiting")}</Tag>;

          case "started":
            return <Tag color="lime">{t("started")}</Tag>;

          case "downloading":
            return <Tag color="orange">{t("downloading")}</Tag>;

          case "errored":
            return (
              <>
                {text && (
                  <Tooltip placement="topLeft" title={() => record.error}>
                    <Tag color="error">{t("errored")}</Tag>
                  </Tooltip>
                )}
              </>
            );

          default:
            return <Tag color="error">{t("errored")}</Tag>;
        }
      },
    },

    {
      title: t("name"),
      defaultFilteredValue: getAtSession("vodTranscoding")?.search?.name,
      filteredValue: getAtSession("vodTranscoding")?.search?.name,
      dataIndex: "name",
      key: "name",
      align: "center",
      width: 200,
      ellipsis: true,
      render: (text, record, index) => {
        return text ? (
          <Tooltip placement="topLeft" title={text}>
            {text}
          </Tooltip>
        ) : (
          <TableEmpty />
        );
      },

      ...getColumnSearchProps(),
    },

    {
      title: t("percent"),
      defaultSortOrder:
        getAtSession("vodTranscoding")?.sort?.[0] === "percent" &&
        asc_desc(getAtSession("vodTranscoding")?.sort?.[1]),
      dataIndex: "percent",
      key: "percent",
      align: "center",
      ellipsis: true,
      width: 100,
      sorter: true,
    },

    {
      title: t("stream_url"),
      dataIndex: "stream_url",
      key: "stream_url",
      align: "center",
      ellipsis: true,

      width: 240,
      ellipsis: true,
      render: (text, record, index) => {
        const cutText = text.slice(-18);
        return (
          <Space>
            <Button
              style={{
                marginTop: 5,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              type="primary"
              icon={icons.PLAY}
              onClick={() => openPlayingVideo(record)}
              disabled={record.status !== "finished"}
            />

            <Typography.Paragraph
              style={{ margin: 0, width: 180, display: "flex" }}
              copyable={{
                text: text,
              }}
            >
              <div style={{ width: 170 }}>...{cutText}</div>
            </Typography.Paragraph>
          </Space>
        );
      },
    },

    {
      title: `Created date`,
      defaultFilteredValue: getAtSession("vodTranscoding")?.search?.createdAt,
      filteredValue: getAtSession("vodTranscoding")?.search?.createdAt,
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      ellipsis: true,

      width: 180,

      render: (text, record, index) => {
        return parseDate(text);
      },

      ...getColumnDateProps(setDate),
    },

    {
      title: "",
      key: "operation",
      fixed: "right",
      width: 60,
      align: "center",
      ellipsis: true,

      render: (text, record, index) => {
        let list = [];

        switch (record.status) {
          case "none":
          case "errored":
          case "finished":
            list.push(
              {
                key: "start",
                name: "Start",
                icon: icons.PLAY,
                question: t("start_transcoding_message"),
                onConfirm: (e) => {
                  handleMenuClick(e, record);
                },
              },

              {
                key: "delete",
                name: "Delete",
                icon: icons.DELETE,
                question: t("delete_transcoding_message"),
                onConfirm: (e) => {
                  handleMenuClick(e, record);
                },
              }
            );

            break;

          case "waiting":
          case "started":
            list.push({
              key: "stop",
              name: t("stop"),
              icon: icons.STOP,
              question: t("stop_transcoding_message"),
              onConfirm: (e) => {
                handleMenuClick(e, record);
              },
            });

            break;

          default:
            break;
        }

        return (
          <TableButtons
            handleMenuClick={(e) => handleMenuClick(e, record)}
            items={list}
          />
        );
      },
    },
  ];

  const handleTableChange = (data) => {
    const vodTranscoding = {
      page: data.page,
      search: data.search,
    };

    if (data.sort) {
      vodTranscoding.sort = [`${data.sort[0]}`, `${data.sort[1]}`];
    }

    setInLocal("pageLimit_vodTranscoding", data.limit);
    setInSession("vodTranscoding", vodTranscoding.search);

    setCurrentPage(data.page);
    setLimit(data.limit);
    setSort(data.sort);

    setSearch(data.search);
  };

  const handleUpload = (value) => {
    const file = value.file.originFileObj;

    if (file.name?.endsWith(".csv")) {
      handle_CSV(file);
    } else {
      notification.error({
        error: "error",
        message: t("select_csv_message"),
      });
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(true);
      const query = {
        // sort,
        limit,
        page: currentPage,
      };

      if (search.name) {
        query.search = {
          name: search.name[0],
        };
      }

      if (sort && sort[1] && sort[1] != "undefined") {
        query.sort = sort;
      } else {
        query.sort = ["id", "DESC"];
      }

      if (search.status && search.status[0]) {
        query.filter = {
          status: search.status[0],
        };
      }

      const queryDate = getQueryDate(date);

      if (queryDate) query.between = queryDate;

      function onSuccess(data) {
        if (data?.rows.length === 0 && currentPage > 1) {
          setCurrentPage((prev) => prev - 1);
          return;
        }

        setLoading(false);

        const hasSearchKeyWithValue = () => {
          const keys = Object.keys(search);

          return keys.some((key) => search[key] && search[key][0]);
        };

        if (data?.rows?.length > 0 || hasSearchKeyWithValue()) {
          setDataSource(data?.rows);
          setIsEmpty(false);
        } else {
          setIsEmpty(true);
        }

        setTotal(data?.total);
        setLimit(data?.limit);
        setCurrentPage(data?.page);
      }

      function onError(data) {
        setLoading(false);
      }

      getDataSource(query, onSuccess, onError);
    }, 1000);

    return () => {
      clearTimeout(timeout);
    };
  }, [limit, currentPage, sort, search, date, getAgain]);

  return (
    <TableComponent
      header={
        <>
          <div>
            <Upploader onChange={handleUpload} title={t("import_from_csv")} />
          </div>
          <div>
            <ButtonComponent
              icon={icons.ADD}
              title={t("add_transcoding")}
              onClick={openDrawer}
            />
          </div>
        </>
      }
      dataSource={dataSource}
      onChange={handleTableChange}
      columns={columns}
      localeClick={openDrawer}
      isEmpty={isEmpty}
      pagination={{
        page: currentPage,
        limit: limit,
        total: total,
      }}
      scroll={{
        x: 800,
      }}
    />
  );
}
