import request from "../../../api/request";

import { appHost } from "../../../helpers/appHost";

const host = appHost;

const URL = {
  MESSAGE: `${host}launcher/admin/message`,
};

const launcherMessagesApi = {
  GET: (query) => request("GET", URL.MESSAGE, query),

  SEND: (query) => request("POST", URL.MESSAGE, query),
};

export default launcherMessagesApi;
