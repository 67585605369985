import { Button, Result } from "antd";
import { useNavigate } from "react-router";

import PATHS from "../../config/paths";
import { useTranslation } from "react-i18next";

export default function ErrorPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Result
      status="403"
      title=""
      subTitle={t("something_wrong")}
      extra={
        <Button type="primary" onClick={() => navigate(PATHS.DASHBOARD_CONTENT)}>
          {t("back_home")}
        </Button>
      }
    />
  );
}
