import request from "../../../api/request";
import { appHost } from "../../../helpers/appHost";

const host = appHost;

const URL = {
  CONFIGS: `${host}v2/admin/social_auth_config`,
};

const socialMediaApi = {
  GET: (query) => request("GET", URL.CONFIGS, query),

  UPDATE: (query) => request("PUT", URL.CONFIGS, query),
};

export default socialMediaApi;
