import { Flex } from "antd";
import ImpressionChart from "./chart/ImpressionChart";
import EarningByTypeChart from "./chart/EarningByTypeChart";
import EarningByPlatformChart from "./chart/EarningByPlatformChart";
import EarningByRegionChart from "./chart/EarningByRegionChart";
import EarningByTopCLientsChart from "./chart/EarningByTopCLientsChart";
import EarningByTopRegionChart from "./chart/EarningByTopRegionChart";
import EarningByTopCampaignChart from "./chart/EarningByTopCampaginChart";

const AdsTab = () => {
  return (
    <>
      {/* <div>
        <EarningByRegionChart />
      </div> */}

      <Flex wrap="wrap" gap="20px">
        <ImpressionChart />
        <EarningByTypeChart />
        <EarningByRegionChart />

        <EarningByPlatformChart />
        <EarningByTopCLientsChart />
        <EarningByTopRegionChart />
        {/* <EarningByTopCampaignChart /> */}
      </Flex>
    </>
  );
};

export default AdsTab;
