import { liveTvServiceId, vodServiceId } from "../../../../config/statics";

export default function initEditableSubscriptionData(
    form,
    editable,
    setVodSeervice,
    setLiveTvService,
    permissions
) {
    form.setFields([
        { name: "name", value: editable.name },
        { name: "description", value: editable.description },
        { name: "description", value: editable.description },
        { name: "price", value: editable.price },
        { name: "device_service", value: editable.device_service },
        { name: "payment_type", value: editable.payment_type },
        { name: "paypal_plan_id", value: editable.paypal_plan_id },
        { name: "stripe_plan_id", value: editable.stripe_plan_id },
        { name: "apple_subscription_id", value: editable.apple_subscription_id },
        { name: "google_subscription_id", value: editable.google_subscription_id },
        { name: "archive_service", value: editable.archive_service },
        { name: "scheduling_type", value: editable.scheduling_type },
        { name: "scheduling_time", value: editable.scheduling_time },
    ]);

    if (permissions?.payments?.authorize) {
        form.setFields([
            { name: "is_supported_authorize", value: editable.is_supported_authorize },
        ]);
    }

    const servicesList = editable.service;

    for (let i = 0; i < servicesList.length; i++) {
        if (servicesList[i].serviceId == vodServiceId) {
            if (servicesList[i].filter) {
                const vodFilter = JSON.parse(servicesList[i].filter);
                if (permissions?.Vod_Manager) {
                    setVodSeervice({
                        category: {
                            type: vodFilter?.category?.type,
                            values: vodFilter?.category?.values,
                        },

                        id: {
                            exclude: vodFilter?.id?.exclude || [],
                            include: vodFilter?.id?.include || [],
                        },

                        year: {
                            type: vodFilter?.year?.type,
                            values: vodFilter?.year?.values,
                        },
                    });
                }
            }
        }

        if (servicesList[i].serviceId == liveTvServiceId) {
            if (servicesList[i].filter) {
                const liveTvFilter = JSON.parse(servicesList[i].filter);

                if (permissions?.LIVE_TV) {
                    setLiveTvService({
                        category: {
                            type: liveTvFilter?.category?.type,
                            values: liveTvFilter?.category?.values,
                        },

                        id: {
                            exclude: liveTvFilter?.id?.exclude || [],
                            include: liveTvFilter?.id?.include || [],
                        },
                    });
                }
            }
        }
    }
}
