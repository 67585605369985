import React from "react";
import "../css/loadings.css";

export function ServerLoading(props) {
    return (
        <div className="server-loading">
            <div className="bg" style={{ width: "100%", height: "100%", padding: 20 }}>
                <div style={{ height: "20%" }}>
                    <p className="loading--text" style={{ fontWeight: "bold", fontSize: 20 }}>
                        Server name
                    </p>
                    <p className="loading--text">http//:exapmle:port</p>
                </div>

                {props.type === "transcoding" && (
                    <div style={{ display: "flex", justifyContent: "center" }}>
                        <p
                            className="loading--text"
                            style={{ fontWeight: "bold", fontSize: 20, margin: "auto" }}
                        >
                            Name
                        </p>
                    </div>
                )}

                <div
                    style={{
                        height: "50%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <div className="server-round-loading">
                        <p className="loading--text" style={{ fontWeight: "bold" }}>
                            RAM
                        </p>
                    </div>
                    <div className="server-round-loading loading-big-round">
                        {props.type === "transcoding" ? (
                            <p
                                className="loading--text"
                                style={{ fontWeight: "bold", fontSize: 20 }}
                            >
                                Memory
                            </p>
                        ) : (
                            <i
                                className="fa fa-users"
                                style={{ fontSize: 60, color: "#ada8a847" }}
                            />
                        )}
                    </div>
                    <div className="server-round-loading">
                        <p className="loading--text" style={{ fontWeight: "bold" }}>
                            CPU
                        </p>
                    </div>
                </div>

                <div
                    style={{
                        height: "30%",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                    }}
                >
                    <div>
                        <p className="loading--text">Input: ...</p>
                        <p className="loading--text">Output: ...</p>
                    </div>
                    <div>
                        <p className="loading--text">Uptime: ...</p>
                        <p className="loading--text">Connected: . . . </p>
                    </div>
                </div>
            </div>
        </div>
    );
}

export function TableLoading() {
    return (
        <div className="loading-bg">
            <div className="table-loading" />
            <div className="table-loading" />
            <div className="table-loading" />
            <div className="table-loading" />
            <div className="table-loading" />
            <div className="table-loading" />
            <div className="table-loading" />
            <div className="table-loading" />
            <div className="table-loading" />
            <div className="table-loading" />
        </div>
    );
}
