import React from "react";

export default function ButtonComponent({
  type = "add",
  title,
  action,
  notIcon,
  disabled,
  style,
}) {
  let icon = "";

  switch (type) {
    case "restore":
      icon = "fa fa-tv";
      break;

    case "save":
      icon = "fas fa-check";
      break;

    case "clear":
      icon = "fa fa-window-close";
      break;

    case "smtp":
      icon = "fas fa-cog";
      break;

    case "send":
      icon = "fa fa-paper-plane";
      break;

    case "edit":
      icon = "fas fa-pencil-alt";
      break;

    case "delete":
      icon = "fas fa-trash-alt";
      break;

    case "upload":
      icon = "fas fa-cloud-upload-alt";
      break;

    case "show":
      break;

    case "fetch":
      icon = "fas fa-sync";
      break;

    case "play":
      icon = "fas fa-play";
      break;

    case "pause":
      icon = "fa fa-pause";
      break;

    case "folder":
      icon = "fas fa-folder-open";
      break;

    case "save_settings":
      icon = "fa fa-save";
      break;

    default:
      icon = "fas fa-plus";
      break;
  }

  return (
    <button
      style={style}
      onClick={action}
      disabled={disabled}
      className={`button-component ${type}-button m-r-5 ${
        !title && "mini-button-component"
      }`}
    >
      {!notIcon && <i className={icon} style={{ marginRight: title && 5 }} />}

      {title && title}
    </button>
  );
}
