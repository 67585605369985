import request from "../../api/request";
import { appHost } from "../../helpers/appHost";

const host = appHost;

const URL = {
  RESET: `${host}v2/admin/reset_password`,

  CONFIRM: `${host}v2/admin/confirm_code`,

  SET_PASSWORD: `${host}v2/admin/confirm_password`,
};

const forgotPasswordApi = {
  RESET: (query) => request("POST", URL.RESET, query),
  CONFIRM: (query) => request("PUT", URL.CONFIRM, query),
  SET_PASSWORD: (query) => request("PUT", URL.SET_PASSWORD, query),
};

export default forgotPasswordApi;
