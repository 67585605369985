import { useEffect, useState } from "react";
import { Popconfirm, Space, Tag, Tooltip } from "antd";

import TableComponent from "../../_components/table/TableComponent";
import {
  getColumnDateProps,
  getColumnSearchProps,
  getQueryDate,
  parseDate,
} from "../../config/config";
import TableButtons from "../../_components/table/TableButtons";
import icons from "../../config/icons";
import ButtonComponent from "../../_components/button/ButtonComponent";
import FetchRemoteEpg from "./FetchRemoteEpg";
import { setInLocal, setInSession, getAtSession } from "../../helpers/storages";
import { useTranslation } from "react-i18next";

export default function EpgTable({
  getAgain,
  openDrawer,
  getDataSource,
  handleMenuClick,
  update,
  forceUpdate,
}) {
  const { t } = useTranslation();

  const [dataSource, setDataSource] = useState([]);

  const [loading, setLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);

  const [limit, setLimit] = useState(10);

  const [total, setTotal] = useState(0);

  const [currentPage, setCurrentPage] = useState(getAtSession("epg")?.page || 1);

  const [sort, setSort] = useState(getAtSession("epg")?.sort || ["id", "DESC"]);

  const [date, setDate] = useState(null);

  const [search, setSearch] = useState(
    getAtSession("epg")?.search || {
      name: null,
    }
  );

  const columns = [
    {
      title: "#",
      width: 60,
      dataIndex: `id`,
      align: "center",
      fixed: "left",
      key: "index",
      render: (record, text, index) => {
        return limit * (currentPage - 1) + index + 1;
      },
    },

    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
      align: "center",
      defaultFilteredValue: getAtSession("epg")?.search?.name,
      filteredValue: getAtSession("epg")?.search?.name,
      ...getColumnSearchProps(),
    },

    {
      title: t("status"),
      dataIndex: "status",
      key: "status",
      align: "center",
      render: (text, record) =>
        record.status?.toString() == "1" ? (
          <Tag color="red">{t("unpublished")}</Tag>
        ) : (
          <Tag color="green">{t("published")}</Tag>
        ),
    },

    {
      title: t("url"),
      dataIndex: "url",
      key: "url",
      align: "center",
      ellipsis: {
        showTitle: false,
      },

      render: (text) => (
        <Tooltip placement="topLeft" title={text}>
          {text}
        </Tooltip>
      ),
    },

    {
      title: t("type"),
      dataIndex: "type",
      key: "type",
      align: "center",
      render: (text, record) => (record.epg_type ? record.epg_type?.name : "N/A"),
    },

    {
      title: t("created_at"),
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      width: 140,

      render: (text, record, index) => parseDate(text),

      ...getColumnDateProps(setDate),
    },

    {
      title: "",
      key: "operation",
      fixed: "right",
      width: 60,
      align: "center",

      render: (text, record, index) => (
        <TableButtons
          handleMenuClick={(e) => handleMenuClick(e, record)}
          items={[
            {
              key: "edit",
              name: t("edit"),
              icon: icons.EDIT,
            },

            record.status
              ? {
                  key: "publish",
                  name: t("publish"),
                  icon: icons.PUBLISH,
                  question: t("publish_message"),
                  onConfirm: (e) => {
                    handleMenuClick(e, record);
                  },
                }
              : {
                  key: "unpublish",
                  name: t("unpublish"),
                  icon: icons.UNPUBLISH,
                  question: t("unpublish_message"),
                  onConfirm: (e) => {
                    handleMenuClick(e, record);
                  },
                },

            {
              key: "delete",
              name: t("delete"),
              icon: icons.DELETE,
              question: t("delete_message"),
              onConfirm: (e) => {
                handleMenuClick(e, record);
              },
            },
          ]}
        />
      ),
    },
  ];

  const handleTableChange = (data) => {
    setCurrentPage(data.page);
    setLimit(data.limit);
    setSort(data.sort);

    setInLocal("pageLimit", data.limit);
    setInSession("epg", {
      ...getAtSession("epg"),
      page: data.page,
      limit: data.limit,
      sort: data.sort,
      // search: data?.search,
    });

    setSearch(data?.search);
  };

  useEffect(() => {
    const fetchLiveTvData = () => {
      setLoading(true);

      const query = {
        sort,
        limit,
        page: currentPage,
      };

      if (search.name) {
        query.search = {
          name: search.name,
        };
      }

      if (search.categories) {
        query.filter = {
          categoryId: search.categories,
        };
      }

      function onSuccess(data) {
        setLoading(false);
        if (data?.rows.length > 0 || search.name) {
          setDataSource(data?.rows);
          setIsEmpty(false);
        } else {
          setIsEmpty(true);
        }
        setTotal(data?.count);

        const maxPage = Math.ceil(data?.count / limit);
        const storedPage = getAtSession("epg")?.page || 1;

        if (storedPage > maxPage || storedPage < 1) {
          setInSession("epg", {
            ...getAtSession("epg"),
            page: 1,
          });
          setCurrentPage(1);
        } else {
          setInSession("epg", {
            ...getAtSession("epg"),
            page: currentPage,
          });
        }
      }

      function onError(data) {
        setLoading(false);
      }

      getDataSource(query, onSuccess, onError);
    };

    const timeout = setTimeout(() => {
      if (getAtSession("epg") && getAtSession("epg")?.page > currentPage) {
        setInSession("epg", {
          ...getAtSession("epg"),
          page: 1,
        });
        setCurrentPage(getAtSession("epg")?.page);
      }

      fetchLiveTvData();
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [limit, currentPage, sort, search, getAgain, date]);

  return (
    <TableComponent
      header={
        <>
          <Space>
            <FetchRemoteEpg />
            <Popconfirm
              placement="topRight"
              title={t("update_message")}
              onConfirm={update}
              okText={t("update")}
              cancelText={t("cancel")}
            >
              <ButtonComponent title={t("update")} />
            </Popconfirm>

            <Popconfirm
              placement="topRight"
              title={t("force_update_message")}
              onConfirm={forceUpdate}
              okText={t("force_update")}
              cancelText={t("cancel")}
            >
              <ButtonComponent title={t("force_update")} />
            </Popconfirm>

            <ButtonComponent
              icon={icons.ADD}
              title={`${t("add")} ${t("epg")}`}
              onClick={openDrawer}
            />
          </Space>
        </>
      }
      isLoading={loading}
      dataSource={dataSource}
      onChange={handleTableChange}
      columns={columns}
      localeClick={openDrawer}
      isEmpty={isEmpty}
      rowClassName={(row) => {
        if (row.archive) return "red-row";
      }}
      pagination={{
        page: currentPage,
        limit: limit,
        total: total,
      }}
    />
  );
}
