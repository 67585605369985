export default function createSubscriptionData(
    values,
    vodService,
    liveTvService,
    editable,
    force,
    permissions
) {
    const body = {
        name: values.name,
        description: values.description,
        price: values.price,
        device_service: values.device_service,
        stripe_plan_id: values.stripe_plan_id,
        paypal_plan_id: values.paypal_plan_id,
        apple_subscription_id: values.apple_subscription_id,
        google_subscription_id: values.google_subscription_id,

        archive_service: values.archive_service,
        scheduling_time: values.scheduling_time,
        scheduling_type: values.scheduling_type,
        payment_type: values.payment_type,
    };

    if (permissions?.payments?.authorize) {
        body.is_supported_authorize = values.is_supported_authorize;
    }

    if (permissions?.Vod_Manager) {
        if (force) {
            vodService.force = true;
        }

        body.vod_service = JSON.stringify(vodService);
    }

    if (permissions?.LIVE_TV) {
        if (force) {
            liveTvService.force = true;
        }

        body.channel_service = JSON.stringify(liveTvService);
    }

    if (editable) {
        body.id = editable.id;
    }

    return body;
}
