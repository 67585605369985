import { Typography, Switch } from "antd";

import { useEffect, useState } from "react";

import icons from "../../../../../../config/icons";
import { parseDate } from "../../../../../../config/config";

import TableButtons from "../../../../../../_components/table/TableButtons";
import TableComponent from "../../../../../../_components/table/TableComponent";
import ButtonComponent from "../../../../../../_components/button/ButtonComponent";
import { useTranslation } from "react-i18next";

export default function PlaylistsTable({
  user,
  getAgain,
  onDefault,
  openDrawer,
  getDataSource,
  handleMenuClick,
}) {
  const { t } = useTranslation();
  const [dataSource, setDataSource] = useState([]);

  const [loading, setLoading] = useState(false);

  const columns = [
    {
      title: "#",
      width: 60,
      dataIndex: `id`,
      align: "center",
      key: "index",
      render: (record, text, index) => {
        return index + 1;
      },
    },

    {
      title: t("default"),
      dataIndex: "is_default",
      align: "center",
      key: "is_default",
      width: 100,
      render: (text, record, index) => {
        return (
          <Switch
            size="small"
            checked={record.is_default}
            onChange={(e) => onDefault(e, record)}
          />
        );
      },
    },

    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
      align: "center",
    },

    {
      title: t("user"),
      dataIndex: "sub_user",
      key: "sub_user",
      align: "center",

      render: (text, record, index) => {
        return record.sub_user == null
          ? user?.name + " " + user?.surname
          : record?.sub_user?.name;
      },
    },

    {
      title: t("url"),
      dataIndex: "url",
      key: "url",
      align: "center",
      width: 200,
      render: (text, record, index) => {
        return (
          <Typography.Paragraph
            ellipsis={true}
            style={{ margin: 0, width: 200 }}
            copyable={{
              tooltips: false,
            }}
          >
            {record.url}
          </Typography.Paragraph>
        );
      },
    },
    {
      title: t("created_at"),
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",

      render: (text, record, index) => {
        return parseDate(record.createdAt);
      },
    },

    {
      title: "",
      key: "operation",
      fixed: "right",
      width: 60,
      align: "center",

      render: (text, record, index) => (
        <TableButtons
          handleMenuClick={(e) => handleMenuClick(e, record)}
          items={[
            {
              key: "edit",
              name: t("edit"),
              icon: icons.EDIT,
            },

            {
              key: "delete",
              name: t("delete"),
              icon: icons.DELETE,
              question: t("play_list_delete_message"),
              onConfirm: (e) => {
                handleMenuClick(e, record);
              },
            },
          ]}
        />
      ),
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(true);

      function onSuccess(data) {
        setLoading(false);
        setDataSource(data);
      }

      function onError(data) {
        setLoading(false);
      }

      getDataSource({ id: user.id }, onSuccess, onError);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [getAgain]);

  return (
    <TableComponent
      header={
        <>
          <ButtonComponent
            icon={icons.ADD}
            title={t("add_playlist")}
            onClick={openDrawer}
          />
        </>
      }
      isLoading={loading}
      dataSource={dataSource}
      columns={columns}
      user={user}
      localeClick={openDrawer}
    />
  );
}
