import React from "react";
import "../css/multiRangeSlider.css";

const MultiRangeSlider = ({ start, end, credits, duration, style }) => {
    const dirationSeconds = duration * 60;

    return (
        <div className="container-range-wrap" style={style}>
            <div className="container-range">
                <div
                    className="multi-range-slider__thumb"
                    style={{
                        left: `${Math.round((start / dirationSeconds) * 1000) / 10}%`,
                    }}
                ></div>

                <div
                    className="multi-range-slider__thumb"
                    style={{
                        left: `${Math.round((end / dirationSeconds) * 1000) / 10}%`,
                    }}
                ></div>

                <div
                    className="multi-range-slider__thumb"
                    style={{
                        left: `${Math.round((credits / dirationSeconds) * 1000) / 10}%`,
                    }}
                ></div>
            </div>
        </div>
    );
};

export default MultiRangeSlider;
