import request from "../../../api/request";
import { ap_host } from "../../../helpers/appHost";
import adsInstance from "../api/instace";

// const host = ap_host;
const host = "https://ads.inorain.tv/";

const URL = {
  GETTER: `${host}admin/clients/getter`,

  GET: `${host}admin/clients`,

  CLIENT: `${host}admin/clients`,
};

const clientApi = {
  GET: (query) => adsInstance.get(URL.GET, { params: query }),
  GETTER: (query) => adsInstance.get(URL.GETTER, { params: query }),
  ADD: (query) => adsInstance.post(URL.CLIENT, query),
  EDIT: (query, id) => adsInstance.put(`${URL.CLIENT}/${id}`, query),
  DELETE: (query) =>
    adsInstance.delete(`${URL.CLIENT}/many`, {
      data: {
        ids: query,
      },
    }),
};

export default clientApi;
