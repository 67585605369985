import { Spin } from "antd";

export default function Loading({ style, size = "default" }) {
  return (
    <div
      style={{
        width: "100%",
        height: "50%",
        background: "rgba(0, 0, 0, 0.05)",
        borderRadius: "4px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        ...style,
      }}
    >
      <Spin size={size} />
    </div>
  );
}
