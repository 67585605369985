import { useEffect, useState } from "react";

import { Drawer, Form, Input, notification, Radio } from "antd";

import { useEpgTypesOptions } from "../../hooks/selectOptions";

import ButtonComponent from "../../_components/ButtonComponent";

import REQUESTS from "../../api/requests";
import { useTranslation } from "react-i18next";

export default function EpgDrawer({ onClose, open, editable, getData }) {
  const { t } = useTranslation();

  const epgTypesOptions = useEpgTypesOptions("id");

  const [isLoading, setIsLoading] = useState(false);

  const [form] = Form.useForm();

  const onFinish = (values) => {
    setIsLoading(true);

    const body = {
      name: values.name,
      epgTypeId: values.type,
      url: values.url,
    };

    if (editable) {
      body.epgId = editable.id;

      REQUESTS.EPG.EDIT(body)
        .then((response) => {
          setIsLoading(false);

          if (response.error) {
            notification.error({
              description: response.message,
            });

            return;
          }

          getData();
          onClose();
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } else {
      REQUESTS.EPG.ADD(body)
        .then((response) => {
          setIsLoading(false);

          if (response.error) {
            notification.error({
              message: t("error"),
              description: response.message,
            });

            return;
          }
          getData();
          onClose();
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (!open) {
      form.resetFields();
    }

    if (editable) {
      form.setFields([
        { name: "name", value: editable.name },
        { name: "type", value: editable.epgTypeId },
        { name: "url", value: editable.url },
      ]);
    }
  }, [open, editable]);

  return (
    <Drawer
      title={`${editable ? t("edit") : t("add")} ${t("epg")}`}
      placement="right"
      onClose={onClose}
      open={open}
    >
      <Form
        form={form}
        name="epg"
        layout="vertical"
        onFinish={onFinish}
        // onKeyPress={(e) => {
        //   if (e.key === "Enter") {
        //     form.submit();
        //   }
        // }}
        initialValues={{
          type: epgTypesOptions[0]?.value,
        }}
      >
        <Form.Item label={t("epg_type")} name="type" style={{ height: 50 }}>
          <Radio.Group buttonStyle="solid">
            {epgTypesOptions.map((item) => (
              <Radio value={item.value} key={item.id}>
                {item.name}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>

        <Form.Item
          label={t("name")}
          name="name"
          rules={[
            {
              required: true,
              message: t("input_message", { name: t("name") }),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t("url")}
          name="url"
          rules={[
            {
              required: true,
              message: t("input_message", { name: t("url") }),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item style={{ textAlign: "center" }}>
          <ButtonComponent
            title={t("save")}
            actionType="save"
            isLoading={isLoading}
            onClick={() => form.submit()}
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
}
