import { Image } from "antd";
import { parseDate } from "../../../helpers/formats";
import ICONS from "../../../helpers/icons";
import style from "./_messages.module.scss";

export default function Message({ image, name, surname, message, admin }) {
    const returnContent = () => {
        switch (message.type) {
            case "text":
                return <p>{message.content}</p>;

            case "image":
                return <Image width={100} src={message.content} />;

            case "video":
                return <video width={300} src={message.content} controls />;

            case "application":
                return (
                    <a href={message.content} download>
                        File
                    </a>
                );

            default:
                break;
        }
    };

    return (
        <div className={`${style["message"]} ${admin && style["admin"]}`}>
            <div className={style["image"]}>
                <img src={image} />
            </div>

            <div className={style["message-block"]}>
                <div className={style["header"]}>
                    <p>
                        {name} {surname && ` ${surname}`}
                    </p>
                    <span>{message?.createdAt && parseDate(message.createdAt)}</span>
                </div>

                <div className={style["body"]}>{returnContent()}</div>
                {admin && (
                    <div className={`${style["is-seen"]} ${message?.is_read && style["is-read"]}`}>
                        {ICONS.CHECK}
                    </div>
                )}
            </div>
        </div>
    );
}
