import request from "../../../../api/request";
import { appHost } from "../../../../helpers/appHost";
import { getToken } from "../../../../server/requests";

const host = appHost;

const URL = {
  CHANNELS: `${host}v2/admin/transcoder/channels`,
  PLAY_PAUSE: `${host}transcoder/channels/`,
  ADD_TO_ARCHIVE: `${host}admin/channels/update/${getToken()}`,
};

const transcoderTranscodingApi = {
  GET: (query) => request("GET", URL.CHANNELS, query),

  ADD: (query) => request("POST", URL.CHANNELS, query),

  EDIT: (query) => request("PUT", URL.CHANNELS, query),

  DELETE: (query) => request("DELETE", URL.CHANNELS, query),

  PLAY: (query) => request("POST", URL.PLAY_PAUSE + "play", query),

  PAUSE: (query) => request("POST", URL.PLAY_PAUSE + "pause", query),

  ADD_TO_ARCHIVE: (query) => request("POST", URL.ADD_TO_ARCHIVE, query),
};

export default transcoderTranscodingApi;
