import { useState } from "react";
import { useNavigate } from "react-router-dom";

import REQUESTS from "../../../api/requests";

import { Modal, notification } from "antd";
import LiveTvCategoryDrawer from "./LiveTvCategoryDrawer";
import PageComponent from "../../../_components/page/PageComponent";
import LavieTvCategoriesTable from "./LiveTvCategoriesTable";
import { useTranslation } from "react-i18next";

export default function LiveTvCategoriesPage() {
  const { t } = useTranslation();

  const navigate = useNavigate();
  const [getAgain, setGetAgain] = useState(false);

  const [isOpenDrawer, setIsOpenDrawer] = useState(false);

  const [editable, setEditable] = useState(null);

  const getCategories = (query, onSuccess, onError) => {
    try {
      REQUESTS.LIVE_TV.CATEGORIES.GET({ query: JSON.stringify(query) })
        .then((response) => {
          if (!response.message) {
            notification.error({
              message: t("error"),
              description: t("something_wrong"),
            });
          } else {
            onSuccess(response.message);
          }
        })
        .catch((error) => {
          notification.error({
            message: t("error"),
            description: t("something_wrong"),
          });
          onError(error);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const onChangePosition = (position, id) => {
    REQUESTS.LIVE_TV.CATEGORIES.POSITION({ position, id, force: false })
      .then((response) => {
        if (response.error) {
          Modal.confirm({
            title: t("change_position"),
            content: (
              <div>
                {/* <p>
                  In {position} position already exists <b>{response.message.name}</b>{" "}
                  category. We can switch their positions. Do you want to do it?
                </p> */}
                <p>
                  {t("position_already_exists", {
                    position,
                    name: response.message.name,
                  })}
                </p>
              </div>
            ),
            okText: t("change"),
            onOk() {
              REQUESTS.LIVE_TV.CATEGORIES.POSITION({
                position,
                id: id,
                force: true,
              })
                .then((response) => {
                  notification.success({
                    message: t("success"),
                    description: t("position_change_success"),
                  });

                  setGetAgain((prev) => !prev);
                })
                .catch((error) => {
                  notification.error({
                    message: t("error"),
                    description: error.message,
                  });
                });
            },
            onCancel() {
              setGetAgain((prev) => !prev);
            },
          });
        } else {
          notification.success({
            message: t("success"),
            description: t("position_change_success"),
          });

          setGetAgain((prev) => !prev);
        }
      })
      .catch((error) => {
        notification.error({
          message: t("error"),
          description: error.message,
        });
      });
  };

  const handleMenuClick = (event, item) => {
    switch (event.key) {
      case "edit":
        setEditable(item);
        setIsOpenDrawer(true);
        break;

      case "archive":
        REQUESTS.LIVE_TV.CATEGORIES.ARCHIVE_RESTORE({ archive: true, id: item.id })
          .then((response) => {
            if (!response.error) {
              notification.success({
                message: t("success"),
                description: response.message,
              });

              setGetAgain((prev) => !prev);
            }
          })
          .catch((error) => {
            notification.error({
              message: t("error"),
              description: error,
            });
          });
        break;

      case "restore":
        REQUESTS.LIVE_TV.CATEGORIES.ARCHIVE_RESTORE({ archive: false, id: item.id })
          .then((response) => {
            if (!response.error) {
              notification.success({
                message: t("success"),
                description: response.message,
              });

              setGetAgain((prev) => !prev);
            }
          })
          .catch((error) => {
            notification.error({
              message: t("error"),
              description: error,
            });
          });
        break;

      case "delete":
        REQUESTS.LIVE_TV.CATEGORIES.DELETE({ id: item.id })
          .then((response) => {
            if (response.error) {
              notification.error({
                message: t("error"),
                description: response.message,
              });

              return;
            }

            notification.success({
              message: t("success"),

              description: response.message,
            });
            setGetAgain((prev) => !prev);
          })
          .catch((error) => {
            notification.error({
              message: t("error"),

              description: error,
            });
          });
        break;

      case "protect":
      case "unprotect":
        const body = {
          id: item.id,
          is_protected: event.key === "protect" ? true : false,
        };

        REQUESTS.LIVE_TV.CATEGORIES.PROTECTED(body)
          .then((response) => {
            if (response.error) {
              notification.error({
                message: t("error"),

                description: response.message,
              });

              return;
            }
            notification.success({
              message: t("success"),
              description: response.message,
            });

            setGetAgain((prev) => !prev);
          })
          .catch((error) => {
            notification.error({
              message: t("error"),

              description: error,
            });
          });

        break;

      default:
        break;
    }
  };

  const handleBulkDelete = (ids) => {
    REQUESTS.LIVE_TV.CATEGORIES.DELETE_BULK({ ids })
      .then((response) => {
        if (!response.error) {
          notification.success({
            message: t("success"),

            description: response.message,
          });
          setGetAgain((prev) => !prev);
        }
      })
      .catch((error) => {
        notification.error({
          message: t("error"),

          description: error,
        });
      });
  };

  return (
    <PageComponent routes={[t("live_tv"), t("categories")]}>
      <LavieTvCategoriesTable
        getAgain={getAgain}
        getDataSource={getCategories}
        handleMenuClick={handleMenuClick}
        onChangePosition={onChangePosition}
        openDrawer={() => setIsOpenDrawer(true)}
        handleBulkDelete={handleBulkDelete}
      />

      <LiveTvCategoryDrawer
        open={isOpenDrawer}
        editable={editable}
        getData={() => setGetAgain((prev) => !prev)}
        onClose={() => {
          setIsOpenDrawer(false);
          setEditable(null);
        }}
      />
    </PageComponent>
  );
}
