import { useEffect, useState } from "react";
import { Form, Input, notification } from "antd";

import REQUESTS from "../../../api/requests";

import PageComponent from "../../../_components/page/PageComponent";
import ButtonComponent from "../../../_components/ButtonComponent";
import { useTranslation } from "react-i18next";

export default function StoreLinksPage({}) {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const [isDisabled, setIsDisabled] = useState(true);

  const [form] = Form.useForm();

  const onFinish = (values) => {
    setIsLoading(true);
    setIsDisabled(true);

    const body = {
      app_store_url: values.app_store_url,
      play_market_url: values.play_market_url,
      amazon_fire: values.amazon_fire,
      foxxum_etc: values.foxxum_etc,
      huawei: values.huawei,
      tizen: values.tizen,
      roku: values.roku,
      webos: values.webos,
    };

    REQUESTS.STORE_LINKS.UPDATE(body)
      .then((response) => {
        setIsLoading(false);

        if (response.error) {
          notification.error({
            message: t("error"),
            description: response.message,
          });

          return;
        }

        notification.success({
          message: t("success"),
          description: response.message,
        });

        getData();
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const getData = () => {
    setIsDisabled(true);
    try {
      REQUESTS.STORE_LINKS.GET()
        .then((response) => {
          setIsDisabled(false);
          if (!response.error) {
            const {
              app_store_url,
              play_market_url,
              amazon_fire,
              foxxum_etc,
              huawei,
              tizen,
              roku,
              webos,
            } = response.message;

            form.setFields([
              { name: "app_store_url", value: app_store_url },
              { name: "play_market_url", value: play_market_url },
              { name: "amazon_fire", value: amazon_fire },
              { name: "foxxum_etc", value: foxxum_etc },
              { name: "huawei", value: huawei },
              { name: "tizen", value: tizen },
              { name: "roku", value: roku },
              { name: "webos", value: webos },
            ]);
          }
        })
        .catch((err) => {
          setIsDisabled(false);
          console.log(err);
        });
    } catch (e) {
      setIsDisabled(false);

      console.log(e);
    }
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <PageComponent routes={[t("settings"), t("stor_link")]}>
      <Form
        form={form}
        name="store-links"
        layout="vertical"
        onFinish={onFinish}
        // onKeyPress={(e) => {
        //     if (e.key === "Enter") {
        //         form.submit();
        //     }
        // }}
        disabled={isDisabled}
      >
        <div className="grid-input ">
          <Form.Item label={t("app_stor_url")} name="app_store_url">
            <Input />
          </Form.Item>

          <Form.Item label={t("play_market_url")} name="play_market_url">
            <Input />
          </Form.Item>

          <Form.Item label={t("amazon_fire_tv")} name="amazon_fire">
            <Input />
          </Form.Item>

          <Form.Item label={t("foxxum_etc")} name="foxxum_etc">
            <Input />
          </Form.Item>

          <Form.Item label={t("huawei")} name="huawei">
            <Input />
          </Form.Item>

          <Form.Item label={t("tizen")} name="tizen">
            <Input />
          </Form.Item>

          <Form.Item label={t("roku")} name="roku">
            <Input />
          </Form.Item>

          <Form.Item label={t("webos")} name="webos">
            <Input />
          </Form.Item>
        </div>

        <Form.Item>
          <ButtonComponent
            title={t("save")}
            actionType="save"
            isLoading={isLoading}
            onClick={() => form.submit()}
          />
        </Form.Item>
      </Form>
    </PageComponent>
  );
}
