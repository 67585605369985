import { useEffect, useState } from "react";
import { Divider, Select, Space, Tabs } from "antd";

import REQUESTS from "../../../api/requests";

import ImagesTab from "./tabs/ImagesTab";
import InformaitonTab from "./tabs/InformationTab";
import PageComponent from "../../../_components/page/PageComponent";
import DocumentsTab from "./tabs/DocumwntsTab";
import { useTranslation } from "react-i18next";

export default function BasicInfoPage({}) {
  const { t } = useTranslation();

  const [data, setData] = useState([]);

  const [languageOptions, setLanguageOptions] = useState([]);

  const [selectedLanguage, setSelectedLanguage] = useState(null);

  const [selectedData, setSelectedData] = useState(null);

  const getData = (again) => {
    try {
      REQUESTS.APPLICATION.BASIC_INFO.GET()
        .then((response) => {
          if (response.message) {
            if (!again) {
              let languages = response.message.map((item) => {
                return {
                  label: item.language.name,
                  value: item.language.id,
                  iso_code: item.language.iso_code,
                };
              });

              setLanguageOptions(languages);

              const eng = languages.find((item) => item.iso_code === "en");

              setSelectedLanguage(eng.value);
            }

            setData(response.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (data.length > 0) {
      const selected = data.find((item) => item.language.id === selectedLanguage);

      setSelectedData(selected);
    }
  }, [selectedLanguage]);

  const tabs = [
    {
      // label: "Informaiton",
      label: t("information"),
      key: "informaiton",
      children: <InformaitonTab data={selectedData} getData={getData} />,
    },

    {
      // label: "Images",
      label: t("images"),
      key: "images",
      children: <ImagesTab data={selectedData} getData={getData} />,
    },

    {
      // label: "Documents",
      label: t("documents"),
      key: "documents",
      children: <DocumentsTab data={selectedData} getData={getData} />,
    },
  ];

  return (
    <PageComponent routes={[t("application"), t("basic_info")]}>
      <Space>
        <span>{t("language")}</span>
        <Select
          style={{ width: "200px" }}
          options={languageOptions}
          value={selectedLanguage}
          onChange={setSelectedLanguage}
        />
      </Space>

      <Divider />

      <Tabs tabPosition="left" items={tabs} />
    </PageComponent>
  );
}
