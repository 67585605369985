import React, { useState, useEffect } from "react";
import {
  Drawer,
  Form,
  Input,
  Button,
  message,
  Divider,
  Select,
  notification,
} from "antd";
import TextareaComponent from "../../../../../../components/TextareaComponent";
import { useLanguagesOptions } from "../../../../../../hooks/selectOptions";
import ButtonComponent from "../../../../../../_components/button/ButtonComponent";
import icons from "../../../../../../config/icons";
import { useTranslation } from "react-i18next";

const AddSeasonDrawer = ({
  show,
  close,
  addOrUpdateSeason,
  isEditMode,
  initialValues,
  selectedSeasonId,
  getTranslation,
  translations,
  addSeasonTranslation,
}) => {
  const { t } = useTranslation();

  const languagesOptions = useLanguagesOptions();

  const [form] = Form.useForm();
  const [translationForm] = Form.useForm();

  const [loading, setLoading] = useState(false);
  const [translationAddLoading, setTranslationAddLoading] = useState(false);
  const [translationLanguage, setTranslationLanguage] = useState();

  const handleSubmit = async (values) => {
    const body = {
      ...values,
    };
    try {
      setLoading(true);
      const type = isEditMode ? "put" : "post";
      await addOrUpdateSeason(body, type);
      message.success(isEditMode ? t("season_update_message") : t("season_add_message"));
      close();
    } catch (error) {
      console.error("Error occurred:", error);
      message.error(isEditMode ? t("season_update_failed") : t("season_add_failed"));
    } finally {
      setLoading(false);
    }
  };

  const submitTranslation = (values) => {
    const body = {
      id: selectedSeasonId,
      languageId: translationLanguage,
      model: "season",
      name: values.translation_name,
      description: values.translation_description,
    };
    setTranslationAddLoading(true);

    const success = (data) => {
      setTranslationAddLoading(false);
      notification.success({
        message: t("translation_added"),
      });
    };

    const error = (error) => {
      console.log(error);
      notification.error({
        message: t("something_wrong"),
      });
      setTranslationAddLoading(false);
    };

    addSeasonTranslation(body, success, error);
  };

  useEffect(() => {
    if (isEditMode) {
      const value = initialValues?.find((value) => value.id === selectedSeasonId);
      form.setFieldsValue({
        name: value?.name,
        number: value?.number,
        description: value?.description,
      });
    }
    getTranslation(selectedSeasonId, "season");
  }, [isEditMode, initialValues, form, selectedSeasonId]);

  useEffect(() => {
    const languageId = translationForm?.getFieldValue("languageId");

    if (!languageId) {
      const eng = languagesOptions.find((item) => item.iso_code === "en");
      translationForm.setFieldsValue({
        languageId: eng?.id,
      });
      setTranslationLanguage(eng?.id);
    }

    const newSeasonTranslation = translations?.find(
      (item) => item.id == translationLanguage
    );

    if (newSeasonTranslation?.season_translation) {
      translationForm.setFields([
        {
          name: "translation_name",
          value: newSeasonTranslation.season_translation.name || "",
        },
        {
          name: "translation_description",
          value: newSeasonTranslation.season_translation.description || "",
        },
      ]);
    } else {
      translationForm.setFields([
        {
          name: "translation_name",
          value: "",
        },
        {
          name: "translation_description",
          value: "",
        },
      ]);
    }
  }, [translationLanguage, translationForm, translations]);

  return (
    <Drawer
      title={isEditMode ? t("edit_season") : t("add_season")}
      open={show}
      destroyOnClose
      onClose={() => {
        form.resetFields();
        translationForm.resetFields();
        close();
      }}
      width={500}
      footer={
        <div
          style={{
            textAlign: "right",
            display: "flex",
            justifyContent: "flex-end",
            gap: "10px",
          }}
        >
          <Button onClick={close}>{t("cancel")}</Button>
          <Button type="primary" onClick={() => form.submit()} loading={loading}>
            {isEditMode ? t("save") : t("add")}
          </Button>
        </div>
      }
    >
      <Form id="seasonForm" form={form} onFinish={handleSubmit} layout="vertical">
        <Form.Item
          name="name"
          label={t("name")}
          rules={[{ required: true, message: t("name_message") }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="number"
          label={t("season_number")}
          rules={[{ required: true, message: t("season_number_message") }]}
        >
          <Input type="number" />
        </Form.Item>

        <Form.Item name="description" label={t("description")}>
          <TextareaComponent />
        </Form.Item>
      </Form>

      {isEditMode && (
        <>
          <Divider orientation="left">{t("translations")}</Divider>
          <Form form={translationForm} onFinish={submitTranslation} layout="vertical">
            <Form.Item name="languageId" label={t("language")}>
              <Select
                showSearch
                options={languagesOptions}
                onChange={(e) => {
                  setTranslationLanguage(e);
                }}
                filterOption={(input, option) =>
                  (option?.label?.toLowerCase() ?? "").includes(
                    input?.toLocaleLowerCase()
                  )
                }
                filterSort={(optionA, optionB) =>
                  (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
                }
              />
            </Form.Item>
            <Form.Item name="translation_name" label={t("name")}>
              <Input />
            </Form.Item>
            <Form.Item name="translation_description" label={t("description")}>
              <TextareaComponent />
            </Form.Item>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <ButtonComponent
                title={t("save_translation")}
                icon={icons.CHECK}
                isLoading={translationAddLoading}
                onClick={() => {
                  translationForm.submit();
                }}
              />
            </div>
          </Form>
        </>
      )}
    </Drawer>
  );
};

export default AddSeasonDrawer;
