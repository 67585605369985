import { useState } from "react";

import GpuDrawer from "./GpuDrawer";

import styles from "./_server.module.scss";
import { notification } from "antd";
import { useTranslation } from "react-i18next";

export default function GpuInfo({ gpu }) {
  const [currentGpu, setCurrentGpu] = useState(null);

  const { t } = useTranslation();

  return (
    <div>
      {gpu && gpu?.length > 1 ? (
        <div className={styles["gpu-list"]}>
          {gpu?.map((item, key) => {
            return (
              <div key={key} className={styles["gpu"]}>
                <div
                  className={styles["gpu-button"]}
                  onClick={() => {
                    if (item?.process_info?.length) {
                      setCurrentGpu(item);
                    } else {
                      notification.error({
                        message: t("info_not_available"),
                      });
                      setCurrentGpu(null);
                    }
                  }}
                >
                  <i className="fas fa-info-circle" />
                </div>

                <div className={styles["temperature"]}>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: '<i class="fa fa-thermometer-empty"></i>',
                    }}
                  />

                  {item?.temperature?.gpu_temp}
                </div>

                <p className={styles["memory"]}>{t("memory")}</p>

                <p className={styles["mib"]}>
                  {item?.fb_memory_usage?.total + "/" + item?.fb_memory_usage?.used}
                </p>
              </div>
            );
          })}
        </div>
      ) : (
        <div className={styles["single-gpu"]}>
          <div
            className={styles["gpu-button"]}
            onClick={() => {
              if (
                gpu &&
                gpu[0]?.fb_memory_usage?.total &&
                gpu[0]?.fb_memory_usage?.used
              ) {
                setCurrentGpu(gpu[0]);
              } else {
                notification.error({
                  message: t("info_not_available"),
                });
                setCurrentGpu(null);
              }
            }}
          >
            <i className="fas fa-info-circle" />
          </div>

          <div className={styles["temperature"]}>
            <div
              dangerouslySetInnerHTML={{
                __html: '<i class="fa fa-thermometer-empty"></i>',
              }}
            />
            {gpu && gpu[0]?.temperature?.gpu_temp}
          </div>

          <p className={styles["memory"]}>{t("memory")}</p>

          <p className={styles["mib"]}>
            {gpu && gpu[0]?.fb_memory_usage?.total && gpu[0]?.fb_memory_usage?.used
              ? gpu[0]?.fb_memory_usage?.total + "/" + gpu[0]?.fb_memory_usage?.used
              : "N/A"}
          </p>
        </div>
      )}

      <GpuDrawer
        gpu={currentGpu}
        open={currentGpu ? true : false}
        onClose={() => setCurrentGpu(null)}
      />
    </div>
  );
}
