import request from "../../../api/request";
import { DEMO_API_HOST } from "../../../config/statics";
import { appHost } from "../../../helpers/appHost";

const host = appHost;

const URL = {
  SERVERS: `${host}v2/admin/transcoder/server`,
};

const transcodersServersApi = {
  GET: (query) => request("GET", URL.SERVERS, query),

  DELETE: (query) => request("DELETE", URL.SERVERS, query),
};

export default transcodersServersApi;
