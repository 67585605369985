import dayjs from "dayjs";

import { useEffect, useState } from "react";

import { DatePicker, Select, Space, TimePicker } from "antd";

const { Option } = Select;

const PickerWithType = ({ onChange }) => {
    const [type, setType] = useState("year");

    const [value, setValue] = useState(dayjs(new Date()));

    const onChangeValue = () => {
        let response = null;

        setValue(value);

        const day = value.$D;
        const month = value.$M;
        const year = value.$y;

        switch (type) {
            case "hour":
                response = {
                    type: "hour",
                    day,
                    month,
                    year,
                };
                break;

            case "day":
                response = {
                    type: "day",
                    month,
                    year,
                };
                break;

            case "month":
                response = {
                    type: "month",
                    year,
                };
                break;

            case "year":
                response = {
                    type: "year",
                    year,
                };
                break;

            default:
                break;
        }

        if (response) onChange(response);
    };

    const returnPicker = () => {
        switch (type) {
            case "hour":
                return <DatePicker value={value} style={{ width: 180 }} onChange={setValue} />;

            case "day":
                return <DatePicker value={value} picker="month" onChange={setValue} />;

            case "month":
                return <DatePicker value={value} picker="year" onChange={setValue} />;

            case "year":
                return null;

            default:
                break;
        }
    };

    useEffect(() => {
        onChangeValue();
    }, [value, type]);

    return (
        <Space>
            <Select value={type} onChange={setType} style={{ width: 100 }}>
                <Option value="hour">Hour</Option>
                <Option value="day">Day</Option>
                <Option value="month">Month</Option>
                <Option value="year">Year</Option>
            </Select>

            {returnPicker()}
        </Space>
    );
};

export default PickerWithType;
