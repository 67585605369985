import { appHost } from "../../../helpers/appHost";
import request from "../../request";

// const host = `${appHost || "DEFAULT_REACT_API_HOST"}`;
const host = appHost;

const URL = {
  COUNTRY: `${host}movie/country`,

  DEVICES: `${host}admin/devices/`,

  CURRENCY: `${host}admin/currency`,

  WIDGETS: `${host}admin/widgets`,
};

export default {
  COUNTRIES: {
    GET: (query) => request("GET", URL.COUNTRY, query),
  },

  CURRENCY: () => {
    const query = {
      limit: 1000,
    };

    return request("GET", URL.CURRENCY, { query: JSON.stringify(query) });
  },

  DEVICES: {
    GET: (query) => request("POST", `${URL.DEVICES}count`, query),
  },

  WIDGETS: {
    GET: (query) => request("GET", URL.WIDGETS, query),

    ENABLE: (query) => request("PUT", `${URL.WIDGETS}/enable`, query),

    DISABLE: (query) => request("PUT", `${URL.WIDGETS}/disable`, query),
  },
};
