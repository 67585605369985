import { Space, Tag, Tooltip, Popconfirm } from "antd";
import { useEffect, useState } from "react";

import icons from "../../../config/icons";

import TableImage from "../../../_components/table/TableImage";
import TableButtons from "../../../_components/table/TableButtons";
import TableComponent from "../../../_components/table/TableComponent";
import { useCatchUpServersOptions } from "../../../hooks/selectOptions";
import {
  getAtLocal,
  getAtSession,
  setInLocal,
  setInSession,
} from "../../../helpers/storages";
import { asc_desc } from "../../../helpers/ascDesc";
import ButtonComponent from "../../../_components/button/ButtonComponent";
import { useTranslation } from "react-i18next";

export default function CatchUpRecordingsTable({
  getAgain,
  getDataSource,
  handleMenuClick,
}) {
  const { t } = useTranslation();

  const [dataSource, setDataSource] = useState([]);

  const [limit, setLimit] = useState(getAtLocal("catch-upRecordings") || 10);

  const [currentPage, setCurrentPage] = useState(
    getAtSession("catch-upRecordings")?.page || 1
  );

  const [sort, setSort] = useState(
    getAtSession("catch-upRecordings")?.sort || ["id", "DESC"]
  );

  const [total, setTotal] = useState(0);

  const [search, setSearch] = useState(getAtSession("catch-upRecordings")?.search || {});

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const serverOptions = useCatchUpServersOptions("text");
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const columns = [
    {
      title: "#",
      width: 60,
      dataIndex: `id`,
      align: "center",
      fixed: "left",
      key: "index",
      render: (record, text, index) => {
        return limit * (currentPage - 1) + index + 1;
      },
    },

    {
      title: t("status"),
      defaultSortOrder:
        getAtSession("catch-upRecordings")?.sort?.[0] === "status" &&
        asc_desc(getAtSession("catch-upRecordings")?.sort?.[1]),
      dataIndex: "status",
      key: "status",
      align: "center",
      width: 120,
      sorter: true,
      render: (text, record, index) => {
        return record.status === 1 ? (
          <Tag color="green">{t("active")}</Tag>
        ) : (
          <Tag color="volcano"> {t("errored")} </Tag>
        );
      },
    },

    {
      title: "Id",
      defaultSortOrder:
        getAtSession("catch-upRecordings")?.sort?.[0] === "id" &&
        asc_desc(getAtSession("catch-upRecordings")?.sort?.[1]),
      dataIndex: "id",
      key: "id",
      align: "center",
      sorter: true,
    },

    {
      title: t("name"),
      dataIndex: "name",
      key: "name",

      align: "center",
      width: 200,
      ellipsis: true,
      render: (text, record, index) => {
        return (
          <Space>
            <TableImage src={record.channel?.image} />
            <span>{record.channel?.name}</span>
          </Space>
        );
      },
    },

    {
      title: t("server"),
      defaultFilteredValue: getAtSession("catch-upRecordings")?.search?.archiverId,
      dataIndex: "archiverId",
      key: "archiverId",
      align: "center",

      filters: serverOptions,
      filterMultiple: false,
      width: 200,
      render: (text, record, index) => {
        return record.archiver ? record.archiver?.name : "N/A";
      },
    },

    {
      title: t("url"),
      dataIndex: "url",
      key: "url",
      align: "center",
      width: 200,
      ellipsis: true,
      render: (text, record, index) => {
        return (
          <Tooltip placement="topLeft" title={text}>
            {text}
          </Tooltip>
        );
      },
    },

    {
      title: t("day"),
      defaultSortOrder:
        getAtSession("catch-upRecordings")?.sort?.[0] === "archiveDays" &&
        asc_desc(getAtSession("catch-upRecordings")?.sort?.[1]),
      dataIndex: "archiveDays",
      key: "archiveDays",
      align: "center",
      sorter: true,
    },

    {
      title: "",
      key: "operation",
      fixed: "right",
      width: 60,
      align: "center",

      render: (text, record, index) => (
        <TableButtons
          handleMenuClick={(e) => handleMenuClick(e, record)}
          items={[
            {
              key: "delete",
              name: t("delete"),
              icon: icons.DELETE,
              question: t("delete_record_message"),
              onConfirm: (e) => {
                handleMenuClick(e, record);
              },
            },
          ]}
        />
      ),
    },
  ];

  const handleTableChange = (data) => {
    const catchUpRecordings = {
      page: data.page,
      search: data.search,
    };
    if (data.sort) {
      catchUpRecordings.sort = [`${data.sort[0]}`, `${data.sort[1]}`];
    }
    setInLocal("pageLimit_catch-upRecordings", data.limit);
    setInSession("catch-upRecordings", catchUpRecordings);

    setCurrentPage(data.page);
    setLimit(data.limit);
    setSort(data.sort);

    setSearch(data.search);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleActionClick = (action) => {
    const ids = selectedRowKeys.join(",");
    handleMenuClick({ key: action }, { id: ids });
  };

  useEffect(() => {
    const fetchLiveTvData = () => {
      // setLoading(true);

      const query = {
        // sort,
        limit,
        page: currentPage,
      };

      if (sort && sort[1] && sort[1] !== "undefined") {
        query.sort = sort;
      } else {
        query.sort = ["id", "DESC"];
      }

      if (search.server) {
        query.search = {
          server: search.server[0],
        };
      }

      function onSuccess(data) {
        // setLoading(false);
        setDataSource(data?.rows);
        setTotal(data?.count);

        const maxPage = Math.ceil(data?.count / limit);
        const storedPage = getAtSession("catch-upRecordings")?.page || 1;

        if (storedPage > maxPage || storedPage < 1) {
          setInSession("catch-upRecordings", {
            ...getAtSession("catch-upRecordings"),
            page: 1,
          });
          setCurrentPage(1);
        } else {
          setInSession("catch-upRecordings", {
            ...getAtSession("catch-upRecordings"),
            page: currentPage,
          });
        }
      }

      function onError(err) {
        // setLoading(false);
        console.log(err);
      }

      getDataSource(query, onSuccess, onError);
    };

    const timeout = setTimeout(() => {
      if (
        getAtSession("catch-upRecordings") &&
        getAtSession("catch-upRecordings")?.page > currentPage
      ) {
        setInSession("catch-upRecordings", {
          ...getAtSession("catch-upRecordings"),
          page: 1,
        });
        setCurrentPage(getAtSession("catch-upRecordings")?.page);
      }

      fetchLiveTvData();
      setShowConfirmModal(false);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [limit, currentPage, sort, getAgain]);

  return (
    <TableComponent
      header={
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {selectedRowKeys.length > 0 && (
            <>
              <ButtonComponent
                title={t("delete")}
                type="default"
                icon={icons.DELETE}
                onClick={() => setShowConfirmModal(true)}
              />
              <Popconfirm
                open={showConfirmModal}
                placement="topRight"
                title={t("delete_record_message")}
                onConfirm={() => handleActionClick("delete")}
                onCancel={() => {
                  setSelectedRowKeys([]);
                  setShowConfirmModal(false);
                }}
              />
            </>
          )}
        </div>
      }
      rowSelection={rowSelection}
      dataSource={dataSource}
      onChange={handleTableChange}
      columns={columns}
      pagination={{
        page: currentPage,
        limit: limit,
        total: total,
      }}
      scroll={{
        x: 1100,
      }}
    />
  );
}
