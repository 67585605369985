import { useState } from "react";

import { Form, Input, notification } from "antd";

import REQUESTS from "../../../api/requests";

import ButtonComponent from "../../../_components/ButtonComponent";
import { useTranslation } from "react-i18next";

export default function NewTranslation({ getData }) {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const [form] = Form.useForm();

  const onFinish = (values) => {
    setIsLoading(true);

    const body = {
      key: values.key,
    };

    REQUESTS.APPLICATION.TRANSLATIONS.ADD(body)
      .then((response) => {
        setIsLoading(false);
        if (!response.error) {
          notification.success({
            message: t("success"),
            description: t("new_translation_added"),
          });

          getData();

          form.resetFields();
        } else {
          notification.error({
            message: t("error"),
            description: response.message,
          });
        }
      })
      .catch((error) => {
        notification.error({
          message: t("error"),
          description: error,
        });

        setIsLoading(false);
      });
  };

  return (
    <Form
      form={form}
      name="add-translation"
      layout="inline"
      onFinish={onFinish}
      //   onKeyPress={(e) => {
      //     if (e.key === "Enter") {
      //       form.submit();
      //     }
      //   }}
    >
      <Form.Item name="key" label={t("new_translation")}>
        <Input />
      </Form.Item>

      <Form.Item style={{ textAlign: "center" }} shouldUpdate noStyle>
        {() => {
          const { key } = form.getFieldsValue();

          return (
            <ButtonComponent
              title={t("save")}
              actionType="save"
              disabled={!key}
              isLoading={isLoading}
              onClick={() => form.submit()}
            />
          );
        }}
      </Form.Item>
    </Form>
  );
}
