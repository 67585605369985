export default function redirectToStepOfTheErrorField(list, setStep) {
  for (let i = 0; i < list.length; i++) {
    switch (list[i].name[0]) {
      case "name":
      case "categories":
      case "poster":
      case "backdrop":
        setStep("1");
        return;

      case "urls":
        setStep("2");
        return;

      case "available_countries":
      case "permission_devices":
        setStep("3");
        return;

      default:
        break;
    }
  }
}
