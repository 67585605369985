import { useState, useEffect } from "react";
import { Form, Button, Select, Input, notification } from "antd";

import REQUESTS from "../../../api/requests";

import style from "./style.module.scss";
import { useTranslation } from "react-i18next";

export default function GeolocationApiTab({ data }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState();

  const [geolocationSettings, setGeolocationSettings] = useState([]);

  const onFinish = (values) => {
    setIsLoading(true);

    const query = {
      id: values.id,
      key: values.key,
    };

    REQUESTS.SETTINGS.GENERAL.GEOLOCATION.UPDATE(query)
      .then((data) => {
        setIsLoading(false);

        if (data.error) {
          notification.error({
            message: t("error"),
            description: data.message,
          });

          return;
        }

        notification.success({
          message: t("success"),
          description: data.message,
        });
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  const getGeolocationData = () => {
    REQUESTS.SETTINGS.GENERAL.GEOLOCATION.GET().then((data) => {
      const settings = data.message.map((item) => {
        if (item.is_enabled) {
          form.setFields([
            {
              name: "id",
              value: item.id,
            },
            {
              name: "key",
              value: item.key,
            },
          ]);
        }

        return {
          id: item.id,
          key: item.key,
          value: item.id,
          label: item.name,
          is_key_required: item.is_key_required,
        };
      });

      setGeolocationSettings(settings);
    });
  };

  const onValuesChange = (changedValues) => {
    if (Object.keys(changedValues)[0] === "id") {
      const finded = geolocationSettings.find((item) => item.id === changedValues.id);

      form.setFields([
        {
          name: "key",
          value: finded.key,
        },
      ]);
    }
  };

  const isKeyRequired = () => {
    const { id } = form.getFieldsValue();

    const finded = geolocationSettings.find((item) => item.id === id);

    return finded?.is_key_required;
  };

  useEffect(() => {
    getGeolocationData();
  }, []);

  return (
    <div className={style["tab"]} style={{ width: 280 }}>
      <p className={style["title"]}>{t("enable_geolocation_restriction")}</p>

      <p className={style["description"]}>
        {t("enable_geolocation_restriction_description")}
      </p>

      <Form
        form={form}
        name="geolocation"
        layout="vertical"
        onFinish={onFinish}
        onValuesChange={onValuesChange}
      >
        <Form.Item
          label="Type"
          shouldUpdate
          name="id"
          rules={[
            {
              required: true,
              message: t("select_anything_message"),
            },
          ]}
        >
          <Select options={geolocationSettings} />
        </Form.Item>

        <Form.Item shouldUpdate noStyle>
          {() => {
            return (
              isKeyRequired() && (
                <Form.Item
                  label="Key"
                  name="key"
                  rules={[
                    {
                      required: true,
                      message: t("input_key_message"),
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              )
            );
          }}
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            onClick={() => form.submit()}
            loading={isLoading}
            style={{ width: "100%" }}
          >
            {t("save")}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
