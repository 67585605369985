import { useState, useEffect } from "react";
import ChartComponent from "../../../../../_components/chart/ChartComponent";
import { Pie } from "@ant-design/plots";
import REQUESTS from "../../../../../api/requests";
import styles from "../../../styles/_charts.module.scss";
import { Empty } from "antd";
import { useTranslation } from "react-i18next";

const FavoriteCountryChart = () => {
  const { t } = useTranslation();

  const [isEmpty, setIsEmpty] = useState(false);

  const [selectedData, setSelectedData] = useState([
    {
      id: 0,
      name: t("all_time"),
      query: {
        // year: new Date().getFullYear(),
        // month: new Date().getMonth() + 1,
        // day: new Date().getDate(),
        // type: "year",
        value: -1,
        // content_id: -1,
        content_type: "channel",
      },
      active: true,
    },
    {
      id: 1,
      name: t("year"),
      query: {
        // year: new Date().getFullYear(),
        // month: new Date().getMonth() + 1,
        // day: new Date().getDate(),
        // type: "month",
        // value: -1,
        // content_id: -1,
        content_type: "channel",
      },
      active: false,
    },
    {
      id: 2,
      name: t("month"),
      query: {
        // year: new Date().getFullYear(),
        // month: new Date().getMonth() + 1,
        // day: new Date().getDate(),
        // type: "day",
        // value: -1,
        // content_id: -1,
        content_type: "channel",
      },
      active: false,
    },
  ]);

  const [dataList, setDataList] = useState({
    appendPadding: 10,
    data: [],
    angleField: "value",
    colorField: "name",
    label: {
      type: "inner",
      content: " ",
    },
    interactions: [
      {
        type: "pie-legend-active",
      },
      {
        type: "element-active",
      },
    ],
  });

  const getFavorites = (query) => {
    REQUESTS.DASHBOARD.FAVORITES_COUNTRY(query)
      .then((response) => {
        if (!response.error) {
          setDataList({
            ...dataList,
            data: response?.message?.count,
          });
        }
      })
      .catch((err) => {
        console.log(`err`, err);
      });
  };

  useEffect(() => {
    getFavorites(selectedData[0].query);
  }, []);

  useEffect(() => {
    !dataList?.data?.length ? setIsEmpty(true) : setIsEmpty(false);
  }, [dataList, t]);

  // const handleSelectTab = (value) => {
  //   const selectActive = selectedData?.filter((item) => {
  //     if (item.name === value.name) {
  //       return {
  //         ...item,
  //         active: true,
  //       };
  //     } else {
  //       return {
  //         ...item,
  //         active: false,
  //       };
  //     }
  //   });
  //   setSelectedData(selectActive);
  //   getFavorites(value.query);
  // };

  const chartHeadName = t("country");

  return (
    <div className={styles["chart-wrapper"]}>
      <ChartComponent
        // title="Favorite by Country"
        title={t("favorite", { name: chartHeadName })}
        children={isEmpty ? <Empty style={{ height: 400 }} /> : <Pie {...dataList} />}
        // radioGroup={selectedData}
        // selectedTab={(item) => handleSelectTab(item)}
      />
    </div>
  );
};

export default FavoriteCountryChart;
