import { useState, useEffect, useContext } from "react";
import { Button, Divider, Form, Input, InputNumber, Select } from "antd";
import REQUESTS from "../../../../../api/requests";
import Container from "../../../../../_components/container/Container";
import NextBackButton from "../../../../../_components/button/NextBackButton";
import icons from "../../../../../config/icons";
import styles from "./_stream.module.scss";
import TranscodingSearch from "../../../../../_components/searches/TranscodingSearch";
import { streamTypeOptions } from "../../../../../config/config";
import VideoComponent from "../../../../../components/VideoComponent";
import { TabContext } from "../../MediaContentPage";
import { useTranslation } from "react-i18next";

export default function StreamStep({
  form,
  onChangeStep,
  mediaType,
  transcodingId,
  originalAudioLAnguage,
}) {
  const { t } = useTranslation();

  const [isOpenTrailerVideo, setIsOpenTrailerVideo] = useState(false);
  const [streamType, setStreamType] = useState("external");
  const [selectedTranscodingId, setSelectedTranscodingId] = useState(null);
  const [urlIsRequired, setUrlIsRequired] = useState(false);
  const [urlValidation, setUrlValidation] = useState(true);

  const [streamData, setStreamData] = useState({
    id: null,
    url: null,
  });

  const { setStreamUrl, streamUrl, trailerUrl, movieStreamType, setValidTranscodingId } =
    useContext(TabContext);

  const getTranscodingForStream = (id) => {
    REQUESTS.VOD.TRANSCODING.GET({
      query: JSON.stringify({
        filter: {
          id,
        },
      }),
    })
      .then((response) => {
        if (response?.rows) {
          const transcoding = response.rows[0];

          form.setFieldsValue({
            transcoding_id: transcoding?.id || transcodingId,
          });

          if (!transcoding?.id && transcodingId) {
            setValidTranscodingId(false);
          } else if (transcoding?.id) {
            setValidTranscodingId(true);
          }

          setStreamData({
            id: transcoding?.id,
            url: transcoding?.stream_url,
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching transcode URL:", error);
      });
  };

  const handleSaveSkipOption = (option) => {
    form.setFieldsValue({ duration: option?.duration || 0 });

    form.setFieldsValue({ credits_time: option.credits });
    form.setFieldsValue({ intro_end_time: option.end });
    form.setFieldsValue({ intro_start_time: option.start });
    form.setFieldsValue({ original: option.stream_type });
  };

  const handleStreamType = (value) => {
    form.setFieldsValue({ stream_type: value });
    setStreamType(value);
  };

  const handleChange = ({ target: { value } }) => {
    const pattern = /^(https?):\/\/[^\s/$.?#].[^\s]*$/;
    const isValid = pattern.test(value);

    setUrlValidation(isValid);

    if (isValid) {
      if (streamType === "external") {
        setStreamData({ ...streamData, url: value });
      }
    }
    setStreamUrl(value);
  };

  useEffect(() => {
    if (
      (streamType === "internal" || movieStreamType === "internal") &&
      selectedTranscodingId
    ) {
      getTranscodingForStream(selectedTranscodingId);
    } else if (streamType === "internal" && transcodingId) {
      getTranscodingForStream(transcodingId);
    }
  }, [streamType, movieStreamType, selectedTranscodingId, transcodingId, trailerUrl]);

  useEffect(() => {
    if (!trailerUrl) {
      setUrlIsRequired(() => false);
    } else {
      setUrlIsRequired(() => true);
    }
  }, [trailerUrl]);

  useEffect(() => {
    setStreamType(movieStreamType);
  }, [movieStreamType]);

  return (
    <div>
      <div className={styles["stream-step"]}>
        <Container>
          <Divider orientation="left" plain>
            <b>{t("stream")}</b>
          </Divider>

          <Form.Item
            label={t("stream_type")}
            name="stream_type"
            rules={[
              {
                required: true,
                message: t("stream_type_message"),
              },
            ]}
          >
            <Select
              options={streamTypeOptions}
              onChange={(value) => handleStreamType(value)}
            />
          </Form.Item>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 10,
            }}
          >
            {streamType === "internal" ? (
              <Form.Item
                label={t("stream_url")}
                name="transcoding_id"
                onChange={handleChange}
                shouldUpdate
                style={{ width: "100%" }}
                rules={[
                  {
                    required: true,
                    message: t("stream_url_message"),
                  },
                ]}
              >
                <TranscodingSearch
                  value={selectedTranscodingId || transcodingId}
                  onChange={(value) => {
                    setSelectedTranscodingId(value);
                  }}
                />
              </Form.Item>
            ) : (
              <Form.Item
                label={t("stream_url")}
                name="stream_url"
                style={{ width: "100%" }}
                rules={[
                  {
                    required: !urlIsRequired,
                    validator: (_, value) => {
                      if (!value && !urlIsRequired) {
                        return Promise.reject(new Error(t("stream_url_message")));
                      }
                      if (!urlValidation && value) {
                        return Promise.reject(new Error(t("valid_url_message")));
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
              >
                <Input onChange={handleChange} />
              </Form.Item>
            )}
            <Button
              type="primary"
              icon={icons.PLAY}
              style={{
                width: "10%",
                marginTop: 5,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={() => setIsOpenTrailerVideo(true)}
              disabled={
                (streamType === "external" && !urlValidation) ||
                (!streamData?.url && !streamUrl)
              }
            />
          </div>

          <div className={styles["times"]}>
            <Form.Item label={t("duration_ms")} name="duration">
              <InputNumber style={{ width: "100%" }} controls={false} />
            </Form.Item>
            <Form.Item label={t("intro_start_ms")} name="intro_start_time">
              <InputNumber style={{ width: "100%" }} controls={false} />
            </Form.Item>
            <Form.Item label={t("intro_end_ms")} name="intro_end_time">
              <InputNumber style={{ width: "100%" }} controls={false} />
            </Form.Item>
            <Form.Item label={t("credit_time_ms")} name="credits_time">
              <InputNumber style={{ width: "100%" }} controls={false} />
            </Form.Item>
          </div>
        </Container>
      </div>

      {isOpenTrailerVideo && (
        <VideoComponent
          url={streamData?.url}
          originalAudioLanguage={originalAudioLAnguage || "en"}
          onClose={() => setIsOpenTrailerVideo(false)}
          showRang={mediaType === "movie"}
          saveSkeepOption={(body) => handleSaveSkipOption(body)}
          creditsTime={form.getFieldValue("credits_time")}
          introEndTime={form.getFieldValue("intro_end_time")}
          introStartTime={form.getFieldValue("intro_start_time")}
          useToken={streamType === "internal"}
        />
      )}

      <Divider />
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <NextBackButton type="back" onClick={() => onChangeStep("back")} />
        <NextBackButton type="next" onClick={() => onChangeStep("next")} />
      </div>
      {/* <AddTranscodeDrawer
        open={showUploadDrawer}
        onClose={() => {
          setShowUploadDrawer(false);
        }}
      /> */}
    </div>
  );
}
