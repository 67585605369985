import React from "react";

export default function MovieStatistic({ name, count, index }) {
    let background;

    switch (index) {
        case 0:
            background = "#328fdc";
            break;

        case 1:
            background = "#39b59c";
            break;

        case 2:
            background = "#b745ff";
            break;

        case 3:
            background = "#3a3849";
            break;

        default:
            break;
    }
    return (
        <div
            style={{
                width: "100%",
                borderRadius: 16,
                padding: "25px 20px",
                background: background,
                color: "white",
                fontWeight: "bold",
                fontSize: 18,
                boxShadow: "1px 4px 5px 0px #00000042",
                maxHeight: 75,
            }}
        >
            <span>{name} </span>

            <span>{count || 0}</span>
        </div>
    );
}
