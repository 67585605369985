import { Alert, Drawer, Form, Input, Select, notification } from "antd";
import { usePaymentsSubscriptionsOptions } from "../../../hooks/selectOptions";
import ButtonComponent from "../../../_components/button/ButtonComponent";
import icons from "../../../config/icons";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const AddMultiUserDrawer = ({ open, onClose, sendValue }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);

  const paymentOPtion = usePaymentsSubscriptionsOptions();

  const handleFinish = (value) => {
    setIsLoading(true);

    const success = (data) => {
      setIsLoading(false);
      const response = data.message;

      const blob = new Blob([response], { type: "text/plain" });

      const downloadLink = document.createElement("a");

      downloadLink.download = "users.csv";

      downloadLink.href = window.URL.createObjectURL(blob);

      downloadLink.click();

      window.URL.revokeObjectURL(downloadLink.href);
      form.resetFields();
      onClose();
    };

    const error = (err) => {
      console.log(err);
      setIsLoading(false);

      notification.error({
        error: t("error"),
        message: t("something_wrong"),
      });
    };

    sendValue(value, success, error);
  };

  return (
    <>
      <Drawer
        title={t("add_user_multiple")}
        open={open}
        onClose={() => {
          onClose();
          form.resetFields();
        }}
      >
        <Form form={form} onFinish={handleFinish} layout="vertical">
          <Form.Item label={t("user_count")} name="user_count">
            <Input type="number" />
          </Form.Item>
          <Form.Item label={t("select_tariff")} name="user_tariff">
            <Select options={paymentOPtion} />
          </Form.Item>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: 10,
            }}
          >
            <ButtonComponent
              title={t("save")}
              icon={icons.CHECK}
              isLoading={isLoading}
              onClick={() => {
                form.submit();
              }}
            />
          </div>
        </Form>
        {isLoading && (
          <div
            style={{
              marginTop: 10,
            }}
          >
            <Alert message={t("not_close_window_message")} type="warning" />
          </div>
        )}
      </Drawer>
    </>
  );
};

export default AddMultiUserDrawer;
