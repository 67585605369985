const IMAGES = {
    FILE: require("../img/file.png"),
    FOLDER: require("../img/folder.png"),
    OPEN_FOLDER: require("../img/open-folder.png"),
    USER: require("../img/user.png"),

    ROTTEN_TOMATOS: require("../img/roten.png"),

    IMDB: require("../img/imdb.png"),

    TMDB: require("../img/tmdb.png"),

    METACRITIC: require("../img/metacritic.png"),
};

export default IMAGES;
