import { Tag, Tooltip } from "antd";
import { useEffect, useState } from "react";
import en from "javascript-time-ago/locale/en";
import JavaScriptTimeAgo from "javascript-time-ago";
import ReactTimeAgo from "react-time-ago";
import icons from "../../../../../../config/icons";
import { parseDate } from "../../../../../../config/config";

import TableComponent from "../../../../../../_components/table/TableComponent";
import TableButtons from "../../../../../../_components/table/TableButtons";
import TableEmpty from "../../../../../../_components/table/TableEmpty";
import { useTranslation } from "react-i18next";

JavaScriptTimeAgo.addLocale(en);

export default function SubusersTable({ getAgain, getDataSource, handleMenuClick }) {
  const { t } = useTranslation();
  const [dataSource, setDataSource] = useState([]);

  const [loading, setLoading] = useState(false);

  const columns = [
    {
      title: "#",
      width: 60,
      dataIndex: `id`,
      align: "center",
      key: "index",
      render: (record, text, index) => {
        return index + 1;
      },
    },

    {
      width: 130,
      title: t("online_offline"),
      dataIndex: `online`,
      align: "center",
      key: "online",
      render: (text, record, index) => {
        // let onlineDate = null;

        // if (record.last_online) {
        //   onlineDate = record.last_online;
        // } else {
        //   onlineDate = parseDate(new Date());
        // }
        return (
          <>
            {record.online ? (
              <Tag color="success">{t("online")}</Tag>
            ) : (
              <Tooltip
                title={() => {
                  return record?.last_online ? (
                    <ReactTimeAgo date={record?.last_online} />
                  ) : (
                    <TableEmpty />
                  );
                }}
                placement="topLeft"
              >
                <Tag color="error">{t("offline")}</Tag>
              </Tooltip>
            )}
          </>
        );
      },
    },

    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
      align: "center",
    },

    {
      title: t("age"),
      dataIndex: "age_restricted",
      key: "age_restricted",
      align: "center",
    },

    // {
    //     title: "Progress",
    //     dataIndex: "progress",
    //     key: "progress",
    //     align: "center",
    // },
    {
      title: t("last_online"),
      dataIndex: "last_online",
      key: "last_online",
      align: "center",
      render: (text, record, index) => {
        return text ? parseDate(text) : <TableEmpty />;
      },
    },

    // {
    //   title: "Level",
    //   dataIndex: "level",
    //   key: "level",
    //   align: "center",
    // },

    {
      title: t("watch"),
      key: "sub_users",
      dataIndex: "sub_users",
      align: "center",
      render: (text, record, index) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "baseline",
            }}
          >
            {record.isLiveWatching ? (
              <div
                style={{
                  backgroundColor: "green",
                  margin: 0,
                  marginRight: 5,
                }}
                className="online-offline"
              />
            ) : (
              <div
                style={{
                  backgroundColor: "#dc3545",
                  margin: 0,
                  marginRight: 5,
                }}
                className="online-offline"
              />
            )}

            {record?.channel?.name}
          </div>
        );
      },
    },

    {
      title: t("created_at"),
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",

      render: (text, record, index) => {
        return parseDate(text);
      },
    },

    {
      title: "",
      key: "operation",
      fixed: "right",
      width: 60,
      align: "center",

      render: (text, record, index) => (
        <TableButtons
          handleMenuClick={(e) => handleMenuClick(e, record)}
          items={[
            {
              key: "edit",
              name: t("edit"),
              icon: icons.EDIT,
            },

            {
              key: "delete",
              name: t("delete"),
              icon: icons.DELETE,
              question: t("delete_categories_message"),
              onConfirm: (e) => {
                handleMenuClick(e, record);
              },
            },
          ]}
        />
      ),
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(true);

      function onSuccess(data) {
        setLoading(false);
        setDataSource(data);
      }

      function onError(data) {
        setLoading(false);
      }

      getDataSource({}, onSuccess, onError);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [getAgain]);

  return <TableComponent isLoading={loading} dataSource={dataSource} columns={columns} />;
}
