import { useEffect, useState } from "react";
import { Button, Popconfirm, notification } from "antd";

import REQUESTS from "../../../api/requests";

import ICONS from "../../../config/icons";
import { getColumnSearchProps } from "../../../config/config";

import NewTranslation from "./NewTranslation";
import EditableTranslation from "./EditableTranslation";

import TableComponent from "../../../_components/table/TableComponent";
import { useTranslation } from "react-i18next";

export default function TranslationsTable({ getDataSource }) {
  const { t } = useTranslation();

  const [dataSource, setDataSource] = useState([]);

  const [loading, setLoading] = useState(false);

  const [limit, setLimit] = useState(10);

  const [currentPage, setCurrentPage] = useState(1);

  const [total, setTotal] = useState(0);

  const [getAgain, setGetAgain] = useState(false);

  const [search, setSearch] = useState({
    key: null,
    translatedWord: null,
  });

  const onDelete = (key) => {
    const body = {
      key,
    };

    REQUESTS.APPLICATION.TRANSLATIONS.DELETE(body)
      .then((response) => {
        if (!response.error) {
          notification.success({
            message: t("success"),
            description: t("translation_delete_success"),
          });

          setGetAgain((prev) => !prev);
        } else {
          notification.error({
            message: t("error"),
            description: response.message,
          });
        }
      })
      .catch((error) => {
        notification.error({
          message: t("error"),

          description: error,
        });
      });
  };

  const columns = [
    {
      title: "#",
      width: 60,
      dataIndex: `id`,
      align: "center",
      key: "index",
      render: (record, text, index) => {
        return limit * (currentPage - 1) + index + 1;
      },
    },

    {
      title: t("key"),
      dataIndex: "key",
      key: "key",
      width: "45%",

      ...getColumnSearchProps(),
    },

    {
      title: t("translation"),
      dataIndex: "translatedWord",
      key: "translatedWord",
      align: "center",
      width: "45%",
      editable: true,
      render: (text, record) => (
        <EditableTranslation
          record={record}
          getData={() => setGetAgain((prev) => !prev)}
        />
      ),
      ...getColumnSearchProps(),
    },

    {
      title: "",
      dataIndex: "operation",
      key: "operation",
      align: "center",
      width: 100,
      render: (text, record, index) => {
        return (
          <Popconfirm
            placement="topRight"
            title={t("delete_word_message")}
            onConfirm={() => onDelete(record.key)}
            okText="Delete"
            cancelText="Cancel"
            okButtonProps={{
              danger: true,
            }}
          >
            <Button
              type="primary"
              icon={ICONS.DELETE}
              size="small"
              danger
              disabled={record.default}
            ></Button>
          </Popconfirm>
        );
      },
    },
  ];

  const handleTableChange = (data) => {
    setCurrentPage(data.page);
    setLimit(data.limit);

    setSearch(data.search);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(true);

      const query = {
        limit,
        page: currentPage,
        sort: ["id", "DESC"],
      };

      if (search.key) {
        query.search = {
          key: search.key[0],
        };
      }

      if (search.translatedWord) {
        query.search = {
          translatedWord: search.translatedWord[0],
        };
      }

      function onSuccess(data) {
        if (data.rows?.length === 0 && currentPage > 1) {
          setCurrentPage((prev) => prev - 1);
          return;
        }

        setLoading(false);
        setDataSource(data.rows);

        setTotal(data?.total);
        setLimit(data?.limit);
        setCurrentPage(data?.page);
      }

      function onError(data) {
        setLoading(false);
      }

      getDataSource(query, onSuccess, onError);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [limit, currentPage, search, getAgain]);

  return (
    <TableComponent
      header={
        <div style={{ width: "100%" }}>
          <NewTranslation getData={() => setGetAgain((prev) => !prev)} />
        </div>
      }
      isLoading={loading}
      dataSource={dataSource}
      onChange={handleTableChange}
      columns={columns}
      pagination={{
        page: currentPage,
        limit: limit,
        total: total,
      }}
    />
  );
}
