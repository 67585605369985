import { useState } from "react";
import { notification } from "antd";
import { useSelector } from "react-redux";

import REQUESTS from "../../../api/requests";

import UsersTable from "./UsersTable";
import UserDrawer from "./UserDrawer";
import LoginDrawer from "./LoginDrawer";
import TariffDrawer from "./TariffDrawer";

import PageComponent from "../../../_components/page/PageComponent";
import AddMultiUserDrawer from "./AddMultiUserDrawer";
import { useTranslation } from "react-i18next";

export default function UsersPage() {
  const { t } = useTranslation();
  const [getAgain, setGetAgain] = useState(false);

  const [editable, setEditable] = useState(null);

  const [isOpenDrawer, setIsOpenDrawer] = useState(false);

  const [isOpenLoginDrawer, setIsOpenLoginDrawer] = useState(false);

  const [userForTariff, setUserForTariff] = useState(null);

  const { permissions } = useSelector((state) => state.globalState);
  const [showMultiUserAdd, setShowMultiUSerAdd] = useState(false);

  const getDataSource = (query, onSuccess, onError) => {
    try {
      REQUESTS.USERS.USERS.GET({ query: JSON.stringify(query) })
        .then((response) => {
          if (!response.message) {
            notification.error({
              message: t("error"),
              description: t("something_wrong"),
            });
          } else {
            onSuccess(response.message);
          }
        })
        .catch((error) => {
          notification.error({
            message: t("error"),
            description: t("something_wrong"),
          });
          onError(error);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const handleMenuClick = (event, item) => {
    switch (event.key) {
      case "edit":
        setEditable(item);
        setIsOpenDrawer(true);
        break;

      case "tariff":
        setUserForTariff(item);
        break;

      case "lock":
        REQUESTS.USERS.USERS.LOCK({ ids: item.id })
          .then((response) => {
            console.log(response);
            if (!response.error) {
              notification.success({
                message: t("success"),
                description: response.message,
              });

              setGetAgain((prev) => !prev);
            }
          })
          .catch((error) => {
            notification.error({
              message: t("error"),

              description: error,
            });
          });

        break;

      case "unlock":
        REQUESTS.USERS.USERS.UNLOCK({ ids: item.id })
          .then((response) => {
            if (!response.error) {
              notification.success({
                message: t("success"),

                description: response.message,
              });

              setGetAgain((prev) => !prev);
            }
          })
          .catch((error) => {
            notification.error({
              message: t("error"),

              description: error,
            });
          });
        break;

      case "archive":
        REQUESTS.USERS.USERS.ARCHIVE({ ids: item.id })
          .then((response) => {
            if (!response.error) {
              notification.success({
                message: t("success"),

                description: response?.message,
              });

              setGetAgain((prev) => !prev);
            } else {
              notification.error({
                message: t("error"),

                description: response.message,
              });
            }
          })
          .catch((error) => {
            notification.error({
              message: t("error"),

              description: error,
            });
          });

        break;

      case "restore":
        REQUESTS.USERS.USERS.RESTORE({ ids: item.id })
          .then((response) => {
            if (!response.error) {
              notification.success({
                message: t("success"),

                description: response.message,
              });

              setGetAgain((prev) => !prev);
            } else {
              notification.error({
                message: t("error"),

                description: response.message,
              });
            }
          })
          .catch((error) => {
            notification.error({
              message: t("error"),

              description: error,
            });
          });

        break;
      default:
        return null;
      // break;
    }
  };

  const addUserMultiple = ({ user_count, user_tariff }, success, error) => {
    const body = {
      count: user_count,
      tariffId: user_tariff,
    };

    REQUESTS.USERS.USERS.ADD_USER_MULTIPLE(JSON.stringify(body))
      .then((res) => {
        success(res);
        notification.success({
          message: t("success"),

          description: t("add_user_message"),
        });
        setGetAgain((prev) => !prev);
      })
      .catch((err) => {
        error(error);
        console.log(err);
      });
  };

  return (
    <PageComponent routes={[t("users"), t("users")]}>
      <UsersTable
        getAgain={getAgain}
        getDataSource={getDataSource}
        handleMenuClick={handleMenuClick}
        openDrawer={() => setIsOpenDrawer(true)}
        openLoginDrawer={() => setIsOpenLoginDrawer(true)}
        openMultiUserDrawer={(value) => {
          console.log(value);
          setShowMultiUSerAdd(value);
        }}
      />

      <TariffDrawer
        open={userForTariff ? true : false}
        user={userForTariff}
        getData={() => setGetAgain((prev) => !prev)}
        onClose={() => {
          setUserForTariff(null);
        }}
      />

      <UserDrawer
        open={isOpenDrawer}
        editable={editable}
        getData={() => setGetAgain((prev) => !prev)}
        onClose={() => {
          setIsOpenDrawer(false);
          setEditable(null);
        }}
      />

      {permissions?.login_with_code && (
        <LoginDrawer
          open={isOpenLoginDrawer}
          onClose={() => setIsOpenLoginDrawer(false)}
          getData={() => setGetAgain((prev) => !prev)}
        />
      )}
      <AddMultiUserDrawer
        open={showMultiUserAdd}
        onClose={() => {
          setShowMultiUSerAdd(false);
        }}
        sendValue={(value, success, error) => {
          addUserMultiple(value, success, error);
        }}
      />
    </PageComponent>
  );
}
