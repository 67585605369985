import { Select } from "antd";
import { useEffect, useState, useCallback } from "react";
import REQUESTS from "../../../../api/requests";
import { getToken } from "../../../../server/requests";
import { useTranslation } from "react-i18next";
import debounce from "lodash/debounce";

export default function SearchEpg({ form, epg_type, defaultValue }) {
  const { t } = useTranslation();

  const [searchTerm, setSearchTerm] = useState("");
  const [epgList, setEpgList] = useState([]);
  const [defaultSelected, setDefaultSelected] = useState(defaultValue);

  const onSearchEpg = useCallback(
    debounce(async (search) => {
      const query = {
        name: search,
        type: epg_type,
        token: getToken(),
      };

      try {
        const response = await REQUESTS.EPG.SEARCH(query);
        const list = response?.message?.map((item) => ({
          label: `${item.name} (${item.channel_id})`,
          value: item.channel_id,
        }));
        setEpgList(list || []);
      } catch (error) {
        setEpgList([]);
      }
    }, 500),
    [epg_type]
  );

  useEffect(() => {
    if (defaultValue !== undefined) {
      setDefaultSelected(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    onSearchEpg(searchTerm);
  }, [searchTerm, onSearchEpg]);

  return (
    <Select
      showSearch
      showArrow={false}
      autoClearSearchValue={false}
      placeholder={t("search_epg_and_select")}
      optionFilterProp="children"
      filterOption={(input, option) =>
        option?.label?.toLowerCase().includes(input.toLowerCase())
      }
      filterSort={(optionA, optionB) =>
        (optionA?.label ?? "").localeCompare(optionB?.label ?? "")
      }
      allowClear
      options={epgList}
      value={defaultSelected}
      onSearch={(value) => setSearchTerm(value)}
      onChange={(value) => {
        form.setFields([{ name: "id_epg", value: value || "" }]);
        setDefaultSelected(value);
      }}
    />
  );
}
