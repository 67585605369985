import React, { useEffect, useState } from "react";

import SearchComponent from "../../../components/SearchComponent";
import Conversation from "./Conversation";

import style from "./_conversation.module.scss";

export default function Conversations({
    conversations,
    activeConversation,
    setActiveConversation,
}) {
    const [user, setUser] = useState("");
    const [filteredConversations, setFilteredConversations] = useState([]);
    const [isEmptyConversations, setIsEmptyConversations] = useState(false);

    const onSearchUser = () => {
        let filtered = conversations.filter((el) =>
            el.sub_user?.name.toLowerCase().includes(user.toLowerCase())
        );

        if (filtered.length > 0) {
            setIsEmptyConversations(false);
        } else {
            setIsEmptyConversations(true);
        }

        setFilteredConversations(filtered);
    };

    useEffect(() => {
        onSearchUser();
    }, [user]);

    useEffect(() => {
        setFilteredConversations(conversations);
        setIsEmptyConversations(false);
    }, [conversations]);

    return (
        <div className={style["conversations-list"]}>
            <div className={style["search"]}>
                <SearchComponent
                    value={user}
                    hideTitle={true}
                    onChange={(e) => setUser(e.target.value)}
                    placeholder="Search user"
                />
            </div>

            <div className={style["list"]}>
                {isEmptyConversations ? (
                    <div style={{ textAlign: "center" }}>List is empty</div>
                ) : (
                    filteredConversations.map((item) => {
                        return (
                            <Conversation
                                key={item.id}
                                item={item}
                                active={activeConversation?.id === item.id}
                                onClick={() => setActiveConversation(item)}
                            />
                        );
                    })
                )}
            </div>
        </div>
    );
}
