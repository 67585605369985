import { useEffect, useState } from "react";

import { Button, Drawer, Form, Input, notification } from "antd";
import REQUESTS from "../../../../../../api/requests";
import icons from "../../../../../../config/icons";
import { useTranslation } from "react-i18next";

export default function NoteItem({ item, getData }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const [description, setDescription] = useState(item.description);

  const [isLoadingDelete, setIsLoadingDelete] = useState(false);
  const onEdit = () => {
    setIsLoading(true);

    const body = {
      id: item?.id,
      description: description,
    };

    REQUESTS.VOD.MEDIA_CONTENT.NOTES.EDIT(body)
      .then((response) => {
        setIsLoading(false);

        if (response.error) {
          notification.error({
            message: t("error"),
            description: response.message,
          });

          return;
        }

        notification.success({
          message: t("success"),
          description: t("note_edit_message"),
        });

        getData();
      })
      .catch((err) => {
        notification.error({
          message: t("error"),

          description: err,
        });

        setIsLoading(false);
      });
  };

  const onDelete = () => {
    setIsLoadingDelete(true);

    REQUESTS.VOD.MEDIA_CONTENT.NOTES.DELETE({ id: item?.id })
      .then((response) => {
        setIsLoadingDelete(false);

        if (response.error) {
          notification.error({
            message: t("error"),

            description: response.message,
          });

          return;
        }

        notification.success({
          message: t("success"),
          description: t("delete_note_message"),
        });

        getData();
      })
      .catch((err) => {
        notification?.error({
          message: t("error"),

          description: err,
        });

        setIsLoadingDelete(false);
      });
  };

  return (
    <div style={{ display: "flex", gap: 10, marginTop: 20 }}>
      <Input value={description} onChange={(e) => setDescription(e.target.value)} />

      <Button
        icon={icons.EDIT}
        style={{
          padding: "10px",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        loading={isLoading}
        onClick={onEdit}
      />

      <Button
        icon={icons.DELETE}
        style={{
          padding: "10px",

          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        loading={isLoadingDelete}
        onClick={onDelete}
      />
    </div>
  );
}
