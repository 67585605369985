import { useSelector } from "react-redux";

import { useEffect, useState } from "react";

import { Drawer, Form, Input, InputNumber, notification, Radio, Select } from "antd";

import REQUESTS from "../../../api/requests";

import initValues from "./initValues";
import createFormValues from "./createFormValues";

import AdsUrl from "./AdsUrl";
import VodSelect from "./VodSelect";
import LiveTvSelect from "./LiveTvSelect";

import ButtonComponent from "../../../_components/ButtonComponent";
import { usePaymentsSubscriptionsOptions } from "../../../hooks/selectOptions";
import { useTranslation } from "react-i18next";

const adsTypeOptions = [
  {
    name: "Video AD",
    value: "custom",
  },
  {
    name: "IMA/VAST",
    value: "IMA",
  },
  {
    name: "Text",
    value: "text",
  },
];

export default function AdsDrawer({ onClose, open, editable, getData, getAgain }) {
  const { t } = useTranslation();

  const { permissions } = useSelector((state) => state.globalState);

  const paymentsSubscriptionsOptions = usePaymentsSubscriptionsOptions();

  const [isLoading, setIsLoading] = useState(false);

  const [type, setType] = useState("custom");

  const [form] = Form.useForm();

  const onFinish = (values) => {
    setIsLoading(true);

    const body = createFormValues({ permissions, values, editable });

    try {
      if (editable) {
        REQUESTS.ADS.EDIT(body)
          .then((response) => {
            setIsLoading(false);

            if (response.error) {
              notification.error({
                description: response.message,
              });

              return;
            }

            onClose();
            getData();
            getAgain();
          })
          .catch((err) => {
            setIsLoading(false);
          });
      } else {
        REQUESTS.ADS.ADD(body)
          .then((response) => {
            setIsLoading(false);

            if (response.error) {
              notification.error({
                description: response.message,
              });

              return;
            }

            onClose();
            getData();
            getAgain();
            // form.resetFields();
          })
          .catch((err) => {
            setIsLoading(false);
          });
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onFieldsChange = (changedFields, allFields) => {
    switch (changedFields[0].name[0]) {
      case "type":
        setType(changedFields[0].value);
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (!open) {
      form.resetFields();
    }

    if (editable && form) {
      setType(editable.type);

      initValues(permissions, form, editable);
    }
  }, [open, editable]);

  return (
    <Drawer
      width={500}
      title={`${editable ? t("edit") : t("add")} ${t("ads")}`}
      placement="right"
      destroyOnClose
      onClose={() => {
        form.resetFields();
        onClose();
      }}
      open={open}
    >
      <Form
        form={form}
        name={t("ads")}
        layout="vertical"
        onFinish={onFinish}
        // onKeyPress={(e) => {
        //   if (e.key === "Enter") {
        //     form.submit();
        //   }
        // }}
        onFieldsChange={onFieldsChange}
        initialValues={{
          type: "custom",
          all_movies: false,
          all_channels: false,
        }}
      >
        <Form.Item label={t("type")} name="type">
          <Radio.Group buttonStyle="solid">
            {adsTypeOptions.map((item) => (
              <Radio key={item.value} value={item.value}>
                {item.name}
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>

        {type === "text" ? (
          <div>
            <Form.Item
              label={t("repeat_counts")}
              name="repeat_counts"
              style={{ width: "100%" }}
              rules={[
                {
                  required: true,
                  message: t("repeat_counts_message"),
                },
              ]}
            >
              <InputNumber controls={false} style={{ width: "100%" }} />
            </Form.Item>

            <Form.Item
              label={t("text")}
              name="content_text"
              rules={[
                {
                  required: true,
                  message: t("text_message"),
                },
              ]}
            >
              <Input.TextArea rows={5} />
            </Form.Item>
          </div>
        ) : (
          <div
            style={{
              marginBottom: 10,
            }}
          >
            <AdsUrl form={form} type={type} editable={editable} />
          </div>
        )}

        {permissions?.Vod_Manager && <VodSelect form={form} />}

        {permissions?.LIVE_TV && <LiveTvSelect form={form} />}

        <Form.Item style={{ textAlign: "center" }}>
          <ButtonComponent
            title={t("save")}
            actionType="save"
            isLoading={isLoading}
            onClick={() => form.submit()}
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
}
