import { useState } from "react";
import { Checkbox, Form, Modal, notification } from "antd";

import REQUESTS from "../../../../api/requests";

import ButtonComponent from "../../../../_components/button/ButtonComponent";

const DeleteContent = ({ id, callback }) => {
  const [isLoading, setIsLoading] = useState(false);

  const [form] = Form.useForm();

  const onFinish = (values) => {
    setIsLoading(true);

    const body = {
      id: id,
      rm_input: values.rm_input,
      rm_output: values.rm_output,
    };
    REQUESTS.VOD.TRANSCODING.DELETE(body)
      .then((response) => {
        setIsLoading(false);

        if (response.error) {
          notification.error({
            description: response.message,
          });

          return;
        }

        Modal.destroyAll();
        callback();
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const onCancel = () => {
    Modal.destroyAll();
  };

  return (
    <Form
      form={form}
      name="live-tv-category"
      layout="vertical"
      onFinish={onFinish}
      // onKeyPress={(e) => {
      //     if (e.key === "Enter") {
      //         form.submit();
      //     }
      // }}
      initialValues={{
        rm_input: false,
        rm_output: false,
      }}
    >
      <div>
        <Form.Item name="rm_input" noStyle valuePropName="checked">
          <Checkbox>Remove input path</Checkbox>
        </Form.Item>

        <br />

        <Form.Item name="rm_output" noStyle valuePropName="checked">
          <Checkbox>Remove output path</Checkbox>
        </Form.Item>
      </div>
      <br />

      <div style={{ display: "flex", justifyContent: "flex-end", gap: 10 }}>
        <ButtonComponent title="Cancel" type="default" onClick={onCancel} />

        <ButtonComponent
          title="Delete"
          danger={true}
          isLoading={isLoading}
          onClick={() => form.submit()}
        />
      </div>
    </Form>
  );
};

export default function confirmDelete(id, callback) {
  Modal.confirm({
    title: "What do you want to delete?",
    content: <DeleteContent id={id} callback={callback} />,
    okText: "Yes",
    footer: false,
    closable: true,
  });
}
