import { useState } from "react";

import { notification } from "antd";

import ImageUpload from "../../../components/ImageUpload";

import ButtonComponent from "../../../_components/button/ButtonComponent";

import REQUESTS from "../../../api/requests";

import styles from "./_user-icons.module.scss";
import { useTranslation } from "react-i18next";

export default function AddUserIcon({ getData }) {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [image, setImage] = useState({
    url: null,
    file: null,
  });

  const onUpload = () => {
    setIsLoading(true);

    const formData = new FormData();

    formData.append("icons", image.file);

    REQUESTS.APPLICATION.USER_ICONS.ADD(formData)
      .then((response) => {
        setIsLoading(false);

        if (response.error) {
          notification.error({
            message: t("error"),
            description: response.message,
          });

          return;
        }

        notification.success({
          message: t("success"),
          description: t("icon_upload_success"),
        });

        getData();
        setImage({
          url: null,
          file: null,
        });
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  return (
    <div className={styles["icon-upload"]}>
      <ImageUpload image={image} setImage={setImage} />

      <ButtonComponent
        title={t("save")}
        isLoading={isLoading}
        disabled={!image.file}
        onClick={onUpload}
      />
    </div>
  );
}
