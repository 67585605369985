import request from "../../../../api/request";
import { appHost } from "../../../../helpers/appHost";

const host = appHost;

const URL = {
  GET_SERVICE: `${host}admin/cloud_service/api`,
};

const usageInfoApi = {
  GET_SERVICE: (query) => request("GET", URL.GET_SERVICE, query),

  PACKAGES_GETTER: (query, api) => request("GET", `${api}/package/getter`, query),

  GET_INFO: (query, api) => request("GET", `${api}/info/server`, query),
};

export default usageInfoApi;
