import { Divider, Form, Select } from "antd";
import REQUESTS from "../../../../../../api/requests";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const Badges = ({ form, movieId }) => {
  const { t } = useTranslation();
  const badgesOption = [
    {
      label: t("coming_soon"),
      value: "coming soon",
    },
    {
      label: "AVOD",
      value: "avod",
    },
    {
      label: "TVOD",
      value: "tvod",
    },
    {
      label: "SVOD",
      value: "svod",
    },
    {
      label: "Premiere",
      value: "premiere",
    },
    {
      label: "Oscar",
      value: "oscar",
    },
  ];

  const handleChange = (value) => {
    form.setFieldsValue({ badges: value || null });
  };

  const getBAdges = (id) => {
    REQUESTS.VOD.MEDIA_CONTENT.BADGES.GET({ movies_id: id })
      .then((res) => {
        const newBadge = res.message?.map((item) => {
          return {
            label: item.name,
            value: item?.name,
          };
        });
        form.setFieldsValue({ badges: newBadge });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getBAdges(movieId);
  }, []);

  return (
    <>
      <Divider orientation="left" plain>
        <b>{t("badges")}</b>
      </Divider>
      <Form.Item label={t("badges")} name="badges">
        <Select
          mode="multiple"
          options={badgesOption}
          placeholder={t("select_badges")}
          showSearch
          onChange={(e) => {
            handleChange(e);
          }}
          filterOption={(input, option) =>
            (option?.label?.toLowerCase() ?? "").includes(input?.toLocaleLowerCase())
          }
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? "")
              .toLowerCase()
              .localeCompare((optionB?.label ?? "").toLowerCase())
          }
        />
      </Form.Item>
    </>
  );
};

export default Badges;
