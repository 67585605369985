import TableComponent from "../table/TableComponent";
import styles from "./_server.module.scss";
import { Drawer } from "antd";
import { useTranslation } from "react-i18next";

const StorageInfoDrawer = ({ open, onClose, info }) => {
  const { t } = useTranslation();

  const tableColumns = [
    {
      title: "#",
      width: 60,
      dataIndex: `id`,
      align: "center",
      fixed: "left",
      key: "index",
      render: (record, text, index) => {
        return index + 1;
      },
    },

    {
      title: t("mount_point"),
      key: "mount",
      dataIndex: "mount",
      align: "center",
    },

    {
      title: t("used"),
      dataIndex: "used",
      key: "used",
      align: "center",
      render: (text) => {
        return text ? `${parseFloat(text).toFixed(0)} GB` : 0;
      },
    },
    {
      title: t("size"),
      dataIndex: "size",
      key: "size",
      align: "center",
      render: (text) => {
        return text ? `${parseFloat(text).toFixed(0)} GB` : 0;
      },
    },
    {
      title: t("used_percentages"),
      dataIndex: "used_percentages",
      key: "used_percentages",
      align: "center",
      render: (text) => {
        return text && text !== "NaN" ? `${parseFloat(text).toFixed(0)} %` : "N/A";
      },
    },
  ];

  return (
    <Drawer
      open={open}
      onClose={() => {
        onClose();
      }}
      destroyOnClose={true}
      width={700}
      title={t("storage_info")}
    >
      <TableComponent columns={tableColumns} dataSource={info} />
    </Drawer>
  );
};

export default StorageInfoDrawer;
