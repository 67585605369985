export const words = {
  en: {
    translation: {
      admin: "Admin",
      about_us: "About us",
      active: "Active",
      action: "Action",
      add: "Add",
      add_ads: "Add ads",
      add_url: "Add URL",
      add_season: "Add season",
      ads: "Ads",
      apple: "Apple",
      advertisements: "Advertisements (AVOD)",
      advertisement: "Advertisement",
      ads_config: "Ads config",
      add_cast: "Add cast",
      add_answer: "Add answer",
      add_category: "Add category",
      add_collection: "Add collection",
      add_collection_movie: "Add Collection movie",
      add_config: "Add config",
      add_content: "Add content",
      add_device: "Add device",
      add_episode: "Add episode",
      add_feature: "Add feature",
      add_filter: "Add filter",
      add_filter_live_tv_message: "Here you can filter and add your live tv",
      add_filter_movie_message: "Here you can filter and add your movies",
      add_package: "Add package",
      add_genre: "Add genre",
      add_keyword: "Add keyword",
      add_language: "Add language",
      add_lesson: "Add lesson",
      add_list: "Add list",
      add_live_tv: "Add live tv",
      add_movie: "Add movie",
      add_new_folder: "Add new folder",
      add_new_user: "Add New User",
      add_playlist: "Add playlist",
      add_provider: "Add provider",
      add_script: "Add script",
      add_series: "Add series",
      add_success: "Added successfully",
      add_to_archive: "Add to archive",
      add_to_streaming: "Add to streaming",
      add_transcoding: "Add transcoding",
      age: "Age",
      age_restriction: "Age restriction",
      age_restriction_message: "Please input age restriction",
      all: "All",
      all_live_tv_selected: "All Live Tv selected",
      all_movie_selected: "All movie selected",
      all_time: "All time",
      amount: "Amount",
      android_ios: "Android/IOS",
      android_mobile: "Android Mobile",
      android_tv: "Android TV",
      api: "API",
      apps: "Apps",
      application: "Application",
      application_radius: "Application radiuses",
      app_id: "App ID",
      app_info_update_message: "Application information updated successfully",
      app_name: "App name",
      app_name_message: "Please input app name",
      app_radiuses: "App radiuses",
      app_stor_url: "App store url",
      archived_chanel_success: "Channel is archived",
      archive: "Archive",
      archiver: "Archiver",
      archive_add_success: "Archive added successfully",
      archive_category_message: "Do you want to archive this category?",
      archive_confirm_message: "Do you want to archive this user?",
      are_you_sure: "Are you sure?",
      are_you_sure: "Are you sure {{action}} this {{name}}?",
      authorize: "Authorize",
      authorize_api_login_key: "Authorize API login key",
      authorize_config_change_message: "Authorize config is changed",
      authorize_transaction_key: "Authorize transaction key",
      auto_renewal: "Auto renewal",
      available: "Available",
      available_countries: "Available countries",
      available_countries_message: "Please input available countries",
      available_devices: "Available devices",
      add_stream: "Add stream",
      add_group: "Add group",
      add_resolution: "Add resolution",
      audio_bitrate: "Audio bitrate",
      add_user: "Add user",
      answer: "Answer",
      amazon_fire_tv: "Amazon Fire TV",
      auto_scroll: "Auto scroll",
      admin_panel_favicon: "Admin panel favicon",
      admin_panel_favicon_description: "Change admin panel favicon",
      admin_panel_logo: "Admin panel logo",
      admin_panel_logo_description: "Change admin panel logo",
      active_icon: "Active icon",
      audio_track: "Audio track",
      address: "Address",
      address_message: "Please input address",
      add_client: "Add client",
      archived: "Archived",
      allowed_countries: "Allowed countries",
      allowed_countries_message: "Please input allowed countries",
      allowed_os: "Allowed OS",
      allowed_os_message: "Please select allowed OS",
      allowed_vod_genres: "Allowed VOD genres",
      allowed_vod_genres_message: "Please select allowed VOD genres",

      allowed_livetv_genres: "Allowed Live TV genres",
      allowed_livetv_genres_message: "Please select allowed Live TV genres",
      allowed_for_kids: "Allowed for kids",
      add_pricing: "Add pricing",
      add_campaign: "Add campaign",
      ad_type: "Ad type",
      ad_phone: "Ad phone",
      ad_email: "Ad email",
      ad_address: "Ad address",
      package_updated_successfully: "Package updated successfully",
      backups: "Backups",
      balance: "Balance",
      backdrop: "Backdrop",
      backdrop_message: "Please choose backdrop",
      badges: "Badges",
      birthday: "Birthday",
      birth_country: "Birth country",
      birth_in: "Birth in",
      blocked: "Blocked",
      blocked_message: "Do you want to activate this device?",
      body: "Body",
      body_message: "Please input body",
      buffer_size: "Buffer size",
      buffer_size_message: "Please input buffer size",
      build_number: "Build number",
      build_number_message: "Please input build number",
      by_country: "by Countries",
      basic_info: "Basic info",
      branding: "Branding",
      back_home: "Back to home",
      background: "Background",
      background_image: "Background image",
      btn_border_radius: "Button border radius",
      balance_message: "Please input balance",
      banner_ads: "Banner ads",
      cancel: "Cancel",
      canceled_ads: "Canceled ads",
      card: "Card",
      cast: "Cast",
      categories: "Categories",
      categories_message: "Please input categories",
      catch_up: "Catch up",
      catchup: "Catchup",
      catchup_disabled: "Catchup is disabled",
      catchup_service: "Catchup Service",
      change: "Change",
      change_position_message: "Do you want to change position",
      change_position_message_with_name:
        "In {position} position already exists <b>{response?.message?.name}</b> live tv. We can switch their positions. Do you want to do it?",
      choose_active_icon: "choose active icon",
      choose_active_icon_message: "Please choose an active icon",
      choose_file: "Choose file",
      choose_file_message: "Please choose file",
      choose_icon_message: "Please choose an icon",
      choose_poster_message: "Please choose poster",
      choose_tariff_for_user: "Choose tariff for user",
      city: "City",
      clear: "Clear",
      clear_all: "Clear all",
      clear_history_message: "Do you want to clear all history?",
      client_id: "Client id",
      client_id_message: "Client id is required",
      client_id_placeholder: "Select client id",
      click_or_drag: "Click or drag file to this area to upload",
      code: "Code",
      code_is_not_valid: "Code is not valid",
      code_message: "Please input code",
      collections: "Collections",
      collection_edit: "Edit collection",
      comment: "Comment",
      coming_soon: "Coming soon",
      company_address: "Company address",
      company_address_message: "Please input company address",
      company_name: "Company name",
      company_name_message: "Please input company name",
      config_is_changed: "Config is changed",
      configs: "Configs",
      connect_date: "Connect date",
      content: "Content",
      content_monetization_type: "Content monetization type",
      content_provider: "Content provider",
      content_question: "Content question",
      created_at: "Created at",
      cpu: "CPU",
      csv_message: "CSV file imported",
      currency: "Currency",
      countries: "Countries",
      collection: "Collection",
      collection_ads: "Collection ads",
      channels: "Channels",
      channel: "Channel",
      contact_person: "Contact person",
      country: "Country",
      country_viewed: "Country viewed",
      contact_email: "Contact email",
      contact_number: "Contact number",
      copy_gift_link: "Copy gift link",
      config_popup_table: "Configure the columns of the table",
      count: "Count",
      count_message: "Please input count",
      create_promo_code: "Create promo code",
      cover: "Cover",
      credit_time_ms: "Credit time (ms)",
      card_border_radius: "Card border radius",
      clients: "Clients",
      connected: "Connected",
      custom_ads: "Custom ads",
      custom_allowed_vods: "Custom allowed VODs",
      custom_allowed_vods_message: "Please select custom allowed VODs",
      custom_allowed_live_tv_channels: "Custom allowed Live TV channels",
      custom_allowed_live_tv_channels_message:
        "Please select custom allowed Live TV channels",
      country_search: "Country search",
      conversion_price: "Conversion price",
      country_code: "Country code",
      campaign: "Campaign",
      campaign_id: "Campaign ID",
      conversion_url: "Conversion URL",
      company_title: "Company title",
      conversation_link: "Conversation link",
      conversation_link_message: "Please input conversation link",
      devices: "Devices",
      description: "Description",
      description_message: "Please enter description",
      data_not_available: "Data not available",
      dashboard: "Dashboard",
      date: "Date",
      date_of_birth: "Date of birth",
      date_of_death: "Date of death",
      day: "Day",
      day_message: "Please input day",
      delete: "Delete",
      delete_cast_message: "Do you want to delete this cast?",
      delete_category_message: "Do you want to delete this category?",
      delete_collection_message: "Do you want to delete this collection?",
      delete_device_message: "Do you want to delete this device?",
      delete_document_message: "Do you want to delete this document?",
      delete_episode_message: "Do you want to delete this episode?",
      delete_gallery_confirm: "Do you want to delete this gallery?",
      delete_genre_message: "Do you want to delete this genre?",
      delete_group_message: "Do you want to delete this group?",
      delete_item_message: "Do you want to delete this item?",
      delete_media_message: "Do you want to delete this media?",
      delete_message: "{{name}} is deleted successfully",
      delete_movie_message: "Do you want to delete selected movies?",
      delete_note_message: "Do you want to delete this note?",
      delete_resolution_message: "Do you want to delete this resolution?",
      delete_script_message: "Do you want to remove this script?",
      delete_selected_message: "Do you want to delete selected content?",
      delete_selected_movie_message: "Do you want to delete selected movies?",
      delete_stream_message: "Do you want to delete this stream?",
      details: "Details",
      device: "Device",
      device_count: "Device count",
      device_count_message: "Please input device count",
      device_id: "Device ID",
      device_message: "Please input device",
      device_platform: "Device platform",
      disable: "Disable",
      disable_all: "Disable all",
      disable_all_content_message: "Do you want to disable all content?",
      disable_selected_message: "Do you want to disable selected content?",
      discount: "Discount",
      dislike: "Dislike",
      document_add_success: "Document added successfully",
      documents: "Documents",
      done: "Done",
      downloading: "Downloading",
      download: "Download",
      duration: "Duration",
      duration_chart: "Duration chart",
      duration_m: "Duration (m)",
      duration_message: "Please input duration",
      duration_type_message: "Please select duration type",
      duration_movie: "Duration movie",
      duration_ms: "Duration (ms)",
      duration_type: "Duration type",
      deathday: "Deathday",
      downloaded: "Downloaded",
      download_csv: "Download CSV",
      delete_selected: "Delete selected",
      delete_promo_code_success: "Promo code deleted successfully",
      delete_selected_live_tv_message: "Do you want to delete selected live tv?",
      disable_selected_live_tv_message: "Do you want to disable selected live tv?",
      disconnected: "Disconnected",
      duration_in_seconds: "Duration in seconds",
      edit: "Edit",
      edit_config: "Edit config",
      edit_episode: "Edit episode",
      edit_season: "Edit season",
      email: "Email",
      email_message: "Please input email",
      email_required_message: "Email is required!",
      email_validation_message: "Please enter a valid email!",
      enable: "Enable",
      enable_all: "Enable all",
      enable_all_content_message: "Do you want to enable all content?",
      enable_selected_message: "Do you want to enable selected content?",
      enabled: "Enabled",
      enter_content: "Enter content",
      enter_email: "Enter your email",
      episode: "Episode",
      epg: "EPG",
      epg_type: "EPG type",
      error: "Error",
      error_count: "Error count",
      errored: "Errored",
      exclude: "Exclude",
      expired_tariff: "Expired tariff",
      expire_date: "Expire date",
      external_backup_urls: "External backup urls",
      encode_audio: "Encode audio",
      enable_selected_live_tv_message: "Do you want to enable selected live tv?",
      enable_geolocation_restriction: "Enable geolocation restriction",
      enable_geolocation_restriction_description:
        "Setup config for geolocation restriction",
      edit_client: "Edit client",
      edit_filter: "Edit filter",
      edit_package: "Edit package",
      enter_pricing_name: "Enter pricing name",
      edit_campaign: "Edit campaign",
      enter_max_budget: "Enter max budget",
      enter_title: "Enter title",
      enter_description: "Enter description",
      end_date_required: "End date is required",
      end_date: "End date",
      earnings: "Earnings",
      earning_by_ad_types: "Earning by ad types",
      earning_by_ad_regions: "Earning by ad regions",
      earning_by_platform: "Earning by platforms",
      earning_by_top_clients: "Earning by top clients",
      earning_by_top_region: "Earning by top region",
      favorite: "Favorite {{name}}",
      fetching_started: "Fetching started",
      fetching_message: "Do you want to start fetching?",
      feature: "Feature",
      feature_remove_message: "Do you want to remove this feature?",
      feature_remove_success: "Feature deleted successfully",
      file: "File",
      file_upload_failed: "File upload failed",
      finished: "Finished",
      flusonic: "Flusonic",
      force_update: "Force Update",
      force_update_message: "Do you want to force update?",
      forgot_password: "Forgot password?",
      fps: "FPS",
      faq: "FAQ",
      fps_message: "Please input fps",
      free: "Free",
      full_view_count: "Full view count",
      foxxum_etc: "Foxxum/ETC",
      file_not_available: "File not available",
      female: "Female",
      favorites: "Favorites",
      full_name: "Full name",
      full_name_message: "Please input full name",
      filters: "Filters",
      filter_id: "Filter id",
      filter_id_required: "Filter id is required",
      gallery: "Gallery",
      gallery_is_deleted: "Gallery is deleted",
      general: "General",
      general_settings: "General settings",
      gender: "Gender",
      genres: "Genres",
      genre: "Genre",
      get_package: "Get package",
      go_home: "Go Home",
      google: "Google",
      google_pay_config_change_message: "Google pay config is changed",
      google_subscription_id: "Google subscription ID",
      group: "Group",
      groups: "Groups",
      group_name: "Group name",
      group_name_message: "Please input group name",
      group_user: "Group users",
      gift_card: "Gift card",
      geolocation_restrictions: "Geolocation restrictions",
      galleries: "Galleries",
      has_archive: "Has archive",
      has_catchup: "Has catchup",
      height: "Height",
      height_message: "Please input height",
      help_page: "Help page",
      help_page_message: "Please input help page",
      home: "Home",
      hook_delete_message: "Do you want to delete this hook?",
      hook_token: "Hook token",
      hook_token_message: "Please input hook token",
      hour: "Hour",
      huawei: "Huawei",
      history: "History",
      highlighted: "Highlighted",
      icon: "Icon",
      icon_ad_success_message: "Icon is added",
      icon_upload_success: "Icon is uploaded successfully",
      image: "Image",
      images: "Images",
      image_message: "Please choose image",
      image_title: "Image title",
      in_app_branding: "In app branding",
      inactive: "Inactive",
      include: "Include",
      info: "Info",
      input: "Input",
      input_key_message: "Please input key",
      input_message: "Please input {{name}}",
      input_time_message: "Please input time",
      input_vector: "Input vector",
      input_vector_message: "Please input input vector",
      intro_end_ms: "Intro end (ms)",
      intro_message: "Please input intro",
      intro_start_ms: "Intro start (ms)",
      ip_address: "IP address",
      is_enabled: "Is enabled",
      is_not_update: "is not updated",
      is_protected: "Is protected",
      import: "Import",
      import_from_csv: "Import from CSV",
      information: "Information",
      info_not_available: "Information not available",
      impression: "Impression",
      impression_price: "Impression price",
      impression_value: "Impression value",
      impression_value_message: "Please input impression value",
      is_running: "Is running",
      keywords: "Keywords",
      keywords_message: "Please input keywords",
      koopbank: "Koop Bank",
      language: "Language",
      language_disable_message: "Do you want to disable this language?",
      language_disable_success: "Language disabled successfully",
      language_enable_success: "Language enabled successfully",
      language_message: "Please input language",
      languages: "Languages",
      last_active: "Last active",
      last_online: "Last online",
      last_update: "Last update",
      launcher: "Launcher",
      launcher_app: "Launcher app",
      level: "Level",
      license_end_date: "License end date",
      like: "Like",
      like_dislike: "Like/Dislike {{name}}",
      live: "Live",
      live_streams_count: "Live streams count",
      live_tv: "Live TV",
      live_tv_channel_update: "Live tv channel is updated",
      live_tv_url_saved: "Live tv urls saved",
      lock: "Lock",
      lock_confirm_message: "Do you want to lock this user?",
      logo: "Logo",
      logo_update_message: "Logo is updated",
      login: "Login",
      login_as_admin: "Login as administrator",
      login_key_message: "Please input login key",
      login_message: "Incorrect username or password",
      login_to_activate: "Login to activate",
      login_with_admin_code: "Login with admin code",
      logout: "Logout",
      logout_message: "Do you want to logout?",
      link_copied: "Link copied",
      live_card_border_radius: "Live card border radius",
      list_item_radius: "List item radius",
      logo_image: "Logo image",
      male: "Male",
      management_panel: "Management panel",
      max_bitrate: "Max bitrate",
      max_bitrate_message: "Please input max bitrate",
      max_duration: "Max duration",
      media_content_add_message: "Media content added successfully",
      media_content_update_message: "Media content updated successfully",
      media_type: "Media type",
      medias: "Medias",
      menu_config: "Menu config",
      menu_config_delete_message: "Do you want to delete this menu config?",
      messages: "Messages",
      message: "Message",
      meta_data: "Meta data",
      mid_rol_ads_interval: "Mid rol ads interval",
      min_bitrate: "Min bitrate",
      min_bitrate_message: "Please input min bitrate",
      mobile_highlight: "Mobile highlight",
      mobile_logo: "Mobile logo",
      monetization: "Monetization",
      month: "Month",
      most_watched_channels: "Most watched channels",
      most_watched_movies: "Most Watched Movies",
      move_group_message:
        "Choose the group where will be moved all users from deleted group or leave it blank if you sure there is no user on deleted group",
      movies: "Movies",
      movie: "Movie",
      movie_encoder: "Movie encoder",
      movie_excluded: "Movie is excluded",
      make_video_public: "Make video public",
      mount_point: "Mount point",
      more_info: "More info",
      mid_role: "Mid role",
      name: "Name",
      name_message: "Please input name",
      memory: "Memory",
      menu_config_delete_success: "Menu config deleted successfully",
      min_version: "Min version",
      max_budget: "Max budget",
      max_budget_required: "Max budget is required",
      max_budget_number: "Max budget must be a number",
      new: "New",
      new_folder: "New folder",
      new_note: "New note",
      new_url: "New URL",
      new_password: "Enter new password",
      new_translation: "New translation",
      new_translation_added: "New translation added successfully",
      news: "News",
      news_add_success: "News added successfully",
      news_remove_message: "Do you want to remove this news?",
      none: "None",
      not_close_window_message: "Please do not close the window",
      not_have_payment: "You don't have any payment!",
      not_have_server_message: "You don't have any server",
      not_protected: "Not protected",
      not_protected_message: "Do you want to make this video not protect?",
      not_server_message: "You don't have any server",
      notes: "Notes",
      notes_message: "Please input notes",
      now_watching: "Now watching",
      number_message: "Please input number",
      new_transcoding: "New transcoding",
      not_shared: "Not shared",
      number: "Number",
      not_archived: "Not archived",
      offline: "Offline",
      online: "Online",
      online_offline: "Online/Offline",
      online_users: "Online users",
      original_audio_language: "Original audio language",
      output: "Output",
      output_type: "Output type",
      output_type_message: "Please select output type",
      package_name: "Package name",
      package_name_message: "Please input package name",
      parental_code: "Parental code",
      part_view_count: "Part view count",
      passphrase: "Passphrase",
      passphrase_message: "Please input passphrase",
      password: "Password",
      password_message: "Please input password",
      path: "Path",
      path_message: "Please input path",
      paused: "Paused",
      paypal: "Paypal",
      paypal_client_id: "Paypal client ID",
      paypal_client_id_message: "Please input paypal client ID",
      paypal_client_secret: "Paypal client secret",
      paypal_client_secret_message: "Please input paypal client secret",
      paypal_config_change: "Paypal config is changed",
      paypal_plan_id: "Paypal plan ID",
      paypal_webhook_id: "Paypal webhook ID",
      paypal_webhook_id_message: "Please input paypal webhook ID",
      payment: "Payment",
      payment_count: "Payment count",
      payment_type: "Payment type",
      payment_type_message: "Please select payment type",
      permissions_list: "Permissions list",
      per_channel_chart: "Per channel chart",
      percent: "Percent",
      platform: "Platform",
      play: "Play",
      play_list_delete_message: "Do you want to delete this playlist?",
      playlist: "Playlist",
      playlists: "Playlists",
      playlist_file: "Playlist file",
      playlist_url: "Playlist url",
      please_sign_in: "Please sign in below.",
      please_wait: "Please wait",
      play_market_url: "Play market url",
      poster: "Poster",
      poster_message: "Please input poster",
      pre_roll_ads_interval: "Pre roll ads interval",
      primary: "Primary",
      primary_color_update_message: "Primary color is updated",
      pricing_message: "Please input pricing",
      pricing_plan_options: "Pricing Plan Options",
      privacy_policy: "Privacy policy",
      profession: "Profession",
      protected: "Is Protected",
      protected_message: "Do you want to make this video protect?",
      publish: "Publish",
      published: "Published",
      published_at: "Published at",
      published_date_message: "Please input published date",
      published_immediately: "Published immediately",
      platforms: "Platforms",
      payments: "Payments",
      provider: "Provider",
      position: "Position",
      protect: "Protect",
      phone_number: "Phone number",
      price: "Price",
      port: "Port",
      phone: "Phone",
      phone_message: "Please input phone",
      promo_codes: "Promo codes",
      promo_code_edited: "Promo code is edited",
      promo_code_deleted: "Promo code is deleted",
      protect_genre_message: "Do you want to protect this genre?",
      primary_color: "Primary color",
      primary_color_description: "Change app primary color",
      pricing_name: "Pricing name",
      pricing_name_required: "Pricing name is required",
      pricing: "Pricing",
      pricing_package_id: "Pricing package ID",
      pricing_package_id_required: "Pricing package ID is required",
      pre_role: "Pre role",
      post_role: "Post role",
      quiz: "Quiz",
      ram: "RAM",
      ratings: "Ratings",
      read: "Read",
      registered: "Registered",
      registered_users: "Registered users",
      registration_date: "Registration date",
      release_date: "Release date",
      release_date_message: "Please select release date",
      rent_price: "Rent price",
      repeat_counts: "Repeat counts",
      repeat_counts_message: "Please input repeat counts",
      reset: "Reset",
      reset_password: "Reset Password",
      resolution: "Resolution",
      resolution_name: "Resolution name",
      resolution_select_message: "Please select resolution",
      restriction: "Restriction",
      restriction_required: "Age restriction is required",
      restriction_monetization: "Restriction & monetization",
      restore: "Restore",
      restore_live_tv_message: "Do you want to restore selected live tv?",
      restore_message: "Do you want to restore this user?",
      role: "Role",
      roku: "Roku",
      regions: "Regions",
      recordings: "Recordings",
      resolutions: "Resolutions",
      recaptcha_verification_message: "Please verify reCAPTCHA",
      save: "Save",

      save_error_message:
        "You can update this content after you will fill app all the required content of the movie or tv show.",
      save_translation: "Save translation",
      scheduled_time_change_message: "Scheduled time es changed",
      scheduling: "Scheduling",
      scheduling_time: "Scheduling time",
      scheduling_time_message: "Please input scheduling time",
      scheduling_type: "Scheduling type",
      script: "Script",
      script_message: "Please input script",
      scripts: "Scripts",
      search_channel_placeholder: "Search channel and select",
      search_epg_and_select: "Search epg and select",
      search_movie_and_select: "Search movie and select",
      search_from_tmdb: "Search from TMDB",
      secondary: "Secondary",
      see_selected_movies: "Here you can see your selected moves",
      select: "Select",
      select_a_value: "Select a value",
      select_an_option: "Select an option",
      select_badges: "Select badges",
      select_channel: "Select channel",
      select_channel_message: "Please select channel",
      select_csv_message: "Please select CSV file",
      select_country: "Select country",
      select_country_message: "Please select country",
      select_device_message: "Please select device",
      select_file: "Select file",
      select_language: "Select language",
      select_live_tv: "Select live tv",
      select_movies: "Select movies",
      select_resolution_price: "Select price for all enabled resolutions",
      select_season: "Select season",
      select_tariff: "Select tariff",
      select_tariff_message: "Please select tariff",
      selected_icon: "Selected icon",
      send: "Send",
      send_message: "Send message",
      series: "Series",
      series_episode: "Series & episode",
      server: "Server",
      server_message: "Please input server",
      servers: "Servers",
      service_account: "Service account",
      service_notification: "Service notification",
      set_collection_type: "Set collection type",
      set_type: "Set type",
      setup_colors_for_application: "Setup colors for application",
      setup_filter: "Setup filter",
      setup_stream: "Setup stream",
      service_name: "Service name",
      size: "Size",
      slider: "Slider",
      smtp_configs: "SMTP config",
      smtp_host: "Smtp host",
      smtp_message: "Please input smtp host",
      something_wrong: "Something went wrong",
      spent: "Spent",
      s_tv: "S-TV",
      started: "Started",
      start: "Start",
      start_logo: "Start logo",
      start_transcoding_message: "Do you want to start transcoding?",
      status: "Status",
      stop: "Stop",
      stop_transcoding_message: "Do you want to stop transcoding?",
      storage: "Storage",
      stor_link: "Store link",
      stream: "Stream",
      streamer: "Streamer",
      streams: "Streams",
      stream_message: "Please input stream",
      stream_type: "Stream type",
      stream_type_message: "Please select stream type",
      streaming_delete_success: "Streaming deleted successfully",
      streaming_pause_success: "Streaming paused successfully",
      streaming_service: "Streaming Service",
      streaming_started_success: "Streaming started successfully",
      stream_url: "Stream url",
      stripe: "Stripe",
      stripe_config_change: "Stripe config is changed",
      stripe_plan_id: "Stripe plan ID",
      stripe_public_key: "stripe public key",
      stripe_public_key_message: "Please input stripe public key",
      stripe_secret_key: "Stripe secret key",
      stripe_secret_key_message: "Please input stripe secret key",
      stripe_webhook_secret: "Stripe webhook secret",
      stripe_webhook_secret_message: "Please input stripe webhook secret",
      subscribers: "Subscribers",
      success: "Success",
      success_purchased_message: "Successfully Purchased Cloud Server !",
      subtitle_track: "Subtitle track",
      support: "Support",
      supported: "Supported",
      subscription: "Subscription",
      settings: "Settings",
      social_auth: "Social auth",
      skip_movie: "Skip movie",
      skip_episodes: "Skip episodes",
      server_name: "Server name",
      subscription_id: "Subscription ID",
      sub_users: "Sub users",
      surname: "Surname",
      social_links: "Social links",
      sort_resolutions: "Sort resolutions",
      state: "State",
      shearing_as_a_gift: "Shearing as a gift",
      selected_live_tv_archive_message: "Do you want to archive selected live tv?",
      share: "Share",
      selected_device: "Selected device",
      selected_all_movies: "Selected all movies",
      selected_all_tv: "Selected all tv",
      show_more: "Show more...",
      storage_info: "Storage info",
      scheduled_restart: "Scheduled restart",
      scheduled_restart_description:
        "Setup time for restarting error stream and transcoding",
      show_georestriced_content: "Show georestriced content",
      show_georestriced_content_description: "Setup config for georestriced content",
      show_all_content: "Show all content",
      sub_user_radius: "Sub user radius",
      season: "Season",
      season_number: "Season number",
      select_type_message: "Please select type",
      secret_key: "Secret key",
      secret_key_message: "Please input secret key",
      secret_key_placeholder: "Enter secret key",
      start_date: "Start date",
      start_date_required: "Start date is required",
      select_start_date: "Select start date",
      select_end_date: "Select end date",
      select_filter_id: "Select filter id",
      select_pricing_package_id: "Select pricing package id",
      splash_screen: "Splash screen",
      splash_screen_ads: "Splash screen ads",
      skipable: "Skipable",
      skip_after_seconds: "Skip after seconds",
      select_campaign: "Select campaign",
      title_message: "Please input title",
      title_message_message: "Please input title message",
      tizen: "Tizen",
      tizen_lg: "Tizen/LG",
      total: "Total",
      top_watched: "Top watched",
      trailers: "Trailers",
      translations: "Translations",
      translation: "Translation",
      translation_added: "Translation added",
      translation_delete_success: "Translation deleted successfully",
      translation_edit_success: "Translation edited successfully",
      translations_updated: "Translations updated",
      transcoding: "Transcoding",
      transcoder: "Transcoder",
      transcoders: "Transcoders",
      transcoding_add_success: "Transcoding added successfully",
      transcoding_deleted_success: "Transcoding deleted successfully",
      transcoding_edit_success: "Transcoding edited successfully",
      transcoding_in_group: "Transcoding in group",
      transcoding_service: "Transcoding Service",
      transcoding_started_success: "Transcoding started successfully",
      transcoding_stopped_success: "Transcoding stopped successfully",
      transcoder_paused: "Transcoder paused",
      transcoder_started: "Transcoder started",
      transcoder_stopped: "Transcoder stopped",
      transcoding_id_not_found: "Internal source with this transcoding id not found",
      tray_later:
        "There was an error while retrieving packages. Please try after few minutes.",
      trailer: "Trailer",
      trailer_url: "Trailer url",
      tv_shows: "TV shows",
      tv_highlight: "TV highlight",
      tvod_payment: "TVOD Payment",
      tvod_pricing: "TVOD Pricing",
      type: "Type",
      tariff: "Tariff",
      tariffs: "Tariffs",
      tariff_created_date: "Tariff created date",
      title: "Title",
      time_shift: "Time shift",
      title_required: "Title is required",
      unprotect_selected_content_message: "Do you want to unprotect selected content?",
      unprotected: "Unprotected",
      unprotected_message: "Do you want to make this video unprotected?",
      update: "Update",
      update_message: "Update is successfully",
      update_url_message: "Urls updated successfully",
      url_placeholder: "Enter URL",
      unlimited_updates: "Unlimited updates",
      unlimited_users: "Unlimited users",
      uploads: "Upload",
      upload_video_file: "Upload video file",
      uptime: "Uptime",
      url: "Url",
      url_message: "Please input url",
      username: "Username",
      username_message: "Please input username",
      user_group: "User group",
      user_group_message: "Please select user group",
      user_icons: "User icons",
      users: "Users",
      user: "User",
      user_type: "User type",
      used: "Used",
      un_used: "Un used",
      updated_date: "Updated date",
      url_after_logout: "Url after logout",
      unspent: "Unspent",
      unprotecd: "Unprotected",
      unprotecd_genre_message: "Do you want to unprotect this genre?",
      used_percentages: "Used percentages",
      updated_successfully: "Updated successfully",
      uploads_logo: "Uploads logo",
      uploads_background: "Uploads background",
      webos: "WebOS",
      video_track: "Video track",
      videos: "Videos",
      view: "View",
      view_list: "View list",
      view_per_movie: "Views per movie",
      views_duration_channel: "Views duration channel",
      views_per_channel: "Views per channel",
      vod: "VOD",
      vod_message: "Please input content id",
      version: "Version",
      version_code: "Version code",
      volume_ads: "Volume ads",
      waiting: "Waiting",
      visible: "Visible",
      video_banner_ads: "Video banner ads",
      wallet_address: "Wallet address",
      web_icon: "Web icon",
      web_page: "Web page",
      web_page_message: "Please input web page",
      welcome: "Welcome to {{name}} Management admin panel.",
      width: "Width",
      watch: "Watch",
      viewed_ads: "Viewed ads",
      welcome_provider:
        "Login to content provider dashboard to review your movies statistics",
      year: "Year",
      yes: "Yes",
      you_have_selected_live_tv: "You have {{selectedTvShowsCount}} selected live tv",
      you_have_selected_movies:
        "You have {{selectedMoviesCount}} selected moves and tv shows",
      you_dont_have: "You still don’t have",
      you_dont_have_mssage:
        "You still don't have any data here. Please hit add button to create it or watch tutorial if required.",
      watch_tutorial: "Watch tutorial",
      xpos_id: "XPOS ID",
      question: "Question",
      others: "Others",
    },
  },

  fr: {
    translation: {
      admin: "Admin",
      about_us: "À propos",
      active: "Actif",
      action: "Action",
      add: "Ajouter",
      add_ads: "Ajouter des publicités",
      add_url: "Ajouter une URL",
      add_season: "Ajouter une saison",
      ads: "Publicités",
      apple: "Apple",

      advertisements: "Publicités (AVOD)",
      advertisement: "Publicité",
      ads_config: "Configuration des publicités",
      add_cast: "Ajouter un casting",
      add_answer: "Ajouter une réponse",
      add_category: "Ajouter une catégorie",
      add_collection: "Ajouter une collection",
      add_collection_movie: "Ajouter un film à la collection",
      add_config: "Ajouter une configuration",
      add_content: "Ajouter du contenu",
      add_device: "Ajouter un appareil",
      add_episode: "Ajouter un épisode",
      add_feature: "Ajouter une fonctionnalité",
      add_filter: "Ajouter un filtre",
      add_filter_live_tv_message:
        "Vous pouvez filtrer et ajouter votre télévision en direct ici",
      add_filter_movie_message: "Vous pouvez filtrer et ajouter vos films ici",
      add_package: "Ajouter un package",
      add_genre: "Ajouter un genre",
      add_keyword: "Ajouter un mot-clé",
      add_language: "Ajouter une langue",
      add_lesson: "Ajouter une leçon",
      add_list: "Ajouter une liste",
      add_live_tv: "Ajouter la télévision en direct",
      add_movie: "Ajouter un film",
      add_new_folder: "Ajouter un nouveau dossier",
      add_new_user: "Ajouter un nouvel utilisateur",
      add_playlist: "Ajouter une playlist",
      add_provider: "Ajouter un fournisseur",
      add_script: "Ajouter un script",
      add_series: "Ajouter une série",
      add_success: "Ajouté avec succès",
      add_to_archive: "Ajouter à l'archive",
      add_to_streaming: "Ajouter à la diffusion",
      add_transcoding: "Ajouter un transcodage",
      age: "Âge",
      age_restriction: "Restriction d'âge",
      age_restriction_message: "Veuillez entrer la restriction d'âge",
      all: "Tout",
      all_live_tv_selected: "Toutes les télévisions en direct sont sélectionnées",
      all_movie_selected: "Tous les films sélectionnés",
      all_time: "Tout le temps",
      amount: "Montant",
      android_ios: "Android/iOS",
      android_mobile: "Mobile Android",
      android_tv: "Android TV",
      apps: "Applications",
      api: "API",
      application: "Application",
      application_radius: "Rayon de l'application",
      app_id: "ID de l'application",
      app_info_update_message:
        "Les informations de l'application ont été mises à jour avec succès",
      app_name: "Nom de l'application",
      app_name_message: "Veuillez entrer le nom de l'application",
      app_radiuses: "Rayons de l'application",
      app_stor_url: "URL du magasin d'applications",
      archived_chanel_success: "Chaîne archivée",
      archive: "Archive",
      // archiver:
      archiver: "Archiver",
      archive_add_success: "Archive ajoutée avec succès",
      archive_category_message: "Voulez-vous archiver cette catégorie?",
      archive_confirm_message: "Voulez-vous archiver cet utilisateur?",
      are_you_sure: "Êtes-vous sûr?",
      are_you_sure: "Êtes-vous sûr de {{action}} ce {{name}}?",
      authorize: "Autoriser",
      authorize_api_login_key: "Clé API de connexion d'autorisation",
      authorize_config_change_message:
        "La configuration de l'autorisation a été modifiée",
      authorize_transaction_key: "Clé de transaction d'autorisation",
      auto_renewal: "Renouvellement automatique",
      available: "Disponible",
      available_countries: "Pays disponibles",
      available_countries_message: "Veuillez entrer les pays disponibles",
      available_devices: "Appareils disponibles",
      add_stream: "Ajouter un flux",
      add_group: "Ajouter un groupe",
      add_resolution: "Ajouter une résolution",
      audio_bitrate: "Débit audio",
      add_user: "Ajouter un utilisateur",
      answer: "Réponse",
      amazon_fire_tv: "Amazon Fire TV",
      auto_scroll: "Défilement automatique",
      admin_panel_favicon: "Favicon du panneau d'administration",
      admin_panel_favicon_description: "Changer l'icône du panneau d'administration",
      admin_panel_logo: "Logo du panneau d'administration",
      admin_panel_logo_description: "Changer le logo du panneau d'administration",
      active_icon: "Icône active",
      audio_track: "Piste audio",
      archived: "Archivé",
      address: "Adresse",
      address_message: "Veuillez entrer l'adresse",
      add_client: "Ajouter un client",
      allowed_countries: "Pays autorisés",
      allowed_countries_message: "Veuillez entrer les pays autorisés",
      allowed_os: "Systèmes d'exploitation autorisés",
      allowed_os_message: "Veuillez entrer les systèmes d'exploitation autorisés",
      allowed_vod_genres: "Genres VOD autorisés",
      allowed_vod_genres_message: "Veuillez entrer les genres VOD autorisés",
      allowed_livetv_genres: "Genres de télévision en direct autor isés",
      allowed_livetv_genres_message:
        "Veuillez entrer les genres de télévision en direct autor isés",
      allowed_for_kids: "Autorisé pour les enfants",
      add_pricing: "Ajouter des tarifs",
      add_campaign: "Ajouter une campagne",
      ad_type: "Type de publicité",
      ad_phone: "Téléphone publicitaire",
      ad_email: "E-mail publicitaire",
      ad_address: "Adresse publicitaire",
      package_updated_successfully: "Package updated successfully",
      backups: "Sauvegardes",
      balance: "Solde",
      backdrop: "Fond",
      backdrop_message: "Veuillez choisir un fond",
      badges: "Badges",
      birthday: "Anniversaire",
      birth_country: "Pays de naissance",
      birth_in: "Né en",
      blocked: "Bloqué",
      blocked_message: "Voulez-vous activer cet appareil?",
      body: "Corps",
      body_message: "Veuillez entrer le corps",
      buffer_size: "Taille du tampon",
      buffer_size_message: "Veuillez entrer la taille du tampon",
      build_number: "Numéro de build",
      build_number_message: "Veuillez entrer le numéro de build",
      by_country: "par pays",
      basic_info: "Informations de base",
      branding: "Marque",
      background: "Arrière-plan",
      background_image: "Image d'arrière-plan",
      btn_border_radius: "Rayon de bordure du bouton",
      balance_message: "Veuillez entrer le solde",
      banner_ads: "Bannières publicitaires",
      back_home: "Retour à l'accueil",
      cancel: "Annuler",
      canceled_ads: "Publicités annulées",
      card: "Carte",
      cast: "Distribution",
      categories: "Catégories",
      categories_message: "Veuillez entrer les catégories",
      catch_up: "Rattraper",
      catchup: "Catchup",
      catchup_disabled: "Catchup est désactivé",
      catchup_service: "Service Catchup",
      change: "Changer",
      change_position_message: "Voulez-vous changer de position",
      change_position_message_with_name:
        "La position {position} contient déjà <b>{response?.message?.name}</b> en direct. Nous pouvons échanger leurs positions. Voulez-vous le faire?",
      choose_active_icon: "choisissez une icône active",
      choose_active_icon_message: "Veuillez choisir une icône active",
      choose_file: "Choisir un fichier",
      choose_file_message: "Veuillez choisir un fichier",
      choose_icon_message: "Veuillez choisir une icône",
      choose_poster_message: "Veuillez choisir une affiche",
      choose_tariff_for_user: "Choisissez le tarif pour l'utilisateur",
      city: "Ville",
      clear: "Effacer",
      clear_all: "Tout effacer",
      clear_history_message: "Voulez-vous effacer tout l'historique?",
      client_id: "ID client",
      client_id_message: "L'ID client est requis",
      client_id_placeholder: "Entrer l'ID client",
      click_or_drag: "Cliquez ou faites glisser un fichier ici pour le télécharger",
      code: "Code",
      code_is_not_valid: "Le code n'est pas valide",
      code_message: "Veuillez entrer le code",
      collections: "Collection",
      collection_edit: "Modifier la collection",
      comment: "Commentaire",
      coming_soon: "À venir",
      company_address: "Adresse de l'entreprise",
      company_address_message: "Veuillez entrer l'adresse de l'entreprise",
      company_name: "Nom de l'entreprise",
      company_name_message: "Veuillez entrer le nom de l'entreprise",
      config_is_changed: "La configuration a été modifiée",
      configs: "Configurations",
      connect_date: "Date de connexion",
      content: "Contenu",
      content_monetization_type: "Type de monétisation du contenu",
      content_provider: "Fournisseur de contenu",
      content_question: "Question de contenu",
      collection: "Collection",
      collection_ads: "Publicités de collection",
      created_at: "Créé à",
      cpu: "Processeur",
      csv_message: "Fichier CSV importé",
      currency: "Devise",
      countries: "Pays",
      channels: "Chaînes",
      channel: "Chaîne",
      contact_person: "Personne de contact",
      country: "Pays",
      country_viewed: "Pays consulté",
      contact_email: "E-mail de contact",
      contact_number: "Numéro de contact",
      copy_gift_link: "Copier le lien du cadeau",
      config_popup_table: "Configurez les colonnes du tableau",

      count: "Compter",
      count_message: "Veuillez entrer le nombre",
      create_promo_code: "Créer un code promo",
      cover: "Couverture",
      credit_time_ms: "Temps de crédit (ms)",
      card_border_radius: "Rayon de bordure de la carte",
      clients: "Clients",
      connected: "Connecté",
      custom_ads: "Publicités personnalisées",
      custom_allowed_vods: "VOD personnalisé autorisé",
      custom_allowed_vods_message: "Veuillez entrer les VOD personnalisés autorisés",
      custom_allowed_live_tv_channels:
        "Chaînes de télévision en direct personnalisées autorisées",
      custom_allowed_live_tv_channels_message:
        "Veuillez entrer les chaînes de télévision en direct personnalisées autorisées",
      country_search: "Recherche par pays",
      conversion_price: "Prix de conversion",
      country_code: "Code pays",
      campaign: "Campagne",
      campaign_id: "ID de campagne",
      conversion_url: "URL de conversion",
      company_title: "Titre de l'entreprise",
      conversation_link: "Lien de conversation",
      conversation_link_message: "Veuillez entrer le lien de conversation",
      devices: "Appareils",
      description: "Description",
      description_message: "Veuillez entrer la description",
      data_not_available: "Données non disponibles",
      dashboard: "Tableau de bord",
      date: "Date",
      date_of_birth: "Date de naissance",
      date_of_death: "Date de décès",
      day: "Jour",
      day_message: "Veuillez entrer le jour",
      delete: "Supprimer",
      delete_cast_message: "Voulez-vous supprimer cette distribution?",
      delete_category_message: "Voulez-vous supprimer cette catégorie?",
      delete_collection_message: "Voulez-vous supprimer cette collection?",
      delete_device_message: "Voulez-vous supprimer cet appareil?",
      delete_document_message: "Voulez-vous supprimer ce document?",
      delete_episode_message: "Voulez-vous supprimer cet épisode?",
      delete_gallery_confirm: "Voulez-vous supprimer cette galerie?",
      delete_genre_message: "Voulez-vous supprimer ce genre?",
      delete_group_message: "Voulez-vous supprimer ce groupe?",
      delete_item_message: "Voulez-vous supprimer cet élément?",
      delete_media_message: "Voulez-vous supprimer ce média?",
      delete_message: "{{name}} a été supprimé avec succès",
      delete_movie_message: "Voulez-vous supprimer les films sélectionnés?",
      delete_note_message: "Voulez-vous supprimer cette note?",
      delete_resolution_message: "Voulez-vous supprimer cette résolution?",
      delete_script_message: "Voulez-vous supprimer ce script?",
      delete_selected_message: "Voulez-vous supprimer le contenu sélectionné?",
      delete_selected_movie_message: "Voulez-vous supprimer les films sélectionnés?",
      delete_stream_message: "Voulez-vous supprimer ce flux?",
      details: "Détails",
      device: "Appareil",
      device_count: "Nombre d'appareils",
      device_count_message: "Veuillez entrer le nombre d'appareils",
      device_id: "ID de l'appareil",
      device_message: "Veuillez entrer l'appareil",
      device_platform: "Plateforme de l'appareil",
      disable: "Désactiver",
      disable_all: "Tout désactiver",
      disable_all_content_message: "Voulez-vous désactiver tout le contenu?",
      disable_selected_message: "Voulez-vous désactiver le contenu sélectionné?",
      discount: "Remise",
      dislike: "Je n'aime pas",
      document_add_success: "Document ajouté avec succès",
      documents: "Documents",
      done: "Fait",
      downloading: "Téléchargement",
      download: "Télécharger",
      duration: "Durée",
      duration_chart: "Graphique de durée",
      duration_m: "Durée (m)",
      duration_message: "Veuillez entrer la durée",
      duration_type_message: "Veuillez entrer le type de durée",
      duration_movie: "Durée du film",
      duration_ms: "Durée (ms)",
      duration_type: "Type de durée",
      deathday: "Jour de décès",
      downloaded: "Téléchargé",
      download_csv: "Télécharger CSV",
      delete_selected: "Supprimer la sélection",
      delete_promo_code_success: "Code promo supprimé avec succès",
      delete_selected_live_tv_message:
        "Voulez-vous supprimer la télévision en direct sélectionnée?",
      disable_selected_live_tv_message:
        "Voulez-vous désactiver la télévision en direct sélectionnée?",
      disconnected: "Déconnecté",
      duration_in_seconds: "Durée en secondes",
      edit: "Modifier",
      edit_config: "Modifier la configuration",
      edit_episode: "Modifier l'épisode",
      edit_season: "Modifier la saison",
      email: "E-mail",
      email_message: "Veuillez entrer un e-mail",
      email_required_message: "L'e-mail est requis",
      email_validation_message: "Veuillez entrer un e-mail valide",
      enable: "Activer",
      enable_all: "Tout activer",
      enable_all_content_message: "Voulez-vous activer tout le contenu?",
      enable_selected_message: "Voulez-vous activer le contenu sélectionné?",
      enabled: "Activé",
      enter_content: "Entrer le contenu",
      enter_email: "Entrez votre e-mail",
      episode: "Épisode",
      epg: "EPG",
      epg_type: "Type EPG",
      error: "Erreur",
      error_count: "Nombre d'erreurs",
      errored: "En erreur",
      exclude: "Exclure",
      expired_tariff: "Tarif expiré",
      expire_date: "Date d'expiration",
      external_backup_urls: "URLs de sauvegarde externes",
      encode_audio: "Encoder l'audio",
      enable_selected_live_tv_message:
        "Voulez-vous activer la télévision en direct sélectionnée?",
      enable_geolocation_restriction: "Activer la restriction de géolocalisation",
      enable_geolocation_restriction_description:
        "Configuration de configuration pour la restriction de géolocalisation",
      edit_client: "Modifier le client",
      edit_filter: "Modifier le filtre",
      edit_package: "Modifier le package",
      enter_pricing_name: "Entrez le nom du tarif",
      edit_campaign: "Modifier la campagne",
      enter_max_budget: "Entrez le budget maximum",
      enter_title: "Entrez le titre",
      enter_description: "Entrez la description",
      end_date_required: "La date de fin est requise",
      end_date: "Date de fin",
      earnings: "Gains",
      earning_by_ad_types: "Gains par types de publicité",
      earning_by_ad_regions: "Gains par régions publicitaires",
      earning_by_platform: "Gains par plateformes",
      earning_by_top_clients: "Gains par les meilleurs clients",
      earning_by_top_regions: "Gains par les meilleures régions",
      favorite: "Favori {{name}}",
      fetching_started: "Récupération démarrée",
      fetching_message: "Voulez-vous démarrer la récupération?",
      feature: "Fonctionnalité",
      feature_remove_message: "Voulez-vous supprimer cette fonctionnalité?",
      feature_remove_success: "Fonctionnalité supprimée avec succès",
      file: "Fichier",
      file_upload_failed: "Échec du téléchargement du fichier",
      finished: "Terminé",
      flusonic: "Flusonic",
      force_update: "Mise à jour forcée",
      force_update_message: "Voulez-vous forcer la mise à jour?",
      forgot_password: "Mot de passe oublié?",
      fps: "FPS",
      faq: "FAQ",
      fps_message: "Veuillez entrer le FPS",
      free: "Gratuit",
      full_view_count: "Nombre de vues complètes",
      foxxum_etc: "Foxxum/ETC",
      file_not_available: "Fichier non disponible",
      female: "Femme",
      favorites: "Favoris",
      full_name: "Nom complet",
      full_name_message: "Veuillez entrer le nom complet",
      filters: "Filtres",
      filter_id: "ID du filtre",
      filter_id_required: "L'ID du filtre est requis",
      gallery: "Galerie",
      gallery_is_deleted: "Galerie supprimée",
      general: "Général",
      general_settings: "Paramètres généraux",
      gender: "Genre",
      genres: "Genres",
      genre: "Genre",
      get_package: "Obtenir le package",
      go_home: "Aller à l'accueil",
      google: "Google",
      google_pay_config_change_message: "La configuration de Google Pay a été modifiée",
      google_subscription_id: "ID d'abonnement Google",
      group: "Groupe",
      groups: "Groupes",
      group_name: "Nom du groupe",
      group_name_message: "Veuillez entrer le nom du groupe",
      group_user: "Utilisateurs du groupe",
      gift_card: "Carte cadeau",
      geolocation_restrictions: "Restrictions de géolocalisation",
      galleries: "Galeries",
      has_archive: "A une archive",
      has_catchup: "A un rattrapage",
      height: "Hauteur",
      height_message: "Veuillez entrer la hauteur",
      help_page: "Page d'aide",
      help_page_message: "Veuillez entrer la page d'aide",
      home: "Accueil",
      hook_delete_message: "Voulez-vous supprimer ce hook?",
      hook_token: "Token du hook",
      hook_token_message: "Veuillez entrer le token du hook",
      hour: "Heure",
      huawei: "Huawei",
      history: "Historique",
      highlighted: "Mis en avant",
      icon: "Icône",
      icon_ad_success_message: "Icône ajoutée",
      icon_upload_success: "Icône téléchargée avec succès",
      image: "Image",
      images: "Images",
      image_message: "Veuillez choisir une image",
      image_title: "Titre de l'image",
      in_app_branding: "Marque dans l'application",
      inactive: "Inactif",
      include: "Inclure",
      info: "Infos",
      input: "Entrée",
      input_key_message: "Veuillez entrer une clé",
      input_message: "Veuillez entrer {{name}}",
      input_time_message: "Veuillez entrer l'heure",
      input_vector: "Vecteur d'entrée",
      input_vector_message: "Veuillez entrer un vecteur d'entrée",
      intro_end_ms: "Fin de l'intro (ms)",
      intro_message: "Veuillez entrer l'intro",
      intro_start_ms: "Début de l'intro (ms)",
      ip_address: "Adresse IP",
      is_enabled: "Est activé",
      is_not_update: "N'est pas mis à jour",
      is_protected: "Est protégé",
      import: "Importer",
      import_from_csv: "Importer depuis CSV",
      information: "Information",
      info_not_available: "Information non disponible",
      impression: "Impression",
      impression_price: "Prix de l'impression",
      impression_value: "Valeur de l'impression",
      impression_value_message: "Veuillez entrer la valeur de l'impression",
      is_running: "Est en cours d'exécution",
      keywords: "Mots-clés",
      keywords_message: "Veuillez entrer des mots-clés",
      koopbank: "Koop Bank",
      language: "Langue",
      language_disable_message: "Voulez-vous désactiver cette langue?",
      language_disable_success: "Langue désactivée avec succès",
      language_enable_success: "Langue activée avec succès",
      language_message: "Veuillez entrer une langue",
      languages: "Langues",
      last_active: "Dernière activité",
      last_online: "Dernière connexion",
      last_update: "Dernière mise à jour",
      launcher: "Lanceur",
      launcher_app: "Application lanceur",
      level: "Niveau",
      license_end_date: "Date de fin de licence",
      like: "J'aime",
      like_dislike: "J'aime/Je n'aime pas {{name}}",
      live: "En direct",
      live_streams_count: "Nombre de diffusions en direct",
      live_tv: "Télévision en direct",
      live_tv_channel_update: "Chaîne de télévision en direct mise à jour",
      live_tv_url_saved: "URLs de télévision en direct enregistrées",
      lock: "Verrouiller",
      lock_confirm_message: "Voulez-vous verrouiller cet utilisateur?",
      logo: "Logo",
      logo_update_message: "Logo mis à jour",
      login: "Connexion",
      login_as_admin: "Se connecter en tant qu'administrateur",
      login_key_message: "Veuillez entrer la clé de connexion",
      login_message: "Nom d'utilisateur ou mot de passe incorrect",
      login_to_activate: "Connectez-vous pour activer",
      login_with_admin_code: "Connexion avec un code administrateur",
      logout: "Déconnexion",
      logout_message: "Voulez-vous vous déconnecter?",
      link_copied: "Lien copié",
      live_card_border_radius: "Rayon de bordure de la carte en direct",
      list_item_radius: "Rayon de l'élément de liste",
      logo_image: "Image du logo",
      male: "Homme",
      management_panel: "Panneau de gestion",
      max_bitrate: "Bitrate maximum",
      max_bitrate_message: "Veuillez entrer le bitrate maximum",
      max_duration: "Durée maximale",
      media_content_add_message: "Contenu média ajouté avec succès",
      media_content_update_message: "Contenu média mis à jour avec succès",
      media_type: "Type de média",
      medias: "Médias",
      menu_config: "Configuration du menu",
      menu_config_delete_message: "Voulez-vous supprimer cette configuration de menu?",
      messages: "Messages",
      message: "Message",
      meta_data: "Métadonnées",
      mid_rol_ads_interval: "Intervalle de publicité Mid-roll",
      min_bitrate: "Bitrate minimum",
      min_bitrate_message: "Veuillez entrer le bitrate minimum",
      mobile_highlight: "Fait saillant mobile",
      mobile_logo: "Logo mobile",
      monetization: "Monétisation",
      month: "Mois",
      most_watched_channels: "Chaînes les plus regardées",
      most_watched_movies: "Films les plus regardés",
      move_group_message:
        "Choisissez le groupe où seront déplacés tous les utilisateurs du groupe supprimé ou laissez-le vide si vous êtes sûr qu'il n'y a pas d'utilisateur dans le groupe supprimé",
      movies: "Films",
      movie: "Film",
      movie_encoder: "Encodeur de film",
      movie_excluded: "Film exclu",
      make_video_public: "Rendre la vidéo publique",
      mount_point: "Point de montage",
      more_info: "Plus d'infos",
      memory: "Mémoire",
      mid_role: "Rôle moyen",
      menu_config_delete_success: "Configuration de menu supprimée",
      min_version: "Version minimale",
      max_budget: "Budget maximum",
      max_budget_required: "Budget maximum requis",
      max_budget_number: "Le budget maximum doit être un nombre",
      name: "Nom",
      name_message: "Veuillez entrer un nom",

      new: "Nouveau",
      new_folder: "Nouveau dossier",
      new_note: "Nouvelle note",
      new_url: "Nouvelle URL",
      new_password: "Entrer un nouveau mot de passe",
      new_translation: "Nouvelle traduction",
      new_translation_added: "Nouvelle traduction ajoutée avec succès",
      news: "Actualités",
      news_add_success: "Actualités ajoutées avec succès",
      news_remove_message: "Voulez-vous supprimer cette actualité?",
      none: "Aucun",
      not_close_window_message: "Veuillez ne pas fermer la fenêtre",
      not_have_payment: "Vous n'avez aucun paiement!",
      not_have_server_message: "Vous n'avez aucun serveur",
      not_protected: "Non protégé",
      not_protected_message: "Voulez-vous rendre cette vidéo non protégée?",
      not_server_message: "Vous n'avez aucun serveur",
      notes: "Notes",
      notes_message: "Veuillez entrer des notes",
      now_watching: "Maintenant en train de regarder",
      number_message: "Veuillez entrer un numéro",
      new_transcoding: "Nouveau transcodage",
      not_shared: "Non partagé",
      number: "Numéro",
      not_archived: "Non archivé",
      offline: "Hors ligne",
      online: "En ligne",
      online_offline: "En ligne/Hors ligne",
      online_users: "Utilisateurs en ligne",
      original_audio_language: "Langue audio originale",
      output: "Sortie",
      output_type: "Type de sortie",
      output_type_message: "Veuillez sélectionner un type de sortie",
      package_name: "Nom du package",
      package_name_message: "Veuillez entrer le nom du package",
      parental_code: "Code parental",
      part_view_count: "Nombre de vues partielles",
      passphrase: "Phrase secrète",
      passphrase_message: "Veuillez entrer une phrase secrète",
      password: "Mot de passe",
      password_message: "Veuillez entrer un mot de passe",
      path: "Chemin",
      path_message: "Veuillez entrer le chemin",
      paused: "En pause",
      paypal: "PayPal",
      paypal_client_id: "ID client PayPal",
      paypal_client_id_message: "Veuillez entrer l'ID client PayPal",
      paypal_client_secret: "Secret client PayPal",
      paypal_client_secret_message: "Veuillez entrer le secret client PayPal",
      paypal_config_change: "La configuration PayPal a été modifiée",
      paypal_plan_id: "ID du plan PayPal",
      paypal_webhook_id: "ID Webhook PayPal",
      paypal_webhook_id_message: "Veuillez entrer l'ID Webhook PayPal",
      payment: "Paiement",
      payment_count: "Nombre de paiements",
      payment_type: "Type de paiement",
      payment_type_message: "Veuillez sélectionner un type de paiement",
      permissions_list: "Liste des autorisations",
      per_channel_chart: "Graphique par chaîne",
      percent: "Pourcentage",
      platform: "Plateforme",
      play: "Lire",
      play_list_delete_message: "Voulez-vous supprimer cette playlist?",
      playlist: "Playlist",
      playlists: "Playlists",
      playlist_file: "Fichier de playlist",
      playlist_url: "URL de playlist",
      please_sign_in: "Veuillez vous connecter ci-dessous.",
      please_wait: "Veuillez patienter",
      play_market_url: "URL du Play Market",
      poster: "Affiche",
      poster_message: "Veuillez entrer une affiche",
      pre_roll_ads_interval: "Intervalle de publicité Pre-roll",
      primary: "Primaire",
      primary_color_update_message: "La couleur primaire a été mise à jour",
      pricing_message: "Veuillez entrer un prix",
      pricing_plan_options: "Options du plan tarifaire",
      privacy_policy: "Politique de confidentialité",
      profession: "Profession",
      protected: "Protégé",
      protected_message: "Voulez-vous protéger cette vidéo?",
      publish: "Publier",
      published: "Publié",
      published_at: "Publié à",
      published_date_message: "Veuillez entrer la date de publication",
      published_immediately: "Publié immédiatement",
      platforms: "Plateformes",
      payments: "Paiements",
      provider: "Fournisseur",
      position: "Position",
      protect: "Protéger",
      price: "Prix",
      phone_number: "Numéro de téléphone",
      phone: "Téléphone",
      phone_message: "Veuillez entrer un numéro de téléphone",
      port: "Port",
      promo_codes: "Codes promo",
      promo_code_edited: "Code promo édité",
      promo_code_deleted: "Code promo supprimé",
      protect_genre_message: "Voulez-vous protéger ce genre?",
      primary_color: "Couleur primaire",
      primary_color_description: "Changer la couleur principale de l'application",
      pricing_name: "Nom du tarif",
      pricing_name_required: "Le nom du tarif est requis",
      pricing: "Tarification",
      pricing_package_id: "ID du package de tarification",
      pricing_package_id_required: "L'ID du package de tarification est requis",
      pre_role: "Pré-rôle",
      post_role: "Post-rôle",
      quiz: "Quiz",
      ram: "RAM",
      ratings: "Évaluations",
      read: "Lire",
      registered: "Enregistré",
      registered_users: "Utilisateurs enregistrés",
      registration_date: "Date d'enregistrement",
      release_date: "Date de sortie",
      release_date_message: "Veuillez sélectionner une date de sortie",
      rent_price: "Prix de location",
      repeat_counts: "Nombre de répétitions",
      repeat_counts_message: "Veuillez entrer le nombre de répétitions",
      reset: "Réinitialiser",
      reset_password: "Réinitialiser le mot de passe",
      resolution: "Résolution",
      resolution_name: "Nom de la résolution",
      resolution_select_message: "Veuillez sélectionner une résolution",
      restriction: "Restriction",
      restriction_required: "La restriction d'âge est requise",
      restriction_monetization: "Restriction et monétisation",
      restore: "Restaurer",
      restore_live_tv_message:
        "Voulez-vous restaurer la télévision en direct sélectionnée?",
      restore_message: "Voulez-vous restaurer cet utilisateur?",
      role: "Rôle",
      roku: "Roku",
      regions: "Régions",
      recordings: "Enregistrements",
      resolutions: "Résolutions",
      recaptcha_verification_message: "Veuillez vérifier le reCAPTCHA",
      save: "Sauvegarder",
      save_error_message:
        "Vous pouvez mettre à jour ce contenu après avoir rempli tous les champs obligatoires du film ou de la série.",
      save_translation: "Sauvegarder la traduction",
      scheduled_time_change_message: "L'heure programmée a été modifiée",
      scheduling: "Programmation",
      scheduling_time: "Heure de programmation",
      scheduling_time_message: "Veuillez entrer l'heure de programmation",
      scheduling_type: "Type de programmation",
      script: "Script",
      script_message: "Veuillez entrer un script",
      scripts: "Scripts",
      search_channel_placeholder: "Rechercher une chaîne et sélectionner",
      search_epg_and_select: "Rechercher un EPG et sélectionner",
      search_movie_and_select: "Rechercher un film et sélectionner",
      search_from_tmdb: "Rechercher sur TMDB",
      secondary: "Secondaire",
      see_selected_movies: "Voici vos films sélectionnés",
      select: "Sélectionner",
      select_a_value: "Sélectionner une valeur",
      select_an_option: "Sélectionner une option",
      select_badges: "Sélectionner des badges",
      select_channel: "Sélectionner une chaîne",
      select_channel_message: "Veuillez sélectionner une chaîne",
      select_csv_message: "Veuillez sélectionner un fichier CSV",
      select_country: "Sélectionner un pays",
      select_country_message: "Veuillez sélectionner un pays",
      select_device_message: "Veuillez sélectionner un appareil",
      select_file: "Sélectionner un fichier",
      select_language: "Sélectionner une langue",
      select_live_tv: "Sélectionner la télévision en direct",
      select_movies: "Sélectionner des films",
      select_resolution_price:
        "Sélectionner le prix pour toutes les résolutions activées",
      select_season: "Sélectionner une saison",
      select_tariff: "Sélectionner un tarif",
      select_tariff_message: "Veuillez sélectionner un tarif",
      selected_icon: "Icône sélectionnée",
      send: "Envoyer",
      send_message: "Envoyer un message",
      series: "Séries",
      series_episode: "Série et épisode",
      server: "Serveur",
      server_message: "Veuillez entrer un serveur",
      servers: "Serveurs",
      service_account: "Compte de service",
      service_notification: "Notification de service",
      set_collection_type: "Définir le type de collection",
      set_type: "Définir le type",
      setup_colors_for_application: "Configurer les couleurs pour l'application",
      setup_filter: "Configurer le filtre",
      setup_stream: "Configurer le flux",
      service_name: "Nom du service",
      size: "Taille",
      slider: "Curseur",
      smtp_configs: "Configuration SMTP",
      smtp_host: "Hôte SMTP",
      smtp_message: "Veuillez entrer l'hôte SMTP",
      something_wrong: "Quelque chose s'est mal passé",
      spent: "Dépensé",
      s_tv: "S-TV",
      started: "Commencé",
      start: "Démarrer",
      start_logo: "Logo de démarrage",
      start_transcoding_message: "Voulez-vous démarrer le transcodage?",
      status: "Statut",
      stop: "Arrêter",
      stop_transcoding_message: "Voulez-vous arrêter le transcodage?",
      storage: "Stockage",
      stor_link: "Lien du magasin",
      stream: "Flux",
      streamer: "Diffuseur",
      streams: "Flux",
      stream_message: "Veuillez entrer un flux",
      stream_type: "Type de flux",
      stream_type_message: "Veuillez sélectionner un type de flux",
      streaming_delete_success: "Diffusion supprimée avec succès",
      streaming_pause_success: "Diffusion mise en pause avec succès",
      streaming_service: "Service de diffusion",
      streaming_started_success: "Diffusion commencée avec succès",
      stream_url: "URL du flux",
      stripe: "Stripe",
      stripe_config_change: "La configuration Stripe a été modifiée",
      stripe_plan_id: "ID du plan Stripe",
      stripe_public_key: "Clé publique Stripe",
      stripe_public_key_message: "Veuillez entrer la clé publique Stripe",
      stripe_secret_key: "Clé secrète Stripe",
      stripe_secret_key_message: "Veuillez entrer la clé secrète Stripe",
      stripe_webhook_secret: "Secret Webhook Stripe",
      stripe_webhook_secret_message: "Veuillez entrer le secret Webhook Stripe",
      subscribers: "Abonnés",
      success: "Succès",
      success_purchased_message: "Serveur Cloud acheté avec succès !",
      subtitle_track: "Piste de sous-titres",
      support: "Support",
      supported: "Pris en charge",
      subscription: "Abonnement",
      settings: "Paramètres",
      soial_auth: "Authentification sociale",
      skip_movie: "Passer le film",
      skip_episodes: "Passer les épisodes",
      server_name: "Nom du serveur",
      subscription_id: "ID d'abonnement",
      sub_users: "Sous-utilisateurs",
      surname: "Nom de famille",
      social_links: "Liens sociaux",
      sort_resolutions: "Trier les résolutions",
      state: "État",
      shearing_as_a_gift: "Partager comme un cadeau",
      selected_live_tv_archive_message:
        "Voulez-vous sélectionner la télévision en direct archivée?",
      share: "Partager",
      selected_device: "Appareil sélectionné",
      selected_all_movies: "Tous les films sélectionnés",
      selected_all_tv: "Tous les téléviseurs sélectionnés",
      show_more: "Afficher plus...",
      storage_info: "Informations de stockage",
      scheduled_restart: "Redémarrage programmé",
      scheduled_restart_description:
        "Temps de configuration pour redémarrer le flux d'erreur et le transcodage",
      show_georestriced_content: "Afficher le contenu géo-restreint",
      show_georestriced_content_description:
        "Configuration de configuration pour le contenu géo-restreint",
      show_all_content: "Afficher tout le contenu",
      sub_user_radius: "Rayon de sous-utilisateur",
      season: "Saison",
      season_number: "Numéro de saison",
      select_type_message: "Veuillez sélectionner un type",
      secret_key: "Clé secr",
      secret_key_message: "Veuillez entrer la clé secrète",
      secret_key_placeholder: "Entrer la clé secrète",
      start_date: "Date de début",
      start_date_required: "La date de début est requise",
      select_start_date: "Sélectionner la date de début",
      select_end_date: "Sélectionner la date de fin",
      select_filter_id: "Sélectionner l'ID du filtre",
      select_pricing_package_id: "Sélectionner l'ID du package de tarification",
      splash_screen: "Écran de démarrage",
      splash_screen_ads: "Publicités à l'écran de démarrage",
      skipable: "Passable",
      skip_after_seconds: "Passer après (secondes)",
      select_campaign: "Sélectionner une campagne",
      title_message: "Veuillez entrer le titre",
      title_message_message: "Veuillez entrer le message du titre",
      tizen: "Tizen",
      tizen_lg: "Tizen/LG",
      total: "Total",
      top_watched: "Les plus regardés",
      trailers: "Bandes-annonces",
      translations: "Traductions",
      translation: "Traduction",
      translation_added: "Traduction ajoutée",
      translation_delete_success: "Traduction supprimée avec succès",
      translation_edit_success: "Traduction modifiée avec succès",
      translations_updated: "Traductions mises à jour",
      transcoding: "Transcodage",
      transcoder: "Transcodeur",
      transcoding_add_success: "Transcodage ajouté avec succès",
      transcoding_deleted_success: "Transcodage supprimé avec succès",
      transcoding_edit_success: "Transcodage modifié avec succès",
      transcoding_in_group: "Transcodage dans le groupe",
      transcoding_service: "Service de transcodage",
      transcoding_started_success: "Transcodage commencé avec succès",
      transcoding_stopped_success: "Transcodage arrêté avec succès",
      transcoders: "Transcodeurs",

      transcoder_paused: "Transcodeur en pause",
      transcoder_started: "Transcodeur démarré",
      transcoder_stopped: "Transcodeur arrêté",
      transcoding_id_not_found: "Source interne avec cet ID de transcodage non trouvée",
      tray_later:
        "Une erreur est survenue lors de la récupération des packages. Veuillez réessayer dans quelques minutes.",
      trailer: "Bande-annonce",
      trailer_url: "URL de la bande-annonce",
      tv_shows: "Émissions de télévision",
      tv_highlight: "Fait saillant à la télévision",
      tvod_payment: "Paiement TVOD",
      tvod_pricing: "Tarification TVOD",
      type: "Type",
      tariff: "Tarif",
      tariffs: "Tarifs",
      tariff_created_date: "Date de création du tarif",
      title: "Titre",
      time_shift: "Décalage temporel",
      title_required: "Le titre est requis",
      unprotect_selected_content_message:
        "Voulez-vous désactiver la protection du contenu sélectionné?",
      unprotected: "Non protégé",
      unprotected_message: "Voulez-vous rendre cette vidéo non protégée?",
      update: "Mettre à jour",
      update_message: "Mise à jour réussie",
      update_url_message: "Les URLs ont été mises à jour avec succès",
      url_placeholder: "Entrer l'URL",
      unlimited_updates: "Mises à jour illimitées",
      unlimited_users: "Utilisateurs illimités",
      uploads: "Télécharger",
      upload_video_file: "Télécharger un fichier vidéo",
      uptime: "Temps de fonctionnement",
      url: "URL",
      url_message: "Veuillez entrer une URL",
      username: "Nom d'utilisateur",
      username_message: "Veuillez entrer un nom d'utilisateur",
      user_group: "Groupe d'utilisateurs",
      user_group_message: "Veuillez sélectionner un groupe d'utilisateurs",

      user_icons: "Icônes d'utilisateurs",
      users: "Utilisateurs",
      user: "Utilisateur",
      used: "Utilisé",
      un_used: "Non utilis",
      user_type: "Type d'utilisateur",
      updated_date: "Date de mise à jour",
      url_after_logout: "URL après déconnexion",
      unspent: "Non dépensé",
      unprotecd: "Non protégé",
      unprotecd_genre_message: "Voulez-vous désactiver la protection de ce genre?",
      used_percentages: "Pourcentages utilisés",
      updated_successfully: "Mis à jour avec succès",
      uploads_logo: "Télécharger le logo",
      uploads_background: "Télécharger l'arrière-plan",
      videos: "Vidéos",
      webos: "WebOS",
      video_track: "Piste vidéo",
      view: "Voir",
      view_list: "Voir la liste",
      view_per_movie: "Vues par film",
      views_duration_channel: "Durée des vues par chaîne",
      views_per_channel: "Vues par chaîne",
      vod: "VOD",
      vod_message: "Veuillez entrer VOD",
      visible: "Visible",
      version: "Version",
      version_code: "Code de version",
      volume_ads: "Publicités de volume",
      video_banner_ads: "Publicités vidéo",
      waiting: "En attente",
      wallet_address: "Adresse du portefeuille",
      web_icon: "Icône web",
      web_page: "Page web",
      web_page_message: "Veuillez entrer une page web",
      welcome: "Bienvenue sur le panneau d'administration {{name}}.",
      width: "Largeur",
      watch: "Regarder",
      viewed_ads: "Publicités vues",
      welcome_provider:
        "Connectez-vous au tableau de bord du fournisseur de contenu pour consulter les statistiques de vos films",
      year: "Année",
      yes: "Oui",
      you_have_selected_live_tv:
        "Vous avez sélectionné {{selectedTvShowsCount}} télévisions en direct",
      you_have_selected_movies:
        "Vous avez sélectionné {{selectedMoviesCount}} films et émissions de télévision",
      you_dont_have: "Vous n'avez pas de",
      you_dont_have_mssage:
        "Vous n'avez pas encore de données ici. Veuillez cliquer sur le bouton Ajouter pour le créer ou regarder le didacticiel si nécessaire.",
      watch_tutorial: "Regarder le tutoriel",
      xpos_id: "ID de l'ID",
      question: "Question",
      others: "Autres",
    },
  },

  ptBR: {
    translation: {
      admin: "Admin",
      about_us: "Sobre nós",
      active: "Ativo",
      action: "Ação",
      add: "Adicionar",
      add_ads: "Adicionar anúncios",
      add_url: "Adicionar URL",
      add_season: "Adicionar temporada",
      ads: "Anúncios",
      apple: "Apple",
      advertisements: "Anúncios (AVOD)",
      advertisement: "Anúncio",
      ads_config: "Configuração de anúncios",
      add_cast: "Adicionar elenco",
      add_answer: "Adicionar resposta",
      add_category: "Adicionar categoria",
      add_collection: "Adicionar coleção",
      add_collection_movie: "Adicionar filme à coleção",
      add_config: "Adicionar configuração",
      add_content: "Adicionar conteúdo",
      add_device: "Adicionar dispositivo",
      add_episode: "Adicionar episódio",
      add_feature: "Adicionar recurso",
      add_filter: "Adicionar filtro",
      add_filter_live_tv_message: "Aqui você pode filtrar e adicionar sua TV ao vivo",
      add_filter_movie_message: "Aqui você pode filtrar e adicionar seus filmes",
      add_package: "Adicionar pacote",
      add_genre: "Adicionar gênero",
      add_keyword: "Adicionar palavra-chave",
      add_language: "Adicionar idioma",
      add_lesson: "Adicionar lição",
      add_list: "Adicionar lista",
      add_live_tv: "Adicionar TV ao vivo",
      add_movie: "Adicionar filme",
      add_new_folder: "Adicionar nova pasta",
      add_new_user: "Adicionar novo usuário",
      add_playlist: "Adicionar playlist",
      add_provider: "Adicionar provedor",
      add_script: "Adicionar script",
      add_series: "Adicionar série",
      add_success: "Adicionado com sucesso",
      add_to_archive: "Adicionar ao arquivo",
      add_to_streaming: "Adicionar ao streaming",
      add_transcoding: "Adicionar transcodificação",
      age: "Idade",
      age_restriction: "Restrição de idade",
      age_restriction_message: "Por favor, insira a restrição de idade",
      all: "Todos",
      all_live_tv_selected: "Todas as TVs ao vivo selecionadas",
      all_movie_selected: "Todos os filmes selecionados",
      all_time: "Todo o tempo",
      amount: "Quantia",
      android_ios: "Android/iOS",
      android_mobile: "Android Mobile",
      android_tv: "Android TV",
      api: "API",
      apps: "Aplicativos",
      application: "Aplicativo",
      application_radius: "Raio do aplicativo",
      app_id: "ID do aplicativo",
      app_info_update_message:
        "As informações do aplicativo foram atualizadas com sucesso",
      app_name: "Nome do aplicativo",
      app_name_message: "Por favor, insira o nome do aplicativo",
      app_radiuses: "Raios do aplicativo",
      app_stor_url: "URL da loja de aplicativos",
      archived_chanel_success: "Canal arquivado",
      archive: "Arquivo",
      archiver: "Arquivador",
      archive_add_success: "Arquivo adicionado com sucesso",
      archive_category_message: "Você quer arquivar esta categoria?",
      archive_confirm_message: "Você quer arquivar este usuário?",
      are_you_sure: "Você tem certeza?",
      are_you_sure: "Você tem certeza de {{action}} este {{name}}?",
      authorize: "Autorizar",
      authorize_api_login_key: "Chave de login da API de autorização",
      authorize_config_change_message: "A configuração de autorização foi alterada",
      authorize_transaction_key: "Chave de transação de autorização",
      auto_renewal: "Renovação automática",
      available: "Disponível",
      available_countries: "Países disponíveis",
      available_countries_message: "Por favor, insira os países disponíveis",
      available_devices: "Dispositivos disponíveis",
      add_stream: "Adicionar fluxo",
      add_group: "Adicionar grupo",
      add_resolution: "Adicionar resolução",
      audio_bitrate: "Bitrate de áudio",
      add_user: "Adicionar usuário",
      answer: "Resposta",
      amazon_fire_tv: "Amazon Fire TV",
      auto_scroll: "Rolagem automática",
      admin_panel_favicon: "Favicon do painel de administração",
      admin_panel_favicon_description: "Alterar favicon do painel de administração",
      admin_panel_logo: "Logotipo do painel de administração",
      admin_panel_logo_description: "Alterar logotipo do painel de administração",
      active_icon: "Ícone ativo",
      audio_track: "Pista de áudio",
      archived: "Arquivado",
      address: "Endereço",
      add_client: "Adicionar cliente",
      allowed_countries: "Países permitidos",
      allowed_countries_message: "Por favor, insira os países permitidos",
      allowed_os: "Sistemas operacionais permitidos",
      allowed_os_message: "Por favor, insira os sistemas operacionais permitidos",
      allowed_vod_genres: "Gêneros VOD permitidos",
      allowed_vod_genres_message: "Por favor, insira os gêneros VOD permitidos",
      allowed_livetv_genres: "Gêneros de TV ao vivo permitidos",
      allowed_livetv_genres_message:
        "Por favor, insira os gêneros de TV ao vivo permitidos",
      allowed_for_kids: "Permitido para crian ças",
      add_pricing: "Adicionar preços",
      add_campaign: "Adicionar campanha",
      ad_type: "Tipo de anúncio",
      ad_phone: "Telefone de anúncio",
      ad_email: "E-mail de anúncio",
      ad_address: "Endereço de anúncio",
      package_updated_successfully: "Pacote atualizado com sucesso",
      notes: "Por favor, insira o endereço",
      notes_message: "Por favor, insira as notas",
      backups: "Backups",
      balance: "Saldo",
      backdrop: "Plano de fundo",
      backdrop_message: "Por favor, escolha um plano de fundo",
      badges: "Emblemas",
      birthday: "Aniversário",
      birth_country: "País de nascimento",
      birth_in: "Nascido em",
      blocked: "Bloqueado",
      blocked_message: "Você quer ativar este dispositivo?",
      body: "Corpo",
      body_message: "Por favor, insira o corpo",
      buffer_size: "Tamanho do buffer",
      buffer_size_message: "Por favor, insira o tamanho do buffer",
      build_number: "Número da versão",
      build_number_message: "Por favor, insira o número da versão",
      by_country: "por país",
      basic_info: "Informações básicas",
      branding: "Marca",
      background: "Fundo",
      background_image: "Imagem de fundo",
      btn_border_radius: "Raio da borda do botão",
      back_home: "Voltar para a página inicial",
      balance_message: "Por favor, insira o saldo",
      banner_ads: "Anúncios de banner",
      cancel: "Cancelar",
      canceled_ads: "Anúncios cancelados",
      card: "Cartão",
      cast: "Elenco",
      categories: "Categorias",
      categories_message: "Por favor, insira as categorias",
      catch_up: "Acompanhar",
      catchup: "Catchup",
      catchup_disabled: "Catchup desativado",
      catchup_service: "Serviço Catchup",
      change: "Mudar",
      change_position_message: "Você quer mudar de posição?",
      change_position_message_with_name:
        "A posição {position} já contém <b>{response?.message?.name}</b> TV ao vivo. Podemos trocar suas posições. Você quer fazer isso?",
      choose_active_icon: "Escolha o ícone ativo",
      choose_active_icon_message: "Por favor, escolha um ícone ativo",
      choose_file: "Escolha o arquivo",
      choose_file_message: "Por favor, escolha um arquivo",
      choose_icon_message: "Por favor, escolha um ícone",
      choose_poster_message: "Por favor, escolha o pôster",
      choose_tariff_for_user: "Escolha a tarifa para o usuário",
      city: "Cidade",
      clear: "Limpar",
      clear_all: "Limpar tudo",
      clear_history_message: "Você quer limpar todo o histórico?",
      client_id: "ID do cliente",
      client_id_message: "O ID do cliente é obrigatório",
      client_id_placeholder: "Insira o ID do cliente",
      click_or_drag: "Clique ou arraste o arquivo para esta área para fazer upload",
      code: "Código",
      code_is_not_valid: "O código não é válido",
      code_message: "Por favor, insira o código",
      collections: "Coleção",
      collection_edit: "Editar coleção",
      comment: "Comentário",
      coming_soon: "Em breve",
      company_address: "Endereço da empresa",
      company_address_message: "Por favor, insira o endereço da empresa",
      company_name: "Nome da empresa",
      company_name_message: "Por favor, insira o nome da empresa",
      config_is_changed: "A configuração foi alterada",
      configs: "Configurações",
      connect_date: "Data de conexão",
      content: "Conteúdo",
      content_monetization_type: "Tipo de monetização do conteúdo",
      content_provider: "Provedor de conteúdo",
      content_question: "Pergunta sobre o conteúdo",
      collection: "Coleção",
      collection_ads: "Anúncios de coleção",
      created_at: "Criado em",
      cpu: "CPU",
      csv_message: "Arquivo CSV importado",
      currency: "Moeda",
      countries: "Países",
      contact_person: "Pessoa de contato",
      channels: "Canais",
      channel: "Canal",
      country: "País",
      country_viewed: "País visualizado",
      contact_email: "E-mail de contato",
      contact_number: "Número de contato",
      copy_gift_link: "Copiar link do presente",
      config_popup_table: "Configure as colunas da tabela",
      count: "Contagem",
      count_message: "Por favor, insira a contagem",
      create_promo_code: "Criar código promocional",
      cover: "Capa",
      credit_time_ms: "Tempo de crédito (ms)",
      card_border_radius: "Raio da borda do cartão",
      clients: "Clientes",
      connected: "Conectado",
      custom_ads: "Anúncios personalizados",
      custom_allowed_vods: "VODs permitidos personalizados",
      custom_allowed_vods_message: "Por favor, insira os VODs permitidos personalizados",
      custom_allowed_live_tv_channels: "Canais de TV ao vivo permitidos personalizados",
      custom_allowed_live_tv_channels_message:
        "Por favor, insira os canais de TV ao vivo permitidos personalizados",
      country_search: "Pesquisa por país",
      conversion_price: "Preço de conversão",
      country_code: "Código do país",
      campaign: "Campanha",
      campaign_id: "ID da campanha",
      conversion_url: "URL de conversão",
      company_title: "Título da empresa",
      conversation_link: "Link de conversa",
      conversation_link_message: "Por favor, insira o link da conversa",
      devices: "Dispositivos",
      description: "Descrição",
      description_message: "Por favor, insira a descrição",
      data_not_available: "Dados indisponíveis",
      dashboard: "Painel",
      date: "Data",
      date_of_birth: "Data de nascimento",
      date_of_death: "Data de falecimento",
      day: "Dia",
      day_message: "Por favor, insira o dia",
      delete: "Deletar",
      delete_cast_message: "Você quer deletar este elenco?",
      delete_category_message: "Você quer deletar esta categoria?",
      delete_collection_message: "Você quer deletar esta coleção?",
      delete_device_message: "Você quer deletar este dispositivo?",
      delete_document_message: "Você quer deletar este documento?",
      delete_episode_message: "Você quer deletar este episódio?",
      delete_gallery_confirm: "Você quer deletar esta galeria?",
      delete_genre_message: "Você quer deletar este gênero?",
      delete_group_message: "Você quer deletar este grupo?",
      delete_item_message: "Você quer deletar este item?",
      delete_media_message: "Você quer deletar este mídia?",
      delete_message: "{{name}} foi deletado com sucesso",
      delete_movie_message: "Você quer deletar os filmes selecionados?",
      delete_note_message: "Você quer deletar esta nota?",
      delete_resolution_message: "Você quer deletar esta resolução?",
      delete_script_message: "Você quer deletar este script?",
      delete_selected_message: "Você quer deletar o conteúdo selecionado?",
      delete_selected_movie_message: "Você quer deletar os filmes selecionados?",
      delete_stream_message: "Você quer deletar este fluxo?",
      details: "Detalhes",
      device: "Dispositivo",
      device_count: "Contagem de dispositivos",
      device_count_message: "Por favor, insira a contagem de dispositivos",
      device_id: "ID do dispositivo",
      device_message: "Por favor, insira o dispositivo",
      device_platform: "Plataforma do dispositivo",
      disable: "Desabilitar",
      disable_all: "Desabilitar tudo",
      disable_all_content_message: "Você quer desativar todo o conteúdo?",
      disable_selected_message: "Você quer desativar o conteúdo selecionado?",
      discount: "Desconto",
      dislike: "Desgostar",
      document_add_success: "Documento adicionado com sucesso",
      documents: "Documentos",
      done: "Feito",
      downloading: "Baixando",
      download: "Baixar",
      duration: "Duração",
      duration_chart: "Gráfico de duração",
      duration_m: "Duração (m)",
      duration_message: "Por favor, insira a duração",
      duration_type_message: "Por favor, insira o tipo de duração",
      duration_movie: "Duração do filme",
      duration_ms: "Duração (ms)",
      duration_type: "Tipo de duração",
      deathday: "Dia da morte",
      downloaded: "Baixado",
      download_csv: "Baixar CSV",
      delete_selected: "Deletar selecionado",
      delete_promo_code_success: "Código promocional deletado com sucesso",
      delete_selected_live_tv_message: "Você quer deletar a TV ao vivo selecionada?",
      disable_selected_live_tv_message: "Você quer desativar a TV ao vivo selecionada?",
      disconnected: "Desconectado",
      duration_in_seconds: "Duração em segundos",
      edit: "Editar",
      edit_config: "Editar configuração",
      edit_episode: "Editar episódio",
      edit_season: "Editar temporada",
      email: "E-mail",
      email_message: "Por favor, insira um e-mail",
      email_required_message: "O e-mail é obrigatório",
      email_validation_message: "Por favor, insira um e-mail válido",
      enable: "Habilitar",
      enable_all: "Habilitar tudo",
      enable_all_content_message: "Você quer habilitar todo o conteúdo?",
      enable_selected_message: "Você quer habilitar o conteúdo selecionado?",
      enabled: "Habilitado",
      enter_content: "Insira o conteúdo",
      enter_email: "Insira seu e-mail",
      episode: "Episódio",
      epg: "EPG",
      epg_type: "Tipo de EPG",
      error: "Erro",
      error_count: "Contagem de erros",
      errored: "Erro",
      exclude: "Excluir",
      expired_tariff: "Tarifa expirada",
      expire_date: "Data de expiração",
      external_backup_urls: "URLs de backup externo",
      encode_audio: "Codificar áudio",
      enable_selected_live_tv_message: "Você quer habilitar a TV ao vivo selecionada?",
      enable_geolocation_restriction: "Habilitar restrição de geolocalização",
      enable_geolocation_restriction_description:
        "Configurar configuração para restrição de geolocalização",
      edit_client: "Editar cliente",
      edit_filter: "Editar filtro",
      edit_package: "Editar pacote",
      enter_pricing_name: "Insira o nome da tarifa",
      edit_campaign: "Editar campanha",
      enter_max_budget: "Insira o orçamento máximo",
      enter_title: "Insira o título",
      enter_description: "Insira a descrição",
      end_date: "Data final",
      end_date_required: "A data final é obrigatória",
      earnings: "Ganhos",
      earning_by_ad_types: "Ganhos por tipos de anúncios",
      earning_by_ad_regions: "Ganhos por regiões de anúncios",
      earning_by_platform: "Ganhos por plataformas",
      earning_by_top_clients: "Ganhos por principais clientes",
      earning_by_top_region: "Ganhos por região principal",
      favorite: "Favorito {{name}}",
      fetching_started: "Busca iniciada",
      fetching_message: "Você quer iniciar a busca?",
      feature: "Recurso",
      feature_remove_message: "Você quer remover este recurso?",
      feature_remove_success: "Recurso removido com sucesso",
      file: "Arquivo",
      file_upload_failed: "Falha no upload do arquivo",
      finished: "Concluído",
      flusonic: "Flusonic",
      force_update: "Forçar atualização",
      force_update_message: "Você quer forçar a atualização?",
      forgot_password: "Esqueceu sua senha?",
      fps: "FPS",
      faq: "FAQ",
      fps_message: "Por favor, insira o FPS",
      free: "Grátis",
      full_view_count: "Contagem total de visualizações",
      foxxum_etc: "Foxxum, etc.",
      file_not_available: "Arquivo não disponível",
      female: "Feminino",
      favorites: "Favoritos",
      full_name: "Nome completo",
      full_name_message: "Por favor, insira o nome completo",
      filters: "Filtros",
      filter_id: "ID do filtro",
      filter_id_required: "O ID do filtro é obrigatório",
      gallery: "Galeria",
      gallery_is_deleted: "Galeria deletada",
      general: "Geral",
      general_settings: "Configurações gerais",
      gender: "Gênero",
      genres: "Gêneros",
      genre: "Gênero",
      get_package: "Obter pacote",
      go_home: "Ir para o início",
      google: "Google",
      google_pay_config_change_message: "A configuração do Google Pay foi alterada",
      google_subscription_id: "ID de assinatura do Google",
      group: "Grupo",
      groups: "Grupos",
      group_name: "Nome do grupo",
      group_name_message: "Por favor, insira o nome do grupo",
      group_user: "Usuários do grupo",
      gift_card: "Cartão presente",
      geolocation_restrictions: "Restrições de geolocalização",
      galleries: "Galerias",
      has_archive: "Tem arquivo",
      has_catchup: "Tem catchup",
      height: "Altura",
      height_message: "Por favor, insira a altura",
      help_page: "Página de ajuda",
      help_page_message: "Por favor, insira a página de ajuda",
      home: "Início",
      hook_delete_message: "Você quer deletar este hook?",
      hook_token: "Token do hook",
      hook_token_message: "Por favor, insira o token do hook",
      hour: "Hora",
      huawei: "Huawei",
      history: "Histórico",
      highlighted: "Destacado",
      icon: "Ícone",
      icon_ad_success_message: "Ícone adicionado",
      icon_upload_success: "Ícone enviado com sucesso",
      image: "Imagem",
      images: "Imagens",
      image_message: "Por favor, escolha uma imagem",
      image_title: "Título da imagem",
      in_app_branding: "Marca no aplicativo",
      inactive: "Inativo",
      include: "Incluir",
      info: "Informações",
      input: "Entrada",
      input_key_message: "Por favor, insira a chave",
      input_message: "Por favor, insira {{name}}",
      input_time_message: "Por favor, insira o horário",
      input_vector: "Vetor de entrada",
      input_vector_message: "Por favor, insira o vetor de entrada",
      intro_end_ms: "Fim da introdução (ms)",
      intro_message: "Por favor, insira a introdução",
      intro_start_ms: "Início da introdução (ms)",
      ip_address: "Endereço IP",
      is_enabled: "Está habilitado",
      is_not_update: "Não está atualizado",
      is_protected: "Está protegido",
      import: "Importar",
      import_from_csv: "Importar do CSV",
      information: "Informação",
      info_not_available: "Informação não disponível",
      impression: "Impressão",
      impression_price: "Preço da impressão",
      impression_value: "Valor da impressão",
      impression_value_message: "Por favor, insira o valor da impressão",
      is_running: "Está rodando",
      keywords: "Palavras-chave",
      keywords_message: "Por favor, insira as palavras-chave",
      koopbank: "Koop Bank",
      language: "Idioma",
      language_disable_message: "Você quer desativar este idioma?",
      language_disable_success: "Idioma desativado com sucesso",
      language_enable_success: "Idioma ativado com sucesso",
      language_message: "Por favor, insira um idioma",
      languages: "Idiomas",
      last_active: "Última atividade",
      last_online: "Última vez online",
      last_update: "Última atualização",
      launcher: "Lançador",
      launcher_app: "Aplicativo de lançamento",
      level: "Nível",
      license_end_date: "Data de expiração da licença",
      like: "Curtir",
      like_dislike: "Curtir/Não curtir {{name}}",
      live: "Ao vivo",
      live_streams_count: "Contagem de transmissões ao vivo",
      live_tv: "TV ao vivo",
      live_tv_channel_update: "Canal de TV ao vivo atualizado",
      live_tv_url_saved: "URLs de TV ao vivo salvas",
      lock: "Bloquear",
      lock_confirm_message: "Você quer bloquear este usuário?",
      logo: "Logo",
      logo_update_message: "Logo atualizado",
      login: "Entrar",
      login_as_admin: "Entrar como administrador",
      login_key_message: "Por favor, insira a chave de login",
      login_message: "Nome de usuário ou senha incorretos",
      login_to_activate: "Entre para ativar",
      login_with_admin_code: "Entrar com código de administrador",
      logout: "Sair",
      logout_message: "Você quer sair?",
      link_copied: "Link copiado",
      live_card_border_radius: "Raio da borda do cartão ao vivo",
      list_item_radius: "Raio do item da lista",
      logo_image: "Imagem do logotipo",
      male: "Masculino",
      management_panel: "Painel de gerenciamento",
      max_bitrate: "Bitrate máximo",
      max_bitrate_message: "Por favor, insira o bitrate máximo",
      max_duration: "Duração máxima",
      media_content_add_message: "Conteúdo de mídia adicionado com sucesso",
      media_content_update_message: "Conteúdo de mídia atualizado com sucesso",
      media_type: "Tipo de mídia",
      medias: "Mídias",
      menu_config: "Configuração de menu",
      menu_config_delete_message: "Você quer deletar esta configuração de menu?",
      messages: "Mensagens",
      message: "Mensagem",
      meta_data: "Metadados",
      mid_rol_ads_interval: "Intervalo de anúncios Mid-roll",
      min_bitrate: "Bitrate mínimo",
      min_bitrate_message: "Por favor, insira o bitrate mínimo",
      mobile_highlight: "Destaque móvel",
      mobile_logo: "Logo móvel",
      monetization: "Monetização",
      month: "Mês",
      most_watched_channels: "Canais mais assistidos",
      most_watched_movies: "Filmes mais assistidos",
      move_group_message:
        "Escolha o grupo para o qual serão movidos todos os usuários do grupo deletado ou deixe em branco se tiver certeza de que não há usuário no grupo deletado",
      movies: "Filmes",
      movie: "Filme",
      movie_encoder: "Codificador de filme",
      movie_excluded: "Filme excluído",
      make_video_public: "Tornar o vídeo público",

      mount_point: "Ponto de montagem",
      memory: "Memória",
      menu_config_delete_success: "Configuração de menu deletada com sucesso",
      min_version: "Versão mínima",
      max_budget: "Orçamento máximo",
      max_budget_required: "Orçamento máximo é obrigatório",
      max_budget_number: "Orçamento máximo deve ser um número",
      name: "Nome",
      name_message: "Por favor, insira um nome",
      more_info: "Mais informações",
      mid_role: "Papel intermediário",
      new: "Novo",
      new_folder: "Nova pasta",
      new_note: "Nova nota",
      new_url: "Nova URL",
      new_password: "Insira uma nova senha",
      new_translation: "Nova tradução",
      new_translation_added: "Nova tradução adicionada com sucesso",
      news: "Notícias",
      news_add_success: "Notícia adicionada com sucesso",
      news_remove_message: "Você quer remover esta notícia?",
      none: "Nenhum",
      not_close_window_message: "Por favor, não feche a janela",
      not_have_payment: "Você não tem nenhum pagamento!",
      not_have_server_message: "Você não tem nenhum servidor",
      not_protected: "Não protegido",
      not_protected_message: "Você quer tornar este vídeo não protegido?",
      not_server_message: "Você não tem nenhum servidor",
      now_watching: "Assistindo agora",
      number_message: "Por favor, insira um número",
      new_transcoding: "Nova transcodificação",
      not_shared: "Não compartilhado",
      number: "Número",
      not_archived: "Não arquivado",
      offline: "Offline",
      online: "Online",
      online_offline: "Online/Offline",
      online_users: "Usuários online",
      original_audio_language: "Idioma original do áudio",
      output: "Saída",
      output_type: "Tipo de saída",
      output_type_message: "Por favor, selecione um tipo de saída",
      package_name: "Nome do pacote",
      package_name_message: "Por favor, insira o nome do pacote",
      parental_code: "Código parental",
      part_view_count: "Contagem de visualizações parciais",
      passphrase: "Frase de segurança",
      passphrase_message: "Por favor, insira a frase de segurança",
      password: "Senha",
      password_message: "Por favor, insira uma senha",
      path: "Caminho",
      path_message: "Por favor, insira o caminho",
      paused: "Pausado",
      paypal: "PayPal",
      paypal_client_id: "ID do cliente PayPal",
      paypal_client_id_message: "Por favor, insira o ID do cliente PayPal",
      paypal_client_secret: "Segredo do cliente PayPal",
      paypal_client_secret_message: "Por favor, insira o segredo do cliente PayPal",
      paypal_config_change: "A configuração do PayPal foi alterada",
      paypal_plan_id: "ID do plano PayPal",
      paypal_webhook_id: "ID do webhook do PayPal",
      paypal_webhook_id_message: "Por favor, insira o ID do webhook do PayPal",
      payment: "Pagamento",
      payment_count: "Contagem de pagamentos",
      payment_type: "Tipo de pagamento",
      payment_type_message: "Por favor, selecione um tipo de pagamento",
      permissions_list: "Lista de permissões",
      per_channel_chart: "Gráfico por canal",
      percent: "Porcentagem",
      platform: "Plataforma",
      play: "Reproduzir",
      play_list_delete_message: "Você quer deletar esta playlist?",
      playlist: "Playlist",
      playlists: "Playlists",
      playlist_file: "Arquivo de playlist",
      playlist_url: "URL da playlist",
      please_sign_in: "Por favor, faça login abaixo.",
      please_wait: "Por favor, aguarde",
      play_market_url: "URL do Play Market",
      poster: "Pôster",
      poster_message: "Por favor, insira um pôster",
      pre_roll_ads_interval: "Intervalo de anúncios Pre-roll",
      primary: "Primário",
      primary_color_update_message: "A cor primária foi atualizada",
      pricing_message: "Por favor, insira o preço",
      pricing_plan_options: "Opções de plano de preços",
      privacy_policy: "Política de privacidade",
      profession: "Profissão",
      protected: "Protegido",
      protected_message: "Você quer proteger este vídeo?",
      publish: "Publicar",
      published: "Publicado",
      published_at: "Publicado em",
      published_date_message: "Por favor, insira a data de publicação",
      published_immediately: "Publicado imediatamente",
      platforms: "Plataformas",
      payments: "Pagamentos",
      provider: "Provedor",
      position: "Posição",
      protect: "Proteger",
      price: "Preço",
      phone_number: "Número de telefone",
      phone: "Telefone",
      phone_message: "Por favor, insira um número de telefone",
      port: "Porta",
      promo_codes: "Códigos promocionais",
      promo_code_edited: "Código promocional editado",
      promo_code_deleted: "Código promocional deletado",
      protect_genre_message: "Você quer proteger este gênero?",
      primary_color_description: "Alterar cor primária do aplicativo",
      pricing_name: "Nome do preço",
      primary_color: "Cor primária",
      pricing_name_required: "O nome do preço é obrigatório",
      pricing: "Preços",
      pricing_package_id: "ID do pacote de preços",
      pricing_package_id_required: "O ID do pacote de preços é obrigatório",
      pre_role: "Pré-papel",
      post_role: "Pós-papel",
      quiz: "Questionário",
      ram: "RAM",
      ratings: "Avaliações",
      read: "Ler",
      registered: "Registrado",
      registered_user: "Usuários registrados",
      registration_date: "Data de registro",
      release_date: "Data de lançamento",
      release_date_message: "Por favor, selecione a data de lançamento",
      rent_price: "Preço de aluguel",
      repeat_counts: "Contagens de repetição",
      repeat_counts_message: "Por favor, insira as contagens de repetição",
      reset: "Redefinir",
      reset_password: "Redefinir senha",
      resolution: "Resolução",
      resolution_name: "Nome da resolução",
      resolution_select_message: "Por favor, selecione uma resolução",
      restriction: "Restrição",
      restriction_required: "A restrição de idade é obrigatória",
      restriction_monetization: "Restrição e monetização",
      restore: "Restaurar",
      restore_live_tv_message: "Você quer restaurar a TV ao vivo selecionada?",
      restore_message: "Você quer restaurar este usuário?",
      role: "Função",
      roku: "Roku",
      regions: "Regiões",
      recordings: "Gravações",
      resolutions: "Resoluções",
      recaptcha_verification_message: "Por favor, verifique o reCAPTCHA",
      save: "Salvar",
      save_error_message:
        "Você pode atualizar este conteúdo após preencher todos os campos obrigatórios do filme ou série.",
      save_translation: "Salvar tradução",
      scheduled_time_change_message: "O horário agendado foi alterado",
      scheduling: "Agendamento",
      scheduling_time: "Horário de agendamento",
      scheduling_time_message: "Por favor, insira o horário de agendamento",
      scheduling_type: "Tipo de agendamento",
      script: "Script",
      script_message: "Por favor, insira um script",
      scripts: "Scripts",
      search_channel_placeholder: "Pesquise canal e selecione",
      search_epg_and_select: "Pesquise epg e selecione",
      search_movie_and_select: "Pesquise filme e selecione",
      search_from_tmdb: "Pesquise no TMDB",
      secondary: "Secundário",
      see_selected_movies: "Aqui estão os seus filmes selecionados",
      select: "Selecionar",
      select_a_value: "Selecione um valor",
      select_an_option: "Selecione uma opção",
      select_badges: "Selecione emblemas",
      select_channel: "Selecione um canal",
      select_channel_message: "Por favor, selecione um canal",
      select_csv_message: "Por favor, selecione um arquivo CSV",
      select_country: "Selecione um país",
      select_country_message: "Por favor, selecione um país",
      select_device_message: "Por favor, selecione um dispositivo",
      select_file: "Selecione o arquivo",
      select_language: "Selecione o idioma",
      select_live_tv: "Selecione a TV ao vivo",
      select_movies: "Selecione filmes",
      select_resolution_price: "Selecione o preço para todas as resoluções ativadas",
      select_season: "Selecione a temporada",
      select_tariff: "Selecione a tarifa",
      select_tariff_message: "Por favor, selecione a tarifa",
      selected_icon: "Ícone selecionado",
      send: "Enviar",
      send_message: "Enviar mensagem",
      series: "Séries",
      series_episode: "Série e episódio",
      server: "Servidor",
      server_message: "Por favor, insira o servidor",
      servers: "Servidores",
      service_account: "Conta de serviço",
      service_notification: "Notificação de serviço",
      set_collection_type: "Definir tipo de coleção",
      set_type: "Definir tipo",
      setup_colors_for_application: "Configurar cores para o aplicativo",
      setup_filter: "Configurar filtro",
      setup_stream: "Configurar streaming",
      service_name: "Nome do serviço",
      size: "Tamanho",
      slider: "Controle deslizante",
      smtp_configs: "Configurações SMTP",
      smtp_host: "Host SMTP",
      smtp_message: "Por favor, insira o host SMTP",
      something_wrong: "Algo deu errado",
      spent: "Gasto",
      s_tv: "S-TV",
      started: "Iniciado",
      start: "Iniciar",
      start_logo: "Logo de início",
      start_transcoding_message: "Você quer iniciar o transcodificação?",
      status: "Status",
      stop: "Parar",
      stop_transcoding_message: "Você quer parar o transcodificação?",
      storage: "Armazenamento",
      stor_link: "Link da loja",
      stream: "Transmissão",
      streams: "Transmissões",
      streamer: "Streamer",
      stream_message: "Por favor, insira a transmissão",
      stream_type: "Tipo de transmissão",
      stream_type_message: "Por favor, selecione um tipo de transmissão",
      streaming_delete_success: "Transmissão excluída com sucesso",
      streaming_pause_success: "Transmissão pausada com sucesso",
      streaming_service: "Serviço de streaming",
      streaming_started_success: "Transmissão iniciada com sucesso",
      stream_url: "URL do fluxo",
      stripe: "Stripe",
      stripe_config_change: "A configuração do Stripe foi alterada",
      stripe_plan_id: "ID do plano Stripe",
      stripe_public_key: "Chave pública Stripe",
      stripe_public_key_message: "Por favor, insira a chave pública do Stripe",
      stripe_secret_key: "Chave secreta do Stripe",
      stripe_secret_key_message: "Por favor, insira a chave secreta do Stripe",
      stripe_webhook_secret: "Segredo Webhook do Stripe",
      stripe_webhook_secret_message: "Por favor, insira o segredo Webhook do Stripe",
      subscribers: "Assinantes",
      success: "Sucesso",
      success_purchased_message: "Servidor Cloud adquirido com sucesso!",
      subtitle_track: "Faixa de legendas",
      support: "Suporte",
      supported: "Suportado",
      subscription: "Assinatura",
      settings: "Configurações",
      social_auth: "Autenticação social",
      skip_movie: "Pular filme",
      skip_episodes: "Pular episódios",
      server_name: "Nome do servidor",
      subscription_id: "ID da assinatura",
      sub_users: "Sub-usuários",
      surname: "Sobrenome",
      social_links: "Links sociais",
      sort_resolutions: "Classificar resoluções",
      state: "Estado",
      shearing_as_a_gift: "Compartilhando como um presente",
      selected_live_tv_archive_message:
        "Você quer selecionar a TV ao vivo para arquivar?",
      share: "Compartilhar",
      selected_device: "Dispositivo selecionado",
      selected_all_movies: "Todos os filmes selecionados",
      selected_all_tv: "Todas as TVs selecionadas",
      show_more: "Mostrar mais...",
      storage_info: "Informações de armazenamento",
      scheduled_restart: "Reinício agendado",
      scheduled_restart_description:
        "Tempo de configuração para reiniciar o fluxo de erros e transcodificação",
      show_georestriced_content: "Mostrar conteúdo georreferenciado",
      show_georestriced_content_description:
        "Configurar configuração para conteúdo georrestringido",
      show_all_content: "Mostrar todo o conteúdo",
      sub_user_radius: "Raio do subusuário",
      season: "Temporada",
      season_number: "Número da temporada",
      select_type_message: "Por favor, selecione um tipo",
      secret_key: "Chave secre",
      secret_key_message: "Por favor, insira a chave secreta",
      secret_key_placeholder: "Insira a chave secreta",
      start_date: "Data de início",
      start_date_required: "Data de início é obrigatória",
      select_start_date: "Selecione a data de início",
      select_end_date: "Selecione a data final",
      select_filter_id: "Selecione o ID do filtro",
      select_pricing_package_id: "Selecione o ID do pacote de preços",
      splash_screen: "Tela de abertura",
      splash_screen_ads: "Anúncios de tela de abertura",
      skipable: "Pulável",
      skip_after_seconds: "Pular após segundos",
      select_campaign: "Selecione a campanha",
      title_message: "Por favor, insira o título",
      title_message_message: "Por favor, insira a mensagem do título",
      tizen: "Tizen",
      tizen_lg: "Tizen/LG",
      total: "Total",
      top_watched: "Mais assistidos",
      trailers: "Trailers",
      translations: "Traduções",
      translation: "Tradução",
      translation_added: "Tradução adicionada",
      translation_delete_success: "Tradução excluída com sucesso",
      translation_edit_success: "Tradução editada com sucesso",
      translations_updated: "Traduções atualizadas",
      transcoding: "Transcodificação",
      transcoders: "Transcodificadores",
      transcoding_add_success: "Transcodificação adicionada com sucesso",
      transcoding_deleted_success: "Transcodificação excluída com sucesso",
      transcoding_edit_success: "Transcodificação editada com sucesso",
      transcoding_in_group: "Transcodificação em grupo",
      transcoding_service: "Serviço de transcodificação",
      transcoding_started_success: "Transcodificação iniciada com sucesso",
      transcoding_stopped_success: "Transcodificação parada com sucesso",
      transcoder: "Transcodificador",
      transcoder_paused: "Transcodificador pausado",
      transcoder_started: "Transcodificador iniciado",
      transcoder_stopped: "Transcodificador parado",
      transcoding_id_not_found:
        "Fonte interna com este ID de transcodificação não encontrada",
      tray_later:
        "Houve um erro ao recuperar pacotes. Tente novamente em alguns minutos.",
      trailer: "Trailer",
      trailer_url: "URL do trailer",
      tv_shows: "Programas de TV",
      tv_highlight: "Destaque da TV",
      tvod_payment: "Pagamento TVOD",
      tvod_pricing: "Preços TVOD",
      type: "Tipo",
      tariff: "Tarifa",
      tariffs: "Tarifas",
      tariff_created_date: "Data de criação da tarifa",
      title: "Título",
      unprotect_selected_content_message: "Você quer desproteger o conteúdo selecionado?",
      time_shift: "Deslocamento de tempo",
      title_required: "O título é obrigatório",
      unprotected: "Não protegido",
      unprotected_message: "Você quer tornar este vídeo não protegido?",
      update: "Atualizar",
      update_message: "Atualização bem-sucedida",
      update_url_message: "URLs atualizadas com sucesso",
      url_placeholder: "Insira a URL",
      unlimited_updates: "Atualizações ilimitadas",
      unlimited_users: "Usuários ilimitados",
      uploads: "Carregar",
      upload_video_file: "Carregar arquivo de vídeo",
      uptime: "Tempo de atividade",
      url: "URL",
      url_message: "Por favor, insira a URL",
      username: "Nome de usuário",
      username_message: "Por favor, insira o nome de usuário",
      user_group: "Grupo de usuários",
      user_group_message: "Por favor, selecione um grupo de usuários",
      user_icons: "Ícones de usuário",
      used: "Usado",
      un_used: "Não usado",
      users: "Usuários",
      user: "Usuário",
      user_type: "Tipo de usuário",
      updated_date: "Data de atualização",
      url_after_logout: "URL após logout",
      unspent: "Não gasto",
      unprotecd: "Não prote",
      unprotecd_genre_message: "Você quer desproteger este gênero?",
      used_percentages: "Porcentagens usadas",
      updated_successfully: "Atualizado com sucesso",
      uploads_logo: "Carregar logo",
      uploads_background: "Carregar plano de fundo",
      videos: "Vídeos",
      webos: "WebOS",
      video_track: "Faixa de vídeo",
      view: "Visualizar",
      view_list: "Ver lista",
      view_per_movie: "Visualizações por filme",
      views_duration_channel: "Duração das visualizações por canal",
      views_per_channel: "Visualizações por canal",
      visible: "Visível",
      vod: "VOD",
      vod_message: "Por favor, insira o VOD",
      version: "Versão",
      version_code: "Código da versão",
      volume_ads: "Anúncios de volume",
      video_banner_ads: "Anúncios de banner de vídeo",
      waiting: "Aguardando",
      wallet_address: "Endereço da carteira",
      web_icon: "Ícone web",
      web_page: "Página web",
      web_page_message: "Por favor, insira a página web",
      welcome: "Bem-vindo ao painel de administração {{name}}.",
      width: "Largura",
      watch: "Assistir",
      viewed_ads: "Anúncios visualizados",
      welcome_provider:
        "Faça login no painel do provedor de conteúdo para revisar as estatísticas dos seus filmes",
      year: "Ano",
      yes: "Sim",
      you_have_selected_live_tv: "Você selecionou {{selectedTvShowsCount}} TVs ao vivo",
      you_have_selected_movies:
        "Você selecionou {{selectedMoviesCount}} filmes e programas de TV",
      you_dont_have: "Você não tem",
      you_dont_have_mssage:
        "Você ainda não tem nenhum dado aqui. Por favor, clique no botão adicionar para criá-lo ou assista ao tutorial, se necessário.",
      watch_tutorial: "Assista ao tutorial",

      xpos_id: "ID do ID",
      question: "Pergunta",
      others: "Outros",
    },
  },
};
