import Axios from "axios";

const adsInstance = Axios.create({
  //   baseURL: "https://ads.inorain.tv/",
  headers: {
    "x-secret-token": "zGdaIbt5M0eRWd36r1TlWyZMzbwZezox",
    authorization: "Basic YXJtZW5oYWtvYnlhbjpBa21saTY5NjQ",
  },
  //   method:

  timeout: 20000,
});

adsInstance.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default adsInstance;
