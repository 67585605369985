import React, { useEffect, useState } from "react";
import { Drawer, Form, Input, notification } from "antd";
import styles from "../promocodeStyles.module.scss";
import ICONS from "../../../config/icons";
import ButtonComponent from "../../../_components/button/ButtonComponent";
import { useTranslation } from "react-i18next";

const DEFAULT_TITLE = "Gift Card";
const DEFAULT_MESSAGE = `Thank you for being amazing! Enjoy this gift card on us.`;

const GiftCardDrawer = ({
  open,
  onClose,
  logo,
  onSave,
  loading,
  giftCardUrl,
  allowCopy,
}) => {
  const [form] = Form.useForm();

  const { t } = useTranslation();

  const [title, setTitle] = useState(DEFAULT_TITLE);
  const [message, setMessage] = useState(DEFAULT_MESSAGE);

  const handleFinish = (values) => {
    const body = {
      personal_name: values.name,
      personal_description: values.description,
    };
    onSave(body, (hasCopy) => {
      console.log("hasCopy", hasCopy);
    });
  };

  const handleTitleChange = (e) => {
    const value = e.target.value.trim();
    setTitle(value.length > 0 ? value : DEFAULT_TITLE);
  };

  const handleMessageChange = (e) => {
    const value = e.target.value.trim();
    setMessage(value.length > 0 ? value : DEFAULT_MESSAGE);
  };

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(giftCardUrl);

    notification.success({
      message: t("success"),
      description: t("link_copied"),
    });
  };

  const handleDownLoad = () => {
    if (!giftCardUrl) {
      notification.error({
        message: t("error"),
        description: t("file_not_available"),
      });
      return;
    }

    fetch(giftCardUrl)
      .then((response) => {
        if (!response.ok) {
          throw new Error("File not found");
        }
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.download = "gift-card.png";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);

        notification.success({
          message: t("success"),
          description: t("downloaded"),
        });
      })
      .catch((error) => {
        notification.error({
          message: t("error"),
          description: t("file_not_available"),
        });
        console.error("Download failed", error);
      });
  };

  useEffect(() => {
    if (open?.personal_name && open?.personal_description) {
      setTitle(open.personal_name);
      setMessage(open.personal_description);

      form.setFieldsValue({
        name: open.personal_name,
        description: open.personal_description,
      });
    } else {
      setTitle(DEFAULT_TITLE);
      setMessage(DEFAULT_MESSAGE);
    }
  }, [open]);

  return (
    <Drawer
      title={t("gift_card")}
      placement="right"
      onClose={() => {
        form.resetFields();
        onClose();
      }}
      open={open}
      width={600}
      destroyOnClose
      footer={
        <div className={styles.footer}>
          <ButtonComponent
            title={t("copy_gift_link")}
            onClick={handleCopyUrl}
            type="default"
            icon={ICONS.ADD}
            disabled={!allowCopy}
          />
          <ButtonComponent
            title={t("download")}
            type="primary"
            icon={ICONS.ARROW_DOWN}
            onClick={handleDownLoad}
          />
        </div>
      }
    >
      <div className={styles["card"]}>
        <div className={styles["logo"]}>
          <img src={logo} alt="logo" />
        </div>
        <h1>{title}</h1>
        <p>{message}</p>
        <div className={styles["code"]}>{`${t("code")}: ${open?.code || ""}`}</div>
        <div className={styles["duration"]}>{`${t("duration")}: ${
          open?.duration + " " + open?.duration_type.toString() || ""
        }`}</div>
        <div className={styles["decoration"]}>{ICONS.PROMO_CODE_DECORATION}</div>
      </div>

      <Form
        layout="vertical"
        style={{ marginTop: 20 }}
        form={form}
        onFinish={handleFinish}
      >
        <Form.Item
          label={t("name")}
          name="name"
          rules={[
            {
              required: true,
              message: t("name_message"),
            },
          ]}
        >
          <Input
            onChange={handleTitleChange}
            value={title === DEFAULT_TITLE ? "" : title}
          />
        </Form.Item>
        <Form.Item
          label={t("description")}
          name="description"
          rules={[
            {
              required: true,
              message: t("description_message"),
            },
          ]}
        >
          <Input.TextArea
            onChange={handleMessageChange}
            value={message === DEFAULT_MESSAGE ? "" : message}
            allowClear
          />
        </Form.Item>
        <ButtonComponent
          title={t("save")}
          icon={ICONS.CHECK}
          onClick={() => form?.submit()}
          isLoading={loading}
        />
      </Form>
    </Drawer>
  );
};

export default GiftCardDrawer;
