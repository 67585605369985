import { useState } from "react";
import ButtonComponent from "../../../_components/button/ButtonComponent";
import { Space, Popconfirm } from "antd";
import icons from "../../../config/icons";
import { useTranslation } from "react-i18next";

const UserBulkAction = ({ handleAction }) => {
  const { t } = useTranslation();

  const [showConfirm, setShowConfirm] = useState({
    showLock: false,
    showUnlock: false,
    showArchive: false,
    showRestore: false,
  });
  const handleClick = (type) => {
    handleAction(type);
  };

  return (
    <div>
      <Space>
        <>
          <ButtonComponent
            type="default"
            title={t("lock")}
            icon={icons.LOCK}
            onClick={() => {
              // handleClick("lock");
              setShowConfirm({
                ...showConfirm,
                showLock: true,
              });
            }}
          />
          <Popconfirm
            open={showConfirm.showLock}
            title={t("lock_confirm_message")}
            onConfirm={() => handleClick("lock")}
            onCancel={() =>
              setShowConfirm({
                ...showConfirm,
                showLock: false,
              })
            }
          />
        </>
        <>
          <ButtonComponent
            type="default"
            title={t("unlock")}
            icon={icons.UNLOCK}
            onClick={() => {
              setShowConfirm({
                ...showConfirm,
                showUnlock: true,
              });
            }}
          />
          <Popconfirm
            open={showConfirm.showUnlock}
            title={t("unlock_confirm_message")}
            onConfirm={() => handleClick("unlock")}
            onCancel={() =>
              setShowConfirm({
                ...showConfirm,
                showUnlock: false,
              })
            }
          />
        </>
        <>
          <ButtonComponent
            type="default"
            title={t("archive")}
            icon={icons.ARCHIVE_ICON}
            onClick={() => {
              setShowConfirm({
                ...showConfirm,
                showArchive: true,
              });
            }}
          />
          <Popconfirm
            open={showConfirm.showArchive}
            title={t("archive_confirm_message")}
            onConfirm={() => handleClick("archive")}
            onCancel={() =>
              setShowConfirm({
                ...showConfirm,
                showArchive: false,
              })
            }
          />
        </>

        <>
          <ButtonComponent
            type="default"
            title={t("restore")}
            icon={icons.RESTORE}
            onClick={() => {
              setShowConfirm({
                ...showConfirm,
                showRestore: true,
              });
            }}
          />
          <Popconfirm
            open={showConfirm.showRestore}
            title={t("restore_message")}
            onConfirm={() => handleClick("restore")}
            onCancel={() =>
              setShowConfirm({
                ...showConfirm,
                showRestore: false,
              })
            }
          />
        </>
      </Space>
    </div>
  );
};

export default UserBulkAction;
