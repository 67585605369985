import { useEffect, useState } from "react";

import REQUESTS from "../../../../../api/requests";

import styles from "./_devices.module.scss";
import DeviceCard from "./DeviceCard";

export default function DevicesList() {
  const [list, setList] = useState([]);

  const getDevices = () => {
    REQUESTS.DASHBOARD.DEVICES().then((response) => {
      if (!response.error) {
        setList(response.message);
      }
    });
  };

  useEffect(() => {
    getDevices();
  }, []);

  return (
    <div style={{ marginTop: 20 }}>
      <h6>Devices</h6>
      <div className={styles["devices-list"]}>
        {list.map((item, idx) => {
          return <DeviceCard item={item} key={idx} />;
        })}
      </div>
    </div>
  );
}
