import { useState, useEffect } from "react";

import { Form, Button, Input, notification } from "antd";

import REQUESTS from "../../../../api/requests";

import styles from "../_basic-info.module.scss";
import { useTranslation } from "react-i18next";

export default function InformaitonTab({ data, getData }) {
  const { t } = useTranslation();

  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState();

  const onFinish = (values) => {
    setIsLoading(true);

    const formData = new FormData();
    formData.append("id", data.id);
    formData.append("app_name", values.app_name);
    formData.append("company_name", values.company_name);
    formData.append("contact_email", values.contact_email);
    formData.append("contact_number", values.contact_number);
    formData.append("company_address", values.company_address);
    formData.append("web_page", values.web_page);
    formData.append("help_page", values.help_page);
    formData.append("logout_url", values.logout_url);
    formData.append("description", values.description);

    REQUESTS.APPLICATION.BASIC_INFO.EDIT(formData)
      .then((data) => {
        setIsLoading(false);

        if (data.error) {
          notification.error({
            message: t("error"),
            description: data.message,
          });

          return;
        }

        notification.success({
          message: t("success"),
          description: t("app_info_update_message"),
        });

        getData(true);
      })
      .catch((error) => {
        notification.error({
          message: t("error"),

          description: error,
        });
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (data) {
      form.resetFields();

      form.setFields([
        { name: "app_name", value: data.app_name },
        { name: "company_name", value: data.company_name },
        { name: "contact_email", value: data.contact_email },
        { name: "contact_number", value: data.contact_number },
        { name: "company_address", value: data.company_address },
        { name: "web_page", value: data.web_page },
        { name: "help_page", value: data.help_page },
        { name: "logout_url", value: data.logout_url },
        { name: "description", value: data.description },
      ]);
    }
  }, [data]);

  return (
    <div className={styles["container"]}>
      <Form form={form} name="app-info" layout="vertical" onFinish={onFinish}>
        <div className={styles["tab"]}>
          <Form.Item
            label={t("app_name")}
            name="app_name"
            rules={[
              {
                required: true,
                message: t("app_name_message"),
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t("company_name")}
            name="company_name"
            rules={[
              {
                required: true,
                message: t("company_name_message"),
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t("contact_email")}
            name="contact_email"
            rules={[
              {
                required: true,
                message: t("contact_email_message"),
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t("contact_number")}
            name="contact_number"
            rules={[
              {
                required: true,
                message: t("contact_number_message"),
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t("company_address")}
            name="company_address"
            rules={[
              {
                required: true,
                message: t("company_address_message"),
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label={t("web_page")}
            name="web_page"
            rules={[
              {
                required: true,
                message: t("web_page_message"),
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t("help_page")}
            name="help_page"
            rules={[
              {
                required: true,
                message: t("help_page_message"),
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t("url_after_logout")}
            name="logout_url"
            rules={[
              {
                required: true,
                message: t("url_after_logout_message"),
              },
            ]}
          >
            <Input />
          </Form.Item>
        </div>

        <Form.Item
          label={t("description")}
          name="description"
          rules={[
            {
              required: true,
              message: t("description_message"),
            },
          ]}
        >
          <Input.TextArea rows={4} />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            onClick={() => form.submit()}
            loading={isLoading}
            style={{ width: 200 }}
          >
            {t("save")}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
