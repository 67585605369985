import { useEffect, useState } from "react";
import { useParams } from "react-router";
import REQUESTS from "../../../api/requests";
import PageComponent from "../../../_components/page/PageComponent";
import CollectionPageTable from "./CollectionPageTable";
import AddCollectionDrawer from "./drawer/AddCollectionDrawer";
import { notification, Modal } from "antd";
import PATHS from "../../../config/paths";
import { useTranslation } from "react-i18next";

const CollectionPage = () => {
  const { t } = useTranslation();

  const { id } = useParams();

  const [showDrawer, setShowDrawer] = useState(false);

  const [searchDataSource, setSearchDataSource] = useState([]);

  const [getAgain, setGetAgain] = useState(false);

  const getSearchData = (name) => {
    const params = {
      query: {
        page: 1,
        limit: 10,
      },
      collectionId: id,
    };

    if (name) {
      params.query.search = { name };
    }

    REQUESTS.VOD.COLLECTION_PAGE.COLLECTION_MOVIE_SEARCH(params)
      .then((response) => {
        setSearchDataSource(response?.message);
        setGetAgain((prev) => !prev);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleMenuClick = (e, { id }) => {
    switch (e.key) {
      case "edit":
        break;
      case "delete":
        bulkAction([id]);
        break;
      default:
        break;
    }
  };

  const handlePosition = (position, id) => {
    REQUESTS.VOD.COLLECTION_PAGE.COLLECTION_POSITION({ position, id, force: false })
      .then((response) => {
        if (response.error) {
          Modal.confirm({
            title: t("change_position_message"),
            content: (
              <div>
                {/* <p>
                  In {position} position already exists <b>{response?.message?.name}</b>{" "}
                  live tv. We can switch their positions. Do you want to do it?
                </p> */}
                {t("change_position_message_with_name", {
                  position,
                  name: response?.message?.name,
                })}
              </div>
            ),
            okText: t("change"),
            onOk() {
              REQUESTS.VOD.COLLECTION_PAGE.COLLECTION_POSITION({
                position,
                id,
                force: true,
              })
                .then((res) => {
                  if (!res.error) {
                    notification.success({
                      message: t("success"),
                      description: t("position_change_success"),
                    });

                    setGetAgain((prev) => !prev);
                  } else {
                    notification.error({
                      message: t("error"),
                      description: res.message,
                    });
                  }
                })
                .catch((err) => {
                  notification.error({
                    message: t("error"),

                    description: err.message,
                  });
                });
            },
            onCancel() {
              setGetAgain((prev) => !prev);
            },
          });
        } else {
          if (!response.error) {
            notification.success({
              message: t("success"),
              description: response?.message,
            });
          }
          setGetAgain((prev) => !prev);
        }
      })
      .catch((error) => {
        notification.error({
          message: t("error"),
          description: error,
        });

        setGetAgain((prev) => !prev);
      });
  };

  const getDataSource = (params, onSuccess, onError) => {
    REQUESTS.VOD.COLLECTION_PAGE.COLLECTION_MOVE_GET({
      query: JSON.stringify(params),
      collectionId: id,
    })
      .then((response) => {
        if (!response.error) {
          onSuccess(response || []);
        } else {
          notification.error({
            message: t("error"),
            description: response.message,
          });
        }
      })
      .catch((error) => {
        onError(error);
      });
  };

  const handleFinish = (moviesId) => {
    const parseMoviesId = moviesId.map((movie) => {
      return movie;
    });

    const body = {
      movieId: parseMoviesId.join(","),
      collectionId: id,
    };

    REQUESTS.VOD.COLLECTION_PAGE.COLLECTION_MOVE(body)
      .then((response) => {
        if (!response.error) {
          setGetAgain((prev) => !prev);
          setShowDrawer(false);
        } else {
          notification.error({
            message: t("error"),

            description: response.message,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const bulkAction = (row) => {
    console.log(row.join(","));
    const body = {
      collectionId: id,
      //   movieId: parseInt(row.join(",")),
      movieId: row.join(","),
    };
    REQUESTS.VOD.COLLECTION_PAGE.COLLECTION_MOVE_DELETE(JSON.stringify(body))
      .then((response) => {
        if (!response.error) {
          setGetAgain((prev) => !prev);
          notification.success({
            message: t("success"),

            description: response.message,
          });
        } else {
          notification.error({
            message: t("error"),

            description: response.message,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getSearchData();
  }, []);

  return (
    <PageComponent
      routes={[t("collection_edit"), t("collection")]}
      backPath={PATHS.COLLECTION}
    >
      <CollectionPageTable
        getDataSource={getDataSource}
        handleMenuClick={handleMenuClick}
        handleChangePosition={handlePosition}
        showDrawer={() => setShowDrawer(true)}
        getAgain={getAgain}
        bulkDelete={(row) => bulkAction(row)}
      />
      <AddCollectionDrawer
        open={showDrawer}
        close={() => setShowDrawer(false)}
        getMovieName={(name) => getSearchData(name)}
        searchDataSource={searchDataSource}
        finish={(selectedMovies) => handleFinish(selectedMovies)}
      />
    </PageComponent>
  );
};

export default CollectionPage;
