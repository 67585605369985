import { Form, Input, Select } from "antd";

import {
  useResolutionOptions,
  useTranscodersGroupsOptions,
  useTranscodersServersOptions,
} from "../../../../../hooks/selectOptions";

import { outputTypeOptions } from "../../../../../config/config";

import ButtonComponent from "../../../../../_components/button/ButtonComponent";
import { useTranslation } from "react-i18next";

export default function FormBlock({
  form,
  onFinish,
  isLoading,
  onSelectResolution,
  onDeselectResolution,
  setOutputType,
  setInputValue,
}) {
  const { t } = useTranslation();
  const resolutionOptions = useResolutionOptions();
  const serverOptions = useTranscodersServersOptions();
  const groupsoptions = useTranscodersGroupsOptions();

  const onSelect = (value) => {
    const finded = resolutionOptions.find((item) => item.id === value);

    onSelectResolution(finded);
  };

  const onDeselect = (value) => {
    onDeselectResolution(value);
  };

  return (
    <div style={{ width: 300 }}>
      <Form
        form={form}
        name="transcoders-transcoding"
        layout="vertical"
        onFinish={onFinish}
        // onKeyPress={(e) => {
        //     if (e.key === "Enter") {
        //         form.submit();
        //     }
        // }}
        initialValues={{
          output_type: outputTypeOptions[0].value,
        }}
      >
        <Form.Item
          label={t("name")}
          name="name"
          rules={[
            {
              required: true,
              message: t("name_message"),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t("input")}
          name="input"
          rules={[
            {
              required: true,
              message: t("input_message", { input: t("input") }),
            },
          ]}
        >
          <Input onChange={(e) => setInputValue(e.target.value)} />
        </Form.Item>

        <Form.Item
          label={t("output_type")}
          name="output_type"
          rules={[
            {
              required: true,
              message: t("output_type_message"),
            },
          ]}
        >
          <Select options={outputTypeOptions} onChange={setOutputType} />
        </Form.Item>

        <Form.Item
          label={t("group")}
          name="groupId"
          rules={[
            {
              required: true,
              message: t("group_message"),
            },
          ]}
        >
          <Select options={groupsoptions} />
        </Form.Item>

        <Form.Item
          label={t("server")}
          name="serverId"
          rules={[
            {
              required: true,
              message: t("server_select_message"),
            },
          ]}
        >
          <Select options={serverOptions} />
        </Form.Item>

        <Form.Item
          label={t("resolutions")}
          name="resolutions"
          rules={[
            {
              required: true,
              message: t("resolution_select_message"),
            },
          ]}
        >
          <Select
            mode="multiple"
            options={resolutionOptions}
            onSelect={onSelect}
            onDeselect={onDeselect}
          />
        </Form.Item>

        <Form.Item style={{ textAlign: "center" }}>
          <ButtonComponent
            title={t("save")}
            actionType="save"
            isLoading={isLoading}
            onClick={() => form.submit()}
          />
        </Form.Item>
      </Form>
    </div>
  );
}
