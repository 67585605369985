import { Tooltip } from "antd";

import { useEffect, useState } from "react";

import icons from "../../../../../../config/icons";

import {
  getColumnDateProps,
  getQueryDate,
  parseDate,
} from "../../../../../../config/config";

import TableComponent from "../../../../../../_components/table/TableComponent";
import { useTranslation } from "react-i18next";

export default function PaymentsTable({ getAgain, getDataSource, userId }) {
  const { t } = useTranslation();
  const [dataSource, setDataSource] = useState([]);

  const [loading, setLoading] = useState(false);

  const [limit, setLimit] = useState(10);

  const [currentPage, setCurrentPage] = useState(1);

  const [sort, setSort] = useState(["id", "DESC"]);

  const [total, setTotal] = useState(0);

  const [date, setDate] = useState(null);

  const columns = [
    {
      title: "#",
      width: 60,
      dataIndex: `id`,
      align: "center",
      key: "index",
      render: (record, text, index) => {
        return limit * (currentPage - 1) + index + 1;
      },
    },

    {
      title: t("amount"),
      dataIndex: "amount",
      key: "amount",
      align: "center",

      render: (text, record, index) => {
        return record.amount + " " + record.currency;
      },
    },

    {
      title: t("payment_type"),
      dataIndex: "payment_type",
      key: "payment_type",
      align: "center",
    },

    {
      title: t("description"),
      dataIndex: "description",
      key: "description",
      align: "center",
      ellipsis: true,

      render: (text, record, index) => {
        return (
          <Tooltip placement="topLeft" title={text}>
            {text}
          </Tooltip>
        );
      },
    },

    {
      title: t("created_at"),
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",

      render: (text, record, index) => {
        return parseDate(text);
      },

      ...getColumnDateProps(setDate),
    },

    {
      title: t("status"),
      key: "status",
      dataIndex: `status`,
      align: "center",
      render: (text, record, index) => {
        return record?.status ? (
          <div style={{ color: "red" }}>{icons.ARROW_DOWN}</div>
        ) : (
          <div style={{ color: "green" }}>{icons.ARROW_UP}</div>
        );
      },
    },
  ];

  const handleTableChange = (data) => {
    setCurrentPage(data.page);
    setLimit(data.limit);
    setSort(data.sort);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(true);

      const query = {
        sort,
        limit,
        page: currentPage,
        filter: {
          user_id: userId,
        },
      };

      const queryDate = getQueryDate(date);

      if (queryDate) query.between = queryDate;

      function onSuccess(data) {
        if (data.rows?.length === 0 && currentPage > 1) {
          setCurrentPage((prev) => prev - 1);
          return;
        }

        setLoading(false);
        setDataSource(data.rows);

        setTotal(data?.total);
        setLimit(data?.limit);
        setCurrentPage(data?.page);
      }

      function onError(data) {
        setLoading(false);
      }

      getDataSource(query, onSuccess, onError);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [limit, currentPage, sort, date, getAgain]);

  return (
    <TableComponent
      isLoading={loading}
      dataSource={dataSource}
      onChange={handleTableChange}
      columns={columns}
      pagination={{
        page: currentPage,
        limit: limit,
        total: total,
      }}
    />
  );
}
