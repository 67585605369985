const createResolutionConfig = (resolution) => {
  let maxBitrate = resolution?.max_bitrate / 8000;

  maxBitrate = maxBitrate?.toFixed(2);

  return {
    resolutions: resolution,
    hls_list_size: 6,
    hls_time: 6,
    input:
      "-async 1\n" +
      "-nostats\n" +
      "-hide_banner\n" +
      "-hwaccel_device 0\n" +
      "-frame_drop_threshold -1.1\n" +
      "-hwaccel cuvid\n" +
      "-deint 2\n" +
      "-drop_second_field 1",
    output:
      "-vf scale_npp='" +
      resolution?.width +
      ":" +
      resolution?.height +
      "'\n" +
      "-c:v h264_nvenc\n" +
      "-c:a copy\n" +
      "-level 4.0\n" +
      "-colorspace bt709\n" +
      "-color_trc bt709\n" +
      "-color_primaries bt709\n" +
      "-color_range tv\n" +
      "-preset fast\n" +
      "-profile:v high\n" +
      "-g 25\n" +
      `-b:v ${maxBitrate}M`,
  };
};

export default createResolutionConfig;
