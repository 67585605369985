import request from "../../../api/request";
import { appHost } from "../../../helpers/appHost";

const apiHost = appHost;

const URL = {
  GET_UPDATE: `${apiHost}v2/admin/app_branding`,
};

const appRadiusApi = {
  GET: (query) => request("GET", URL.GET_UPDATE, query),

  UPDATE: (query) => request("PUT", URL.GET_UPDATE, query),
};

export default appRadiusApi;
