import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  getAtSession,
  setInSession,
  getAtLocal,
  setInLocal,
} from "../../../helpers/storages";
import TableButtons from "../../../_components/table/TableButtons";
import TableComponent from "../../../_components/table/TableComponent";
import icons from "../../../config/icons";
import Check from "../../../_components/table/Check";
import ButtonComponent from "../../../_components/button/ButtonComponent";
import { getColumnSearchProps } from "../../../config/config";

const CampaignsTable = ({
  getDataSource,
  handleMenuClick,
  setShowDrawer,
  getAgain,
  bulkAction,
  loading,
}) => {
  const { t } = useTranslation();

  const [dataSource, setDataSource] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(10);

  const [sort, setSort] = useState(
    getAtLocal("custom_ads_campaign_filter")?.sort || ["id", "DESC"]
  );
  const [search, setSearch] = useState(
    {
      title: "",
      archived: false,
    } || getAtLocal("custom_ads_campaign_filter")?.search
  );

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      align: "center",
      fixed: "left",
      width: 50,
    },
    {
      title: t("title"),
      dataIndex: "title",
      key: "title",
      align: "center",
      width: 150,
      ellipsis: true,
      defaultFilteredValue: getAtSession("custom_ads_campaign_filter")?.search?.title,
      filteredValue: getAtSession("custom_ads_campaign_filter")?.search?.title,
      ...getColumnSearchProps(),
    },
    {
      title: t("client_id"),
      dataIndex: "client_id",
      key: "client_id",
      align: "center",
    },
    {
      title: t("start_date"),
      dataIndex: "start_date",
      key: "start_date",
      align: "center",
      render: (date) => (date ? new Date(date).toLocaleDateString() : ""),
    },
    {
      title: t("end_date"),
      dataIndex: "end_date",
      key: "end_date",
      align: "center",
      render: (date) => (date ? new Date(date).toLocaleDateString() : ""),
    },
    {
      title: t("max_budget"),
      dataIndex: "max_budget",
      key: "max_budget",
      align: "center",
      render: (budget) => `$${budget}`,
    },
    {
      title: t("earning"),
      dataIndex: "earning",
      key: "earning",
      align: "center",
      render: (earning) => `$${earning}`,
    },

    {
      title: t("filter_id"),
      dataIndex: "filter_id",
      key: "filter_id",
      align: "center",
    },
    {
      title: t("pricing_package_id"),
      dataIndex: "pricing_package_id",
      key: "pricing_package_id",
      align: "center",
      width: 150,
    },
    {
      title: t("notes"),
      dataIndex: "notes",
      key: "notes",
      align: "center",
      ellipsis: true,
      render: (notes) => (notes ? notes : "N/A"),
    },
    {
      title: t("archived"),
      dataIndex: "archived",
      key: "archived",
      align: "center",
      sorter: true,

      render: (archived) => <Check value={archived} />,
      filters: [
        { text: t("archived"), value: true },
        { text: t("not_archived"), value: false },
      ],
      onFilter: (value, record) => record.archived === value,
    },

    {
      title: "",
      key: "action",
      align: "center",
      fixed: "right",
      width: 100,
      render: (text, record) => (
        <TableButtons
          handleMenuClick={(key) => handleMenuClick(key, record)}
          items={[
            {
              key: "edit",
              name: t("edit"),
              icon: icons.EDIT,
              onConfirm: (e) => {
                handleMenuClick(e, record);
              },
            },
            {
              key: "delete",
              name: t("delete"),
              icon: icons.DELETE,
              question: t("delete_message"),
              onConfirm: (e) => {
                handleMenuClick(e, record);
              },
            },
          ]}
        />
      ),
    },
  ];

  const handleTableChange = (data) => {
    const campaign = {
      page: data.page || 1,
      search: data.search || {},
      sort: data.sort && data.sort[1] ? data.sort : ["id", "DESC"],
    };

    setCurrentPage(campaign.page);
    setLimit(data.limit);
    setSort(campaign.sort);

    setInLocal("pageLimit", campaign);

    setInSession("custom_ads_campaign_filter", {
      ...getAtSession("custom_ads_campaign_filter"),
      page: campaign.page,
      limit: data.limit,
      sort: campaign.sort,
      search: campaign.search,
    });

    const searchData = {
      ...campaign.search,
      archived: data?.search?.archived != null ? data.search.archived : false,
    };

    setSearch(searchData);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  useEffect(() => {
    const query = {
      page: currentPage,
      limit,
      filter: {
        archived: search.archived,
      },
    };

    if (search.title) {
      query.search = {
        title: search.title,
      };
    }

    if (sort[1]) {
      query.sort = sort;
    }

    const success = (data) => {
      setDataSource(data.rows);
      setLimit(data.limit);
      setCurrentPage(data.page);
    };

    const error = (err) => {
      console.log(err);
    };

    getDataSource(query, success, error);
  }, [search, sort, currentPage, getAgain]);

  return (
    <div>
      <TableComponent
        header={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            {selectedRowKeys.length > 0 && (
              <ButtonComponent
                actionType="delete"
                title={t("delete")}
                type="default"
                icon={icons.DELETE}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() => {
                  bulkAction("delete", selectedRowKeys, (response) => {
                    if (response == getAgain) {
                      setSelectedRowKeys([]);
                    }
                  });
                }}
              />
            )}
            <ButtonComponent
              type="primary"
              title={t("add_campaign")}
              icon={icons.ADD}
              onClick={() => {
                setShowDrawer(true);
              }}
            />
          </div>
        }
        columns={columns}
        dataSource={dataSource}
        onChange={handleTableChange}
        rowSelection={dataSource.length > 1 && rowSelection}
        isLoading={loading}
        pagination={{
          page: currentPage,
          total: dataSource.length,
          limit,
        }}
        scroll={{ x: 1600 }}
      />
    </div>
  );
};

export default CampaignsTable;
