import { useEffect, useState } from "react";

import REQUESTS from "../../../../../../api/requests";

import PaymentsTable from "./PaymentsTable";

export default function PaymentsTab({ user }) {
    const [getAgain, setGetAgain] = useState(false);

    const getDataSource = (query, onSuccess, onError) => {
        REQUESTS.PAYMENTS.HISTORY.GET({ query: JSON.stringify(query) })
            .then((response) => {
                onSuccess(response.message);
            })
            .catch((error) => {
                onError(error);
            });
    };

    useEffect(() => {
        setGetAgain((prev) => !prev);
    }, [user]);

    return (
        <div>
            <PaymentsTable userId={user?.id} getAgain={getAgain} getDataSource={getDataSource} />
        </div>
    );
}
