import { useNavigate } from "react-router";
import styles from "./statistics.module.scss";

export default function StatisticCard({ item }) {
  const navigate = useNavigate();

  return (
    <div className={styles["statistic-card"]} onClick={() => navigate(item.path)}>
      <div>{item.icon}</div>

      <div>
        <p className={styles["title"]}>{item.title}</p>
        <span>{item.count || 0}</span>
      </div>
    </div>
  );
}
