import { useState } from "react";

import { Input, InputNumber } from "antd";

import styles from "../../styles/transcoding-drawer.module.scss";
import { useTranslation } from "react-i18next";

export default function ResolutionConfig({ option, onChange, outputType, inputValue }) {
  const { t } = useTranslation();
  const [input, setInput] = useState(option.config?.input);
  const [output, setOutput] = useState(option.config?.output);

  const [hlsTime, setHlsTime] = useState(option.config?.hls_time);
  const [hlsListSize, setHlsListSize] = useState(option.config?.hls_list_size);
  const [resolutionSize, setResolutionSize] = useState(option.config?.resolution?.size);

  const [outputUrl, setOutputUrl] = useState(option.config?.output_url);

  return (
    <div>
      <div className={styles["header"]}>{option.name}</div>

      <div className={styles["resolution-config"]}>
        <div className={styles["container"]}>
          <div>
            <p>ffmpeg</p>

            <Input.TextArea
              name="input"
              rows="10"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              onBlur={(e) => onChange(option.id, input, "input")}
            />
          </div>

          <div style={{ marginTop: 10 }}>
            <p style={{ wordBreak: "break-all" }}>-i {inputValue}</p>

            <Input.TextArea
              name="output"
              rows="10"
              value={output}
              onChange={(e) => setOutput(e.target.value)}
              onBlur={(e) => onChange(option.id, output, "output")}
            />
          </div>

          <div className={styles["footer"]}>
            {outputType == "hls" ? (
              <div className={styles["hls"]}>
                <span>
                  -{t("start")}-{t("number")} 1 -hls_time
                </span>
                <InputNumber
                  min={0}
                  value={hlsTime}
                  onChange={setHlsTime}
                  controls={false}
                  onBlur={(e) => onChange(option.id, hlsTime, "hls_time")}
                />
                <span>-{t("hls_list_size")}</span>
                <InputNumber
                  min={0}
                  value={hlsListSize}
                  onChange={setHlsListSize}
                  controls={false}
                  onBlur={() => onChange(option.id, hlsListSize, "hls_list_size")}
                />{" "}
                <span>
                  -{t("hls_flags_delete_segments_f_HLS")} {resolutionSize}/index.m3u8
                </span>
              </div>
            ) : (
              <div>
                <span>-f mpegts</span>

                <br />

                <Input
                  placeholder="Output url"
                  value={outputUrl}
                  onChange={(e) => setOutputUrl(e.target.value)}
                  onBlur={() => onChange(option.id, outputUrl, "output_url")}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
