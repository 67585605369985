import { useState, useEffect } from "react";

import { Form, Button, Input, notification } from "antd";

import REQUESTS from "../../../../api/requests";

import style from "../style.module.scss";
import { useTranslation } from "react-i18next";

export default function AboutUsTab({ about_us }) {
  const [t] = useTranslation();

  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState();

  const onFinish = (values) => {
    setIsLoading(true);

    const query = {
      about_us: values.about_us,
    };

    REQUESTS.WEB_PAGE.SETTINGS.EDIT(query)
      .then((data) => {
        setIsLoading(false);

        if (data.error) {
          notification.error({
            message: t("error"),
            description: data.message,
          });

          return;
        }

        notification.success({
          message: t("success"),
          description: data.message,
        });
      })

      .catch((error) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (about_us) {
      form.setFields([
        {
          name: "about_us",
          value: about_us,
        },
      ]);
    }
  }, [about_us]);

  return (
    <div className={style["tab"]}>
      <p className={style["title"]}>{t("about_us")}</p>

      <Form form={form} name="web-about-us" layout="vertical" onFinish={onFinish}>
        <Form.Item name="about_us">
          <Input.TextArea rows={16} />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            onClick={() => form.submit()}
            loading={isLoading}
            style={{ width: "100%" }}
          >
            {t("save")}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
