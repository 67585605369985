import styles from "./styles.module.scss";
import volumeBg from "../image/volumeBg.jpg";

const VolumePreview = ({ data }) => {
  console.log("VolumePreview data", data);

  return (
    <div
      className={styles["volume-preview"]}
      style={{
        backgroundImage: `url(${volumeBg})`,
      }}
    >
      <div className={styles["volume-slider-wrapper"]}>
        <div className={styles["volume-slider"]}>
          <div className={styles["volume-slider__bar"]}>
            <div className={styles["volume-slider__fill"]}></div>
          </div>
          <div
            className={styles["volume-slider-ads-place"]}
            style={{
              backgroundImage: `url(${data.previewLogo})`,
            }}
          ></div>
        </div>
      </div>
    </div>
  );
};

export default VolumePreview;
