import { Badge, Divider } from "antd";
import Ratings from "./Ratings";

import Notes from "./notes/Notes";
import BasicInfo from "./BasicInfo";

import Container from "../../../../../_components/container/Container";
import NextBackButton from "../../../../../_components/button/NextBackButton";

import styles from "./_meta_data.module.scss";
import Badges from "./badges/Badges";

export default function MetaDataStep({ form, onChangeStep, editableMovieId }) {
  return (
    <div>
      <div className={styles["meta-data-step"]}>
        <BasicInfo form={form} handleStep={onChangeStep} />

        <Container>
          <Ratings form={form} />

          {editableMovieId && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 10,
              }}
            >
              <Notes editableMovieId={editableMovieId} />
              <Badges form={form} movieId={editableMovieId} />
            </div>
          )}
        </Container>
      </div>
      <Divider />

      <div style={{ width: "100%", display: "flex", justifyContent: "space-between" }}>
        <NextBackButton type="back" onClick={() => onChangeStep("back")} />
        <NextBackButton type="next" onClick={() => onChangeStep("next")} />
      </div>
    </div>
  );
}
