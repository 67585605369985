import { useParams } from "react-router";

import REQUESTS from "../../../api/requests";

import PATHS from "../../../config/paths";

import TranslationsTable from "./TranslationsTable";

import PageComponent from "../../../_components/page/PageComponent";
import { useTranslation } from "react-i18next";

export default function TranslationsPage() {
  const { t } = useTranslation();
  const params = useParams();

  const getDataSource = (query, onSuccess, onError) => {
    const id = params?.id;

    try {
      if (id) {
        REQUESTS.APPLICATION.TRANSLATIONS.GET({ query: JSON.stringify(query), id })
          .then((response) => {
            onSuccess(response.message);
          })
          .catch((error) => {
            onError(error);
          });
      }
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <PageComponent
      routes={[t("application"), t("languages"), t("translations")]}
      backPath={PATHS.APPLICATION_LANGUAGES}
    >
      <TranslationsTable getDataSource={getDataSource} />
    </PageComponent>
  );
}
