import { useEffect, useState } from "react";

import REQUESTS from "../../../../../api/requests";

import styles from "./totals.module.scss";
import Total from "./Total";
import icons from "../../../../../config/icons";
import { useTranslation } from "react-i18next";

export default function Totals() {
  const { t } = useTranslation();
  const [totals, setTotals] = useState(null);

  const getTotals = () => {
    REQUESTS.DASHBOARD.VOD.TOTALS()
      .then((response) => {
        if (!response.error) {
          setTotals(response?.message);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getTotals();
  }, []);

  return (
    <div className={styles["totals"]}>
      <Total title={t("movies")} count={totals?.totalMovies || 0} icon={icons.VOD} />

      <Total title={t("tv_shows")} count={totals?.totalTvShows || 0}>
        <div>
          <div className={styles["row"]}>
            <p>{t("season")}</p>
            <span>{totals?.totalSeasons || 0}</span>
          </div>

          <div className={styles["row"]}>
            <p>{t("episode")}</p>
            <span>{totals?.totalEpisodes || 0}</span>
          </div>
        </div>
      </Total>

      <Total title={t("genres")} count={totals?.totalGenres || 0} icon={icons.SETTINGS} />
      <Total
        title={t("collection")}
        count={totals?.totalCollections || 0}
        icon={icons.ADS}
      />
      <Total
        title={t("now_watching")}
        count={totals?.now_watching_users_count || 0}
        icon={icons.USERS}
      />
    </div>
  );
}
