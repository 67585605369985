import dayjs from "dayjs";
import { getAtLocal } from "../../../../helpers/storages";

export default function setEditableMediaInfo(data, form) {
  const genresIds = data.genres.map((genre) => genre?.label);

  const countriesNames = data?.movie?.countries?.map((country) => country.name);

  const badgesItem = data.movie.badges?.map((badge) => badge);

  const selectedSeason = data?.season?.slice(-1)[0];

  const setRating = (name) => {
    const obj = {};
    obj[name] = data?.rating?.[0]?.[name] || 0;
    return obj[name];
  };

  const mediaType = data.movie?.type ? data.movie.type : getAtLocal("mediaType");

  if (data.movie?.trailer_stream_type === "external") {
    if (data.movie.trailer_url == " ") {
      form.setFields([{ name: "trailer_url", value: "" }]);
    }
    form.setFields([{ name: "trailer_url", value: data.movie.trailer_url }]);
  }

  if (data.movie?.stream_type === "external") {
    form.setFields([{ name: "stream_url", value: data.movie.stream_url }]);
  }
  if (data.movie?.stream_url === "null") {
    form.setFields([{ name: "stream_url", value: "" }]);
  }

  if (data?.movie.license_ending_date) {
    form.setFields([
      {
        name: "license_ending_date",
        value: dayjs(new Date(data?.movie?.license_ending_date)),
      },
    ]);
  }

  if (data.movie?.channel_id) {
    form.setFields([{ name: "channel_id", value: data.movie.channel_id }]);
  }

  form.setFields([
    // Details
    { name: "name", value: data.movie.name },
    { name: "type", value: mediaType },
    { name: "description", value: data.movie.description },
    { name: "content_provider_id", value: data.movie?.content_provider_id },

    // Basic info

    {
      name: "release_date",
      value: dayjs(data?.movie?.release_date),
    },

    { name: "trailer_url_id", value: data.movie.trailer_transcoding_id },

    { name: "genres", value: genresIds },
    { name: "countries", value: countriesNames },

    {
      name: "original_audio_language",
      value: data.movie.original_audio_language || "en",
    },

    // Ratings
    { name: "tmdb", value: setRating("tmdb") || 0 },
    { name: "imdb", value: setRating("imdb") || 0 },
    { name: "metacritic", value: setRating("metacritic") || 0 },
    { name: "rotten_tomatos", value: setRating("rotten_tomatos") || 0 },

    // Stream

    {
      name: "stream_type",
      value: data?.movie?.stream_type || "external",
    },

    {
      name: "trailer_stream_type",
      value: data?.movie?.trailer_stream_type || "external",
    },
    { name: "duration", value: data.movie.duration || 0 },
    { name: "credits_time", value: data.movie.credits_time || 0 },
    { name: "intro_start_time", value: data.movie.intro_start_time || 0 },
    { name: "intro_end_time", value: data.movie.intro_end_time || 0 },

    //  Restriction
    { name: "pg", value: data.movie.pg || 0 },
    { name: "is_protected", value: data.movie.is_protected },
    // {
    //   name: "license_ending_date",
    //   value: dayjs(new Date(data?.movie?.license_ending_date)) || "",
    // },
    {
      name: "is_location_restriction",
      value: data.movie.is_location_restriction,
    },

    {
      name: "badges",
      value: badgesItem,
    },

    // Monetization
    { name: "content_monetization_type", value: data.movie.content_monetization_type },

    // season
    { name: "season", value: selectedSeason?.id },
  ]);

  if (data.movie.content_monetization_type === "tvod") {
    form.setFields([{ name: "pricing_id", value: data.movie.pricing_id }]);
  }

  if (data.movie.content_monetization_type === "svod") {
    if (data.movie.tariffIds) {
      form.setFields([{ name: "tariffIds", value: data.movie.tariffIds }]);
    }
  }

  if (data?.movie.is_location_restriction) {
    form.setFields([
      { name: "available_countries", value: data.movie.available_countries },
    ]);
  }
  // Gallery
  if (data.movie.poster) {
    form.setFields([{ name: "poster", value: data.movie?.poster }]);
  }

  if (data.movie.backdrop) {
    form.setFields([{ name: "backdrop", value: data.movie.backdrop }]);
  }

  if (data.movie.image_title) {
    form.setFields([{ name: "image_title", value: data.movie.image_title }]);
  }

  if (data.movie.highlight_tv) {
    form.setFields([{ name: "highlight_tv", value: data.movie.highlight_tv }]);
  }

  if (data.movie.poster_tv) {
    form.setFields([{ name: "poster_tv", value: data.movie.poster_tv }]);
  }

  if (data.movie.highlight_mobile) {
    form.setFields([{ name: "highlight_mobile", value: data.movie.highlight_mobile }]);
  }
  if (data.movie?.published_date > 0) {
    form.setFields([
      { name: "published_at", value: true },
      { name: "published_date", value: dayjs(new Date(data.movie?.published_date)) },
    ]);
  } else {
    form.setFields([
      { name: "published_at", value: false },
      { name: "published_date", value: "" },
    ]);
  }
}
