import { Switch, Popconfirm, Typography, Button, Tooltip } from "antd";
import { useEffect, useState } from "react";

import {
  getQueryDate,
  parseDate,
  getColumnDateProps,
  getColumnSearchProps,
} from "../../../config/config";

import TableComponent from "../../../_components/table/TableComponent";
import ButtonComponent from "../../../_components/button/ButtonComponent";
import icons from "../../../config/icons";
import TableButtons from "../../../_components/table/TableButtons";
import { useTranslation } from "react-i18next";

export default function LauncherDevicesTable({
  getAgain,
  getDataSource,
  onChange,
  activation,
  buttonLoading,
}) {
  const { t } = useTranslation();
  const [dataSource, setDataSource] = useState([]);

  const [limit, setLimit] = useState(10);

  const [loading, setLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);

  const [sort, setSort] = useState(["id", "DESC"]);

  const [date, setDate] = useState(null);

  const [total, setTotal] = useState(0);

  const [search, setSearch] = useState({
    device_id: null,
    device_model: null,
  });

  const columns = [
    {
      title: "#",
      width: 60,
      dataIndex: `id`,
      align: "center",
      key: "index",
      render: (text, record, index) => {
        return limit * (currentPage - 1) + index + 1;
      },
    },

    {
      title: t("device_id"),
      dataIndex: "device_id",
      key: "device_id",
      align: "center",

      ...getColumnSearchProps(),
    },
    {
      title: t("device_platform"),
      dataIndex: "device_model",
      key: "device_model",
      align: "center",

      ...getColumnSearchProps(),
    },

    {
      title: t("type"),
      dataIndex: "device_type",
      key: "device_type",
      align: "center",
    },

    {
      title: t("date"),
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      render: (text, record, index) => {
        return parseDate(text);
      },
      ...getColumnDateProps(setDate),
    },

    {
      title: t("blocked"),
      dataIndex: "is_blocked",
      key: "is_blocked",
      align: "center",

      render: (is_blocked, item, index) => (
        <Popconfirm
          placement="bottomRight"
          title={is_blocked ? t("activate_message") : t("blocked_message")}
          description={item.device_model}
          onConfirm={() => onChange(item)}
          okText={is_blocked ? t("activate") : t("block")}
          cancelText={t("cancel")}
        >
          <Tooltip
            // title={
            //   !item?.is_active
            //     ? "You must activate device"
            //     : is_blocked
            //     ? "To unblock"
            //     : " To block"
            // }
            title={
              !item?.is_active
                ? t("activate_device")
                : is_blocked
                ? t("unblock")
                : t("block")
            }
          >
            <Switch size="small" checked={is_blocked} disabled={!item?.is_active} />
          </Tooltip>
        </Popconfirm>
      ),
    },

    {
      title: t("activate"),
      dataIndex: "is_active",
      key: "is_active",
      align: "center",
      render: (is_active, record) =>
        !is_active ? (
          <Button
            icon={icons.ENABLE}
            onClick={() => {
              activation(record?.id);
            }}
            loading={buttonLoading}
          >
            {t("activate")}
          </Button>
        ) : (
          <Typography.Paragraph>{t("activate")}</Typography.Paragraph>
        ),
    },
  ];

  const handleTableChange = (data) => {
    setCurrentPage(data.page);
    setLimit(data.limit);
    setSort(data.sort);

    setSearch(data.search);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(true);

      const query = {
        sort,
        limit,
        page: currentPage,
      };

      if (search.device_id) {
        query.search = {
          device_id: search.device_id[0],
        };
      }

      if (search.device_model) {
        query.search = {
          device_model: search.device_model[0],
        };
      }

      const queryDate = getQueryDate(date);

      if (queryDate) query.between = queryDate;

      function onSuccess(data) {
        if (data?.rows.length === 0 && currentPage > 1) {
          setCurrentPage((prev) => prev - 1);
          return;
        }

        setLoading(false);
        setDataSource(data.rows);

        setTotal(data?.total);
        setLimit(data?.limit);
        setCurrentPage(data?.page);
      }

      function onError(data) {
        setLoading(false);
      }

      getDataSource(query, onSuccess, onError);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [limit, currentPage, sort, search, date, getAgain]);

  return (
    <TableComponent
      rowClassName={(row) => {
        if (row.is_blocked) return "red-row";
      }}
      isLoading={loading}
      dataSource={dataSource}
      onChange={handleTableChange}
      columns={columns}
      pagination={{
        page: currentPage,
        limit: limit,
        total: total,
      }}
    />
  );
}
