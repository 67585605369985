import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PageComponent from "../../../_components/page/PageComponent";
import FilterPageTable from "./FilterPageTable";
import REQUESTS from "../../../api/requests";
import {
  useMediasOptions,
  useLiveTvOptions,
  useVodGenresOptions,
  useLiveTvCategoriesOptions,
  useAdsCountriesOptions,
} from "../../../hooks/selectOptions";
import FilterDrawer from "./drawer/FilterDrawer";
import { notification } from "antd";

const CustomAdFilterPage = () => {
  const { t } = useTranslation();

  const vodOptions = useMediasOptions();
  const liveTvOptions = useLiveTvOptions();

  const vodGenresOptions = useVodGenresOptions();
  const liveTvCategoriesOptions = useLiveTvCategoriesOptions();

  const countriesOptions = useAdsCountriesOptions();

  const [showDrawer, setShowDrawer] = useState(false);
  const [editable, setEditable] = useState(null);
  const [getAgain, setGetAgain] = useState(false);
  const [loading, setLoading] = useState(false);

  const getDataSource = (query, success, error) => {
    setLoading(true);
    try {
      REQUESTS.CUSTOM_ADS.FILTERS.GETTER({ query: JSON.stringify(query) })
        .then((response) => {
          console.log("Response: ", response);
          setLoading(false);

          success(response?.data);
        })
        .catch((err) => {
          setLoading(false);

          console.log("Error: ", err);
          error(err);
        });
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  const addFilter = (values) => {
    setLoading(true);
    try {
      if (editable) {
        REQUESTS.CUSTOM_ADS.FILTERS.EDIT(values, editable.id)
          .then((response) => {
            notification.success({
              message: "Success",
              description: "Filter updated successfully",
            });
            setShowDrawer(false);
            setEditable(null);
            setLoading(false);
            setGetAgain((prev) => !prev);
          })
          .catch((error) => {
            setLoading(false);

            notification.error({
              message: "Error",
              description: error.error,
            });
            console.log("Error: ", error);
          });
      } else {
        REQUESTS.CUSTOM_ADS.FILTERS.ADD(values)
          .then((response) => {
            setLoading(false);
            notification.success({
              message: "Success",
              description: "Filter added successfully",
            });
            setShowDrawer(false);
            setGetAgain((prev) => !prev);
          })
          .catch((error) => {
            setLoading(false);
            notification.error({
              message: "Error",
              description: error.error,
            });
            console.log("Error: ", error);
          });
      }
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  const onDelete = (data, type) => {
    setLoading(true);

    try {
      let id = [];

      if (type == "many") {
        id = data.join(",");
      } else {
        id = data?.toString();
      }
      REQUESTS.CUSTOM_ADS.FILTERS.DELETE(id)
        .then((response) => {
          notification.success({
            message: "Success",
            description: "Filter deleted successfully",
          });
          setLoading(false);
          setGetAgain((prev) => !prev);
        })
        .catch((error) => {
          setLoading(false);
          notification.error({
            message: "Error",
            description: error.error,
          });
        });
    } catch (error) {
      console.log("Error: ", error);
    }
  };

  const handleBulkAction = (key, data, response) => {
    switch (key) {
      case "delete":
        onDelete(data, "many");
        response(getAgain);
        break;
      default:
        break;
    }
  };

  const handleMenuClick = (e, record) => {
    switch (e.key) {
      case "edit":
        setEditable(record);
        setShowDrawer(true);
        break;
      case "delete":
        onDelete(record.id, "one");
        break;
      default:
        break;
    }
  };

  return (
    <PageComponent routes={[t("custom_ads"), t("filters")]}>
      <FilterPageTable
        getDataSource={getDataSource}
        vodList={vodOptions}
        liveTvList={liveTvOptions}
        showDrawer={setShowDrawer}
        handleMenuClick={handleMenuClick}
        countriesOptions={countriesOptions}
        vodGenresOptions={vodGenresOptions}
        liveTvCategoriesOptions={liveTvCategoriesOptions}
        bulkAction={handleBulkAction}
        getAgain={getAgain}
        loading={loading}
      />
      <FilterDrawer
        open={showDrawer}
        onClose={() => {
          setShowDrawer(false);
          setEditable(null);
        }}
        onSave={addFilter}
        editable={editable}
        vodOPtions={vodOptions}
        liveTvOptions={liveTvOptions}
        vodGenresOptions={vodGenresOptions}
        liveTvCategoriesOptions={liveTvCategoriesOptions}
        countriesOptions={countriesOptions}
        loading={loading}
      />
    </PageComponent>
  );
};

export default CustomAdFilterPage;
