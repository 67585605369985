import adsInstance from "../../api/instace";

const host = "https://ads.inorain.tv/";

const URL = {
  ADD: `${host}admin/ads`,
};

const adsContentApi = {
  ADD: (data) => adsInstance.post(URL.ADD, data),
  UPDATE: (data, id) => adsInstance.put(`${URL.ADD}/${id}`, data),
};

export default adsContentApi;
