import { useEffect, useState } from "react";

import { Drawer, Form, InputNumber, notification, Select, Space } from "antd";

import REQUESTS from "../../../../api/requests";

import { useCatchUpServersOptions } from "../../../../hooks/selectOptions";

import ButtonComponent from "../../../../_components/ButtonComponent";

import LiveTvSearch from "../../../../_components/searches/LiveTvSearch";
import { useTranslation } from "react-i18next";

export default function AddToArchiveDrawer({ onClose, open, transcodingChannel }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const serverOptions = useCatchUpServersOptions();

  const [isLoading, setIsLoading] = useState(false);

  const [selectedChannel, setSelectedChannel] = useState(null);

  const [resolutionsOptions, setResolutionsOptions] = useState([]);

  useEffect(() => {
    if (transcodingChannel) {
      const list = transcodingChannel.resolutions.map((item) => ({
        value: item.id,
        label: item.name,
        ...item,
      }));

      setResolutionsOptions(list);
    }
  }, [transcodingChannel]);

  const onFinish = (values) => {
    setIsLoading(true);

    const formData = new FormData();

    formData.append("id", values.channelId);
    formData.append("has_archive", true);

    const resolution = resolutionsOptions.find((item) => item.id === values.resolution);

    let _archivedChannel = {
      id: selectedChannel.archivedChannel ? selectedChannel.archivedChannel.id : "",
      url: resolution.info.output,
      archiverId: values.server,
      archiveDays: values.day,
      type: "inorain",
    };

    formData.append("archivedChannels", JSON.stringify(_archivedChannel));

    REQUESTS.TRANSCODERS.TRANSCODING.ADD_TO_ARCHIVE(formData)
      .then((response) => {
        setIsLoading(false);

        if (response.error) {
          notification.error({
            message: t("error"),
            description: response.message,
          });

          return;
        }

        notification.success({
          message: t("success"),
          description: t("archive_add_success"),
        });
        onClose();
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!open) {
      form.resetFields();
      setIsLoading(false);
    }
  }, [open]);

  return (
    <Drawer
      width={400}
      title={t("add_to_archive")}
      placement="right"
      onClose={onClose}
      open={open}
    >
      <Form
        form={form}
        name="add-to-archive"
        layout="vertical"
        onFinish={onFinish}
        // onKeyPress={(e) => {
        //     if (e.key === "Enter") {
        //         form.submit();
        //     }
        // }}
      >
        <Form.Item
          labe={t("channel")}
          name="channelId"
          rules={[
            {
              required: true,
              message: t("select_channel_message"),
            },
          ]}
        >
          <LiveTvSearch onSelect={setSelectedChannel} />
        </Form.Item>

        <Space>
          <Form.Item
            label={t("day")}
            name="day"
            rules={[
              {
                required: true,
                message: t("day_message"),
              },
            ]}
          >
            <InputNumber style={{ width: 125 }} controls={false} />
          </Form.Item>

          <Form.Item
            label={t("server")}
            name="server"
            rules={[
              {
                required: true,
                message: t("server_message"),
              },
            ]}
          >
            <Select options={serverOptions} style={{ width: 220 }} />
          </Form.Item>
        </Space>

        <Form.Item
          label={t("resolution")}
          name="resolution"
          rules={[
            {
              required: true,
              message: t("resolution_select_message"),
            },
          ]}
        >
          <Select options={resolutionsOptions} />
        </Form.Item>

        <Form.Item style={{ textAlign: "center" }}>
          <ButtonComponent
            title={t("save")}
            actionType="save"
            isLoading={isLoading}
            onClick={() => form.submit()}
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
}
