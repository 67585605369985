import { useEffect, useState } from "react";

import { Drawer, Form, notification } from "antd";

import REQUESTS from "../../../../../api/requests";

import FormBlock from "./FormBlock";

import OptionsBlock from "./OptionsBlock";

import createResolutionConfig from "../../logic/createResolutionConfig";

import styles from "../../styles/transcoding-drawer.module.scss";
import returnTranscodingBody from "../../logic/transcodingRequestBody";
import initEditable from "../../logic/initEditable";
import { useTranslation } from "react-i18next";

export default function TranscodingDrawer({ onClose, open, editable, getData }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const [form] = Form.useForm();

  const [optionsList, setOptionsList] = useState([]);

  const [outputType, setOutputType] = useState("hls");

  const [inputValue, setInputValue] = useState("");

  const onSelectResolution = (resolution) => {
    const finded = optionsList.find((item) => item.id === resolution.id);

    if (!finded) {
      let newResolution = {
        id: resolution.id,
        name: resolution.name,
        config: createResolutionConfig(resolution),
      };

      setOptionsList([...optionsList, newResolution]);
    }
  };

  const onDeselectResolution = (resolutionId) => {
    setOptionsList(optionsList.filter((item) => item.id !== resolutionId));
  };

  const onChangeConfig = (id, value, type) => {
    const option = optionsList.find((item) => item.id === id);

    if (option) {
      option.config[type] = value;
    }

    setOptionsList([...optionsList]);
  };

  const onFinish = (values) => {
    const body = returnTranscodingBody(values, optionsList, editable);

    setIsLoading(true);
    if (editable) {
      REQUESTS.TRANSCODERS.TRANSCODING.EDIT(body)
        .then((response) => {
          setIsLoading(false);

          if (response.error) {
            notification.error({
              message: t("error"),
              description: response.message,
            });

            return;
          }

          notification.success({
            message: t("success"),
            description: t("transcoding_edit_success"),
          });
          onClose();
          getData();
        })
        .catch((err) => {
          notification.error({
            message: t("error"),

            description: err,
          });

          setIsLoading(false);
        });
    } else {
      REQUESTS.TRANSCODERS.TRANSCODING.ADD(body)
        .then((response) => {
          setIsLoading(false);

          if (response.error) {
            notification.error({
              message: t("error"),

              description: response.message,
            });

            return;
          }

          notification.success({
            message: t("success"),
            description: t("transcoding_add_success"),
          });
          onClose();
          getData();
        })
        .catch((err) => {
          notification.error({
            message: t("error"),

            description: err,
          });

          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (!open) {
      form.resetFields();
      setIsLoading(false);
      setOptionsList([]);
      setOutputType("hls");
      setInputValue("");
    }

    if (editable) {
      initEditable(editable, form, setOptionsList, setOutputType, setInputValue);
    }
  }, [open, editable]);

  useEffect(() => {
    setOptionsList([...optionsList]);
  }, [outputType, inputValue]);

  return (
    <Drawer
      width={optionsList.length > 0 ? 950 : "auto"}
      title={`${editable ? t("edit") : t("add")} ${t("transcoding")}`}
      placement="right"
      onClose={onClose}
      open={open}
      className={styles["transcoding-drawer"]}
      forceRender={true}
    >
      <div className={styles["body"]}>
        {optionsList.length > 0 && (
          <OptionsBlock
            inputValue={inputValue}
            outputType={outputType}
            optionsList={optionsList}
            onChangeConfig={onChangeConfig}
          />
        )}

        <FormBlock
          form={form}
          onFinish={onFinish}
          isLoading={isLoading}
          setInputValue={setInputValue}
          setOutputType={setOutputType}
          onSelectResolution={onSelectResolution}
          onDeselectResolution={onDeselectResolution}
        />
      </div>
    </Drawer>
  );
}
