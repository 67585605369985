import React, { useEffect, useState } from "react";
import requests from "../../../../server/requests";
import SelectComponent from "../../../../components/SelectComponent";
import InputComponent from "../../../../components/InputComponent";
import ButtonComponent from "../../../../components/ButtonComponent";
import { inoToast } from "../../../../components/toast/Toast";
import { createPortal } from "react-dom";
import QuestionPopup from "../../../../popups/QuestionsPopup";

export default function Notes({ id, model }) {
  const [selectedLanguage, setSelectedLanguage] = useState("");

  const [languagesOptions, setLanguagesOption] = useState([]);

  const [newNote, setNewNote] = useState("");

  const [notes, setNotes] = useState([]);

  const [editableNote, setEditableNote] = useState(null);

  const [deletableNote, setDeletableNote] = useState(null);

  const getNotes = (cb) => {
    function callback(data) {
      const languages = data.map((item) => {
        return {
          label: item.name,
          value: item.id,
          ...item,
        };
      });

      setLanguagesOption(languages);

      if (languages.length) {
        if (!selectedLanguage.id) {
          const eng = languages.find((item) => item.iso_code === "en");
          setSelectedLanguage(eng);
        } else {
          const find = languages.find(
            (item) => item.id === selectedLanguage.id
          );

          setNotes(find.not_translations);
        }
      }

      if (cb) {
        cb();
      }
    }

    function errorCallback() {}

    requests.vod.media.notes.get(id, callback, errorCallback);
  };

  const addNewNote = () => {
    const body = {
      movieId: id,
      description: newNote,
      languageId: selectedLanguage.id,
    };

    function callback() {
      setNewNote("");

      getNotes();
    }

    function errorCallback() {}

    requests.vod.media.notes.add(body, callback, errorCallback);
  };

  const editNote = () => {
    const body = {
      id: editableNote.id,
      description: editableNote.description,
    };

    function callback() {
      inoToast.success("Success");

      getNotes(() => {
        setEditableNote(null);
      });
    }

    function errorCallback() {}

    requests.vod.media.notes.edit(body, callback, errorCallback);
  };

  const deleteNote = () => {
    function callback() {
      setDeletableNote(null);

      getNotes();
    }

    function errorCallback() {}

    requests.vod.media.notes.delete(deletableNote.id, callback, errorCallback);
  };

  useEffect(() => {
    if (selectedLanguage) {
      setNotes(selectedLanguage.not_translations);
    }
  }, [selectedLanguage]);

  useEffect(() => {
    if (id) {
      getNotes();
    }
  }, [id]);

  return (
    <div style={{ borderTop: "1px solid #dee2e6", paddingTop: 10 }}>
      <label className="f-w-500 m-b-5">Translations:</label>

      <SelectComponent
        label="Language"
        value={selectedLanguage}
        options={languagesOptions}
        change={(e) => setSelectedLanguage(e)}
      />

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "end",
          marginBottom: 20,
        }}
      >
        <InputComponent
          style={{ width: "88%", marginBottom: 0 }}
          label="Note"
          name="note"
          value={newNote}
          onChange={(e) => setNewNote(e.target.value)}
        />

        <ButtonComponent action={addNewNote} />
      </div>

      <div>
        {notes.map((item) => {
          return (
            <div className="note-style">
              {(function () {
                if (editableNote?.id === item.id) {
                  return (
                    <>
                      <input
                        className="input"
                        style={{ width: "78%" }}
                        value={editableNote.description}
                        onChange={(e) =>
                          setEditableNote({
                            ...editableNote,
                            description: e.target.value,
                          })
                        }
                      />

                      <button
                        onClick={editNote}
                        className={`button-component save-button`}
                        style={{ minWidth: "auto" }}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: '<i class="fas fa-check"></i>',
                          }}
                        />
                      </button>
                    </>
                  );
                } else {
                  return (
                    <>
                      <input
                        disabled={true}
                        className="input"
                        style={{ width: "78%" }}
                        value={item.description}
                      />

                      <button
                        onClick={() => setEditableNote(item)}
                        className={`button-component edit-button`}
                        style={{ minWidth: "auto" }}
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html: '<i class="fas fa-pencil-alt"></i>',
                          }}
                        />
                      </button>
                    </>
                  );
                }
              })()}

              <ButtonComponent
                type="delete"
                action={() => setDeletableNote(item)}
              />
            </div>
          );
        })}
      </div>

      {deletableNote &&
        createPortal(
          <QuestionPopup
            type="Delete"
            title="Do you want to delete this note?"
            onClose={() => {
              setDeletableNote(null);
            }}
            action={deleteNote}
          />,

          document.body
        )}
    </div>
  );
}
