import { useEffect, useState } from "react";

import icons from "../../../config/icons";

import {
  getColumnDateProps,
  getColumnSearchProps,
  getQueryDate,
  parseDate,
} from "../../../config/config";

import TableImage from "../../../_components/table/TableImage";
import TableButtons from "../../../_components/table/TableButtons";
import TableComponent from "../../../_components/table/TableComponent";
import ButtonComponent from "../../../_components/button/ButtonComponent";
import { Button, Popconfirm, Space, Tag } from "antd";
import { setInSession, getAtSession, setInLocal } from "../../../helpers/storages";
import { useTranslation } from "react-i18next";

export default function VodGenresTable({
  getAgain,
  openDrawer,
  getDataSource,
  handleMenuClick,
  handleBulkDelete,
}) {
  const { t } = useTranslation();

  const [dataSource, setDataSource] = useState([]);

  const [limit, setLimit] = useState(10);

  const [loading, setLoading] = useState(false);

  const [isEmpty, setIsEmpty] = useState(false);

  const [currentPage, setCurrentPage] = useState(getAtSession("vodGenres")?.page || 1);

  const [sort, setSort] = useState(getAtSession("vodGenres")?.sort || ["id", "DESC"]);

  const [date, setDate] = useState(null);

  const [total, setTotal] = useState(0);

  const [search, setSearch] = useState(
    getAtSession("vodGenres")?.search || {
      name: null,
    }
  );

  const [selectionRowKeys, setSelectionRowKeys] = useState([]);
  const [openConfirm, setOpenConfirm] = useState(false);

  const columns = [
    {
      title: "#",
      width: 60,
      dataIndex: `id`,
      align: "center",
      fixed: "left",
      key: "index",
      render: (record, text, index) => {
        return limit * (currentPage - 1) + index + 1;
      },
    },

    {
      title: t("status"),
      dataIndex: "is_protected",
      key: "is_protected",
      align: "center",
      sorter: true,
      width: 150,
      render: (text, record, index) => {
        return record.is_protected ? (
          <Tag color="green">Protected</Tag>
        ) : (
          <Tag color="volcano">No Protected</Tag>
        );
      },
    },

    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
      align: "center",

      render: (text, record, index) => {
        return (
          <Space>
            <TableImage src={record.icon} />
            <span>{record.name}</span>
          </Space>
        );
      },

      ...getColumnSearchProps(),
    },

    {
      title: t("created_at"),
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",
      width: 180,

      render: (text, record, index) => {
        return parseDate(text);
      },

      ...getColumnDateProps(setDate),
    },

    {
      title: "",
      key: "operation",
      fixed: "right",
      width: 60,
      align: "center",

      render: (text, record, index) => (
        <TableButtons
          handleMenuClick={(e) => handleMenuClick(e, record)}
          items={[
            {
              key: "edit",
              name: t("edit"),
              icon: icons.EDIT,
            },

            record.is_protected
              ? {
                  key: "unprotect",
                  name: t("unprotecd"),
                  icon: icons.UNPROTECT,
                  question: t("unprotecd_genre_message"),
                  onConfirm: (e) => {
                    handleMenuClick(e, record);
                  },
                }
              : {
                  key: "protect",
                  name: t("protect"),
                  icon: icons.PROTECT,
                  question: t("protect_genre_message"),
                  onConfirm: (e) => {
                    handleMenuClick(e, record);
                  },
                },

            {
              key: "delete",
              name: t("delete"),
              icon: icons.DELETE,
              question: t("delete_genre_message"),
              onConfirm: (e) => {
                handleMenuClick(e, record);
              },
            },
          ]}
        />
      ),
    },
  ];

  const handleTableChange = (data) => {
    setCurrentPage(data.page);
    setLimit(data.limit);
    setSort(data.sort);

    setInLocal("pageLimit", data.limit);
    setInSession("vodGenres", {
      ...getAtSession("vodGenres"),
      page: data.page,
      limit: data.limit,
      sort: data.sort,
      search: data.search,
      date: data.date,
    });

    setSearch(data.search);
  };

  const handleDelete = () => {
    setOpenConfirm(true);
  };

  useEffect(() => {
    const fetchLiveTvData = () => {
      setLoading(true);

      const query = {
        // sort,
        limit,
        page: currentPage,
      };

      if (sort && sort[1] && sort[1] != "undefined") {
        query.sort = sort;
      } else {
        query.sort = ["id", "DESC"];
      }

      if (search.name) {
        query.search = {
          name: search.name,
        };
      }

      if (search.categories) {
        query.filter = {
          categoryId: search.categories,
        };
      }

      function onSuccess(data) {
        setLoading(false);

        const hasSearchKeyWithValue = () => {
          const keys = Object.keys(search);

          return keys.some((key) => search[key] && search[key][0]);
        };

        if (data?.rows?.length > 0 || hasSearchKeyWithValue()) {
          setDataSource(data?.rows);
          setIsEmpty(false);
        } else {
          setIsEmpty(true);
        }

        setTotal(data?.count);

        const maxPage = Math.ceil(data?.count / limit);
        const storedPage = getAtSession("vodGenres")?.page || 1;

        if (storedPage > maxPage || storedPage < 1) {
          setInSession("vodGenres", {
            ...getAtSession("vodGenres"),
            page: 1,
          });
          setCurrentPage(1);
        } else {
          setInSession("vodGenres", {
            ...getAtSession("vodGenres"),
            page: currentPage,
          });
        }
      }

      function onError(data) {
        setLoading(false);
      }

      getDataSource(query, onSuccess, onError);
    };

    const timeout = setTimeout(() => {
      if (getAtSession("vodGenres") && getAtSession("vodGenres")?.page > currentPage) {
        setInSession("vodGenres", {
          ...getAtSession("vodGenres"),
          page: 1,
        });
        setCurrentPage(getAtSession("vodGenres")?.page);
      }

      fetchLiveTvData();
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [limit, currentPage, sort, search, date, getAgain]);

  return (
    <>
      <TableComponent
        header={
          <>
            <ButtonComponent
              icon={icons.ADD}
              title={t("add_genre")}
              onClick={openDrawer}
            />

            {selectionRowKeys?.length > 0 && (
              <div>
                <ButtonComponent
                  icon={icons.DELETE}
                  title="Delete"
                  onClick={() => {
                    handleDelete();
                  }}
                />

                <Popconfirm
                  title={t("delete_genre_message")}
                  onConfirm={(e) => {
                    const selected = selectionRowKeys.map((item) => {
                      return item.id;
                    });
                    const parsSelected = {
                      ids: selected.join(),
                    };
                    handleBulkDelete(parsSelected.ids);

                    setOpenConfirm(false);
                    setSelectionRowKeys([]);
                  }}
                  okText={t("delete")}
                  cancelText={t("cancel")}
                  open={openConfirm}
                  onCancel={() => {
                    setOpenConfirm(false);
                  }}
                />
              </div>
            )}
          </>
        }
        isLoading={loading}
        dataSource={dataSource}
        onChange={handleTableChange}
        columns={columns}
        localeClick={openDrawer}
        isEmpty={isEmpty}
        pagination={{
          page: currentPage,
          limit: limit,
          total: total,
        }}
        rowSelection={{
          type: "checkbox",
          onChange: (selectedRowKeys, selectedRows) => {
            setSelectionRowKeys(selectedRows);
          },
        }}
      />
    </>
  );
}
