import { Drawer } from "antd";
import { useEffect, useState } from "react";

import REQUESTS from "../../../../../api/requests";

import Gallery from "./Gallery";
import GalleryUpload from "./GalleryUpload";

import styles from "./gallery_drawer.module.scss";

export default function GalleryDrawer({ open, onClose, movie }) {
    const [list, setList] = useState([]);

    const getGalley = () => {
        REQUESTS.VOD.MEDIAS.BY_ID(movie.id).then((response) => {
            if (!response.error) {
                setList(response.message.galery);
            }
        });
    };

    useEffect(() => {
        if (movie) {
            getGalley();
        }
    }, [movie]);

    useEffect(() => {
        if (!open) {
            setList([]);
        }
    }, [open]);

    return (
        <Drawer
            open={open}
            placement="right"
            onClose={onClose}
            width={700}
            title={movie ? `${movie?.name} gallery` : ""}
        >
            <div className={styles["list"]}>
                <GalleryUpload movieId={movie?.id} getData={getGalley} />

                {list.map((item) => (
                    <Gallery key={item?.id} item={item} getData={getGalley} />
                ))}
            </div>
        </Drawer>
    );
}
