export default function initEditable(editable, form, setOptionsList, setOutputType, setInputValue) {
    const resolutions = editable.resolutions.map((resolution) => resolution.id);

    form.setFieldsValue({
        name: editable.name,
        input: editable.input,
        output_type: editable.output_type,
        groupId: editable.groupId,
        serverId: editable.serverId,
        resolutions: resolutions,
    });

    if (editable.options?.length > 0) {
        const options = editable.options.map((resolution) => {
            return {
                name: resolution?.resolutions?.name,
                id: resolution.resolutions?.id,
                config: resolution,
            };
        });

        setOptionsList(options);
    }

    setOutputType(editable.output_type);

    setInputValue(editable.input);
}
