import { useEffect, useState } from "react";
import PageComponent from "../../../_components/page/PageComponent";
import REQUESTS from "../../../api/requests";

import { Divider, Tabs, notification } from "antd";
import GoogleIcon from "../../../img/google-icon.png";
import FacebookIcon from "../../../img/facebook-icon.png";
import ConfigForm from "./components/ConfigForm";
import { useTranslation } from "react-i18next";

const SocialMediaAuth = () => {
  const { t } = useTranslation();

  const [enabled, setEnabled] = useState({
    googleIsEnabled: false,
    facebookEnabled: false,
  });

  const [initialKeys, setInitialKeys] = useState({
    google_client_id: "",
    google_client_secret: "",
    facebook_client_id: "",
    facebook_client_secret: "",
  });

  const [getAgain, setGetAgain] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const tabItems = [
    {
      label: "Google",
      key: "google",
      children: (
        <ConfigForm
          isEnabled={enabled?.googleIsEnabled}
          name={{
            clientId: "google_client_id",
            secretKey: "google_client_secret",
            enable: "is_google_enabled",
          }}
          img={GoogleIcon}
          onSave={(value) => handleSave(value)}
          isLoading={isLoading}
          clientSecret={initialKeys?.google_client_secret}
          clientId={initialKeys?.google_client_id}
        />
      ),
    },
    {
      label: "Facebook",
      key: "facebook",
      children: (
        <ConfigForm
          isEnabled={enabled?.facebookEnabled}
          name={{
            clientId: "facebook_client_id",
            secretKey: "facebook_client_secret",
            enable: "is_facebook_enabled",
          }}
          img={FacebookIcon}
          onSave={(value) => handleSave(value)}
          isLoading={isLoading}
          initialKeys={initialKeys}
          clientSecret={initialKeys.facebook_client_secret}
          clientId={initialKeys.facebook_client_id}
        />
      ),
    },
  ];

  const handleSave = (value) => {
    setIsLoading(true);

    REQUESTS.SOCIAL_MEDIA.AUTH.UPDATE(JSON.stringify(value))
      .then((res) => {
        setIsLoading(false);
        setGetAgain((prev) => !prev);
        notification.success({
          success: t("success"),
          message: res.message,
        });
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);

        notification.error({
          success: t("error"),
          message: err,
        });
      });
  };

  useEffect(() => {
    REQUESTS.SOCIAL_MEDIA.AUTH.GET()
      .then((res) => {
        setEnabled({
          ...enabled,
          googleIsEnabled: res?.message?.is_google_enabled,
          facebookEnabled: res?.message?.is_facebook_enabled,
        });

        setInitialKeys({
          ...initialKeys,
          google_client_id: res?.message?.google_client_id,
          google_client_secret: res?.message?.google_client_secret,
          facebook_client_id: res?.message?.facebook_client_id,
          facebook_client_secret: res?.message.facebook_client_secret,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [getAgain]);

  return (
    <PageComponent routes={[t("settings"), t("social_auth")]}>
      <Divider />
      <Tabs items={tabItems} tabPosition="left" />
    </PageComponent>
  );
};

export default SocialMediaAuth;
