import { appHost } from "../../../helpers/appHost";
import request from "../../request";

// const host = `${appHost || "DEFAULT_REACT_API_HOST"}`;

const host = appHost;

const URL = {
  COUNTRY: `${host}movie/country`,
};

export default {
  GET: (query) => request("GET", URL.COUNTRY, query),
};
