import React, { useState, useEffect } from "react";
import { Table } from "antd";
import FilterOutlined from "@ant-design/icons/lib/icons/FilterOutlined";
import { useLocation } from "react-router";
import TableConfigPopup from "./TableConfigPopup";
import ButtonComponent from "../button/ButtonComponent";
import TableLocaleComponent from "./TableLocaleComponent";

export default function TableComponent({
  columns: initialColumns,
  dataSource,
  onChange,
  isLoading,
  pagination,
  rowKey = "id",
  header,
  localeClick,
  dropButton,
  notLocale,
  isEmpty,
  ...props
}) {
  const [showConfig, setShowConfig] = useState(false);
  const [tableColumns, setTableColumns] = useState(initialColumns);
  const [refresh, setRefresh] = useState(false);
  const { pathname } = useLocation();
  const tableId = pathname?.split("/")[2];

  useEffect(() => {
    const loadTableConfig = () => {
      try {
        const configData = JSON.parse(localStorage.getItem(`tableConfig_${tableId}`));

        if (!configData) {
          setTableColumns(initialColumns);
          return;
        }

        const updatedColumns = initialColumns.map((column) => ({
          ...column,
          visible: configData[column.key],
        }));

        setTableColumns(updatedColumns.filter((column) => column.visible !== false));
      } catch (error) {
        console.error("Error loading table configuration:", error);
      }
    };

    loadTableConfig();
  }, [refresh, showConfig, initialColumns, tableId]);

  const handleTableChange = (pagination, filters, sorter) => {
    const sortOrder =
      sorter.order === "ascend" ? "ASC" : sorter.order === "descend" ? "DESC" : undefined;
    const sort = sorter.field ? [sorter.field, sortOrder] : ["id", "DESC"];

    onChange({
      search: filters,
      page: pagination.current,
      limit: pagination.pageSize,
      sort,
    });
  };

  return (
    <div>
      {isEmpty && localeClick ? (
        <TableLocaleComponent
          click={localeClick}
          actionExist={localeClick}
          dropButton={dropButton}
        />
      ) : (
        <>
          {header && (
            <div
              style={{
                display: "flex",
                flexDirection: "row-reverse",
                width: "100%",
                marginBottom: 15,
                gap: 10,
              }}
            >
              <ButtonComponent
                style={{
                  display: "flex",
                  justifyContent: "center",
                  border: "0.1px solid #eee",
                }}
                type="secondary"
                onClick={() => setShowConfig(true)}
                icon={<FilterOutlined />}
              />
              {header}
            </div>
          )}
          <Table
            rowKey={rowKey}
            size="small"
            bordered
            columns={tableColumns}
            dataSource={dataSource}
            onChange={handleTableChange}
            loading={isLoading}
            pagination={
              pagination
                ? {
                    position: ["bottomCenter"],
                    showSizeChanger: true,
                    pageSize: pagination.limit || 10,
                    current: pagination.page || 1,
                    ...pagination,
                  }
                : false
            }
            {...props}
          />
          <TableConfigPopup
            open={showConfig}
            onClose={() => setShowConfig(false)}
            columns={initialColumns}
            tableId={tableId}
            getAgain={setRefresh}
          />
        </>
      )}
    </div>
  );
}
