import { appHost } from "../../../helpers/appHost";
import request from "../../request";

// const host = `${appHost || "DEFAULT_REACT_API_HOST"}`;
const host = appHost;

const URL = {
  HISTORY: `${host}payment/admin/history`,
};

export default {
  GET: (query) => request("GET", URL.HISTORY, query),
};
