import { useEffect, useState } from "react";

import { Drawer, Form, Input, Select, notification } from "antd";

import REQUESTS from "../../../../../../api/requests";
import ButtonComponent from "../../../../../../_components/button/ButtonComponent";
import { useTranslation } from "react-i18next";

export default function NewPlaylistDrawer({ onClose, open, getData, user }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const [subUsersOptions, setSubUsersOptions] = useState([]);

  const [form] = Form.useForm();

  const onFinish = (values) => {
    setIsLoading(true);

    const body = {
      name: values.name,
      url: values.url,
      userId: user.id,
      subId: values.subId,
    };

    REQUESTS.USERS.USER.PLAYLISTS.ADD(body)
      .then((response) => {
        setIsLoading(false);

        if (response.error) {
          notification.error({
            message: t("error"),
            description: response.message,
          });

          return;
        }

        getData();

        onClose();
      })
      .catch((err) => {
        notification.error({
          message: t("error"),

          description: err,
        });

        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!open) {
      form.resetFields();
    }
  }, [open]);

  useEffect(() => {
    if (user && user.sub_users) {
      const options = user.sub_users.map((item) => ({
        label: item.name,
        value: item.id,
      }));

      setSubUsersOptions(options);
    }
  }, [user]);

  return (
    <Drawer
      width={500}
      open={open}
      placement="right"
      onClose={onClose}
      title={t("add_playlist")}
    >
      <Form
        form={form}
        name="user-playlist"
        layout="vertical"
        onFinish={onFinish}
        // onKeyPress={(e) => {
        //     if (e.key === "Enter") {
        //         form.submit();
        //     }
        // }}
      >
        <Form.Item
          label={t("name")}
          name="name"
          rules={[
            {
              required: true,
              message: t("name_message"),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t("url")}
          name="url"
          rules={[
            {
              required: true,
              message: t("url_message"),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t("sub_user")}
          name="subId"
          rules={[
            {
              required: true,
              message: t("sub_user_message"),
            },
          ]}
        >
          <Select options={subUsersOptions} />
        </Form.Item>

        <Form.Item style={{ textAlign: "center" }}>
          <ButtonComponent
            title={t("save")}
            actionType="save"
            isLoading={isLoading}
            onClick={() => form.submit()}
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
}
