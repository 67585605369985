import { host } from "../../../server/url";

import userPng from "../../../img/user.png";

import { parseDate } from "../../../helpers/formats";

import style from "./_conversation.module.scss";
import ICONS from "../../../helpers/icons";

export default function Conversation({ item, active, onClick }) {
    return (
        <div className={`${style["conversation"]} ${active && style["active"]}`} onClick={onClick}>
            <div className={style["profile"]}>
                <div className={style["image"]}>
                    <img
                        src={item?.sub_user?.image && `${host}${item.sub_user.image}`}
                        onError={(e) => (e.target.src = userPng)}
                        alt="userPng"
                    />

                    {item.sub_online && <div className={style["online"]} />}
                </div>

                <div className={style["info"]}>
                    <div className={style["name-message-date"]}>
                        <p>{item?.sub_user?.name}</p>

                        <div className={style["last-message-date"]}>
                            {parseDate(item.last_message_date)}
                        </div>
                    </div>
                    <span>
                        ({item?.user?.name} {item?.user?.surname})
                    </span>

                    <p className={style["message"]}>
                        {item?.last_message?.type === "text" ? (
                            item?.last_message?.content
                        ) : (
                            <span>
                                {ICONS.FILE}
                                File
                            </span>
                        )}
                    </p>
                </div>
            </div>

            {item.not_read_count_admin > 0 && (
                <div className={style["not-read-count"]}>{item.not_read_count_admin}</div>
            )}
        </div>
    );
}
