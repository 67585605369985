import { useCallback, useEffect, useState } from "react";

import { notification } from "antd";

import REQUESTS from "../../../api/requests";

import VideoModal from "../../../_components/VideoModal";
import PageComponent from "../../../_components/page/PageComponent";

import Table from "./components/Table";
import AddToArchiveDrawer from "./components/AddToArchiveDrawer";
import AddToStreamingDrawer from "./components/AddToStreamingDrawer";
import TranscodingDrawer from "./components/transcoding-drawer/TranscodingDrawer";
import LogDraweromponent from "../../../_components/logDrawer/LogDrawer";
import { useTranslation } from "react-i18next";

export default function TranscoderTranscodingPage() {
  const { t } = useTranslation();
  const [getAgain, setGetAgain] = useState(false);

  const [isOpenDrawer, setIsOpenDrawer] = useState(false);

  const [channel, setChannel] = useState(null);

  const [editable, setEditable] = useState(null);

  const [playingResolution, setPlayingResolution] = useState(null);

  const [channelForAddToArchive, setChannelForAddToArchive] = useState(null);

  const [channelForAddToStreaming, setChannelForAddToStreaming] = useState(null);

  const getDataSource = (query, onSuccess, onError) => {
    try {
      REQUESTS.TRANSCODERS.TRANSCODING.GET(query)

        .then((response) => {
          if (!response.message) {
            notification.error({
              message: "Error",
              description: "Something went wrong",
            });
          } else {
            onSuccess(response.message);
          }
        })
        .catch((error) => {
          notification.error({
            message: t("error"),
            description: t("something_wrong"),
          });
          onError(error);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const handleMenuClick = (event, item) => {
    switch (event.key) {
      case "edit":
        setEditable(item);
        setIsOpenDrawer(true);
        break;

      case "add_to_archive":
        setChannelForAddToArchive(item);
        break;

      case "add_to_streaming":
        setChannelForAddToStreaming(item);
        break;

      case "delete":
        REQUESTS.TRANSCODERS.TRANSCODING.DELETE({ id: item.id })
          .then((response) => {
            if (response.error) {
              notification.error({
                message: t("error"),

                description: response.message,
              });

              return;
            }

            notification.success({
              message: t("success"),
              description: response.message,
            });

            setGetAgain((prev) => !prev);
          })
          .catch((error) => {
            notification.error({
              message: t("error"),

              description: error,
            });
          });
        break;

      default:
        break;
    }
  };

  const onPlayPause = (type, item) => {
    if (type === "play") {
      REQUESTS.TRANSCODERS.TRANSCODING.PLAY({ id: item.id })
        .then((response) => {
          if (response.error) {
            notification.error({
              message: t("error"),

              description: response.message,
            });

            return;
          }

          notification.success({
            message: t("success"),
            description: `${item.name} ${t("transcoder_started")}`,
          });

          setGetAgain((prev) => !prev);
        })
        .catch((error) => {
          notification.error({
            message: t("error"),

            description: error,
          });
        });
    } else {
      REQUESTS.TRANSCODERS.TRANSCODING.PAUSE({ id: item.id })
        .then((response) => {
          if (response.error) {
            notification.error({
              message: t("error"),

              description: response.message,
            });

            return;
          }

          notification.success({
            message: t("success"),
            description: `${item.name} ${t("transcoder_paused")}`,
          });

          setGetAgain((prev) => !prev);
        })
        .catch((error) => {
          notification.error({
            message: t("error"),
            description: error,
          });
        });
    }
  };

  // const handleBulkAction = (action, rowKey) => {
  //   const params = {
  //     id: rowKey.join(","),
  //     type: action,
  //   };
  //   console.log(params);
  // };

  const closeVideoModal = useCallback(() => {
    setPlayingResolution(null);
  }, []);

  useEffect(() => {
    let timeout = setTimeout(() => {
      setGetAgain((prev) => !prev);
    }, 10000);

    return () => {
      clearTimeout(timeout);
    };
  }, [getAgain]);

  return (
    <PageComponent routes={[t("transcoders"), t("transcoding")]}>
      <Table
        getAgain={getAgain}
        getDataSource={getDataSource}
        handleMenuClick={handleMenuClick}
        setPlayingResolution={setPlayingResolution}
        openDrawer={() => setIsOpenDrawer(true)}
        onPlayPause={onPlayPause}
        getChannel={(record) => setChannel(record)}
        // getBulkAction={(action, rowKey) => handleBulkAction(action, rowKey)}
      />

      <TranscodingDrawer
        open={isOpenDrawer}
        editable={editable}
        getData={() => setGetAgain((prev) => !prev)}
        onClose={() => {
          setIsOpenDrawer(false);
          setEditable(null);
        }}
      />

      <LogDraweromponent
        open={channel !== null ? true : false}
        onClose={() => setChannel(null)}
        channel={channel}
        url={"/channel_logs"}
        title={t("transcoder")}
      />

      <VideoModal
        open={playingResolution ? true : false}
        onClose={closeVideoModal}
        title={playingResolution?.name}
        url={playingResolution?.info?.output}
      />

      <AddToArchiveDrawer
        transcodingChannel={channelForAddToArchive}
        open={channelForAddToArchive ? true : false}
        onClose={() => setChannelForAddToArchive(null)}
      />

      <AddToStreamingDrawer
        channel={channelForAddToStreaming}
        open={channelForAddToStreaming ? true : false}
        onClose={() => setChannelForAddToStreaming(null)}
      />
    </PageComponent>
  );
}
