import { useState } from "react";

import { Button, Modal, notification } from "antd";

import REQUESTS from "../../../api/requests";

import style from "./_messages.module.scss";

export default function SendFileModal({ isOpen, onClose, file, send, activeConversationId }) {
    const [isLoading, setIsLoading] = useState(false);

    const sendFile = () => {
        if (file) {
            setIsLoading(true);

            const formData = new FormData();

            const type = file.type.split("/")[0];

            formData.append("conversation_id", activeConversationId);

            formData.append("content", file);

            if (type !== "image" && type !== "video") {
                formData.append("type", "application");
            } else {
                formData.append("type", type);
            }

            REQUESTS.MESSAGES.CHAT.SEND.FILE(formData)
                .then((data) => {
                    setIsLoading(false);
                    send();
                })

                .catch((err) => {
                    setIsLoading(false);

                    notification.error({
                        message: "Error",
                    });
                });
        }
    };

    return (
        <Modal centered open={isOpen} onCancel={onClose} footer={null} width={300}>
            <div className={style.send_file_modal_body}>
                <div className={style.file_type}>{file?.type}</div>

                <div>
                    <Button type="default" style={{ width: 100 }} onClick={onClose}>
                        Cancel
                    </Button>
                    <Button
                        type="primary"
                        style={{ width: 100, marginLeft: 10 }}
                        onClick={sendFile}
                        loading={isLoading}
                    >
                        Send
                    </Button>
                </div>
            </div>
        </Modal>
    );
}
