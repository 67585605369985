import { useEffect, useState } from "react";

import { Form, InputNumber, notification } from "antd";

import icons from "../../../../config/icons";
import REQUESTS from "../../../../api/requests";

import Upploader from "../../../../_components/uploader/Uploader";
import ButtonComponent from "../../../../_components/ButtonComponent";

import style from "../apks.module.scss";
import { useTranslation } from "react-i18next";

export default function AndroidMobileTab({ data }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);

  const onFinish = (values) => {
    setIsLoading(true);

    const formData = new FormData();

    formData.append("id", data.id);
    formData.append("application", values.apk);
    formData.append("version", values.version);

    REQUESTS.SETTINGS.APKS.UPDATE(formData)
      .then((response) => {
        setIsLoading(false);
        if (response.error) {
          notification.error({
            message: t("error"),
            description: response.message,
          });
          return;
        }
        notification.success({
          message: t("success"),
          description: t("apk_change_success"),
        });
      })
      .catch((error) => {
        setIsLoading(false);
        notification.error({
          message: t("error"),
          description: error,
        });
      });
  };

  const chooseApk = (info) => {
    form.setFields([{ name: "apk", value: info?.file?.originFileObj }]);
  };

  useEffect(() => {
    form.setFields([{ name: "version", value: data?.version }]);
  }, [data]);

  return (
    <div>
      <div className={style["tab-header"]}>
        {icons.ANDROID_MOBILE}
        <p>{t("android_mobile")}</p>
      </div>
      <Form
        form={form}
        name="android-mobile"
        layout="vertical"
        onFinish={onFinish}
        style={{ width: 300 }}
      >
        <Form.Item
          label="Version"
          name={t("version")}
          rules={[
            {
              required: true,
              message: t("version_message"),
            },
          ]}
        >
          <InputNumber controls={false} />
        </Form.Item>

        <Form.Item label="APK" name="apk">
          <Upploader accept=".apk" onChange={chooseApk} />
        </Form.Item>

        <ButtonComponent
          title={t("save")}
          actionType="save"
          isLoading={isLoading}
          onClick={() => form.submit()}
        />
      </Form>
    </div>
  );
}
