import { useState } from "react";
import { notification } from "antd";

import REQUESTS from "../../../api/requests";

import LanguagesTable from "./LanguagesTable";
import LanguageDrawer from "./LanguageDrawer";

import PageComponent from "../../../_components/page/PageComponent";
import { useTranslation } from "react-i18next";

export default function LanguagesPage() {
  const { t } = useTranslation();
  const [getAgain, setGetAgain] = useState(false);

  const [isOpenDrawer, setIsOpenDrawer] = useState(false);

  const getDataSource = (query, onSuccess, onError) => {
    try {
      REQUESTS.APPLICATION.LANGUAGES.GET({ query: JSON.stringify(query) })
        .then((response) => {
          onSuccess(response.message);
        })
        .catch((error) => {
          onError(error);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const onDisable = (id) => {
    REQUESTS.APPLICATION.LANGUAGES.DISABLE({ id })
      .then((response) => {
        if (response.error) {
          notification.error({
            message: t("error"),
            description: response.message,
          });

          return;
        }

        setGetAgain((prev) => !prev);

        notification.success({
          message: t("success"),

          description: t("language_disable_success"),
        });
      })

      .catch((error) => {
        notification.error({
          message: t("error"),

          description: error,
        });
      });
  };

  return (
    <PageComponent routes={[t("language"), t("languages")]}>
      <LanguagesTable
        getAgain={getAgain}
        getDataSource={getDataSource}
        openDrawer={() => setIsOpenDrawer(true)}
        onDisable={onDisable}
      />

      <LanguageDrawer
        open={isOpenDrawer}
        getData={() => setGetAgain((prev) => !prev)}
        onClose={() => {
          setIsOpenDrawer(false);
        }}
      />
    </PageComponent>
  );
}
