import { Checkbox, Form, Radio, Select } from "antd";

import {
  useCountriesOptions,
  useAgeRestrictionOptions,
  usePaymentsSubscriptionsOptions,
  useAddListOPtions,
} from "../../../../hooks/selectOptions";

import NextBackButton from "../../../../_components/button/NextBackButton";

import confirmIsProtected from "./confirmIsProtected";
import { useTranslation } from "react-i18next";

export default function RestrictionStep({
  form,
  setStep,
  devicesOptions,
  allDevicesIds,
  categoriesOption,
}) {
  const { t } = useTranslation();

  const countriesOptions = useCountriesOptions();
  const ageRestrictionOptions = useAgeRestrictionOptions();
  const paymentsSubscriptionsOptions = usePaymentsSubscriptionsOptions();
  const addListOptions = useAddListOPtions();

  const onSelectDevices = (selected) => {
    if (selected === "all") {
      form.setFields([{ name: "permission_devices", value: allDevicesIds }]);
    }
  };

  const onCheckGeolocationRestriction = (event) => {
    const checked = event.target.checked;

    if (!checked) {
      form.setFields([{ name: "available_countries", value: undefined }]);
    }
  };

  const onCheckIsProtected = (event) => {
    const checked = event.target.checked;

    confirmIsProtected({ form, categoriesOption, checked, t });
  };

  return (
    <div>
      <div style={{ display: "flex", gap: 40 }}>
        <Form.Item label={t("Age")} name="age_restricted" style={{ width: "20%" }}>
          <Select style={{ width: "100%" }} options={ageRestrictionOptions} />
        </Form.Item>

        <Form.Item
          label={t("content_monetization_type")}
          name="content_monetization_type"
          style={{ width: "75%" }}
        >
          <Radio.Group buttonStyle="solid">
            <Radio value="free">{t("free")}</Radio>
            <Radio value="stv">{t("s_tv")}</Radio>
          </Radio.Group>
        </Form.Item>
      </div>
      <Form.Item shouldUpdate noStyle>
        {() => {
          const { content_monetization_type } = form.getFieldsValue();

          if (content_monetization_type === "stv") {
            return (
              <Form.Item label={t("tariffs")} name="tariffIds">
                <Select
                  options={paymentsSubscriptionsOptions}
                  mode="multiple"
                  style={{ width: "100%", marginTop: 5 }}
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label.toLowerCase() ?? "").includes(input)
                  }
                  filterSort={(optionA, optionB) => {
                    return (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase());
                  }}
                />
              </Form.Item>
            );
          }
        }}
      </Form.Item>

      <Form.Item shouldUpdate noStyle>
        {() => {
          const { content_monetization_type } = form.getFieldsValue();

          if (content_monetization_type === "free") {
            return (
              <Form.Item label={t("ads")} name="adds_id">
                <Select
                  options={addListOptions}
                  mode="multiple"
                  style={{ width: "100%", marginTop: 5 }}
                  showSearch
                  filterOption={(input, option) =>
                    (option?.label.toLowerCase() ?? "").includes(input)
                  }
                  filterSort={(optionA, optionB) => {
                    return (optionA?.label ?? "")
                      .toLowerCase()
                      .localeCompare((optionB?.label ?? "").toLowerCase());
                  }}
                />
              </Form.Item>
            );
          }
        }}
      </Form.Item>

      <Form.Item
        label={t("devices")}
        name="permission_devices"
        rules={[
          {
            required: true,
            message: t("device_message"),
          },
        ]}
      >
        <Select mode="multiple" options={devicesOptions} onSelect={onSelectDevices} />
      </Form.Item>

      <div style={{ display: "flex", gap: 10, marginBottom: 15 }}>
        <Form.Item name="is_protected" noStyle valuePropName="checked">
          <Checkbox onChange={onCheckIsProtected}>{t("is_protected")}</Checkbox>
        </Form.Item>

        <Form.Item name="is_location_restriction" noStyle valuePropName="checked">
          <Checkbox onChange={onCheckGeolocationRestriction}>
            {t("geolocation_restrictions")}
          </Checkbox>
        </Form.Item>
      </div>

      <Form.Item shouldUpdate noStyle>
        {() => {
          const { is_location_restriction } = form.getFieldsValue();
          return (
            <Form.Item
              label={t("available_countries")}
              name="available_countries"
              rules={[
                {
                  required: is_location_restriction ? true : false,
                  message: t("available_countries_message"),
                },
              ]}
            >
              <Select
                mode="multiple"
                options={countriesOptions}
                disabled={!is_location_restriction}
              />
            </Form.Item>
          );
        }}
      </Form.Item>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <NextBackButton type="back" onClick={() => setStep("2")} />
        <NextBackButton type="next" onClick={() => setStep("4")} />
      </div>
    </div>
  );
}
