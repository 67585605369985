import { useSelector } from "react-redux";
import PageComponent from "../../_components/page/PageComponent";
import UsageInfoTable from "./UsageInfoTable";
import { useEffect, useState } from "react";
import REQUESTS from "../../api/requests";
import { notification } from "antd";
import { useTranslation } from "react-i18next";

const UsageInfoPage = () => {
  const { t } = useTranslation();
  const { packages } = useSelector((state) => state.globalState);

  const getDailyInfo = (query, cb, error) => {
    try {
      if (packages) {
        REQUESTS.DAILY_INFO.GET({ key: packages?.key, query }, packages?.host)
          .then((res) => {
            if (!res.error) {
              cb(res);
            }
          })
          .catch((err) => {
            error(err);
          });
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <PageComponent
      routes={[t("dashboard"), t("usage_info")]}
      backPath={"/dashboard/content"}
    >
      <div
        style={{
          marginTop: "50px",
        }}
      >
        <UsageInfoTable getDailyInfo={getDailyInfo} />
      </div>
    </PageComponent>
  );
};

export default UsageInfoPage;
