import { useEffect, useState } from "react";
import { Form, Input, Checkbox, notification } from "antd";
import ButtonComponent from "../../../_components/button/ButtonComponent";

import { getSmtpNotifications, rUpdateSmtp } from "../../../server/requests";
import PageComponent from "../../../_components/page/PageComponent";
import { useTranslation } from "react-i18next";

const SmtpConfigPAge = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);

  const onFinish = (values) => {
    setIsLoading(true);

    const body = {
      name: values.name,
      username: values.username,
      password: values.password,
      port: values.port,
      smtp_host: values.smtp_host,
      sender: values.sender,
      secure: values.secure,
    };

    function callback() {
      setIsLoading(false);
      notification.success({
        message: t("updated_successfully"),
      });
      //   onClose();
    }

    function errorCallback() {
      setIsLoading(false);
      notification.error({
        message: t("something_wrong"),
      });
    }

    rUpdateSmtp(body, callback, errorCallback);
  };

  const getData = () => {
    try {
      getSmtpNotifications((data) => {
        const { name, username, password, smtp_host, port, sender, secure } = data;

        form.setFields([
          { name: "name", value: name },
          { name: "username", value: username },
          { name: "password", value: password },
          { name: "smtp_host", value: smtp_host },
          { name: "port", value: port },
          { name: "sender", value: sender },
          { name: "secure", value: secure },
        ]);
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <PageComponent routes={[t("settings"), t("smtp_configs")]}>
      <div
        style={{
          marginBottom: 10,
          width: 500,
        }}
      >
        <Form
          form={form}
          name="users-group"
          layout="vertical"
          onFinish={onFinish}
          // onKeyPress={(e) => {
          //   if (e.key === "Enter") {
          //     form.submit();
          //   }
          // }}
        >
          <Form.Item
            label={t("name")}
            name="name"
            rules={[
              {
                required: true,
                message: t("name_message"),
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t("username")}
            name="username"
            rules={[
              {
                required: true,
                message: t("username_message"),
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t("email")}
            name="sender"
            rules={[
              {
                type: "email",
                message: t("email_valid_message"),
              },
              {
                required: true,
                message: t("email_required_message"),
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={t("password")}
            name="password"
            autocomplete="off"
            rules={[
              {
                required: true,
                message: t("password_message"),
              },
            ]}
          >
            <Input type="password" />
          </Form.Item>

          <div style={{ display: "flex", gap: 10 }}>
            <Form.Item
              label={t("smtp_host")}
              name="smtp_host"
              rules={[
                {
                  required: true,
                  message: t("smtp_message"),
                },
              ]}
              style={{ width: "70%" }}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label={t("port")}
              name="port"
              rules={[
                {
                  required: true,
                  message: t("port_message"),
                },
              ]}
              style={{ width: "30%" }}
            >
              <Input />
            </Form.Item>
          </div>

          <Form.Item name="secure" noStyle valuePropName="checked">
            <Checkbox>SSL</Checkbox>
          </Form.Item>

          <Form.Item style={{ textAlign: "center", marginTop: 20 }}>
            <ButtonComponent
              title={t("save")}
              actionType="save"
              isLoading={isLoading}
              onClick={() => form.submit()}
            />
          </Form.Item>
        </Form>
      </div>
    </PageComponent>
  );
};

export default SmtpConfigPAge;
