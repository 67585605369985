import { useState } from "react";
import { Button, notification } from "antd";
import { ColorPicker, useColor } from "react-color-palette";

import REQUESTS from "../../../api/requests";

import style from "./style.module.scss";
import { useTranslation } from "react-i18next";

export default function PrimaryColorTab({ data, getDashboardSettings }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const [color, setColor] = useColor("hex", data.value ? data.value : "green");

  const save = () => {
    setIsLoading(true);

    REQUESTS.SETTINGS.GENERAL.PRIMARY_COLOR({ color: color?.hex })
      .then((response) => {
        if (!response.error) {
          notification.success({
            message: t("success"),
            description: t("primary_color_update_message"),
          });

          getDashboardSettings();
        }

        setIsLoading(false);
      })

      .catch((err) => {
        notification.error({
          message: t("error"),
          description: t("something_wrong"),
        });

        setIsLoading(false);
      });
  };

  return (
    <div className={style["tab"]}>
      <p className={style["title"]}>{t("primary_color")}</p>

      <p className={style["description"]}>{t("primary_color_description")}</p>

      <ColorPicker
        width={320}
        height={200}
        color={color}
        onChange={setColor}
        hideHSV
        hideRGB
        dark
      />

      <Button
        type="primary"
        onClick={save}
        loading={isLoading}
        style={{ width: 320, marginTop: 10 }}
      >
        {t("save")}
      </Button>
    </div>
  );
}
