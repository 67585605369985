import { Form, Input, InputNumber, Select, Space } from "antd";
import { useTranslation } from "react-i18next";

export default function Scheduling({ form }) {
  const { t } = useTranslation();
  const options = [
    {
      value: "day",
      label: "Day",
    },
    {
      value: "month",
      label: "Month",
    },
    {
      value: "year",
      label: "Year",
    },
  ];

  return (
    <Space>
      <Form.Item
        label={t("scheduling_type")}
        name="scheduling_type"
        style={{ width: 180 }}
      >
        <Select options={options} />
      </Form.Item>

      <Form.Item style={{ textAlign: "center" }} shouldUpdate noStyle>
        {() => {
          const { scheduling_type } = form.getFieldsValue();
          return (
            <Form.Item
              label={t("scheduling_time")}
              name="scheduling_time"
              rules={[
                {
                  required: true,
                  message: t("scheduling_time_message"),
                },
              ]}
            >
              <InputNumber
                style={{ width: "100%" }}
                min={scheduling_type === "day" ? 7 : 1}
                max={scheduling_type === "day" ? 365 : 12}
                controls={false}
              />
            </Form.Item>
          );
        }}
      </Form.Item>
    </Space>
  );
}
