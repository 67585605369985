export default function initValues(permissions, form, editable) {
  form.setFields([
    { name: "type", value: editable?.type },
    { name: "content_text", value: editable.text },
    { name: "repeat_counts", value: editable.repeat_counts },
  ]);

  if (editable?.type == "IMA") {
    form.setFields([{ name: "url", value: editable?.url }]);
  } else if (editable?.type == "custom") {
    form.setFields([{ name: "transcode_url", value: editable?.url }]);
  }

  if (editable.tariffs) {
    const tariffsIds = editable.tariffs.map((item) => item.id);
    form.setFields([{ name: "tariffs_id", value: tariffsIds }]);
  }

  if (permissions?.Vod_Manager) {
    form.setFields([{ name: "all_movies", value: editable.all_movies }]);

    if (editable.movies) {
      const moviesIds = editable.movies.map((item) => item.id);
      form.setFields([{ name: "movies_id", value: moviesIds }]);
    }
  }

  if (permissions?.LIVE_TV) {
    form.setFields([{ name: "all_channels", value: editable.all_channels }]);

    if (editable.movies) {
      const liveTvIds = editable.channels.map((item) => item.id);
      form.setFields([{ name: "channels_id", value: liveTvIds }]);
    }
  }
}
