import { appHost } from "../../../helpers/appHost";
import request from "../../request";

// const host = `${appHost || "DEFAULT_REACT_API_HOST"}`;

const host = appHost;

const URL = {
  PRICING: `${host}movie/pricing`,
};

export default {
  GET: (query) => request("GET", URL.PRICING, query),

  ADD: (query) => request("POST", URL.PRICING, query),

  EDIT: (query) => request("PUT", URL.PRICING, query),

  DELETE: (query) => request("DELETE", URL.PRICING, query),
};
