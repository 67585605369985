import { Button, Popconfirm } from "antd";
import icons from "../../config/icons";

export default function DeleteConfirmButton({ name = "item", onConfirm, disabled }) {
    return (
        <Popconfirm
            placement="topRight"
            title={`Do you want to delete this ${name}`}
            onConfirm={onConfirm}
            okText="Delete"
            cancelText="Cancel"
            okButtonProps={{
                danger: true,
            }}
            disabled={disabled}
        >
            <Button type="primary" icon={icons.DELETE} danger disabled={disabled}></Button>
        </Popconfirm>
    );
}
