import { Drawer } from "antd";
import { useEffect, useState } from "react";

import LiveTvSearch from "./LiveTvSearch";

import ButtonComponent from "../../../../../../_components/button/ButtonComponent";
import { useTranslation } from "react-i18next";

export default function ByIdDrawer({ open, onClose, onAdd, actualVodService }) {
  const { t } = useTranslation();
  const [selectedsIds, setSelectedsIds] = useState([]);

  const add = () => {
    onAdd(selectedsIds);
    onClose();
  };

  useEffect(() => {
    if (!open) {
      setSelectedsIds([]);
    }
  }, [open]);

  return (
    <Drawer
      width={600}
      title={t("add_live_tv")}
      placement="right"
      onClose={onClose}
      open={open}
    >
      {open && (
        <LiveTvSearch
          selectedsIds={selectedsIds}
          setSelectedsIds={setSelectedsIds}
          filter={actualVodService}
        />
      )}

      <div style={{ marginTop: 20, textAlign: "center" }}>
        <ButtonComponent title={t("add")} actionType="add" onClick={add} />
      </div>
    </Drawer>
  );
}
