import React, { useEffect, useState } from "react";
import { createPortal } from "react-dom";

import Player from "../Player";
import { copy } from "../../../../helpers/formats";

import TableImage from "../../../../components/TableImage";
import { ActionsBlock } from "../../../../components/ActionsBlock";
import TableEmptyComponent from "../../../../components/TableEmptyComponent";
import TableLoadingComponent from "../../../../components/TableLoadingComponent";
import ActionButtonComponent from "../../../../components/ActionButtonComponent";

export default function Series({ openPopup, selectedSeason, getData, streamType }) {
  const [series, setSeries] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const [currentUrl, setCurrentUrl] = useState(null);

  useEffect(() => {
    if (selectedSeason) {
      setIsLoading(false);
      setSeries(selectedSeason.episodes);
    } else {
      setSeries([]);
    }
  }, [selectedSeason]);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  return (
    <>
      <div style={{ minHeight: 250 }}>
        <div className="row-space-between">
          <h5>Series</h5>
          <button
            className="popup-button-style add-button"
            onClick={() => openPopup("addSerie")}
          >
            <i className="fas fa-plus" style={{ marginRight: 5 }} />
            New serie
          </button>
        </div>
        <div className="fix-serie-wrap">
          <table className="table m-t-15 auto-width">
            <thead>
              <tr>
                <th>#</th>
                <th></th>
                <th>Name</th>
                <th>Number</th>
                <th>Duration</th>
                <th>Play</th>
                <th>URL</th>
                <th>Status</th>
                <th></th>
              </tr>
            </thead>

            <tbody>
              {isLoading ? (
                <TableLoadingComponent />
              ) : series.length > 0 ? (
                series.map((item, key) => {
                  return (
                    <tr key={key}>
                      <td>{key + 1}</td>
                      <td>
                        <TableImage isZoom={false} src={item.poster} />
                      </td>
                      <td>{item.name}</td>
                      <td>{item.number}</td>
                      <td>{item.duration ? item.duration : "N/A"}</td>
                      <td>
                        <div
                          className="media-play-button"
                          onClick={() => {
                            setCurrentUrl(item.stream_url);
                          }}
                        >
                          <i className="fas fa-play-circle" />
                        </div>
                      </td>
                      <td>
                        {item?.stream_url ? (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              width: "120px",
                            }}
                          >
                            <input
                              id={"series_url" + key}
                              style={{
                                width: "90p%",
                                background: "none",
                                border: "none",
                                overflowX: "hidden",
                                textOverflow: "clip",
                                whiteSpace: "nowrap",
                                cursor: "pointer",
                              }}
                              value={item?.stream_url && item?.stream_url}
                              type="text"
                              readOnly={true}
                            />
                            <div
                              onClick={() => copy("series_url" + key)}
                              className={"copy-btn"}
                            >
                              <i className={"fa fa-copy"} />
                            </div>
                          </div>
                        ) : (
                          "N/A"
                        )}
                      </td>
                      <td style={{ position: "relative" }}>
                        <ActionsBlock>
                          <ActionButtonComponent
                            title="Edit"
                            type="edit"
                            action={() => openPopup("editSerie", item, key)}
                          />

                          <ActionButtonComponent
                            title="Delete"
                            type="delete"
                            action={() => openPopup("deleteSerie", item, key)}
                          />
                        </ActionsBlock>
                      </td>
                    </tr>
                  );
                })
              ) : (
                <TableEmptyComponent />
              )}
            </tbody>
          </table>
        </div>
      </div>
      {createPortal(
        <Player
          type="hls"
          showVideoAudioSubtitleTracks={true}
          isVisible={currentUrl ? true : false}
          src={currentUrl}
          hideModal={() => {
            setCurrentUrl(null);
          }}
          useToken={streamType?.value === "internal" ? true : false}
        />,
        document.body
      )}
    </>
  );
}
