import { useEffect, useState } from "react";
import styles from "../../../styles/_charts.module.scss";
import { useTranslation } from "react-i18next";
import ChartComponent from "../../../../../_components/chart/ChartComponent";
import { Bar } from "@ant-design/plots";
import REQUESTS from "../../../../../api/requests";
import dayjs from "dayjs";

const EarningByTopRegionChart = () => {
  const { t } = useTranslation();
  const [dateRange, setDateRange] = useState({
    start_date: dayjs("2024-11-04").format("YYYY-MM-DD"),
    end_date: dayjs("2024-11-23").format("YYYY-MM-DD"),
  });

  const [data, setData] = useState({
    data: [],
    xField: "type",
    yField: "value",
    paddingRight: 80,
    style: {
      maxWidth: 25,
    },
    markBackground: {
      label: {
        text: ({ originData }) => {
          return `${(originData.value / 1000) * 100}% | ${originData.value}`;
        },
        position: "right",
        dx: 80,
        style: {
          fill: "#aaa",
          fillOpacity: 1,
          fontSize: 14,
        },
      },
      style: {
        fill: "#eee",
      },
    },
    scale: {
      y: {
        domain: [0, 1000],
      },
    },
    axis: {
      x: {
        tick: false,
        title: false,
      },
      y: {
        grid: false,
        tick: false,
        label: false,
        title: false,
      },
    },
    interaction: {
      elementHighlight: false,
    },
  });

  const handleDateRange = (date) => {
    if (!date) {
      setDateRange({
        start_date: dayjs(new Date()).format("YYYY-MM-DD"),
        end_date: dayjs(new Date()).format("YYYY-MM-DD"),
      });
    } else {
      setDateRange({
        start_date: dayjs(date[0]).format("YYYY-MM-DD"),
        end_date: dayjs(date[1]).format("YYYY-MM-DD"),
      });
    }
  };

  useEffect(() => {
    const fetchEarningByAdsTYpe = async () => {
      try {
        const response = await REQUESTS.DASHBOARD.ADS.EARNING_BY_TOP_REGIONS(dateRange);
        if (response && Array.isArray(response)) {
          const formattedData = response.map((item) => ({
            value: item.earning,
            type: item.region,
          }));

          setData({
            ...data,
            data: formattedData,
          });
        }
      } catch (err) {
        console.error("Error fetching impressions:", err);
      }
    };

    fetchEarningByAdsTYpe();
  }, [dateRange]);

  return (
    <div className={styles["chart-wrapper"]}>
      <ChartComponent
        title={t("earning_by_top_region")}
        children={<Bar {...data} />}
        dateRange={handleDateRange}
      />
    </div>
  );
};

export default EarningByTopRegionChart;
