import { useState } from "react";
import { notification } from "antd";

import REQUESTS from "../../../api/requests";

import TranscodersGroupsTable from "./TranscodersGroupsTable";
import TranscodersGroupDrawer from "./TranscodersGroupDrawer";

import PageComponent from "../../../_components/page/PageComponent";
import { useTranslation } from "react-i18next";

export default function TranscodersGroupsPage() {
  const { t } = useTranslation();

  const [getAgain, setGetAgain] = useState(false);

  const [isOpenDrawer, setIsOpenDrawer] = useState(false);

  const [editable, setEditable] = useState(null);

  const getDataSource = (query, onSuccess, onError) => {
    try {
      REQUESTS.TRANSCODERS.GROUPS.GET(query)
        .then((response) => {
          onSuccess(response.message);
        })
        .catch((error) => {
          onError(error);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const handleMenuClick = (event, item) => {
    switch (event.key) {
      case "edit":
        setEditable(item);
        setIsOpenDrawer(true);
        break;

      case "delete":
        REQUESTS.TRANSCODERS.GROUPS.DELETE({ id: item.id })
          .then((response) => {
            if (!response.error) {
              notification.success({
                message: t("success"),
                description: response.message,
              });
              setGetAgain((prev) => !prev);
            }
          })
          .catch((error) => {
            notification.error({
              message: t("error"),
              description: error,
            });
          });
        break;

      default:
        break;
    }
  };

  return (
    <PageComponent routes={[t("transcoders"), t("groups")]}>
      <TranscodersGroupsTable
        getAgain={getAgain}
        getDataSource={getDataSource}
        handleMenuClick={handleMenuClick}
        openDrawer={() => setIsOpenDrawer(true)}
      />

      <TranscodersGroupDrawer
        open={isOpenDrawer}
        editable={editable}
        getData={() => setGetAgain((prev) => !prev)}
        onClose={() => {
          setIsOpenDrawer(false);
          setEditable(null);
        }}
      />
    </PageComponent>
  );
}
