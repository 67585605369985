import { useEffect, useState } from "react";

import { Drawer, Form, Input, notification } from "antd";

import REQUESTS from "../../../../../api/requests";
import ButtonComponent from "../../../../../_components/button/ButtonComponent";
import { useTranslation } from "react-i18next";

export default function ScriptDrawer({ onClose, open, editable, getData }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const [form] = Form.useForm();

  const onFinish = (values) => {
    setIsLoading(true);

    const body = {
      name: values.name,
      script: values.script,
    };

    if (editable) {
      body.id = editable.id;

      REQUESTS.SCRIPTS.EDIT(body)
        .then((response) => {
          setIsLoading(false);

          if (response.error) {
            notification.error({
              description: response.message,
            });

            return;
          }

          getData();
          onClose();
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } else {
      REQUESTS.SCRIPTS.ADD(body)
        .then((response) => {
          setIsLoading(false);
          if (response.error) {
            notification.error({
              description: response.message,
            });
            return;
          }
          getData();
          onClose();
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (!open) {
      form.resetFields();
      setIsLoading(false);
    }

    if (editable) {
      form.setFields([
        { name: "name", value: editable.name },
        { name: "script", value: editable.script },
      ]);
    }
  }, [open, editable]);

  return (
    <Drawer
      title={`${editable ? t("edit") : t("add")} ${t("script")}`}
      placement="right"
      onClose={onClose}
      open={open}
    >
      <Form
        form={form}
        name="scripts"
        layout="vertical"
        onFinish={onFinish}
        // onKeyPress={(e) => {
        //     if (e.key === "Enter") {
        //         form.submit();
        //     }
        // }}
      >
        <Form.Item
          label={t("name")}
          name="name"
          rules={[
            {
              required: true,
              message: t("name_message"),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t("script")}
          name="script"
          rules={[
            {
              required: true,
              message: t("script_message"),
            },
          ]}
        >
          <Input.TextArea rows={10} />
        </Form.Item>

        <Form.Item style={{ textAlign: "center" }}>
          <ButtonComponent
            title={t("save")}
            actionType="save"
            isLoading={isLoading}
            onClick={() => form.submit()}
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
}
