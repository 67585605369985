import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PageComponent from "../../_components/page/PageComponent";
import REQUESTS from "../../api/requests";
import PromoCodesTable from "./PromoCodestable";
import { notification } from "antd";
import PromoCodesDrawer from "./drawer/PromoCodesDrawer";
import { usePaymentsSubscriptionsOptions } from "../../hooks/selectOptions";
import GiftCardDrawer from "./drawer/GiftCardDrawer";
import { appHost } from "../../helpers/appHost";

const PromoCodesPage = () => {
  const { t } = useTranslation();

  const tariffOptions = usePaymentsSubscriptionsOptions();

  const [loading, setLoading] = useState(false);
  const [getAgain, setGetAgain] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [editableData, setEditableData] = useState(null);
  const [showGiftDrawer, setGiftDrawer] = useState(null);
  const [giftCardLogo, setGiftCardLogo] = useState(null);
  const [giftCardUrl, setGiftCardUrl] = useState(null);
  const [allowCopy, setAllowCopy] = useState(false);

  const handleSave = (values) => {
    setLoading(true);

    if (editableData || showGiftDrawer) {
      const body = JSON.stringify(values);
      let id;

      if (showGiftDrawer) {
        id = showGiftDrawer?.id;
      } else {
        id = editableData?.id;
      }

      try {
        REQUESTS.PROMO_CODES.EDIT(id, body)
          .then((response) => {
            setLoading(false);

            if (response?.error) {
              notification.error({
                message: t("error"),
                description: t("something_wrong"),
              });
            } else {
              notification.success({
                message: t("success"),
                description: t("promo_code_edited"),
              });
              setShowDrawer(false);
              setGetAgain(!getAgain);
              setAllowCopy(true);
            }

            if (showGiftDrawer) {
              const giftUrl = `${appHost}v2/api/promo_code/cart/${showGiftDrawer?.code}`;

              setGiftCardUrl(giftUrl);
            }
          })
          .catch((error) => {
            setLoading(false);
            console.log(error);
          });
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    } else {
      try {
        REQUESTS.PROMO_CODES.ADD(values)
          .then((response) => {
            setLoading(false);
            if (response?.error) {
              notification.error({
                message: t("error"),
                description: t("something_wrong"),
              });
            } else {
              notification.success({
                message: t("success"),
                description: t("promo_code_added"),
              });
              setShowDrawer(false);
              setGetAgain(!getAgain);
            }
          })
          .catch((error) => {
            setLoading(false);
            notification.error({
              message: t("error"),
              description: t("something_wrong"),
            });
          });
      } catch (error) {
        setLoading(false);
        console.log(error);
      }
    }
  };

  const handleDelete = (id, type = null) => {
    setLoading(true);
    const body = {
      id: type == "bulk" ? id.join(",") : id?.toString(),
    };
    try {
      REQUESTS.PROMO_CODES.DELETE(JSON.stringify(body))
        .then((response) => {
          setLoading(false);
          if (response?.error) {
            notification.error({
              message: t("error"),
              description: response?.message,
            });
          } else {
            notification.success({
              message: t("success"),
              description: t("promo_code_deleted"),
            });
            setGetAgain(!getAgain);
          }
        })
        .catch((error) => {
          setLoading(false);

          notification.error({
            message: t("error"),
            description: t("something_wrong"),
          });
        });
    } catch (error) {
      setLoading(false);

      console.log(error);
      notification.error({
        message: t("error"),
        description: t("something_wrong"),
      });
    }
  };

  const handleActions = (key, record) => {
    switch (key) {
      case "edit":
        setShowDrawer(true);
        setEditableData(record);
        break;
      case "delete":
        handleDelete(record.id);

        break;
      case "bulk_delete":
        handleDelete(record, "bulk");
        break;
      default:
        break;
    }
  };

  const getPromoCodes = (query, onSuccess, onError) => {
    try {
      REQUESTS.PROMO_CODES.GET({ query })
        .then((response) => {
          setLoading(false);

          onSuccess(response.message);
        })
        .catch((error) => {
          setLoading(false);

          onError(error);
        });
    } catch (error) {
      console.log(error);
      setLoading(false);

      notification.error({
        message: t("error"),
        description: t("something_wrong"),
      });
    }
  };

  useEffect(() => {
    try {
      REQUESTS.PROMO_CODES.GET_LOGO()
        .then((response) => {
          setGiftCardLogo(response?.message?.logo);
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <PageComponent routes={[t("promo_codes")]}>
      <PromoCodesTable
        getDataSource={getPromoCodes}
        loading={loading}
        getAgain={getAgain}
        showDrawer={setShowDrawer}
        setEditable={setEditableData}
        handleClick={handleActions}
        showGiftDrawer={setGiftDrawer}
      />
      <PromoCodesDrawer
        open={showDrawer}
        onClose={() => {
          setShowDrawer(false);
          setEditableData(null);
        }}
        tariffOptions={tariffOptions}
        editableData={editableData}
        onSave={handleSave}
        loading={loading}
      />
      <GiftCardDrawer
        open={showGiftDrawer}
        onClose={() => {
          setGiftDrawer(null);
          setGiftCardUrl(null);
        }}
        logo={giftCardLogo}
        onSave={handleSave}
        loading={loading}
        giftCardUrl={giftCardUrl}
        allowCopy={allowCopy}
      />
    </PageComponent>
  );
};

export default PromoCodesPage;
