import { useSelector } from "react-redux";

import Card from "./Card";

import styles from "./_import.module.scss";
import { useTranslation } from "react-i18next";

export default function Statistics({ data }) {
  const { t } = useTranslation();
  const { permissions } = useSelector((state) => state.globalState);

  return (
    <div className={styles["statistics"]}>
      {permissions?.LIVE_TV && (
        <Card
          title={t("live_tv")}
          total={data?.channels?.total}
          done={data?.channels?.done}
        />
      )}

      {permissions?.Vod_Manager && (
        <>
          <Card
            title={t("movies")}
            total={data?.movies?.total}
            done={data?.movies?.done}
          />

          <Card
            title={t("tv_shows")}
            total={data?.series?.total}
            done={data?.series?.done}
          />
        </>
      )}
    </div>
  );
}
