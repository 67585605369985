import { Table, Image } from "antd";

import { useEffect, useState } from "react";

import REQUESTS from "../../../../../api/requests";

import styles from "./most_watched.module.scss";
import { useTranslation } from "react-i18next";

export default function MoviesList() {
  const { t } = useTranslation();
  const [movies, setMovies] = useState([]);

  const getList = () => {
    REQUESTS.DASHBOARD.VOD.MOST_WATCHED_LIST().then((response) => {
      if (!response.error) {
        let list = [];

        for (let i = 0; i < response?.message?.rows?.length; i++) {
          list.push(response?.message?.rows[i]);
        }
        setMovies(list);
      }
    });
  };

  useEffect(() => {
    getList();
  }, []);

  return (
    <div className={styles["movies-list"]}>
      {movies?.map((movie, index) => {
        return (
          <div className={styles["movie"]} key={index}>
            <div className={styles["poster"]}>
              <Image
                src={movie?.movie?.poster}
                style={{
                  width: 35,
                  height: 35,
                  borderRadius: "50%",
                  objectFit: "cover",
                }}
                alt="poster"
              />
            </div>

            <div className={styles["name"]}>
              <b style={{ fontSize: 12 }}>{movie?.movie?.name}</b>
            </div>

            <div className={styles["type"]}>
              <span style={{ color: "grey" }}>
                {movie?.movie?.type === "tv_show" ? "Tv Show" : "Movie"}
              </span>
            </div>

            <div className={styles["counter"]}>
              <span style={{ color: "grey" }}>
                <b>{movie?.counter}</b> {t("view")}
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
}
