import React from "react";
import posterPng from "../../../../img/poster.png";
import defaultImage from "../../../../img/movie.jpeg";

export default function Poster({ image, onChange, className }) {
    const imageError = (e) => (e.target.src = defaultImage);

    const onChangeImage = (event) => {
        let input = event.target;

        let image = input.parentElement.childNodes[0];

        let reader = new FileReader();

        reader.onload = function () {
            let dataURL = reader.result;
            image.src = dataURL;
        };

        reader.readAsDataURL(input.files[0]);

        onChange(input.files[0]);
    };

    return (
        <div className={`media-poster-block ${className ? className : ""}`}>
            {image ? (
                <img
                    alt="media-img"
                    src={image}
                    className="media-poster"
                    onError={imageError}
                    style={{
                        objectFit: "cover",
                    }}
                />
            ) : (
                <img
                    alt="media-img"
                    src={posterPng}
                    className="media-poster"
                    style={{ width: 40, objectFit: "contain" }}
                />
            )}

            <input
                name="poster"
                type="file"
                accept=".jpg,.png"
                className={"image-input"}
                onChange={onChangeImage}
            />

            {image && (
                <div className="remove-image" onClick={() => onChange(null)}>
                    <i className="fa fa-trash" />
                </div>
            )}
        </div>
    );
}
