import { useEffect, useState } from "react";

import { Checkbox, Drawer, Form, Input, notification } from "antd";

import REQUESTS from "../../../api/requests";

import ButtonComponent from "../../../_components/ButtonComponent";
import { useTranslation } from "react-i18next";

export default function VodContentProvidersDrawer({ onClose, open, editable, getData }) {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const [form] = Form.useForm();

  const onFinish = (values) => {
    setIsLoading(true);

    let body = {
      company_name: values.company_name,
      contact_person: values.contact_person,
      phone_number: values.phone_number,
      email: values.email,
      status: values.status ? "active" : "inactive",
    };

    if (values.password) {
      body.password = values.password;
    }

    if (editable) {
      body.id = editable.id;

      REQUESTS.VOD.CONTENT_PROVIDERS.EDIT(body)
        .then((response) => {
          setIsLoading(false);

          if (response.error) {
            notification.error({
              message: t("error"),
              description: response.message,
            });

            return;
          }

          getData();
          onClose();
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } else {
      REQUESTS.VOD.CONTENT_PROVIDERS.ADD(body)
        .then((response) => {
          setIsLoading(false);

          if (response.error) {
            notification.error({
              message: t("error"),
              description: response.message,
            });

            return;
          }
          getData();
          onClose();
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    // if (!open) {
    //   form.resetFields();

    //   setIsLoading(false);
    // }

    if (editable) {
      form.setFields([
        { name: "company_name", value: editable.company_name },
        { name: "contact_person", value: editable.contact_person },
        { name: "phone_number", value: editable.phone_number },
        { name: "email", value: editable.email },
        { name: "status", value: editable.status === "active" ? true : false },
      ]);
    }
  }, [open, editable]);

  return (
    <Drawer
      title={`${editable ? t("edit") : t("add")} ${t("provider")?.toLocaleLowerCase()}`}
      placement="right"
      destroyOnClose
      onClose={() => {
        onClose();
        form.resetFields();
      }}
      open={open}
    >
      <Form
        form={form}
        name="vod-content-provider"
        layout="vertical"
        onFinish={onFinish}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            form.submit();
          }
        }}
      >
        <Form.Item
          label={t("company_name")}
          name="company_name"
          rules={[
            {
              required: true,
              message: t("company_name_message"),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t("contact_person")}
          name="contact_person"
          rules={[
            {
              required: true,
              message: t("contact_person_message"),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label={t("phone_number")} name="phone_number">
          <Input />
        </Form.Item>

        <Form.Item
          label={t("email")}
          name="email"
          rules={[
            {
              required: true,
              message: t("email_message"),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t("password")}
          name="password"
          rules={[
            {
              required: !editable,
              message: t("password_message"),
            },
          ]}
        >
          <Input.Password />
        </Form.Item>

        <Form.Item noStyle valuePropName="checked" name="status">
          <Checkbox>{t("Active")}</Checkbox>
        </Form.Item>

        <Form.Item style={{ textAlign: "center" }}>
          <ButtonComponent
            title={t("save")}
            actionType="save"
            isLoading={isLoading}
            onClick={() => form.submit()}
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
}
