import { useEffect, useState } from "react";

import REQUESTS from "../../../api/requests";

import Title from "../../../_components/Title";

import TableComponent from "../../../_components/table/TableComponent";

import returnColumns from "./columns";
import { useTranslation } from "react-i18next";

export default function ServiceNotificationsPage() {
  const { t } = useTranslation();
  const [dataSource, setDataSource] = useState();

  const [isLoading, setIsLoading] = useState(false);

  const [query, setQuery] = useState({
    page: 1,
    limit: 10,
    sort: ["is_archive", "ASC"],
  });

  const getServicesNotifications = () => {
    setIsLoading(true);

    try {
      REQUESTS.SETTINGS.GENERAL.SERVICE_NOTIFICATION.GET({
        query: JSON.stringify(query),
      })
        .then((response) => {
          setIsLoading(false);

          if (response.error) return;

          setDataSource(response.message.rows);
        })
        .catch((error) => {
          setIsLoading(false);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const handleTableChange = (data) => {
    setQuery((prev) => ({
      ...prev,
      page: data.page,
      limit: data.limit,
    }));
  };

  useEffect(() => {
    getServicesNotifications();
  }, [query]);

  return (
    <div>
      <Title text={t("service_notification")} />

      <TableComponent
        isLoading={isLoading}
        dataSource={dataSource}
        onChange={handleTableChange}
        columns={returnColumns(t)}
        pagination={{
          page: query.page,
          limit: query.limit,
        }}
        rowClassName={(record) => {
          if (!record.is_archive) return "table-green-row";
        }}
      />
    </div>
  );
}
