import { Button, Dropdown, notification, Popconfirm } from "antd";
import style from "./_quick-messages.module.scss";

import { EllipsisOutlined } from "@ant-design/icons";

export default function QuickMessage({ item, deleteQuickMessage, onClick, onEdit }) {
    const items = [
        {
            key: "1",
            label: <div onClick={onEdit}>Edit</div>,
        },
        {
            key: "2",
            label: (
                <Popconfirm
                    title="Delete the quick message"
                    description="Are you sure to delete this quick message?"
                    onConfirm={() => deleteQuickMessage(item.id)}
                    okText="Delete"
                    okType="danger"
                    cancelText="Cancel"
                >
                    {/* <div className={style["remove"]} onClick={(e) => e.stopPropagation()}>
                    <i className="fas fa-trash-alt" />
                </div> */}
                    <div>Delete</div>
                </Popconfirm>
            ),
        },
    ];

    return (
        <div className={style["quick-message"]}>
            <div className={style["text"]} onClick={onClick}>
                {item.message}
            </div>

            <Dropdown
                menu={{
                    items,
                }}
                trigger="click"
                placement="bottomRight"
                arrow={{
                    pointAtCenter: true,
                }}
                onClick={(e) => e.stopPropagation()}
            >
                <div className={style["menu"]}>
                    <EllipsisOutlined />
                </div>
                {/* <Button>bottomRight</Button> */}
            </Dropdown>
        </div>
    );
}
