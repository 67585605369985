export default function returnTranscodingBody(values, optionsList, editable) {
    const options = optionsList.map((option) => option.config);

    if (values.output_type === "hls") {
        for (let i = 0; i < options.length; i++) {
            if (options[i].output_url) {
                delete options[i].output_url;
            }
        }
    }

    let body = {
        groupId: values.groupId,
        input: values.input,
        name: values.name,
        serverId: values.serverId,
        resolutions: values.resolutions,
        output_type: values.output_type,
        options: options,
    };

    if (editable) {
        body.id = editable.id;
    }

    return body;
}
