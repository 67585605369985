import { useTranslation } from "react-i18next";
import styles from "./_import.module.scss";

export default function Card({ title, total, done }) {
  const { t } = useTranslation();
  return (
    <div className={styles["card"]}>
      <p className={styles["title"]}>{title}</p>
      <p>
        {t("total")}: <span>{total}</span>
      </p>
      <p>
        {t("done")}: <span>{done}</span>
      </p>
    </div>
  );
}
