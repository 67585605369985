import React from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

export default function XLSbuttonComponent({ id, table, style }) {
    return (
        <div className="xls-button" style={style}>
            <i className="fas fa-download m-r-10" />

            <ReactHTMLTableToExcel
                id={id}
                table={table}
                filename="tablexls"
                sheet="tablexls"
                buttonText="Export as XLS"
            />
        </div>
    );
}
