import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Divider, Result, Select, Space, Tabs } from "antd";

import REQUESTS from "../../../api/requests";

import { useCurrencyOptions } from "../../../hooks/selectOptions";

import PageComponent from "../../../_components/page/PageComponent";

import StripeConfig from "./components/StripeConfig";
import PaypalConfig from "./components/PaypalConfig";
import TelcellConfig from "./components/TelcellConfig";
import AuthorizeConfig from "./components/AuthorizeConfig";
import GoogleConfig from "./components/GoogleConfig";
import AppleConfig from "./components/AppleConfig";
import KoopBankConfig from "./components/KoopBankConfig";
import { useTranslation } from "react-i18next";

export default function PaymentConfigPage() {
  const { t } = useTranslation();
  const { permissions } = useSelector((state) => state.globalState);

  const [data, setData] = useState(null);

  const curencyOptions = useCurrencyOptions();

  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [paymentsMethods, setPaymentsMethods] = useState(false);

  const tabs = [
    permissions?.payments?.paypal && {
      label: t("paypal"),
      key: "paypal",
      children: <PaypalConfig data={data} selectedCurrency={selectedCurrency} />,
    },

    permissions?.payments?.stripe && {
      label: t("stripe"),
      key: "stripe",
      children: <StripeConfig data={data} selectedCurrency={selectedCurrency} />,
    },

    permissions?.payments?.authorize && {
      label: t("authorize"),
      key: "authorize",
      children: <AuthorizeConfig data={data} selectedCurrency={selectedCurrency} />,
    },

    permissions?.payments?.telcell && {
      label: t("telcell"),
      key: "telcell",
      children: <TelcellConfig data={data} selectedCurrency={selectedCurrency} />,
    },

    permissions?.payments?.google && {
      label: t("google"),
      key: "google",
      children: <GoogleConfig data={data} selectedCurrency={selectedCurrency} />,
    },

    permissions?.payments?.apple && {
      label: t("apple"),
      key: "apple",
      children: <AppleConfig data={data} selectedCurrency={selectedCurrency} />,
    },
    permissions?.payments?.koopbank && {
      label: t("koopbank"),
      key: "koopBank",
      children: <KoopBankConfig data={data} selectedCurrency={selectedCurrency} />,
    },
  ];

  const getConfigs = () => {
    try {
      REQUESTS.PAYMENTS.CONFIGS.GET()
        .then((response) => {
          if (response.error) {
            return;
          }

          if (response.message) {
            setData(response.message);

            setSelectedCurrency(response.message.currency_id);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const checkPayment = () => {
    if (!permissions) return;

    const paymentsArr = Object.entries(permissions.payments || {})
      .filter(([key, value]) => typeof value === "boolean")
      .map(([key, value]) => ({ [key]: value }));

    const hasPaymentMethod = paymentsArr.some((item) => Object.values(item)[0]);
    setPaymentsMethods(hasPaymentMethod);
  };

  useEffect(() => {
    checkPayment();
  }, [permissions]);

  useEffect(() => {
    getConfigs();
  }, []);

  return (
    <PageComponent routes={[t("payment"), t("configs")]}>
      {paymentsMethods ? (
        <Space
          style={{
            marginBottom: 20,
          }}
        >
          <span>{t("currency")}: </span>
          <Select
            value={selectedCurrency}
            showSearch
            showArrow={true}
            onSelect={setSelectedCurrency}
            options={curencyOptions}
            style={{ width: 250 }}
            filterOption={(input, option) =>
              (option?.label?.toLowerCase() ?? "").includes(input.toLocaleLowerCase())
            }
            filterSort={(optionA, optionB) => {
              return (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase());
            }}
          />
        </Space>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            textAlign: "center",
          }}
        >
          <Result status="404" title={t("not_have_payment")} />
        </div>
      )}

      <Divider style={{ marginTop: 0 }}></Divider>
      <Tabs tabPosition="left" items={tabs} />
    </PageComponent>
  );
}
