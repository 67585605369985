import { appHost } from "../../../helpers/appHost";
import request from "../../request";

// const host = `${appHost || "DEFAULT_REACT_API_HOST"}`;

const host = appHost;

const URL = {
  KEYWORD: `${host}admin/web_keyword`,
};

export default {
  GET: (query) => request("GET", `${URL.KEYWORD}`, query),

  ADD: (query) => request("POST", `${URL.KEYWORD}`, query),

  EDIT: (query) => request("PUT", `${URL.KEYWORD}`, query),

  DELETE: (query) => request("DELETE", `${URL.KEYWORD}`, query),
};
