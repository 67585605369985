import { Select } from "antd";

import { useEffect, useState } from "react";
import requests from "../../server/requests";

export default function LiveTvSearch({ disabled, onChange, multiple = false, onSelect }) {
    const [search, setSearch] = useState("");

    const [list, setList] = useState([]);

    const onSearch = () => {
        let body = {
            limit: 50,
            search: JSON.stringify({ name: search }),
        };

        requests.channels.get(
            body,
            (data) => {
                const list = data.rows.map((item) => ({
                    value: item.id,
                    label: item.name,

                    ...item,
                }));
                setList(list);

                // setSearchLoading(false);
            },
            () => {
                // setSearchLoading(false);
            }
        );
    };

    useEffect(() => {
        let timeout = setTimeout(() => {
            onSearch();
        }, [500]);

        return () => {
            clearTimeout(timeout);
        };
    }, [search]);

    // useEffect(() => {
    //     setList([]);
    //     setSearch("");
    // form.setFields([{ name: "id_epg", value: undefined }]);
    // }, [epg_type]);

    const onChangeValue = (value) => {
        onChange(value);

        const finded = list.find((item) => item.value === value);

        if (onSelect) onSelect(finded);
    };

    return (
        <Select
            disabled={disabled}
            style={{ width: "100%" }}
            showSearch
            mode={multiple}
            showArrow={false}
            autoClearSearchValue={false}
            placeholder="Search live tv and select"
            optionFilterProp="children"
            filterOption={(input, option) => (option?.label?.toLowerCase() ?? "").includes(input)}
            filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            options={list}
            onSearch={setSearch}
            onChange={onChangeValue}
        />
    );
}
