import { useState } from "react";

import { notification } from "antd";

import REQUESTS from "../../../api/requests";

import CastTable from "./CastTable";

import CastDrawer from "./CastDrawer";

import CastInfoDrawer from "./CastInfoDrawer";

import PageComponent from "../../../_components/page/PageComponent";
import { useTranslation } from "react-i18next";

export default function CastPage() {
  const { t } = useTranslation();

  const [getAgain, setGetAgain] = useState(false);

  const [isOpenDrawer, setIsOpenDrawer] = useState(false);

  const [editable, setEditable] = useState(null);

  const [currentCastInfo, setCurrentCastInfo] = useState(null);

  const getDataSource = (query, onSuccess, onError) => {
    try {
      REQUESTS.VOD.CAST.GET({ query: JSON.stringify(query) })
        .then((response) => {
          onSuccess(response.message);
        })
        .catch((error) => {
          onError(error);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const handleMenuClick = (event, item) => {
    switch (event.key) {
      case "edit":
        setEditable(item);
        setIsOpenDrawer(true);
        break;

      case "info":
        setCurrentCastInfo(item);
        break;

      case "delete":
        REQUESTS.VOD.CAST.DELETE({ id: item.id })
          .then((response) => {
            if (response.error) {
              notification.error({
                message: t("error"),
                description: response.message,
              });

              return;
            }

            notification.success({
              message: t("success"),
              description: response.message,
            });
            setGetAgain((prev) => !prev);
          })
          .catch((error) => {
            notification.error({
              message: t("error"),
              description: error,
            });
          });

      default:
        break;
    }
  };

  return (
    <PageComponent routes={[t("vod"), t("cast")]}>
      <CastTable
        getAgain={getAgain}
        getDataSource={getDataSource}
        handleMenuClick={handleMenuClick}
        openDrawer={() => setIsOpenDrawer(true)}
      />

      <CastDrawer
        open={isOpenDrawer}
        editable={editable}
        getData={() => setGetAgain((prev) => !prev)}
        onClose={() => {
          setIsOpenDrawer(false);
          setEditable(null);
        }}
      />

      <CastInfoDrawer
        info={currentCastInfo}
        open={currentCastInfo ? true : false}
        onClose={() => setCurrentCastInfo(null)}
      />
    </PageComponent>
  );
}
