import request from "../../../api/request";
import { DEMO_API_HOST } from "../../../config/statics";
import { appHost } from "../../../helpers/appHost";

const host = appHost;

const URL = {
  DEVICE: `${host}launcher/admin/device`,
};

const launcherDevicesApi = {
  GET: (query) => request("GET", URL.DEVICE, query),

  BLOCK: (query) => request("POST", URL.DEVICE, query),

  ACTIVATE: (query) => request("PUT", `${URL.DEVICE}/active`, query),
};

export default launcherDevicesApi;
