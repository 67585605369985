import { useState, useEffect } from "react";
import { Select, notification, Button, Skeleton, Empty, Tag } from "antd";

import REQUESTS from "../../../api/requests";
import requests from "../../../server/requests";

import style from "./slider.module.scss";

import defaultImage from "../../../img/photo.png";
import { useTranslation } from "react-i18next";

export default function ChannelTab({ onClose, open }) {
  const { t } = useTranslation();
  const [search, setSearch] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [channels, setChannels] = useState([]);

  const [selected, setSelected] = useState(null);

  const getChannels = () => {
    setSearchLoading(true);

    let body = {
      limit: 50,
      search: JSON.stringify({ name: search }),
    };

    requests.channels.get(
      body,
      (data) => {
        const list = data.rows.map((item) => ({
          value: item.id,
          label: item.name,

          ...item,
        }));
        setChannels(list);

        setSearchLoading(false);
      },
      () => {
        setSearchLoading(false);
      }
    );
  };

  const selectChannel = (item) => {
    setSelected(item);
    setIsOpen(false);
  };

  const save = () => {
    setIsLoading(true);

    REQUESTS.WEB_PAGE.SLIDER.ADD({ type: "channel", id: selected?.id })
      .then((response) => {
        setIsLoading(false);

        if (response.error) {
          notification.error({
            message: t("error"),
            description: response.message,
          });

          return;
        }

        notification.success({
          message: t("success"),
          description: response.message,
        });

        onClose();
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    let timeout = setTimeout(() => {
      getChannels();
    }, [500]);

    if (search.length) {
      setIsOpen(true);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [search]);

  useEffect(() => {
    if (open) {
      setSearch("");
      setIsOpen(false);
      setChannels([]);
      setSelected(null);
      getChannels();
    } else {
      setIsOpen(false);
    }
  }, [open]);

  return (
    <div>
      <Select
        showSearch
        style={{
          width: "100%",
        }}
        placeholder={t("search_channel_placeholder")}
        optionFilterProp="children"
        filterOption={(input, option) => (option?.label ?? "").includes(input)}
        filterSort={(optionA, optionB) =>
          (optionA?.label ?? "")
            .toLowerCase()
            .localeCompare((optionB?.label ?? "").toLowerCase())
        }
        onFocus={() => setIsOpen(true)}
        onBlur={() => {
          setTimeout(() => {
            setIsOpen(false);
          }, 500);
        }}
        onSearch={setSearch}
        searchValue={search}
        value={selected}
        open={isOpen}
        dropdownRender={(a) => {
          return (
            <div className={style["search-container"]}>
              {searchLoading ? (
                <Skeleton active />
              ) : channels.length ? (
                channels.map((item) => {
                  let isError = false;
                  let tester = new Image();

                  tester.src = item.backdrop;

                  tester.onerror = isError = true;

                  return (
                    <div
                      className={style["movie-channel"]}
                      key={item.id}
                      onClick={() => selectChannel(item)}
                    >
                      <img
                        src={item.image}
                        onError={(e) => (e.target.src = defaultImage)}
                      />
                      <div>
                        <p className={style["name"]}>{item.name}</p>
                        {isError && (
                          <Tag color="warning" style={{ fontSize: 10 }}>
                            {t("better_view_upload_backdrop")}
                          </Tag>
                        )}
                      </div>
                    </div>
                  );
                })
              ) : (
                <Empty />
              )}
            </div>
          );
        }}
      />

      <Button
        type="primary"
        onClick={save}
        disabled={!selected}
        loading={isLoading}
        style={{ width: "100%", marginTop: 20 }}
      >
        {t("save")}
      </Button>
    </div>
  );
}
