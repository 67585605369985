import { Form, Input } from "antd";
import { useTranslation } from "react-i18next";

const VerifyForm = ({ onChange }) => {
  const { t } = useTranslation();
  return (
    <>
      <span
        style={{
          display: "inline-block",
          textAlign: "center",
          marginBottom: "30px",
        }}
      >
        {t("verify_title")}
      </span>
      <Form.Item
        name="code"
        label={t("verify_code")}
        rules={[
          {
            required: true,
            message: t("verify_code_message"),
          },
        ]}
      >
        <Input onChange={onChange} />
      </Form.Item>
    </>
  );
};

export default VerifyForm;
