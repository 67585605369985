export default function createFormValues({ permissions, values, editable }) {
  const body = {
    type: values.type,
  };

  if (values.type === "text") {
    body.text = values.content_text;
    body.repeat_counts = values.repeat_counts;
    body.url = "";
  } else if (values?.type == "custom") {
    console.log(values);
    body.url = values?.transcode_url;
    body.tex = "";
  } else {
    body.url = values.url;
    body.text = "";
  }

  if (values?.tariffs_id) {
    body.tariffs_id = values.tariffs_id.join(",");
  }

  if (permissions?.Vod_Manager) {
    body.all_movies = values.all_movies;

    if (values.movies_id && !values.all_movies) {
      body.movies_id = values.movies_id.join(",");
    }
  }

  if (permissions?.LIVE_TV) {
    body.all_channels = values.all_channels;

    if (values.channels_id && !values.all_channels) {
      body.channels_id = values.channels_id.join(",");
    }
  }

  if (editable) {
    body.id = editable.id;
  }

  return body;
}
