import React, { useEffect, useState } from "react";

import { renderDate, converterMSToYMD } from "../../../../helpers/formats";

import TableImage from "../../../../components/TableImage";
import { ActionsBlock } from "../../../../components/ActionsBlock";
import TableEmptyComponent from "../../../../components/TableEmptyComponent";
import TableLoadingComponent from "../../../../components/TableLoadingComponent";
import ActionButtonComponent from "../../../../components/ActionButtonComponent";

export default function Seasons({ seasons, openPopup, select, current }) {
  const [isLoading, setIsLoading] = useState(false);

  const [seasonsData, setSeasonData] = useState([]);

  // useEffect(() => {
  //     if (media && media.type.value === "tv_show") {
  //         setSeasons(media.seasons);
  //         setIsLoading(false);
  //     }
  // }, [media]);

  // useEffect(() => {
  //   console.log("seasons", seasons, getAgain);
  //   setSeasonData(seasons);
  // }, [seasons, getAgain]);

  return (
    <div style={{ minHeight: 250 }}>
      <div className="row-space-between">
        <h5>Seasons</h5>
        <button
          className="popup-button-style add-button"
          onClick={() => openPopup("addSeason")}
        >
          <i className="fas fa-plus" style={{ marginRight: 5 }} />
          New season
        </button>
      </div>

      <table className="table m-t-15 auto-width">
        <thead>
          <tr>
            <th style={{ width: "5%" }}>#</th>
            <th></th>
            <th>Name</th>
            <th>Number</th>
            <th>Episode count</th>
            <th>Release date</th>
            <th></th>
          </tr>
        </thead>

        <tbody>
          {isLoading ? (
            <TableLoadingComponent />
          ) : seasons.length > 0 ? (
            seasons.map((item, key) => {
              return (
                <tr
                  key={key}
                  style={{
                    background: current === key && "#0a5d577a",
                    cursor: "pointer",
                  }}
                >
                  <td onClick={() => select(item, key)}>{key + 1}</td>
                  <td onClick={() => select(item, key)}>
                    <TableImage
                      isZoom={false}
                      src={item.poster}
                      onClick={() => select(item, key)}
                    />
                  </td>
                  <td onClick={() => select(item, key)}>
                    {item?.name ? item?.name : `Seasons ${key + 1}`}
                  </td>

                  <td onClick={() => select(item, key)}>{item.number}</td>
                  <td onClick={() => select(item, key)}>
                    {item.episodes && item.episodes.length}
                  </td>

                  <td onClick={() => select(item, key)}>
                    {item && item?.release_date
                      ? converterMSToYMD(item?.release_date)
                      : "N/A"}
                    {/* {renderDate(item.release_date)} */}
                  </td>

                  <td style={{ position: "relative" }}>
                    <ActionsBlock>
                      <ActionButtonComponent
                        title="Edit"
                        type="edit"
                        action={() => openPopup("editSeason", item)}
                      />

                      <ActionButtonComponent
                        title="Delete"
                        type="delete"
                        action={() => openPopup("deleteSeason", item)}
                      />
                    </ActionsBlock>
                  </td>
                </tr>
              );
            })
          ) : (
            <TableEmptyComponent />
          )}
        </tbody>
      </table>
    </div>
  );
}
