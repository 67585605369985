import { useEffect, useState } from "react";

import { Checkbox, Form, Select, Space, Tag } from "antd";

import requests from "../../../server/requests";
import { useTranslation } from "react-i18next";

export default function LiveTvSelect({ form }) {
  const { t } = useTranslation();

  const [options, setOptions] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const getOptions = () => {
    let body = {
      limit: 1000000,
    };

    requests.channels.get(
      body,
      (data) => {
        setIsLoading(true);

        const list = data.rows.map((item) => ({
          value: item.id,
          label: item.name,

          ...item,
        }));
        setOptions(list);
        setIsLoading(false);
      },
      () => {
        setIsLoading(false);
      }
    );
  };

  useEffect(() => {
    getOptions();
  }, []);

  return (
    <Form.Item shouldUpdate noStyle>
      {() => {
        const { all_channels } = form.getFieldsValue();

        return (
          <Form.Item
            label={
              <Space>
                {t("Live Tv")}
                <Form.Item noStyle valuePropName="checked" name="all_channels">
                  <Checkbox style={{ color: "gray" }}>{t("selected_all_tv")}</Checkbox>
                </Form.Item>
              </Space>
            }
            name="channels_id"
          >
            {all_channels ? (
              <Tag
                color="green"
                style={{ width: "100%", padding: 5, textAlign: "center" }}
              >
                {t("all_live_tv_selected")}
              </Tag>
            ) : (
              <Select
                mode="multiple"
                disabled={all_channels}
                options={options}
                loading={isLoading}
                showArrow={true}
              />
            )}
          </Form.Item>
        );
      }}
    </Form.Item>
  );
}
