import { useEffect, useState } from "react";
import styles from "./usageInfo.module.scss";
import REQUESTS from "../../../../api/requests";
import { useNavigate } from "react-router";
import PATHS from "../../../../config/paths";
import { useSelector } from "react-redux";

const UsageInfo = () => {
  const { packages } = useSelector((state) => state.globalState);

  const navigate = useNavigate();

  const [showUpgrade, setShowUpgrade] = useState(false);

  const [usageCard, setUsageCard] = useState([
    {
      id: 0,
      label: "Bandwidth",
      capacity: "325 GB from 3TB",
      percent: "",
      limit: 0,
      used: 0,
    },
    {
      id: 1,
      label: "Storage",
      capacity: "500 GB from 3TB used",
      percent: "",
      limit: 0,
      used: 0,
    },
  ]);

  const getCapacity = (total, used) => {
    let capacityValue = 0;

    capacityValue = (100 * used) / total;

    return capacityValue ? capacityValue.toFixed(2) : 0;
  };

  const handleUpgradeClick = () => {
    navigate(PATHS.CLOUD_PRICING);
  };

  const handleInfoClick = () => {
    navigate(PATHS.USAGE_INFO);
  };

  const getCurrentTariff = (key, host) => {
    REQUESTS.USAGE_INFO.CLOUD_SERVICE.PACKAGES_GETTER({ key }, host)
      .then((res) => {
        if (res.rows?.length) {
          let maxValue = 0;

          res.rows.forEach((value) => {
            if (value.price > maxValue) {
              maxValue = value.price;
            }
          });

          const activeExpensive = res?.rows.find((item) => {
            return (item.price = maxValue);
          });
          if (!activeExpensive.is_active) {
            setShowUpgrade(true);
          } else {
            setShowUpgrade(false);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    if (packages?.host) {
      getCurrentTariff(packages?.key, packages.host);

      REQUESTS.USAGE_INFO.CLOUD_SERVICE.GET_INFO({ key: packages?.key }, packages?.host)
        .then((response) => {
          setUsageCard([
            {
              id: 0,
              label: "Bandwidth",
              capacity: `${response.used_traffic.toFixed(3)} GB from ${
                response.traffic_limit
              } GB`,
              percent: getCapacity(response.traffic_limit, response.used_traffic),
              limit: response.traffic_limit,
              used: response.used_traffic,
            },
            {
              id: 1,
              label: "Storage",
              capacity: `${response.used_storage.toFixed(3)} GB from ${
                response.storage_limit
              } GB`,
              percent: getCapacity(response.storage_limit, response.used_storage),
              limit: response.storage_limit,
              used: response.used_storage,
            },
          ]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [packages]);

  return (
    <div className={styles["usage"]}>
      {usageCard?.map((item) => {
        return (
          <div key={item.id} className={styles["usage-card"]} onClick={handleInfoClick}>
            <div className={styles["usage-card-info"]}>
              <span className={styles["label"]}>{item.label}</span>
              <span className={styles["capacity"]}>{item.capacity}</span>
            </div>
            <div
              style={{
                backgroundImage: `
                radial-gradient(closest-side, #fff 79%, transparent 80% 100%),
                    conic-gradient(#0F87B2 ${item?.percent}%, #fff 0)
                `,
              }}
              className={styles["percent-wrapper"]}
            >
              <span>{item.percent} %</span>
            </div>
          </div>
        );
      })}
      {showUpgrade && (
        <div className={styles["upgrade-button"]} onClick={handleUpgradeClick}>
          <span>Upgrade Capacity Now</span>
        </div>
      )}
    </div>
  );
};
export default UsageInfo;
