import { useEffect, useState } from "react";
import { DatePicker, Divider, Form, InputNumber, Radio, Select, Switch } from "antd";

import { useCountriesOptions } from "../../../../../hooks/selectOptions";

import Container from "../../../../../_components/container/Container";

import styles from "./_restriction_monetization.module.scss";
import { useTranslation } from "react-i18next";

export default function Restriction({ form }) {
  const { t } = useTranslation();
  const countriesOptions = useCountriesOptions("name", "name");

  const [geolocationRestriction, setGeolocationRestriction] = useState(false);
  const [showPublishPicker, setShowPublishPicker] = useState(false);

  const handleSwitchChange = (value) => {
    setGeolocationRestriction(value);

    form.setFieldsValue({ is_location_restriction: value });
  };

  useEffect(() => {
    setGeolocationRestriction(form?.getFieldValue("is_location_restriction"));
  }, [form?.getFieldValue("is_location_restriction")]);

  useEffect(() => {
    if (form.getFieldValue("published_at")) {
      setShowPublishPicker(true);
    } else {
      setShowPublishPicker(false);
    }
  }, [form.getFieldValue("published_at")]);

  return (
    <Container className={styles["restriction"]}>
      <Divider orientation="left" plain>
        <b>{t("restriction")}</b>
      </Divider>

      <Form.Item label={t("is_protected")} name="is_protected">
        <Radio.Group name="radiogroup" defaultValue={false}>
          <Radio value={true}>{t("yes")}</Radio>
          <Radio value={false}>{t("no")}</Radio>
        </Radio.Group>
      </Form.Item>

      <div className={styles["age-date"]}>
        <Form.Item label={t("age_restriction")} name="pg">
          <InputNumber style={{ width: 100, whiteSpace: "nowrap" }} controls={false} />
        </Form.Item>

        <Form.Item label={t("license_end_date")} name="license_ending_date">
          <DatePicker style={{ width: "100%" }} showTime={false} format={"YYYY-MM-DD"} />
        </Form.Item>
      </div>

      <div>
        <Form.Item name="published_at" label={t("publish")}>
          <Radio.Group
            defaultValue={false}
            name="publish_radio"
            onChange={({ target }) => {
              if (target?.value) {
                setShowPublishPicker(true);
              } else {
                setShowPublishPicker(false);
              }
            }}
          >
            <Radio value={false}>{t("published_immediately")}</Radio>
            <Radio value={true}>{t("published_at")}</Radio>
          </Radio.Group>
        </Form.Item>
        {showPublishPicker && (
          <Form.Item
            name="published_date"
            rules={[
              {
                required: true,
                message: t("published_date_message"),
              },
            ]}
          >
            <DatePicker
              style={{ width: "100%" }}
              showTime={false}
              format={"YYYY-MM-DD"}
            />
          </Form.Item>
        )}
      </div>

      <Form.Item name="is_location_restriction" valuePropName="checked">
        <div style={{ width: "100%", display: "flex", gap: 10 }}>
          <span>{t("geolocation_restrictions")}</span>
          <Switch checked={geolocationRestriction} onChange={handleSwitchChange} />
        </div>
      </Form.Item>

      {geolocationRestriction && (
        <Form.Item
          label={t("available_countries")}
          name="available_countries"
          rules={[
            {
              required: true,
              message: t("available_countries_message"),
            },
          ]}
        >
          <Select
            mode="multiple"
            showSearch={true}
            options={countriesOptions}
            filterOption={(input, option) =>
              (option?.label?.toLowerCase() ?? "")?.includes(input?.toLocaleLowerCase())
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                ?.toLowerCase()
                ?.localeCompare((optionB?.label ?? "")?.toLowerCase())
            }
          />
        </Form.Item>
      )}
    </Container>
  );
}
