import { Button, Result } from "antd";
import { useTranslation } from "react-i18next";
const BillingStatusPage = () => {
  const { t } = useTranslation();
  return (
    <Result
      status="success"
      title={t("success_purchased_message")}
      subTitle="Order number: 2017182818828182881 Cloud server configuration takes 1-5 minutes, please wait."
      extra={[
        <Button type="primary" key="console">
          {t("go_home")}
        </Button>,
      ]}
    />
  );
};
export default BillingStatusPage;
