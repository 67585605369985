import request from "../../../api/request";
import { appHost } from "../../../helpers/appHost";

const host = appHost;

const URL = {
  MENU_CONFIG_HOST: `${host}v2/admin/menu`,

  CONFIG_POSITION: `${host}v2/admin/menu/positions`,
};

const menuConfigApi = {
  GET: (query) => request("GET", URL.MENU_CONFIG_HOST, query),

  ADD: (query) => request("POST", URL.MENU_CONFIG_HOST, query),

  UPDATE: (query) => request("PUT", URL.MENU_CONFIG_HOST, query),

  REMOVE: (query) => request("DELETE", URL.MENU_CONFIG_HOST, query),

  UPDATE_POSITION: (query) => request("PUT", URL.CONFIG_POSITION, query),

  ENABLED: (query) => request("PUT", `${URL.MENU_CONFIG_HOST}/enabled`, query),
};

export default menuConfigApi;
