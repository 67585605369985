import { useEffect, useState } from "react";

import { Form, Input, Space, Tabs, notification } from "antd";

import icons from "../../../../config/icons";

import PathDrawer from "./PathDrawer";

import Upploader from "../../../../_components/uploader/Uploader";

import ButtonComponent from "../../../../_components/button/ButtonComponent";

import REQUESTS from "../../../../api/requests";
import { useTranslation } from "react-i18next";

export default function InputField({ form, setProgress, progress }) {
  const { t } = useTranslation();

  const [activeTab, setActiveTab] = useState("select_file");

  const [isOpenDrawer, setIsOpenDrawer] = useState(false);

  const onInput = (value) => {
    form.setFields([{ name: "select_file", value: value }]);

    setIsOpenDrawer(false);
  };

  useEffect(() => {
    form.setFields([{ name: "input_type", value: activeTab }]);
  }, [activeTab]);

  const uploadFile = (value) => {
    if (value.file) {
      let file = value.file.originFileObj;

      REQUESTS.VOD.TRANSCODING.UPLOAD.STATUS({
        file_id: file.uid + file.name,
      })
        .then((response) => {
          const total_chunk_uploaded = response?.total_chunk_uploaded;

          const headers = {
            "starting-byte": total_chunk_uploaded,
            "file-id": file.uid + file.name,
            "chunk-size": file.size - total_chunk_uploaded,
            "file-size": file.size,
          };

          file = file?.slice(total_chunk_uploaded, file.size);

          const formData = new FormData();
          formData.append("file", file, file.name);

          const onProgress = (e) => {
            setProgress(e);
          };

          REQUESTS.VOD.TRANSCODING.UPLOAD.START(formData, headers, onProgress)
            .then((response) => {
              if (response.message === "Finish") {
                form.setFields([
                  {
                    name: "choose_file",
                    value: response.file_path,
                  },
                ]);

                setTimeout(() => {
                  setProgress(0);
                }, 1000);
                return;
              }

              notification.error({
                message: t("error"),
                description: t("something_wrong"),
              });
            })
            .catch((error) => {
              notification.error({
                message: t("error"),

                description: error?.message,
              });
            });
        })
        .catch((error) => {
          notification.error({
            message: t("error"),

            description: error?.message,
          });
        });
    }
  };

  const tabs = [
    {
      label: t("select_file"),
      key: "select_file",
      children: (
        <Space>
          <Form.Item
            label={t("input")}
            name="select_file"
            rules={[
              {
                required: activeTab === "select_file" ? true : false,
                message: t("choose_file_message"),
              },
            ]}
          >
            <Input style={{ width: 510 }} readOnly />
          </Form.Item>

          <ButtonComponent
            style={{
              marginTop: 5,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
            type="dashed"
            icon={icons.FOLDER}
            onClick={() => setIsOpenDrawer(true)}
          />
        </Space>
      ),
    },

    {
      label: t("choose_file"),
      key: "choose_file",
      children: (
        <Form.Item
          label={t("input")}
          name="choose_file"
          rules={[
            {
              required: activeTab === "choose_file" ? true : false,
              message: t("choose_file_message"),
            },
          ]}
        >
          <Upploader
            disabled={progress > 0 ? true : false}
            style={{ width: 550 }}
            onChange={uploadFile}
          />
        </Form.Item>
      ),
    },

    {
      label: "Url",
      key: "url",
      children: (
        <Form.Item
          label={t("input")}
          name="url"
          rules={[
            {
              required: activeTab === "url" ? true : false,
              message: t("please_input_url"),
            },
          ]}
        >
          <Input />
        </Form.Item>
      ),
    },
  ];

  return (
    <Form.Item name="input_type">
      <Tabs tabPosition="top" items={tabs} onChange={setActiveTab} />
      <PathDrawer
        type="input"
        onSelect={onInput}
        open={isOpenDrawer}
        onClose={() => setIsOpenDrawer(false)}
      />
    </Form.Item>
  );
}
