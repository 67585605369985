import { Image, Popconfirm } from "antd";

import ButtonComponent from "../../../../../../_components/button/ButtonComponent";

import styles from "./document.module.scss";
import { useTranslation } from "react-i18next";

export default function Document({ item, onConfirm }) {
  const { t } = useTranslation();
  let type = item.split(".");
  type = type[type.length - 1];
  let fileType = "file";

  switch (type) {
    case "jpg":
    case "png":
    case "svg":
    case "jpeg":
      fileType = "image";
      break;
    default:
      break;
  }

  return (
    <div className={styles["document"]}>
      {fileType === "file" ? (
        <a href={item} className={styles["container"]} target="_blank">
          {type}
          <i className="fas fa-download" />
        </a>
      ) : (
        <Image src={item} className={styles["container"]} />
      )}

      <Popconfirm
        placement="topRight"
        title={t("delete_document_message")}
        onConfirm={onConfirm}
        okText="Delete"
        cancelText="Cancel"
        okButtonProps={{
          danger: true,
        }}
      >
        <ButtonComponent title={t("delete")} danger style={{ width: 230 }} />
      </Popconfirm>
    </div>
  );
}
