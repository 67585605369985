import { useState } from "react";
import { Form, Input, notification } from "antd";

import REQUESTS from "../../../../api/requests";

import icons from "../../../../config/icons";

import ButtonComponent from "../../../../_components/button/ButtonComponent";

import styles from "../messages.module.scss";
import { useTranslation } from "react-i18next";

export default function SendMessage({ id, setGetAgain }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const [form] = Form.useForm();

  const onFinish = (values) => {
    setIsLoading(true);

    const body = {
      title: values.title,
      message: values.message,
      device_id: id,
    };

    REQUESTS.LAUNCHER.MESSAGES.SEND(body)
      .then((response) => {
        setIsLoading(false);

        if (response.error) {
          notification.error({
            message: t("error"),
            description: response.message,
          });

          return;
        }

        notification.success({
          message: t("success"),
          description: response.message,
        });

        form.resetFields();
        setGetAgain();
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  return (
    <div className={styles["send-message"]}>
      <h4> {t("send_message")} </h4>
      <Form
        form={form}
        name="launcher-messages"
        layout="vertical"
        onFinish={onFinish}
        // onKeyPress={(e) => {
        //     if (e.key === "Enter") {
        //         form.submit();
        //     }
        // }}
        disabled={!id}
      >
        <Form.Item
          label={t("title")}
          name="title"
          rules={[
            {
              required: true,
              message: t("title_message"),
            },
          ]}
        >
          <Input placeholder={t("title_message")} />
        </Form.Item>

        <Form.Item
          label={t("message")}
          name="message"
          rules={[
            {
              required: true,
              message: t("message"),
            },
          ]}
        >
          <Input.TextArea rows={6} placeholder={t("title_message_message")} />
        </Form.Item>

        <ButtonComponent
          title={t("send")}
          icon={icons.SEND}
          isLoading={isLoading}
          style={{ width: "100%" }}
          onClick={() => form.submit()}
        />
      </Form>
    </div>
  );
}
