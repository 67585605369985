import { Tabs } from "antd";
import Subusers from "./subusers/Subusers";
import PaymentsTab from "./payments/PaymentsTab";
import DevicesTab from "./devices/DevicesTab";
import MostWatchedChannelsTab from "./most-watched-channels/MostWatchedChannelsTab";
import DocumentsTab from "./documents/DocumentsTab";
import PlaylistsTab from "./playlists/PlaylistsTab";
import { useTranslation } from "react-i18next";

export default function TabsList({ user, getUser }) {
  const { t } = useTranslation();
  const tabs = [
    {
      label: t("sub_users"),
      key: "sub_users",
      children: <Subusers user={user} />,
    },

    {
      label: t("playlists"),
      key: "playlists",
      children: <PlaylistsTab user={user} />,
    },

    {
      label: t("devices"),
      key: "devices",
      children: <DevicesTab user={user} />,
    },

    {
      label: t("most_watched_channels"),
      key: "most_watched_channels",
      children: <MostWatchedChannelsTab user={user} />,
    },

    {
      label: t("documents"),
      key: "documents",
      children: <DocumentsTab user={user} getUser={getUser} />,
    },

    {
      label: t("payments"),
      key: "payments",
      children: <PaymentsTab user={user} />,
    },
  ];

  return <Tabs tabPosition="top" items={tabs} />;
}
