import { useEffect, useState } from "react";
import { Button, Divider, Drawer, Empty, Space, Spin, notification } from "antd";

import icons from "../../../../config/icons";
import REQUESTS from "../../../../api/requests";

import FileFolder from "./FileFolder";
import NewFolderDrawer from "./NewFolderDrawer";

import ButtonComponent from "../../../../_components/button/ButtonComponent";

import styles from "../_transcoding.module.scss";
import { useTranslation } from "react-i18next";

export default function PathDrawer({ open, onClose, type, onSelect, streamPath }) {
  const { t } = useTranslation();

  const defaultPath = type === "output" ? (streamPath ? streamPath + "/" : "/") : "/";

  const [isLoading, setIsLoading] = useState(false);

  const [openNewFolderDrawer, setOpenNewFolderDrawer] = useState(false);

  const [pathsList, setPathsList] = useState([]);

  const [backPath, setBackPath] = useState("");

  const [currentPath, setCurrentPath] = useState(defaultPath);

  const [selected, setSelected] = useState(null);

  const getPathsList = (path = defaultPath) => {
    setIsLoading(true);
    REQUESTS.VOD.TRANSCODING.FILE.GET({
      path: path,
    })
      .then((response) => {
        setIsLoading(false);

        if (type === "output") {
          response.data = response.data?.filter((item) => item.is_file === false);
        } else {
          response.data = response.data.sort(
            (firstItem, secondItem) => firstItem.is_file - secondItem.is_file
          );
        }

        setBackPath(response.back_path);
        setCurrentPath(response.current_path);
        setPathsList(response.data);
      })
      .catch((error) => {
        notification.error({
          message: t("error"),
          description: error.message,
        });
        setIsLoading(false);
      });
  };

  const onClickFolder = (item) => {
    setSelected(item);

    if (item.is_file) {
      setCurrentPath(item.path);
      return;
    }

    getPathsList(item.path);
  };

  const onBack = () => {
    if (type === "output") {
      if (backPath === "/") {
        setCurrentPath(defaultPath);
        setBackPath(defaultPath);
        return;
      }
    }

    setCurrentPath(backPath);
    getPathsList(backPath);
  };

  const onClickSelect = () => {
    if (type === "input" && !selected.is_file) {
      return;
    }

    if (streamPath) {
      let len = streamPath.length + 1;
      let newOutput = currentPath.substring(len, currentPath.length);
      let outputSlice = newOutput.slice(0, -1);

      onSelect(`/${outputSlice}`);
    } else {
      onSelect(currentPath);
    }
  };

  useEffect(() => {
    if (open) {
      setCurrentPath(type === "input" ? "/" : "/stream/");

      getPathsList();
    } else {
      setPathsList([]);
      setBackPath("");
      setSelected(null);
    }
  }, [open]);

  return (
    <Drawer
      title={`${type.charAt(0).toUpperCase() + type.slice(1)} path`}
      placement="right"
      onClose={onClose}
      open={open}
      width={600}
      className={styles["path-drawer"]}
    >
      <div className={styles["header"]}>
        <Space>
          <Button
            icon={icons.BACK}
            type="dashed"
            size="small"
            style={{ fontSize: 10 }}
            onClick={onBack}
            disabled={
              type === "input" && currentPath === "/"
                ? true
                : type === "output" && currentPath === "/stream/"
                ? true
                : false
            }
          />

          <span style={{ color: "gray" }}>
            {t("path")}: {currentPath}
          </span>
        </Space>

        {type === "output" && (
          <ButtonComponent
            icon={icons.ADD}
            title={t("new_folder")}
            onClick={() => setOpenNewFolderDrawer(true)}
          />
        )}
      </div>

      <Divider />

      <div className={styles["container"]}>
        {isLoading && (
          <div className={styles["loading"]}>
            <Spin />
          </div>
        )}

        <div className={styles["body"]}>
          {pathsList.length ? (
            <div className={styles["paths-list"]}>
              {pathsList?.map((item) => {
                return (
                  <FileFolder
                    item={item}
                    key={item.id}
                    selected={selected}
                    onClick={() => onClickFolder(item)}
                  />
                );
              })}
            </div>
          ) : (
            <Empty style={{ marginTop: 20 }} />
          )}
        </div>
      </div>

      <div style={{ marginTop: 20, textAlign: "center" }}>
        <ButtonComponent
          title={t("select")}
          onClick={onClickSelect}
          disabled={type === "input" && !selected?.is_file}
        />
      </div>

      {type === "output" && (
        <NewFolderDrawer
          getData={getPathsList}
          open={openNewFolderDrawer}
          currentPath={currentPath}
          onClose={() => setOpenNewFolderDrawer(false)}
        />
      )}
    </Drawer>
  );
}
