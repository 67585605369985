import request from "../../api/request";
import { DEMO_API_HOST } from "../../config/statics";
import { appHost } from "../../helpers/appHost";

const host = appHost;

const URL = {
  HISTORY: `${host}admin/history`,
};

const historyApi = {
  GET: (query) => request("GET", URL.HISTORY, query),

  DELETE: (query) => request("DELETE", URL.HISTORY, query),
};

export default historyApi;
