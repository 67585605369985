import { Button, Space } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import styles from "./style.module.scss";

export default function NextBackButton({ type, onClick }) {
    let icon;
    let title;

    switch (type) {
        case "next":
            icon = <RightOutlined />;
            title = "Next";
            break;

        case "back":
            icon = <LeftOutlined />;
            title = "Back";

            break;

        default:
            break;
    }

    return (
        <div
            type="text"
            icon={icon}
            onClick={onClick}
            className={styles["next-back-button"]}
            style={{
                flexDirection: type === "next" ? "row-reverse" : "row",
            }}
        >
            {icon}
            {title}
            {/* {type === "next" ? "Next" : "Back"} */}
        </div>
    );
}
