import { useTranslation } from "react-i18next";
import icons from "../../../../../config/icons";

import { parseHours } from "../../../../../helpers/formats";

import styles from "./_live-tv-video.module.scss";

/**
 * This component is used to display the EPG of a channel in the Live TV page.
 * @param {object} item - The EPG item to display.
 * @param {boolean} current - Whether the EPG item is currently playing or not.
 * @param {function} onClick - The function to call when the play button is clicked.
 * @returns
 */

export default function Epg({ item, current = false, onClick, disabled }) {
  const { t } = useTranslation();

  let start = new Date(item.start_ut * 1000);
  let end = new Date(item.stop_ut * 1000);
  let prevEpg = start < new Date();
  let live = start < new Date() && new Date() < end;

  let _disabled = false;

  if (!prevEpg) _disabled = true;
  if (disabled) _disabled = true;

  const onClickItem = () => {
    const values = {
      start,
      end,
      time: `${parseHours(start)} - ${parseHours(end)}`,
      prevEpg,
      live,
      ...item,
    };

    if (live) {
      onClick("live", values);

      return;
    }

    if (prevEpg) {
      onClick("catchup", values);
      return;
    }
  };

  return (
    <div
      className={`${styles["epg"]} ${current && styles["current"]} ${
        _disabled && styles["disabled"]
      } ${live && styles["live"]} `}
      onClick={onClickItem}
    >
      <p className={styles["date"]}>
        {parseHours(start)} - {parseHours(end)}
      </p>

      <p className={styles["title"]}>{item.title}</p>

      {_disabled ? <div></div> : live ? t("live") : icons.PLAY}
    </div>
  );
}
