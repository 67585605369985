import { Button, Upload } from "antd";

import { UploadOutlined } from "@ant-design/icons";

export default function Upploader({ accept, onChange, style, disabled, remove, title }) {
  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      onChange(info);

      // Get this url from response in real world.
    }
  };

  return (
    <Upload
      disabled={disabled}
      multiple={false}
      maxCount={1}
      accept={accept}
      onChange={handleChange}
      onRemove={remove}
      customRequest={({ file, onSuccess }) => {
        setTimeout(() => {
          onSuccess("ok");
        }, 0);
      }}
    >
      <Button icon={<UploadOutlined />} style={style} disabled={disabled}>
        {title ? title : "Click to Upload"}
      </Button>
    </Upload>
  );
}
