import { useState } from "react";

import REQUESTS from "../../../api/requests";

import UsersGroupsTable from "./UsersGroupsTable";
import UsersGroupDrawer from "./UsersGroupDrawer";
import OtherGroupsDrawer from "./OtherGroupsDrawer";

import PageComponent from "../../../_components/page/PageComponent";
import { useTranslation } from "react-i18next";

export default function UsersGroupsPage() {
  const { t } = useTranslation();
  const [getAgain, setGetAgain] = useState(false);

  const [editable, setEditable] = useState(null);

  const [deletable, setDeletable] = useState(null);

  const [isOpenDrawer, setIsOpenDrawer] = useState(false);

  const getDataSource = (query, onSuccess, onError) => {
    try {
      REQUESTS.USERS.GROUPS.GET({ query: JSON.stringify(query) })
        .then((response) => {
          onSuccess(response.message);
        })
        .catch((error) => {
          onError(error);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const handleMenuClick = (event, item) => {
    switch (event.key) {
      case "edit":
        setEditable(item);
        setIsOpenDrawer(true);
        break;

      case "delete":
        setDeletable(item);
      default:
        break;
    }
  };

  return (
    <PageComponent routes={[t("users"), t("groups")]}>
      <UsersGroupsTable
        getAgain={getAgain}
        getDataSource={getDataSource}
        handleMenuClick={handleMenuClick}
        openDrawer={() => setIsOpenDrawer(true)}
      />

      <UsersGroupDrawer
        open={isOpenDrawer}
        editable={editable}
        getData={() => setGetAgain((prev) => !prev)}
        onClose={() => {
          setIsOpenDrawer(false);
          setEditable(null);
        }}
      />

      <OtherGroupsDrawer
        open={deletable ? true : false}
        deletable={deletable}
        onClose={() => {
          setDeletable(null);
        }}
        getData={() => setGetAgain((prev) => !prev)}
      />
    </PageComponent>
  );
}
