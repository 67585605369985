import { toColor } from "react-color-palette";
import Colors from "../helpers/Colors";

export default function configDashboardSettings(data) {
    const dashboardSettins = {};

    for (let i = 0; i < data.length; i++) {
        if (data[i].key === "admin_panel_favicon") {
            dashboardSettins.favicon = data[i].value;

            const link = document.querySelector("link[rel~='icon']");

            if (!link) {
                link = document.createElement("link");
                link.rel = "icon";
                document.getElementsByTagName("head")[0].appendChild(link);
            }

            link.href = data[i].value;
        }

        if (data[i].key === "admin_panel_logo") {
            dashboardSettins.logo = data[i].value;
        }

        if (data[i].key === "primary_color") {
            document.documentElement.style.setProperty("--main-active-color", data[i].value);

            let rgb = toColor("hex", data[i].value).rgb;

            rgb.r = rgb.r + 30;
            rgb.g = rgb.g + 30;
            rgb.b = rgb.b + 30;

            const hover = toColor("rgb", rgb);

            document.documentElement.style.setProperty("--hover", hover.hex);

            Colors.main_active_color = data[i].value;

            dashboardSettins.primaryColor = data[i].value;
            dashboardSettins.primaryHoverColor = hover.hex;
        }
    }

    return dashboardSettins;
}
