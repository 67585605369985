import { useState, useEffect } from "react";

import { Form, Button, Input, notification } from "antd";

import REQUESTS from "../../../../api/requests";

import style from "../style.module.scss";
import { useTranslation } from "react-i18next";

export default function GeneralTab({ settings }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState();

  const onFinish = (values) => {
    setIsLoading(true);

    const query = {
      email: values.email,
      phone_number: values.phone_number,
    };

    REQUESTS.WEB_PAGE.SETTINGS.EDIT(query)
      .then((data) => {
        setIsLoading(false);

        if (data.error) {
          notification.error({
            message: t("error"),
            description: data.message,
          });

          return;
        }

        notification.success({
          message: t("success"),
          description: data.message,
        });
      })

      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (settings) {
      form.setFields([
        {
          name: "email",
          value: settings.email,
        },

        {
          name: "phone_number",
          value: settings.phone_number,
        },
      ]);
    }
  }, [settings]);

  return (
    <div className={style["tab"]}>
      <p className={style["title"]}>{t("general")}</p>

      <Form form={form} name="web-about-us" layout="vertical" onFinish={onFinish}>
        <Form.Item label={t("email")} name="email">
          <Input />
        </Form.Item>

        <Form.Item label={t("phone_number")} name="phone_number">
          <Input type="phone" />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            onClick={() => form.submit()}
            loading={isLoading}
            style={{ width: "100%" }}
          >
            {t("save")}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
