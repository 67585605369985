import { useEffect, useState } from "react";

import { Form, Input, Switch, notification } from "antd";

import REQUESTS from "../../../../api/requests";

import ImageUpload from "../../../../components/ImageUpload";

import ButtonComponent from "../../../../_components/button/ButtonComponent";
import { useTranslation } from "react-i18next";

export default function StripeConfig({ data, selectedCurrency }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);

  const [icon, setIcon] = useState({
    url: null,
    file: null,
  });

  const onFinish = (values) => {
    setIsLoading(true);

    const formData = new FormData();

    if (icon.file) {
      formData.append("stripe_icon", icon.file);
    }

    formData.append("id", data.id);
    formData.append("currency_id", selectedCurrency);
    formData.append("stripe_enabled", values.stripe_enabled);
    formData.append("stripe_public_key", values.stripe_public_key);
    formData.append("stripe_secret_key", values.stripe_secret_key);
    formData.append("stripe_webhook_secret", values.stripe_webhook_secret);

    REQUESTS.PAYMENTS.CONFIGS.UPDATE(formData)
      .then((response) => {
        setIsLoading(false);

        if (response.error) {
          notification.error({
            message: t("error"),
            description: response.message,
          });

          return;
        }

        notification.success({
          message: t("success"),
          description: t("stripe_config_change"),
        });
      })
      .catch((error) => {
        setIsLoading(false);
        notification.error({
          message: t("error"),
          description: error,
        });
      });
  };

  const onChangeIcon = (value) => {
    setIcon(value);

    form.setFieldsValue({
      stripe_icon: value.file,
    });
  };

  useEffect(() => {
    if (data) {
      setIcon({
        url: data.stripe_icon,
        file: null,
      });

      form.setFields([
        { name: "stripe_icon", value: data.stripe_icon },
        { name: "stripe_enabled", value: data.stripe_enabled },
        { name: "stripe_public_key", value: data.stripe_public_key },
        { name: "stripe_secret_key", value: data.stripe_secret_key },
        { name: "stripe_webhook_secret", value: data.stripe_webhook_secret },
      ]);
    }
  }, [data]);

  return (
    <Form
      form={form}
      name="stripe-config"
      layout="vertical"
      onFinish={onFinish}
      style={{ maxWidth: 800 }}
    >
      <Form.Item shouldUpdate noStyle>
        {() => {
          const { stripe_enabled } = form.getFieldsValue();

          return (
            <Form.Item
              label={t("icon")}
              name="stripe_icon"
              rules={[
                {
                  required: stripe_enabled,
                  message: t("select_icon_message"),
                },
              ]}
            >
              <ImageUpload image={icon} setImage={onChangeIcon} />
            </Form.Item>
          );
        }}
      </Form.Item>

      <Form.Item label={t("enabled")} name="stripe_enabled" valuePropName="checked">
        <Switch />
      </Form.Item>

      <Form.Item shouldUpdate noStyle>
        {() => {
          const { stripe_enabled } = form.getFieldsValue();

          return (
            <Form.Item
              label={t("stripe_public_key")}
              name="stripe_public_key"
              rules={[
                {
                  required: stripe_enabled,
                  message: t("stripe_public_key_message"),
                },
              ]}
            >
              <Input />
            </Form.Item>
          );
        }}
      </Form.Item>

      <Form.Item shouldUpdate noStyle>
        {() => {
          const { stripe_enabled } = form.getFieldsValue();

          return (
            <Form.Item
              label={t("stripe_secret_key")}
              name="stripe_secret_key"
              rules={[
                {
                  required: stripe_enabled,
                  message: t("stripe_secret_key_message"),
                },
              ]}
            >
              <Input />
            </Form.Item>
          );
        }}
      </Form.Item>

      <Form.Item shouldUpdate noStyle>
        {() => {
          const { stripe_enabled } = form.getFieldsValue();

          return (
            <Form.Item
              label={t("stripe_webhook_secret")}
              name="stripe_webhook_secret"
              rules={[
                {
                  required: stripe_enabled,
                  message: t("stripe_webhook_secret_message"),
                },
              ]}
            >
              <Input />
            </Form.Item>
          );
        }}
      </Form.Item>

      <ButtonComponent
        title={t("save")}
        actionType="save"
        isLoading={isLoading}
        onClick={() => form.submit()}
      />
    </Form>
  );
}
