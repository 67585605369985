const Colors = {
    // main_active_color : "#00857c",
    main_active_color: "#ec6814",
    green: "#14746f",
    red: "#bf4342",

    blocked: "#e8de90",
    grey: "#dee2e6",
};

export default Colors;
