import request from "../../../api/request";
import { appHost } from "../../../helpers/appHost";

const host = appHost;

const URL = {
  LESSONS: `${host}admin/lessons`,
};

const lessonsApi = {
  GET: (query) => request("GET", URL.LESSONS, query),

  ADD: (query) => request("POST", URL.LESSONS, query),

  EDIT: (query) => request("PUT", URL.LESSONS, query),

  DELETE: (query) => request("DELETE", URL.LESSONS, query),
};

export default lessonsApi;
