import { Button, Switch } from "antd";
import { useEffect, useState } from "react";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";

import icons from "../../../config/icons";

import TableImage from "../../../_components/table/TableImage";
import TableComponent from "../../../_components/table/TableComponent";
import { useTranslation } from "react-i18next";

export default function PlatformsTable({ getAgain, onEdit, getDataSource, onChange }) {
  const { t } = useTranslation();
  const [dataSource, setDataSource] = useState([]);

  const [loading, setLoading] = useState(false);

  const columns = [
    {
      title: "#",
      width: 60,
      dataIndex: `id`,
      align: "center",
      key: "index",
      fixed: "left",
      render: (record, item, index) => {
        return index + 1;
      },
    },

    {
      title: t("enabled"),
      dataIndex: "is_enabled",
      key: "is_enabled",
      align: "center",

      render: (is_enabled, item, index) => (
        <Switch size="small" checked={is_enabled} onChange={(e) => onChange(e, item)} />
      ),
    },

    {
      title: t("icon"),
      dataIndex: "icon",
      key: "icon",
      align: "center",

      render: (text, record, index) => {
        return (
          <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
            <div
              style={{
                width: 35,
                height: 35,
                padding: 3,
                background: "#535353de",
                borderRadius: "50%",
              }}
            >
              <TableImage src={record.icon} />
            </div>
          </div>
        );
      },
    },

    {
      title: t("title"),
      dataIndex: "name",
      key: "name",
      align: "center",
    },

    {
      title: t("web_icon"),
      dataIndex: "web_icon",
      key: "web_icon",
      align: "center",

      render: (text, record, index) => {
        return <TableImage src={record.web_icon} />;
      },
    },

    {
      title: t("supported"),
      dataIndex: "supported",
      key: "supported",
      align: "center",
      render: (supported) => (
        <div>
          {supported ? (
            <CheckOutlined style={{ color: "green" }} />
          ) : (
            <CloseOutlined style={{ color: "red" }} />
          )}
        </div>
      ),
    },

    {
      title: "",
      dataIndex: "action",
      key: "action",
      align: "center",

      render: (text, record, index) => (
        <Button onClick={() => onEdit(record)}>{icons.EDIT}</Button>
      ),
    },
  ];

  useEffect(() => {
    setLoading(true);

    function onSuccess(data) {
      setLoading(false);

      if (!data.error) {
        setDataSource(data.rows);
      }
    }

    function onError() {
      setLoading(false);
    }

    getDataSource({ query: JSON.stringify({ limit: 1000 }) }, onSuccess, onError);
  }, [getAgain]);

  return <TableComponent isLoading={loading} dataSource={dataSource} columns={columns} />;
}
