import { appHost } from "../../../helpers/appHost";
import request from "../../request";

const host = appHost;

const URL = {
  INFO: `${host}info`,
};

export default {
  GET: (query) => request("GET", URL.INFO, query),

  EDIT: (query) => request("PUT", URL.INFO, query),
};
