import React, { useEffect, useState } from "react";
import movieJPEG from "../../../../img/movie.jpeg";

import requests, { requestSearchTmdb } from "../../../../server/requests";
import { Select } from "antd";

let timeout = null;

let searchTimeout = null;

export default function Search({ value, onChange, select, mediaType, languageId }) {
  const [showBlock, setShowBlock] = useState(false);

  const [selectedLanguage, setSelectedLanguage] = useState(null);

  const [languagesOptions, setLanguagesOptions] = useState([]);

  const [list, setList] = useState([]);

  const onChangeName = (text) => {
    if (text.length >= 3) {
      clearTimeout(searchTimeout);

      searchTimeout = setTimeout(() => {
        requestSearchTmdb(text, selectedLanguage, (data) => {
          let newList = [];

          for (let i = 0; i < data.length; i++) {
            if (data[i].media_type == "movie" && mediaType.value === "movie") {
              newList.push(data[i]);
            } else if (data[i].media_type == "tv" && mediaType.value === "tv_show") {
              newList.push(data[i]);
            }
          }

          setList(newList);
        });
      }, 300);
    }

    if (!showBlock) setShowBlock(true);
    onChange(text);
  };

  const selectValue = (item) => {
    onChange(item[mediaType.value === "movie" ? "title" : "name"]);
    setShowBlock(false);
    select(item);
  };

  const onFocusFunction = (event) => {
    event.target.parentNode.style.zIndex = 1;
  };

  const onBlurFunction = (event) => {
    let component = event.target.parentNode;
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      if (component) {
        component.style.zIndex = 0;
      }
    }, 500);
  };

  const imageError = (event) => {
    event.target.src = movieJPEG;
  };

  const getLanguages = () => {
    let query = {
      limit: 1000,
    };

    requests.languages.get(query, (data) => {
      const languagesList = data.map((item) => {
        return {
          label: item.name,
          value: item.id,
        };
      });

      const finded = data.find((item) => item.iso_code === "en");

      setSelectedLanguage(finded?.id);

      setLanguagesOptions(languagesList);
    });
  };

  useEffect(() => {
    getLanguages();
  }, []);
  return (
    <>
      <div style={{ width: "100%" }}>
        <label className="f-w-500 m-b-5">Search:</label>

        <div style={{ display: "flex", gap: 10 }}>
          <Select
            value={selectedLanguage}
            onSelect={setSelectedLanguage}
            options={languagesOptions}
            onChange={(e) => {
              languageId(e);
            }}
            style={{ width: 150 }}
            size="large"
            showSearch
            filterOption={(input, option) =>
              (option?.label.toLowerCase() ?? "").includes(input)
            }
            filterSort={(optionA, optionB) => {
              return (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase());
            }}
          />

          <div style={{ width: "100%", position: "relative" }}>
            <input
              type="text"
              value={value}
              name="name"
              autoComplete="off"
              className="input"
              onChange={(e) => onChangeName(e.target.value)}
              onFocus={onFocusFunction}
              onBlur={onBlurFunction}
              placeholder={`Search name...`}
            />

            {showBlock && list.length > 0 && (
              <div className="movie-search" style={{ marginTop: 10 }}>
                {list.map((item, key) => {
                  return (
                    <div
                      key={key}
                      className="found-movie"
                      onClick={() => selectValue(item)}
                    >
                      <img
                        src={item.poster_path ? item.poster_path.original : movieJPEG}
                        className="searching-movie-image"
                        onError={imageError}
                      />

                      <div style={{ marginLeft: 5 }}>
                        <h1>{item[mediaType.value === "movie" ? "title" : "name"]}</h1>
                        <h4>
                          <b>Type: </b>
                          {item.media_type}
                        </h4>
                        <h4>
                          <b>Release date: </b>{" "}
                          {item?.release_date || item?.first_air_date}
                        </h4>
                        <h4 className="text">{item.overview}</h4>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>

      {showBlock && (
        <div className="search-empty-block" onClick={() => setShowBlock(false)} />
      )}
    </>
  );
}
