import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Space, Tag } from "antd";

import { useUsersOptions } from "../../../hooks/selectOptions";

import icons from "../../../config/icons";
import { getColumnDateProps, getQueryDate, parseDate } from "../../../config/config";

import TableComponent from "../../../_components/table/TableComponent";
import {
  getAtLocal,
  getAtSession,
  setInLocal,
  setInSession,
} from "../../../helpers/storages";
import { asc_desc } from "../../../helpers/ascDesc";
import { useTranslation } from "react-i18next";

export default function PaymentHistoryTable({ getDataSource }) {
  const { t } = useTranslation();
  const usersOptions = useUsersOptions("text");

  const [dataSource, setDataSource] = useState([]);

  const [loading, setLoading] = useState(false);

  const [limit, setLimit] = useState(getAtLocal("pageLimit_paymentHistory") || 10);

  const [currentPage, setCurrentPage] = useState(
    getAtSession("paymentHistory")?.page || 1
  );

  const [sort, setSort] = useState(
    getAtSession("paymentHistory")?.sort || ["id", "DESC"]
  );

  const [date, setDate] = useState(null);

  const [total, setTotal] = useState(0);

  const [amount, setAmount] = useState(null);

  const [search, setSearch] = useState(
    getAtSession("paymentHistory")?.search || {
      user: null,
    }
  );

  const columns = [
    {
      width: 60,
      title: "#",
      key: "index",
      dataIndex: `id`,
      fixed: "left",
      align: "center",
      render: (text, record, index) => {
        return limit * (currentPage - 1) + index + 1;
      },
    },

    {
      title: t("user"),
      defaultFilteredValue: getAtSession("paymentHistory")?.search?.user,
      filteredValue: getAtSession("paymentHistory")?.search?.user,
      dataIndex: "user",
      key: "user",
      align: "center",
      // filters: [{ text: "N/A", value: null }],
      // filters: [
      //   {
      //     text: "Joe",
      //     value: "Joe",
      //   },
      //   {
      //     text: "Jim",
      //     value: "Jim",
      //   },
      // ],

      filters: usersOptions.slice(0, 10),

      onFilter: (value, record) => record.name?.includes(value),

      filterSearch: true,
      filterMultiple: false,
      render: (text, record, index) => {
        return record.user ? (
          <Link
            to={`/dashboard/user/${record?.user?.id}`}
            style={{ color: "#007bff" }}
            target="_blank"
          >
            {record?.user?.name}
          </Link>
        ) : (
          "N/A"
        );
      },
    },

    {
      title: t("amount"),
      defaultSortOrder:
        getAtSession("paymentHistory")?.sort?.[0] === "amount" &&
        asc_desc(getAtSession("paymentHistory")?.sort?.[1]),
      key: "amount",
      dataIndex: `amount`,
      align: "center",
      sorter: true,
      width: 120,
    },

    {
      title: t("payment_type"),
      key: "payment_type",
      dataIndex: `payment_type`,
      align: "center",
    },

    {
      title: t("date"),
      defaultFilteredValue: getAtSession("paymentHistory")?.search?.createdAt,
      filteredValue: getAtSession("paymentHistory")?.search?.createdAt,
      key: "createdAt",
      dataIndex: `createdAt`,
      align: "center",
      render: (text, record, index) => {
        return parseDate(text);
      },

      ...getColumnDateProps(setDate),
    },

    {
      title: t("status"),
      key: "status",
      dataIndex: `status`,
      align: "center",
      render: (text, record, index) => {
        return record?.status ? (
          <div style={{ color: "red" }}>{icons.ARROW_DOWN}</div>
        ) : (
          <div style={{ color: "green" }}>{icons.ARROW_UP}</div>
        );
      },
    },
  ];

  const handleTableChange = (data) => {
    const paymentHistory = {
      page: data.page,
      search: data.search,
    };
    if (data.sort && data.sort[1]) {
      paymentHistory.sort = [`${data.sort[0]}`, `${data.sort[1]}`];
    } else {
      paymentHistory.sort = ["id", "DESC"];
    }
    setInLocal("pageLimit_paymentHistory", data.limit);
    setInSession("paymentHistory", paymentHistory);

    setCurrentPage(data.page);
    setLimit(data.limit);

    setSort(data.sort);

    setSearch(data.search);
  };

  useEffect(() => {
    const fetchLiveTvData = () => {
      setLoading(true);

      const query = {
        // sort,
        limit,
        page: currentPage,
      };

      if (sort && sort[1]) {
        query.sort = sort;
      } else {
        query.sort = ["id", "DESC"];
      }

      if (search.name) {
        query.search = {
          name: search.name,
        };
      }

      if (search.categories) {
        query.filter = {
          categoryId: search.categories,
        };
      }

      function onSuccess(data) {
        setLoading(false);

        if (data?.rows?.length > 0) {
          setDataSource(data?.rows);
          // setIsEmpty(false);
        } else {
          // setIsEmpty(true);
        }

        setTotal(data?.count);
        setAmount(data?.amount);

        const maxPage = Math.ceil(data?.count / limit);
        const storedPage = getAtSession("paymentHistory")?.page || 1;

        if (storedPage > maxPage || storedPage < 1) {
          setInSession("paymentHistory", {
            ...getAtSession("paymentHistory"),
            page: 1,
          });
          setCurrentPage(1);
        } else {
          setInSession("paymentHistory", {
            ...getAtSession("paymentHistory"),
            page: currentPage,
          });
        }
      }

      function onError(data) {
        setLoading(false);
      }

      getDataSource({ query: JSON.stringify(query) }, onSuccess, onError);
    };

    const timeout = setTimeout(() => {
      if (
        getAtSession("paymentHistory") &&
        getAtSession("paymentHistory")?.page > currentPage
      ) {
        setInSession("paymentHistory", {
          ...getAtSession("paymentHistory"),
          page: 1,
        });
        setCurrentPage(getAtSession("paymentHistory")?.page);
      }

      fetchLiveTvData();
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [limit, currentPage, sort, search, date]);

  return (
    <TableComponent
      isLoading={loading}
      dataSource={dataSource}
      onChange={handleTableChange}
      columns={columns}
      // isEmpty={isEmpty}
      pagination={{
        page: currentPage,
        limit: limit,
        total: total,
      }}
      footer={() => (
        <Space>
          <Tag style={{ fontSize: 14 }}>
            {t("total")}: {amount?.total ? amount?.total.toFixed(2) : 0}
          </Tag>
          <Tag style={{ fontSize: 14 }}>
            {t("spent")}: {amount?.spent ? amount?.spent.toFixed(2) : 0}
          </Tag>
          <Tag style={{ fontSize: 14 }}>
            {t("unspent")}:{amount?.unspent ? amount?.unspent.toFixed(2) : 0}
          </Tag>
        </Space>
      )}
    />
  );
}
