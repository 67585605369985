import { Spin } from "antd";
import styles from "./blur.module.scss";

export default function Blur() {
    return (
        <div className={styles["container"]}>
            <Spin />
            Please wait...
        </div>
    );
}
