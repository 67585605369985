import { notification } from "antd";

import { useEffect, useState } from "react";

import REQUESTS from "../../../../../../api/requests";

import DevicesTable from "./DevicesTable";
import { useTranslation } from "react-i18next";

export default function DevicesTab({ user }) {
  const { t } = useTranslation();
  const [getAgain, setGetAgain] = useState(false);

  const getDataSource = (query, onSuccess, onError) => {
    try {
      REQUESTS.USERS.USER.DEVICES.GET(query)
        .then((response) => {
          onSuccess(response.message);
        })
        .catch((error) => {
          onError(error);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const handleMenuClick = (event, item) => {
    switch (event.key) {
      case "delete":
        REQUESTS.USERS.USER.DEVICES.DELETE({ id: item.id })
          .then((response) => {
            if (response.error) {
              notification.error({
                message: t("error"),
                description: response.message,
              });

              return;
            }

            notification.success({
              message: t("success"),
              description: response.message,
            });

            setGetAgain((prev) => !prev);
          })
          .catch((error) => {
            notification.error({
              message: t("error"),

              description: error,
            });
          });
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    setGetAgain((prev) => !prev);
  }, [user]);

  return (
    <div>
      <DevicesTable
        userId={user?.id}
        getAgain={getAgain}
        getDataSource={getDataSource}
        handleMenuClick={handleMenuClick}
      />
    </div>
  );
}
