import { Input } from "antd";
import DeleteConfirmButton from "../../../../_components/button/DeleteConfirmButton";
import { useTranslation } from "react-i18next";

export default function Resolutions({ resolutions = [], setResolutions }) {
  const { t } = useTranslation();
  const onChangeResolutions = (e, id) => {
    const _resolutions = resolutions.map((item) => {
      if (item.id === id) {
        return {
          ...item,
          input: e.target.value,
        };
      }

      return item;
    });

    setResolutions(_resolutions);
  };

  const removeResolution = (id) => {
    const _resolutions = resolutions.filter((item) => item.id !== id);

    setResolutions(_resolutions);
  };

  return (
    <div style={{ marginBottom: 20 }}>
      <span>{t("resolutions")}</span>

      {resolutions.map((resolution, index) => {
        return (
          <div style={{ display: "flex", gap: 10, marginTop: 10 }}>
            <Input
              value={resolution.name}
              readOnly
              style={{ color: "gray", background: "#8080801c", width: 230 }}
            />

            <Input
              placeholder={t("input")}
              value={resolution.input}
              onChange={(e) => onChangeResolutions(e, resolution.id)}
              style={{ width: 270 }}
            />

            <DeleteConfirmButton
              disabled={resolutions.length === 1}
              name="resolition"
              onConfirm={() => removeResolution(resolution.id)}
            />
          </div>
        );
      })}
    </div>
  );
}
