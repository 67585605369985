import { Popconfirm, Space, notification } from "antd";

import REQUESTS from "../../../../api/requests";
import icons from "../../../../config/icons";
import ButtonComponent from "../../../../_components/button/ButtonComponent";
import { useTranslation } from "react-i18next";

export default function ActionsForAll({ setGetAgain }) {
  const { t } = useTranslation();
  const onChange = (enable) => {
    REQUESTS.VOD.MEDIAS.ENABLE_OR_DISABLE_ALL({
      enable,
    })
      .then((response) => {
        if (response.error) {
          notification.error({
            message: t("error"),
            description: response.message,
          });

          return;
        }

        setGetAgain((prev) => !prev);

        notification.success({
          message: t("success"),
          description: response.message,
        });
      })
      .catch((error) => {
        notification.error({
          message: t("error"),

          description: error,
        });
      });
  };

  return (
    <Space>
      <Popconfirm
        placement="topRight"
        title={t("enable_all_content_message")}
        onConfirm={() => onChange(true)}
        okText={t("enable")}
        cancelText={t("cancel")}
        okButtonProps={{
          className: "enable-button",
        }}
      >
        <ButtonComponent
          icon={icons.ENABLE}
          title={t("enable_all")}
          className="enable-button"
        />
      </Popconfirm>

      <Popconfirm
        placement="topRight"
        title={t("disable_all_content_message")}
        onConfirm={() => onChange(false)}
        okText={t("disable")}
        cancelText={t("cancel")}
        okButtonProps={{
          className: "disable-button",
        }}
      >
        <ButtonComponent
          icon={icons.DISABLE}
          title={t("disable_all")}
          className="disable-button"
        />
      </Popconfirm>
    </Space>
  );
}
