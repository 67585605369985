import { useEffect, useState } from "react";

import { Checkbox, Drawer, Form, Select, notification } from "antd";

import REQUESTS from "../../../api/requests";

import ButtonComponent from "../../../_components/ButtonComponent";

import { usePaymentsSubscriptionsOptions } from "../../../hooks/selectOptions";
import { useTranslation } from "react-i18next";

export default function TariffDrawer({ onClose, open, user, getData }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const tariffOptions = usePaymentsSubscriptionsOptions();

  const [form] = Form.useForm();

  const onFinish = (values) => {
    setIsLoading(true);

    const body = {
      user_id: user?.id,
      tariff_id: values.tariff_id,
      auto_renewal: values.auto_renewal,
    };

    REQUESTS.USERS.USERS.TARIFF(body)
      .then((response) => {
        setIsLoading(false);

        if (response.error) {
          notification.error({
            message: t("error"),
            description: response.message,
          });

          return;
        }

        getData();
        onClose();
      })
      .catch((err) => {
        notification.error({
          message: t("error"),

          description: err,
        });
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!open) {
      form.resetFields();
    }

    if (user) {
      form.setFields([
        { name: "auto_renewal", value: user.auto_renewal },
        { name: "tariff_id", value: user.tariffId },
      ]);
    }
  }, [open, user]);

  return (
    <Drawer
      open={open}
      placement="right"
      onClose={onClose}
      title={t("choose_tariff_for_user")}
    >
      <Form
        form={form}
        name="user-tariff"
        layout="vertical"
        onFinish={onFinish}
        // onKeyPress={(e) => {
        //     if (e.key === "Enter") {
        //         form.submit();
        //     }
        // }}
      >
        <Form.Item
          label={t("tariff")}
          name="tariff_id"
          rules={[
            {
              required: true,
              message: t("tariff_select_message"),
            },
          ]}
        >
          <Select options={tariffOptions} />
        </Form.Item>

        <Form.Item name="auto_renewal" noStyle valuePropName="checked">
          <Checkbox>{t("auto_renewal")}</Checkbox>
        </Form.Item>

        <Form.Item style={{ textAlign: "center", marginTop: 20 }}>
          <ButtonComponent
            title={t("save")}
            actionType="save"
            isLoading={isLoading}
            onClick={() => form.submit()}
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
}
