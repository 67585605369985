import request from "../../../api/request";
import { DEMO_API_HOST } from "../../../config/statics";
import { appHost } from "../../../helpers/appHost";

const host = appHost;

const URL = {
  DOCUMENT: `${host}v2/admin/document`,
};

const documentsApi = {
  GET: (query) => request("GET", URL.DOCUMENT, query),

  EDIT: (query) => request("PUT", URL.DOCUMENT, query),
};

export default documentsApi;
