import { Drawer, Form, Select, Input } from "antd";
import { useTranslation } from "react-i18next";
import ButtonComponent from "../../../_components/button/ButtonComponent";
import ICONS from "../../../config/icons";
import { useEffect } from "react";

const PromoCodesDrawer = ({
  open,
  onClose,
  tariffOptions,
  editableData,
  onSave,
  loading,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const durationTypes = [
    {
      label: "Day",
      value: "day",
    },
    {
      label: "Month",
      value: "month",
    },
    {
      label: "Year",
      value: "year",
    },
  ];

  const finish = (values) => {
    onSave(values);
  };

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  useEffect(() => {
    if (editableData) {
      form.setFieldsValue({
        tariff_id: editableData.tariff_id,
        duration: editableData.duration,
        duration_type: editableData.duration_type,
        group_name: editableData.group_name,
        count: editableData.count,
      });
    }
    if (!open) {
      form.resetFields();
    }
  }, [editableData, open]);

  return (
    <Drawer
      title={`${editableData ? t("edit") : t("add")} ${t("promo_code")}`}
      closable={true}
      onClose={handleClose}
      open={open}
      destroyOnClose={true}
      width={600}
      footer={
        <div
          style={{
            textAlign: "right",
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            gap: 5,
          }}
        >
          <ButtonComponent onClick={onClose} type="default" title={t("cancel")} />

          <ButtonComponent
            onClick={() => form.submit()}
            type="primary"
            title={t("save")}
            icon={ICONS.CHECK}
            isLoading={loading}
          />
        </div>
      }
    >
      <div></div>
      <Form form={form} onFinish={finish} layout="vertical">
        <Form.Item
          label={t("tariff")}
          name="tariff_id"
          rules={[
            {
              required: true,
              message: t("select_tariff_message"),
            },
          ]}
        >
          <Select options={tariffOptions} allowClear />
        </Form.Item>
        <Form.Item
          label={t("duration")}
          name="duration"
          rules={[
            {
              required: true,
              message: t("duration_message"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label={t("duration_type")}
          name="duration_type"
          rules={[
            {
              required: true,
              message: t("duration_type_message"),
            },
          ]}
        >
          <Select options={durationTypes} allowClear />
        </Form.Item>
        <Form.Item
          label={t("group_name")}
          name="group_name"
          rules={[
            {
              required: true,
              message: t("group_name_message"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        {!editableData && (
          <Form.Item
            label={t("count")}
            name="count"
            rules={[
              {
                required: true,
                message: t("count_message"),
              },
            ]}
          >
            <Input />
          </Form.Item>
        )}
      </Form>
    </Drawer>
  );
};

export default PromoCodesDrawer;
