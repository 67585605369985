import { Button } from "antd";
import styles from "./style.module.scss";

export default function ButtonComponent({
  title,
  type = "primary",
  icon,
  isLoading,
  onClick,
  danger,
  disabled,
  style,
  className,
}) {
  return (
    <Button
      style={style}
      type={type}
      disabled={disabled}
      danger={danger}
      loading={isLoading}
      icon={icon}
      onClick={onClick}
      className={`${styles["button-style"]} ${className ? className : ""}`}
    >
      {title}
    </Button>
  );
}
