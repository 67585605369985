import { useEffect, useState } from "react";

import REQUESTS from "../../api/requests";

import Statistics from "./Statistics";

import PlaylistForm from "./PlaylistForm";

import PageComponent from "../../_components/page/PageComponent";
import { useTranslation } from "react-i18next";
import { notification } from "antd";

export default function ImportPage() {
  const { t } = useTranslation();
  const [data, setData] = useState({
    status: "IDLE",
    channels: { total: 0, done: 0 },
    movies: { total: 0, done: 0 },
    series: { total: 0, done: 0 },
  });

  const getData = () => {
    try {
      REQUESTS.IMPORT.PARSE().then((response) => {
        if (!response.error) {
          setData(response.message);
        } else {
          notification.error({
            message: t("error"),
            description: response.message,
          });
          setData({
            status: "IDLE",
            channels: { total: 0, done: 0 },
            movies: { total: 0, done: 0 },
            series: { total: 0, done: 0 },
          });
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    let interval = setInterval(() => {
      getData();
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <PageComponent routes={[t("import")]}>
      <PlaylistForm status={data?.status} />

      {data?.status !== "IDLE" && <Statistics data={data} />}
    </PageComponent>
  );
}
