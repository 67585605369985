import { Drawer, Image } from "antd";

import userImage from "../../../img/user.png";

import { parseDateFormat } from "../../../config/config";

import styles from "./_cast.module.scss";
import { useTranslation } from "react-i18next";

export default function CastInfoDrawer({ onClose, open, info }) {
  const { t } = useTranslation();

  return (
    <Drawer
      width={800}
      title={`${info?.name} ${t("info")}`}
      placement="right"
      onClose={onClose}
      open={open}
      destroyOnClose
    >
      <div className={styles["info-drawer"]}>
        <div className={styles["container"]}>
          <img
            src={info?.image}
            onError={(e) => (e.target.src = userImage)}
            style={{
              float: "left",
              marginRight: 20,
              marginBottom: 10,
            }}
          />

          <div className={styles["info"]}>
            <h3>{info?.name}</h3>
            <p>
              <b>{t("genre")}:</b> {info?.gender}
            </p>
            <p>
              <b>{t("birth_in")}:</b> {info?.country}
            </p>
            <p>
              <b>{t("birthday")}:</b> {parseDateFormat(info?.birthday)}
            </p>
            {info?.deathday && (
              <p>
                <b>{t("deathday")}:</b> {parseDateFormat(info?.deathday)}
              </p>
            )}

            <p>{info?.bio}</p>
          </div>
        </div>

        {info?.galery?.length > 0 && (
          <div className={styles["galeries"]}>
            <p> {t("galleries")}</p>

            <div className={styles["list"]}>
              {info?.galery?.map((galery, index) => (
                <div className={styles["galery"]} key={index}>
                  <Image src={galery.source} />
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </Drawer>
  );
}
