import style from "./pricingPage.module.scss";
import { Button, Result } from "antd";
import PATHS from "../../config/paths";
import REQUESTS from "../../api/requests";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PricingCards from "./components/PricingCards";
import Loading from "../../_components/loadings/Loading";
import icons from "../../config/icons";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const PricingPage = ({ host, key }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { packages, price_list } = useSelector((state) => state.globalState);

  const [pricingList, setPricingList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showError, setShowError] = useState(false);

  const getPriceList = (host, key) => {
    setLoading(true);
    try {
      function success(data) {
        setLoading(false);
        setShowError(false);
        setPricingList(data?.rows);
      }

      function error(error) {
        setLoading(false);
        setShowError(true);

        console.log(error);
      }

      REQUESTS.PRICING.PRICING_PACKAGE.GET({ key }, host)
        .then((res) => {
          success(res);
        })
        .catch((err) => {
          error(err);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const handleId = (id) => {
    setLoading(true);

    REQUESTS.PRICING.PRICING_PACKAGE.UPGRADE(
      JSON.stringify({ package_id: id, key: packages?.key }),
      packages?.host
    )
      .then((res) => {
        window.open(res?.url, "_blank");
        setLoading(false);
        setShowError(false);
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
      });
  };

  useEffect(() => {
    if (packages?.host) {
      getPriceList(packages?.host, packages?.key);
    }
  }, [packages]);

  return (
    <>
      <div className={style["pricing"]}>
        <div className={style["pricing-page-header"]}>
          <div className={style["backward"]}>
            <Link to={"/dashboard/content"}>
              <span className={style["backward-icon"]}>{icons.SECONDARY_BACK}</span>
              {t("back_to_dashboard")}
            </Link>
          </div>
        </div>
        <span className={style["pricing-head"]}>{t("pricing_plan_options")}</span>
        <span className={style["pricing-description"]}>
          {t("pricing_plan_options_description")}
        </span>
        <div className={style["pricing-card-wrapper"]}>
          {showError ? (
            <div className={style["result"]}>
              <Result
                status="404"
                title={t("something_wrong")}
                subTitle={t("tray_later")}
                extra={
                  <Button
                    type="primary"
                    onClick={() => {
                      navigate("/dashboard/content");
                    }}
                  >
                    {t("go_home")}
                  </Button>
                }
              />
            </div>
          ) : (
            <>
              {pricingList?.map((item, idx) => {
                return (
                  <PricingCards item={item} getId={(id) => handleId(id)} key={idx} />
                );
              })}
            </>
          )}
        </div>
      </div>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            zIndex: 100,
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, .6)",
          }}
        >
          <Loading
            size="large"
            style={{
              height: "100%",
            }}
          />
        </div>
      )}
    </>
  );
};

export default PricingPage;
