import React, { useState } from "react";

export const ActionsBlock = (props) => {
    const [show, setShow] = useState(false);

    return (
        <>
            <div
                className="actions-button"
                onClick={(e) => {
                    setShow(!show);
                    if (props.withShow) {
                        props.withShow(e);
                    }
                }}
            >
                <i className="fa fa-ellipsis-h" style={{ color: "grey" }} />
            </div>

            {show && (
                <>
                    <div className="actions-block">{props.children}</div>

                    <div
                        className="actions-block-empty"
                        onClick={() => {
                            setShow(false);
                            if (props.withHide) {
                                props.withHide();
                            }
                        }}
                    />
                </>
            )}
        </>
    );
};

export const closeActionsBlock = () => {
    if (document.getElementsByClassName("actions-block-empty")[0])
        document.getElementsByClassName("actions-block-empty")[0].click();
};
