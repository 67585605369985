import { useEffect, useState } from "react";

import { Button, Drawer, Form, Input } from "antd";

import requests from "../../../server/requests";

export default function QuickMessageDrawer({ onClose, open, current, getQuickMessages }) {
    const [isLoading, setIsLoading] = useState(false);

    const [form] = Form.useForm();

    const onFinish = (values) => {
        setIsLoading(true);

        function callback() {
            getQuickMessages();
            onClose();
            setIsLoading(false);
        }

        function errorCallback(err) {
            setIsLoading(false);
            // notification.error({
            //     description: err.message,
            // });
        }

        const body = {
            message: values.message,
        };

        if (current) {
            body.id = current.id;
            requests.chat.quick_messages.edit(body, callback, errorCallback);
        } else {
            requests.chat.quick_messages.add(body, callback, errorCallback);
        }
    };

    useEffect(() => {
        if (!open) {
            form.resetFields();
        }

        if (current) {
            form.setFields([{ name: "message", value: current.message }]);
        }
    }, [open, current]);

    return (
        <Drawer
            title={`${current ? "Edit" : "Add"} qucik message`}
            placement="right"
            onClose={onClose}
            open={open}
        >
            <Form form={form} name="quick_message" layout="vertical" onFinish={onFinish}>
                <Form.Item
                    name="message"
                    rules={[
                        {
                            required: true,
                            message: "Please input message",
                        },
                    ]}
                >
                    <Input.TextArea rows={6} />
                </Form.Item>

                <Form.Item style={{ textAlign: "center" }}>
                    <Button type="primary" onClick={() => form.submit()} loading={isLoading}>
                        Save
                    </Button>
                </Form.Item>
            </Form>
        </Drawer>
    );
}
