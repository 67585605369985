import { Divider, Form, Image, InputNumber, Space } from "antd";

import IMAGES from "../../../../../config/images";

import styles from "./_meta_data.module.scss";
import { useTranslation } from "react-i18next";

export default function Ratings({ form }) {
  const { t } = useTranslation();
  const ratings = [
    {
      label: "Tmdb",
      name: "tmdb",
      icon: IMAGES.TMDB,
      value: form.getFieldsValue().tmdb,
    },

    {
      label: "Imdb",
      name: "imdb",
      icon: IMAGES.IMDB,
      value: form.getFieldsValue().imdb,
    },

    {
      label: "Metacritic",
      name: "metacritic",
      icon: IMAGES.METACRITIC,
      value: form.getFieldsValue().metacritic,
    },

    {
      label: "Rotten Tomatos",
      name: "rotten_tomatos",
      icon: IMAGES.ROTTEN_TOMATOS,
      value: form.getFieldsValue().rotten_tomatos,
    },
  ];
  return (
    <div>
      <Divider orientation="left" plain>
        <b>{t("ratings")}</b>
      </Divider>

      <div className={styles["ratings"]}>
        {ratings.map((rating) => (
          <Form.Item label={rating.label} name={rating.name} key={rating.name}>
            <Space>
              <Image preview={false} src={rating.icon} />

              <InputNumber
                style={{ width: "100%" }}
                controls={false}
                value={rating.value}
              />
            </Space>
          </Form.Item>
        ))}
      </div>
    </div>
  );
}
