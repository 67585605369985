import React, { useEffect, useState } from "react";
import { Button, notification } from "antd";

import { PlusOutlined } from "@ant-design/icons";

import requests from "../../../server/requests";

import QuickMessage from "./QuickMessage";

import QuickMessageDrawer from "./QuickMessageDrawer";

import style from "./_quick-messages.module.scss";

export default function QuickMessages({ setSelectedQuickMessage }) {
    const [quickMessages, setQuickMessages] = useState([]);

    const [isOpenDrawer, setIsOpenDrawer] = useState(false);
    const [currentQucikMessage, setCurrentQuickMessage] = useState(null);

    const getQuickMessages = () => {
        function callback(data) {
            if (data.rows) {
                let list = data.rows.reverse();
                setQuickMessages(list);
            }
        }

        function errorCallback(data) {}

        requests.chat.quick_messages.get(callback, errorCallback);
    };

    const deleteQuickMessage = (id) => {
        function callback() {
            getQuickMessages();

            notification.success({
                description: "This quick message is removed",
            });
        }

        requests.chat.quick_messages.delete(id, callback);
    };

    const closeDrawer = () => {
        setIsOpenDrawer(false);
        setCurrentQuickMessage(null);
    };

    const onEdit = (item) => {
        setIsOpenDrawer(true);
        setCurrentQuickMessage(item);
    };

    useEffect(() => {
        getQuickMessages();
    }, []);

    return (
        <>
            <div className={style["quick-messages"]}>
                <div className={style["add-quick-message"]}>
                    <p>Quick messages</p>

                    <Button
                        type="primary"
                        icon={<PlusOutlined />}
                        onClick={() => setIsOpenDrawer(true)}
                    />
                </div>

                <div className={style["list"]}>
                    {quickMessages.map((item) => {
                        return (
                            <QuickMessage
                                item={item}
                                key={item.id}
                                onEdit={() => onEdit(item)}
                                deleteQuickMessage={deleteQuickMessage}
                                onClick={() => setSelectedQuickMessage(item)}
                            />
                        );
                    })}
                </div>
            </div>

            <QuickMessageDrawer
                open={isOpenDrawer}
                onClose={closeDrawer}
                current={currentQucikMessage}
                getQuickMessages={getQuickMessages}
            />
        </>
    );
}
