import { useEffect, useState } from "react";

import icons from "../../../../../../config/icons";
import { parseDate } from "../../../../../../config/config";

import TableButtons from "../../../../../../_components/table/TableButtons";
import TableComponent from "../../../../../../_components/table/TableComponent";
import { useTranslation } from "react-i18next";

export default function DevicesTable({
  getAgain,
  getDataSource,
  handleMenuClick,
  userId,
}) {
  const { t } = useTranslation();
  const [dataSource, setDataSource] = useState([]);

  const [loading, setLoading] = useState(false);

  const columns = [
    {
      title: "#",
      width: 60,
      dataIndex: `id`,
      align: "center",
      key: "index",
      render: (record, text, index) => {
        return index + 1;
      },
    },

    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
      align: "center",
    },

    {
      title: t("model"),
      dataIndex: "model",
      key: "model",
      align: "center",
    },

    {
      title: "Ipv4",
      dataIndex: "ipv4",
      key: "ipv4",
      align: "center",
    },

    {
      title: t("connect_date"),
      dataIndex: "connectDate",
      key: "connectDate",
      align: "center",

      render: (text, record, index) => {
        return parseDate(record.connect_date);
      },
    },

    {
      title: "",
      key: "operation",
      fixed: "right",
      width: 60,
      align: "center",

      render: (text, record, index) => (
        <TableButtons
          handleMenuClick={(e) => handleMenuClick(e, record)}
          items={[
            {
              key: "delete",
              name: t("delete"),
              icon: icons.DELETE,
              question: t("delete_device_message"),
              onConfirm: (e) => {
                handleMenuClick(e, record);
              },
            },
          ]}
        />
      ),
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(true);

      function onSuccess(data) {
        setLoading(false);
        setDataSource(data);
      }

      function onError(data) {
        setLoading(false);
      }

      getDataSource({ id: userId }, onSuccess, onError);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [getAgain]);

  return <TableComponent isLoading={loading} dataSource={dataSource} columns={columns} />;
}
