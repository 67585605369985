import { useEffect, useState } from "react";

import { Drawer, notification } from "antd";

import REQUESTS from "../../../api/requests";

import VodSearch from "../../../_components/searches/VodSearch";
import ButtonComponent from "../../../_components/ButtonComponent";

import VodCollectionMoviesTable from "./VodCollectionMoviesTable";
import { useTranslation } from "react-i18next";

export default function ManageMoviesDrawer({ onClose, open, collection, getData }) {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const [getAgain, setGetAgain] = useState(false);

  const [possitions, setPossitions] = useState(null);

  const onSelectMovie = (movie) => {
    const body = {
      movieId: movie,
      collectionId: collection.id,
    };

    REQUESTS.VOD.COLLECTIONS.MOVIES.ADD(body)
      .then((response) => {
        console.log(response);
        if (response.error) {
          notification.error({
            message: t("error"),
            description: response.message,
          });

          return;
        }

        notification.success({
          message: t("success"),
          description: response.message,
        });

        setGetAgain((prev) => !prev);
      })
      .catch((err) => {
        notification.error({
          message: t("error"),
          description: err,
        });
      });
  };

  const getDataSource = (query, onSuccess, onError) => {
    REQUESTS.VOD.COLLECTIONS.MOVIES.GET({
      // query: JSON.stringify(query),
      collectionId: collection.id,
    })
      .then((response) => {
        onSuccess(response.message);
      })
      .catch((error) => {
        onError(error);
      });
  };

  const onDelete = (id) => {
    REQUESTS.VOD.COLLECTIONS.MOVIES.DELETE({ collectionId: collection.id, movieId: id })

      .then((response) => {
        if (response.error) {
          notification.error({
            message: t("error"),

            description: response.message,
          });

          return;
        }

        notification.success({
          message: t("success"),

          description: response.message,
        });

        setGetAgain((prev) => !prev);
      })

      .catch((error) => {
        notification.error({
          message: t("error"),

          description: error,
        });
      });
  };

  const changePosition = (ids) => {
    setPossitions(ids);
  };

  const onSave = () => {
    REQUESTS.VOD.COLLECTIONS.MOVIES.SORT({
      collectionId: collection.id,
      movieIds: possitions,
    })
      .then((response) => {
        if (response.error) {
          notification.error({
            message: t("error"),
            description: response.message,
          });
          return;
        }
        notification.success({
          message: t("success"),

          description: response.message,
        });
        onClose();
      })
      .catch((error) => {
        notification.error({
          message: t("error"),

          description: error,
        });
      });
  };

  useEffect(() => {
    if (!open) {
      setIsLoading(false);
      setPossitions(null);
    }
  }, [open]);

  return (
    <Drawer
      width={800}
      title={`${collection?.name}  ${t("movies")}`}
      placement="right"
      onClose={onClose}
      open={open}
    >
      {open && <VodSearch onChange={onSelectMovie} multiple={false} />}

      {open && (
        <VodCollectionMoviesTable
          getAgain={getAgain}
          getDataSource={getDataSource}
          changePosition={changePosition}
          onDelete={onDelete}
        />
      )}

      <div style={{ textAlign: "center", marginTop: 20 }}>
        <ButtonComponent
          title={t("save")}
          actionType="save"
          isLoading={isLoading}
          onClick={onSave}
          disabled={!possitions}
        />
      </div>
    </Drawer>
  );
}
