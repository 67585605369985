import React, { useEffect, useState } from "react";
import MyPopup from "../../../../popups/MyPopup";
import ImageChooser from "../../../../components/ImageCooser";
import InputComponent from "../../../../components/InputComponent";
import { requestAddOrUpdateSerie } from "../../../../server/requests";
import TextareaComponent from "../../../../components/TextareaComponent";
import Translations from "./Translations";
import { createPortal } from "react-dom";
import Player from "../Player";
import { inoToast } from "../../../../components/toast/Toast";
import { streamTypeOptions } from "../../../../config/config";
import SelectComponent from "../../../../components/SelectComponent";
import TranscodingSearch from "../../../../_components/searches/TranscodingSearch";
import REQUESTS from "../../../../api/requests";
import { Drawer } from "antd";

function SeriePopup({ onClose, getData, seasonId, editableSerie, open }) {
  const [serie, setSerie] = useState({
    id: "",
    name: "",
    number: "",
    // seasonId: seasonId,
    description: "",
    duration: "",
    poster: "",
    release_date: "",
    stream_url: "",
    credits_time: 0,
    intro_start_time: 0,
    intro_end_time: 0,
  });

  const [showPlayer, setShowPlayer] = useState(false);

  const [url, setUrl] = useState("");

  const [streamType, setStreamType] = useState(streamTypeOptions[0]);

  const [selectedTranscoding, setSelectedTranscoding] = useState(null);

  const [selectedTranscodingId, setSelectedTranscodingId] = useState(null);

  useEffect(() => {
    setSerie({
      ...serie,
      seasonId,
    });
  }, [seasonId]);

  const onChange = (event) => {
    setSerie({
      ...serie,
      [event.target.name]: event.target.value,
    });
  };
  const onChangeSkeep = (event) => {
    const newObject = { ...serie };

    let value = event.target.value;

    const { intro_start_time, intro_end_time, credits_time, duration } = newObject;

    if (value) {
      value = Number(value);
    }

    switch (event.target.name) {
      case "intro_start_time":
        if (value > intro_end_time) {
          inoToast.error("Intro start time can't be big from Intro end time");

          return;
        }

        newObject.intro_start_time = value;

        break;

      case "intro_end_time":
        if (value > credits_time) {
          inoToast.error("Intro end time can't be big from Credits time");

          return;
        }

        if (value < intro_start_time) {
          inoToast.error("Intro end time can't be less from Intro start time");

          return;
        }

        newObject.intro_end_time = value;

        break;

      case "credits_time":
        if (value < intro_end_time) {
          inoToast.error("Credits time can't be less from Intro end time");

          return;
        }

        newObject.credits_time = value;

        break;

      case "duration":
        newObject.duration = value;

      default:
        break;
    }

    setSerie(newObject);
  };

  let addOrderTimeOut;

  const asyncAddOrUpdateSerie = () => {
    clearTimeout(addOrderTimeOut);
    addOrderTimeOut = setTimeout(() => {
      addOrUpdateSerie();
    }, 1000);
  };

  const addOrUpdateSerie = () => {
    const type = !editableSerie ? "post" : "put";
    let formData = new FormData();
    formData.append("name", serie.name);
    formData.append("description", serie.description);
    formData.append("duration", serie.duration);
    formData.append("credits_time", serie.credits_time);
    formData.append("intro_start_time", serie.intro_start_time);
    formData.append("intro_end_time", serie.intro_end_time);

    formData.append("number", serie.number);

    formData.append("stream_type", streamType.value);

    if (streamType.value === "internal") {
      formData.append("stream_url", "");
      formData.append("transcoding_id", selectedTranscodingId);
    } else {
      formData.append("stream_url", serie.stream_url);
      formData.append("transcoding_id", null);
    }

    if (type == "put") formData.append("id", serie.id);
    if (type == "post") formData.append("seasonId", serie.seasonId);
    if (serie.release_date) formData.append("release_date", serie.release_date);
    if (typeof serie.poster === "object") formData.append("poster", serie.poster);

    requestAddOrUpdateSerie(type, formData, (data) => {
      onClose();
      getData();
    });
  };

  const saveSkeepOption = (option) => {
    setSerie({
      ...serie,
      intro_start_time: option.start,
      intro_end_time: option.end,
      credits_time: option.credits,
      duration: option.duration,
    });
  };
  const handleClose = () => {
    onClose();

    setSerie({
      // ...serie,
      id: "",
      name: "",
      description: "",
      number: "",
      poster: "",
      release_date: "",
      stream_url: "",
      credits_time: 0,
      intro_start_time: 0,
      intro_end_time: 0,
    });
  };

  useEffect(() => {
    if (editableSerie) {
      setSerie(editableSerie);

      if (editableSerie.stream_type) {
        const streamType = streamTypeOptions.find(
          (item) => item.value === editableSerie.stream_type
        );

        setStreamType(streamType);
      }

      if (editableSerie?.transcoding_id) {
        setSelectedTranscodingId(editableSerie.transcoding_id);
      }
    }
  }, [editableSerie]);

  const generateUrlFromTranscoding = () => {
    REQUESTS.VOD.TRANSCODING.GET({
      query: JSON.stringify({
        filter: {
          id: selectedTranscodingId,
        },
      }),
    }).then((response) => {
      if (response?.rows) {
        const transcoding = response.rows[0];

        setSelectedTranscoding(transcoding);
      }
    });
  };

  useEffect(() => {
    if (selectedTranscodingId) {
      generateUrlFromTranscoding();
    }
  }, [selectedTranscodingId]);

  return (
    <>
      <Drawer open={open} onClose={handleClose} title="Add serie" width={600}>
        <div style={{ marginBottom: 20 }}>
          <div className="image-label">
            <label className="f-w-500 m-b-5">Poster:</label>

            <span>1080 x 1920</span>
          </div>

          <ImageChooser
            image={serie.poster}
            id="seriePosterId"
            onChangeImage={(poster) => setSerie({ ...serie, poster })}
            isOpen={open}
          />
        </div>

        <InputComponent
          label="Name"
          name="name"
          value={serie.name}
          onChange={onChange}
          placeholder="Enter name..."
        />

        <InputComponent
          type="number"
          label="Number"
          name="number"
          value={serie.number}
          onChange={onChange}
          placeholder="Enter number..."
        />

        <SelectComponent
          label="Stream type"
          isMulti={false}
          value={streamType}
          options={streamTypeOptions}
          change={setStreamType}
        />

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-end",
          }}
        >
          {streamType?.value === "internal" ? (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                textAlign: "start",
              }}
            >
              <div style={{ width: "95%", marginRight: 10 }}>
                <TranscodingSearch
                  value={selectedTranscodingId}
                  onChange={setSelectedTranscodingId}
                />
              </div>

              <div
                className="media-play-button"
                style={{
                  marginTop: 12,
                  fontSize: 23,
                  color: selectedTranscoding ? "#14746f" : "#8f8f8f",
                  cursor: selectedTranscoding ? "pointer" : "no-drop",
                }}
                onClick={() => {
                  setShowPlayer(true);

                  setUrl(selectedTranscoding?.stream_url);
                }}
              >
                <i className="fas fa-play-circle" />
              </div>
            </div>
          ) : (
            <>
              <InputComponent
                style={{ width: "93%" }}
                name="stream_url"
                label="Stream url"
                onChange={onChange}
                value={serie.stream_url}
                placeholder="Enter stream url..."
              />

              <div
                className="media-play-button"
                style={{ marginBottom: 10 }}
                onClick={() => {
                  setShowPlayer(true);

                  setUrl(serie.stream_url);
                }}
              >
                <i className="fas fa-play-circle" />
              </div>
            </>
          )}
        </div>

        <div className="into-section-row">
          <InputComponent
            type="number"
            label="Duration(m)"
            name="duration"
            value={serie.duration}
            onChange={onChangeSkeep}
            placeholder="Enter duration..."
          />
          <InputComponent
            type="number"
            name="intro_start_time"
            label="Intro start time(s)"
            value={serie.intro_start_time}
            onChange={onChangeSkeep}
            placeholder={"Seconds"}
          />

          <InputComponent
            type="number"
            name="intro_end_time"
            label="Intro end time(s)"
            value={serie.intro_end_time}
            onChange={onChangeSkeep}
            placeholder={"Seconds"}
          />

          <InputComponent
            type="number"
            name="credits_time"
            label="Credits time(s)"
            value={serie.credits_time}
            onChange={onChangeSkeep}
            placeholder={"Seconds"}
          />
        </div>

        <TextareaComponent
          label="Description"
          name="description"
          value={serie.description}
          onChange={onChange}
          placeholder="Enter description..."
        />

        {editableSerie && <Translations id={serie.id} model="episode" />}

        {
          <button
            style={{ marginTop: 20 }}
            className={`popup-button-style ${
              !editableSerie ? "add-button" : "edit-button"
            } `}
            onClick={asyncAddOrUpdateSerie}
          >
            <i
              className={!editableSerie ? "fas fa-plus" : "fas fa-pencil-alt"}
              style={{ marginRight: 5 }}
            />

            {editableSerie ? "Edit" : "Add"}
          </button>
        }
      </Drawer>

      {createPortal(
        <Player
          type="hls"
          isVisible={showPlayer}
          src={url}
          hideModal={() => {
            setUrl("");
            setShowPlayer(false);
          }}
          saveSkeepOption={saveSkeepOption}
          introStartTime={serie.intro_start_time}
          introEndTime={serie.intro_end_time}
          creditsTime={serie.credits_time}
          useToken={streamType?.value === "internal" ? true : false}
        />,
        document.body
      )}
    </>
  );
}

export default SeriePopup;
