import React from "react";

import tmdbPng from "../../../../img/tmdb.png";
import imdbPng from "../../../../img/imdb.png";
import rotenPng from "../../../../img/roten.png";
import metacriticPng from "../../../../img/metacritic.png";

export default function Rating({ rating, onChange }) {
    const onChangeFunction = (event) => {
        let newObject = { ...rating };

        newObject[event.target.name] = event.target.value;

        onChange(newObject);
    };

    return (
        <div className="rating-section">
            <div className="rating-block">
                <img alt="tmdb" src={tmdbPng} />
                <input
                    name="tmdb"
                    className="input"
                    value={rating.tmdb}
                    onChange={onChangeFunction}
                    placeholder="..."
                />
            </div>

            <div className="rating-block">
                <img alt="roten" src={rotenPng} />

                <input
                    name="rotten_tomatos"
                    className="input"
                    value={rating.rotten_tomatos}
                    onChange={onChangeFunction}
                    placeholder="..."
                />
            </div>

            <div className="rating-block">
                <img alt="metacritic" src={metacriticPng} />

                <input
                    name="metacritic"
                    value={rating.metacritic}
                    onChange={onChangeFunction}
                    className="input"
                    placeholder="..."
                />
            </div>

            <div className="rating-block">
                <img alt="imdb" src={imdbPng} />

                <input
                    name="imdb"
                    value={rating.imdb}
                    onChange={onChangeFunction}
                    className="input"
                    placeholder="..."
                />
            </div>
        </div>
    );
}
