import { Form, Input, Select } from "antd";

import ImageUpload from "../../../../components/ImageUpload";

import NextBackButton from "../../../../_components/button/NextBackButton";
import { useTranslation } from "react-i18next";

export default function InformationStep({
  editable,
  setStep,
  poster,
  setPoster,
  backdrop,
  setBackdrop,
  categoriesOption,
}) {
  const { t } = useTranslation();
  return (
    <div>
      <div style={{ display: "flex", gap: 10 }}>
        <Form.Item
          label={t("poster")}
          name="poster"
          rules={[
            {
              required: editable ? false : true,
              message: t("poster_message"),
            },
          ]}
        >
          <ImageUpload image={poster} setImage={setPoster} />
        </Form.Item>

        <Form.Item
          label={t("backdrop")}
          name="backdrop"
          rules={[
            {
              required: false,
              message: t("backdrop_message"),
            },
          ]}
        >
          <ImageUpload image={backdrop} setImage={setBackdrop} />
        </Form.Item>
      </div>

      <Form.Item
        label={t("name")}
        name="name"
        rules={[
          {
            required: true,
            message: t("name_message"),
          },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label={t("categories")}
        name="categories"
        rules={[
          {
            required: false,
            message: t("categories_message"),
          },
        ]}
      >
        <Select
          mode="multiple"
          options={categoriesOption}
          optionFilterProp="children"
          filterOption={(input, option) => {
            return (option?.label?.toLowerCase() ?? "").includes(
              input.toLocaleLowerCase()
            );
          }}
          filterSort={(optionA, optionB) => {
            return (optionA?.label ?? "")
              .toLowerCase()
              .localeCompare((optionB?.label ?? "").toLowerCase());
          }}
        />
      </Form.Item>

      <Form.Item label={t("comment")} name="comment">
        <Input.TextArea rows={4} />
      </Form.Item>

      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <NextBackButton type="next" onClick={() => setStep("2")} />
      </div>
    </div>
  );
}
