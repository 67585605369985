import request from "../../api/request";
import { appHost } from "../../helpers/appHost";

const host = appHost;

const URL = {};

const dailyInfoApi = {
  GET: (query, host) => request("GET", `${host}/info/server/daily`, query),
};

export default dailyInfoApi;
