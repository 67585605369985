import styles from "./styles.module.scss";
import bannerBg from "../image/player_banner.jpg";

const PlayerBannerPreview = ({ data }) => {
  return (
    <div
      className={styles["player-banner"]}
      style={{
        backgroundImage: `url(${bannerBg})`,
      }}
    >
      <div
        className={styles["player-banner__content"]}
        style={{
          backgroundImage: `url(${data.previewBg})`,
        }}
      >
        <div className={styles["banner__content_wrapper"]}>
          <div className={styles["player-banner__content__title"]}>
            <span>{data.title}</span>
          </div>

          <div className={styles["player-banner__content__description"]}>
            <span>{data.description}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlayerBannerPreview;
