import { useState } from "react";
import styles from "./_server.module.scss";
import StorageInfoDrawer from "./StorageInfoDrawer";

export default function Storage({ data, t }) {
  const [showPopup, setShowPopup] = useState(false);

  const handleShowPopup = () => {
    if (data.length > 1) {
      setShowPopup(true);
    }
  };

  const renderStorageItem = (item) => (
    <>
      <p>
        {item?.used && item?.used !== "NaN" ? parseFloat(item?.used).toFixed(0) : 0} gb
      </p>
      <div>/</div>
      <p> {item?.size ? parseFloat(item?.size).toFixed(0) : 0} gb </p>
      <p className={styles["percent"]}>
        (
        {item?.used_percentages && item?.used_percentages !== "NaN"
          ? parseFloat(item?.used_percentages).toFixed(0)
          : 0}{" "}
        %){" "}
      </p>
    </>
  );

  return (
    <div className={styles["storage"]}>
      <p className={styles["title"]}> {t("storage")}</p>
      {data && data.length > 0 ? (
        <>
          {renderStorageItem(data[0])}

          {data.length > 1 && (
            <span onClick={handleShowPopup} className={styles["show-more"]}>
              {/* show more... */}
              {t("show_more")}
            </span>
          )}

          <StorageInfoDrawer
            open={showPopup}
            info={data}
            onClose={() => {
              setShowPopup(false);
            }}
          />
        </>
      ) : (
        <span>N/A</span>
      )}
    </div>
  );
}
