import React from "react";
import ReactPaginate from "react-paginate";
export default function TableComponent({
  id,
  children,
  pagination,
  style,
  className = "",
}) {
  return (
    <>
      <div className="table-min-heigth">
        <table className={`table  ${className}`} style={style} id={id}>
          {children}
        </table>
      </div>
      {pagination && pagination.list && pagination.list.length > 0 && (
        <div className="pagination-pages">
          <ReactPaginate
            previousLabel="<"
            nextLabel=">"
            breakLabel={"..."}
            pageCount={pagination?.pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={(e) => pagination.change(e.selected + 1)}
            containerClassName={"pagination table-pagination"}
            pageClassName={"page-item"}
            // pageLinkClassName={"page-link"}
            previousClassName={"page-item"}
            // previousLinkClassName={"page-link"}
            nextClassName={"page-item"}
            // nextLinkClassName={"page-link"}
            breakClassName={"page-item"}
            // breakLinkClassName={"page-link"}
            activeClassName={"active-page"}
          />
        </div>
      )}
    </>
  );
}
