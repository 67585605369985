import { useEffect, useState } from "react";

import icons from "../../../../../../config/icons";

import { getColumnSearchProps } from "../../../../../../config/config";

import TableComponent from "../../../../../../_components/table/TableComponent";
import ButtonComponent from "../../../../../../_components/button/ButtonComponent";
import { Button, Popconfirm, Space, Image } from "antd";

import defaultImage from "../../../../../../img/movie.jpeg";
import { useTranslation } from "react-i18next";

export default function LiveTvTable({
  getDataSource,
  openSetupFilterDrawer,
  opnByIdDrawer,
  excludeLiveTv,
  actualLiveTvService,
}) {
  const { t } = useTranslation();
  const [dataSource, setDataSource] = useState([]);

  const [loading, setLoading] = useState(false);

  const [limit, setLimit] = useState(10);

  const [currentPage, setCurrentPage] = useState(1);

  const [sort, setSort] = useState(["id", "DESC"]);

  const [total, setTotal] = useState(0);

  const [search, setSearch] = useState({
    name: null,
  });

  const imageError = (e) => (e.target.src = defaultImage);

  const columns = [
    {
      title: "#",
      width: 60,
      dataIndex: `id`,
      align: "center",
      key: "index",
      render: (record, text, index) => {
        return limit * (currentPage - 1) + index + 1;
      },
    },

    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
      align: "start",
      render: (text, record, index) => {
        return (
          <Space>
            <Image
              src={record.image || defaultImage}
              onError={imageError}
              style={{
                width: 40,
                height: 40,
                borderRadius: "50%",
                objectFit: "cover",
              }}
            />

            <p>{record.name}</p>
          </Space>
        );
      },

      ...getColumnSearchProps(),
    },

    {
      title: "",
      key: "operation",
      fixed: "right",
      width: 60,
      align: "center",

      render: (text, record, index) => (
        <Popconfirm
          placement="topRight"
          title={t("delete_selected_live_tv_message")}
          description={"This live tv is encluded"}
          onConfirm={(e) => {
            excludeLiveTv(record);
          }}
          okText="Delete"
          cancelText="Cancel"
          okButtonProps={{
            danger: true,
          }}
        >
          <Button icon={icons.DELETE} danger></Button>
        </Popconfirm>
      ),
    },
  ];

  const handleTableChange = (data) => {
    setCurrentPage(data.page);
    setLimit(data.limit);
    setSort(data.sort);

    setSearch(data.search);
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(true);
      const query = {
        sort,
        limit,
        page: currentPage,
      };

      if (search.name) {
        query.search = {
          name: search.name[0],
        };
      }
      function onSuccess(data) {
        if (data.rows?.length === 0 && currentPage > 1) {
          setCurrentPage((prev) => prev - 1);
          return;
        }
        setLoading(false);
        setDataSource(data.rows);
        setTotal(data?.total);
        setLimit(data?.limit);
        setCurrentPage(data?.page);
      }
      function onError(data) {
        setLoading(false);
      }
      getDataSource(query, onSuccess, onError);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [limit, currentPage, sort, search, actualLiveTvService]);

  return (
    <TableComponent
      style={{ marginTop: 20 }}
      header={
        <>
          <Space>
            <ButtonComponent title={t("setup_filter")} onClick={openSetupFilterDrawer} />
            <ButtonComponent title={t("add_live_tv")} onClick={opnByIdDrawer} />
          </Space>
        </>
      }
      isLoading={loading}
      dataSource={dataSource}
      onChange={handleTableChange}
      columns={columns}
      localeClick={opnByIdDrawer}
      pagination={{
        page: currentPage,
        limit: limit,
        total: total,
      }}
    />
  );
}
