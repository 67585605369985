import { Tabs } from "antd";

import { useEffect, useState } from "react";

import ResolutionConfig from "./ResolutionConfig";

export default function OptionsBlock({
  optionsList,
  onChangeConfig,
  outputType,
  inputValue,
}) {
  const [items, setItems] = useState([]);

  const [activeKey, setActiveKey] = useState();

  useEffect(() => {
    let newItems = optionsList.map((option) => {
      return {
        key: option.id,
        label: option.name,
        width: +option?.config?.resolutions?.width,
        children: (
          <ResolutionConfig
            option={option}
            onChange={onChangeConfig}
            outputType={outputType}
            inputValue={inputValue}
          />
        ),
      };
    });

    newItems.sort((a, b) => {
      return a.width - b.width;
    });

    setItems(newItems);
  }, [optionsList]);

  return (
    <div>
      <Tabs
        onChange={setActiveKey}
        tabBarStyle={{ width: 180 }}
        tabPosition="left"
        activeKey={activeKey}
        items={items}
      />
    </div>
  );
}
