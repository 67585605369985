import dayjs from "dayjs";

import { useEffect, useState } from "react";

import { Button, DatePicker, Spin } from "antd";

import requests from "../../../../../server/requests";

import icons from "../../../../../config/icons";
import { parseDateFormat } from "../../../../../config/config";

import Epg from "./Epg";

import styles from "./_live-tv-video.module.scss";
import { useTranslation } from "react-i18next";

export default function EpgSection({ channel, open, playing, onChangePlaying }) {
  const { t } = useTranslation();

  const [epgs, setEpgs] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const [date, setDate] = useState(dayjs(parseDateFormat(new Date()), "DD-MM-YYYY"));

  const getEpgs = () => {
    if (channel) {
      setIsLoading(true);

      let day = new Date(date);

      day = parseInt(day.getTime() / 1000);

      let today = parseInt(new Date().getTime() / 1000);

      let epgDay = Math.ceil((day - today) / 86400);

      let id_epg = -1;

      if (channel.id_epg) {
        id_epg = channel.id_epg;
      }

      requests.epg.getByDate(
        id_epg,
        epgDay,
        (data) => {
          setIsLoading(false);

          setEpgs(data);
        },
        () => {
          setIsLoading(false);
        }
      );
      // REQUESTS.LIVE_TV.CHANNELS.EPG(query)
      //     .then((response) => {
      //         console.log(response);
      //     })
      //     .catch((error) => {
      //         console.log(error);
      //     });
    }
  };

  const onClickButton = () => {
    if (playing.type === "live") {
      onChangePlaying("timeshift");
    } else {
      onChangePlaying("live");
    }
  };

  useEffect(() => {
    if (open) {
      getEpgs();
    }
  }, [open, date]);

  return (
    <div className={styles["epg-section"]}>
      <div className={styles["header"]}>
        <DatePicker
          value={date}
          onChange={setDate}
          format="DD-MM-YYYY"
          disabled={!channel?.has_archive}
        />

        <Button
          icon={icons.PLAY}
          type="primary"
          disabled={!channel?.has_archive}
          onClick={onClickButton}
        >
          {playing?.type === "live" ? t("time_shift") : t("live")}
        </Button>
      </div>

      {isLoading ? (
        <div className={styles["loading"]}>
          <Spin />
        </div>
      ) : epgs.length > 0 ? (
        <div className={styles["list"]}>
          {epgs.map((item, index) => {
            return (
              <Epg
                key={index}
                disabled={!channel?.has_archive}
                item={item}
                onClick={(type, values) => onChangePlaying(type, values)}
                current={playing?.epg?.start_ut === item.start_ut}
              />
            );
          })}
        </div>
      ) : (
        <p>{t("no_epg")}</p>
      )}
    </div>
  );
}
