import { useState } from "react";

import { notification } from "antd";

import REQUESTS from "../../../api/requests";

import AvailableDevicesTable from "./AvailableDevicesTable";
import AvailableDevicesDrawer from "./AvailableDevicesDrawer";
import PageComponent from "../../../_components/page/PageComponent";
import { useTranslation } from "react-i18next";

export default function AvailableDevicesPage({}) {
  const { t } = useTranslation();
  const [current, setCurrent] = useState(null);

  const [getAgain, setGetAgain] = useState(false);

  const [isOpenDrawer, setIsOpenDrawer] = useState(false);

  const getDataSource = (query, onSuccess, onError) => {
    try {
      REQUESTS.WEB_PAGE.AVAILABLE_DEVICES.GET(query)
        .then((response) => {
          onSuccess(response.message);
        })
        .catch((error) => {
          onError(error);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const handleMenuClick = (e, item) => {
    switch (e.key) {
      case "edit":
        setCurrent(item);
        setIsOpenDrawer(true);
        break;

      case "delete":
        REQUESTS.WEB_PAGE.AVAILABLE_DEVICES.DELETE({ id: item.id })
          .then((response) => {
            if (response.error) {
              notification.error({
                message: t("error"),
                description: response.message,
              });

              return;
            }

            notification.success({
              message: t("success"),
              //   description: "Device deleted successfully",
              description: t("delete_message", { name: t("device") }),
            });

            setGetAgain((prev) => !prev);
          })
          .catch((err) => {
            notification.error({
              message: t("error"),
              description: err,
            });
          });
        break;

      default:
        break;
    }
  };

  const closeDrawer = () => {
    setCurrent(null);
    setIsOpenDrawer(false);
  };

  return (
    <PageComponent routes={[t("web_page"), t("available_devices")]}>
      <AvailableDevicesTable
        getAgain={getAgain}
        getDataSource={getDataSource}
        handleMenuClick={handleMenuClick}
        openDrawer={() => setIsOpenDrawer(true)}
      />

      <AvailableDevicesDrawer
        open={isOpenDrawer}
        onClose={closeDrawer}
        getData={() => setGetAgain((prev) => !prev)}
        current={current}
      />
    </PageComponent>
  );
}
