import request from "../../../../api/request";
import { appHost } from "../../../../helpers/appHost";
import { getToken } from "../../../../server/requests";

const host = appHost;

const URL = {
  MOVIE: `${host}movie`,

  MOVIES: `${host}v2/admin/movies`,

  GALLERY: `${host}movie/galery`,

  FETCH: `${host}v2/admin/movies/fetch`,
};

const mediasApi = {
  GET: (query) => request("GET", URL.MOVIES, query),

  DELETE: (query) => request("DELETE", URL.MOVIE, query),

  HIGHLIGHTED: (query) => request("POST", `${URL.MOVIE}/highlighted`, query),

  ENABLE_OR_DISABLE: (type, query) => request("PUT", `${URL.MOVIE}/${type}`, query),

  ENABLE_OR_DISABLE_ALL: (query) => request("PUT", `${URL.MOVIES}/enable_all`, query),

  BULK_PROTECT: (query) => request("PUT", `${URL.MOVIES}/protect`, query),

  BULK_UPDATE: (query) => request("PUT", `${URL.MOVIES}/bulk_update`, query),

  BY_ID: (id) => request("GET", `${URL.MOVIE}?id=${id}`),

  GALLERY: {
    ADD: (query) => request("POST", URL.GALLERY, query),

    DELETE: (query) => request("DELETE", URL.GALLERY, query),
  },

  FETCH: {
    STATE: (query) => request("GET", `${URL.FETCH}/state`, query),

    START: (query) => request("PUT", URL.FETCH, query),
  },
};

export default mediasApi;
