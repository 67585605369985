import { memo } from "react";
import { Modal } from "antd";
import Player from "./Player";
import { generateUrl } from "../config/config";

function VideoModal({ open, onClose, url = "", title = "Video" }) {
  return (
    <Modal
      zIndex={11}
      title={title}
      open={open}
      onCancel={onClose}
      footer={false}
      forceRender={true}
      width={1000}
    >
      {open && <Player url={generateUrl(url)} useToken={true} />}
    </Modal>
  );
}

export default memo(VideoModal);
