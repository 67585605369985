import request from "../../../api/request";
import { DEMO_API_HOST } from "../../../config/statics";
import { appHost } from "../../../helpers/appHost";

const host = appHost;

const URL = {
  CONTENT_PROVIDERS: `${host}admin/content_providers`,
};

const vodContentProvidersApi = {
  GET: (query) => request("GET", URL.CONTENT_PROVIDERS, query),

  ADD: (query) => request("POST", URL.CONTENT_PROVIDERS, query),

  EDIT: (query) => request("PUT", URL.CONTENT_PROVIDERS, query),

  DELETE: (query) => request("DELETE", URL.CONTENT_PROVIDERS, query),
};

export default vodContentProvidersApi;
