import { useState } from "react";

import { EllipsisOutlined } from "@ant-design/icons";

import { Dropdown, Menu, Button, Popconfirm } from "antd";

import style from "./style.module.scss";

// The TableButtons component is used to display a dropdown menu with buttons.
// From props we get the buttons that will be displayed in the dropdown menu and the function that will be
// called when a button is clicked. The function will receive the key of the button that was clicked.

export default function TableButtons({ handleMenuClick, items = [], disabled }) {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Dropdown
      disabled={disabled}
      open={isOpen}
      onOpenChange={(e) => setIsOpen(e)}
      trigger={["click"]}
      placement="bottomLeft"
      overlay={
        <Menu onClick={handleMenuClick} className={style["menu"]} style={{ padding: 0 }}>
          {items.map((item) => {
            if (item) {
              if (item.question) {
                return (
                  <Menu.Item key={item.key} className={style["item"]}>
                    <div
                      onClick={(e) => {
                        setIsOpen(false);
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                    >
                      <Popconfirm
                        placement="topRight"
                        title={item.question}
                        description={item.description}
                        onConfirm={(e) => {
                          item.onConfirm({ key: item.key }, item);
                        }}
                        okButtonProps={{
                          danger: item.key === "delete" ? true : false,
                        }}
                        okText={item.name}
                        cancelText="Cancel"
                      >
                        <div className={style["row"]}>
                          {item.icon}

                          <div style={{ width: "100%" }}>{item.name}</div>
                        </div>
                      </Popconfirm>
                    </div>
                  </Menu.Item>
                );
              } else {
                return (
                  <Menu.Item key={item.key} className={style["item"]}>
                    <div className={style["row"]} onClick={() => setIsOpen(false)}>
                      {item.icon}
                      {item.name}
                    </div>
                  </Menu.Item>
                );
              }
            }
          })}
        </Menu>
      }
    >
      <Button icon={<EllipsisOutlined />} />
    </Dropdown>
  );
}
