import { appHost } from "../../../helpers/appHost";
import request from "../../request";

// const host = `${appHost || "DEFAULT_REACT_API_HOST"}`;

const host = appHost;

const URL = {
  TARIFF: `${host}admin/tariff`,

  CONTENT: `${host}admin/tariff/content`,
};

export default {
  GET: (query) => request("GET", URL.TARIFF, query),

  CONTENT: (query) => request("GET", URL.CONTENT, query),

  ADD: (query) => request("POST", URL.TARIFF, query),

  EDIT: (query) => request("PUT", URL.TARIFF, query),

  DELETE: (query) => request("DELETE", URL.TARIFF, query),
};
