import { appHost } from "../../../helpers/appHost";
import request from "../../request";

// const host = `${appHost || "DEFAULT_REACT_API_HOST"}`;

const host = appHost;

const URL = {
  ARCHIVE: `${host}archive/`,
};

export default {
  SERVERS: {
    GET: (query) => request("POST", `${URL.ARCHIVE}/get`, query),
  },
};
