import { useState, useEffect } from "react";
import TableButtons from "../../../_components/table/TableButtons";
import { useTranslation } from "react-i18next";
import TableComponent from "../../../_components/table/TableComponent";
import icons from "../../../config/icons";
import ButtonComponent from "../../../_components/ButtonComponent";
import {
  getAtSession,
  setInSession,
  getAtLocal,
  setInLocal,
} from "../../../helpers/storages";
import { asc_desc } from "../../../helpers/ascDesc";
import { getColumnSearchProps } from "../../../config/config";
import Check from "../../../_components/table/Check";

const AdsPricingTable = ({
  getDataSources,
  getAgain,
  handleClick,
  handleAddPackage,
  bulkAction,
  loading,
}) => {
  const { t } = useTranslation();

  const archiveFilterOptions = [
    { text: t("archived"), value: true },
    { text: t("not_archived"), value: false },
  ];

  const [dataSources, setDataSources] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [limit, setLimit] = useState(getAtLocal("custom_ads_pricing_search") || 10);

  const [currentPage, setCurrentPage] = useState(
    getAtSession("custom_ads_pricing_search")?.page || 1
  );

  const [sort, setSort] = useState(
    getAtSession("custom_ads_pricing_filter")?.sort || ["id", "DESC"]
  );

  const [total, setTotal] = useState(0);

  const [search, setSearch] = useState(
    getAtSession("custom_ads_pricing_search")?.search || {
      name: null,
      archived: false,
    }
  );

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      align: "center",
      width: 50,
      fixed: "left",
      sorter: true,
      defaultSortOrder:
        getAtLocal("custom_ads_pricing_search")?.sort[1] &&
        asc_desc(getAtLocal("custom_ads_pricing_search")?.sort[1]),
    },
    {
      title: t("package_name"),
      dataIndex: "name",
      key: "name",
      align: "center",
      defaultFilteredValue: getAtSession("custom_ads_pricing_search")?.search?.name,
      filteredValue: getAtSession("custom_ads_pricing_search")?.search?.name,
      ...getColumnSearchProps(),
    },
    {
      title: t("regions"),
      dataIndex: "regions",
      key: "regions",
      align: "center",

      render: (_, record) =>
        record.regional_pricing_pricing_package
          .map((item) => item.country_code)
          .join(", "),
    },
    {
      title: t("country_code"),
      dataIndex: "country_code",
      key: "country_code",
      align: "center",
      render: (_, record) => {
        return record?.regional_pricing_pricing_package?.map((item) => item.country_code);
      },
    },
    {
      title: t("impression_price"),
      dataIndex: "impression_price",
      key: "impression_price",
      align: "center",
      render: (_, record) => {
        return record?.regional_pricing_pricing_package?.map(
          (item) => item.impression_price
        );
      },
    },
    {
      title: t("conversion_price"),
      dataIndex: "conversion_price",
      key: "conversion_price",
      align: "center",
      render: (_, record) => {
        return record?.regional_pricing_pricing_package?.map(
          (item) => item.conversion_price
        );
      },
    },
    {
      title: t("currency"),
      dataIndex: "currency",
      key: "currency",
      align: "center",
      render: (_, record) => {
        return record?.regional_pricing_pricing_package?.map((item) => item.currency);
      },
    },
    {
      title: t("archived"),
      dataIndex: "archived",
      key: "archived",
      align: "center",
      width: 100,
      ellipsis: true,
      sorter: true,
      defaultSortOrder:
        getAtSession("custom_ads_pricing_filter")?.search[1] === "archived" &&
        asc_desc(getAtSession("custom_ads_pricing_filter")?.sort[1]),
      filters: archiveFilterOptions,

      // Updated onFilter function to correctly filter by archived status
      onFilter: (value, record) => record.archived === value,
      render: (record) => <Check value={record} />,
    },

    {
      title: "",
      key: "actions",
      align: "center",
      fixed: "right",
      width: 80,
      render: (_, record) => {
        return (
          <TableButtons
            handleMenuClick={(key) => handleMenuClick(key, record)}
            items={[
              {
                key: "edit",
                name: t("edit"),
                icon: icons.EDIT,
                onConfirm: (e) => {
                  handleClick("edit", record);
                },
              },
              {
                key: "delete",
                name: t("delete"),
                icon: icons.DELETE,
                question: t("delete_package"),
                onConfirm: (e) => {
                  handleClick("delete", record);
                },
              },
            ]}
          />
        );
      },
    },
  ];

  const handleTableChange = (data) => {
    console.log(data);

    const filter = {
      page: data.page || 1,
      search: data.search || {},
      sort: data.sort && data.sort[1] ? data.sort : ["id", "DESC"],
    };

    setCurrentPage(filter.page);
    setLimit(data.limit);
    setSort(filter.sort);

    setInLocal("pageLimit", filter);

    setInSession("custom_ads_pricing_search", {
      ...getAtSession("custom_ads_pricing_search"),
      page: filter.page,
      limit: data.limit,
      sort: filter.sort,
      search: filter.search,
    });

    const searchData = {
      ...filter.search,
      archived: data?.search?.archived != null ? data.search.archived : false,
    };

    setSearch(searchData);
  };

  const handleMenuClick = ({ key }, record) => {
    handleClick(key, record);
  };

  useEffect(() => {
    const query = {
      page: currentPage,
      limit: limit,
      sort: sort,
      filter: {
        archived: search.archived,
      },
    };

    if (search.name) {
      query.search = {
        name: search.name,
      };
    }

    if (search.archived) {
      query.filter = {
        ...query.search,
        archived: search.archived,
      };
    }

    const success = (res) => {
      setDataSources(res.rows);
      setTotal(res.count);
      setCurrentPage(res.page);
      setLimit(res.limit);
    };

    const error = (err) => {
      console.log(err);
    };

    getDataSources(query, success, error);
  }, [search, sort, currentPage, total, getAgain, limit]);

  return (
    <div>
      <TableComponent
        header={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            {selectedRowKeys.length > 0 && (
              <ButtonComponent
                actionType="delete"
                title={t("delete")}
                type="default"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() => {
                  bulkAction("delete", selectedRowKeys, (response) => {
                    if (response == getAgain) {
                      setSelectedRowKeys([]);
                    }
                  });
                }}
              />
            )}
            <ButtonComponent
              style={{
                display: "flex",
                alignItems: "center",
              }}
              title={t("add_pricing")}
              actionType="add"
              onClick={handleAddPackage}
            />
          </div>
        }
        columns={columns}
        dataSource={dataSources}
        onChange={handleTableChange}
        rowSelection={{
          selectedRowKeys,
          onChange: (selectedRowKeys) => setSelectedRowKeys(selectedRowKeys),
        }}
        pagination={{ current: currentPage, total, limit }}
        scroll={{ x: 1300 }}
        isLoading={loading}
      />
    </div>
  );
};

export default AdsPricingTable;
