import { useEffect, useState } from "react";

import { Drawer, Form, Input, notification, Select } from "antd";

import REQUESTS from "../../../../../../api/requests";

import { useAgeRestrictionOptions } from "../../../../../../hooks/selectOptions";

import ButtonComponent from "../../../../../../_components/button/ButtonComponent";
import { AGE_RESTRICTION } from "../../../../../../config/statics";
import { useTranslation } from "react-i18next";

export default function SubuserDrawer({ onClose, open, editable, getData }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const ageRestrictionOptions = useAgeRestrictionOptions();

  const [form] = Form.useForm();

  const onFinish = (values) => {
    if (editable) {
      setIsLoading(true);

      const body = {
        id: editable.id,
        name: values.name,
        password: values.password,
        age_restricted: values.age_restricted,
      };

      REQUESTS.USERS.USER.SUBUSERS.EDIT(body)
        .then((response) => {
          setIsLoading(false);

          if (response.error) {
            notification.error({
              message: t("error"),
              description: response.message,
            });

            return;
          }

          getData();
          onClose();
        })
        .catch((error) => {
          notification.error({
            message: t("error"),

            description: error,
          });

          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (!open) {
      form.resetFields();
    }

    if (editable) {
      form.setFields([
        { name: "name", value: editable.name },
        { name: "age_restricted", value: editable.age_restricted },
      ]);
    }
  }, [open, editable]);

  return (
    <Drawer
      title={`${editable ? t("edit") : t("add")} ${t("sub_user")}`}
      placement="right"
      onClose={onClose}
      open={open}
    >
      <Form
        form={form}
        name="subuser"
        layout="vertical"
        onFinish={onFinish}
        // onKeyPress={(e) => {
        //     if (e.key === "Enter") {
        //         form.submit();
        //     }
        // }}
        initialValues={{
          age_restricted: AGE_RESTRICTION,
        }}
      >
        <Form.Item
          label={t("name")}
          name="name"
          rules={[
            {
              required: true,
              message: t("name_message"),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label={t("password")} name="password">
          <Input.Password />
        </Form.Item>

        <Form.Item
          label={t("age_restriction")}
          name="age_restricted"
          rules={[
            {
              required: true,
              message: t("age_restriction_message"),
            },
          ]}
        >
          <Select options={ageRestrictionOptions} />
        </Form.Item>

        <Form.Item style={{ textAlign: "center" }}>
          <ButtonComponent
            title={t("save")}
            actionType="save"
            isLoading={isLoading}
            onClick={() => form.submit()}
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
}
