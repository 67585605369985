import { useEffect } from "react";
import styles from "./terminal.module.scss";

const Terminal = ({ content }) => {
  useEffect(() => {
    const terminal = document.getElementById("transcoder-terminal");
    terminal.scrollTop = terminal.scrollHeight;
    terminal.style.scrollBehavior = "smooth";
  }, [content]);

  return (
    <div id="transcoder-terminal" className={styles["transcoder-terminal-wrapper"]}>
      <pre className={styles["terminal-content"]}>{content}</pre>
    </div>
  );
};

export default Terminal;
