import { useEffect, useState } from "react";
import styles from "../../../styles/_charts.module.scss";
import { useTranslation } from "react-i18next";
import ChartComponent from "../../../../../_components/chart/ChartComponent";
import REQUESTS from "../../../../../api/requests";
import dayjs from "dayjs";
import { Chart } from "react-google-charts";

const EarningByRegionChart = () => {
  const { t } = useTranslation();

  const [mapData, setMapData] = useState([]);
  const [error, setError] = useState(null);

  //     data: [],
  //     angleField: "value",
  //     colorField: "type",
  //     radius: 1,
  //     innerRadius: 0.6,
  //     label: {
  //       type: "inner",
  //       offset: "-50%",
  //       content: "{value}",
  //       style: {
  //         textAlign: "center",
  //         fontSize: 14,
  //       },
  //     },
  //     interactions: [
  //       {
  //         type: "element-selected",
  //       },
  //       {
  //         type: "element-active",
  //       },
  //     ],
  //     statistic: {
  //       title: false,
  //       content: {
  //         style: {
  //           whiteSpace: "pre-wrap",
  //           overflow: "hidden",
  //           textOverflow: "ellipsis",
  //         },
  //       },
  //     },
  //   });

  useEffect(() => {
    const fetchEarningByAdsRegions = async () => {
      try {
        const response = await REQUESTS.DASHBOARD.ADS.EARNING_BY_REGION();

        if (!response.error) {
          let country_array = [["region", "earning"]];

          for (let i = 0; i < response?.length; i++) {
            country_array.push([
              response?.message[i].region,
              response?.message[i].earning,
            ]);
          }
          setMapData(country_array);
        } else {
          setError(response.message);
        }
      } catch (err) {
        console.error("Error fetching impressions:", err);
      }
    };

    fetchEarningByAdsRegions();
  }, []);

  return (
    <div className={styles["chart-wrapper"]}>
      {error ? (
        <p>{error}</p>
      ) : (
        <ChartComponent
          title={t("earning_by_ad_regions")}
          children={
            <Chart
              chartType="GeoChart"
              data={mapData}
              width="100%"
              height="400px"
              legendToggle
              options={{
                backgroundColor: "transparent",
                title: "Countries",
                colorAxis: { colors: ["black", "#696565", "#00853f"] },
              }}
            />
          }
        />
      )}
    </div>
  );
};

export default EarningByRegionChart;
