import Hls from "hls.js";

import { useEffect, useRef } from "react";

import ReactHlsPlayer from "react-hls-player";
import { generateUrl } from "../config/config";

export default function Player({ url, useToken }) {
  const hls = useRef(null);
  const playerRef = useRef(null);
  let test_video = document.getElementById("test-video");

  useEffect(() => {
    hls.current = new Hls();
    hls.current.loadSource(generateUrl(url, useToken));
    hls.current.attachMedia(playerRef.current);
    playerRef.current.autoplay = true;

    return () => {
      hls.current.destroy();
    };
  }, []);

  if (test_video && test_video.canPlayType("application/vnd.apple.mpegurl")) {
    return (
      <video
        src={generateUrl(url, useToken)}
        autoPlay={true}
        controls
        width="100%"
        height="auto"
      ></video>
    );
  } else {
    return (
      <ReactHlsPlayer
        url={generateUrl(url, useToken)}
        autoplay={true}
        controls={true}
        width="100%"
        playerRef={playerRef}
      />
    );
  }
}
