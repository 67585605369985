import adsInstance from "../api/instace";

const host = "https://ads.inorain.tv/";

const URL = {
  GETTER: `${host}admin/campaigns/getter`,

  CAMPAIGNS: `${host}admin/campaigns`,

  COUNTRIES: `${host}admin/countries`,
};

const campaignApi = {
  GETTER: (query) => adsInstance.get(URL.GETTER, { params: query }),
  GET: () => adsInstance.get(URL.CAMPAIGNS),
  GET_COUNTRIES: () => adsInstance.get(URL.COUNTRIES),
  ADD: (query) => adsInstance.post(URL.CAMPAIGNS, query),
  EDIT: (query, id) => adsInstance.put(`${URL.CAMPAIGNS}/${id}`, query),
  DELETE: (query) =>
    adsInstance.delete(`${URL.CAMPAIGNS}/many`, { data: { ids: query } }),
};

export default campaignApi;
