import { useState, useEffect } from "react";
import { Switch, notification } from "antd";

import REQUESTS from "../../api/requests";
import requests from "../../server/requests";
import { useTranslation } from "react-i18next";

export default function FetchRemoteEpg({}) {
  const { t } = useTranslation();

  const [widget, setWidget] = useState(null);

  const getWidgets = () => {
    REQUESTS.WIDGETS.GET().then((response) => {
      if (response.error) {
        return;
      }

      setWidget(response.message?.fetch_remote_epg);
    });
  };

  const onChange = (checked) => {
    const body = {
      key: widget?.key,
    };
    if (checked) {
      REQUESTS.WIDGETS.ENABLE(body).then((response) => {
        if (response.error) {
          notification.error({
            message: t("error"),
            description: response.message,
          });
          return;
        }

        getWidgets();
      });
    } else {
      REQUESTS.WIDGETS.DISABLE(body).then((response) => {
        if (response.error) {
          notification.error({
            message: t("error"),

            description: response.message,
          });
          return;
        }

        getWidgets();
      });
    }
  };

  useEffect(() => {
    getWidgets();
  }, []);

  return widget?.show ? (
    <>
      <span>{t("fetch_remote_epg")}: </span>
      <Switch checked={widget?.is_enabled} onChange={onChange} />
    </>
  ) : (
    ""
  );
}
