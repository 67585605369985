export default function onStep(step, type, mediaType, setStep) {
  switch (step) {
    case "details":
      if (type === "next") {
        setStep("gallery");
      }
      break;

    case "gallery":
      if (type === "next") {
        setStep("meta-data");
      } else {
        setStep("details");
      }

      break;

    case "meta-data":
      if (type === "next") {
        setStep(mediaType === "movie" ? "stream" : "restriction_monetization");
      } else {
        setStep("gallery");
      }
      break;

    case "stream":
      if (type === "next") {
        setStep("restriction_monetization");
      } else {
        setStep("meta-data");
      }
      break;

    case "restriction_monetization":
      if (type === "next") {
        setStep("series_episodes");
      } else {
        setStep(mediaType === "movie" ? "stream" : "meta-data");
      }
      break;

    case "series_episodes":
      if (type === "back") {
        setStep("restriction_monetization");
      }

      break;

    case "preview":
      if (type === "back") {
        setStep("restriction_monetization");
      }

      break;

    default:
      break;
  }
}
