import React from "react";
import InputComponent from "../../../../components/InputComponent";

export default function UrlWithPlayer({ name, label, value, onChange, placeholder, openPlayer }) {
    return (
        <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ width: "95%", marginRight: 10 }}>
                <InputComponent
                    name={name}
                    label={label}
                    onChange={(e) => onChange(e.target.value)}
                    value={value}
                    placeholder={placeholder}
                />
            </div>

            <div
                className="media-play-button"
                style={{
                    marginTop: 12,
                    fontSize: 23,
                    color: value ? "#14746f" : "#8f8f8f",
                    cursor: value ? "pointer" : "no-drop",
                }}
                onClick={() => {
                    openPlayer();
                }}
            >
                <i className="fas fa-play-circle" />
            </div>
        </div>
    );
}
