import { Divider } from "antd";
import Movie from "./Movie";

import MoviesList from "./MoviesList";
// import NowWatched from "./NowWatched";

import styles from "./most_watched.module.scss";
import { useTranslation } from "react-i18next";
// import REQUESTS from "../../../../../api/requests";
// import { useEffect, useState } from "react";

export default function WatchedBlock() {
  const { t } = useTranslation();

  // const [watchList, setWatchList] = useState([]);

  // const getWatchList = (cb, error) => {
  //   REQUESTS.DASHBOARD.VOD.NOW_WATCHING()
  //     .then((res) => {
  //       console.log(res);
  //       setWatchList(res?.message);
  //     })
  //     .catch((err) => {
  //       error(err);
  //       console.log(err);
  //     });
  // };

  // useEffect(() => {
  //   getWatchList();
  // }, []);

  return (
    <div>
      <div className={styles["watched-block"]}>
        <div className={styles["movies-block"]}>
          <div className={styles["title"]}>{t("most_watched_movies")}</div>

          <div className={styles["container"]}>
            <MoviesList />
            <Movie />
          </div>
        </div>

        {/* {watchList.length > 0 && (
          <div className={styles["now-watching-block"]}>
            <div className={styles["title"]}>Now Watching</div>
            <NowWatched dataSource={watchList} />
          </div>
        )} */}
      </div>
    </div>
  );
}
