import React from "react";
import Select from "react-select";

export default function SelectComponent({
  label,
  value,
  change,
  options,
  onInputChange,
  isMulti,
  isClearable,
  style,
}) {
  return (
    <div className="form-group" style={{ textAlign: "start", ...style }}>
      <label className="f-w-500 m-b-5">{label}:</label>
      <Select
        isClearable={isClearable}
        isMulti={isMulti}
        value={value}
        onChange={change}
        options={options}
        onInputChange={onInputChange && onInputChange}
        style={{ width: "100%" }}
        menuPlacement="auto"
        // captureMenuScroll={true}
      />
    </div>
  );
}
