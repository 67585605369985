import { useEffect, useState } from "react";

import { Form, Input, Switch, notification } from "antd";

import REQUESTS from "../../../../api/requests";

import ImageUpload from "../../../../components/ImageUpload";

import ButtonComponent from "../../../../_components/button/ButtonComponent";
import { useTranslation } from "react-i18next";

export default function AuthorizeConfig({ data, selectedCurrency }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);

  const [icon, setIcon] = useState({
    url: null,
    file: null,
  });

  const onFinish = (values) => {
    setIsLoading(true);

    const formData = new FormData();

    if (icon.file) {
      formData.append("authorize_icon", icon.file);
    }

    formData.append("id", data.id);
    formData.append("currency_id", selectedCurrency);
    formData.append("authorize_enabled", values.authorize_enabled);
    formData.append("authorize_api_login_key", values.authorize_api_login_key);
    formData.append("authorize_transaction_key", values.authorize_transaction_key);

    REQUESTS.PAYMENTS.CONFIGS.UPDATE(formData)
      .then((response) => {
        setIsLoading(false);

        if (response.error) {
          notification.error({
            message: t("Error"),
            description: response.message,
          });

          return;
        }

        notification.success({
          message: t("success"),
          description: t("authorize_config_change_message"),
        });
      })
      .catch((error) => {
        setIsLoading(false);
        notification.error({
          message: t("error"),
          description: error,
        });
      });
  };

  const onChangeIcon = (value) => {
    setIcon(value);

    form.setFieldsValue({
      authorize_icon: value.file,
    });
  };

  useEffect(() => {
    if (data) {
      setIcon({
        url: data.authorize_icon,
        file: null,
      });

      form.setFields([
        { name: "authorize_icon", value: data.authorize_icon },
        { name: "authorize_enabled", value: data.authorize_enabled },
        { name: "authorize_api_login_key", value: data.authorize_api_login_key },
        { name: "authorize_transaction_key", value: data.authorize_transaction_key },
      ]);
    }
  }, [data]);

  return (
    <Form
      form={form}
      name="authorize-config"
      layout="vertical"
      onFinish={onFinish}
      style={{ maxWidth: 250 }}
    >
      <Form.Item shouldUpdate noStyle>
        {() => {
          const { authorize_enabled } = form.getFieldsValue();

          return (
            <Form.Item
              label={t("icon")}
              name="authorize_icon"
              rules={[
                {
                  required: authorize_enabled,
                  message: t("select_icon_message"),
                },
              ]}
            >
              <ImageUpload image={icon} setImage={onChangeIcon} />
            </Form.Item>
          );
        }}
      </Form.Item>

      <Form.Item label={t("enabled")} name="authorize_enabled" valuePropName="checked">
        <Switch />
      </Form.Item>

      <Form.Item shouldUpdate noStyle>
        {() => {
          const { authorize_enabled } = form.getFieldsValue();

          return (
            <Form.Item
              label={t("authorize_api_login_key")}
              name="authorize_api_login_key"
              rules={[
                {
                  required: authorize_enabled,
                  message: t("login_key_message"),
                },
              ]}
            >
              <Input />
            </Form.Item>
          );
        }}
      </Form.Item>

      <Form.Item shouldUpdate noStyle>
        {() => {
          const { authorize_enabled } = form.getFieldsValue();

          return (
            <Form.Item
              label={t("authorize_transaction_key")}
              name="authorize_transaction_key"
              rules={[
                {
                  required: authorize_enabled,
                  message: t("transaction_key_message"),
                },
              ]}
            >
              <Input />
            </Form.Item>
          );
        }}
      </Form.Item>

      <ButtonComponent
        title={t("save")}
        actionType="save"
        isLoading={isLoading}
        onClick={() => form.submit()}
      />
    </Form>
  );
}
