import React, { useEffect, useState } from "react";
import styles from "./style.module.scss";
import { flags } from "./flags";
import { useTranslation } from "react-i18next";

const LanguageDropDown = () => {
  const { i18n } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);
  const [selectedLanguage, setSelectedLanguage] = useState(i18n?.language);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const selectLanguage = (language) => {
    setSelectedLanguage(language);
    setIsOpen(false);
    i18n.changeLanguage(language);
  };

  useEffect(() => {
    setSelectedLanguage(i18n.language);
  }, [i18n.language]);

  const languageList = [
    {
      key: "en",
      label: "EN",
      flag: flags["en"],
    },
    {
      key: "fr",
      label: "FR",
      flag: flags["fr"],
    },
    {
      key: "ptBR",
      label: "BR",
      flag: flags["ptBR"],
    },
  ];

  return (
    <div className={styles["language"]}>
      <div className={styles["drop-down"]} onClick={toggleDropdown}>
        <div className={styles["selected"]}>
          <span>{flags[selectedLanguage]} </span>
          {selectedLanguage.toLocaleUpperCase()}
        </div>
        {isOpen && (
          <div className={styles["options"]}>
            {languageList?.map((item) => {
              return (
                <div key={item.key} onClick={() => selectLanguage(item?.key)}>
                  {item?.flag}
                  {item?.label}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};

export default LanguageDropDown;
