import styles from "./styles.module.scss";

const SplashScreenPreview = ({ data }) => {
  return (
    <div
      className={styles["splash-screen-preview"]}
      style={{
        backgroundImage: `url(${data.previewBg})`,
      }}
    >
      <div className={styles["splash-screen-preview_content"]}>
        <div
          className={styles["splash-screen-preview_logo"]}
          style={{
            backgroundImage: `url(${data.previewLogo})`,
          }}
        />
        <div className={styles["splash-screen-preview_content-wrapper"]}>
          <div className={styles["splash-screen-preview_title"]}>{data.title}</div>
          <div className={styles["splash-screen-preview_description"]}>
            {data.description}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SplashScreenPreview;
