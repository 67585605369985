import request from "../../../api/request";
import { DEMO_API_HOST } from "../../../config/statics";
import { appHost } from "../../../helpers/appHost";

const host = appHost;

const URL = {
  LAUNCHER: `${host}launcher/admin`,
};

const launcherConfigsApi = {
  GET: (query) => request("GET", URL.LAUNCHER, query),

  EDIT: (query) => request("PUT", URL.LAUNCHER, query),
};

export default launcherConfigsApi;
