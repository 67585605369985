import { Tag } from "antd";
import styles from "./totals.module.scss";

export default function Total({ title, count, icon, children }) {
    return (
        <div className={styles["total"]}>
            <div className={styles["info-block"]}>
                <div>
                    <span className={styles["count"]}>{count}</span>

                    <p className={styles["title"]}>{title}</p>
                </div>
            </div>

            <div className={styles["icon-block"]}>
                {icon}
                {children}
            </div>
        </div>
    );
}
