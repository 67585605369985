import React from "react";
import { Link } from "react-router-dom";
import ButtonComponent from "./ButtonComponent";
import XLSbuttonComponent from "./XLSbuttonComponent";

export default function PageComponent({
    header,
    xls,
    rightButton,
    nearToButton,
    children,
    fluid,
    back,
}) {
    return (
        <div className={fluid ? "container" : "container-fluid"} style={{ paddingBottom: 20 }}>
            <div className="flex-b flex-sb m-b-20 ">
                <div className="header-row">
                    {header && <h2 style={{ fontSize: 20, marginBottom: 0 }}> {header}</h2>}

                    {back && (
                        <Link to={back.path} className="pointer fs-25">
                            <i className="fas fa-arrow-left mr-1" />
                            {back.title}
                        </Link>
                    )}

                    {xls && <XLSbuttonComponent id={xls.id} table={xls.name} />}
                </div>

                <div>
                    {nearToButton && nearToButton}

                    {rightButton && (
                        <ButtonComponent
                            type={rightButton.type}
                            title={rightButton.title}
                            action={rightButton.action}
                        />
                    )}
                </div>
            </div>

            {children}
        </div>
    );
}
