import { Modal } from "antd";
import { useTranslation } from "react-i18next";

export default function ConfirmIsProtected({ form, categoriesOption, checked, t }) {
  const { categories } = form.getFieldsValue();

  if (categories) {
    const selectedCategories = categoriesOption.filter((obj) =>
      categories.includes(obj.id)
    );

    const isProtectedCategories = selectedCategories.filter((item) => item.is_protected);

    if (isProtectedCategories.length) {
      form.setFields([{ name: "is_protected", value: true }]);

      if (checked === false) {
        Modal.confirm({
          title: t("are_you_sure"),
          content: t("confirm_protect_message"),
          okText: t("yes"),
          onOk() {
            form.setFields([{ name: "is_protected", value: false }]);
          },
          onCancel() {
            form.setFields([{ name: "is_protected", value: true }]);
          },
        });
      }
    } else {
      form.setFields([{ name: "is_protected", value: checked }]);
    }
  }
}
