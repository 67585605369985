import { Button, Space, Typography } from "antd";

import icons from "../../../../config/icons";

import styles from "../styles/styles.module.scss";

export default function ChannelResolutions({ resolutions, play }) {
  return (
    <div className={styles["resolutions"]}>
      {resolutions.map((item) => {
        return (
          <Space>
            <Typography.Paragraph
              className={styles["item"]}
              copyable={{
                text: item.info?.output,
              }}
            >
              {item.name}
            </Typography.Paragraph>

            <Button
              type="primary"
              size="small"
              disabled={!item.info?.output}
              onClick={() => play(item)}
            >
              {icons.PLAY}
            </Button>
          </Space>
        );
      })}
    </div>
  );
}
