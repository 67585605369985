import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { TableLoading } from "./Loadings";
import { inoToast } from "./toast/Toast";
import { parseHours } from "../helpers/formats";
import requests from "../server/requests";

export default function PlayerEpgList({
  channel,
  onChange = () => {},
  onChangeEpg = () => {},
}) {
  const [loading, setLoading] = useState(false);
  const [epgList, setEpgList] = useState([]);
  const [serverList, setServerList] = useState([]);
  const [date, setDate] = useState(new Date());
  const [selected, setSelected] = useState(null);
  const [isLive, setIsLive] = useState(true);

  const onChangeUrl = (item, type) => {
    if (!channel.has_archive) return;

    if (channel.archived_channel) {
      for (let i = 0; i < serverList.length; i++) {
        if (serverList[i].id === channel.archived_channel.archiverId) {
          let { ip, port } = serverList[i];

          let currentUrl = "";
          if (type === "archive") {
            let { start_ut, stop_ut } = item;

            let duration = stop_ut - start_ut;

            if (ip.startsWith("http")) {
              currentUrl = `${ip}/archive/${channel.id}/index.m3u8?start=${start_ut}&duration=${duration}`;
            } else {
              currentUrl = `http://${ip}${port && ":" + port}/archive/${
                channel.id
              }/index.m3u8?start=${start_ut}&duration=${duration}`;
            }
          } else {
            if (ip.startsWith("http")) {
              currentUrl = `${ip}/timeshift/${channel.id}/index.m3u8`;
            } else {
              currentUrl = `http://${ip}${port && ":" + port}/timeshift/${
                channel.id
              }/index.m3u8`;
            }
          }

          onChange(currentUrl);

          break;
        }
      }
    } else {
      inoToast.warning("This channel haven't archived");
    }
  };

  const getEpgList = () => {
    setLoading(true);

    let today = parseInt(new Date().getTime() / 1000);
    let day = parseInt(date.getTime() / 1000);

    let epgDay = Math.ceil((day - today) / 86400);

    requests.epg.getByDate(channel.id_epg, epgDay, (data) => {
      for (let i = 0; i < data.length; i++) {
        let start = new Date(data[i].start_ut * 1000);
        let end = new Date(data[i].stop_ut * 1000);

        let currentEpg = start < new Date() && new Date() < end;

        if (currentEpg) {
          onChangeEpg(data[i], data[i + 1]);
          break;
        }
      }

      setLoading(false);

      setEpgList(data);
    });
  };

  useEffect(() => {
    getEpgList();
  }, [date]);

  useEffect(() => {
    requests.archive.server.get((data) => {
      setServerList(data.rows);
    });
  }, []);

  const onClickPlayType = () => {
    if (isLive) {
      setIsLive(false);
      onChangeUrl(null, "timeshift");
    } else {
      setIsLive(true);
      onChange(channel.url + "?token=" + localStorage.getItem("admtoken"));
    }
    setSelected(null);
  };

  return (
    <>
      <div className="play-buttons">
        <div style={{ width: 150 }}>
          <DatePicker
            selected={date}
            onChange={setDate}
            showYearDropdown
            showMonthDropdown
          />
        </div>

        {channel && channel.has_archive && (
          <div className="buttons-item" onClick={onClickPlayType}>
            {isLive ? "Timeshift" : "Live"}
            <i className="fa fa-play-circle action-icon" style={{ marginLeft: 5 }} />
          </div>
        )}
      </div>

      <div style={{ width: "100%", overflow: "auto", maxHeight: "90%" }}>
        {loading ? (
          <TableLoading />
        ) : epgList.length > 0 ? (
          epgList.map((item, key) => {
            let start = new Date(item.start_ut * 1000);
            let end = new Date(item.stop_ut * 1000);
            let prevEpg = start < new Date();
            let currentEpg = start < new Date() && new Date() < end;

            let className = "epg-style";

            if (selected == key || (!selected && currentEpg)) {
              className += " selected";
            }

            return (
              <div key={key} className={className}>
                <div className="epg-date">
                  {parseHours(start)} - {parseHours(end)}
                </div>
                <div className="epg-title">{item.title}</div>
                <div
                  className={
                    !channel.has_archive
                      ? "disable-button"
                      : prevEpg
                      ? "epg-button"
                      : "disable-button"
                  }
                  onClick={() => {
                    if (currentEpg) {
                      // onChange(
                      //     channel.url +
                      //         "?token=" +
                      //         localStorage.getItem("admtoken")
                      // );
                      // setSelected(null);
                    } else if (prevEpg) {
                      setIsLive(false);
                      onChangeUrl(item, "archive");
                      setSelected(key);
                    }
                  }}
                  style={{ color: currentEpg && "green" }}
                >
                  <i className="fa fa-play-circle action-icon" />
                </div>
              </div>
            );
          })
        ) : (
          <p className="text-center">No epg</p>
        )}
      </div>
    </>
  );
}
