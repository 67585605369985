import React, { useEffect, useState } from "react";
import { Tabs, Tab } from "react-bootstrap";

import Hls from "hls.js";
import MultiRangeSlider from "../../../../components/MultiRangeSlider";
import ButtonComponent from "../../../../components/ButtonComponent";
import InputComponent from "../../../../components/InputComponent";
import { inoToast } from "../../../../components/toast/Toast";
import { Modal, Divider, Radio, Space, Tooltip, Card, Badge } from "antd";

import style from "../styles/player.module.scss";
import { generateUrl } from "../../../../config/config";
import { useTranslation } from "react-i18next";

export default function Player({
  isVisible,
  hideModal,
  src = "",
  type = "hls",
  selectedTvShow,

  showVideoAudioSubtitleTracks,
  saveSkeepOption,

  introStartTime,
  introEndTime,
  creditsTime,
  useToken,
}) {
  const { t } = useTranslation();

  const [hls, setHls] = useState(null);

  const [audioTracks, setAudioTracks] = useState([]);
  const [audioTrack, setAudioTrack] = useState({});

  const [subtitleTracks, setSubtitleTracks] = useState([]);
  const [subtitleTrack, setSubtitleTrack] = useState({});

  const [videoTracks, setVideoTracks] = useState([]);
  const [videoTrack, setVideoTrack] = useState({});
  const [activeTab, setActiveTab] = useState(0);
  const [selectedEpisodeId, setSelectedEpisodeId] = useState();

  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(0);
  const [credits, setCredits] = useState(0);
  const [duration, setDuration] = useState(0);

  const [showTimePositions, setShowTimePositions] = useState(false);
  const [currentTimes, setCurrentTimes] = useState(0);
  useEffect(() => {
    setStart(introStartTime || 0);
  }, [introStartTime, isVisible]);

  useEffect(() => {
    setEnd(introEndTime || 0);
  }, [introEndTime, isVisible]);

  useEffect(() => {
    setCredits(creditsTime || 0);
  }, [creditsTime, isVisible]);

  const video = (
    <video
      id="hls_video"
      autoPlay={true}
      className="player-video"
      controls={true}
      width="100%"
      height="auto"
    />
  );

  const selectVideo = (event) => {
    hls.currentLevel = event.target.value;
  };

  const selectAudio = (event) => {
    hls.audioTrack = event.target.value;
  };

  const selectSubtitle = (event) => {
    hls.subtitleTrack = event.target.value;
  };

  const setCurrentEpisode = (episode) => {
    if (type == "hls") {
      setPlayer(episode.stream_url || "");
      setSelectedEpisodeId(episode.id);
      //   setPlayer(
      //     "https://multiplatform-f.akamaihd.net/i/multi/will/bunny/big_buck_bunny_,640x360_400,640x360_700,640x360_1000,950x540_1500,.f4v.csmil/master.m3u8"
      //   );
    }
  };

  const onChange = (type, value) => {
    if (value) {
      value = Number(value);
    }

    switch (type) {
      case "start":
        if (value > end) {
          inoToast.error("Intro start time can't be big from Intro end time");

          return;
        }

        setStart(value);

        break;

      case "end":
        if (value > credits) {
          inoToast.error("Intro end time can't be big from Credits time");

          return;
        }

        if (value < start) {
          inoToast.error("Intro end time can't be less from Intro start time");

          return;
        }

        setEnd(value);

        break;

      case "credits":
        if (value < end) {
          inoToast.error("Credits time can't be less from Intro end time");

          return;
        }

        setCredits(value);

        break;

      default:
        break;
    }
  };

  const onPluse = (key) => {
    const time = currentTimes;
    let value = Math.floor(time);

    switch (key) {
      case "start":
        onChange("start", value);
        break;

      case "end":
        onChange("end", value);
        break;

      case "credits":
        onChange("credits", value);
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    const vid = document.getElementById("hls_video");

    let paused = false;

    function getTimeupdate(e) {
      paused = false;
      setCurrentTimes(Math.floor(Number(e.target.currentTime)));
      setDuration(Math.floor(Number(e.target.duration) / 60));
    }

    function getSeeked(e) {
      setCurrentTimes(Math.floor(Number(e.target.currentTime)));
    }

    function getPause(e) {
      paused = true;
      // setCurrentTimes(Math.floor(Number(e.target.currentTime)));
    }

    function mouseover(e) {
      setShowTimePositions(true);
    }

    function mouseleave(e) {
      if (!paused) {
        setShowTimePositions(false);
      } else {
        setShowTimePositions(true);
      }
    }
    setTimeout(() => {
      if (src) {
        if (vid) {
          vid.addEventListener("timeupdate", getTimeupdate);
          vid.addEventListener("seeked", getSeeked);
          vid.addEventListener("pause", getPause);
          vid.addEventListener("mouseover", mouseover);
          vid.addEventListener("mouseleave", mouseleave);
        }
      } else {
        if (vid) {
          setTimeout(function () {
            vid.removeEventListener("timeupdate", getTimeupdate);
            vid.removeEventListener("seeked", getSeeked);
            vid.removeEventListener("pause", getPause);
            vid.removeEventListener("mouseover", mouseover);
            vid.removeEventListener("mouseleave", mouseleave);
          }, 3000);
        }
      }
    }, 1000);
  }, [src, isVisible]);

  useEffect(() => {
    if (hls) hls.destroy();

    if (isVisible) {
      setPlayer(src);
      setSelectedEpisodeId(src);
    } else {
      if (document.getElementById("hls_video"))
        document.getElementById("hls_video").src = "";

      setHls(null);

      setAudioTracks([]);
      setAudioTrack({});

      setSubtitleTracks([]);
      setSubtitleTrack({});

      setVideoTracks([]);
      setVideoTrack({});
    }
  }, [isVisible]);

  useEffect(() => {
    if (selectedTvShow) {
      if (selectedTvShow.seasons && selectedTvShow.seasons.length > 0) {
        if (
          selectedTvShow.seasons[0].episodes &&
          selectedTvShow.seasons[0].episodes.length > 0
        )
          setCurrentEpisode(selectedTvShow.seasons[0].episodes[0]);
      }
    }
  }, [selectedTvShow]);

  const setPlayer = (_url) => {
    const url = generateUrl(_url, useToken);

    if (type == "hls") {
      var _hls = new Hls();

      var video = document.getElementById("hls_video");

      _hls.attachMedia(video);
      _hls.loadSource(url);

      _hls.on(Hls.Events.MANIFEST_PARSED, function (e, data) {});

      _hls.on(Hls.Events.ERROR, function (e, data) {});

      _hls.on(Hls.Events.AUDIO_TRACK_LOADED, function (e, data) {
        setAudioTracks(_hls.audioTracks);
      });

      _hls.on(Hls.Events.AUDIO_TRACK_SWITCHED, function (e, data) {
        setAudioTrack(data);
      });

      _hls.on(Hls.Events.SUBTITLE_TRACK_LOADED, function (e, data) {
        setSubtitleTracks(_hls.subtitleTracks);
      });

      _hls.on(Hls.Events.SUBTITLE_TRACK_SWITCH, function (e, data) {
        setSubtitleTrack(data);
      });

      _hls.on(Hls.Events.LEVEL_LOADED, function (e, data) {
        setVideoTracks(_hls.levels);
      });

      _hls.on(Hls.Events.LEVEL_SWITCHED, function (e, data) {
        setVideoTrack(data);
      });

      setHls(_hls);
    } else {
      document.getElementById("hls_video").src = url;
    }
  };

  const onSave = () => {
    const body = {
      start: start || 0,
      end: end || 0,
      credits: credits || 0,
      duration,
    };

    saveSkeepOption(body);
    hideModal();
  };

  return (
    <Modal
      open={isVisible}
      onCancel={() => {
        hideModal();
      }}
      footer={false}
      width={"max-content"}
      style={{
        top: 50,
      }}
    >
      <div className={style["player"]}>
        <div className={style["video-settings"]}>
          <div className={style["video"]}>
            {isVisible && video}

            {!showVideoAudioSubtitleTracks && (
              <MultiRangeSlider
                start={start}
                end={end}
                credits={credits}
                duration={duration}
                style={{ opacity: showTimePositions ? 1 : 0, transition: "0.2s" }}
              />
            )}
          </div>

          {showVideoAudioSubtitleTracks && (
            <>
              <div className={style["settings"]}>
                {videoTracks.length ? (
                  <div>
                    <Divider orientation="left">{t("video_track")}</Divider>
                    <Radio.Group
                      onChange={selectVideo}
                      value={videoTrack.level}
                      className={style["radio-groups"]}
                    >
                      <Space direction="vertical">
                        {videoTracks.map((item, index) => {
                          return (
                            <Radio key={index} value={index}>
                              {item.attrs.RESOLUTION}
                            </Radio>
                          );
                        })}
                      </Space>
                    </Radio.Group>
                  </div>
                ) : (
                  <></>
                )}

                {audioTracks.length ? (
                  <div>
                    <Divider orientation="left">{t("audio_track")}</Divider>
                    <Radio.Group
                      onChange={selectAudio}
                      value={audioTrack.id}
                      className={style["radio-groups"]}
                    >
                      <Space direction="vertical">
                        {audioTracks.map((item, index) => {
                          return (
                            <Radio key={index} value={item.id}>
                              {item.name}
                            </Radio>
                          );
                        })}
                      </Space>
                    </Radio.Group>
                  </div>
                ) : (
                  <></>
                )}

                {subtitleTracks.length ? (
                  <div>
                    <Divider orientation="left">{t("subtitle_track")}</Divider>
                    <Radio.Group
                      onChange={selectSubtitle}
                      value={subtitleTrack.id}
                      className={style["radio-groups"]}
                    >
                      <Space direction="vertical">
                        {subtitleTracks.map((item, index) => {
                          return (
                            <Radio key={index} value={item.id}>
                              {item.name}
                            </Radio>
                          );
                        })}
                      </Space>
                    </Radio.Group>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </>
          )}

          {saveSkeepOption && (
            <div style={{ marginTop: 15 }}>
              <div className="intro-times-inps" style={{ marginBottom: 10 }}>
                <div
                  style={{
                    textAlign: "start",
                    width: 150,
                  }}
                >
                  {t("duration_m")}:
                </div>

                <div style={{ width: 100 }}>{duration > 0 && duration}</div>

                <div style={{ width: 40 }}></div>
              </div>

              <div className="intro-times-inps">
                <span>{t("intro_start_ms")}:</span>
                <InputComponent
                  value={start}
                  type="number"
                  name="start"
                  onChange={(e) => {
                    onChange("start", e.target.value);
                  }}
                />
                <ButtonComponent type="add" action={() => onPluse("start")} />
              </div>

              <div className="intro-times-inps">
                <span>{t("intro_end_ms")}:</span>
                <InputComponent
                  value={end}
                  type="number"
                  name="end"
                  onChange={(e) => {
                    onChange("end", e.target.value);
                  }}
                />
                <ButtonComponent action={() => onPluse("end")} />
              </div>

              <div className="intro-times-inps">
                <span>{t("credit_time_ms")}:</span>
                <InputComponent
                  value={credits}
                  type="number"
                  name="credits"
                  onChange={(e) => {
                    onChange("credits", e.target.value);
                  }}
                />
                <ButtonComponent action={() => onPluse("credits")} />
              </div>

              <div style={{ position: "absolute", bottom: "5%", right: 0 }}>
                <ButtonComponent type="save" title="Save" action={onSave} />
              </div>
            </div>
          )}
        </div>

        {selectedTvShow && Object.keys(selectedTvShow).length > 0 && (
          <div className={style["tabs"]}>
            <Tabs defaultActiveKey={activeTab} onSelect={(k) => setActiveTab(k)}>
              {selectedTvShow.seasons &&
                selectedTvShow.seasons.length > 0 &&
                selectedTvShow.seasons.map((item, index) => {
                  return (
                    <Tab eventKey={index} title={item.name}>
                      <div className={style["episodes-list"]}>
                        <div className={style["container"]}>
                          {item.episodes &&
                            item.episodes.length > 0 &&
                            item.episodes.map((episode) => {
                              return (
                                <Tooltip placement="topLeft" title={episode.name}>
                                  <Badge.Ribbon
                                    text={`${
                                      selectedEpisodeId === episode.id ? "playing" : ""
                                    }`}
                                    color={`${
                                      selectedEpisodeId === episode.id
                                        ? "cyan"
                                        : "transparent"
                                    }`}
                                  >
                                    <Card
                                      hoverable
                                      style={{
                                        width: 140,
                                      }}
                                      bodyStyle={{
                                        padding: 10,
                                        textAlign: "start",
                                      }}
                                      cover={
                                        <img
                                          alt="example"
                                          style={{
                                            height: 100,
                                            objectFit: "cover",
                                          }}
                                          src={episode.poster}
                                        />
                                      }
                                      onClick={() => setCurrentEpisode(episode)}
                                    >
                                      <p className={style["episode-name"]}>
                                        {episode.name}
                                      </p>
                                    </Card>
                                  </Badge.Ribbon>
                                </Tooltip>
                              );
                            })}
                        </div>
                      </div>
                    </Tab>
                  );
                })}
            </Tabs>
          </div>
        )}
      </div>
    </Modal>
  );
}
