import { notification } from "antd";

import { useEffect, useState } from "react";

import REQUESTS from "../../../../../../api/requests";
import { getToken } from "../../../../../../server/requests";

import PlaylistsTable from "./PlaylistsTable";
import NewPlaylistDrawer from "./NewPlaylistDrawer";
import PlaylistDrawer from "./PlaylistDrawer";
import { useTranslation } from "react-i18next";

export default function PlaylistsTab({ user }) {
  const { t } = useTranslation();
  const [getAgain, setGetAgain] = useState(false);

  const [isOpenDrawer, setIsOpenDrawer] = useState(false);

  const [currentPlaylist, setCurrentPlaylist] = useState(null);

  const getDataSource = (query, onSuccess, onError) => {
    try {
      REQUESTS.USERS.USER.PLAYLISTS.GET(query)
        .then((response) => {
          onSuccess(response.message);
        })
        .catch((error) => {
          onError(error);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const handleMenuClick = (event, item) => {
    switch (event.key) {
      case "edit":
        setCurrentPlaylist(item);
        break;

      case "delete":
        const body = {
          id: {
            id: item.id,
            subId: item.subId,
            token: getToken(),
          },
        };

        REQUESTS.USERS.USER.PLAYLISTS.DELETE(body)
          .then((response) => {
            if (response.error) {
              notification.error({
                message: t("error"),
                description: response.message,
              });

              return;
            }

            notification.success({
              message: t("success"),
              description: response.message,
            });

            setGetAgain((prev) => !prev);
          })
          .catch((error) => {
            notification.error({
              message: t("error"),

              description: error,
            });
          });
        break;
      default:
        break;
    }
  };

  const onDefault = (event, item) => {
    const body = {
      id: item?.id,
      subId: item?.subId,
    };

    REQUESTS.USERS.USER.PLAYLISTS.DEFAULT(body)
      .then((response) => {
        if (response.error) {
          notification.error({
            message: t("error"),

            description: response.message,
          });

          return;
        }

        notification.success({
          message: t("success"),

          description: response.message,
        });

        setGetAgain((prev) => !prev);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    setGetAgain((prev) => !prev);
  }, [user]);

  return (
    <div>
      <PlaylistsTable
        user={user}
        getAgain={getAgain}
        onDefault={onDefault}
        getDataSource={getDataSource}
        handleMenuClick={handleMenuClick}
        openDrawer={() => setIsOpenDrawer(true)}
      />

      <NewPlaylistDrawer
        user={user}
        open={isOpenDrawer}
        getData={() => setGetAgain((prev) => !prev)}
        onClose={() => {
          setIsOpenDrawer(false);
        }}
      />

      <PlaylistDrawer
        current={currentPlaylist}
        open={currentPlaylist ? true : false}
        onClose={() => {
          setCurrentPlaylist(null);
        }}
      />
    </div>
  );
}
