import { useEffect, useState } from "react";
import { Popover, Tag, Tooltip } from "antd";

import icons from "../../../../../config/icons";
import { parseDate } from "../../../../../config/config";

import TableButtons from "../../../../../_components/table/TableButtons";
import TableComponent from "../../../../../_components/table/TableComponent";
import ButtonComponent from "../../../../../_components/button/ButtonComponent";
import { useTranslation } from "react-i18next";

const returnKeywordsList = (keywords) => {
  return (
    <div
      style={{
        maxWidth: "800px",
        width: "max-content",
        gap: 10,
        display: "flex",
        flexWrap: "wrap",
      }}
    >
      {keywords.map((keyword) => {
        return (
          <>
            <Tag>{keyword}</Tag>
          </>
        );
      })}
    </div>
  );
};

export default function KeywordsTable({
  getAgain,
  openDrawer,
  getDataSource,

  handleMenuClick,
}) {
  const { t } = useTranslation();

  const [dataSource, setDataSource] = useState([]);

  const [loading, setLoading] = useState(false);

  const columns = [
    {
      title: "#",
      width: 60,
      dataIndex: `id`,
      align: "center",
      key: "index",
      render: (record, text, index) => {
        return index + 1;
      },
    },

    {
      title: t("path"),
      dataIndex: "path",
      key: "path",
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip placement="topLeft" title={text}>
          {text}
        </Tooltip>
      ),
    },

    {
      title: t("description"),
      dataIndex: "description",
      key: "description",
      align: "center",
      ellipsis: {
        showTitle: false,
      },
      render: (text) => (
        <Tooltip placement="topLeft" title={text}>
          {text}
        </Tooltip>
      ),
    },

    {
      title: t("keywords"),
      dataIndex: "keywords",
      key: "keywords",
      align: "center",
      ellipsis: true,

      render: (text, record, index) => {
        const list = record.keywords.split(",");

        return (
          <Popover
            content={() => returnKeywordsList(list)}
            title={t("channels")}
            trigger="hover"
          >
            {record.keywords}
          </Popover>
        );
      },
    },

    {
      title: t("created_at"),
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",

      render: (text, record, index) => {
        return parseDate(text);
      },
    },

    {
      title: "",
      key: "operation",
      fixed: "right",
      width: 60,
      align: "center",

      render: (text, record, index) => (
        <TableButtons
          handleMenuClick={(e) => handleMenuClick(e, record)}
          items={[
            {
              key: "edit",
              name: t("edit"),
              icon: icons.EDIT,
            },

            {
              key: "delete",
              name: t("delete"),
              icon: icons.DELETE,
              question: t("keywords_remove_message"),
              onConfirm: (e) => {
                handleMenuClick(e, record);
              },
            },
          ]}
        />
      ),
    },
  ];

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(true);

      const query = {
        sort: ["id", "DESC"],
        limit: 100,
      };

      function onSuccess(data) {
        setLoading(false);
        setDataSource(data.rows);
      }

      function onError(data) {
        setLoading(false);
      }

      getDataSource(query, onSuccess, onError);
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [getAgain]);

  return (
    <TableComponent
      header={
        <>
          <ButtonComponent
            icon={icons.ADD}
            title={t("add_keyword")}
            onClick={openDrawer}
          />
        </>
      }
      isLoading={loading}
      dataSource={dataSource}
      columns={columns}
      localeClick={openDrawer}
    />
  );
}
