import Details from "./Details";
import Translations from "./Translations";

import NextBackButton from "../../../../../_components/button/NextBackButton";

import styles from "./_details.module.scss";
import { Divider } from "antd";
import SearchFromTmdb from "./SearchFromTmdb";
import { getAtLocal } from "../../../../../helpers/storages";
import { useEffect } from "react";

export default function DetailsStep({ form, editableMovieId, onChangeStep }) {
  const movieType = getAtLocal("mediaType") || "movie";

  useEffect(() => {
    if (editableMovieId) {
      form.setFields([{ name: "type", value: movieType }]);
    }
  }, []);

  return (
    <div>
      {!editableMovieId && <SearchFromTmdb form={form} />}

      <div className={styles["container"]}>
        <Details editableMovieId={editableMovieId} type={movieType} />

        <Translations editableMovieId={editableMovieId} />
      </div>
      <Divider plain />

      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <NextBackButton type="next" onClick={() => onChangeStep("next")} />
      </div>
    </div>
  );
}
