import { useEffect, useState } from "react";
import TableComponent from "../../_components/table/TableComponent";
import ButtonComponent from "../../_components/button/ButtonComponent";
import ICONS from "../../config/icons";
import { useTranslation } from "react-i18next";
import { Tag } from "antd";
import TableButtons from "../../_components/table/TableButtons";
import {
  getAtLocal,
  getAtSession,
  setInLocal,
  setInSession,
} from "../../helpers/storages";
import { getColumnSearchProps } from "../../config/config";
import { usePromoCodeGroupName } from "../../hooks/selectOptions";

const PromoCodesTable = ({
  getDataSource,
  loading,
  getAgain,
  showDrawer,
  handleClick,
  showGiftDrawer,
}) => {
  const { t } = useTranslation();

  const groupNameOptions = usePromoCodeGroupName("text");

  const [dataSource, setDataSource] = useState([]);
  const [isEmpty, setIsEmpty] = useState(false);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [limit, setLimit] = useState(getAtLocal("promo_codes") || 10);

  const [currentPage, setCurrentPage] = useState(getAtSession("promo_codes")?.page || 1);

  const [sort, setSort] = useState(getAtSession("promo_codes")?.sort || ["id", "DESC"]);

  const [total, setTotal] = useState(0);

  const [search, setSearch] = useState(
    getAtSession("promo_codes")?.search || {
      code: null,
    }
  );

  const columns = [
    {
      title: "#",
      dataIndex: "index",
      key: "index",
      align: "center",
      render: (text, record, index) => index + 1,
    },

    {
      title: t("code"),
      dataIndex: "code",
      key: "code",
      align: "center",
      defaultFilteredValue: getAtSession("promo_codes")?.search?.code || null,
      filteredValue: getAtSession("promo_codes")?.search?.code || null,
      ...getColumnSearchProps(),
    },
    {
      title: t("state"),
      dataIndex: "is_used",
      key: "is_used",
      align: "center",
      sorter: true,
      render: (text, record) => {
        return record.is_used ? (
          <Tag color="red">{t("used")}</Tag>
        ) : (
          <Tag color="green">{t("un_used")}</Tag>
        );
      },
    },

    {
      title: t("group_name"),
      dataIndex: "group_name",
      key: "group_name",
      align: "center",
      filters: groupNameOptions,
      filteredValue: search.group_name || null,
      onFilter: (value, record) => {
        if (!value) return true;
        return record.group_name === value;
      },
      filterMultiple: false,
    },
    {
      title: t("shearing_as_a_gift"),
      dataIndex: "is_active",
      key: "is_active",
      align: "center",

      render: (text, record) => {
        return !record.is_used ? (
          <Tag color="green">
            <div
              onClick={() => {
                showGiftDrawer(record);
              }}
              style={{
                display: "flex",
                alignItems: "center",
                gap: 5,
                cursor: "pointer",
                fontSize: 14,
              }}
            >
              {ICONS.GIFT}
              {t("share")}
            </div>
          </Tag>
        ) : (
          <Tag color="red">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: 5,
                cursor: "pointer",
                fontSize: 14,
              }}
            >
              {ICONS.GIFT}
              {t("not_shared")}
            </div>
          </Tag>
        );
      },
    },
    {
      title: "",
      key: "actions",
      align: "center",

      render: (text, record) => {
        return (
          <TableButtons
            handleMenuClick={({ key }) => {
              handleClick(key, record);
            }}
            items={[
              {
                key: "edit",
                name: t("edit"),
                icon: ICONS.EDIT,
              },
              {
                key: "delete",
                name: t("delete"),
                icon: ICONS.DELETE,
                question: t("delete_promo_code"),
                onConfirm: () => {
                  handleClick("delete", record);
                },
              },
            ]}
          />
        );
      },
    },
  ];

  const handleTableChange = (data) => {
    const promoCodes = {
      page: data.page,
      search: data.search,
    };
    if (data.sort) {
      promoCodes.sort = [`${data.sort[0]}`, `${data.sort[1]}`];
    }
    setInLocal("promo_codes", data.limit);
    setInSession("promo_codes", promoCodes);

    setCurrentPage(data.page);
    setLimit(data.limit);
    setSort(data.sort);

    setSearch(data.search);
  };

  const handleDownload = () => {
    const headers = columns.map((column) => column.title);

    const csvRows = dataSource.map((record, index) => {
      const state = record.is_used ? "Used" : "Unused";
      const sharing = record.is_active ? "Active" : "Inactive";

      return [index + 1, record.code, state, record.group_name, sharing].join(",");
    });

    const csvContent = [headers.join(","), ...csvRows].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv" });

    const element = document.createElement("a");
    element.href = URL.createObjectURL(blob);
    element.download = "promo_codes.csv";

    document.body.appendChild(element);
    element.click();

    document.body.removeChild(element);
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  useEffect(() => {
    const query = {
      page: currentPage,
      limit,
      // total,
    };

    if (sort && sort[1] && sort[1] !== "undefined") {
      query.sort = sort;
    } else {
      query.sort = ["id", "ASC"];
    }

    if (search.code) {
      query.search = {
        ...search,
        code: search.code[0],
      };
    }

    if (search.group_name) {
      query.search = {
        ...search,
        group_name: search.group_name[0],
      };
    }

    const hasSearchKeyWithValue = () => {
      const keys = Object.keys(search);

      return keys.some((key) => search[key] && search[key][0]);
    };

    const onSuccess = (response) => {
      if (response?.rows.length === 0 && hasSearchKeyWithValue()) {
        setIsEmpty(true);
      } else {
        setDataSource(response?.rows);
        setSelectedRowKeys([]);

        setTotal(response?.total);
      }
    };

    const onError = (error) => {
      console.log(error);
    };

    getDataSource(query, onSuccess, onError);
  }, [getAgain, currentPage, limit, sort, search]);

  return (
    <div>
      <TableComponent
        header={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 10,
              // flexDirection: "column",
            }}
          >
            {rowSelection?.selectedRowKeys?.length > 0 && (
              <ButtonComponent
                title={t("delete_selected")}
                icon={ICONS.DELETE}
                // type="danger"
                onClick={() => {
                  handleClick("bulk_delete", selectedRowKeys);
                }}
              />
            )}
            <ButtonComponent
              onClick={() => {
                showDrawer(true);
              }}
              title={t("create_promo_code")}
              icon={ICONS.ADD}
            />
            <ButtonComponent
              title={t("download_csv")}
              icon={ICONS.ARROW_DOWN}
              type="default"
              onClick={handleDownload}
            />
          </div>
        }
        columns={columns}
        dataSource={dataSource}
        onChange={handleTableChange}
        isLoading={loading}
        isEmpty={isEmpty}
        rowSelection={rowSelection}
        pagination={{
          total: total,
          current: currentPage,
          pageSize: limit,
        }}
      />
    </div>
  );
};

export default PromoCodesTable;
