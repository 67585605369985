import { Tabs } from "antd";

import VodTab from "./tabs/VodTab";

import OthersTab from "./tabs/OthersTab";

import LiveTvTab from "./tabs/LiveTvTab";

import PageComponent from "../../../_components/page/PageComponent";
import { useTranslation } from "react-i18next";

export default function CdnPage() {
  const { t } = useTranslation();
  const tabs = [
    {
      label: t("vod"),
      key: "vod",
      children: <VodTab />,
    },

    {
      label: t("live_tv"),
      key: "live_tv",
      children: <LiveTvTab />,
    },

    {
      label: t("others"),
      key: "others",
      children: <OthersTab />,
    },
  ];

  return (
    <PageComponent routes={["Settings", "CDN"]}>
      <div
        style={{
          width: "40%",
        }}
      >
        <Tabs tabPosition="left" items={tabs} />
      </div>
    </PageComponent>
  );
}
