import { Drawer, Form, Select, Input, message } from "antd";
import ButtonComponent from "../../../_components/button/ButtonComponent";
import icons from "../../../config/icons";
import { useEffect, useState } from "react";
import {
  useDevicesOptions,
  useCollectionsOptions,
  useVodGenresOptions,
} from "../../../hooks/selectOptions";
import ImageUpload from "../../../components/ImageUpload";
import { useTranslation } from "react-i18next";

const MenuConfigDrawer = ({ open, close, onSave, editable, loading }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const deviceOptions = useDevicesOptions();
  const vodGenresOption = useVodGenresOptions();
  const vodCollectionsOptions = useCollectionsOptions();

  const [selectedImage, setSelectedImage] = useState({ file: "", url: "" });
  const [selectIcon, setSelectIcon] = useState({ file: "", url: "" });
  const [showVodOption, setShowVodOption] = useState(false);
  const [menuType, setMenuType] = useState("");

  const typeOptions = [
    { label: t("home"), value: "home" },
    { label: t("movies"), value: "movies" },
    { label: t("tv_shows"), value: "tv-shows" },
    { label: t("collection"), value: "collection" },
    { label: t("categories"), value: "category" },
    { label: t("favorites"), value: "favorites" },
    { label: t("live_tv"), value: "live-tv" },
  ];

  const handleFinish = async (values) => {
    try {
      await form.validateFields();

      if (
        !selectIcon.file &&
        !selectIcon.url &&
        !selectedImage.file &&
        !selectedImage.url
      ) {
        message.error(t("choose_icon_message"));
        return;
      }

      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("type", values.type);
      formData.append("devices", values.devices);
      formData.append("is_enabled", false);
      if (values.content_id) formData.append("content_id", values.content_id);
      if (selectIcon.file) formData.append("selectedIcon", selectIcon.file);
      if (selectedImage.file) formData.append("icon", selectedImage.file);
      if (editable) formData.append("id", editable.id);

      onSave(formData);
    } catch (error) {
      console.error("Validation failed:", error);
    }
  };

  const resetFormState = () => {
    setSelectIcon({ file: "", url: "" });
    setSelectedImage({ file: "", url: "" });
    setShowVodOption(false);
    setMenuType("");
  };

  const handleClose = () => {
    resetFormState();
    form.resetFields();
    close();
  };

  const handleTypeChange = (value) => {
    const showOption = value === "collection" || value === "category";
    setShowVodOption(showOption);
    setMenuType(value);
  };

  const handleDeviceChange = (value) => {
    if (value.includes("all")) {
      const allDeviceValues = deviceOptions
        .filter((item) => item.value !== "all")
        .map((item) => item.value);

      form.setFieldsValue({ devices: allDeviceValues });
    } else {
      form.setFieldsValue({ devices: value });
    }
  };

  useEffect(() => {
    if (!open) {
      resetFormState();
      form.resetFields();
    }
  }, [open]);

  useEffect(() => {
    if (editable) {
      const editableDevices = editable.devices.split(",") ?? [];

      setSelectIcon({ url: editable.icon });
      setSelectedImage({ url: editable.selectedIcon });
      form.setFieldsValue({
        name: editable.name,
        type: editable.type,
        devices: editableDevices,
        content_id: editable.content_id,
      });
      setMenuType(editable?.type);

      if (editable?.type === "collection" || editable?.type === "category") {
        setShowVodOption(true);
      }
    }
  }, [editable, open]);

  return (
    <Drawer
      title={editable ? t("edit_config") : t("add_config")}
      width={400}
      open={open}
      onClose={handleClose}
      destroyOnClose
    >
      <Form form={form} layout="vertical" onFinish={handleFinish}>
        <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
          <Form.Item
            label={t("icon")}
            name="icon"
            rules={[
              {
                required: !selectIcon.url,
                message: t("choose_icon_message"),
              },
            ]}
          >
            <ImageUpload
              label={t("choose_file")}
              image={selectIcon}
              setImage={(image) => setSelectIcon(image)}
              removeImage
            />
          </Form.Item>

          <Form.Item
            name="selectedIcon"
            label={t("active_icon")}
            rules={[
              {
                required: !selectedImage.url,
                message: t("choose_active_icon_message"),
              },
            ]}
          >
            <ImageUpload
              label={t("choose_active_icon")}
              image={selectedImage}
              setImage={(image) => setSelectedImage(image)}
              removeImage
            />
          </Form.Item>
        </div>

        <Form.Item
          name="name"
          label={t("name")}
          rules={[{ required: true, message: t("name_message") }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="type"
          label={t("type")}
          rules={[{ required: true, message: t("select_type_message") }]}
        >
          <Select
            options={typeOptions}
            onChange={handleTypeChange}
            filterOption={(input, option) =>
              (option.label.toLowerCase() ?? "").includes(input.toLowerCase())
            }
          />
        </Form.Item>

        {showVodOption && (
          <Form.Item
            name="content_id"
            label={menuType === "collection" ? t("collections") : t("genres")}
            rules={[{ required: true, message: t("vod_message") }]}
          >
            <Select
              options={
                menuType === "collection" ? vodCollectionsOptions : vodGenresOption
              }
              filterOption={(input, option) =>
                (option.label.toLowerCase() ?? "").includes(input.toLowerCase())
              }
            />
          </Form.Item>
        )}

        <Form.Item
          name="devices"
          label={t("devices")}
          rules={[{ required: true, message: t("select_device_message") }]}
        >
          <Select
            mode="multiple"
            options={deviceOptions}
            showSearch
            allowClear
            filterOption={(input, option) =>
              (option?.label?.toLocaleLowerCase() ?? "").includes(
                input?.toLocaleLowerCase()
              )
            }
            filterSort={(optionA, optionB) =>
              (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            onChange={handleDeviceChange}
          />
        </Form.Item>

        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <ButtonComponent
            title={t("save")}
            icon={icons.CHECK}
            onClick={() => form.submit()}
            isLoading={loading}
          />
        </div>
      </Form>
    </Drawer>
  );
};

export default MenuConfigDrawer;
