import { Divider, Form, Radio, Select } from "antd";
import REQUESTS from "../../../../../api/requests";
import {
  useTvodPaymentsOptions,
  usePaymentsSubscriptionsOptions,
  useAddListOPtions,
} from "../../../../../hooks/selectOptions";

import Container from "../../../../../_components/container/Container";

import styles from "./_restriction_monetization.module.scss";
import { useTranslation } from "react-i18next";

export default function Monetization({ form }) {
  const { t } = useTranslation();
  const tvodPaymentsOptions = useTvodPaymentsOptions();

  const paymentsSubscriptionsOptions = usePaymentsSubscriptionsOptions();

  const addListOptions = useAddListOPtions();

  return (
    <Container className={styles["monetization"]}>
      <Divider orientation="left" plain>
        <b>{t("monetization")}</b>
      </Divider>

      <Form.Item label={t("content_monetization_type")} name="content_monetization_type">
        <Radio.Group name="radiogroup" defaultValue={"free"}>
          <Radio value="free">Free/AVOD</Radio>
          <Radio value="svod">SVOD</Radio>
          <Radio value="tvod">TVOD</Radio>
        </Radio.Group>
      </Form.Item>

      <Form.Item shouldUpdate noStyle>
        {() => {
          const { content_monetization_type } = form.getFieldsValue();

          switch (content_monetization_type) {
            case "svod":
              return (
                <Form.Item
                  label={t("tariffs")}
                  name="tariffIds"
                  rules={[
                    {
                      required: true,
                      message: t("pricing_message"),
                    },
                  ]}
                >
                  <Select mode="multiple" options={paymentsSubscriptionsOptions} />
                </Form.Item>
              );
            case "tvod":
              return (
                <Form.Item
                  label={t("tvod_pricing")}
                  name="pricing_id"
                  rules={[
                    {
                      required: true,
                      message: t("pricing_message"),
                    },
                  ]}
                >
                  <Select options={tvodPaymentsOptions} />
                </Form.Item>
              );
            case "free":
              return (
                <Form.Item label={t("advertisements")} name="ads_id">
                  <Select mode="multiple" options={addListOptions} />
                </Form.Item>
              );
            default:
              return null;
          }
        }}
      </Form.Item>
    </Container>
  );
}
