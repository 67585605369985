import React from "react";

export default function TextareaComponent({
    label,
    name,
    type,
    onChange,
    value,
    placeholder,
    disabled,
    readOnly,
    style,
}) {
    return (
        <div className="form-group" style={{ textAlign: "start" }}>
            {label && <label className="f-w-500 m-b-5">{label}:</label>}

            <textarea
                style={style}
                name={name}
                value={value}
                type={type || "text"}
                className="input textarea"
                onChange={onChange}
                placeholder={placeholder}
                disabled={disabled}
                readOnly={readOnly}
            />
        </div>
    );
}
