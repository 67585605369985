import { Drawer } from "antd";
import { useEffect, useState } from "react";
import axios from "axios";
import Terminal from "../terminal/Terminal";
import Loading from "../terminal/Terminal";

const LogDraweromponent = ({ open, onClose, channel, url, title }) => {
  const [logData, setLogData] = useState("");
  const [loading, setLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [shouldFetch, setShouldFetch] = useState(true); // Track whether to fetch data

  const transCodeUrl = (url, protocol) => {
    let httpPattern = /^http:\/\//;

    if (httpPattern.test(url)) {
      return url.replace(httpPattern, "http://") + `:${protocol}`;
    } else {
      return url;
    }
  };

  const getLogs = (abortController) => {
    axios
      .get(transCodeUrl(channel?.server.ip, channel?.server.port) + url, {
        params: { id: channel?.id },
        signal: abortController.signal,
      })
      .then((response) => {
        const list = response.data.split("\n").join("\n");
        setLogData(list);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        setErrorMessage(err.message);
        setShouldFetch(false);
        console.log(err.message);
      });
  };

  const fetchDataPeriodically = () => {
    const abortController = new AbortController();

    const logInterval = setInterval(() => {
      if (shouldFetch) {
        getLogs(abortController);
      }
    }, 5000);

    getLogs(abortController);

    return { abortController, logInterval };
  };

  useEffect(() => {
    let intervalData = null;

    if (open) {
      intervalData = fetchDataPeriodically();
    }

    return () => {
      if (intervalData) {
        intervalData.abortController.abort();
        clearInterval(intervalData.logInterval);
        setLogData("");
      }
    };
  }, [open, channel, shouldFetch]);

  return (
    <Drawer
      title={title}
      placement="right"
      closable={true}
      onClose={onClose}
      visible={open}
      width={"55%"}
      destroyOnClose={true}
    >
      {errorMessage && !logData.length && (
        <p style={{ color: "#f54d4f", fontSize: 18 }}>{errorMessage}</p>
      )}

      {loading ? <Loading style={{ height: "100%" }} /> : <Terminal content={logData} />}
    </Drawer>
  );
};

export default LogDraweromponent;
