import { Layout } from "antd";
import { useEffect } from "react";
import { Outlet } from "react-router";
import REQUESTS from "../api/requests";
import { bindActionCreators } from "redux";
import * as actions from "../redux-store/actions";
import { useDispatch } from "react-redux";

const PricingLayout = ({}) => {
  const dispatch = useDispatch();

  const { setPackages } = bindActionCreators(actions, dispatch);

  const getServicePackage = () => {
    REQUESTS.USAGE_INFO.CLOUD_SERVICE.GET_SERVICE()
      .then((res) => {
        setPackages(res?.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getServicePackage();
  }, []);

  return (
    <Layout
      style={{
        minHeight: "100vh",
        overflow: "hidden",
        padding: "26px 16px",
        margin: "0 auto",
        background: "#F4F7FE",
      }}
      hasSider
    >
      <Layout.Content>
        <Outlet />
      </Layout.Content>
    </Layout>
  );
};

export default PricingLayout;
