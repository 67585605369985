import { useEffect, useState } from "react";
import { Drawer, Form, Input, Select, Divider } from "antd";
import { useTranslation } from "react-i18next";
import ButtonComponent from "../../../../_components/ButtonComponent";
import styles from ".././style.module.scss";
import CountryPricingItem from "../CountryPricingItem";

const PricingDrawer = ({
  visible,
  onClose,
  countryOptions,
  selectedPackage,
  onSave,
  loading,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [selectedPricingList, setSelectedPricingList] = useState([]);

  useEffect(() => {
    if (selectedPackage) {
      const initialPricingList = selectedPackage.regional_pricing_pricing_package.map(
        (region) => ({
          country_code: region.country_code,
          country_icon: countryOptions.find(
            (option) => option.value === region.country_code
          )?.icon,
          impression_price: region.impression_price || "",
          conversion_price: region.conversion_price || "",
          currency: region.currency || "USD",
        })
      );

      setSelectedPricingList(initialPricingList);

      const initialValues = {
        pricing: selectedPackage.name,
      };
      initialPricingList.forEach((pricing) => {
        initialValues[`${pricing.country_code}_impression_price`] =
          pricing.impression_price;
        initialValues[`${pricing.country_code}_conversion_price`] =
          pricing.conversion_price;
      });

      form.setFieldsValue(initialValues);
    } else {
      form.resetFields();
      setSelectedPricingList([]);
    }
  }, [selectedPackage, countryOptions, form, visible]);

  const handleFinish = (values) => {
    const pricing = {};
    selectedPricingList.forEach((item) => {
      pricing[item.country_code] = {
        impression_price: values[`${item.country_code}_impression_price`] || "0",
        conversion_price: values[`${item.country_code}_conversion_price`] || "0",
      };
    });
    const result = {
      package_name: values.pricing,
      pricing: JSON.stringify(pricing),
    };

    if (selectedPackage) {
      result.package_id = selectedPackage.id;
    }

    let type;

    if (selectedPackage) {
      type = "edit";
    } else {
      type = "add";
    }

    onSave(result, type);

    onClose();
  };

  const handlePriceChange = (countryCode, field, value) => {
    setSelectedPricingList((prevList) =>
      prevList.map((item) =>
        item.country_code === countryCode ? { ...item, [field]: value } : item
      )
    );
    form.setFieldsValue({
      [`${countryCode}_${field}`]: value,
    });
  };

  return (
    <Drawer
      title={selectedPackage ? t("edit_package") : t("add_package")}
      width={480}
      onClose={onClose}
      open={visible}
      bodyStyle={{ paddingBottom: 80 }}
    >
      <Form layout="vertical" onFinish={handleFinish} form={form}>
        <Form.Item
          label={t("pricing_name")}
          name="pricing"
          rules={[
            {
              required: true,
              message: t("pricing_name_required"),
            },
          ]}
        >
          <Input placeholder={t("enter_pricing_name")} />
        </Form.Item>
        <Divider />
        <Form.Item label={t("country_search")}>
          <Select
            mode="multiple"
            placeholder={t("select_country")}
            options={countryOptions}
            onChange={(selectedCountryCodes) =>
              setSelectedPricingList(
                selectedCountryCodes.map((code) => ({
                  country_code: code,
                  country_icon: countryOptions.find((opt) => opt.value === code)?.icon,
                  impression_price: "",
                  conversion_price: "",
                  currency: "USD",
                }))
              )
            }
            showSearch
            value={selectedPricingList.map((item) => item.country_code)}
          />
        </Form.Item>

        <div className={styles["countries-pricing"]}>
          {selectedPricingList.map((pricing) => (
            <CountryPricingItem
              key={pricing.country_code}
              pricing={pricing}
              setSelectedPricingList={setSelectedPricingList}
              form={form}
            />
          ))}
        </div>
        <ButtonComponent
          title={t("save")}
          actionType={"add"}
          onClick={() => form.submit()}
          style={{ display: "flex", alignItems: "center" }}
          disabled={selectedPricingList.length === 0}
          isLoading={loading}
        />
      </Form>
    </Drawer>
  );
};

export default PricingDrawer;
