import { useState, useEffect } from "react";

import { Form, Button, Input, notification } from "antd";

import REQUESTS from "../../../../api/requests";

import style from "../style.module.scss";
import { useTranslation } from "react-i18next";

export default function PrivacyPolicyTab({ privacy_policy }) {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState();

  const onFinish = (values) => {
    setIsLoading(true);

    const query = {
      privacy_policy: values.privacy_policy,
    };

    REQUESTS.WEB_PAGE.SETTINGS.EDIT(query)
      .then((data) => {
        setIsLoading(false);

        if (data.error) {
          notification.error({
            message: t("error"),
            description: data.message,
          });

          return;
        }

        notification.success({
          message: t("success"),
          description: data.message,
        });
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (privacy_policy) {
      form.setFields([
        {
          name: "privacy_policy",
          value: privacy_policy,
        },
      ]);
    }
  }, [privacy_policy]);

  return (
    <div className={style["tab"]}>
      <p className={style["title"]}>{t("privacy_policy")}</p>

      <Form form={form} name="web-privacy-policy" layout="vertical" onFinish={onFinish}>
        <Form.Item name="privacy_policy">
          <Input.TextArea rows={16} />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            onClick={() => form.submit()}
            loading={isLoading}
            style={{ width: "100%" }}
          >
            {t("save")}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
