import { Divider, Form, Input, Radio, Select } from "antd";

import Container from "../../../../../_components/container/Container";

import { useProvidersOptions } from "../../../../../hooks/selectOptions";
import { useTranslation } from "react-i18next";

export default function Details({ editableMovieId, type }) {
  const { t } = useTranslation();
  const providersOptions = useProvidersOptions();

  return (
    <Container>
      <Divider orientation="left" plain>
        <b>{t("details")}</b>
      </Divider>

      <Form.Item
        label={t("name")}
        name="name"
        rules={[
          {
            required: true,
            message: t("name_message"),
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item label={t("description")} name="description">
        <Input.TextArea rows={5} style={{ resize: "none" }} />
      </Form.Item>

      <Form.Item label={t("provider")} name="content_provider_id">
        <Select options={providersOptions} />
      </Form.Item>
    </Container>
  );
}
