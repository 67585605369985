import adsInstance from "../api/instace";

const host = "https://ads.inorain.tv/";

const URL = {
  PRICE: `${host}admin/pricing`,
  PRICE_GETTER: `${host}admin/pricing/getter`,
  GET: `${host}admin/pricing`,

  GET_COUNTRY: `${host}admin/countries/`,

  // PRICES: `${host}admin/prices`,
};

const adsPricingApi = {
  GETTER: (query) => adsInstance.get(URL.PRICE_GETTER, { params: query }),
  GET: (query) => adsInstance.get(URL.GET, { params: query }),
  GET_COUNTRY: (id) => adsInstance.get(`${URL.GET_COUNTRY}`),

  ADD: (query) => adsInstance.post(URL.PRICE, query),
  EDIT: (query, id) => adsInstance.put(`${URL.PRICE}`, query),

  DELETE: (id) => adsInstance.delete(`${URL.PRICE}/many`, { data: { ids: id } }),
};

export default adsPricingApi;
