import request from "../../../api/request";

import { DEMO_API_HOST } from "../../../config/statics";
import { appHost } from "../../../helpers/appHost";

const host = appHost;

const URL = {
  USER: `${host}v2/admin/user`,
  // USER_V2: `${host}v2/admin/user`,
  USERS: `${host}admin/users`,
  SEARCH: `${host}admin/users/search`,

  VERIFY_LOGIN_CODE: `${host}v2/admin/user/verify_login_code`,
  ACTIVATE_LOGIN_CODE: `${host}v2/admin/user/activate_login_code`,
};

const usersApi = {
  GET: (query) => request("GET", URL.USERS, query),

  ADD: (query) => request("POST", URL.USERS, query),

  EDIT: (query) => request("PUT", URL.USERS, query),

  SEARCH: (query) => request("POST", URL.SEARCH, query),

  ARCHIVE: (query) => request("POST", `${URL.USER}/archive`, query),

  RESTORE: (query) => request("POST", `${URL.USER}/restore`, query),

  // LOCK: (query) => request("POST", `${URL.USERS}/block_user`, query),
  LOCK: (query) => request("POST", `${URL.USER}/lock`, query),

  // UNLOCK: (query) => request("POST", `${URL.USERS}/activate_user`, query),
  UNLOCK: (query) => request("POST", `${URL.USER}/unlock`, query),

  TARIFF: (query) => request("POST", `${URL.USERS}/update_tariff`, query),

  UNIQUE_USERNAME: (query) => request("POST", `${URL.USERS}/getUniqueName`, query),

  VERIFY_LOGIN_CODE: (query) => request("POST", URL.VERIFY_LOGIN_CODE, query),

  ACTIVATE_LOGIN_CODE: (query) => request("POST", URL.ACTIVATE_LOGIN_CODE, query),

  ADD_USER_MULTIPLE: (query) => request("POST", `${URL.USER}/generate`, query),
};

export default usersApi;
