import { useEffect, useState } from "react";

import { Drawer, Form, Input, notification } from "antd";

import REQUESTS from "../../../api/requests";

import Answers from "./Answers";

import ButtonComponent from "../../../_components/ButtonComponent";
import { useTranslation } from "react-i18next";

export default function QuizDrawer({ onClose, open, editable, getData }) {
  const { t } = useTranslation;
  const [isLoading, setIsLoading] = useState(false);

  const [form] = Form.useForm();

  const onFinish = (values) => {
    let answers = [];
    if (values.answers) {
      answers = values.answers.map((item, index) => ({
        content: item.content,
        unit: item.unit,
        number: index,
      }));
    }

    setIsLoading(true);

    const body = {
      answer: JSON.stringify(answers),
      content: values.content,
    };

    if (editable) {
      body.question_id = editable.id;

      REQUESTS.LESSONS.QUIZ.EDIT(body)
        .then((response) => {
          setIsLoading(false);

          if (response.error) {
            notification.error({
              description: response.message,
            });

            return;
          }

          getData();
          onClose();
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } else {
      REQUESTS.LESSONS.QUIZ.ADD(body)
        .then((response) => {
          setIsLoading(false);

          if (response.error) {
            notification.error({
              description: response.message,
            });

            return;
          }
          getData();
          onClose();
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (!open) {
      form.resetFields();
    }
    if (editable) {
      form.setFields([
        { name: "content", value: editable.content },
        { name: "answers", value: editable.answers },
      ]);
    }
  }, [open, editable]);

  return (
    <Drawer
      width={500}
      title={`${editable ? t("edit") : t("add")} ${t("quiz")}`}
      placement="right"
      onClose={onClose}
      open={open}
    >
      <Form form={form} name="quiz" layout="vertical" onFinish={onFinish}>
        <Form.Item
          label={t("content_question")}
          name="content"
          rules={[
            {
              required: true,
              message: t("content_message"),
            },
          ]}
        >
          <Input.TextArea rows={5} />
        </Form.Item>

        <Answers />

        <Form.Item style={{ textAlign: "center" }}>
          <ButtonComponent
            title={t("save")}
            actionType="save"
            isLoading={isLoading}
            onClick={() => form.submit()}
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
}
