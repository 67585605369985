import { useState } from "react";

import { Image, Popconfirm, notification } from "antd";

import ButtonComponent from "../../../../_components/ButtonComponent";

import styles from "../_cast.module.scss";
import REQUESTS from "../../../../api/requests";
import { useTranslation } from "react-i18next";

export default function Galery({ item, deleteGalery }) {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  console.log(item);

  const onDelete = () => {
    setIsLoading(true);

    REQUESTS.VOD.CAST.GALERY.DELETE({ id: item.id })
      .then((response) => {
        setIsLoading(false);

        if (response.error) {
          notification.error({
            message: t("error"),
            description: response.message,
          });

          return;
        }

        notification.success({
          message: t("success"),
          description: t("gallery_is_deleted"),
        });

        deleteGalery(item.id);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  return (
    <div className={styles["galery"]}>
      <Image src={item?.source} className={styles["image"]} />

      <Popconfirm
        placement="topRight"
        title={t("delete_gallery_confirm")}
        onConfirm={onDelete}
        okText={t("delete")}
        okButtonProps={{
          danger: true,
        }}
        cancelText={t("cancel")}
      >
        <ButtonComponent title={t("delete")} danger={true} isLoading={isLoading} />
      </Popconfirm>
    </div>
  );
}
