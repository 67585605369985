import { useEffect, useState } from "react";

import REQUESTS from "../../../../../../api/requests";

import MostWatchedChannelsTable from "./MostWatchedChannelsTable";

export default function MostWatchedChannelsTab({ user }) {
    const [getAgain, setGetAgain] = useState(false);

    const getDataSource = (onSuccess, onError) => {
        REQUESTS.USERS.USER.MOST_WATCHED_CHANNELS({ id: user?.id })
            .then((response) => {
                onSuccess(response.message);
            })
            .catch((error) => {
                onError(error);
            });
    };

    useEffect(() => {
        setGetAgain((prev) => !prev);
    }, [user]);

    return (
        <div>
            <MostWatchedChannelsTable getAgain={getAgain} getDataSource={getDataSource} />
        </div>
    );
}
