import { useState, useEffect } from "react";
import ChartComponent from "../../../../_components/chart/ChartComponent";
import { Pie } from "@ant-design/plots";
import REQUESTS from "../../../../api/requests";

import styles from "./chart.module.scss";
import { Empty } from "antd";
import { useTranslation } from "react-i18next";

const CountryChart = () => {
  const { t } = useTranslation();

  const [isEmpty, setIsEmpty] = useState(false);
  const [watchedData, setWatchedData] = useState({
    appendPadding: 10,
    data: [],
    angleField: "counter",
    colorField: "movie",
    label: {
      type: "inner",
      content: " ",
    },
    interactions: [
      {
        type: "pie-legend-active",
      },
      {
        type: "element-active",
      },
    ],
  });

  const getTopWatched = () => {
    try {
      REQUESTS.ADS.CHARTS.COUNTRY_VIEWS({ id: "all" })
        .then((response) => {
          if (!response.error) {
            const dataArray = response?.message?.full?.map((item) => {
              return {
                movie: item.movie?.name,
                counter: item?.counter,
              };
            });
            setWatchedData({
              ...watchedData,
              data: dataArray,
            });
          }
        })
        .catch((err) => {
          console.log(`err`, err);
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getTopWatched();
  }, []);

  useEffect(() => {
    !watchedData.data?.length ? setIsEmpty(true) : setIsEmpty(false);
  }, [watchedData]);

  return (
    <div className={styles["chart-wrapper"]}>
      <ChartComponent
        title={t("country_viewed")}
        children={
          isEmpty ? (
            <Empty style={{ height: 400 }} />
          ) : (
            <Pie {...watchedData} style={{ height: 450 }} />
          )
        }
      />
    </div>
  );
};
export default CountryChart;
