import { Form, Input, Switch } from "antd";
import ButtonComponent from "../../../../_components/ButtonComponent";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

const ConfigForm = ({
  isEnabled,
  name,
  img,
  onSave,
  isLoading,
  clientSecret,
  clientId,
}) => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      [name.enable]: checked,
      [name.clientId]: clientId,
      [name.secretKey]: clientSecret,
    });
  }, [checked, clientId, clientSecret, form, name]);

  const handleFinish = (values) => {
    if (values[name.enable]) {
      onSave(values);
    } else {
      onSave({
        [name.enable]: checked,
      });
    }
  };

  useEffect(() => {
    setChecked(isEnabled);
  }, [isEnabled]);

  return (
    <div style={{ width: 350 }}>
      <img src={img} alt="logo" style={{ width: 70, marginBottom: 30 }} />
      <Form layout="vertical" form={form} onFinish={handleFinish}>
        <Form.Item label={t("enabled")} name={name.enable} valuePropName="checked">
          <Switch checked={checked} onChange={() => setChecked(!checked)} />
        </Form.Item>

        {checked && (
          <>
            <Form.Item
              label={t("client_id")}
              name={name.clientId}
              rules={[{ required: true, message: t("client_id_message") }]}
            >
              <Input placeholder={t("client_id_placeholder")} />
            </Form.Item>

            <Form.Item
              label={t("secret_key")}
              name={name.secretKey}
              rules={[{ required: true, message: t("secret_key_message") }]}
            >
              <Input placeholder={t("secret_key_placeholder")} />
            </Form.Item>
          </>
        )}

        <div style={{ display: "flex" }}>
          <ButtonComponent
            type="primary"
            title={t("save")}
            onClick={() => form.submit()}
            isLoading={isLoading}
          />
        </div>
      </Form>
    </div>
  );
};

export default ConfigForm;
