import request from "../../../api/request";
import { DEMO_API_HOST } from "../../../config/statics";
import { appHost } from "../../../helpers/appHost";

const host = appHost;

const URL = {
  APK: `${host}admin/apk`,
};

const apksApi = {
  GET: (query) => request("GET", `${URL.APK}`, query),

  UPDATE: (query) => request("PUT", `${URL.APK}`, query, false),
};

export default apksApi;
