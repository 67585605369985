import request from "../../../api/request";
import { DEMO_API_HOST } from "../../../config/statics";
import { appHost } from "../../../helpers/appHost";

const host = appHost;

const URL = {
  ARCHIVE: `${host}v2/admin/recordings`,
};

const catchUpRecordingsApi = {
  GET: (query) => request("GET", URL.ARCHIVE, query),

  DELETE: (query) => request("DELETE", URL.ARCHIVE, query),
};

export default catchUpRecordingsApi;
