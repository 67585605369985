import React from "react";
import DatePicker from "react-datepicker";

export default function DatePickerComponent({
  label,
  selected = "",
  onChange,
  autoFocus,
  showTimeSelect,
  minDate,
  style,
  disabled,
  isClearable,
}) {
  return (
    <div className="form-group" style={{ textAlign: "start", ...style }}>
      <label className="f-w-500 m-b-5">{label}:</label>

      <DatePicker
        isClearable={isClearable}
        disabled={disabled}
        autoFocus={autoFocus}
        selected={selected}
        onChange={onChange}
        showYearDropdown
        showMonthDropdown
        // withPortal
        showTimeSelect={showTimeSelect}
        timeFormat="HH:mm"
        timeCaption="time"
        minDate={minDate}
        dateFormat={showTimeSelect && "yyyy-MM-dd h:mm:ss"}
      />
    </div>
  );
}
