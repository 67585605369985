import request from "../../api/request";
import { DEMO_API_HOST } from "../../config/statics";
import { appHost } from "../../helpers/appHost";

const host = appHost;

const URL = {
  LOGIN: `${host}content_provider/login`,

  CONTENT_PROVIDER: `${host}admin/content_provider`,
};

const contentProviderApi = {
  GET: (query) => request("GET", URL.CONTENT_PROVIDER, query),

  LOGIN: (query) => request("POST", URL.LOGIN, query),
};

export default contentProviderApi;
