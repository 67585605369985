import request from "../../../api/request";
import { DEMO_API_HOST } from "../../../config/statics";
import { appHost } from "../../../helpers/appHost";

const host = appHost;

const URL = {
  LAUNCHER: `${host}launcher/admin/app_basics`,
};

const launcherAppsApi = {
  GET: (query) => request("GET", URL.LAUNCHER, query),

  EDIT: (query) => request("PUT", URL.LAUNCHER, query),

  POSITION: (query) => request("PUT", `${URL.LAUNCHER}/position`, query),
};

export default launcherAppsApi;
