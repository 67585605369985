import icons from "../../config/icons";

export default function Check({ value }) {
    return (
        <div>
            {value ? (
                <div style={{ color: "green" }}>{icons.CHECK}</div>
            ) : (
                <div style={{ color: "red" }}>{icons.X}</div>
            )}
        </div>
    );
}
