import { appHost } from "../../helpers/appHost";
import request from "../request";

// const host = `${appHost || "DEFAULT_REACT_API_HOST"}admin/`;

const host = appHost;

const URL = {
  APP_INFO: `${host}v2/admin/app_info`,

  LOGIN: `${host}admin/login`,

  VALIDATE: `${host}validate`,

  PERMISSIONS: `${host}admin/menu/permission`,

  CONTENT_PROVIDER_PERMISSION: `${host}admin/menu/permission/content_provider`,

  SERVICE_PACKAGE: `${host}admin/cloud_service/api`,
};

const PROFILE = {
  LOGIN: (query) => request("POST", URL.LOGIN, query),

  VALIDATE: (query) => request("POST", URL.VALIDATE, query),

  PERMISSIONS: () => request("GET", URL.PERMISSIONS),

  HAS_CONTENT_PROVIDER_PERMISSION: () => request("GET", URL.CONTENT_PROVIDER_PERMISSION),

  SERVICE_GET: () => request("GET", URL.SERVICE_PACKAGE),

  GET_APP_INFO: () => request("GET", URL.APP_INFO),
};

export default PROFILE;
