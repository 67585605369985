import React, { useState } from "react";
import defaultImage from "../img/photo.png";

export default function TableImage({ src, isZoom = true }) {
    const [zoomIn, setZoomIn] = useState(false);

    const imageError = (e) => (e.target.src = defaultImage);

    const openPopup = () => {
        if (isZoom) {
            setZoomIn(true);
        }
    };

    const closePopup = (event) => {
        let clName = event.target.className;

        if (clName === "zoom-image" || clName === "table-image-container") return;

        setZoomIn(false);
    };

    return (
        <>
            {zoomIn && (
                <div className="table-image-popup" onClick={closePopup}>
                    <div className="table-image-container">
                        <img className="zoom-image" src={src} onError={imageError} />

                        <div onClick={() => setZoomIn(false)}>
                            <i className="fas fa-times-circle zoom-close" />
                        </div>
                    </div>
                </div>
            )}

            <img
                alt="poster"
                src={src}
                className="table-image"
                onError={imageError}
                onClick={openPopup}
                style={{
                    cursor: isZoom && "zoom-in",
                }}
            />
        </>
    );
}
