import React from "react";

export default function SearchComponent({ value, onChange, placeholder, hideTitle }) {
    return (
        <div className="form-group">
            {!hideTitle && <label className="f-w-500 m-b-5">Search:</label>}

            <input
                type="text"
                className="input search-input"
                name="searchInput"
                value={value}
                onChange={onChange}
                placeholder={placeholder}
            />
        </div>
    );
}
