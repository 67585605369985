import React, { useEffect, useState } from "react";
// import MyPopup from "../../../../popups/MyPopup";
import ImageChooser from "../../../../components/ImageCooser";
import InputComponent from "../../../../components/InputComponent";
import { requestAddOrUpdateSeason } from "../../../../server/requests";
import TextareaComponent from "../../../../components/TextareaComponent";
import DatePickerComponent from "../../../../components/DatePickerComponent";
import Translations from "./Translations";
import { Drawer } from "antd";

function SeasonPopup({ movieId, onClose, editableSeason, getData, open }) {
  const [season, setSeason] = useState({
    id: "",
    // movieId,
    name: "",
    description: "",
    number: "",
    poster: "",
    release_date: new Date(),
  });

  useEffect(() => {
    if (movieId) {
      setSeason({
        ...season,
        movieId,
      });
    }
  }, [movieId]);
  const onChange = (event) => {
    setSeason({
      ...season,
      [event.target.name]: event.target.value,
    });
  };

  let addTimeOut;

  const addOrUpdateSeason = () => {
    if (addTimeOut) {
      clearTimeout(addTimeOut);
    }
    addTimeOut = setTimeout(() => {
      addSeason();
    }, 1000);
  };

  const addSeason = () => {
    requestAddOrUpdateSeason(!editableSeason ? "post" : "put", season, (data) => {
      onClose();
      getData();
    });
  };

  const handleClose = () => {
    onClose();
    setSeason({
      // ...season,
      id: "",
      name: "",
      description: "",
      number: "",
      poster: "",
      release_date: new Date(),
    });
  };

  useEffect(() => {
    if (editableSeason) {
      setSeason(editableSeason);
    }
  }, [editableSeason]);

  useEffect(() => {
    const handleBlur = (event) => {
      if (document.activeElement.type === "number") {
        document.activeElement.blur();
      }
    };

    document.addEventListener("scroll", (e) => e.stopPropagation());
    window.addEventListener("wheel", handleBlur);

    return () => {
      window.removeEventListener("wheel", handleBlur);
      document.removeEventListener("scroll", (e) => e.stopPropagation());
    };
  }, []);

  return (
    <Drawer open={open} onClose={handleClose} width={600} title="Season">
      <div style={{ marginBottom: 20 }}>
        <div className="image-label">
          <label className="f-w-500 m-b-5">Poster:</label>

          <span>1080 x 1920</span>
        </div>

        <ImageChooser
          image={season?.poster}
          id="seasonPosterId"
          onChangeImage={(poster) => setSeason({ ...season, poster })}
          isOpen={open}
        />
      </div>

      <InputComponent
        label="Name"
        name="name"
        value={season.name}
        onChange={onChange}
        placeholder="Enter name..."
      />

      <InputComponent
        type="number"
        label="Number"
        name="number"
        value={season.number}
        onChange={onChange}
        placeholder="Enter number..."
      />

      <DatePickerComponent
        label="Release date"
        selected={season?.release_date && new Date(season.release_date)}
        onChange={(day) => setSeason({ ...season, release_date: day })}
      />

      <TextareaComponent
        label="Description"
        name="description"
        value={season.description}
        onChange={onChange}
        placeholder="Enter description..."
      />

      {editableSeason && <Translations id={season.id} model="season" />}

      {
        <button
          style={{ marginTop: 20 }}
          className={`popup-button-style ${
            !editableSeason ? "add-button" : "edit-button"
          } `}
          onClick={addOrUpdateSeason}
        >
          <i
            className={!editableSeason ? "fas fa-plus" : "fas fa-pencil-alt"}
            style={{ marginRight: 5 }}
          />

          {editableSeason ? "Edit" : "Add"}
        </button>
      }
    </Drawer>
  );
}

export default SeasonPopup;
