import { useState } from "react";
import { notification, Modal } from "antd";

import REQUESTS from "../../../api/requests";

import ManageMoviesDrawer from "./ManageMoviesDrawer";
import VodCollectionDrawer from "./VodCollectionDrawer";
import VodCollectionsTable from "./VodCollectionsTable";

import PageComponent from "../../../_components/page/PageComponent";
import SetTypeDrawer from "./SetTypeDrawer";
import { useTranslation } from "react-i18next";

export default function VodCollectionsPage({}) {
  const { t } = useTranslation();
  const [getAgain, setGetAgain] = useState(false);

  const [editable, setEditable] = useState(null);

  const [editableCollection, setEditableCollection] = useState(null);

  const [isOpenDrawer, setIsOpenDrawer] = useState(false);

  const [showType, setShowType] = useState(false);

  const [collectionForManageMovies, setCollectionForManageMovies] = useState(null);

  const handleMenuClick = (event, item) => {
    switch (event.key) {
      case "edit":
        setEditable(item);

        setIsOpenDrawer(true);
        break;

      case "manage_movies":
        setCollectionForManageMovies(item);
        break;

      case "protect":
        onProtect(item.id, true);
        break;

      case "unprotect":
        onProtect(item.id, false);
        break;

      case "set_type":
        setShowType(true);
        setEditable(item);
        break;

      case "delete":
        REQUESTS.VOD.COLLECTIONS.DELETE({ id: item.id })
          .then((response) => {
            if (response.error) {
              notification.error({
                message: t("error"),
                description: response.message,
              });

              return;
            }

            notification.success({
              message: t("success"),
              description: response.message,
            });

            setGetAgain((prev) => !prev);
          })
          .catch((error) => {
            notification.error({
              message: t("error"),
              description: error,
            });
          });

        break;

      default:
        break;
    }
  };

  const getDataSource = (params, onSuccess, onError) => {
    try {
      REQUESTS.VOD.COLLECTIONS.GET({ query: JSON.stringify(params) })
        .then((response) => {
          if (!response.error) {
            onSuccess(response || []);
            setEditableCollection(response);
          }
        })
        .catch((error) => {
          onError(error);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const changePosition = (ids, callback) => {
    const query = {
      collectionIds: ids,
    };

    REQUESTS.VOD.COLLECTIONS.SORT(query)
      .then((response) => {
        if (response.error) {
          notification.error({
            message: t("error"),
            description: response.message,
          });
          return;
        }

        notification.success({
          message: t("success"),
          description: response.message,
        });

        callback();
      })

      .catch(() => {
        callback();
      });
  };

  const onVisible = (isVisible, isError) => {
    const formData = new FormData();

    if (isVisible.type == "collection_type") {
      formData.append("id", editable?.id);
      formData.append("collection_type", isVisible?.value);
    } else {
      formData.append("visible", isVisible?.type);
      formData.append("id", isVisible?.value);
    }

    REQUESTS.VOD.COLLECTIONS.EDIT(formData)
      .then((response) => {
        if (!response.error) {
          notification.success({
            message: t("success"),
            description: response.message,
          });
          setGetAgain((prev) => !prev);
          setShowType(false);
        } else {
          isError();
          notification.error({
            message: t("error"),
            description: t("something_wrong"),
          });
        }
      })
      .catch((error) => {
        notification.error({
          message: t("error"),

          description: error,
        });
      });
  };

  const onProtect = (id, is_protected) => {
    const body = {
      id: id,
      is_protected: is_protected,
    };

    REQUESTS.VOD.COLLECTIONS.PROTECT(body)
      .then((response) => {
        if (!response.error) {
          notification.success({
            message: t("success"),
            description: response.message,
          });
          setGetAgain((prev) => !prev);
        }
      })
      .catch((error) => {
        notification.error({
          message: t("error"),

          description: error,
        });
      });
  };

  const handlePosition = (position, id) => {
    REQUESTS.VOD.COLLECTIONS.POSITIONS({ position, id, force: false })
      .then((response) => {
        if (response.error) {
          Modal.confirm({
            title: t("change_position_message"),
            content: (
              <div>
                {/* <p>
                  In {position} position already exists <b>{response?.message?.name}</b>{" "}
                  live tv. We can switch their positions. Do you want to do it?
                </p> */}
                <p>
                  {t("change_position_message_with_name", {
                    position,
                    name: response?.message?.name,
                  })}
                </p>
              </div>
            ),
            okText: t("change"),
            onOk() {
              REQUESTS.VOD.COLLECTIONS.POSITIONS({ position, id, force: true })
                .then(() => {
                  notification.success({
                    message: t("success"),
                    description: t("position_change_success"),
                  });

                  setGetAgain((prev) => !prev);
                })
                .catch((err) => {
                  notification.error({
                    message: t("error"),
                    description: err.message,
                  });
                });
            },
            onCancel() {
              setGetAgain((prev) => !prev);
            },
          });
        } else {
          setGetAgain((prev) => !prev);
        }
      })
      .catch((error) => {
        notification.error({
          message: t("error"),

          description: error,
        });

        setGetAgain((prev) => !prev);
      });
  };

  return (
    <PageComponent routes={[t("vod"), t("collection")]}>
      <VodCollectionsTable
        getAgain={getAgain}
        onVisible={onVisible}
        getDataSource={getDataSource}
        handleMenuClick={handleMenuClick}
        changePosition={changePosition}
        openDrawer={() => setIsOpenDrawer(true)}
        onChangePosition={(position, id) => handlePosition(position, id)}
      />

      <VodCollectionDrawer
        open={isOpenDrawer}
        editable={editable}
        onClose={() => {
          setEditable(null);
          setIsOpenDrawer(false);
        }}
        getData={() => setGetAgain((prev) => !prev)}
      />

      <ManageMoviesDrawer
        collection={collectionForManageMovies}
        open={collectionForManageMovies ? true : false}
        onClose={() => setCollectionForManageMovies(null)}
      />

      <SetTypeDrawer
        open={showType}
        onClose={() => {
          setShowType(false);
        }}
        editable={editable}
        onSave={(value, err) => {
          onVisible(value, err);
        }}
      />
    </PageComponent>
  );
}
