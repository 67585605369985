import { useEffect, useState } from "react";

import { Drawer, Form, Input, notification } from "antd";
import ButtonComponent from "../../../../_components/ButtonComponent";
import REQUESTS from "../../../../api/requests";
import { useTranslation } from "react-i18next";

export default function NewFolderDrawer({ onClose, open, getData, currentPath }) {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const [form] = Form.useForm();

  const onFinish = (values) => {
    setIsLoading(true);

    const body = {
      path: currentPath + values.name,
    };

    REQUESTS.VOD.TRANSCODING.FILE.ADD(body)
      .then((response) => {
        setIsLoading(false);

        if (response.error) {
          notification.error({
            description: response.message,
          });

          return;
        }
        getData(currentPath);
        onClose();
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!open) {
      form.resetFields();
    }
  }, [open]);

  return (
    <Drawer title={t("add_new_folder")} placement="right" onClose={onClose} open={open}>
      <Form form={form} name="new-folder" layout="vertical" onFinish={onFinish}>
        <Form.Item
          label={t("name")}
          name="name"
          rules={[
            {
              required: true,
              message: t("input_message", { name: t("name") }),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item style={{ textAlign: "center" }}>
          <ButtonComponent
            title={t("save")}
            actionType="save"
            isLoading={isLoading}
            onClick={() => form.submit()}
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
}
