import { useEffect, useState } from "react";
import { Flex } from "antd";
import EpgMetric from "./charts/EpgMetric";
import REQUESTS from "../../../../api/requests";
import { sortData } from "./utils";
import EpgTable from "./table/EpgTable";

const EpgTabs = () => {
  const [metricData, setMetricData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [metricDae, setMetricDate] = useState("");
  const [title, setTitle] = useState([]);

  const getEpgMetrics = () => {
    try {
      REQUESTS.DASHBOARD.CHARTS.EPG.GET_EPG_METRICS({})
        .then((response) => {
          const metricArr = [];
          const tableArr = [];
          const titleArr = [];

          if (response) {
            console.log(response);

            parseStatisticDate(response?.last_generated_date);

            sortData(response, (item) => {
              if (item.render_type == "chart") {
                metricArr.push(item.data);
                titleArr.push(item.name);

                setTitle(titleArr);
                setMetricData(metricArr);
              } else {
                tableArr.push(item);
                setTableData(tableArr);
              }
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const parseStatisticDate = (data) => {
    if (typeof data.data !== "object") {
      const date = new Date(data).toLocaleString().split(",")[0];

      setMetricDate(date);
    }
  };

  useEffect(() => {
    getEpgMetrics();
  }, []);

  return (
    <div>
      <h4>{metricDae}</h4>
      <Flex wrap="wrap" gap="20px">
        {metricData?.map((item, idx) => {
          return <EpgMetric data={item.data} key={item.name} title={title[idx]} />;
        })}
      </Flex>
      <br />

      <Flex wrap="wrap" gap="20px">
        {tableData?.map((item) => {
          return (
            <div
              style={{
                width: "calc(50% - 20px)",
                minWidth: "430px",
              }}
            >
              <EpgTable data={item.data} />
              <br />
            </div>
          );
        })}
      </Flex>
    </div>
  );
};

export default EpgTabs;
