import { Tooltip } from "antd";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getAtSession, setInSession, setInLocal } from "../../../helpers/storages";

import icons from "../../../config/icons";
import { getColumnDateProps, getQueryDate, parseDate } from "../../../config/config";

import Check from "../../../_components/table/Check";
import TableButtons from "../../../_components/table/TableButtons";
import ButtonComponent from "../../../_components/ButtonComponent";
import TableComponent from "../../../_components/table/TableComponent";
import { useTranslation } from "react-i18next";

export default function PaymentsSubsriptionsTable({
  getAgain,
  openDrawer,
  getDataSource,
  handleMenuClick,
}) {
  const { t } = useTranslation();

  const { permissions } = useSelector((state) => state.globalState);

  const [dataSource, setDataSource] = useState([]);

  const [loading, setLoading] = useState(false);
  const [isEmpty, setIsEmpty] = useState(false);

  const [limit, setLimit] = useState(10);

  const [currentPage, setCurrentPage] = useState(
    getAtSession("paymentSubscription")?.page || 1
  );

  const [sort, setSort] = useState(
    getAtSession("paymentSubscription")?.sort || ["id", "ASC"]
  );

  const [date, setDate] = useState(null);

  const [total, setTotal] = useState(0);

  const [search, setSearch] = useState(
    getAtSession("paymentSubscription")?.search || {
      user: null,
    }
  );

  const setupColumns = () => {
    const columns = [
      {
        width: 60,
        title: "#",
        key: "index",
        dataIndex: `id`,
        fixed: "left",
        align: "center",
        render: (text, record, index) => {
          return limit * (currentPage - 1) + index + 1;
        },
      },
      {
        title: t("name"),
        dataIndex: "name",
        key: "name",
        align: "center",
        ellipsis: true,
        width: 200,
      },

      {
        title: t("price"),
        width: 90,
        key: "price",
        dataIndex: `price`,
        align: "center",
        sorter: true,
      },

      {
        title: t("device_count"),
        key: "device_service",
        dataIndex: `device_service`,
        align: "center",
        width: 110,
        ellipsis: true,

        render: (text, record, index) => {
          let count = 0;

          if (record?.service) {
            for (let i = 0; i < record?.service.length; i++) {
              if (record?.service[i]?.serviceId == 4) {
                count = record?.service[i]?.value;
              }
            }
          }

          return <div>{count || 0}</div>;
        },
      },

      {
        title: t("payment_type"),
        key: "payment_type",
        dataIndex: `payment_type`,
        align: "center",
        width: 130,
      },
    ];

    if (permissions?.payments?.paypal) {
      columns.push({
        title: t("paypal_plan_id"),
        key: "paypal_plan_id",
        dataIndex: `paypal_plan_id`,
        align: "center",

        ellipsis: {
          showTitle: false,
        },
        render: (text) => (
          <Tooltip placement="topLeft" title={text}>
            {text}
          </Tooltip>
        ),
      });
    }

    if (permissions?.payments?.stripe) {
      columns.push({
        title: t("stripe_plan_id"),
        key: "stripe_plan_id",
        dataIndex: `stripe_plan_id`,
        align: "center",

        ellipsis: {
          showTitle: false,
        },
        render: (text) => (
          <Tooltip placement="topLeft" title={text}>
            {text}
          </Tooltip>
        ),
      });
    }

    if (permissions?.payments?.apple) {
      columns.push({
        title: t("apple_subscription_id"),
        key: "apple_subscription_id",
        dataIndex: `apple_subscription_id`,
        align: "center",

        ellipsis: {
          showTitle: false,
        },
        render: (text) => (
          <Tooltip placement="topLeft" title={text}>
            {text}
          </Tooltip>
        ),
      });
    }

    if (permissions?.payments?.google) {
      columns.push({
        title: t("google_subscription_id"),
        key: "google_subscription_id",
        dataIndex: `google_subscription_id`,
        align: "center",

        ellipsis: {
          showTitle: false,
        },
        render: (text) => (
          <Tooltip placement="topLeft" title={text}>
            {text}
          </Tooltip>
        ),
      });
    }

    columns.push(
      {
        title: t("has_archive"),
        key: "archive_service",
        dataIndex: `archive_service`,
        align: "center",
        width: 90,

        render: (text, record, index) => {
          return <Check value={record.archive_service} />;
        },
      },

      {
        title: t("vod"),
        key: "vod_service",
        dataIndex: `vod_service`,
        align: "center",
        width: 70,

        render: (text, record, index) => {
          return <Check value={record.vod_service} />;
        },
      },

      {
        title: t("scheduling"),
        key: "scheduling_time",
        dataIndex: `scheduling_time`,
        align: "center",
        width: 90,

        render: (text, record, index) => {
          return (
            <div>
              {record.scheduling_time} {record.scheduling_type}
            </div>
          );
        },
      },

      {
        title: t("date"),
        key: "createdAt",
        dataIndex: `createdAt`,
        width: 150,
        align: "center",
        render: (text, record, index) => {
          return parseDate(text);
        },

        ...getColumnDateProps(setDate),
      },

      {
        title: "",
        key: "operation",
        fixed: "right",
        width: 60,
        align: "center",

        render: (text, record, index) => (
          <TableButtons
            handleMenuClick={(e) => handleMenuClick(e, record)}
            items={[
              {
                key: "edit",
                name: t("edit"),
                icon: icons.EDIT,
              },

              {
                key: "delete",
                name: t("delete"),
                icon: icons.DELETE,
                question: t("delete_scheduling_message"),
                onConfirm: (e) => {
                  handleMenuClick(e, record);
                },
              },
            ]}
          />
        ),
      }
    );

    return columns;
  };

  const handleTableChange = (data) => {
    setCurrentPage(data.page);
    setLimit(data.limit);
    setSort(data.sort);

    setInLocal("pageLimit", data.limit);
    setInSession("paymentsSubscriptions", {
      ...getAtSession("paymentsSubscriptions"),
      page: data.page,
      limit: data.limit,
      sort: data.sort,
    });

    setSearch(data.search);
  };

  // useEffect(() => {
  //     const timeout = setTimeout(() => {
  //         setLoading(true);

  //         const query = {
  //             sort,
  //             limit,
  //             page: currentPage,
  //         };

  //         const queryDate = getQueryDate(date);

  //         if (queryDate) query.between = queryDate;

  //         function onSuccess(data) {
  //             if (data.rows?.length === 0 && currentPage > 1) {
  //                 setCurrentPage((prev) => prev - 1);
  //                 return;
  //             }

  //             setLoading(false);
  //             setDataSource(data.rows);
  //             setTotal(data?.total);
  //             setLimit(data?.limit);
  //             setCurrentPage(data?.page);
  //         }

  //         function onError() {
  //             setLoading(false);
  //         }

  //         getDataSource({ query: JSON.stringify(query) }, onSuccess, onError);
  //     }, 500);

  //     return () => {
  //         clearTimeout(timeout);
  //     };
  // }, [limit, currentPage, sort, search, date, getAgain]);

  useEffect(() => {
    const fetchLiveTvData = () => {
      setLoading(true);

      const query = {
        // sort,
        limit,
        page: currentPage,
      };

      if (sort && sort[1] && sort[1] !== "undefined") {
        query.sort = sort;
      } else {
        query.sort = ["id", "ASC"];
      }

      if (search.name) {
        query.search = {
          name: search.name,
        };
      }

      if (search.categories) {
        query.filter = {
          categoryId: search.categories,
        };
      }

      function onSuccess(data) {
        setLoading(false);
        if (data?.rows?.length > 0) {
          setDataSource(data?.rows);
          setIsEmpty(false);
        } else {
          setIsEmpty(true);
        }
        setTotal(data?.count);

        const maxPage = Math.ceil(data?.count / limit);
        const storedPage = getAtSession("paymentSubscription")?.page || 1;

        if (storedPage > maxPage || storedPage < 1) {
          setInSession("paymentSubscription", {
            ...getAtSession("paymentSubscription"),
            page: 1,
          });
          setCurrentPage(1);
        } else {
          setInSession("paymentSubscription", {
            ...getAtSession("paymentSubscription"),
            page: currentPage,
          });
        }
      }

      function onError(data) {
        setLoading(false);
      }
      getDataSource({ query: JSON.stringify(query) }, onSuccess, onError);
    };

    const timeout = setTimeout(() => {
      if (
        getAtSession("paymentSubscription") &&
        getAtSession("paymentSubscription")?.page > currentPage
      ) {
        setInSession("paymentSubscription", {
          ...getAtSession("paymentSubscription"),
          page: 1,
        });
        setCurrentPage(getAtSession("paymentSubscription")?.page);
      }

      fetchLiveTvData();
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [limit, currentPage, sort, search, date, getAgain]);

  return (
    <TableComponent
      header={
        <>
          <ButtonComponent icon={icons.ADD} title={t("add")} onClick={openDrawer} />
        </>
      }
      isLoading={loading}
      dataSource={dataSource}
      onChange={handleTableChange}
      columns={setupColumns()}
      localeClick={openDrawer}
      isEmpty={isEmpty}
      pagination={{
        page: currentPage,
        limit: limit,
        total: total,
      }}
      scroll={{
        x: 1400,
      }}
    />
  );
}
