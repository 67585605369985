import adsInstance from "../../api/instace";

const host = "https://ads.inorain.tv/";

const URL = {
  UPLOAD: `${host}file/upload`,
};

const uploadApi = {
  UPLOAD: (data) => {
    return adsInstance.post(URL.UPLOAD, data);
  },
};

export default uploadApi;
