import { Form, Input } from "antd";
import { useTranslation } from "react-i18next";

const SetPasswordForm = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Form.Item
        name="new_password"
        label={t("new_password")}
        rules={[
          {
            required: true,
            message: t("password_message"),
          },
        ]}
      >
        <Input.Password />
      </Form.Item>
      <Form.Item
        name="confirm_password"
        label={t("confirm_password")}
        rules={[
          {
            required: true,
            message: t("confirm_password_message"),
          },
        ]}
      >
        <Input.Password />
      </Form.Item>
    </div>
  );
};

export default SetPasswordForm;
