import { useState } from "react";
import {
  Button,
  Descriptions,
  Divider,
  Popconfirm,
  Space,
  Tooltip,
  Typography,
  notification,
} from "antd";
import { parseDateFormat } from "../../../../../config/config";

import styles from "../../user.module.scss";
import icons from "../../../../../config/icons";
import BalanceDrawer from "./BalanceDrawer";
import ButtonComponent from "../../../../../_components/button/ButtonComponent";
import UserDrawer from "../../../users/UserDrawer";
import REQUESTS from "../../../../../api/requests";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

export default function Biography({ user, getUser }) {
  const { t } = useTranslation();
  const { permissions } = useSelector((state) => state.globalState);

  const [balanceType, setBalanceType] = useState(null);
  const [isOpenEditDrawer, setIsOpenEditDrawer] = useState(false);

  const onLock = () => {
    REQUESTS.USERS.USERS.LOCK({ ids: user.id })

      .then((response) => {
        if (!response.error) {
          notification.success({
            message: t("success"),
            description: response.message,
          });
          getUser();
        }
      })
      .catch((error) => {
        notification.error({
          message: t("error"),
          description: error,
        });
      });
  };

  const onUlock = () => {
    REQUESTS.USERS.USERS.UNLOCK({ ids: user.id })
      .then((response) => {
        if (!response.error) {
          notification.success({
            message: t("success"),

            description: response.message,
          });

          getUser();
        }
      })
      .catch((error) => {
        notification.error({
          message: t("error"),

          description: error,
        });
      });
  };

  const onArchive = () => {
    REQUESTS.USERS.USERS.ARCHIVE({ ids: user.id })
      .then((response) => {
        if (response.error) {
          notification.error({
            message: t("error"),

            description: response.message,
          });
          return;
        }

        if (!response.error) {
          notification.success({
            message: t("success"),
            description: response.message,
          });

          getUser();
        }
      })
      .catch((error) => {
        notification.error({
          message: t("error"),

          description: error,
        });
      });
  };

  const onRestore = () => {
    REQUESTS.USERS.USERS.RESTORE({ ids: user.id })
      .then((response) => {
        if (response.error) {
          notification.error({
            message: t("error"),

            description: response.message.message,
          });
          return;
        }

        if (!response.error) {
          notification.success({
            message: t("success"),
            description: response.message,
          });

          getUser();
        }
      })
      .catch((error) => {
        notification.error({
          message: t("error"),

          description: error,
        });
      });
  };
  return (
    <>
      <Descriptions
        title={
          <>
            <Space
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <h3
                style={{
                  margin: 0,
                }}
              >
                {user.name} {user.surname}
              </h3>

              <ButtonComponent
                actionType="edit"
                title={t("edit")}
                onClick={() => setIsOpenEditDrawer(true)}
              />

              {user.status !== 0 ? (
                <Popconfirm
                  placement="topRight"
                  title={t("unlock_confirm_message")}
                  onConfirm={onUlock}
                  okText={t("unlock")}
                  cancelText="Cancel"
                >
                  <ButtonComponent actionType="unlock" title={t("unlock")} />
                </Popconfirm>
              ) : (
                <Popconfirm
                  placement="topRight"
                  title={t("lock_confirm_message")}
                  onConfirm={onLock}
                  okText={t("lock")}
                  cancelText={t("cancel")}
                >
                  <ButtonComponent actionType="lock" title={t("lock")} />
                </Popconfirm>
              )}

              {user.archive ? (
                <Popconfirm
                  placement="topRight"
                  title={t("restore_message")}
                  onConfirm={onRestore}
                  okText={t("restore")}
                  cancelText={t("cancel")}
                >
                  <ButtonComponent actionType="restore" title={t("restore")} />
                </Popconfirm>
              ) : (
                <Popconfirm
                  placement="topRight"
                  title={t("archive_confirm_message")}
                  onConfirm={onArchive}
                  okText={t("archive")}
                  cancelText={t("cancel")}
                >
                  <ButtonComponent actionType="archive" title={t("archive")} />
                </Popconfirm>
              )}
            </Space>

            <Divider />
          </>
        }
        column={4}
      >
        <Descriptions.Item label={t("name")}>{user.name}</Descriptions.Item>
        <Descriptions.Item label={t("surname")}>{user.surname}</Descriptions.Item>
        <Descriptions.Item label={t("birthday")}>
          {parseDateFormat(user.birthday)}
        </Descriptions.Item>
        <Descriptions.Item label={t("phone")}>{user.phone || "N/A"}</Descriptions.Item>
        <Descriptions.Item label={t("email")}>{user.email}</Descriptions.Item>
        <Descriptions.Item label={t("country")}>
          {user.country || "N/A"}
        </Descriptions.Item>
        <Descriptions.Item label={t("city")}>{user?.city || "N/A"}</Descriptions.Item>

        <Descriptions.Item label={t("parental_code")}>
          {user.parental_code}
        </Descriptions.Item>

        <Descriptions.Item label={t("register")}>
          {parseDateFormat(user.register_date)}
        </Descriptions.Item>
        <Descriptions.Item label={t("tariff")}>
          {user.tariff ? (
            <Space>
              <Space>
                {user.tariff.name}
                <div className={styles["tariff"]}>
                  (<span>{user.tariff.price}</span>
                  <span>{user.tariff.currency}</span>)
                </div>
              </Space>
            </Space>
          ) : (
            "N/A"
          )}
        </Descriptions.Item>
        {permissions.billing && (
          <Descriptions.Item label={t("expired_tariff")}>
            {user.expire_tariff ? parseDateFormat(user.expire_tariff) : "N/A"}
          </Descriptions.Item>
        )}
        <Descriptions.Item label={t("payment_type")}>
          {user.payment_type || "N/A"}
        </Descriptions.Item>
        <Descriptions.Item label={t("wallet_address")}>
          {user?.wallet_address || "N/A"}
        </Descriptions.Item>
        <Descriptions.Item label={t("subscription_id")}>
          <Typography.Paragraph
            ellipsis={true}
            style={{ margin: 0, width: 115, textAlign: "start" }}
          >
            {user.subscription_id || "N/A"}
          </Typography.Paragraph>
        </Descriptions.Item>

        {permissions?.billing && !user?.is_guest && (
          <Descriptions.Item label={t("balance")}>
            {" "}
            <Space className={styles["balance"]}>
              {user.balance}
              <Button
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                type="primary"
                icon={icons.ADD}
                size={"small"}
                onClick={() => setBalanceType("Add")}
              />
              <Button
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                type="primary"
                danger
                icon={icons.MINUS}
                size={"small"}
                onClick={() => setBalanceType("Minus")}
              />
            </Space>
          </Descriptions.Item>
        )}
        {/* <Descriptions.Item label="POINT">{user.point || "N/A"}</Descriptions.Item>
        <Descriptions.Item label="SPAY">{user.spay || "N/A"}</Descriptions.Item>
        <Descriptions.Item label="SCPT">{user.scpt || "N/A"}</Descriptions.Item> */}
        <Divider />
      </Descriptions>
      <BalanceDrawer
        userId={user?.id}
        type={balanceType}
        open={balanceType ? true : false}
        onClose={() => setBalanceType(null)}
        getData={getUser}
      />
      <UserDrawer
        open={isOpenEditDrawer}
        editable={user}
        getData={getUser}
        onClose={() => {
          setIsOpenEditDrawer(false);
        }}
      />
      {/* <Descriptions column={3}>
        <Descriptions.Item label="POINT">{user.point || "N/A"}</Descriptions.Item>
        <Descriptions.Item label="SPAY">{user.spay || "N/A"}</Descriptions.Item>
        <Descriptions.Item label="SCPT">{user.scpt || "N/A"}</Descriptions.Item>
      </Descriptions> */}
    </>
  );
}
