import { notification } from "antd";
import { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";

import { EditorState, ContentState, convertToRaw, convertFromHTML } from "draft-js";

import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import REQUESTS from "../../../../api/requests";

import ButtonComponent from "../../../../_components/ButtonComponent";
import { useTranslation } from "react-i18next";

export default function DocumentEditor({ item }) {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);
  const [editorState, setEditorState] = useState(() => {
    const contentState = ContentState.createFromBlockArray(convertFromHTML(""));
    return EditorState.createWithContent(contentState);
  });

  const customToolbar = {
    options: [
      "inline",
      "blockType",
      "list",
      "textAlign",
      "fontFamily",
      "fontSize",
      "remove",
      "history",
    ],
    inline: {
      options: ["bold", "italic", "underline"],
    },
    blockType: {
      options: ["Normal", "H1", "H2", "H3", "h4", "h5", "h6", "Blockquote"],
    },
  };

  const handleEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  const convertToHtml = () => {
    const contentState = editorState.getCurrentContent();
    const html = draftToHtml(convertToRaw(contentState));
    return html;
  };

  const onSave = () => {
    setIsLoading(true);
    REQUESTS.SETTINGS.DOCUMENTS.EDIT({
      id: item.id,
      text: convertToHtml(),
    })
      .then((res) => {
        setIsLoading(false);

        if (res.error) {
          notification.error({
            message: t("error"),
            description: res.message,
          });
          return;
        }

        notification.success({
          message: t("success"),
          description: t("document_update_message"),
        });
      })
      .catch((err) => {
        setIsLoading(false);

        notification.error({
          message: t("error"),
          description: err,
        });
      });
  };

  useEffect(() => {
    if (item?.text) {
      const contentState = ContentState.createFromBlockArray(convertFromHTML(item.text));
      setEditorState(EditorState.createWithContent(contentState));
    } else {
      const contentState = ContentState.createFromBlockArray(convertFromHTML(""));
      setEditorState(EditorState.createWithContent(contentState));
    }
  }, [item]);

  return (
    <div className="document-editor">
      <p style={{ fontSize: 18, fontWeight: "bold", marginBottom: 20 }}>{item?.name}</p>

      <Editor
        toolbar={customToolbar}
        editorState={editorState}
        onEditorStateChange={handleEditorStateChange}
        editorStyle={{
          border: "1px solid #f1f1f1",
          padding: 10,
        }}
      />

      <ButtonComponent
        title={t("save")}
        actionType="save"
        isLoading={isLoading}
        onClick={onSave}
        style={{ marginTop: 20 }}
      />
    </div>
  );
}
