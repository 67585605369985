import { appHost } from "../../../helpers/appHost";
import request from "../../request";

const host = appHost;

const URL = {
  HOOKS: `${host}admin/hooks`,
};

export default {
  GET: (query) => request("GET", `${URL.HOOKS}`, query),

  ADD: (query) => request("POST", `${URL.HOOKS}`, query),

  EDIT: (query) => request("PUT", `${URL.HOOKS}`, query),

  DELETE: (query) => request("DELETE", `${URL.HOOKS}`, query),
};
