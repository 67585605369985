import adsInstance from "../api/instace";

const host = "https://ads.inorain.tv/";

const URL = {
  GETTER: `${host}admin/filters/getter`,
  GET: `${host}admin/filters`,

  FILTER: `${host}admin/filters`,

  COUNTRIES: `${host}admin/countries`,
};

const filterApi = {
  GETTER: (query) => adsInstance.get(URL.GETTER, { params: query }),
  GET: (query) => adsInstance.get(URL.GET, { params: query }),
  GET_COUNTRIES: () => adsInstance.get(URL.COUNTRIES),
  ADD: (query) => adsInstance.post(URL.FILTER, query),
  EDIT: (query, id) => adsInstance.put(`${URL.FILTER}/${id}`, query),
  DELETE: (query) => adsInstance.delete(`${URL.FILTER}/many`, { data: { ids: query } }),
};

export default filterApi;
