import React from 'react';

export default function TableLoadingComponent() {
    return (
        <tr>
            <td colSpan={30} style={{ paddingLeft: 0, paddingRight: 0 }}>
                <div className="loading-bg">
                    <div className="table-loading" />
                    <div className="table-loading" />
                    <div className="table-loading" />
                    <div className="table-loading" />
                    <div className="table-loading" />
                    <div className="table-loading" />
                    <div className="table-loading" />
                    <div className="table-loading" />
                    <div className="table-loading" />
                    <div className="table-loading" />
                </div>
            </td>
        </tr>
    )
}
