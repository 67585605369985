const tablesInitialValues = {
    keys: {
        medias: "medias",
        vod_transcoding: "vod_transcoding",
    },

    get: (key) => {
        if (localStorage.getItem(key) === null) {
            return {};
        } else {
            return JSON.parse(localStorage.getItem(key));
        }
    },

    set: (key, value) => {
        localStorage.setItem(key, JSON.stringify(value));
    },
};

export default tablesInitialValues;
