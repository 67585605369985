import { useEffect } from "react";
import { useSelector } from "react-redux";

import { Button, Checkbox, Form, Input, InputNumber, Radio, Select, Tag } from "antd";

import {
  useArchiveServersOptions,
  useEpgTypesOptions,
} from "../../../../hooks/selectOptions";

import NextBackButton from "../../../../_components/button/NextBackButton";

import SearchEpg from "./SearchEpg";

import styles from "../styles/drawer.module.scss";
import { useTranslation } from "react-i18next";

export default function CatchUpStep({ form, setStep, loading, editable }) {
  const { t } = useTranslation();

  const { permissions } = useSelector((state) => state.globalState);

  const epgTypesOptions = useEpgTypesOptions();

  const archiveServersOptions = useArchiveServersOptions();

  const onCheckHasCetchUp = (event) => {
    const checked = event.target.checked;
    if (!checked) {
      form.setFields([
        { name: "archive_day", value: undefined },
        { name: "archive_server", value: undefined },
        { name: "archive_url", value: undefined },
      ]);
    }
  };

  return (
    <div>
      {/* <Form.Item label="Epg type" name="epg_type" style={{ height: 50 }}>
        <Radio.Group buttonStyle="solid">
          {epgTypesOptions.map((item) => (
            <Radio key={item.value} value={item.value}>
              {item.name}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item> */}

      <Form.Item shouldUpdate noStyle>
        {() => {
          const { epg_type, id_epg } = form.getFieldsValue();

          return (
            <Form.Item label={t("epg")} name="id_epg">
              <SearchEpg form={form} epg_type={epg_type} defaultValue={id_epg} />
            </Form.Item>
          );
        }}
      </Form.Item>

      <div className={styles["catch-up-block"]}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Form.Item name="has_archive" noStyle valuePropName="checked">
            <Checkbox
              style={{ marginBottom: 10 }}
              onChange={onCheckHasCetchUp}
              disabled={!permissions?.Archive}
            >
              {t("has_catchup")}
            </Checkbox>
          </Form.Item>
          <Form.Item shouldUpdate noStyle>
            {() => {
              const { has_archive } = form.getFieldsValue();

              return (
                <Form.Item name="archived_channel_type" noStyle>
                  <Radio.Group
                    disabled={!has_archive}
                    name="radiogroup"
                    defaultValue={"inorain"}
                  >
                    <Radio value="inorain">{t("default")}</Radio>
                    <Radio value="flussonic">{t("flusonic")}</Radio>
                  </Radio.Group>
                </Form.Item>
              );
            }}
          </Form.Item>
        </div>

        <div style={{ display: "flex", gap: 10 }}>
          <Form.Item shouldUpdate noStyle>
            {() => {
              const { has_archive } = form.getFieldsValue();
              return (
                <Form.Item
                  label={t("day")}
                  name="archive_day"
                  style={{ width: "30%" }}
                  rules={[
                    {
                      required: has_archive ? true : false,
                      message: t("day_message"),
                    },
                  ]}
                >
                  <InputNumber
                    min={1}
                    disabled={!has_archive}
                    style={{ width: "100%" }}
                    controls={false}
                  />
                </Form.Item>
              );
            }}
          </Form.Item>

          <Form.Item shouldUpdate noStyle>
            {() => {
              const { has_archive, archived_channel_type } = form.getFieldsValue();
              let disabled = true;

              if (has_archive && archived_channel_type === "inorain") {
                disabled = false;
              }

              return (
                <Form.Item
                  label={t("servers")}
                  name="archive_server"
                  style={{ width: "70%" }}
                  rules={[
                    {
                      required: !disabled ? true : false,
                      message: t("server_message"),
                    },
                  ]}
                >
                  <Select disabled={disabled} options={archiveServersOptions} />
                </Form.Item>
              );
            }}
          </Form.Item>
        </div>

        <Form.Item shouldUpdate noStyle>
          {() => {
            const { has_archive } = form.getFieldsValue();
            return (
              <Form.Item
                label={t("url")}
                name="archive_url"
                rules={[
                  {
                    required: has_archive ? true : false,
                    message: t("url_message"),
                  },
                ]}
              >
                <Input disabled={!has_archive} />
              </Form.Item>
            );
          }}
        </Form.Item>

        {!permissions?.Archive && (
          <div className={styles["catch-up-is-disibled"]}>{t("catchup_disabled")}</div>
        )}
      </div>

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <NextBackButton type="back" onClick={() => setStep("3")} />

        <Button loading={loading} type="primary" onClick={() => form.submit()}>
          {t("finish")}
        </Button>
      </div>
    </div>
  );
}
