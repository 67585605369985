import { Flex } from "antd";
import CanceledChart from "./charts/CanceledChart";
import ExplicitCHart from "./charts/ExplicitCHart";
import CountryChart from "./charts/CountryChart";

const AddStatisticPage = () => {
  return (
    <div style={{ width: "100%" }}>
      <Flex wrap="wrap" gap={15}>
        <ExplicitCHart />
        <CanceledChart />
        <CountryChart />
        {/* <CountryChart /> */}
      </Flex>
    </div>
  );
};

export default AddStatisticPage;
