

import { Input, Switch } from "antd";
import { useEffect, useState } from "react";
import REQUESTS from "../../../api/requests";
import { useTranslation } from "react-i18next";

export default function Resolutions({
  editable,
  setSelectedResolutions,
  isChecked,
  defaultChecked,
}) {
  const { t } = useTranslation();
  const [resolutions, setResolutions] = useState([]);
  const [isSort, setIsSort] = useState(defaultChecked);

  const getResolutions = () => {
    REQUESTS.TRANSCODERS.RESOLUTIONS.GET().then((response) => {
      if (response.error) return;

      const list = response.message?.rows.map((item) => ({
        id: item.id,
        input: "",
        name: item.name,
        output: "",
      }));

      if (editable) {
        const editableResolutions = editable.resolutions || [];
        list.forEach((item) => {
          const match = editableResolutions.find(
            (editableItem) => editableItem.id === item.id
          );
          if (match) {
            item.input = match.info.input;
            item.output = match.info.output;
          }
        });
      }

      setResolutions(list);
    });
  };

  const onChangeResolutions = (e, name) => {
    const { value } = e.target;
    setResolutions((prevResolutions) =>
      prevResolutions.map((item) =>
        item.name === name ? { ...item, input: value } : item
      )
    );
  };

  const handleSwitch = (checked) => {
    setIsSort(checked);
    isChecked(checked);
  };

  useEffect(() => {
    if (resolutions.length > 0) {
      setResolutions((prevResolutions) =>
        [...prevResolutions].sort((a, b) => {
          const [aWidth, aHeight] = a.name
            .match(/\d+x\d+/)[0]
            .split("x")
            .map(Number);
          const [bWidth, bHeight] = b.name
            .match(/\d+x\d+/)[0]
            .split("x")
            .map(Number);

          return isSort
            ? bWidth === aWidth
              ? bHeight - aHeight
              : bWidth - aWidth
            : aWidth === bWidth
            ? aHeight - bHeight
            : aWidth - bWidth;
        })
      );
    }
  }, [isSort, resolutions]);

  useEffect(() => {
    getResolutions();
  }, []);

  useEffect(() => {
    const list = resolutions.filter((item) => item.input !== "");
    setSelectedResolutions(list);
  }, [resolutions]);

  return (
    <div style={{ marginBottom: 20 }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          gap: 10,
          marginTop: 10,
        }}
      >
        <span>{t("resolution")}</span>
        <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
          <span>{t("sort_resolutions")}</span>
          <Switch
            checkedChildren={t("on")}
            unCheckedChildren={t("off")}
            checked={isSort}
            onChange={handleSwitch}
          />
        </div>
      </div>

      {resolutions?.map((resolution) => (
        <div style={{ display: "flex", gap: 10, marginTop: 10 }} key={resolution.id}>
          <Input
            value={resolution.name}
            readOnly
            style={{ color: "gray", background: "#8080801c" }}
          />
          <Input
            placeholder={t("input")}
            value={resolution.input}
            onChange={(e) => onChangeResolutions(e, resolution.name)}
          />
          <Input
            placeholder={t("output")}
            value={resolution.output}
            readOnly={true}
            style={{ color: "gray", background: "#8080801c" }}
          />
        </div>
      ))}
    </div>
  );
}
