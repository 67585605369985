import request from "../../api/request";
import { appHost } from "../../helpers/appHost";

const host = appHost;

const URL = {
  GET_PRICING: `${host}admin/cloud_service/api`,
};

const pricingApi = {
  GET: (query, host) => request("GET", `${host}/package/getter`, query),

  UPGRADE: (query, host) => request("POST", `${host}/users/upgrade`, query),
};

export default pricingApi;
