import request from "../../api/request";
import { appHost } from "../../helpers/appHost";

const host = appHost;

const URL = {
  PROMO_CODES: `${host}v2/admin/promo_code`,
};

const promoCodeApi = {
  GET: (query) => request("GET", URL.PROMO_CODES, query),

  GET_LOGO: (query) => request("GET", `${URL.PROMO_CODES}/logo`, query),

  GET_GIFT_CARD: (query) => request("GET", `${URL.PROMO_CODES}/cart/${query}`, query),

  ADD: (query) => request("POST", URL.PROMO_CODES, query),

  EDIT: (id, query) => request("PUT", `${URL.PROMO_CODES}/${id}`, query),

  DELETE: (query) => request("DELETE", `${URL.PROMO_CODES}`, query),
};

export default promoCodeApi;
