import Hls from "hls.js";
import ReactHlsPlayer from "react-hls-player";
import React, { useEffect, useState, memo } from "react";

import PlayerEpgList from "./PlayerEpgList";
import InputComponent from "./InputComponent";
import SelectComponent from "./SelectComponent";

import MultiRangeSlider from "../components/MultiRangeSlider";
import { generateUrl } from "../config/config";
import ButtonComponent from "../_components/button/ButtonComponent";
import icons from "../config/icons";
import { Input } from "antd";

export default function VideoComponent({
  fullInfo,
  channel,
  url,
  onClose = () => {},
  showRang = false,
  saveSkeepOption,
  introStartTime,
  introEndTime,
  creditsTime,
  originalAudioLanguage,
  useToken,
}) {
  const [currentUrl, setCurrentUrl] = useState("");
  const [audioTracks, setAudioTracks] = useState([]);

  const [currentTimes, setCurrentTimes] = useState(0);

  const [start, setStart] = useState(introStartTime);
  const [end, setEnd] = useState(introEndTime);
  const [credits, setCredits] = useState(creditsTime);
  const [duration, setDuration] = useState(0);
  const [originalAudioTrack, setOriginalAudioTrack] = useState(null);
  const [showTimePositions, setShowTimePositions] = useState(false);

  const [error, setError] = useState({
    start: "",
    end: "",
    credit: "",
  });

  const escFunction = (event) => {
    if (event.keyCode === 27) {
      onClose();
    }
  };

  const onSave = () => {
    const body = {
      start,
      end,
      credits,
      duration,
      originalAudioTrack,
    };

    saveSkeepOption(body);
    onClose();
  };

  useEffect(() => {
    if (fullInfo) {
      setCurrentUrl(channel.url);
    } else {
      setCurrentUrl(generateUrl(url, useToken));
    }

    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  let sectionStyles = {};
  let rowStyle = { display: "flex" };

  if (fullInfo || showRang) {
    sectionStyles = {
      width: "70%",
      padding: 20,
      display: "flex",
      justifyContent: "space-between",
      // maxHeight: "70vh",
      // height: "auto",
    };

    rowStyle = {
      width: "100%",
      display: "flex",
      justifyContent: "center",
    };
  }

  const onChange = (type, value) => {
    value = Number(value);

    switch (type) {
      case "start":
        if (value > end) {
          // inoToast.error("Intro start time can't be big from Intro end time");
          setError({
            ...error,
            start: "Intro start time can't be big from Intro end time",
          });

          return;
        }
        setError({
          ...error,
          start: "",
        });
        setStart(value);

        break;

      case "end":
        if (value > credits) {
          // inoToast.error("Intro end time can't be big from Credits time");
          setError({
            ...error,
            end: "Intro end time can't be big from Credits time",
          });

          return;
        }

        if (value < start) {
          // inoToast.error("Intro end time can't be less from Intro start time");

          setError({
            ...error,
            end: "Intro end time can't be less from Intro start time",
          });
          return;
        }
        setError({
          ...error,
          end: "",
        });
        setEnd(value);

        break;

      case "credits":
        if (value < end) {
          // inoToast.error("Credits time can't be less from Intro end time");
          setError({
            ...error,
            credit: "Credits time can't be less from Intro end time",
          });

          return;
        }
        setError({
          ...error,
          credit: "",
        });
        setCredits(value);

        break;

      default:
        break;
    }
  };

  const onPluse = (key) => {
    console.log(key);
    const time = currentTimes;
    let value = Math.floor(time);
    switch (key) {
      case "start":
        onChange("start", value);
        break;

      case "end":
        onChange("end", value);
        break;

      case "credits":
        onChange("credits", value);
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    try {
      if (currentUrl) {
        var _hls = new Hls();

        let list = [];

        var video = document.getElementById("hls_video");

        _hls?.attachMedia(video);
        _hls?.loadSource(currentUrl || "");

        _hls?.on(Hls.Events.AUDIO_TRACKS_UPDATED, function (e, data) {
          list = data?.audioTracks.map((item) => {
            return {
              id: item.id,
              value: item.id,
              label: item.name,
            };
          });

          setAudioTracks(list);
        });

        _hls?.on(Hls.Events.AUDIO_TRACK_LOADING, function (e, data) {
          if (originalAudioLanguage) {
            console.log(originalAudioLanguage);
            const finded = list.find((item) => item.label === originalAudioLanguage);

            setOriginalAudioTrack(finded);
          } else {
            const finded = list.find((item) => item?.id === data.id);

            setOriginalAudioTrack(finded);
          }
        });
      }
    } catch (e) {
      console.log(e);
    }

    return () => {
      _hls?.destroy();
    };
  }, [currentUrl]);

  useEffect(() => {
    const vid = document.getElementsByTagName("video")[0];

    let paused = false;

    function getTimeupdate(e) {
      paused = false;
      setCurrentTimes(Math.floor(Number(e.target.currentTime)));
      setDuration(Math.floor(Number(e?.target?.duration) / 60));
    }

    function getSeeked(e) {
      setCurrentTimes(Math.floor(Number(e.target.currentTime)));
    }

    function getPause(e) {
      paused = true;
    }

    function mouseover(e) {
      setShowTimePositions(true);
    }

    function mouseleave(e) {
      if (!paused) {
        setShowTimePositions(false);
      } else {
        setShowTimePositions(true);
      }
    }

    if (url) {
      vid.addEventListener("timeupdate", getTimeupdate);
      vid.addEventListener("seeked", getSeeked);
      vid.addEventListener("pause", getPause);
      vid.addEventListener("mouseover", mouseover);
      vid.addEventListener("mouseleave", mouseleave);
    } else {
      console.log("else");
      setTimeout(function () {
        vid.removeEventListener("timeupdate", getTimeupdate);
        vid.removeEventListener("seeked", getSeeked);
        vid.removeEventListener("pause", getPause);
        vid.removeEventListener("mouseover", mouseover);
        vid.removeEventListener("mouseleave", mouseleave);
      }, 3000);
    }
  }, [url]);

  return (
    <div className="video-popup">
      <div className="video-popup-empty" onClick={onClose} />
      <div
        style={{
          ...sectionStyles,
          background: fullInfo || showRang ? "white" : "transparent",
        }}
        className="video-section"
      >
        <div className="close-video" onClick={onClose}>
          <i className="fas fa-times-circle" />
        </div>

        <div style={rowStyle}>
          <div
            className={fullInfo || showRang ? "col-md-8" : "col-md-12"}
            style={{ padding: 0 }}
            onMouseEnter={() => setShowTimePositions(true)}
            onMouseLeave={() => setShowTimePositions(false)}
          >
            <Player url={currentUrl} token={useToken} />
            {showRang && (
              <MultiRangeSlider
                start={start}
                end={end}
                credits={credits}
                duration={duration}
                style={{
                  opacity: showTimePositions ? 1 : 0,
                  transition: "0.2s",
                }}
              />
            )}
          </div>

          <div className="col-md-4">
            {fullInfo && (
              <PlayerEpgList
                channel={channel}
                onChange={(url) => {
                  setCurrentUrl(url);
                }}
              />
            )}
            {showRang && (
              <>
                <div className="intro-times-inps" style={{ marginBottom: 10 }}>
                  <div
                    style={{
                      textAlign: "start",
                      width: 150,
                    }}
                  >
                    Duration(m):
                  </div>

                  <div style={{ width: 100 }}>{duration > 0 && duration}</div>

                  <div style={{ width: 40 }}></div>
                </div>

                <div className="intro-times-inps">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <span>Intro start time(s):</span>
                    <div
                      style={{
                        width: "100%",
                        display: "flex",
                        alignItems: "baseline",
                        gap: 8,
                      }}
                    >
                      <InputComponent
                        value={start}
                        type="number"
                        name="start"
                        onError={error.start}
                        onChange={(e) => {
                          onChange("start", e.target.value);
                        }}
                      />
                      <ButtonComponent
                        onClick={() => onPluse("start")}
                        icon={icons.ADD}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="intro-times-inps">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <span>Intro end time(s):</span>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "baseline",
                        gap: 8,
                      }}
                    >
                      <InputComponent
                        value={end}
                        type="number"
                        name="end"
                        onError={error.end}
                        onChange={(e) => {
                          onChange("end", e.target.value);
                        }}
                      />
                      <ButtonComponent
                        onClick={() => onPluse("end")}
                        icon={icons.ADD}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="intro-times-inps">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                    }}
                  >
                    <span>Credits time(s):</span>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "baseline",
                        gap: 8,
                      }}
                    >
                      <InputComponent
                        value={credits}
                        type="number"
                        name="credits"
                        onError={error.credit}
                        onChange={(e) => {
                          onChange("credits", e.target.value);
                        }}
                      />
                      <ButtonComponent
                        onClick={() => onPluse("credits")}
                        icon={icons.ADD}
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      />
                    </div>
                  </div>
                  {/* <ButtonComponent
                    onClick={() => onPluse("credits")}
                    icon={icons.ADD}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  /> */}
                </div>
                {originalAudioLanguage && (
                  <SelectComponent
                    label="Original audio language"
                    value={originalAudioTrack}
                    options={audioTracks}
                    change={setOriginalAudioTrack}
                  />
                )}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "flex-end",
                    marginTop: 20,
                  }}
                >
                  <ButtonComponent onClick={onSave} title="Save" />
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

const Player = memo(({ url, token }) => {
  const _url = generateUrl(url, token);
  let test_video = document.getElementById("test-video");

  if (test_video && test_video.canPlayType("application/vnd.apple.mpegurl")) {
    return <video src={_url} autoPlay controls width="100%" height="auto"></video>;
  } else {
    return (
      <ReactHlsPlayer
        url={_url}
        autoplay={true}
        controls={true}
        width="100%"
        height="auto"
      />
    );
  }
});
