import { Button, Form, Input, InputNumber } from "antd";

import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { useTranslation } from "react-i18next";

export default function Answers() {
  const { t } = useTranslation();
  return (
    <Form.Item label={t("answer")} shouldUpdate>
      {() => {
        return (
          <Form.List name="answers">
            {(fields, { add, remove }) => {
              return (
                <>
                  {fields.map(({ key, name, ...restField }) => {
                    return (
                      <div
                        key={key}
                        style={{
                          display: "flex",
                          alignItems: "start",
                          justifyContent: "space-between",
                          gap: 10,
                        }}
                        align="baseline"
                      >
                        <Form.Item
                          {...restField}
                          name={[name, "content"]}
                          rules={[
                            {
                              required: true,
                              message: t("content_message"),
                            },
                          ]}
                          style={{ width: "70%" }}
                        >
                          <Input placeholder={t("enter_content")} />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, "unit"]}
                          rules={[
                            {
                              required: true,
                              message: t("unit_message"),
                            },
                          ]}
                          style={{ width: "20%" }}
                        >
                          <InputNumber min={0} placeholder={t("unit")} />
                        </Form.Item>

                        <div style={{ width: "10%" }} disabled={true}>
                          <Button
                            icon={<MinusCircleOutlined />}
                            onClick={() => remove(name)}
                            // disabled={key == 0 ? true : false}
                          />
                        </div>
                      </div>
                    );
                  })}
                  <Form.Item>
                    <Button
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      {t("add_answer")}
                    </Button>
                  </Form.Item>
                </>
              );
            }}
          </Form.List>
        );
      }}
    </Form.Item>
  );
}
