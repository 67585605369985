import React from "react";
import LazyLoad from "react-lazyload";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import ProgressBar from "react-bootstrap/ProgressBar";
import "react-datepicker/dist/react-datepicker.css";

import requests, {
  rGetChannelsQount,
  requestGetDeviceCount,
  rGetMostViewedChannels,
  rGetUserByTariff,
  rGetUserCount,
  requestGetStreamServersList,
  requestGetTranscodingServer,
} from "../../server/requests";
import { getLimit, converterMSToYMD } from "../../helpers/formats";
import Totals from "./Totals";
import NowWatching from "./NowWatching";
import MostWatchedList from "./MostWatchedList";
import MostWatchedMovie from "./MostWatchedMovie";

import MiniServer from "../../components/MiniServer";
import CardOfDevice from "../../components/CardOfDevice";
import PageComponent from "../../components/PageComponent";
import ChartComponent from "../../components/ChartComponent";
import SelectComponent from "../../components/SelectComponent";
import DatePickerComponent from "../../components/DatePickerComponent";
import ButtonComponent from "../../components/ButtonComponent";
import { TableLoading } from "../../components/Loadings";
import ShortTxt from "../../components/ShortTxt";
import defaultImage from "../../img/photo.png";
import StatisticCard from "./StatisticCard";
import CountChart from "./charts/CountChart";

class DashboardContent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      totalChannelCount: 0,
      totalUserCount: 0,
      tariffLength: 0,
      mounthlyPayment: 0,
      isOnline: 0,
      mostViewedChannels: [],
      tariffChartData: {},
      usersChartData: {},
      channelsChartData: {},
      chartData: {},
      mostViewedChannel: { image: "", views: 0 },
      selectedLimit: getLimit(),
      listOfArchive: [],
      listOfStreams: [],
      listOfTranscoding: [],
      countOfDevices: [],
      startDate: new Date().setDate(new Date().getDate() - 30),
      endDate: new Date(),
      showLoading: false,
      allViewCount: 0,
      permissionMenu: null,
    };
  }

  getListOfArchive = () => {
    requests.archive.get((data) => {
      this.setState({ listOfArchive: data.rows });
    });
  };

  getListOfStreams = () => {
    requestGetStreamServersList((data) => {
      this.setState({ listOfStreams: data });
    });
  };

  getListOfTranscoding = () => {
    requestGetTranscodingServer((data) => {
      this.setState({ listOfTranscoding: data });
    });
  };

  getPayment = () => {
    let body = {
      from: new Date().getFullYear() + "-01-01 00:00:00",
      to: new Date().getFullYear() + "-12-31 23:59:59",
    };

    requests.payments.history(body, (data) => {
      let chartData = data.chartData;
      this.setState({
        chartData,
      });
    });
  };
  getOfDevicesTheCount = () => {
    requestGetDeviceCount((data) => {
      this.setState({ countOfDevices: data });
    });
  };
  handleChangeStartDate = (date) => this.setState({ startDate: date });
  handleChangeEndDate = (date) => this.setState({ endDate: date });

  mostViewedChannels = () => {
    const { selectedLimit, startDate, endDate } = this.state;
    let fromMonth = startDate === "" ? "" : converterMSToYMD(startDate);
    let toMonth = endDate === "" ? "" : converterMSToYMD(endDate);

    this.setState({ showLoading: true });

    let body = {
      limit: selectedLimit.value,
      from: fromMonth,
      to: toMonth,
    };
    for (let key in body) {
      if (body[key] === "") {
        delete body[key];
      }
    }
    rGetMostViewedChannels(body, (data) => {
      let allView = 0;
      for (let i = 0; i < data.length; i++) {
        allView += data[i].views_count;
      }
      const sortingData = data.sort((a, b) => b.views_count - a.views_count);
      this.setState({
        mostViewedChannels: sortingData,
        allViewCount: allView,
        showLoading: false,
      });
    });
    // rGetMostViewedChannels(body, (data) => {
    //   let channels = data || [];
    //   let mostViewedChannels = [];

    //   for (let i = 0; i < channels.length; i++) {
    //     if (channels[i].channel) {
    //       channels[i].channel.view = channels[i].views;
    //       mostViewedChannels.push(channels[i].channel);
    //     }
    //   }
    //   let mostViewedChannel = channels[0] || {};

    //   if (channels[0]) {
    //     mostViewedChannel = {
    //       views: channels[0].views,
    //       image: channels[0].channel.image,
    //     };
    //   }
    //   this.setState({ showLoading: false });
    //   this.setState({
    //     mostViewedChannel,
    //     mostViewedChannels,
    //     userChartData: data.chartData,
    //   });
    // });
  };

  selectLimit = (selected) => {
    this.setState({ selectedLimit: selected }, this.mostViewedChannels);
  };

  componentDidMount = () => {
    this.getListOfArchive();
    this.getListOfStreams();
    this.getListOfTranscoding();
    this.getOfDevicesTheCount();
    this.mostViewedChannels();

    this.getPayment();
    requests.payments.monthly((data) => {
      this.setState({ mounthlyPayment: data[0].total || 0 });
    });

    rGetChannelsQount((data) => {
      this.setState({
        totalChannelCount: data.total,
        channelsChartData: data.pieData,
      });
    });

    rGetUserByTariff((data) => {
      let users = [];
      for (let i = 0; i < data.tariff.length; i++) {
        users.push({
          name: data.tariff[i].name,
          count: data.tariff[i].users.length,
        });
      }

      this.setState({
        tariffChartData: data.polarData,
        tariffLength: data.tariff.length,
      });
    });

    rGetUserCount((data) => {
      this.setState({
        totalUserCount: data.total,
        usersChartData: data.chartData,
        isOnline: data.online,
      });
    });

    requests.permission_menu({}, (data) => {
      this.setState({ permissionMenu: data });
    });
  };
  clearFields = () => {
    this.setState(
      {
        startDate: "",
        endDate: "",
      },
      () => {
        this.mostViewedChannels();
      }
    );
  };
  render() {
    const {
      mostViewedChannel,
      usersChartData,
      channelsChartData,
      mostViewedChannels,
      allViewCount,
      chartData,
      tariffChartData,
      totalChannelCount,
      tariffLength,
      totalUserCount,
      mounthlyPayment,
      isOnline,
      listOfArchive,
      listOfStreams,
      listOfTranscoding,
      countOfDevices,
      selectedLimit,
      startDate,
      endDate,
      showLoading,
      permissionMenu,
    } = this.state;

    let limit = selectedLimit.value;

    let limitOptions = [
      { label: 10, value: 10 },
      { label: 20, value: 20 },
      { label: 50, value: 50 },
      { label: 100, value: 100 },
    ];

    let colors = [
      "#FF6384",
      "#4BC0C0",
      "#FFCE56",
      "#123f99",
      "#36A2EB",
      "#248800",
      "#34c1ce",
      "#4810af",
      "#bb2cae",
      "#6f0948",
      "#7fcb29",
      "#87205a",
      "#aa5a11",
      "#573a90",
      "#dc0949",
    ];

    let topCards = [
      {
        path: "/dashboard/live-tv",
        routeId: "channelsId",
        icon: "fas fa-tv",
        name: "Live TV",
        count: totalChannelCount,
      },
      {
        path: "/dashboard/tariff",
        routeId: "tariffId",
        icon: "fas fa-dollar-sign",
        name: "Tariffs",
        count: tariffLength,
      },
      {
        path: "/dashboard/users",
        routeId: "usersId",
        icon: "fas fa-users",
        name: "Users",
        count: totalUserCount,
      },
      {
        path: "/dashboard/payment-history",
        routeId: "payemntHistoryId",
        icon: "fas fa-money-bill-alt",
        name: "Payments",
        count: Number.parseFloat(mounthlyPayment).toFixed(2),
      },
      {
        path: "/dashboard/users",
        routeId: "onlineId",
        icon: "fa fa-regular fa-user",
        name: "Online",
        count: isOnline,
      },
    ];

    let chartsList = [
      {
        type: "line",
        data: chartData,
        title: new Date().getFullYear() + " payments",
        icon: "fas fa-money-bill-alt",
      },

      {
        type: "polar",
        data: tariffChartData,
        title: "Most used tariffs",
        icon: "fas fa-dollar-sign",
      },

      {
        type: "polar",
        data: usersChartData,
        title: "Users",
        icon: "fas fa-users",
      },

      {
        type: "polar",
        data: channelsChartData,
        title: "Live TV",
        icon: "fas fa-tv",
      },
    ];

    if (!permissionMenu?.LIVE_TV) {
      topCards.splice(0, 1);
      chartsList.splice(chartsList?.length - 1, 1);
    }
    return (
      <PageComponent fluid>
        <Tabs>
          <TabList>
            <Tab> {permissionMenu?.LIVE_TV ? "Live TV" : "Analytics"}</Tab>
            {permissionMenu?.Vod_Manager && <Tab>Movies</Tab>}
          </TabList>

          <TabPanel>
            <div className="dashboard-cards">
              {topCards.map((item, index) => {
                return <StatisticCard key={index} item={item} />;
              })}
            </div>

            {permissionMenu?.LIVE_TV && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 20,
                  marginTop: 30,
                }}
              >
                <CountChart infoType="views_per_movie" />
                <CountChart infoType="views_duration_movie" />
              </div>
            )}

            <div className="charts-section" style={{ marginTop: "30px" }}>
              {chartsList.map((item, index) => {
                return <ChartComponent key={index} chart={item} />;
              })}
            </div>

            <div className="m-t-20">
              {listOfArchive?.length > 0 && (
                <>
                  <h3 className="mini-header">Archive servers</h3>
                  <div className="mini-blocks-section">
                    {listOfArchive?.map((item, key) => {
                      return <MiniServer key={key} type="archive" server={item} />;
                    })}
                  </div>
                </>
              )}
            </div>

            <div className="m-t-20">
              {listOfStreams.length > 0 && (
                <>
                  <h3 className="mini-header">Stream servers</h3>
                  <div className="mini-blocks-section">
                    {listOfStreams.map((item, key) => {
                      return <MiniServer key={key} type="stream" server={item} />;
                    })}
                  </div>
                </>
              )}
            </div>

            <div className="m-t-20">
              {listOfTranscoding.length > 0 && (
                <>
                  <h3 className="mini-header">Transcoding servers</h3>
                  <div className="mini-blocks-section">
                    {listOfTranscoding.map((item, key) => {
                      return <MiniServer key={key} type="transcoding" server={item} />;
                    })}
                  </div>
                </>
              )}
            </div>

            <h3 className="m-t-20">Devices</h3>

            <div className="row m-t-20">
              {countOfDevices.map((item, key) => {
                return (
                  <div className="col-md-3 m-b-10" key={key}>
                    <CardOfDevice device={item} />
                  </div>
                );
              })}
            </div>
            {permissionMenu?.LIVE_TV && (
              <div>
                <div className="viewed-channels-head">
                  <h3 className="m-t-20 m-b-20">Most viewed live tv</h3>
                  <div className="filters-list">
                    <SelectComponent
                      label="Limit"
                      value={selectedLimit}
                      options={limitOptions}
                      change={this.selectLimit}
                      style={{ width: 82 }}
                    />
                    <DatePickerComponent
                      label="Start date"
                      selected={startDate}
                      onChange={this.handleChangeStartDate}
                    />
                    <DatePickerComponent
                      label="End date"
                      selected={endDate}
                      onChange={this.handleChangeEndDate}
                    />
                    <div style={{ height: 30 }}>
                      <button
                        onClick={() => this.mostViewedChannels()}
                        className={"btn btn-primary"}
                        style={{ marginRight: 10 }}
                      >
                        <i className={"fa fa-search"}></i>
                      </button>

                      <ButtonComponent type="clear" action={this.clearFields} />
                    </div>
                  </div>
                </div>

                <div style={{ paddingBottom: 20 }}>
                  {mostViewedChannels.length > 0 ? (
                    mostViewedChannels.map((item, key) => {
                      return (
                        <div className="chart-line-channel" key={key}>
                          <LazyLoad>
                            <img
                              src={item?.channel?.image}
                              alt={item?.channel?.name}
                              title={item?.channel?.name}
                              onError={(e) => (e.target.src = defaultImage)}
                            />
                          </LazyLoad>
                          {/* <p
                          className="text-width-style"
                          style={{ width: 300 }} // marginTop: "12px",
                        >
                          {item?.channel?.name}
                        </p> */}
                          <ShortTxt children={item?.channel?.name} />
                          <div
                            style={{
                              // marginTop: "20px",
                              width: "600px",
                            }}
                            className={"progress"}
                          >
                            <div
                              style={{
                                background: colors[key] ? colors[key] : "#36a2eb",
                                display: "flex",
                                alignItems: "center",
                                width:
                                  Math.round((item.views_count * 100) / allViewCount) +
                                  "%",
                              }}
                              className={"progress-inner"}
                            >
                              <span style={{ paddingLeft: 10 }}>
                                {Math.round((item.views_count * 100) / allViewCount) +
                                  "%"}
                              </span>
                            </div>{" "}
                          </div>
                          <p
                            style={{
                              textAlign: "center",
                              // marginTop: "inherit",
                              marginRight: 5,
                              // marginLeft: 5,
                              width: 100,
                            }}
                          >
                            {item.views_count}
                          </p>
                        </div>
                      );
                    })
                  ) : showLoading ? (
                    <TableLoading />
                  ) : (
                    <div className="most-viewed-not-found">
                      List of the Most viewed live tv channels not found, you can search
                      by filtering date.
                    </div>
                  )}
                </div>
              </div>
            )}
          </TabPanel>

          {/* MOVIES TAB */}
          {permissionMenu?.Vod_Manager && (
            <TabPanel>
              <div>{"test"}</div>
              {/* <MostWatchedMovie />
                            <Totals />

                            <div style={{ marginTop: "30px" }}>
                                <NowWatching />
                            </div>

                            <div style={{ marginTop: "30px" }}>
                                <MostWatchedList />
                            </div> */}
            </TabPanel>
          )}
        </Tabs>
      </PageComponent>
    );
  }
}

export default DashboardContent;
