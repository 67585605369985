import { appHost } from "../../../helpers/appHost";
import request from "../../request";

const host = appHost;

const URL = {
  STORE_LINKS: `${host}admin/settings/mobil_apps_url`,
};

export default {
  GET: (query) => request("GET", `${URL.STORE_LINKS}`, query),

  UPDATE: (query) => request("PUT", `${URL.STORE_LINKS}`, query),
};
