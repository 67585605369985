export const inorainCatchUpUrl = (server, channel, item) => {
    const { ip, port } = server;

    let url = "";

    let { start_ut, stop_ut } = item;

    let duration = stop_ut - start_ut;

    if (ip.startsWith("https")) {
        url = `${ip}/archive/${channel.id}/index.m3u8?start=${start_ut}&duration=${duration}`;
    } else {
        if (ip.startsWith("http")) {
            url = `${ip}${port && ":" + port}/archive/${
                channel.id
            }/index.m3u8?start=${start_ut}&duration=${duration}`;
        } else {
            url = `http://${ip}${port && ":" + port}/archive/${
                channel.id
            }/index.m3u8?start=${start_ut}&duration=${duration}`;
        }
    }

    return url;
};

export const flussonicCatchUpUrl = (channel, item) => {
    let { start_ut, stop_ut } = item;

    let duration = stop_ut - start_ut;

    let url = `${channel.url}/index-${start_ut}-${duration}.m3u8}`;

    return url;
};

export const inorainTimeshiftUrl = (server, channel) => {
    const { ip, port } = server;

    let url = "";

    if (ip.startsWith("https")) {
        url = `${ip}/timeshift/${channel.id}/index.m3u8`;
    } else {
        if (ip.startsWith("http")) {
            url = `${ip}${port && ":" + port}/timeshift/${channel.id}/index.m3u8`;
        } else {
            url = `http://${ip}${port && ":" + port}/timeshift/${channel.id}/index.m3u8`;
        }
    }

    return url;
};

export const flussonicTimeshiftUrl = (channel, item) => {
    let { start_ut, stop_ut } = item;

    let duration = stop_ut - start_ut;

    let url = `${channel.url}/index-${start_ut}-${duration}-now.m3u8}`;

    return url;
};
