import React from "react";

export default function ActionButtonComponent({ action, title, type, footer, disabled }) {
    let icon;
    let extralStyle = {};

    switch (type) {
        case "x":
            icon = "fas fa-times";
            break;

        case "check":
            icon = "fas fa-check";
            break;

        case "edit":
            icon = "fas fa-pencil-alt";
            break;

        case "restore":
            icon = "fas fa-window-restore";
            break;

        case "delete":
            icon = "fas fa-trash-alt";
            break;

        case "archive":
            icon = "fas fa-archive";
            break;

        case "unlock":
            icon = "fa fa-unlock-alt";
            break;

        case "lock":
            icon = "fa fa-lock";
            break;

        case "publish":
            icon = "fa fa-download";
            break;

        case "unpublish":
            icon = "fa fa-upload";
            break;

        case "streaming":
            icon = "fa fa-wifi";
            extralStyle = { transform: "rotate(45deg)" };
            break;

        case "box":
            icon = "fa fa-box";
            break;

        case "restart":
            icon = "fas fa-redo";
            break;

        case "logs":
            icon = "fas fa-bug";
            break;

        case "resolution":
            icon = "fas fa-window-maximize action-icon";
            break;

        case "subtitle":
            icon = "fas fa-align-left action-icon";
            break;

        case "audiotrack":
            icon = "fa fa-audio-description action-icon";
            break;

        case "galery":
            icon = "fas fa-images action-icon";
            break;

        case "soundtrack":
            icon = "fa fa-music action-icon";
            break;

        case "cast":
            icon = "fas fa-users action-icon";
            break;

        case "subtitles":
            icon = "fas fa-align-left action-icon";
            break;

        case "balance":
            icon = "fas fa-coins action-icon";
            break;

        case "tariff":
            icon = "fas fa-money-check-alt";
            break;

        case "plus":
            icon = "fas fa-plus";
            break;

        case "minus":
            icon = "fas fa-minus";
            break;

        case "info":
            icon = "fas fa-info-circle";
            break;

        case "play":
            icon = "fa fa-play-circle";
            break;

        case "pause":
            icon = "fas fa-pause-circle ";
            break;

        case "manage":
            icon = "fa fa-arrows-alt-v";
            break;

        case "protect":
            icon = "fa fa-lock";
            break;

        case "unprotect":
            icon = "fa fa-unlock";
            break;

        default:
            break;
    }

    return (
        <div
            disabled={disabled}
            className={footer ? "action-button-component-footer" : "action-button-component"}
            onClick={action}
        >
            <i className={`action-button-component-icon ${icon}`} style={extralStyle} />

            {title}
        </div>
    );
}
