import styles from "./style.module.scss";
import { Form, Input } from "antd";
import { useTranslation } from "react-i18next";

const CountryPricingItem = ({ pricing, form, setSelectedPricingList }) => {
  const { t } = useTranslation();

  const handlePriceChange = (countryCode, field, value) => {
    setSelectedPricingList((prevList) =>
      prevList.map((item) =>
        item.country_code === countryCode ? { ...item, [field]: value } : item
      )
    );

    form.setFieldsValue({
      [`${countryCode}_${field}`]: value,
    });
  };
  return (
    <div key={pricing.country_code} className={styles["countries-pricing-input"]}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <img
          src={pricing.country_icon}
          alt={`${pricing.country_code} flag`}
          style={{ width: 20, height: 20, marginRight: 8 }}
        />
        <span>{pricing.country_code.toUpperCase()}</span>
      </div>
      <Form.Item
        label={t("impression_price")}
        name={`${pricing.country_code}_impression_price`}
      >
        <Input
          value={pricing.impression_price}
          addonAfter={pricing.currency}
          onChange={(e) =>
            handlePriceChange(pricing.country_code, "impression_price", e.target.value)
          }
        />
      </Form.Item>
      <Form.Item
        label={t("conversion_price")}
        name={`${pricing.country_code}_conversion_price`}
      >
        <Input
          value={pricing.conversion_price}
          addonAfter={pricing.currency}
          onChange={(e) =>
            handlePriceChange(pricing.country_code, "conversion_price", e.target.value)
          }
        />
      </Form.Item>
    </div>
  );
};

export default CountryPricingItem;
