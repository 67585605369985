import React from "react";
import styles from "../../style.module.scss";
import { useTranslation } from "react-i18next";
import AdsUpload from "../AdsUpload";
import AdsForm from "../AdsForm";
import { DatePicker, Form, Input, Select } from "antd";
import AdsPreview from "../AdsPreview";
import ButtonComponent from "../../../../../_components/button/ButtonComponent";
import icons from "../../../../../config/icons";

const VolumeAdPage = ({ form, loading }) => {
  const { t } = useTranslation();

  const TexArea = Input.TextArea;
  const RangePicker = DatePicker.RangePicker;

  return (
    <div className={styles["ads-page-wrapper"]}>
      <AdsForm form={form}>
        <div className={styles["ads-collection-head-wrapper"]}>
          <AdsUpload />
          <div className={styles["ads-page-collection-head-data"]}>
            <Form.Item
              label={t("title")}
              name="title"
              rules={[
                {
                  required: true,
                  message: t("enter_title"),
                },
              ]}
            >
              <Input placeholder={t("enter_title")} />
            </Form.Item>
            <Form.Item
              label={t("description")}
              name="description"
              rules={[
                {
                  required: true,
                  message: t("description_message"),
                },
              ]}
            >
              <TexArea placeholder={t("enter_description")} />
            </Form.Item>
          </div>
        </div>

        <div className={styles["ads-page-collection-info-data"]}>
          <Form.Item
            label={t("impression_value")}
            rules={[
              {
                required: true,
                message: t("impression_value"),
              },
            ]}
          >
            <Input placeholder={t("impression_value")} />
          </Form.Item>
        </div>

        <AdsPreview />
      </AdsForm>
    </div>
  );
};

export default VolumeAdPage;
