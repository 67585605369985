import { useTranslation } from "react-i18next";
import { parseDate } from "../../../config/config";

export default function returnColumns(t) {
  const columns = [
    {
      title: "#",
      width: 60,
      dataIndex: `id`,
      align: "center",
      key: "index",
      render: (record, text, index) => {
        // return limit * (currentPage - 1) + index + 1;
        return index + 1;
      },
    },

    {
      title: t("service_name"),
      dataIndex: "service_name",
      key: "service_name",
      align: "center",
    },

    {
      title: t("messages"),
      dataIndex: "message",
      key: "message",
      align: "center",
    },

    {
      title: t("updated_date"),
      dataIndex: "updatedAt",
      key: "updatedAt",
      align: "center",
      render: (text, record, index) => {
        return parseDate(text);
      },
    },
  ];

  return columns;
}
