import {
  PlayCircleOutlined,
  PauseCircleOutlined,
  CheckOutlined,
  CloseOutlined,
  PlusOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
  MobileOutlined,
  LockOutlined,
  UnlockOutlined,
  TagsOutlined,
  MinusOutlined,
  SettingOutlined,
  SafetyCertificateOutlined,
  CloseCircleOutlined,
  SendOutlined,
  FolderOpenOutlined,
  CodeOutlined,
  DragOutlined,
  LeftOutlined,
  InfoCircleOutlined,
  LoginOutlined,
} from "@ant-design/icons";

import Icon from "@ant-design/icons";

export default {
  // a
  ADD: <PlusOutlined />,
  ARCHIVE_ICON: <i className="fas fa-archive" />,

  ARROW_UP: <ArrowUpOutlined />,
  ARROW_DOWN: <ArrowDownOutlined />,

  ANDROID_MOBILE: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 96 960 960"
      width="48"
      fill="currentColor"
    >
      <path d="M398 931h164v-40H398v40Zm-138 85q-24 0-42-18t-18-42V196q0-24 18-42t42-18h440q24 0 42 18t18 42v760q0 24-18 42t-42 18H260Zm0-150v90h440v-90H260Zm0-60h440V286H260v520Zm0-580h440v-30H260v30Zm0 640v90-90Zm0-640v-30 30Z" />
    </svg>
  ),

  ADMIN: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 96 960 960"
      width="48"
      fill="currentColor"
    >
      <path d="M480 575q-66 0-108-42t-42-108q0-66 42-108t108-42q66 0 108 42t42 108q0 66-42 108t-108 42ZM160 896v-94q0-38 19-65t49-41q67-30 128.5-45T480 636q62 0 123 15.5t127.921 44.694q31.301 14.126 50.19 40.966Q800 764 800 802v94H160Zm60-60h520v-34q0-16-9.5-30.5T707 750q-64-31-117-42.5T480 696q-57 0-111 11.5T252 750q-14 7-23 21.5t-9 30.5v34Zm260-321q39 0 64.5-25.5T570 425q0-39-25.5-64.5T480 335q-39 0-64.5 25.5T390 425q0 39 25.5 64.5T480 515Zm0-90Zm0 411Z" />
    </svg>
  ),

  ADS: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 96 960 960"
      width="48"
      fill="currentColor"
    >
      <path d="M468 816q-96-5-162-74t-66-166q0-100 70-170t170-70q97 0 166 66t74 163l-63-20q-11-64-60-106.5T480 396q-75 0-127.5 52.5T300 576q0 67 42.5 116.5T449 753l19 63Zm48 158q-9 1-18 1.5t-18 .5q-83 0-156-31.5T197 859q-54-54-85.5-127T80 576q0-83 31.5-156T197 293q54-54 127-85.5T480 176q83 0 156 31.5T763 293q54 54 85.5 127T880 576q0 9-.5 18t-1.5 18l-58-18v-18q0-142-99-241t-241-99q-142 0-241 99t-99 241q0 142 99 241t241 99h18l18 58Zm305 22L650 825l-50 151-120-400 400 120-151 50 171 171-79 79Z" />
    </svg>
  ),

  APPLICATION_SETTINGS: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 96 960 960"
      width="48"
      fill="currentColor"
    >
      <path d="M452 770h56l10-54q20-6 34-15t26-21l62 19 26-54-47-30q4-21 4-39t-4-39l47-30-26-54-62 19q-12-12-26-21t-34-15l-10-54h-56l-10 54q-20 6-34 15t-26 21l-62-19-26 54 47 30q-4 21-4 39t4 39l-47 30 26 54 62-19q12 12 26 21t34 15l10 54Zm28-109q-36 0-60.5-24.5T395 576q0-36 24.5-60.5T480 491q36 0 60.5 24.5T565 576q0 36-24.5 60.5T480 661ZM180 936q-24 0-42-18t-18-42V276q0-24 18-42t42-18h600q24 0 42 18t18 42v600q0 24-18 42t-42 18H180Zm0-60h600V276H180v600Zm0-600v600-600Z" />
    </svg>
  ),

  ARCHIVE: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 96 960 960"
      width="48"
      fill="currentColor"
    >
      <path d="M120 896V748h720v148H120Zm60-38h72v-72h-72v72Zm-60-454V256h720v148H120Zm60-38h72v-72h-72v72Zm-60 284V502h720v148H120Zm60-38h72v-72h-72v72Z" />
    </svg>
  ),

  // b
  BACK: <LeftOutlined />,
  BANNER: (
    <svg
      stroke="currentColor"
      fill="currentColor"
      stroke-width="0"
      viewBox="0 0 512 512"
      height="18px"
      width="18px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M279 25v30h-46V25h46zm178 35.396c3.485 1.262 7.267 2.717 11.195 4.55 5.553 2.589 10.945 5.739 14.409 8.742C486.067 76.69 487 78.936 487 80c0 1.063-.933 3.31-4.396 6.313-3.464 3.002-8.856 6.152-14.409 8.742-3.928 1.832-7.71 3.287-11.195 4.549V60.396zm-402 0v39.208c-3.485-1.262-7.267-2.717-11.195-4.55-5.553-2.589-10.945-5.739-14.409-8.742C25.933 83.31 25 81.064 25 80c0-1.063.933-3.31 4.396-6.313 3.464-3.002 8.856-6.152 14.409-8.742 3.928-1.832 7.71-3.287 11.195-4.549zM439 73v14h-46V73h46zm-64 0v291.578L256 498.453 137 364.578V73h238zm-256 0v14H73V73h46zm210 23h-18v252.844l-55 68.75-55-68.75V96h-18v259.156l73 91.25 73-91.25V96z"></path>
    </svg>
  ),

  // c
  CHAT: (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 2C0.447715 2 0 2.44772 0 3V12C0 12.5523 0.447715 13 1 13H14C14.5523 13 15 12.5523 15 12V3C15 2.44772 14.5523 2 14 2H1ZM1 3L14 3V3.92494C13.9174 3.92486 13.8338 3.94751 13.7589 3.99505L7.5 7.96703L1.24112 3.99505C1.16621 3.94751 1.0826 3.92486 1 3.92494V3ZM1 4.90797V12H14V4.90797L7.74112 8.87995C7.59394 8.97335 7.40606 8.97335 7.25888 8.87995L1 4.90797Z"
        fill="currentColor"
        fillRule="evenodd"
        clipRule="evenodd"
      ></path>
    </svg>
  ),

  CHECK: <CheckOutlined />,

  CHECK_PRICE: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M17.0229 6.52289L9.4263 14.1195L5.28795 9.99265L3.66258 11.618L9.4263 17.3817L18.6483 8.15979L17.0229 6.52289ZM11.7318 0.0905762C5.36864 0.0905762 0.204346 5.25487 0.204346 11.618C0.204346 17.9812 5.36864 23.1455 11.7318 23.1455C18.0949 23.1455 23.2592 17.9812 23.2592 11.618C23.2592 5.25487 18.0949 0.0905762 11.7318 0.0905762ZM11.7318 20.84C6.63666 20.84 2.50983 16.7132 2.50983 11.618C2.50983 6.52289 6.63666 2.39607 11.7318 2.39607C16.8269 2.39607 20.9537 6.52289 20.9537 11.618C20.9537 16.7132 16.8269 20.84 11.7318 20.84Z"
        fill="#0F87B2"
      />
    </svg>
  ),

  CONFIGS: <SettingOutlined />,

  CLOSE: <i className="fa fa-times" aria-hidden="true" />,

  COLLECTION: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="15"
      viewBox="0 -960 960 960"
      fill="currentColor"
    >
      <path d="M320-320h480v-480h-80v280l-100-60-100 60v-280H320v480Zm0 80q-33 0-56.5-23.5T240-320v-480q0-33 23.5-56.5T320-880h480q33 0 56.5 23.5T880-800v480q0 33-23.5 56.5T800-240H320ZM160-80q-33 0-56.5-23.5T80-160v-560h80v560h560v80H160Zm360-720h200-200Zm-200 0h480-480Z" />
    </svg>
  ),

  CUSTOM_ADS: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 18 18"
      fill="none"
    >
      {/* <rect width="18" height="18" rx="2" fill="#343434" /> */}
      <path
        d="M5.625 12H14.625V4.5H5.625V12ZM4.875 12.75V2.25H15.375V12.75H4.875ZM2.625 15V4.962H3.375V14.25H12.6637V15H2.625Z"
        fill="currentColor"
      />
    </svg>
  ),

  // d
  DASHBOARD: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 96 960 960"
      width="48"
      fill="currentColor"
    >
      <path d="M160 896V456h140v440H160Zm250 0V256h140v640H410Zm250 0V616h140v280H660Z" />
    </svg>
  ),
  DELETE: <i className="fas fa-trash-alt" />,

  DISABLE: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      //enableBackground="new 0 0 24 24"
      enableBackground="new 0 0 24 24"
      height="18px"
      viewBox="0 0 24 24"
      width="18px"
      fill="currentColor"
    >
      <path d="M7.94,5.12L6.49,3.66C8.07,2.61,9.96,2,12,2c5.52,0,10,4.48,10,10c0,2.04-0.61,3.93-1.66,5.51l-1.46-1.46 C19.59,14.86,20,13.48,20,12c0-4.41-3.59-8-8-8C10.52,4,9.14,4.41,7.94,5.12z M17.66,9.53l-1.41-1.41l-2.65,2.65l1.41,1.41 L17.66,9.53z M19.78,22.61l-2.27-2.27C15.93,21.39,14.04,22,12,22C6.48,22,2,17.52,2,12c0-2.04,0.61-3.93,1.66-5.51L1.39,4.22 l1.41-1.41l18.38,18.38L19.78,22.61z M16.06,18.88l-3.88-3.88l-1.59,1.59l-4.24-4.24l1.41-1.41l2.83,2.83l0.18-0.18L5.12,7.94 C4.41,9.14,4,10.52,4,12c0,4.41,3.59,8,8,8C13.48,20,14.86,19.59,16.06,18.88z" />
    </svg>
  ),

  // e
  EPG: (
    // <svg
    //     width="15"
    //     height="15"
    //     viewBox="0 0 15 15"
    //     fill="currentColor"
    //     xmlns="http://www.w3.org/2000/svg"
    // >
    //     <path
    //         d="M0 1.5C0 1.22386 0.223858 1 0.5 1H2.5C2.77614 1 3 1.22386 3 1.5C3 1.77614 2.77614 2 2.5 2H0.5C0.223858 2 0 1.77614 0 1.5ZM4 1.5C4 1.22386 4.22386 1 4.5 1H14.5C14.7761 1 15 1.22386 15 1.5C15 1.77614 14.7761 2 14.5 2H4.5C4.22386 2 4 1.77614 4 1.5ZM4 4.5C4 4.22386 4.22386 4 4.5 4H11.5C11.7761 4 12 4.22386 12 4.5C12 4.77614 11.7761 5 11.5 5H4.5C4.22386 5 4 4.77614 4 4.5ZM0 7.5C0 7.22386 0.223858 7 0.5 7H2.5C2.77614 7 3 7.22386 3 7.5C3 7.77614 2.77614 8 2.5 8H0.5C0.223858 8 0 7.77614 0 7.5ZM4 7.5C4 7.22386 4.22386 7 4.5 7H14.5C14.7761 7 15 7.22386 15 7.5C15 7.77614 14.7761 8 14.5 8H4.5C4.22386 8 4 7.77614 4 7.5ZM4 10.5C4 10.2239 4.22386 10 4.5 10H11.5C11.7761 10 12 10.2239 12 10.5C12 10.7761 11.7761 11 11.5 11H4.5C4.22386 11 4 10.7761 4 10.5ZM0 13.5C0 13.2239 0.223858 13 0.5 13H2.5C2.77614 13 3 13.2239 3 13.5C3 13.7761 2.77614 14 2.5 14H0.5C0.223858 14 0 13.7761 0 13.5ZM4 13.5C4 13.2239 4.22386 13 4.5 13H14.5C14.7761 13 15 13.2239 15 13.5C15 13.7761 14.7761 14 14.5 14H4.5C4.22386 14 4 13.7761 4 13.5Z"
    //         fill="currentColor"
    //         fillRule="evenodd"
    //         clipRule="evenodd"
    //     ></path>
    // </svg>

    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 96 960 960"
      width="48"
      fill="currentColor"
    >
      <path d="M100 726v-60h306v60H100Zm0-165v-60h473v60H100Zm0-165v-60h473v60H100Zm542 540V614l218 161-218 161Z" />
    </svg>
  ),

  EDIT: <i className="fas fa-pencil-alt" />,

  ENABLE: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="18px"
      viewBox="0 0 24 24"
      width="18px"
      fill="currentColor"
    >
      <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm4.59-12.42L10 14.17l-2.59-2.58L6 13l4 4 8-8z" />
    </svg>
  ),

  // f
  FOLDER: <FolderOpenOutlined />,
  // g
  GALLERY: <i className="fas fa-images" />,

  GIFT: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="9"
      height="10"
      viewBox="0 0 9 10"
      fill="none"
    >
      <path
        // fill-rule="evenodd"
        // clip-rule="evenodd"
        d="M8.5 5.5V8C8.5 8.39782 8.34196 8.77936 8.06066 9.06066C7.77936 9.34196 7.39782 9.5 7 9.5H5V5.5H8.5ZM4 5.5V9.5H2C1.60218 9.5 1.22064 9.34196 0.93934 9.06066C0.658035 8.77936 0.5 8.39782 0.5 8V5.5H4ZM3 3.00715e-09C3.28374 -1.55557e-05 3.56424 0.0603434 3.82286 0.177068C4.08148 0.293792 4.3123 0.464209 4.5 0.677C4.6877 0.464209 4.91852 0.293792 5.17714 0.177068C5.43576 0.0603434 5.71626 -1.55557e-05 6 3.00715e-09H6.25C6.44891 3.00715e-09 6.63968 0.0790177 6.78033 0.21967C6.92098 0.360322 7 0.551088 7 0.75V1C7 1.3645 6.9025 1.706 6.7325 2H8C8.26522 2 8.51957 2.10536 8.70711 2.29289C8.89464 2.48043 9 2.73478 9 3V4C9 4.182 8.9515 4.353 8.866 4.5H5V3C5 2.86739 4.94732 2.74021 4.85355 2.64645C4.75979 2.55268 4.63261 2.5 4.5 2.5C4.36739 2.5 4.24021 2.55268 4.14645 2.64645C4.05268 2.74021 4 2.86739 4 3V4.5H0.134C0.0462227 4.34798 7.72367e-06 4.17554 0 4V3C0 2.73478 0.105357 2.48043 0.292893 2.29289C0.48043 2.10536 0.734784 2 1 2H2.2675C2.09212 1.69592 1.99986 1.35103 2 1V0.75C2 0.551088 2.07902 0.360322 2.21967 0.21967C2.36032 0.0790177 2.55109 3.00715e-09 2.75 3.00715e-09H3ZM3 1C3 1.26522 3.10536 1.51957 3.29289 1.70711C3.48043 1.89464 3.73478 2 4 2C4 1.73478 3.89464 1.48043 3.70711 1.29289C3.51957 1.10536 3.26522 1 3 1ZM6 1C5.73478 1 5.48043 1.10536 5.29289 1.29289C5.10536 1.48043 5 1.73478 5 2C5.26522 2 5.51957 1.89464 5.70711 1.70711C5.89464 1.51957 6 1.26522 6 1Z"
        fill="#C74727"
      />
    </svg>
  ),

  // h
  HISTORY: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 96 960 960"
      width="48"
      fill="currentColor"
    >
      <path d="M142 449V241h60v106q52-61 123.5-96T477 216q151 0 257 104t106 254v10h-60v-13q0-124-89-209.5T477 276q-68 0-127.5 31T246 389h105v60H142Zm-22 126h60q0 125 86 213t211 88h12l34 57q-11 2-22.5 2.5t-23.5.5q-149 0-253-105.5T120 575Zm440 119L451 587V373h60v189l81 79-32 53Zm175 352-10-66q-18-5-34-13.5T661 944l-55 12-25-42 47-44q-3-12-3-25t3-25l-47-44 25-42 55 12q14-14 30-22.5t34-13.5l10-66h54l10 66q18 5 34 13.5t30 22.5l55-12 25 42-47 44q3 12 3 25t-3 25l47 44-25 42-55-12q-14 14-30 22.5T799 980l-10 66h-54Zm27-121q36 0 58-22t22-58q0-36-22-58t-58-22q-36 0-58 22t-22 58q0 36 22 58t58 22Z" />
    </svg>
  ),
  // i
  INFO: <InfoCircleOutlined />,
  IMPORT: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 96 960 960"
      width="48"
      fill="currentColor"
    >
      <path d="m570 635 146-146-42-43-74 73V339h-60v181l-74-74-42 43 146 146Zm114 223h123q0 21-15.5 35.5T754 910l-540 65q-25 3-44.5-11.5T147 924L83 426q-3-25 12.237-44.964Q110.473 361.071 135 358l64-7v61l-57 6 65 498 477-58Zm-365-60q-24.75 0-42.375-17.625T259 738V236q0-24.75 17.625-42.375T319 176h502q24.75 0 42.375 17.625T881 236v502q0 24.75-17.625 42.375T821 798H319Zm0-60h502V236H319v502ZM207 916Zm112-178V236v502Z" />
    </svg>
  ),
  // j
  // k
  // l
  LOGIN: <LoginOutlined />,
  LOGS: <CodeOutlined />,
  LOCK: <LockOutlined />,
  LAUNCHER: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 96 960 960"
      width="48"
      fill="currentColor"
    >
      <path d="M40 817q8-106 65-196.5T256 477l-75-129q-3-9-.5-18t10.5-14q9-5 19.5-2t15.5 12l74 127q86-37 180-37t180 37l75-127q5-9 15.5-12t19.5 2q8 5 11.5 14.5T780 348l-76 129q94 53 151 143.5T920 817H40Zm240-110q20 0 35-15t15-35q0-20-15-35t-35-15q-20 0-35 15t-15 35q0 20 15 35t35 15Zm400 0q20 0 35-15t15-35q0-20-15-35t-35-15q-20 0-35 15t-15 35q0 20 15 35t35 15Z" />
    </svg>
  ),

  LESSONS: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 96 960 960"
      width="48"
      fill="currentColor"
    >
      <path d="M493 917q3 14 11.5 31t17.5 29H180q-24 0-42-18t-18-42V236q0-24 18-42t42-18h520q24 0 42 18t18 42v339q-13.5-2-30-2t-30 2V236H462v278l-96-59-96 59V236h-90v681h313Zm235 99q-81 0-136.5-55.5T536 824q0-81 55.5-136.5T728 632q81 0 136.5 55.5T920 824q0 81-55.5 136.5T728 1016Zm-49-100 143-92-143-92v184ZM270 236h192-192Zm223 0H180h520-223 16Z" />
    </svg>
  ),

  LIVE_TV: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 96 960 960"
      width="48"
      fill="currentColor"
    >
      <path d="m383 706 267-170-267-170v340Zm-53 230v-80H140q-24 0-42-18t-18-42V276q0-24 18-42t42-18h680q24 0 42 18t18 42v520q0 24-18 42t-42 18H630v80H330ZM140 796h680V276H140v520Zm0 0V276v520Z" />
    </svg>
  ),
  // m
  MOBILE: <MobileOutlined />,
  MINUS: <MinusOutlined />,
  MOVE: <DragOutlined />,
  // n
  NOTIFICATIONS: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 96 960 960"
      width="48"
      fill="currentColor"
    >
      <path d="M480 976q-83 0-156-31.5T197 859q-54-54-85.5-127T80 576q0-83 31.5-156T197 293q54-54 127-85.5T480 176q83 0 156 31.5T763 293q54 54 85.5 127T880 576v53q0 56-39.5 94.5T744 762q-36 0-68-17.5T627 695q-26 34-65 50.5T480 762q-78 0-132.5-54T293 576q0-78 54.5-133T480 388q78 0 132.5 55T667 576v53q0 31 22.5 52t54.5 21q31 0 53.5-21t22.5-52v-53q0-142-99-241t-241-99q-142 0-241 99t-99 241q0 142 99 241t241 99h214v60H480Zm0-274q53 0 90-36.5t37-89.5q0-54-37-91t-90-37q-53 0-90 37t-37 91q0 53 37 89.5t90 36.5Z" />
    </svg>
  ),
  // o
  ONLINE: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 -960 960 960"
      width="48"
    >
      <path d="M197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763l36 36q-49 47-76 111.5T130-480q0 71 27 135.5T233-233l-36 36Zm80-80q-38-38-62.5-91.5T190-480q0-58 24.5-111.5T277-683l35 35q-34 30-53 76t-19 92q0 46 19 92t53 76l-35 35Zm165-23q0-21-15-43t-32.5-45Q377-411 362-436.5T347-490q0-55 39-94t94-39q55 0 94 39t39 94q0 28-15 53.5T565.5-388Q548-365 533-343t-15 43h-76Zm-2 90v-50h80v50h-80Zm243-67-35-35q34-30 53-76t19-92q0-46-19-92t-53-76l35-35q38 38 62.5 91.5T770-480q0 58-24.5 111.5T683-277Zm80 80-36-36q49-47 76-111.5T830-480q0-71-27-135.5T727-727l36-36q54 54 85.5 127T880-480q0 83-31.5 156T763-197Z" />
    </svg>
  ),
  // p
  PUBLISH: <i className="fa fa-download" />,

  PLAY: <PlayCircleOutlined />,

  PAUSE: <PauseCircleOutlined />,

  PROMO: (
    <svg
      stroke="currentColor"
      fill="currentColor"
      strokeWidth="0"
      viewBox="0 0 24 24"
      height="200px"
      width="200px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path fill="none" d="M0 0h24v24H0z"></path>
      <path d="M12.79 21 3 11.21v2c0 .53.21 1.04.59 1.41l7.79 7.79c.78.78 2.05.78 2.83 0l6.21-6.21c.78-.78.78-2.05 0-2.83L12.79 21z"></path>
      <path d="M11.38 17.41c.78.78 2.05.78 2.83 0l6.21-6.21c.78-.78.78-2.05 0-2.83L12.63.58A2.04 2.04 0 0 0 11.21 0H5C3.9 0 3 .9 3 2v6.21c0 .53.21 1.04.59 1.41l7.79 7.79zM7.25 3a1.25 1.25 0 1 1 0 2.5 1.25 1.25 0 0 1 0-2.5z"></path>
    </svg>
  ),

  PROMO_CODE_DECORATION: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="46"
      height="154"
      viewBox="0 0 46 154"
      fill="none"
    >
      <path
        d="M29.3672 3.90773L27.1869 4.17162L28.054 6.1885L26.7346 4.43551L25.4151 6.1885L26.2759 4.17162L24.0957 3.90773L26.2759 3.64384L25.4151 1.62695L26.7346 3.37995L28.054 1.62695L27.1869 3.64384L29.3672 3.90773Z"
        fill="white"
      />
      <g opacity="0.5">
        <path
          opacity="0.5"
          d="M29.3672 3.90773L27.1869 4.17162L28.054 6.1885L26.7346 4.43551L25.4151 6.1885L26.2759 4.17162L24.0957 3.90773L26.2759 3.64384L25.4151 1.62695L26.7346 3.37995L28.054 1.62695L27.1869 3.64384L29.3672 3.90773Z"
          fill="white"
        />
      </g>
      <path
        d="M46.5161 48.9132C45.4428 48.0546 44.3953 47.2475 43.3564 46.3975L41.8281 45.0924C41.5644 44.8859 41.3122 44.6652 41.0725 44.4313C40.9609 44.3025 40.8492 44.1823 40.7462 44.0449C40.6432 43.9075 40.5487 43.7701 40.7462 43.3408L41.0124 43.0832C41.4331 42.8944 41.5877 42.9716 41.7164 43.0832C41.8483 43.1819 41.9744 43.288 42.0942 43.4009C42.3332 43.6384 42.5569 43.8907 42.7639 44.1565L44.069 45.6934C44.9277 46.7324 45.7863 47.7713 46.5848 48.8445L46.5161 48.9132Z"
        fill="white"
      />
      <path
        d="M44.3417 54.2655C43.0023 54.4458 41.6714 54.5832 40.332 54.712L38.3228 54.8751C37.9898 54.9194 37.6542 54.9423 37.3183 54.9438H36.8203C36.6571 54.9438 36.4854 54.8837 36.3223 54.4458V54.068C36.4854 53.6387 36.6571 53.5872 36.8203 53.5786C36.9862 53.5701 37.1524 53.5701 37.3183 53.5786C37.6542 53.5801 37.9898 53.6031 38.3228 53.6473L40.332 53.8019C41.6714 53.9393 43.0023 54.0766 44.3417 54.2569V54.2655Z"
        fill="white"
      />
      <path
        d="M46.5792 59.589C45.7549 60.6537 44.9164 61.7041 44.0635 62.7401L42.7584 64.2684C42.5558 64.5379 42.3318 64.7905 42.0887 65.024L41.7109 65.3503C41.5821 65.4619 41.4275 65.5478 41.0068 65.3503L40.7407 65.0841C40.5518 64.6634 40.6376 64.5089 40.7407 64.3886C40.8426 64.2543 40.9515 64.1253 41.0669 64.0023C41.3023 63.7638 41.5549 63.5428 41.8225 63.3411L43.3508 62.036C44.3898 61.1774 45.4373 60.3188 46.5105 59.5117L46.5792 59.589Z"
        fill="white"
      />
      <path
        d="M47.4644 43.6552C46.3654 41.7491 45.4123 39.7743 44.4507 37.808C43.9785 36.8206 43.5062 35.8418 43.0683 34.8372C42.8623 34.3307 42.639 33.8327 42.4587 33.3175L42.1754 32.5447C42.1023 32.3914 42.0763 32.2198 42.1007 32.0517C42.1252 31.8835 42.199 31.7264 42.3127 31.6003L42.6562 31.4629C42.8252 31.4715 42.988 31.5299 43.1239 31.6307C43.2599 31.7315 43.363 31.8703 43.4204 32.0296L43.7638 32.7766C44.0042 33.266 44.2017 33.7726 44.4164 34.2791C44.8113 35.3009 45.1719 36.3312 45.5325 37.3616C46.2452 39.4308 46.9664 41.5001 47.5417 43.6208L47.4644 43.6552Z"
        fill="white"
      />
      <path
        d="M41.3267 49.8846C39.1973 49.3094 37.1367 48.5881 35.0674 47.8755C34.0371 47.5063 32.9982 47.1457 31.985 46.7507C31.4784 46.5446 30.9718 46.3472 30.4738 46.1067L29.7354 45.7547C29.5746 45.6988 29.434 45.5962 29.3315 45.4602C29.2291 45.3241 29.1694 45.1606 29.1602 44.9905L29.3061 44.6213C29.4323 44.5076 29.5894 44.4338 29.7575 44.4093C29.9256 44.3849 30.0972 44.4108 30.2506 44.484L31.0148 44.7673C31.5385 44.939 32.0365 45.1623 32.5431 45.3683C33.5391 45.8062 34.5265 46.287 35.5139 46.7593C37.4801 47.7123 39.4549 48.6654 41.361 49.7644L41.3267 49.8846Z"
        fill="white"
      />
      <path
        d="M41.3239 58.6406C39.4178 59.7396 37.443 60.6841 35.4768 61.6457C34.4894 62.1179 33.502 62.5988 32.506 63.0281C31.9994 63.2427 31.5014 63.4574 30.9777 63.6377L30.2135 63.921C30.0601 63.9941 29.8885 64.0201 29.7204 63.9957C29.5523 63.9712 29.3952 63.8974 29.269 63.7837L29.123 63.4402C29.1323 63.2701 29.192 63.1067 29.2944 62.9706C29.3969 62.8345 29.5375 62.732 29.6983 62.676L30.4367 62.3326C30.9347 62.0922 31.4413 61.8947 31.9479 61.6886C32.9611 61.2851 34 60.9245 35.0303 60.5639C37.0996 59.8512 39.1602 59.13 41.2896 58.5547L41.3239 58.6406Z"
        fill="white"
      />
      <path
        d="M47.5391 64.8039C46.9638 66.9332 46.2425 69.0025 45.5299 71.0717C45.1693 72.1021 44.8087 73.1324 44.4137 74.1542C44.199 74.6522 44.0016 75.1673 43.7611 75.6567L43.4177 76.3951C43.3618 76.556 43.2592 76.6966 43.1231 76.7991C42.9871 76.9015 42.8236 76.9611 42.6535 76.9704L42.3101 76.8245C42.1981 76.699 42.1253 76.5435 42.1009 76.3771C42.0765 76.2108 42.1015 76.0409 42.1727 75.8886L42.4561 75.1158C42.6364 74.6007 42.8596 74.0941 43.0657 73.5961C43.5036 72.5915 43.9758 71.6041 44.448 70.6167C45.4097 68.6505 46.3627 66.6842 47.4618 64.7695L47.5391 64.8039Z"
        fill="white"
      />
      <path
        d="M48.7675 35.7609C47.2135 31.2016 45.7109 26.6338 44.2169 22.0231L42.0274 15.1542C41.6496 14.0122 41.2633 12.8702 40.9456 11.7197C40.791 11.1444 40.6365 10.5606 40.5077 10.0025C40.3925 9.69385 40.3444 9.36422 40.3666 9.03554C40.3888 8.70686 40.4808 8.38669 40.6365 8.09636L41.1087 7.95898C41.3945 8.12489 41.6423 8.34894 41.8361 8.61665C42.0298 8.88435 42.1652 9.18974 42.2335 9.51307C42.431 10.0798 42.6113 10.655 42.7916 11.2303C43.1351 12.3808 43.4098 13.5486 43.7017 14.7163L45.4791 21.714C46.6239 26.3848 47.7687 31.0614 48.9135 35.7437L48.7675 35.7609Z"
        fill="white"
      />
      <path
        d="M37.8894 43.2624C33.7452 40.9728 29.6124 38.6516 25.491 36.299L19.3348 32.7444C18.3044 32.1605 17.2655 31.5853 16.2695 30.9499C15.7715 30.6322 15.2735 30.3059 14.7927 29.9711C14.5028 29.8197 14.2486 29.6081 14.0472 29.3505C13.8457 29.0928 13.7018 28.795 13.625 28.4771L13.8826 28.0564C14.201 27.9864 14.5305 27.9839 14.8499 28.0489C15.1693 28.114 15.4715 28.2452 15.7372 28.4341C16.2609 28.7175 16.7675 29.018 17.2741 29.2928C18.2959 29.9024 19.2747 30.5721 20.2535 31.2332L26.1865 35.1571C30.119 37.7902 34.0428 40.449 37.9581 43.1336L37.8894 43.2624Z"
        fill="white"
      />
      <path
        d="M29.6921 42.7223C27.391 42.147 25.1071 41.5117 22.8232 40.8591C20.5393 40.2066 18.2811 39.5283 16.0144 38.8585C13.7477 38.1888 11.4809 37.5019 9.23135 36.7635C8.10657 36.3857 6.98178 36.0251 5.87417 35.613L4.20846 34.9948C3.8916 34.9175 3.59571 34.7713 3.3419 34.5665C3.0881 34.3616 2.88265 34.1033 2.74023 33.8099L2.89478 33.3377C3.19066 33.2002 3.51299 33.1289 3.83926 33.1289C4.16553 33.1289 4.48786 33.2002 4.78374 33.3377L6.50096 33.8528C7.63433 34.1963 8.75053 34.5912 9.86673 34.969C12.0991 35.7504 14.3143 36.5918 16.5296 37.4247C18.7448 38.2575 20.96 39.0904 23.1581 40.0005C25.3561 40.9106 27.5627 41.7177 29.7436 42.688L29.6921 42.7223Z"
        fill="white"
      />
      <path
        d="M36.1687 36.3615C34.3742 34.6958 32.6226 32.9786 30.8797 31.2613C29.1367 29.5441 27.4452 27.8269 25.728 26.0496C24.0108 24.2722 22.2935 22.5636 20.5763 20.7777C19.7177 19.9191 18.9106 18.9918 18.1121 18.0645L16.9014 16.6821C16.654 16.4543 16.4579 16.1765 16.3262 15.8671C16.1944 15.5577 16.13 15.2238 16.1373 14.8876L16.5065 14.5527C16.8409 14.5739 17.167 14.666 17.4631 14.8229C17.7592 14.9798 18.0184 15.198 18.2237 15.4629L19.5116 16.7765C20.3702 17.6352 21.1773 18.5625 22.0102 19.464C23.6416 21.2843 25.2386 23.1389 26.8442 24.9935C28.4498 26.8481 30.0382 28.7027 31.6009 30.5916C33.1636 32.4806 34.7348 34.3524 36.2546 36.2842L36.1687 36.3615Z"
        fill="white"
      />
      <path
        d="M41.8403 37.6146C39.5907 34.2917 37.324 30.9775 35.1345 27.6203L31.8117 22.5974C31.245 21.7388 30.704 20.9317 30.1717 20.0817C29.9141 19.6524 29.6565 19.2231 29.4161 18.7766C29.0974 18.3319 28.9621 17.7815 29.0383 17.2397L29.356 17.0508C29.8745 17.2209 30.3078 17.5836 30.5667 18.0639C30.8529 18.4761 31.1391 18.8968 31.4253 19.3261C31.949 20.1847 32.4642 21.0433 32.9536 21.9019L35.9759 27.1051C38.0023 30.5396 39.9685 34.0685 41.9862 37.5545L41.8403 37.6146Z"
        fill="white"
      />
      <path
        d="M37.2299 64.764C33.3775 67.4257 29.5052 70.0587 25.6128 72.6632L19.7571 76.5356C18.7868 77.1881 17.8166 77.8492 16.8206 78.4503C16.314 78.7508 15.8075 79.0427 15.2923 79.3089C15.0309 79.4962 14.7327 79.6259 14.4174 79.6895C14.1022 79.7532 13.7771 79.7493 13.4634 79.6781L13.1973 79.2574C13.2752 78.9434 13.4178 78.6491 13.6159 78.3934C13.8141 78.1376 14.0633 77.926 14.3478 77.772C14.8286 77.4371 15.318 77.1194 15.8075 76.8017C16.7949 76.1749 17.8166 75.6082 18.8384 75.033L24.9173 71.5213C28.9872 69.2116 33.0656 66.9105 37.1612 64.6523L37.2299 64.764Z"
        fill="white"
      />
      <path
        d="M39.3997 69.8339C37.4936 71.8688 35.5531 73.8608 33.604 75.8442C31.655 77.8276 29.6716 79.7766 27.7054 81.7429C25.7391 83.7091 23.7557 85.6496 21.7466 87.5729C20.7334 88.5173 19.7289 89.479 18.6813 90.3977L17.1273 91.7801C16.6216 92.3425 15.9158 92.6846 15.161 92.7331L14.8262 92.3725C14.9229 91.6243 15.308 90.9434 15.8994 90.475L17.3934 89.0153C18.3808 88.0365 19.4026 87.1006 20.4157 86.1476C22.4678 84.2758 24.5543 82.4383 26.6407 80.6009C28.7271 78.7635 30.8136 76.9261 32.9257 75.1144C35.0379 73.3027 37.1501 71.4996 39.3138 69.748L39.3997 69.8339Z"
        fill="white"
      />
      <path
        d="M29.7231 59.7233C27.3276 60.5819 24.9149 61.3461 22.4936 62.1189C20.0723 62.8916 17.651 63.6128 15.2297 64.3684C12.8084 65.124 10.3785 65.8366 7.94009 66.5235C6.71227 66.8498 5.49303 67.2018 4.26521 67.4852L2.41061 67.9317C2.08664 68.0511 1.74125 68.1013 1.39669 68.0791C1.05213 68.0569 0.716035 67.9628 0.410043 67.8029L0.28125 67.3221C0.463913 67.0286 0.706894 66.7773 0.994071 66.5848C1.28125 66.3924 1.60606 66.2632 1.94696 66.2059L3.77581 65.6649C4.98645 65.2871 6.21427 64.978 7.4335 64.6518C9.88913 64.0164 12.3534 63.4411 14.8262 62.8573C17.299 62.2734 19.7632 61.6981 22.236 61.14C24.7088 60.5819 27.1988 60.0668 29.6888 59.5859L29.7231 59.7233Z"
        fill="white"
      />
      <path
        d="M33.1024 54.9948C29.668 55.5615 26.2851 56.0939 22.8764 56.6176L17.7247 57.3646C16.8661 57.502 16.0075 57.6394 15.1488 57.7252C14.7195 57.7252 14.2902 57.8111 13.8609 57.8368C13.6261 57.8806 13.3846 57.8725 13.1532 57.8132C12.9219 57.7539 12.7063 57.6447 12.5215 57.4934V57.1242C12.6687 56.9366 12.8545 56.7827 13.0664 56.6731C13.2782 56.5635 13.5111 56.5007 13.7493 56.4888L15.0286 56.2999C15.8873 56.1883 16.7459 56.1368 17.6045 56.0681L22.7562 55.6388C26.1906 55.3698 29.6251 55.1236 33.0595 54.9004L33.1024 54.9948Z"
        fill="white"
      />
      <path
        d="M35.3093 51.1038C32.8108 50.932 30.3294 50.6916 27.8566 50.4426C25.3838 50.1936 22.9024 49.9017 20.421 49.6355C17.9396 49.3694 15.4754 49.0688 13.0026 48.7254C11.7747 48.5451 10.5383 48.382 9.31052 48.1673L7.47309 47.841C7.13897 47.8146 6.81393 47.7193 6.5184 47.5612C6.22287 47.4031 5.96326 47.1856 5.75586 46.9223L5.83313 46.4329C6.11149 46.2364 6.42868 46.1018 6.76338 46.0382C7.09809 45.9745 7.44254 45.9832 7.7736 46.0637L9.6282 46.2784C10.8646 46.4329 12.0924 46.6304 13.3202 46.8107C15.7845 47.1971 18.2315 47.6693 20.6871 48.09C23.1428 48.5107 25.5898 48.9486 28.0283 49.4638C30.4668 49.979 32.9224 50.434 35.3608 50.9836L35.3093 51.1038Z"
        fill="white"
      />
      <path
        d="M48.1113 73.2207C47.2527 76.7668 46.394 80.3128 45.5354 83.8417L44.2046 89.148C43.9899 90.0066 43.7581 90.8652 43.5177 91.7839C43.3803 92.2218 43.2429 92.6425 43.097 93.089C43.045 93.3346 42.9417 93.5664 42.7937 93.7691C42.6457 93.9719 42.4564 94.141 42.2384 94.2653L41.8863 94.1623C41.7714 93.9415 41.7037 93.6993 41.6874 93.451C41.6712 93.2026 41.7067 92.9537 41.7919 92.7198C41.8863 92.2733 42.0065 91.8612 42.1267 91.389C42.3757 90.5303 42.6419 89.6717 42.9338 88.8131L44.6081 83.6099C45.6986 80.1755 46.8749 76.6809 48.0426 73.2207H48.1113Z"
        fill="white"
      />
      <path
        d="M40.3793 74.1589C39.5207 75.9277 38.6192 77.6707 37.709 79.4051C36.7989 81.1395 35.8373 82.8395 34.91 84.5567C33.9827 86.274 33.0124 87.9912 32.025 89.7084C31.5184 90.567 31.0205 91.4257 30.4881 92.207L29.681 93.4348C29.5525 93.6844 29.3647 93.8986 29.1342 94.0587C28.9037 94.2189 28.6374 94.3201 28.3587 94.3535L27.938 94.0959C27.8337 93.8356 27.7979 93.553 27.8339 93.2749C27.87 92.9968 27.9768 92.7326 28.1441 92.5075L28.8567 91.2196C29.3376 90.361 29.8527 89.5024 30.3593 88.7038C31.3982 87.0381 32.4887 85.4068 33.5705 83.7754C34.6524 82.1441 35.7342 80.5127 36.859 78.8985C37.9838 77.2843 39.1 75.6787 40.2935 74.1074L40.3793 74.1589Z"
        fill="white"
      />
      <g opacity="0.3">
        <path
          opacity="0.3"
          d="M37.8894 43.2624C33.7452 40.9728 29.6124 38.6516 25.491 36.299L19.3348 32.7444C18.3044 32.1605 17.2655 31.5853 16.2695 30.9499C15.7715 30.6322 15.2735 30.3059 14.7927 29.9711C14.5028 29.8197 14.2486 29.6081 14.0472 29.3505C13.8457 29.0928 13.7018 28.795 13.625 28.4771L13.8826 28.0564C14.201 27.9864 14.5305 27.9839 14.8499 28.0489C15.1693 28.114 15.4715 28.2452 15.7372 28.4341C16.2609 28.7175 16.7675 29.018 17.2741 29.2928C18.2959 29.9024 19.2747 30.5721 20.2535 31.2332L26.1865 35.1571C30.119 37.7902 34.0428 40.449 37.9581 43.1336L37.8894 43.2624Z"
          fill="black"
        />
        <path
          opacity="0.3"
          d="M41.8344 37.6107C39.5848 34.2878 37.3181 30.9736 35.1286 27.6164L31.8058 22.5935C31.2391 21.7349 30.6982 20.9278 30.1658 20.0778C29.9083 19.6485 29.6507 19.2192 29.4103 18.7727C29.0916 18.3279 28.9563 17.7776 29.0325 17.2358L29.3502 17.0469C29.8686 17.217 30.302 17.5797 30.5608 18.06C30.847 18.4722 31.1332 18.8929 31.4194 19.3222C31.9432 20.1808 32.4583 21.0394 32.9478 21.898L35.9701 27.1012C37.9964 30.5357 39.9626 34.0646 41.9804 37.5506L41.8344 37.6107Z"
          fill="black"
        />
        <path
          opacity="0.3"
          d="M39.3997 69.8281C37.4936 71.863 35.5531 73.8549 33.604 75.8383C31.655 77.8217 29.6716 79.7708 27.7054 81.737C25.7391 83.7032 23.7557 85.6437 21.7466 87.567C20.7334 88.5115 19.7289 89.4731 18.6813 90.3918L17.1273 91.7742C16.6216 92.3367 15.9158 92.6788 15.161 92.7273L14.8262 92.3666C14.9229 91.6184 15.308 90.9375 15.8994 90.4691L17.3934 89.0095C18.3808 88.0306 19.4026 87.0948 20.4157 86.1417C22.4678 84.2699 24.5543 82.4325 26.6407 80.5951C28.7271 78.7576 30.8136 76.9202 32.9257 75.1085C35.0379 73.2968 37.1501 71.4938 39.3138 69.7422L39.3997 69.8281Z"
          fill="black"
        />
        <path
          opacity="0.3"
          d="M29.7231 59.7233C27.3276 60.5819 24.9149 61.3461 22.4936 62.1189C20.0723 62.8916 17.651 63.6128 15.2297 64.3684C12.8084 65.124 10.3785 65.8366 7.94009 66.5235C6.71227 66.8498 5.49303 67.2018 4.26521 67.4852L2.41061 67.9317C2.08664 68.0511 1.74125 68.1013 1.39669 68.0791C1.05213 68.0569 0.716035 67.9628 0.410043 67.8029L0.28125 67.3221C0.463913 67.0286 0.706894 66.7773 0.994071 66.5848C1.28125 66.3924 1.60606 66.2632 1.94696 66.2059L3.77581 65.6649C4.98645 65.2871 6.21427 64.978 7.4335 64.6518C9.88913 64.0164 12.3534 63.4411 14.8262 62.8573C17.299 62.2734 19.7632 61.6981 22.236 61.14C24.7088 60.5819 27.1988 60.0668 29.6888 59.5859L29.7231 59.7233Z"
          fill="black"
        />
        <path
          opacity="0.3"
          d="M35.3093 51.1018C32.8108 50.9301 30.3294 50.6897 27.8566 50.4407C25.3838 50.1917 22.9024 49.8997 20.421 49.6336C17.9396 49.3674 15.4754 49.0669 13.0026 48.7234C11.7747 48.5431 10.5383 48.38 9.31052 48.1653L7.47309 47.8391C7.13897 47.8127 6.81393 47.7174 6.5184 47.5593C6.22287 47.4012 5.96326 47.1836 5.75586 46.9204L5.83313 46.431C6.11149 46.2345 6.42868 46.0999 6.76338 46.0362C7.09809 45.9725 7.44254 45.9812 7.7736 46.0617L9.6282 46.2764C10.8646 46.431 12.0924 46.6284 13.3202 46.8087C15.7845 47.1951 18.2315 47.6674 20.6871 48.0881C23.1428 48.5088 25.5898 48.9467 28.0283 49.4619C30.4668 49.977 32.9224 50.4321 35.3608 50.9816L35.3093 51.1018Z"
          fill="black"
        />
        <path
          opacity="0.3"
          d="M48.1015 73.2188C47.2429 76.7648 46.3843 80.3109 45.5257 83.8398L44.1948 89.146C43.9802 90.0046 43.7483 90.8632 43.5079 91.782C43.3706 92.2199 43.2332 92.6406 43.0872 93.0871C43.0353 93.3326 42.9319 93.5644 42.7839 93.7672C42.6359 93.9699 42.4467 94.139 42.2286 94.2634L41.8766 94.1603C41.7617 93.9396 41.6939 93.6973 41.6777 93.449C41.6614 93.2007 41.697 92.9517 41.7821 92.7179C41.8766 92.2714 41.9968 91.8592 42.117 91.387C42.366 90.5284 42.6321 89.6698 42.9241 88.8112L44.5984 83.608C45.6888 80.1735 46.8651 76.679 48.0328 73.2188H48.1015Z"
          fill="black"
        />
      </g>
      <g opacity="0.5">
        <path
          opacity="0.5"
          d="M46.5161 48.9132C45.4428 48.0546 44.3953 47.2475 43.3564 46.3975L41.8281 45.0924C41.5644 44.8859 41.3122 44.6652 41.0725 44.4313C40.9609 44.3025 40.8492 44.1823 40.7462 44.0449C40.6432 43.9075 40.5487 43.7701 40.7462 43.3408L41.0124 43.0832C41.4331 42.8944 41.5877 42.9716 41.7164 43.0832C41.8483 43.1819 41.9744 43.288 42.0942 43.4009C42.3332 43.6384 42.5569 43.8907 42.7639 44.1565L44.069 45.6934C44.9277 46.7324 45.7863 47.7713 46.5848 48.8445L46.5161 48.9132Z"
          fill="white"
        />
        <path
          opacity="0.5"
          d="M44.3417 54.2655C43.0023 54.4458 41.6714 54.5832 40.332 54.712L38.3228 54.8751C37.9898 54.9194 37.6542 54.9423 37.3183 54.9438H36.8203C36.6571 54.9438 36.4854 54.8837 36.3223 54.4458V54.068C36.4854 53.6387 36.6571 53.5872 36.8203 53.5786C36.9862 53.5701 37.1524 53.5701 37.3183 53.5786C37.6542 53.5801 37.9898 53.6031 38.3228 53.6473L40.332 53.8019C41.6714 53.9393 43.0023 54.0766 44.3417 54.2569V54.2655Z"
          fill="white"
        />
        <path
          opacity="0.5"
          d="M46.5792 59.589C45.7549 60.6537 44.9164 61.7041 44.0635 62.7401L42.7584 64.2684C42.5558 64.5379 42.3318 64.7905 42.0887 65.024L41.7109 65.3503C41.5821 65.4619 41.4275 65.5478 41.0068 65.3503L40.7407 65.0841C40.5518 64.6634 40.6376 64.5089 40.7407 64.3886C40.8426 64.2543 40.9515 64.1253 41.0669 64.0023C41.3023 63.7638 41.5549 63.5428 41.8225 63.3411L43.3508 62.036C44.3898 61.1774 45.4373 60.3188 46.5105 59.5117L46.5792 59.589Z"
          fill="white"
        />
        <path
          opacity="0.5"
          d="M47.4644 43.6552C46.3654 41.7491 45.4123 39.7743 44.4507 37.808C43.9785 36.8206 43.5062 35.8418 43.0683 34.8372C42.8623 34.3307 42.639 33.8327 42.4587 33.3175L42.1754 32.5447C42.1023 32.3914 42.0763 32.2198 42.1007 32.0517C42.1252 31.8835 42.199 31.7264 42.3127 31.6003L42.6562 31.4629C42.8252 31.4715 42.988 31.5299 43.1239 31.6307C43.2599 31.7315 43.363 31.8703 43.4204 32.0296L43.7638 32.7766C44.0042 33.266 44.2017 33.7726 44.4164 34.2791C44.8113 35.3009 45.1719 36.3312 45.5325 37.3616C46.2452 39.4308 46.9664 41.5001 47.5417 43.6208L47.4644 43.6552Z"
          fill="white"
        />
        <path
          opacity="0.5"
          d="M41.3267 49.8846C39.1973 49.3094 37.1367 48.5881 35.0674 47.8755C34.0371 47.5063 32.9982 47.1457 31.985 46.7507C31.4784 46.5446 30.9718 46.3472 30.4738 46.1067L29.7354 45.7547C29.5746 45.6988 29.434 45.5962 29.3315 45.4602C29.2291 45.3241 29.1694 45.1606 29.1602 44.9905L29.3061 44.6213C29.4323 44.5076 29.5894 44.4338 29.7575 44.4093C29.9256 44.3849 30.0972 44.4108 30.2506 44.484L31.0148 44.7673C31.5385 44.939 32.0365 45.1623 32.5431 45.3683C33.5391 45.8062 34.5265 46.287 35.5139 46.7593C37.4801 47.7123 39.4549 48.6654 41.361 49.7644L41.3267 49.8846Z"
          fill="white"
        />
        <path
          opacity="0.5"
          d="M41.3239 58.6406C39.4178 59.7396 37.443 60.6841 35.4768 61.6457C34.4894 62.1179 33.502 62.5988 32.506 63.0281C31.9994 63.2427 31.5014 63.4574 30.9777 63.6377L30.2135 63.921C30.0601 63.9941 29.8885 64.0201 29.7204 63.9957C29.5523 63.9712 29.3952 63.8974 29.269 63.7837L29.123 63.4402C29.1323 63.2701 29.192 63.1067 29.2944 62.9706C29.3969 62.8345 29.5375 62.732 29.6983 62.676L30.4367 62.3326C30.9347 62.0922 31.4413 61.8947 31.9479 61.6886C32.9611 61.2851 34 60.9245 35.0303 60.5639C37.0996 59.8512 39.1602 59.13 41.2896 58.5547L41.3239 58.6406Z"
          fill="white"
        />
        <path
          opacity="0.5"
          d="M47.5391 64.8039C46.9638 66.9332 46.2425 69.0025 45.5299 71.0717C45.1693 72.1021 44.8087 73.1324 44.4137 74.1542C44.199 74.6522 44.0016 75.1673 43.7611 75.6567L43.4177 76.3951C43.3618 76.556 43.2592 76.6966 43.1231 76.7991C42.9871 76.9015 42.8236 76.9611 42.6535 76.9704L42.3101 76.8245C42.1981 76.699 42.1253 76.5435 42.1009 76.3771C42.0765 76.2108 42.1015 76.0409 42.1727 75.8886L42.4561 75.1158C42.6364 74.6007 42.8596 74.0941 43.0657 73.5961C43.5036 72.5915 43.9758 71.6041 44.448 70.6167C45.4097 68.6505 46.3627 66.6842 47.4618 64.7695L47.5391 64.8039Z"
          fill="white"
        />
        <path
          opacity="0.5"
          d="M48.7675 35.7609C47.2135 31.2016 45.7109 26.6338 44.2169 22.0231L42.0274 15.1542C41.6496 14.0122 41.2633 12.8702 40.9456 11.7197C40.791 11.1444 40.6365 10.5606 40.5077 10.0025C40.3925 9.69385 40.3444 9.36422 40.3666 9.03554C40.3888 8.70686 40.4808 8.38669 40.6365 8.09636L41.1087 7.95898C41.3945 8.12489 41.6423 8.34894 41.8361 8.61665C42.0298 8.88435 42.1652 9.18974 42.2335 9.51307C42.431 10.0798 42.6113 10.655 42.7916 11.2303C43.1351 12.3808 43.4098 13.5486 43.7017 14.7163L45.4791 21.714C46.6239 26.3848 47.7687 31.0614 48.9135 35.7437L48.7675 35.7609Z"
          fill="white"
        />
        <path
          opacity="0.5"
          d="M37.8894 43.2624C33.7452 40.9728 29.6124 38.6516 25.491 36.299L19.3348 32.7444C18.3044 32.1605 17.2655 31.5853 16.2695 30.9499C15.7715 30.6322 15.2735 30.3059 14.7927 29.9711C14.5028 29.8197 14.2486 29.6081 14.0472 29.3505C13.8457 29.0928 13.7018 28.795 13.625 28.4771L13.8826 28.0564C14.201 27.9864 14.5305 27.9839 14.8499 28.0489C15.1693 28.114 15.4715 28.2452 15.7372 28.4341C16.2609 28.7175 16.7675 29.018 17.2741 29.2928C18.2959 29.9024 19.2747 30.5721 20.2535 31.2332L26.1865 35.1571C30.119 37.7902 34.0428 40.449 37.9581 43.1336L37.8894 43.2624Z"
          fill="white"
        />
        <path
          opacity="0.5"
          d="M29.6921 42.7223C27.391 42.147 25.1071 41.5117 22.8232 40.8591C20.5393 40.2066 18.2811 39.5283 16.0144 38.8585C13.7477 38.1888 11.4809 37.5019 9.23135 36.7635C8.10657 36.3857 6.98178 36.0251 5.87417 35.613L4.20846 34.9948C3.8916 34.9175 3.59571 34.7713 3.3419 34.5665C3.0881 34.3616 2.88265 34.1033 2.74023 33.8099L2.89478 33.3377C3.19066 33.2002 3.51299 33.1289 3.83926 33.1289C4.16553 33.1289 4.48786 33.2002 4.78374 33.3377L6.50096 33.8528C7.63433 34.1963 8.75053 34.5912 9.86673 34.969C12.0991 35.7504 14.3143 36.5918 16.5296 37.4247C18.7448 38.2575 20.96 39.0904 23.1581 40.0005C25.3561 40.9106 27.5627 41.7177 29.7436 42.688L29.6921 42.7223Z"
          fill="white"
        />
        <path
          opacity="0.5"
          d="M36.1687 36.3615C34.3742 34.6958 32.6226 32.9786 30.8797 31.2613C29.1367 29.5441 27.4452 27.8269 25.728 26.0496C24.0108 24.2722 22.2935 22.5636 20.5763 20.7777C19.7177 19.9191 18.9106 18.9918 18.1121 18.0645L16.9014 16.6821C16.654 16.4543 16.4579 16.1765 16.3262 15.8671C16.1944 15.5577 16.13 15.2238 16.1373 14.8876L16.5065 14.5527C16.8409 14.5739 17.167 14.666 17.4631 14.8229C17.7592 14.9798 18.0184 15.198 18.2237 15.4629L19.5116 16.7765C20.3702 17.6352 21.1773 18.5625 22.0102 19.464C23.6416 21.2843 25.2386 23.1389 26.8442 24.9935C28.4498 26.8481 30.0382 28.7027 31.6009 30.5916C33.1636 32.4806 34.7348 34.3524 36.2546 36.2842L36.1687 36.3615Z"
          fill="white"
        />
        <path
          opacity="0.5"
          d="M41.8403 37.6146C39.5907 34.2917 37.324 30.9775 35.1345 27.6203L31.8117 22.5974C31.245 21.7388 30.704 20.9317 30.1717 20.0817C29.9141 19.6524 29.6565 19.2231 29.4161 18.7766C29.0974 18.3319 28.9621 17.7815 29.0383 17.2397L29.356 17.0508C29.8745 17.2209 30.3078 17.5836 30.5667 18.0639C30.8529 18.4761 31.1391 18.8968 31.4253 19.3261C31.949 20.1847 32.4642 21.0433 32.9536 21.9019L35.9759 27.1051C38.0023 30.5396 39.9685 34.0685 41.9862 37.5545L41.8403 37.6146Z"
          fill="white"
        />
        <path
          opacity="0.5"
          d="M37.2299 64.764C33.3775 67.4257 29.5052 70.0587 25.6128 72.6632L19.7571 76.5356C18.7868 77.1881 17.8166 77.8492 16.8206 78.4503C16.314 78.7508 15.8075 79.0427 15.2923 79.3089C15.0309 79.4962 14.7327 79.6259 14.4174 79.6895C14.1022 79.7532 13.7771 79.7493 13.4634 79.6781L13.1973 79.2574C13.2752 78.9434 13.4178 78.6491 13.6159 78.3934C13.8141 78.1376 14.0633 77.926 14.3478 77.772C14.8286 77.4371 15.318 77.1194 15.8075 76.8017C16.7949 76.1749 17.8166 75.6082 18.8384 75.033L24.9173 71.5213C28.9872 69.2116 33.0656 66.9105 37.1612 64.6523L37.2299 64.764Z"
          fill="white"
        />
        <path
          opacity="0.5"
          d="M39.3997 69.8339C37.4936 71.8688 35.5531 73.8608 33.604 75.8442C31.655 77.8276 29.6716 79.7766 27.7054 81.7429C25.7391 83.7091 23.7557 85.6496 21.7466 87.5729C20.7334 88.5173 19.7289 89.479 18.6813 90.3977L17.1273 91.7801C16.6216 92.3425 15.9158 92.6846 15.161 92.7331L14.8262 92.3725C14.9229 91.6243 15.308 90.9434 15.8994 90.475L17.3934 89.0153C18.3808 88.0365 19.4026 87.1006 20.4157 86.1476C22.4678 84.2758 24.5543 82.4383 26.6407 80.6009C28.7271 78.7635 30.8136 76.9261 32.9257 75.1144C35.0379 73.3027 37.1501 71.4996 39.3138 69.748L39.3997 69.8339Z"
          fill="white"
        />
        <path
          opacity="0.5"
          d="M29.7231 59.7233C27.3276 60.5819 24.9149 61.3461 22.4936 62.1189C20.0723 62.8916 17.651 63.6128 15.2297 64.3684C12.8084 65.124 10.3785 65.8366 7.94009 66.5235C6.71227 66.8498 5.49303 67.2018 4.26521 67.4852L2.41061 67.9317C2.08664 68.0511 1.74125 68.1013 1.39669 68.0791C1.05213 68.0569 0.716035 67.9628 0.410043 67.8029L0.28125 67.3221C0.463913 67.0286 0.706894 66.7773 0.994071 66.5848C1.28125 66.3924 1.60606 66.2632 1.94696 66.2059L3.77581 65.6649C4.98645 65.2871 6.21427 64.978 7.4335 64.6518C9.88913 64.0164 12.3534 63.4411 14.8262 62.8573C17.299 62.2734 19.7632 61.6981 22.236 61.14C24.7088 60.5819 27.1988 60.0668 29.6888 59.5859L29.7231 59.7233Z"
          fill="white"
        />
        <path
          opacity="0.5"
          d="M33.1024 54.9948C29.668 55.5615 26.2851 56.0939 22.8764 56.6176L17.7247 57.3646C16.8661 57.502 16.0075 57.6394 15.1488 57.7252C14.7195 57.7252 14.2902 57.8111 13.8609 57.8368C13.6261 57.8806 13.3846 57.8725 13.1532 57.8132C12.9219 57.7539 12.7063 57.6447 12.5215 57.4934V57.1242C12.6687 56.9366 12.8545 56.7827 13.0664 56.6731C13.2782 56.5635 13.5111 56.5007 13.7493 56.4888L15.0286 56.2999C15.8873 56.1883 16.7459 56.1368 17.6045 56.0681L22.7562 55.6388C26.1906 55.3698 29.6251 55.1236 33.0595 54.9004L33.1024 54.9948Z"
          fill="white"
        />
        <path
          opacity="0.5"
          d="M35.3093 51.1038C32.8108 50.932 30.3294 50.6916 27.8566 50.4426C25.3838 50.1936 22.9024 49.9017 20.421 49.6355C17.9396 49.3694 15.4754 49.0688 13.0026 48.7254C11.7747 48.5451 10.5383 48.382 9.31052 48.1673L7.47309 47.841C7.13897 47.8146 6.81393 47.7193 6.5184 47.5612C6.22287 47.4031 5.96326 47.1856 5.75586 46.9223L5.83313 46.4329C6.11149 46.2364 6.42868 46.1018 6.76338 46.0382C7.09809 45.9745 7.44254 45.9832 7.7736 46.0637L9.6282 46.2784C10.8646 46.4329 12.0924 46.6304 13.3202 46.8107C15.7845 47.1971 18.2315 47.6693 20.6871 48.09C23.1428 48.5107 25.5898 48.9486 28.0283 49.4638C30.4668 49.979 32.9224 50.434 35.3608 50.9836L35.3093 51.1038Z"
          fill="white"
        />
        <path
          opacity="0.5"
          d="M48.1113 73.2207C47.2527 76.7668 46.394 80.3128 45.5354 83.8417L44.2046 89.148C43.9899 90.0066 43.7581 90.8652 43.5177 91.7839C43.3803 92.2218 43.2429 92.6425 43.097 93.089C43.045 93.3346 42.9417 93.5664 42.7937 93.7691C42.6457 93.9719 42.4564 94.141 42.2384 94.2653L41.8863 94.1623C41.7714 93.9415 41.7037 93.6993 41.6874 93.451C41.6712 93.2026 41.7067 92.9537 41.7919 92.7198C41.8863 92.2733 42.0065 91.8612 42.1267 91.389C42.3757 90.5303 42.6419 89.6717 42.9338 88.8131L44.6081 83.6099C45.6986 80.1755 46.8749 76.6809 48.0426 73.2207H48.1113Z"
          fill="white"
        />
        <path
          opacity="0.5"
          d="M40.3793 74.1589C39.5207 75.9277 38.6192 77.6707 37.709 79.4051C36.7989 81.1395 35.8373 82.8395 34.91 84.5567C33.9827 86.274 33.0124 87.9912 32.025 89.7084C31.5184 90.567 31.0205 91.4257 30.4881 92.207L29.681 93.4348C29.5525 93.6844 29.3647 93.8986 29.1342 94.0587C28.9037 94.2189 28.6374 94.3201 28.3587 94.3535L27.938 94.0959C27.8337 93.8356 27.7979 93.553 27.8339 93.2749C27.87 92.9968 27.9768 92.7326 28.1441 92.5075L28.8567 91.2196C29.3376 90.361 29.8527 89.5024 30.3593 88.7038C31.3982 87.0381 32.4887 85.4068 33.5705 83.7754C34.6524 82.1441 35.7342 80.5127 36.859 78.8985C37.9838 77.2843 39.1 75.6787 40.2935 74.1074L40.3793 74.1589Z"
          fill="white"
        />
        <g opacity="0.3">
          <path
            opacity="0.3"
            d="M37.8894 43.2624C33.7452 40.9728 29.6124 38.6516 25.491 36.299L19.3348 32.7444C18.3044 32.1605 17.2655 31.5853 16.2695 30.9499C15.7715 30.6322 15.2735 30.3059 14.7927 29.9711C14.5028 29.8197 14.2486 29.6081 14.0472 29.3505C13.8457 29.0928 13.7018 28.795 13.625 28.4771L13.8826 28.0564C14.201 27.9864 14.5305 27.9839 14.8499 28.0489C15.1693 28.114 15.4715 28.2452 15.7372 28.4341C16.2609 28.7175 16.7675 29.018 17.2741 29.2928C18.2959 29.9024 19.2747 30.5721 20.2535 31.2332L26.1865 35.1571C30.119 37.7902 34.0428 40.449 37.9581 43.1336L37.8894 43.2624Z"
            fill="white"
          />
          <path
            opacity="0.3"
            d="M41.8344 37.6107C39.5848 34.2878 37.3181 30.9736 35.1286 27.6164L31.8058 22.5935C31.2391 21.7349 30.6982 20.9278 30.1658 20.0778C29.9083 19.6485 29.6507 19.2192 29.4103 18.7727C29.0916 18.3279 28.9563 17.7776 29.0325 17.2358L29.3502 17.0469C29.8686 17.217 30.302 17.5797 30.5608 18.06C30.847 18.4722 31.1332 18.8929 31.4194 19.3222C31.9432 20.1808 32.4583 21.0394 32.9478 21.898L35.9701 27.1012C37.9964 30.5357 39.9626 34.0646 41.9804 37.5506L41.8344 37.6107Z"
            fill="white"
          />
          <path
            opacity="0.3"
            d="M39.3997 69.8281C37.4936 71.863 35.5531 73.8549 33.604 75.8383C31.655 77.8217 29.6716 79.7708 27.7054 81.737C25.7391 83.7032 23.7557 85.6437 21.7466 87.567C20.7334 88.5115 19.7289 89.4731 18.6813 90.3918L17.1273 91.7742C16.6216 92.3367 15.9158 92.6788 15.161 92.7273L14.8262 92.3666C14.9229 91.6184 15.308 90.9375 15.8994 90.4691L17.3934 89.0095C18.3808 88.0306 19.4026 87.0948 20.4157 86.1417C22.4678 84.2699 24.5543 82.4325 26.6407 80.5951C28.7271 78.7576 30.8136 76.9202 32.9257 75.1085C35.0379 73.2968 37.1501 71.4938 39.3138 69.7422L39.3997 69.8281Z"
            fill="white"
          />
          <path
            opacity="0.3"
            d="M29.7231 59.7233C27.3276 60.5819 24.9149 61.3461 22.4936 62.1189C20.0723 62.8916 17.651 63.6128 15.2297 64.3684C12.8084 65.124 10.3785 65.8366 7.94009 66.5235C6.71227 66.8498 5.49303 67.2018 4.26521 67.4852L2.41061 67.9317C2.08664 68.0511 1.74125 68.1013 1.39669 68.0791C1.05213 68.0569 0.716035 67.9628 0.410043 67.8029L0.28125 67.3221C0.463913 67.0286 0.706894 66.7773 0.994071 66.5848C1.28125 66.3924 1.60606 66.2632 1.94696 66.2059L3.77581 65.6649C4.98645 65.2871 6.21427 64.978 7.4335 64.6518C9.88913 64.0164 12.3534 63.4411 14.8262 62.8573C17.299 62.2734 19.7632 61.6981 22.236 61.14C24.7088 60.5819 27.1988 60.0668 29.6888 59.5859L29.7231 59.7233Z"
            fill="white"
          />
          <path
            opacity="0.3"
            d="M35.3093 51.1018C32.8108 50.9301 30.3294 50.6897 27.8566 50.4407C25.3838 50.1917 22.9024 49.8997 20.421 49.6336C17.9396 49.3674 15.4754 49.0669 13.0026 48.7234C11.7747 48.5431 10.5383 48.38 9.31052 48.1653L7.47309 47.8391C7.13897 47.8127 6.81393 47.7174 6.5184 47.5593C6.22287 47.4012 5.96326 47.1836 5.75586 46.9204L5.83313 46.431C6.11149 46.2345 6.42868 46.0999 6.76338 46.0362C7.09809 45.9725 7.44254 45.9812 7.7736 46.0617L9.6282 46.2764C10.8646 46.431 12.0924 46.6284 13.3202 46.8087C15.7845 47.1951 18.2315 47.6674 20.6871 48.0881C23.1428 48.5088 25.5898 48.9467 28.0283 49.4619C30.4668 49.977 32.9224 50.4321 35.3608 50.9816L35.3093 51.1018Z"
            fill="white"
          />
          <path
            opacity="0.3"
            d="M48.1015 73.2188C47.2429 76.7648 46.3843 80.3109 45.5257 83.8398L44.1948 89.146C43.9802 90.0046 43.7483 90.8632 43.5079 91.782C43.3706 92.2199 43.2332 92.6406 43.0872 93.0871C43.0353 93.3326 42.9319 93.5644 42.7839 93.7672C42.6359 93.9699 42.4467 94.139 42.2286 94.2634L41.8766 94.1603C41.7617 93.9396 41.6939 93.6973 41.6777 93.449C41.6614 93.2007 41.697 92.9517 41.7821 92.7179C41.8766 92.2714 41.9968 91.8592 42.117 91.387C42.366 90.5284 42.6321 89.6698 42.9241 88.8112L44.5984 83.608C45.6888 80.1735 46.8651 76.679 48.0328 73.2188H48.1015Z"
            fill="white"
          />
        </g>
      </g>
    </svg>
  ),

  PAYMENTS: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 96 960 960"
      width="48"
      fill="currentColor"
    >
      <path d="M540 636q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35ZM220 776q-24.75 0-42.375-17.625T160 716V316q0-24.75 17.625-42.375T220 256h640q24.75 0 42.375 17.625T920 316v400q0 24.75-17.625 42.375T860 776H220Zm100-60h440q0-42 29-71t71-29V416q-42 0-71-29t-29-71H320q0 42-29 71t-71 29v200q42 0 71 29t29 71Zm480 180H100q-24.75 0-42.375-17.625T40 836V376h60v460h700v60ZM220 716V316v400Z" />
    </svg>
  ),

  PROTECT: <SafetyCertificateOutlined />,
  // q
  // r
  RESTORE: <i className="fas fa-window-restore" />,

  // s
  SEND: <SendOutlined />,
  SETTINGS: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 96 960 960"
      width="48"
      fill="currentColor"
    >
      <path d="m388 976-20-126q-19-7-40-19t-37-25l-118 54-93-164 108-79q-2-9-2.5-20.5T185 576q0-9 .5-20.5T188 535L80 456l93-164 118 54q16-13 37-25t40-18l20-127h184l20 126q19 7 40.5 18.5T669 346l118-54 93 164-108 77q2 10 2.5 21.5t.5 21.5q0 10-.5 21t-2.5 21l108 78-93 164-118-54q-16 13-36.5 25.5T592 850l-20 126H388Zm92-270q54 0 92-38t38-92q0-54-38-92t-92-38q-54 0-92 38t-38 92q0 54 38 92t92 38Zm0-60q-29 0-49.5-20.5T410 576q0-29 20.5-49.5T480 506q29 0 49.5 20.5T550 576q0 29-20.5 49.5T480 646Zm0-70Zm-44 340h88l14-112q33-8 62.5-25t53.5-41l106 46 40-72-94-69q4-17 6.5-33.5T715 576q0-17-2-33.5t-7-33.5l94-69-40-72-106 46q-23-26-52-43.5T538 348l-14-112h-88l-14 112q-34 7-63.5 24T306 414l-106-46-40 72 94 69q-4 17-6.5 33.5T245 576q0 17 2.5 33.5T254 643l-94 69 40 72 106-46q24 24 53.5 41t62.5 25l14 112Z" />
    </svg>
  ),

  STREAM: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 96 960 960"
      width="48"
      fill="currentColor"
    >
      <path d="M197 859q-54-54-85.5-126.5T80 576q0-84 31.5-156.5T197 293l43 43q-46 46-73 107.5T140 576q0 71 26.5 132T240 816l-43 43Zm113-113q-32-32-51-75.5T240 576q0-51 19-94.5t51-75.5l43 43q-24 24-38.5 56.5T300 576q0 38 14 70t39 57l-43 43Zm170-90q-33 0-56.5-23.5T400 576q0-33 23.5-56.5T480 496q33 0 56.5 23.5T560 576q0 33-23.5 56.5T480 656Zm170 90-43-43q24-24 38.5-56.5T660 576q0-38-14-70t-39-57l43-43q32 32 51 75.5t19 94.5q0 50-19 93.5T650 746Zm113 113-43-43q46-46 73-107.5T820 576q0-71-26.5-132T720 336l43-43q54 55 85.5 127.5T880 576q0 83-31.5 155.5T763 859Z"></path>
    </svg>
  ),

  STREAMING: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="20"
      viewBox="0 90 900 900"
      width="15"
      fill="currentColor"
    >
      <path d="M197 859q-54-54-85.5-126.5T80 576q0-84 31.5-156.5T197 293l43 43q-46 46-73 107.5T140 576q0 71 26.5 132T240 816l-43 43Zm113-113q-32-32-51-75.5T240 576q0-51 19-94.5t51-75.5l43 43q-24 24-38.5 56.5T300 576q0 38 14 70t39 57l-43 43Zm170-90q-33 0-56.5-23.5T400 576q0-33 23.5-56.5T480 496q33 0 56.5 23.5T560 576q0 33-23.5 56.5T480 656Zm170 90-43-43q24-24 38.5-56.5T660 576q0-38-14-70t-39-57l43-43q32 32 51 75.5t19 94.5q0 50-19 93.5T650 746Zm113 113-43-43q46-46 73-107.5T820 576q0-71-26.5-132T720 336l43-43q54 55 85.5 127.5T880 576q0 83-31.5 155.5T763 859Z"></path>
    </svg>
  ),

  SUBSCRIPTION: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 -960 960 960"
      width="48"
    >
      <path d="M480-40q-112 0-216-66T100-257v137H40v-240h240v60H143q51 77 145.5 138.5T480-100q78 0 147.5-30t121-81.5Q800-263 830-332.5T860-480h60q0 91-34.5 171T791-169q-60 60-140 94.5T480-40Zm-29-153v-54q-45-12-75.5-38.5T324-358l51-17q12 38 42.5 60t69.5 22q40 0 66.5-19.5T580-364q0-33-25-55.5T463-470q-60-25-90-54t-30-78q0-44 30-75t80-38v-51h55v51q38 4 66 24t45 55l-48 23q-15-28-37-42t-52-14q-39 0-61.5 18T398-602q0 32 26 51t84 43q69 29 98 61t29 83q0 25-9 46t-25.5 36Q584-267 560-257.5T506-245v52h-55ZM40-480q0-91 34.5-171T169-791q60-60 140-94.5T480-920q112 0 216 66t164 151v-137h60v240H680v-60h137q-51-77-145-138.5T480-860q-78 0-147.5 30t-121 81.5Q160-697 130-627.5T100-480H40Z" />
    </svg>
  ),

  SOUNDTRACKS: <i className="fa fa-music" />,
  // <FontAwesomeIcon icon="fa-solid fa-chart-simple" />

  STATISTICS: (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 448 512">
      <path d="M160 80c0-26.5 21.5-48 48-48h32c26.5 0 48 21.5 48 48V432c0 26.5-21.5 48-48 48H208c-26.5 0-48-21.5-48-48V80zM0 272c0-26.5 21.5-48 48-48H80c26.5 0 48 21.5 48 48V432c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V272zM368 96h32c26.5 0 48 21.5 48 48V432c0 26.5-21.5 48-48 48H368c-26.5 0-48-21.5-48-48V144c0-26.5 21.5-48 48-48z" />
    </svg>
  ),

  STOP: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 24 24"
      height="24px"
      viewBox="0 0 24 24"
      width="24px"
      fill="#e8eae"
    >
      <rect fill="none" height="24" width="24" />
      <path d="M12,2C6.48,2,2,6.48,2,12c0,5.52,4.48,10,10,10s10-4.48,10-10C22,6.48,17.52,2,12,2z M12,20c-4.42,0-8-3.58-8-8s3.58-8,8-8 s8,3.58,8,8S16.42,20,12,20z M16,16H8V8h8V16z" />
    </svg>
  ),

  SECONDARY_BACK: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M5.25 11.25H20.25C20.4489 11.25 20.6397 11.329 20.7803 11.4697C20.921 11.6103 21 11.8011 21 12C21 12.1989 20.921 12.3897 20.7803 12.5303C20.6397 12.671 20.4489 12.75 20.25 12.75H5.25C5.05109 12.75 4.86032 12.671 4.71967 12.5303C4.57902 12.3897 4.5 12.1989 4.5 12C4.5 11.8011 4.57902 11.6103 4.71967 11.4697C4.86032 11.329 5.05109 11.25 5.25 11.25Z"
        fill="#0F87B2"
      />
      <path
        d="M5.5605 12L11.781 18.219C11.9218 18.3598 12.0009 18.5508 12.0009 18.75C12.0009 18.9491 11.9218 19.1401 11.781 19.281C11.6402 19.4218 11.4492 19.5009 11.25 19.5009C11.0508 19.5009 10.8598 19.4218 10.719 19.281L3.969 12.531C3.89915 12.4613 3.84374 12.3785 3.80593 12.2874C3.76812 12.1963 3.74866 12.0986 3.74866 12C3.74866 11.9013 3.76812 11.8036 3.80593 11.7125C3.84374 11.6214 3.89915 11.5386 3.969 11.469L10.719 4.71897C10.8598 4.57814 11.0508 4.49902 11.25 4.49902C11.4492 4.49902 11.6402 4.57814 11.781 4.71897C11.9218 4.8598 12.0009 5.05081 12.0009 5.24997C12.0009 5.44913 11.9218 5.64014 11.781 5.78097L5.5605 12Z"
        fill="#0F87B2"
      />
    </svg>
  ),

  SPLASH: (
    <svg
      stroke="currentColor"
      fill="none"
      stroke-width="2"
      viewBox="0 0 24 24"
      stroke-linecap="round"
      stroke-linejoin="round"
      height="18px"
      width="18px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M4 11h5v4h6v-4h5v9h-16zm5 -7h6v4h-6z"></path>
    </svg>
  ),

  // t
  TARIFF: <TagsOutlined />,

  TV: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 24 24"
      height="48"
      viewBox="0 96 960 960"
      width="48"
      fill="#e8eaed"
    >
      <path d="M170 936v-80h-30q-24.75 0-42.375-17.625T80 796V316q0-24.75 17.625-42.375T140 256h680q24.75 0 42.375 17.625T880 316v480q0 24.75-17.625 42.375T820 856h-30v80h-23l-26-80H220l-27 80h-23Zm-30-140h680V316H140v480Zm340-240Z" />
    </svg>
  ),
  TRANSCODING: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 96 960 960"
      width="48"
      fill="currentColor"
    >
      <path d="M271 936 80 745l192-192 42 42-120 120h649v60H194l119 119-42 42Zm418-337-42-42 119-119H117v-60h649L646 258l42-42 192 192-191 191Z" />
    </svg>
  ),
  // u
  UNLOCK: <UnlockOutlined />,

  // UNPROTECT: <CloseCircleOutlined />,
  UNPROTECT: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      enableBackground="new 0 0 24 24"
      height="18px"
      viewBox="0 0 24 24"
      width="18px"
      fill="currentColor"
    >
      <g>
        <path d="M12,4.14l6,2.25v4.7c0,1.19-0.23,2.36-0.64,3.44l1.51,1.51c0.72-1.53,1.13-3.22,1.13-4.95V5l-8-3L6.78,3.96l1.55,1.55 L12,4.14z M2.81,2.81L1.39,4.22L4,6.83v4.26c0,5.05,3.41,9.76,8,10.91c1.72-0.43,3.28-1.36,4.55-2.62l3.23,3.23l1.41-1.41 L2.81,2.81z M12,19.92c-3.45-1.13-6-4.82-6-8.83V8.83l9.14,9.14C14.24,18.85,13.17,19.54,12,19.92z" />
      </g>
    </svg>
  ),

  USERS: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 96 960 960"
      width="48"
      fill="currentColor"
    >
      <path d="M38 896v-94q0-35 18-63.5t50-42.5q73-32 131.5-46T358 636q62 0 120 14t131 46q32 14 50.5 42.5T678 802v94H38Zm700 0v-94q0-63-32-103.5T622 633q69 8 130 23.5t99 35.5q33 19 52 47t19 63v94H738ZM358 575q-66 0-108-42t-42-108q0-66 42-108t108-42q66 0 108 42t42 108q0 66-42 108t-108 42Zm360-150q0 66-42 108t-108 42q-11 0-24.5-1.5T519 568q24-25 36.5-61.5T568 425q0-45-12.5-79.5T519 282q11-3 24.5-5t24.5-2q66 0 108 42t42 108ZM98 836h520v-34q0-16-9.5-31T585 750q-72-32-121-43t-106-11q-57 0-106.5 11T130 750q-14 6-23 21t-9 31v34Zm260-321q39 0 64.5-25.5T448 425q0-39-25.5-64.5T358 335q-39 0-64.5 25.5T268 425q0 39 25.5 64.5T358 515Zm0 321Zm0-411Z" />
    </svg>
  ),

  USERS_GROUP: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 -960 960 960"
      width="48"
    >
      <path d="M0-240v-53q0-38.567 41.5-62.784Q83-380 150.376-380q12.165 0 23.395.5Q185-379 196-377.348q-8 17.348-12 35.165T180-305v65H0Zm240 0v-65q0-32 17.5-58.5T307-410q32-20 76.5-30t96.5-10q53 0 97.5 10t76.5 30q32 20 49 46.5t17 58.5v65H240Zm540 0v-65q0-19.861-3.5-37.431Q773-360 765-377.273q11-1.727 22.171-2.227 11.172-.5 22.829-.5 67.5 0 108.75 23.768T960-293v53H780Zm-480-60h360v-6q0-37-50.5-60.5T480-390q-79 0-129.5 23.5T300-305v5ZM149.567-410Q121-410 100.5-430.562 80-451.125 80-480q0-29 20.562-49.5Q121.125-550 150-550q29 0 49.5 20.5t20.5 49.933Q220-451 199.5-430.5T149.567-410Zm660 0Q781-410 760.5-430.562 740-451.125 740-480q0-29 20.562-49.5Q781.125-550 810-550q29 0 49.5 20.5t20.5 49.933Q880-451 859.5-430.5T809.567-410ZM480-480q-50 0-85-35t-35-85q0-51 35-85.5t85-34.5q51 0 85.5 34.5T600-600q0 50-34.5 85T480-480Zm.351-60Q506-540 523-557.351t17-43Q540-626 522.851-643t-42.5-17Q455-660 437.5-642.851t-17.5 42.5Q420-575 437.351-557.5t43 17.5ZM480-300Zm0-300Z" />
    </svg>
  ),

  UNPUBLISH: <i className="fa fa-upload" />,

  // v
  VOD: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 96 960 960"
      width="48"
      fill="currentColor"
    >
      <path d="M314 853V689l132 82-132 82Zm206-382q-45.833 0-77.917-32.118-32.083-32.117-32.083-78Q410 315 442.083 283q32.084-32 77.917-32h40v40h-40q-29.167 0-49.583 20.382Q450 331.765 450 360.882 450 390 470.417 410.5 490.833 431 520 431h40v40h-40Zm120 0v-40h40q29.167 0 49.583-20.382Q750 390.235 750 361.118 750 332 729.583 311.5 709.167 291 680 291h-40v-40h40q45.833 0 77.917 32.118 32.083 32.117 32.083 78Q790 407 757.917 439 725.833 471 680 471h-40Zm-110-90v-40h140v40H530Zm123 265v-80h207V196H340v370h-60V196q0-24.75 17.625-42.375T340 136h520q24.75 0 42.375 17.625T920 196v390q0 24.75-17.625 42.375T860 646H653Zm-553 370q-24.75 0-42.375-17.625T40 956V586q0-24.75 17.625-42.375T100 526h520q24.75 0 42.375 17.625T680 586v370q0 24.75-17.625 42.375T620 1016H100Zm0-60h520V586H100v370Zm500-575ZM360 771Z" />
    </svg>
  ),
  VOLUME: (
    <svg
      stroke="currentColor"
      fill="currentColor"
      stroke-width="0"
      viewBox="0 0 24 24"
      height="18px"
      width="18px"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Volume_High">
        <g>
          <path d="M13.816,19.937a1.446,1.446,0,0,1-.717-.194L9.43,17.623a3.257,3.257,0,0,0-1.625-.434H4.439a2.379,2.379,0,0,1-2.375-2.376V9.187A2.378,2.378,0,0,1,4.439,6.812H7.805A3.257,3.257,0,0,0,9.43,6.376L13.1,4.259A1.437,1.437,0,0,1,15.255,5.5V18.5a1.424,1.424,0,0,1-.718,1.245A1.445,1.445,0,0,1,13.816,19.937ZM4.439,7.812A1.377,1.377,0,0,0,3.064,9.187v5.626a1.378,1.378,0,0,0,1.375,1.376H7.805a4.254,4.254,0,0,1,2.125.569L13.6,18.876a.439.439,0,0,0,.657-.38V5.5a.438.438,0,0,0-.657-.379L9.93,7.242a4.251,4.251,0,0,1-2.125.57Z"></path>
          <path d="M18.407,6.262a7.79,7.79,0,0,1,.021,11.476c-.474.437.235,1.143.707.707a8.793,8.793,0,0,0-.021-12.89c-.474-.434-1.184.272-.707.707Z"></path>
          <path d="M16.91,9.031a4.021,4.021,0,0,1,.012,5.938c-.474.438.234,1.143.707.707a5.025,5.025,0,0,0-.012-7.352c-.474-.434-1.183.272-.707.707Z"></path>
        </g>
      </g>
    </svg>
  ),
  // w
  WEB: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="48"
      viewBox="0 96 960 960"
      width="48"
      fill="currentColor"
    >
      <path d="M480 976q-84 0-157-31.5T196 859q-54-54-85-127.5T80 574q0-84 31-156.5T196 291q54-54 127-84.5T480 176q84 0 157 30.5T764 291q54 54 85 126.5T880 574q0 84-31 157.5T764 859q-54 54-127 85.5T480 976Zm0-58q35-36 58.5-82.5T577 725H384q14 60 37.5 108t58.5 85Zm-85-12q-25-38-43-82t-30-99H172q38 71 88 111.5T395 906Zm171-1q72-23 129.5-69T788 725H639q-13 54-30.5 98T566 905ZM152 665h159q-3-27-3.5-48.5T307 574q0-25 1-44.5t4-43.5H152q-7 24-9.5 43t-2.5 45q0 26 2.5 46.5T152 665Zm221 0h215q4-31 5-50.5t1-40.5q0-20-1-38.5t-5-49.5H373q-4 31-5 49.5t-1 38.5q0 21 1 40.5t5 50.5Zm275 0h160q7-24 9.5-44.5T820 574q0-26-2.5-45t-9.5-43H649q3 35 4 53.5t1 34.5q0 22-1.5 41.5T648 665Zm-10-239h150q-33-69-90.5-115T565 246q25 37 42.5 80T638 426Zm-254 0h194q-11-53-37-102.5T480 236q-32 27-54 71t-42 119Zm-212 0h151q11-54 28-96.5t43-82.5q-75 19-131 64t-91 115Z" />
    </svg>
  ),
  // x
  X: <CloseOutlined />,
  // y
  // z
};
