import { appHost } from "../../../helpers/appHost";
import request from "../../request";

const host = appHost;

const URL = {
  FAQ: `${host}admin/faq`,
};

export default {
  GET: (query) => request("GET", URL.FAQ, query),

  ADD: (query) => request("POST", URL.FAQ, query),

  EDIT: (query) => request("PUT", URL.FAQ, query),

  DELETE: (query) => request("DELETE", URL.FAQ, query),
};
