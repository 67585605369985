import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as actions from "../../redux-store/actions";

import requests from "../../server/requests";

import Messages from "./messages/Messages";

import Conversations from "./conversations/Conversations";

import QuickMessages from "./quick-messages/QuickMessages";

import PageComponent from "../../components/PageComponent";

import style from "./chat.module.scss";
import { bindActionCreators } from "redux";
import { useLocation } from "react-router-dom";

export default function ChatPage() {
    const dispatch = useDispatch();
    const { new_message } = useSelector((state) => state.globalState);
    const { setNewMessage } = bindActionCreators(actions, dispatch);

    const location = useLocation();

    const [conversations, setConversations] = useState([]);
    const [activeConversation, setActiveConversation] = useState();
    const [selectedQuickMessage, setSelectedQuickMessage] = useState(null);

    const getConversations = () => {
        function callback(data) {
            setConversations(data.rows);

            if (location?.state?.id) {
                if (new_message?.conversation_id && !activeConversation) {
                    const finded = data.rows.find(
                        (item) => item.id === new_message.conversation_id
                    );

                    if (finded) {
                        setActiveConversation(finded);

                        setNewMessage(null);
                    }
                }
            }
        }

        function errorCallback(err) {
            console.log(err, "err");
        }

        requests.chat.conversation.get(callback, errorCallback);
    };

    useEffect(() => {
        getConversations();
    }, []);

    useEffect(() => {
        if (new_message) {
            getConversations();
        }
    }, [new_message]);

    return (
        <PageComponent>
            <div className={style["page"]}>
                <h2>Messages</h2>
                <div
                    className={`${style["chat-container"]} ${
                        !activeConversation && style["two-section"]
                    }`}
                >
                    <Conversations
                        conversations={conversations}
                        activeConversation={activeConversation}
                        setActiveConversation={setActiveConversation}
                    />

                    {activeConversation ? (
                        <>
                            <Messages
                                getConversations={getConversations}
                                activeConversation={activeConversation}
                                setActiveConversation={setActiveConversation}
                                selectedQuickMessage={selectedQuickMessage}
                                setSelectedQuickMessage={setSelectedQuickMessage}
                            />

                            <QuickMessages setSelectedQuickMessage={setSelectedQuickMessage} />
                        </>
                    ) : (
                        <div className={style["select-chat"]}>
                            <div className={style["text"]}>Select a chat to start messaging</div>
                        </div>
                    )}
                </div>
            </div>
        </PageComponent>
    );
}
