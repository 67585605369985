import request from "../../../api/request";

import { DEMO_API_HOST } from "../../../config/statics";
import { appHost } from "../../../helpers/appHost";

const host = appHost;

const URL = {
  AVAILABLE_DEVICE: `${host}v2/admin/available_device`,
};

const _webAvailableDevicesApi = {
  GET: (query) => request("GET", URL.AVAILABLE_DEVICE, query),

  ADD: (query) => request("POST", URL.AVAILABLE_DEVICE, query),

  EDIT: (query) => request("PUT", URL.AVAILABLE_DEVICE, query),

  DELETE: (query) => request("DELETE", URL.AVAILABLE_DEVICE, query),
};

export default _webAvailableDevicesApi;
