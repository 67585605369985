import { useState } from "react";

import { notification } from "antd";
import PageComponent from "../../_components/page/PageComponent";
import REQUESTS from "../../api/requests";
import EpgTable from "./EpgTable";
import EpgDrawer from "./EpgDrawer";
import { useTranslation } from "react-i18next";

export default function EpgPage() {
  const { t } = useTranslation();
  const [getAgain, setGetAgain] = useState(false);

  const [isOpenDrawer, setIsOpenDrawer] = useState(false);

  const [editable, setEditable] = useState(null);

  const getDataSource = (query, onSuccess, onError) => {
    try {
      REQUESTS.EPG.GET({ query: JSON.stringify(query) })
        .then((response) => {
          onSuccess(response.message);
        })
        .catch((error) => {
          onError(error);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const update = () => {
    REQUESTS.EPG.UPDATE()
      .then((response) => {
        if (response.error) {
          notification.error({
            message: t("error"),
            description: response.message,
          });

          return;
        }

        notification.success({
          message: t("success"),
          description: response.message.message,
        });

        setGetAgain((prev) => !prev);
      })

      .catch((error) => {
        notification.error({
          message: t("error"),

          description: error,
        });
      });
  };

  const forceUpdate = () => {
    REQUESTS.EPG.FORCE_UPDATE()
      .then((response) => {
        if (response.error) {
          notification.error({
            message: t("error"),

            description: response.message.message,
          });

          return;
        }

        notification.success({
          message: t("success"),

          description: response.message,
        });

        setGetAgain((prev) => !prev);
      })

      .catch((error) => {
        notification.error({
          message: t("error"),

          description: error,
        });
      });
  };

  const handleMenuClick = (event, item) => {
    switch (event.key) {
      case "edit":
        setEditable(item);
        setIsOpenDrawer(true);
        break;

      case "delete":
        REQUESTS.EPG.DELETE({ epgId: item.id })
          .then((response) => {
            if (response.error) {
              notification.error({
                message: t("error"),

                description: response.message,
              });

              return;
            }

            notification.success({
              message: t("success"),

              description: response.message,
            });

            setGetAgain((prev) => !prev);
          })

          .catch((error) => {
            notification.error({
              message: t("error"),

              description: error,
            });
          });

        break;

      case "publish":
        REQUESTS.EPG.PUBLISH({ id: item.id })
          .then((response) => {
            if (response.error) {
              notification.error({
                message: t("error"),

                description: response.message,
              });

              return;
            }

            notification.success({
              message: t("success"),

              description: response.message,
            });

            setGetAgain((prev) => !prev);
          })

          .catch((error) => {
            notification.error({
              message: t("error"),

              description: error,
            });
          });

        break;

      case "unpublish":
        REQUESTS.EPG.UNPUBLISH({ id: item.id })
          .then((response) => {
            if (response.error) {
              notification.error({
                message: t("error"),

                description: response.message,
              });

              return;
            }

            notification.success({
              message: t("success"),

              description: response.message,
            });

            setGetAgain((prev) => !prev);
          })

          .catch((error) => {
            notification.error({
              message: t("error"),

              description: error,
            });
          });

        break;

      default:
        break;
    }
  };

  return (
    <PageComponent routes={[t("epg")]}>
      <EpgTable
        update={update}
        forceUpdate={forceUpdate}
        getAgain={getAgain}
        getDataSource={getDataSource}
        handleMenuClick={handleMenuClick}
        openDrawer={() => setIsOpenDrawer(true)}
      />

      <EpgDrawer
        open={isOpenDrawer}
        editable={editable}
        getData={() => setGetAgain((prev) => !prev)}
        onClose={() => {
          setIsOpenDrawer(false);
          setEditable(null);
        }}
      />
    </PageComponent>
  );
}
