import { useState } from "react";
import PageComponent from "../../../_components/page/PageComponent";
import REQUESTS from "../../../api/requests";
import MenuConfigTable from "./MenuConfigTable";
import MenuConfigDrawer from "./MenuConfigDrawer";
import { notification, Modal } from "antd";
import { useTranslation } from "react-i18next";

const MenuConfigPage = () => {
  const { t } = useTranslation();

  const [showDrawer, setShowDrawer] = useState(false);
  const [getAgain, setGetAgain] = useState(false);
  const [editable, setEditable] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleMenuData = (query, cb, error) => {
    try {
      REQUESTS.MENU_CONFIG.GET(JSON.stringify(query))
        .then((res) => {
          cb(res);
        })
        .catch((err) => {
          console.log(err);
          error(err);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const handleEnableState = (data) => {
    const body = {
      id: data.value,
      is_enabled: data.type,
    };

    REQUESTS.MENU_CONFIG.ENABLED(JSON.stringify(body))
      .then((res) => {
        if (!res.error) {
          setGetAgain((prev) => !prev);
          notification.success({
            message: t("updated_successfully"),
          });
        } else {
          notification.error({
            message: res?.message,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleSave = (data) => {
    setLoading(true);

    if (editable) {
      REQUESTS.MENU_CONFIG.UPDATE(data)
        .then((res) => {
          setLoading(false);

          if (!res.error) {
            setShowDrawer(false);
            setGetAgain((prev) => !prev);

            notification.success({
              message: t("updated_successfully"),
            });
          } else {
            notification.error({
              message: res?.message,
            });
          }
        })
        .catch((err) => {
          console.log(err);
          setLoading(false);
        });
    } else {
      REQUESTS.MENU_CONFIG.ADD(data)
        .then((res) => {
          setLoading(false);
          if (!res.error) {
            setShowDrawer(false);
            setGetAgain((prev) => !prev);
            notification.success({
              message: t("add_success"),
            });
          } else {
            notification.error({
              message: res?.message,
            });
          }
        })
        .catch((err) => {
          setLoading(false);

          console.log(err);
        });
    }
  };

  const handleDelete = (id) => {
    REQUESTS.MENU_CONFIG.REMOVE({ id })
      .then((res) => {
        if (!res.error) {
          setGetAgain((prev) => !prev);
          notification.success({
            message: t("delete_success"),
          });
        } else {
          notification.error({
            message: res?.message,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleChangePosition = (position, id) => {
    REQUESTS.MENU_CONFIG.UPDATE_POSITION({ position, id, force: false })
      .then((response) => {
        if (response.error) {
          Modal.confirm({
            title: t("change_position"),
            content: (
              <div>
                {/* <p>
                  In {position} position already exists <b>{response?.message?.name}</b>{" "}
                  live tv. We can switch their positions. Do you want to do it?
                </p> */}

                <p>
                  {t("change_position_message_with_name", {
                    position,
                    name: response?.message?.name,
                  })}
                </p>
              </div>
            ),
            okText: t("change"),
            onOk() {
              REQUESTS.MENU_CONFIG.UPDATE_POSITION({ position, id, force: true })
                .then(() => {
                  notification.success({
                    message: t("success"),
                    description: t("position_change_success"),
                  });
                  setGetAgain((prev) => !prev);
                })
                .catch((err) => {
                  notification.error({
                    message: t("error"),
                    description: err.message,
                  });
                });
            },
            onCancel() {
              setGetAgain((prev) => !prev);
            },
          });
        } else {
          setGetAgain((prev) => !prev);
          notification.success({
            message: response.message,
          });
        }
      })
      .catch((error) => {
        notification.error({
          message: t("error"),

          description: error,
        });
        setGetAgain((prev) => !prev);
      });
  };

  return (
    <PageComponent routes={[t("application"), t("menu_config")]}>
      <div>
        <MenuConfigTable
          getMenuData={handleMenuData}
          setShowDrawer={setShowDrawer}
          getAgain={getAgain}
          setEditable={setEditable}
          onEnable={handleEnableState}
          onChangePosition={handleChangePosition}
          onDelete={handleDelete}
        />
      </div>

      <MenuConfigDrawer
        open={showDrawer}
        close={() => {
          setShowDrawer(false);
          setEditable(null);
        }}
        onSave={handleSave}
        editable={editable}
        loading={loading}
      />
    </PageComponent>
  );
};

export default MenuConfigPage;
