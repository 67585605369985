import request from "../../../api/request";

import { appHost } from "../../../helpers/appHost";

const host = appHost;

const URL = {
  FEATURE: `${host}admin/feature`,
};

const webFeaturesApi = {
  GET: (query) => request("GET", URL.FEATURE, query),

  ADD: (query) => request("POST", URL.FEATURE, query),

  EDIT: (query) => request("PUT", URL.FEATURE, query),

  DELETE: (query) => request("DELETE", URL.FEATURE, query),
};

export default webFeaturesApi;
