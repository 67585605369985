import React from "react";
import imagePNG from "../img/image.png";
import defaultImage from "../img/photo.png";

export default function ImageChooser(props) {
  const { image, onChangeImage, isOpen } = props;

  const onChange = (event) => {
    const input = event.target;
    const reader = new FileReader();

    reader.onload = function () {
      const dataURL = reader.result;
      if (dataURL !== image) {
        onChangeImage(dataURL);
      } else {
        onChangeImage(null);
      }
    };

    if (input.files.length > 0) {
      reader.readAsDataURL(input.files[0]);
    }
  };

  return (
    <div style={{ textAlign: "start" }}>
      <img
        className="image-chooser"
        alt="icon"
        src={image ? image : imagePNG}
        onClick={() => document.getElementById("idOfImage").click()}
        onError={(e) => (e.target.src = defaultImage)}
      />

      <div className="input-box" style={{ marginTop: 20 }}>
        <input
          type="file"
          style={{ width: "100%", cursor: "pointer" }}
          id="idOfImage"
          aria-describedby="inputGroupFileAddon01"
          onChange={onChange}
          accept="image/*"
          disabled={!isOpen}
        />
      </div>
    </div>
  );
}
