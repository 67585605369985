import React, { useEffect, useState } from "react";
import { bindActionCreators } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import * as actions from "../../../redux-store/actions";

import Message from "./Message";

import { host } from "../../../server/url";

import requests from "../../../server/requests";

import style from "./_messages.module.scss";
import ICONS from "../../../helpers/icons";

import { Form, Input, notification, Popconfirm } from "antd";
import REQUESTS from "../../../api/requests";
import { parseDate } from "../../../helpers/formats";
import { socket } from "../../../App";
import SendFileModal from "./SendFileModal";

export default function Messages({
    activeConversation,
    setActiveConversation,
    getConversations,
    selectedQuickMessage,
    setSelectedQuickMessage,
}) {
    const dispatch = useDispatch();

    const { setIsChangedNotReadMessagesCount } = bindActionCreators(actions, dispatch);

    const [messages, setMessages] = useState([]);

    const [form] = Form.useForm();

    const [file, setFile] = useState(null);

    const { profile, new_message } = useSelector((state) => state.globalState);

    const getMessages = () => {
        REQUESTS.MESSAGES.CHAT.GET(activeConversation?.id).then((data) => {
            setMessages(data?.message?.rows);

            REQUESTS.MESSAGES.CHAT.READ({ conversation_id: activeConversation.id }).then((data) => {
                getConversations();
                setIsChangedNotReadMessagesCount(new Date());
            });

            setTimeout(() => {
                if (document.getElementById("chatId")) {
                    document.getElementById("chatId").scrollTo({
                        behavior: "smooth",
                        top: 2000000000000,
                    });
                }
            }, 100);
        });
    };

    const deleteChat = () => {
        if (activeConversation) {
            function callback() {
                getConversations();
                setActiveConversation(null);
                notification.success({
                    description: "This chat is removed",
                });
            }

            requests.chat.conversation.delete(activeConversation.id, callback);
        }
    };

    const sendMessage = (message) => {
        const body = {
            conversation_id: activeConversation?.id,
            content: message,
            type: "text",
        };

        REQUESTS.MESSAGES.CHAT.SEND.MESSAGE(body)
            .then((data) => {
                getMessages();
                setSelectedQuickMessage(null);
                form.resetFields();
            })
            .catch((err) => {});
    };

    const sendFile = () => {
        getMessages();
        setFile(null);
    };

    const onFinish = (values) => {
        sendMessage(values.message);
    };

    useEffect(() => {
        if (activeConversation) {
            getMessages();
        }
    }, [activeConversation]);

    useEffect(() => {
        if (selectedQuickMessage) {
            sendMessage(selectedQuickMessage.message);
        }
    }, [selectedQuickMessage]);

    useEffect(() => {
        if (socket) {
            socket.on("user_read_chat_message", (data) => {
                getMessages();
            });
        }

        return () => {
            if (socket) {
                socket.off("user_read_chat_message");
            }
        };
    }, [activeConversation]);

    useEffect(() => {
        const keyDownHandler = (event) => {
            if (event.key === "Enter" && event.key !== "Shift") {
                event.preventDefault();
                form.submit();
            }
        };

        document.addEventListener("keydown", keyDownHandler);

        return () => {
            document.removeEventListener("keydown", keyDownHandler);
        };
    }, []);

    useEffect(() => {
        if (new_message?.conversation_id === activeConversation.id) {
            getMessages();
        }
    }, [new_message]);

    return (
        <>
            <div className={style["messages"]}>
                <header>
                    <NavLink to={`/dashboard/user/${activeConversation?.user_id}`} target="_blank">
                        <div className={style["profile-info"]}>
                            {activeConversation?.sub_user?.name}{" "}
                            <span style={{ fontSize: 13 }}>
                                ( {activeConversation?.user?.name}{" "}
                                {activeConversation?.user?.surname} )
                            </span>
                        </div>

                        <div>
                            {activeConversation?.sub_online ? (
                                <div className={style["online"]}>Online</div>
                            ) : (
                                <div className={style["last-seen"]}>
                                    last seen at: {parseDate(activeConversation?.sub_last_online)}
                                </div>
                            )}
                        </div>
                    </NavLink>

                    <Popconfirm
                        title="Delete the chat"
                        description="Are you sure to delete this chat?"
                        onConfirm={deleteChat}
                        okText="Delete"
                        cancelText="Cancel"
                        okType="danger"
                    >
                        <div className={style["delete"]}>
                            <i className="fas fa-trash-alt" />
                        </div>
                    </Popconfirm>
                </header>

                <div id="chatId" className={style["list"]}>
                    {messages?.map((item) => {
                        return (
                            <>
                                {item.sender === "admin" ? (
                                    <Message
                                        image={profile?.image}
                                        name={profile?.name}
                                        surname={profile?.surname}
                                        message={item}
                                        admin={true}
                                    />
                                ) : (
                                    <Message
                                        image={
                                            activeConversation?.sub_user?.image &&
                                            `${host}${activeConversation.sub_user.image}`
                                        }
                                        name={activeConversation?.sub_user?.name}
                                        message={item}
                                        admin={false}
                                    />
                                )}
                            </>
                        );
                    })}
                </div>

                <Form
                    form={form}
                    name="message"
                    layout="horizontal"
                    onFinish={onFinish}
                    className={style["send-message-block"]}
                >
                    <div className={style["upload"]}>
                        {ICONS.FILE}

                        <input
                            type="file"
                            onChange={(e) => setFile(e.target.files[0])}
                            multiple={false}
                            accept="image/*, video/*, application/*"
                        />
                    </div>

                    <Form.Item
                        name="message"
                        noStyle
                        rules={[
                            {
                                required: true,
                                message: "Please input message",
                            },
                        ]}
                    >
                        <Input.TextArea rows={1} />
                    </Form.Item>

                    <Form.Item noStyle>
                        <div className={style["send"]} onClick={() => form.submit()}>
                            <i className="fa fa-paper-plane" />
                        </div>
                    </Form.Item>
                </Form>
            </div>

            <SendFileModal
                file={file}
                send={sendFile}
                isOpen={file ? true : false}
                onClose={(e) => setFile(null)}
                activeConversationId={activeConversation.id}
            />
        </>
    );
}
