import React from "react";

export default class MyPopup extends React.Component {
  constructor(props) {
    super(props);
  }

  escFunction = (event) => {
    if (event.keyCode === 27) {
      this.props.onClose();
    }
  };

  componentDidMount() {
    document.addEventListener("keydown", this.escFunction, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.escFunction, false);
  }

  render() {
    const { size, onClose, outlineComponent, noPadding, title } = this.props;

    return (
      <div className="popup-background">
        {outlineComponent}
        <div className="popup-empty-section" onClick={onClose} />

        <div
          className={`popup-style ${!this.props.dontSlide && "slide-left"} ${
            this.props.class && this.props.class
          }`}
          style={{
            width: size || 700,
            overflow: "auto",
            ...this.props.style,
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <button
              style={{ position: noPadding && "absolute", zIndex: noPadding && 1000 }}
              className="close-button-style"
              onClick={onClose}
            >
              <i className="fa fa-times" />
            </button>

            {title && (
              <div style={{ marginLeft: 25, marginTop: 12, fontSize: 20 }}>{title}</div>
            )}
          </div>

          <div style={{ padding: !noPadding && "50px" }}>{this.props.children}</div>
        </div>
      </div>
    );
  }
}
