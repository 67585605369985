import { Popconfirm, Space, notification } from "antd";

import REQUESTS from "../../../../api/requests";

import ButtonComponent from "../../../../_components/button/ButtonComponent";
import icons from "../../../../config/icons";
import { useTranslation } from "react-i18next";

export default function ActionsForBulk({ selectedRowKeys, setGetAgain, setSelected }) {
  const { t } = useTranslation();
  const bulkActions = (type, ids) => {
    switch (type) {
      case "enable":
      case "disable":
        REQUESTS.VOD.MEDIAS.ENABLE_OR_DISABLE(type, {
          id: ids.join(","),
        })
          .then((response) => {
            if (!response.error) {
              notification.success({
                message: t("success"),
                description: response.message,
              });
              setGetAgain((prev) => !prev);
              setSelected([]);
            }
          })
          .catch((error) => {
            notification.error({
              message: t("error"),
              description: error,
            });
          });
        break;

      case "protect":
      case "unprotect":
        REQUESTS.VOD.MEDIAS.BULK_PROTECT({
          id: ids.join(","),
          is_protected: type === "protect" ? true : false,
        })

          .then((response) => {
            if (response.error) {
              notification.error({
                message: t("error"),

                description: response.message,
              });
              setSelected([]);
              return;
            }

            notification.success({
              message: t("success"),

              description: response.message,
            });

            setGetAgain((prev) => !prev);
          })
          .catch((error) => {
            notification.error({
              message: t("error"),

              description: error,
            });
          });

        break;

      case "delete":
        REQUESTS.VOD.MEDIAS.DELETE({ id: ids })
          .then((response) => {
            if (!response.error) {
              notification.success({
                message: t("success"),

                description: response.message,
              });
              setGetAgain((prev) => !prev);
              setSelected([]);
            }
          })
          .catch((error) => {
            notification.error({
              message: t("error"),

              description: error,
            });
          });
        break;

      default:
        break;
    }
  };

  return (
    <Space>
      <Popconfirm
        disabled={selectedRowKeys.length === 0}
        placement="topRight"
        title={t("protect_selected_content_message")}
        onConfirm={(e) => bulkActions("protect", selectedRowKeys)}
        okText={t("protect")}
        cancelText={t("cancel")}
      >
        <ButtonComponent
          title={t("protect")}
          type="default"
          icon={icons.PROTECT}
          disabled={selectedRowKeys.length === 0}
          //   className={"protect-button"}
        />
      </Popconfirm>

      <Popconfirm
        placement="topRight"
        title={t("unprotect_selected_content_message")}
        onConfirm={(e) => bulkActions("unprotect", selectedRowKeys)}
        okText={t("unprotecd")}
        cancelText="Cancel"
        disabled={selectedRowKeys.length === 0}
      >
        <ButtonComponent
          title={t("unprotecd")}
          type="default"
          icon={icons.UNPROTECT}
          disabled={selectedRowKeys.length === 0}
          //   className={"unprotect-button"}
        />
      </Popconfirm>

      <Popconfirm
        placement="topRight"
        title={t("enable_selected_message")}
        onConfirm={(e) => bulkActions("enable", selectedRowKeys)}
        okText="Enable"
        cancelText="Cancel"
        disabled={selectedRowKeys.length === 0}
      >
        <ButtonComponent
          title={t("enable")}
          type="default"
          icon={icons.ENABLE}
          //   className={"enable-button"}
          disabled={selectedRowKeys.length === 0}
        />
      </Popconfirm>

      <Popconfirm
        placement="topRight"
        title={t("disable_selected_message")}
        onConfirm={(e) => bulkActions("disable", selectedRowKeys)}
        okText={t("disable")}
        cancelText={t("cancel")}
        disabled={selectedRowKeys.length === 0}
      >
        <ButtonComponent
          title="Disable"
          type="default"
          icon={icons.DISABLE}
          disabled={selectedRowKeys.length === 0}
          //   className={"disable-button"}
        />
      </Popconfirm>

      <Popconfirm
        placement="topRight"
        title={t("delete_selected_message")}
        onConfirm={(e) => bulkActions("delete", selectedRowKeys)}
        okText={t("delete")}
        cancelText={t("cancel")}
        disabled={selectedRowKeys.length === 0}
      >
        <ButtonComponent
          title={t("delete")}
          type="default"
          disabled={selectedRowKeys.length === 0}
          icon={icons.DELETE}
          //   className={"delete-button"}
        />
      </Popconfirm>
    </Space>
  );
}
