import { useEffect, useState } from "react";

import { Drawer, Form, Input, notification } from "antd";

import REQUESTS from "../../../../../api/requests";

import ButtonComponent from "../../../../../_components/button/ButtonComponent";
import { useTranslation } from "react-i18next";

export default function BalanceDrawer({ onClose, open, type, userId, getData }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const [form] = Form.useForm();

  const onFinish = (values) => {
    setIsLoading(true);
    const paymentType = type === "Add" ? "add" : "out";

    const body = {
      userId: userId,
      price: values.price,
      description: values.description,
    };

    REQUESTS.USERS.USER.BALANCE(paymentType, body)
      .then((response) => {
        setIsLoading(false);

        if (response.error) {
          notification.error({
            message: t("error"),
            description: response.message,
          });

          return;
        }

        getData();
        onClose();
      })
      .catch((err) => {
        notification.error({
          message: t("error"),

          description: t("something_wrong"),
        });
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!open) {
      form.resetFields();
    }
  }, [open]);

  return (
    <Drawer title={`${type} blance`} placement="right" onClose={onClose} open={open}>
      <Form
        form={form}
        name="user-balance"
        layout="vertical"
        onFinish={onFinish}
        // onKeyPress={(e) => {
        //   if (e.key === "Enter") {
        //     form.submit();
        //   }
        // }}
      >
        <Form.Item
          label={t("price")}
          name="price"
          rules={[
            {
              required: true,
              message: t("price_message"),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t("description")}
          name="description"
          rules={[
            {
              required: true,
              message: t("description_message"),
            },
          ]}
        >
          <Input.TextArea rows={3} />
        </Form.Item>

        <Form.Item style={{ textAlign: "center" }}>
          <ButtonComponent
            title={t("save")}
            actionType="save"
            isLoading={isLoading}
            onClick={() => form.submit()}
          />
        </Form.Item>
      </Form>
    </Drawer>
  );
}
