import { useEffect, useState } from "react";
import { Radio, Form, Input, notification, Select, Switch } from "antd";

import REQUESTS from "../../api/requests";

import Upploader from "../../_components/uploader/Uploader";

import ButtonComponent from "../../_components/ButtonComponent";
import { useLanguagesOptions, useCountriesOptions } from "../../hooks/selectOptions";

import styles from "./_import.module.scss";
import { useTranslation } from "react-i18next";

export default function PlaylistForm({ status }) {
  const { t } = useTranslation();
  const languageOptions = useLanguagesOptions();
  const countryOptions = useCountriesOptions();

  const [type, setType] = useState("url");

  const [isLoading, setIsLoading] = useState(false);

  const [form] = Form.useForm();

  const onFinish = (values) => {
    setIsLoading(true);

    const formData = new FormData();

    if (type === "url") {
      formData.append("url", values.url);
    } else {
      formData.append("file", values.file);
    }

    if (values.country?.id) {
      formData.append("country", values.country?.id);
    }
    if (values.language) {
      formData.append("language", values.language);
    }

    formData.append("skip_episodes", values.skip_episodes ? values.skip_episodes : false);
    formData.append("skip_movies", values.skip_movies ? values.skip_movies : false);

    REQUESTS.IMPORT.ADD(formData)
      .then((response) => {
        setIsLoading(false);

        if (response.error) {
          notification.error({
            message: t("error"),
            description: response.message,
          });

          return;
        }
      })
      .catch((err) => {
        notification.error({
          message: t("error"),
          description: t("something_wrong"),
        });
        setIsLoading(false);
      });
  };

  const onStop = () => {
    REQUESTS.IMPORT.STOP().then((response) => {
      if (response.error) {
        notification.error({
          message: t("error"),
          description: response.message,
        });

        return;
      }
    });
  };

  const choosePlaylist = (info) => {
    form.setFields([{ name: "file", value: info?.file?.originFileObj }]);
  };

  useEffect(() => {
    form.resetFields();
  }, [type]);

  return (
    <div className={styles["playlist-form"]}>
      <Radio.Group
        name="radiogroup"
        defaultValue={"url"}
        value={type}
        onChange={(e) => {
          setType(e.target.value);
        }}
      >
        <Radio value="url">{t("playlist_url")}</Radio>
        <Radio value="file"> {t("playlist_file")} </Radio>
      </Radio.Group>

      <Form
        form={form}
        name="import"
        layout="vertical"
        onFinish={onFinish}
        // onKeyPress={(e) => {
        //   if (e.key === "Enter") {
        //     form.submit();
        //   }
        // }}
        style={{ width: "100%", marginTop: 20 }}
      >
        {type === "url" ? (
          <Form.Item
            name="url"
            disabled={status === "RUNNING" ? true : false}
            rules={[
              {
                required: true,
                message: t("url_message"),
              },
            ]}
          >
            <Input
              placeholder={t("url_placeholder")}
              disabled={status === "RUNNING" ? true : false}
            />
          </Form.Item>
        ) : (
          <Form.Item
            name="file"
            rules={[
              {
                required: true,
                message: t("choose_file_message"),
              },
            ]}
          >
            <Upploader
              style={{ width: 350 }}
              onChange={choosePlaylist}
              disabled={status === "RUNNING" ? true : false}
            />
          </Form.Item>
        )}
        <Form.Item name="country" label={t("select_country")}>
          <Select
            disabled={status === "RUNNING" ? true : false}
            options={countryOptions}
            onChange={(e) => {
              const countryId = countryOptions.find((item) => item.value === e)?.id;
              if (countryId) {
                form.setFields([
                  {
                    name: "country",
                    value: {
                      id: countryId,
                      label: countryOptions.find((item) => item.value === e)?.label,
                    },
                  },
                ]);
              }
            }}
            showSearch
            filterOption={(input, option) =>
              (option?.label.toLowerCase() ?? "").includes(input.toLocaleLowerCase())
            }
            filterSort={(optionA, optionB) => {
              return (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase());
            }}
            placeholder={t("select_country_message")}
          />
        </Form.Item>
        <Form.Item name="language" label={t("select_language")}>
          <Select
            options={languageOptions}
            placeholder="Please select a language"
            disabled={status === "RUNNING" ? true : false}
            showSearch
            filterOption={(input, option) =>
              (option?.label.toLowerCase() ?? "").includes(input.toLocaleLowerCase())
            }
            filterSort={(optionA, optionB) => {
              return (optionA?.label ?? "")
                .toLowerCase()
                .localeCompare((optionB?.label ?? "").toLowerCase());
            }}
          />
        </Form.Item>

        <Form.Item name="skip_movies" label={t("skip_movie")}>
          <Switch disabled={status === "RUNNING" ? true : false} />
        </Form.Item>
        <Form.Item name="skip_episodes" label={t("skip_episodes")}>
          <Switch disabled={status === "RUNNING" ? true : false} />
        </Form.Item>

        <Form.Item style={{ textAlign: "center" }}>
          {status === "RUNNING" ? (
            <ButtonComponent
              title={t("stop")}
              danger={true}
              style={{ width: "100%" }}
              onClick={onStop}
            />
          ) : (
            <ButtonComponent
              title={t("start")}
              actionType="save"
              isLoading={isLoading}
              style={{ width: "100%" }}
              onClick={() => form.submit()}
              disabled={status && status === "RUNNING" ? true : false}
            />
          )}
        </Form.Item>
      </Form>
    </div>
  );
}
