import { useEffect, useState } from "react";
import { Tooltip } from "antd";
import {
  getAtSession,
  getAtLocal,
  setInLocal,
  setInSession,
} from "../../../helpers/storages";
import TableComponent from "../../../_components/table/TableComponent";
import Check from "../../../_components/table/Check";
import TableButtons from "../../../_components/table/TableButtons";
import { useTranslation } from "react-i18next";
import icons from "../../../config/icons";
import ButtonComponent from "../../../_components/ButtonComponent";
import { getColumnSearchProps } from "../../../config/config";
import { asc_desc } from "../../../helpers/ascDesc";

const FilterPageTable = ({
  getDataSource,
  handleMenuClick,
  vodList,
  liveTvList,
  showDrawer,
  countriesOptions,
  vodGenresOptions,
  liveTvCategoriesOptions,
  bulkAction,
  getAgain,
  loading,
}) => {
  const { t } = useTranslation();

  const archiveFilterOPtions = [
    { text: t("archived"), value: true },
    { text: t("not_archived"), value: false },
  ];

  const [dataSource, setDataSource] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [limit, setLimit] = useState(10);

  const [sort, setSort] = useState(
    getAtSession("custom_ads_client_filter")?.sort || ["id", "DESC"]
  );
  const [search, setSearch] = useState(
    {
      name: "",
      allowed_countries: "",
      allowed_os: "",
      allowed_vod_genres: "",
      allowed_livetv_genres: "",
      custom_allowed_vods: "",
      custom_allowed_live_tv_channels: "",
      archived: false,
    } || getAtSession("custom_ads_client_filter")?.search
  );

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      key: "id",
      align: "center",
      ellipsis: true,
      fixed: "left",
      width: 60,
    },
    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
      align: "center",
      width: 100,
      ellipsis: true,
      defaultFilteredValue: getAtSession("custom_ads_client_filter")?.search?.name,
      filteredValue: getAtSession("custom_ads_client_filter")?.search?.name,

      ...getColumnSearchProps(),
    },
    {
      title: t("allowed_countries"),
      dataIndex: "allowed_countries",
      key: "allowed_countries",
      align: "center",
      ellipsis: true,
      defaultFilteredValue: getAtSession("custom_ads_client_filter")?.search
        .allowed_countries?.allowed_countries,
      filteredValue: getAtSession("custom_ads_client_filter")?.search?.allowed_countries,

      ...getColumnSearchProps(),
      render: (text, record) => {
        const allowedCountries = countriesOptions.filter((item) =>
          text.split(",").includes(item.code)
        );
        const countryCodes = allowedCountries.map((item) => item.label);

        return countryCodes.join(", ");
      },
    },
    {
      title: t("allowed_os"),
      dataIndex: "allowed_os",
      key: "allowed_os",
      align: "center",
      ellipsis: true,
      defaultFilteredValue: getAtSession("custom_ads_client_filter")?.search.allowed_os
        ?.allowed_countries,
      filteredValue: getAtSession("custom_ads_client_filter")?.search?.allowed_os,

      ...getColumnSearchProps(),

      render: (text) => text.split(",").join(", "),
    },
    {
      title: t("allowed_vod_genres"),
      dataIndex: "allowed_vod_genres",
      key: "allowed_vod_genres",
      align: "center",
      ellipsis: true,
      defaultFilteredValue: getAtSession("custom_ads_client_filter")?.search
        .allowed_vod_genres?.allowed_countries,
      filteredValue: getAtSession("custom_ads_client_filter")?.search?.allowed_vod_genres,

      ...getColumnSearchProps(),
      render: (text, record) => {
        const allowedGenres = vodGenresOptions.filter((item) => {
          return text.split(",").includes(item.id.toString());
        });

        return allowedGenres.map((item) => item.name).join(", ");
      },
    },
    {
      title: t("allowed_livetv_genres"),
      dataIndex: "allowed_livetv_genres",
      key: "allowed_livetv_genres",
      align: "center",
      ellipsis: true,
      defaultFilteredValue: getAtSession("custom_ads_client_filter")?.search
        .allowed_livetv_genres?.allowed_countries,
      filteredValue: getAtSession("custom_ads_client_filter")?.search
        ?.allowed_livetv_genres,

      ...getColumnSearchProps(),
      render: (text, record) => {
        const allowedGenres = liveTvCategoriesOptions.filter((item) => {
          return text.split(",").includes(item.id.toString());
        });

        return allowedGenres.map((item) => item.name).join(", ");
      },
    },
    {
      title: t("allowed_for_kids"),
      dataIndex: "allowed_for_kids",
      key: "allowed_for_kids",
      align: "center",
      ellipsis: true,
      sort: true,
      defaultSortOrder:
        getAtSession("custom_ads_client_filter")?.sort[1] == "allowed_for_kids" &&
        asc_desc(getAtSession("custom_ads_client_filter")?.sort[1]),

      render: (text) => (text ? "Yes" : "No"),
    },
    {
      title: t("custom_allowed_vods"),
      dataIndex: "custom_allowed_vods",
      key: "custom_allowed_vods",
      align: "center",
      ellipsis: true,

      render: (text) => {
        const allowedId = text.split(",").map((id) => parseInt(id));

        const allowedVods = vodList.filter((item) => allowedId.includes(item.id));

        return (
          <Tooltip title={allowedVods.map((item) => item.name)}>
            {allowedVods.map((item) => item.name).length || 0}
          </Tooltip>
        );
      },
    },
    {
      title: t("custom_allowed_live_tv_channels"),
      dataIndex: "custom_allowed_live_tv_channels",
      key: "custom_allowed_live_tv_channels",
      align: "center",
      ellipsis: true,

      render: (text) => {
        const allowedId = text.split(",").map((id) => parseInt(id));

        const allowedLiveTv = liveTvList.filter((item) => allowedId.includes(item.id));

        return (
          <Tooltip title={allowedLiveTv.map((item) => item.name)}>
            {allowedLiveTv.map((item) => item.name).length || 0}
          </Tooltip>
        );
      },
    },
    {
      title: t("archived"),
      dataIndex: "archived",
      key: "archived",
      align: "center",
      width: 100,
      ellipsis: true,
      sorter: true,
      defaultSortOrder:
        getAtSession("custom_ads_client_filter")?.sort[1] == "archived" &&
        asc_desc(getAtSession("custom_ads_client_filter")?.sort[1]),
      filters: archiveFilterOPtions,

      onFilter: (value, record) => true,
      render: (record, text) => <Check value={record} />,
    },
    {
      title: "",
      dataIndex: "actions",
      key: "actions",
      align: "center",
      fixed: "right",
      width: 70,
      ellipsis: true,

      render: (text, record) => (
        <TableButtons
          handleMenuClick={(key) => handleMenuClick(key, record)}
          items={[
            {
              key: "edit",
              name: t("edit"),
              icon: icons.EDIT,
              onConfirm: (e) => {
                handleMenuClick(e, record);
              },
            },
            {
              key: "delete",
              name: t("delete"),
              icon: icons.DELETE,
              question: t("delete_message"),
              onConfirm: (e) => {
                handleMenuClick(e, record);
              },
            },
          ]}
        />
      ),
    },
  ];

  const handleTableChange = (data) => {
    const filter = {
      page: data.page || 1,
      search: data.search || {},
      sort: data.sort && data.sort[1] ? data.sort : ["id", "DESC"],
    };

    setCurrentPage(filter.page);
    setLimit(data.limit);
    setSort(filter.sort);

    setInLocal("pageLimit", filter);

    setInSession("custom_ads_client_search", {
      ...getAtSession("custom_ads_client_search"),
      page: filter.page,
      limit: data.limit,
      sort: filter.sort,
      search: filter.search,
    });

    const searchData = {
      ...filter.search,
      archived: data?.search?.archived != null ? data.search.archived : false,
    };

    setSearch(searchData);
  };

  useEffect(() => {
    const success = (res) => {
      setDataSource(res.rows);
      setPageSize(res.count);
    };

    const error = (err) => {
      console.log("Error: ", err);
    };

    const query = {
      page: currentPage,
      limit: limit,
      filter: {
        archived: search.archived,
      },
    };

    if (sort[1]) {
      query.sort = sort;
    }

    if (search.name) {
      query.search = {
        name: search.name,
      };
    }

    if (search.allowed_countries) {
      query.search.allowed_countries = search.allowed_countries;
    }

    if (search.allowed_os) {
      query.search.allowed_os = search.allowed_os;
    }

    if (search.allowed_vod_genres) {
      query.search.allowed_vod_genres = search.allowed_vod_genres;
    }

    if (search.allowed_livetv_genres) {
      query.search.allowed_livetv_genres = search.allowed_livetv_genres;
    }

    if (search.custom_allowed_vods) {
      query.search.custom_allowed_vods = search.custom_allowed_vods;
    }

    getDataSource(query, success, error);
  }, [currentPage, sort, search, limit, getAgain]);

  return (
    <div>
      <TableComponent
        header={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
            }}
          >
            {selectedRowKeys.length > 0 && (
              <ButtonComponent
                actionType="delete"
                title={t("delete")}
                type="default"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={() => {
                  bulkAction("delete", selectedRowKeys, (response) => {
                    if (response == getAgain) {
                      setSelectedRowKeys([]);
                    }
                  });
                }}
              />
            )}
            <ButtonComponent
              title={t("add_filter")}
              actionType="add"
              onClick={() => showDrawer(true)}
              style={{
                display: "flex",
                alignItems: "center",
              }}
            />
          </div>
        }
        dataSource={dataSource}
        columns={columns}
        isLoading={loading}
        onChange={handleTableChange}
        rowSelection={{
          selectedRowKeys,
          onChange: (selectedRowKeys) => setSelectedRowKeys(selectedRowKeys),
        }}
        pagination={{
          current: currentPage,
          pageSize: pageSize,
          total: dataSource?.length,
        }}
        scroll={{ x: 1600 }}
      />
    </div>
  );
};

export default FilterPageTable;
