import { useEffect, useState } from "react";
import styles from "./styles.module.scss";
import { Image } from "antd";
import defaultImage from "../../../../../../img/photo.png";

const CollectionPreview = ({ collection }) => {
  const imageError = (e) => (e.target.src = defaultImage);

  return (
    <div
      className={styles["ads-card"]}
      style={{
        backgroundImage: `url(${collection.previewBg})`,
        backgroundSize: "contain",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
      }}
    >
      <div className={styles["ads-card-logo"]}>
        <Image src={collection.logo} alt="logo" preview={false} onError={imageError} />
      </div>
      <h2>{collection.title}</h2>
      <p>{collection.description}</p>
    </div>
  );
};

export default CollectionPreview;
