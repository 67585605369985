import { useEffect, useState } from "react";
import { Tabs } from "antd";

import PageComponent from "../../../../_components/page/PageComponent";
import DocumentEditor from "./DocumentEditor";
import Loading from "../../../../_components/loadings/Loading";

export default function DocumentsTab({ data, getData }) {
  const [isLoading, setIsLoading] = useState(true);

  const [tabs, setTabs] = useState([]);

  const [activeTab, setActiveTab] = useState("1");

  useEffect(() => {
    const list = data?.documents?.map((item, index) => {
      setIsLoading(false);
      return {
        key: JSON.stringify(index + 1),
        label: item.name,
        children: <DocumentEditor item={item} key={item.id} />,
      };
    });
    setTabs(list);
  }, [data]);

  return (
    <div>
      {isLoading ? (
        <div
          style={{
            width: "100%",
            height: "50rem",
          }}
        >
          <Loading />
        </div>
      ) : (
        <Tabs
          defaultActiveKey="1"
          tabPosition="left"
          items={tabs}
          activeKey={activeTab}
          onChange={setActiveTab}
        />
      )}
    </div>
  );
}
