import React, { useEffect, useState } from "react";
import requests from "../../../../server/requests";
import SelectComponent from "../../../../components/SelectComponent";
import InputComponent from "../../../../components/InputComponent";
import TextareaComponent from "../../../../components/TextareaComponent";
import ButtonComponent from "../../../../components/ButtonComponent";
import { inoToast } from "../../../../components/toast/Toast";

export default function Translations({ id, model }) {
  const [selectedLanguage, setSelectedLanguage] = useState("");

  const [languagesOptions, setLanguagesOption] = useState([]);

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  let saveTimeOut;

  const onClickSave = () => {
    clearTimeout(saveTimeOut);
    saveTimeOut = setTimeout(() => {
      save();
    }, 1000);
  };

  const save = () => {
    if (selectedLanguage.movie_translation) {
      let body = {
        model,
        name,
        description,
        id: selectedLanguage.movie_translation.id,
      };

      requests.vod.media.translation.edit(body, () => {
        inoToast.success("Success");
        getTranslations();
      });
    } else {
      let body = {
        id,
        model,
        name,
        description,
        languageId: selectedLanguage.id,
      };

      requests.vod.media.translation.add(body, () => {
        inoToast.success("Success");
        getTranslations();
      });
    }
  };

  const getTranslations = () => {
    requests.vod.media.translations(id, model, (data) => {
      const languages = data.map((item) => {
        return {
          label: item.name,
          value: item.id,
          ...item,
        };
      });

      setLanguagesOption(languages);

      if (languages.length) {
        if (!selectedLanguage.id) {
          const eng = languages.find((item) => item.iso_code === "en");
          setSelectedLanguage(eng);
        }
      }
    });
  };

  useEffect(() => {
    if (selectedLanguage) {
      switch (model) {
        case "movie":
          if (selectedLanguage.movie_translation) {
            setName(selectedLanguage.movie_translation.name);
            setDescription(selectedLanguage.movie_translation.description);
          } else {
            setName("");
            setDescription("");
          }

          break;

        case "season":
          if (selectedLanguage.season_translation) {
            setName(selectedLanguage.season_translation.name);
            setDescription(selectedLanguage.season_translation.description);
          } else {
            setName("");
            setDescription("");
          }
          break;

        case "episode":
          if (selectedLanguage.episode_translation) {
            setName(selectedLanguage.episode_translation.name);
            setDescription(selectedLanguage.episode_translation.description);
          } else {
            setName("");
            setDescription("");
          }
          break;

        default:
          break;
      }
    }
  }, [selectedLanguage]);

  useEffect(() => {
    if (id && model) {
      getTranslations();
    }
    return () => {
      clearTimeout(saveTimeOut);
    };
  }, [id, model]);

  return (
    <div style={{ borderTop: "1px solid #dee2e6", paddingTop: 10 }}>
      <label className="f-w-500 m-b-5">Translations:</label>
      <div>
        <SelectComponent
          label="Language"
          value={selectedLanguage}
          options={languagesOptions}
          change={(e) => setSelectedLanguage(e)}
        />
      </div>

      <div>
        <InputComponent
          label="Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          style={{ marginBottom: 15 }}
        />

        <TextareaComponent
          label="Description"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </div>

      <ButtonComponent type="save" title="Save" action={onClickSave} />
    </div>
  );
}
