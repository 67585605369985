export const filterOPtions = {
  adsTypeFilterOptions: (t) => [
    { text: t("splash_screen"), value: "splash_screen" },
    { text: t("collection"), value: "collection" },
    { text: t("volume_ads"), value: "volume_ads" },
    { text: t("banner_ads"), value: "banner_ads" },
  ],

  roleOPtions: (t) => [
    { text: t("enabled"), value: "enabled" },
    { text: t("disabled"), value: "disabled" },
  ],
  archiveFilterOPtions: (t) => [
    { text: t("archived"), value: false },
    { text: t("not_archived"), value: true },
  ],
};
