import { useEffect, useState } from "react";
import { Divider, Modal, notification } from "antd";

import REQUESTS from "../../../../../api/requests";

import Playing from "./Playing";
import EpgSection from "./EpgSection";

import Player from "../../../../../_components/Player";

import styles from "./_live-tv-video.module.scss";
import {
  flussonicCatchUpUrl,
  flussonicTimeshiftUrl,
  inorainCatchUpUrl,
  inorainTimeshiftUrl,
} from "./_generateUrls";
import { useTranslation } from "react-i18next";

export default function LiveTvVideoModal({ open, onClose, channel }) {
  const { t } = useTranslation();

  const [server, setServer] = useState(null);

  const [playing, setPlaying] = useState({
    type: "live",
    epg: null,
    url: "",
  });

  const getServer = () => {
    let id = channel?.archived_channel?.archiverId;
    try{

    if (id) {
      let query = {
        id: id,
      };
      REQUESTS.CATCH_UP.SERVERS.GET({ query: JSON.stringify(query) })
        .then((response) => {
          if (response.error) {
            return;
          }

          if (response.message.rows) {
            setServer(response.message.rows[0]);
          }
        })
        .catch(() => {});
    }
    }catch(e){
      console.log(e)
    }
    
  };

  const playTimeshift = (item) => {
    const archivedChannelType = channel?.archived_channel?.type;

    let url = null;

    switch (archivedChannelType) {
      case "inorain":
        if (server) {
          url = inorainTimeshiftUrl(server, channel);
        } else {
          notification.error({
            message: t("error"),
            description: t("have_channel_server"),
          });
        }
        break;

      case "flussonic":
        url = flussonicTimeshiftUrl(channel, item);
        break;

      default:
        break;
    }

    setPlaying({
      type: "timeshift",
      url: url,
      epg: null,
    });
  };

  const playLive = () => {
    setPlaying({
      type: "live",
      url: channel?.url,
      epg: null,
    });
  };

  const playCatchUp = (item) => {
    const archivedChannelType = channel?.archived_channel?.type;

    let url = null;

    switch (archivedChannelType) {
      case "inorain":
        if (server) {
          url = inorainCatchUpUrl(server, channel, item);
        } else {
          notification.error({
            message: t("error"),
            description: t("have_channel_server"),
          });
        }
        break;

      case "flussonic":
        url = flussonicCatchUpUrl(channel, item);
        break;

      default:
        break;
    }

    setPlaying({
      type: "catchup",
      url: url,
      epg: item,
    });
  };

  const onChangePlaying = (type, item) => {
    switch (type) {
      case "live":
        playLive();
        break;

      case "timeshift":
        playTimeshift(item);
        break;

      case "catchup":
        playCatchUp(item);
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    if (open) {
      getServer();
      onChangePlaying("live");
    } else {
      setPlaying({
        type: "live",
        epg: null,
        url: "",
      });
    }
  }, [open]);

  return (
    <Modal
      width={1100}
      title={channel?.name}
      open={open}
      onCancel={onClose}
      footer={false}
      forceRender={true}
    >
      <div className={styles["container"]}>
        <div className={styles["video"]}>
          {open && (
            <Player
              url={playing.url}
              // stream_type={channel?.stream_type}
              useToken={channel?.stream_type === "internal" ? true : false}
            />
          )}

          <Divider />

          <Playing playing={playing} />
        </div>

        <EpgSection
          open={open}
          channel={channel}
          playing={playing}
          onChangePlaying={onChangePlaying}
        />
      </div>
    </Modal>
  );
}
