import styles from "./styles.module.scss";
import { useTranslation } from "react-i18next";
import PageComponent from "../../../_components/page/PageComponent";
import { Image, Typography } from "antd";

const FeedPage = () => {
  const { t } = useTranslation();

  const feedData = [
    {
      id: 1,
      title: "Samsung",
      url: "/dashboard/feed-page/samsung",
      icon: "https://download.tizen.org/misc/Tizen-Brand/01-Primary-Assets/Pinwheel/On-Light/01-RGB/Tizen-Pinwheel-On-Light-RGB.png",
    },
    {
      id: 2,
      type: "lg",
      title: "LG",
      url: "/dashboard/feed-page/lg",
      icon: "https://cdn4.iconfinder.com/data/icons/flat-brand-logo-2/512/lg-512.png",
    },
    {
      id: 3,
      title: "Android",
      url: "/dashboard/feed-page/android",
      icon: "https://download.logo.wine/logo/Android_TV/Android_TV-Logo.wine.png",
    },
    {
      id: 4,
      title: "Apple",
      url: "/dashboard/feed-page/apple",
      icon: "https://cdn-icons-png.flaticon.com/512/831/831330.png",
    },
    {
      id: 5,
      title: "Roku",
      url: "/dashboard/feed-page/roku",
      icon: "https://m.media-amazon.com/images/I/41UpCIriqhL.png",
    },
    {
      id: 6,
      title: "Amazon",
      url: "/dashboard/feed-page/amazon",
      icon: "https://m.media-amazon.com/images/I/71HqVEIDBNL._h1_.png",
    },
  ];

  return (
    <PageComponent routes={[t("settings"), t("feed")]}>
      <div className={styles["feed-page"]}>
        {feedData.map((feed) => (
          <div key={feed.id} className={styles["fid-item"]}>
            <div className={styles["feed-icon"]}>
              <Image src={feed.icon} alt={feed.title} />
            </div>

            <Typography.Title level={5} className={styles["feed-title"]}>
              {feed.title}
            </Typography.Title>
            <div className={styles["feed-url"]}>
              <Typography.Text copyable>{feed.url}</Typography.Text>
            </div>
          </div>
        ))}
      </div>
    </PageComponent>
  );
};

export default FeedPage;
