import { useState } from "react";
import { useTranslation } from "react-i18next";
import REQUESTS from "../../../api/requests";
import PageComponent from "../../../_components/page/PageComponent";
import { useCountriesOptions } from "../../../hooks/selectOptions";

import AdsPricingTable from "./AdsPricingTable";
import PricingDrawer from "./drawer/PricingDrawer";
import { notification } from "antd";

const AdsPricing = () => {
  const { t } = useTranslation();
  const countryOptions = useCountriesOptions("label", "iso_code");

  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [getAgain, setGetAgain] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleEditPackage = (key, pkg) => {
    setSelectedPackage(pkg);
    setDrawerVisible(true);
  };

  const handleAddPackage = () => {
    setSelectedPackage(null);
    setDrawerVisible(true);
  };

  const handleDrawerClose = () => {
    setDrawerVisible(false);
    setSelectedPackage(null);
  };

  const handleSave = (values, type) => {
    setLoading(true);
    if (type === "add") {
      try {
        REQUESTS.CUSTOM_ADS.PRICING.ADD(values)
          .then((res) => {
            setLoading(false);
            setSelectedPackage(null);
            setGetAgain((prev) => !prev);
            notification.success({
              message: t("success"),
              description: t("package_added_successfully"),
            });
          })
          .catch((err) => {
            setLoading(false);
            notification.error({
              message: t("error"),
              description: t("package_add_failed"),
            });
            console.log(err);
          });
      } catch (e) {
        console.log(e);
      }
    } else {
      try {
        REQUESTS.CUSTOM_ADS.PRICING.EDIT(values)
          .then((res) => {
            setSelectedPackage(null);
            setLoading(false);
            setGetAgain((prev) => !prev);
            notification.success({
              message: t("success"),
              description: t("package_updated_successfully"),
            });
          })
          .catch((err) => {
            setLoading(false);
            notification.error({
              message: t("error"),
              description: t("package_update_failed"),
            });
            console.log(err);
          });
      } catch (e) {
        console.log(e);
      }
    }
  };

  const onDelete = (id) => {
    setLoading(true);

    try {
      REQUESTS.CUSTOM_ADS.PRICING.DELETE(`${id}`)
        .then((res) => {
          setGetAgain((prev) => !prev);
          setLoading(false);
          notification.success({
            message: t("success"),
            description: t("package_deleted_successfully"),
          });
        })
        .catch((err) => {
          setLoading(false);
          notification.error({
            message: t("error"),
            description: t("package_delete_failed"),
          });
        });
    } catch (e) {
      console.log(e);
    }
  };

  const getDataSources = (query, success, error) => {
    setLoading(true);
    try {
      REQUESTS.CUSTOM_ADS.PRICING.GETTER({ query: JSON.stringify(query) })
        .then((res) => {
          setLoading(false);
          success(res.data);
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
          error(err);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const handleClick = (key, record) => {
    if (key === "edit") {
      handleEditPackage(key, record);
    } else if (key === "delete") {
      onDelete(record.id);
    }
  };

  const handleBulkAction = (key, data, response) => {
    switch (key) {
      case "delete":
        onDelete(data, "many");
        response(getAgain);
        break;
      default:
        break;
    }
  };

  return (
    <PageComponent routes={[t("custom_ads"), t("pricing")]}>
      <AdsPricingTable
        getDataSources={getDataSources}
        handleAddPackage={handleAddPackage}
        getAgain={getAgain}
        onDelete={onDelete}
        handleClick={handleClick}
        bulkAction={handleBulkAction}
        loading={loading}
      />
      <PricingDrawer
        visible={drawerVisible}
        onClose={handleDrawerClose}
        countryOptions={countryOptions}
        selectedPackage={selectedPackage}
        onSave={handleSave}
        loading={loading}
      />
    </PageComponent>
  );
};

export default AdsPricing;
