import { useState } from "react";
import { useTranslation } from "react-i18next";
import PageComponent from "../../../_components/page/PageComponent";
import CustomAdsTable from "./CustomAdsTable";
import REQUESTS from "../../../api/requests";
import { notification } from "antd";
import confirm from "antd/es/modal/confirm";

const CustomAdsPage = () => {
  const { t } = useTranslation();

  const [getAgain, setGetAgain] = useState(false);
  const [loading, setLoading] = useState(false);

  const getDataSource = (query, success, error) => {
    setLoading(true);
    try {
      REQUESTS.CUSTOM_ADS.ADS.GETTER({ query: JSON.stringify(query) })
        .then((response) => {
          setLoading(false);
          success(response.data);
        })
        .catch((e) => {
          setLoading(false);
          error(e);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const onDelete = (data, type) => {
    setLoading(true);
    try {
      let id = [];

      if (type == "many") {
        console.log("many", data);

        id = data.join(",");
      } else {
        id = data?.toString();
      }

      REQUESTS.CUSTOM_ADS.ADS.DELETE(id)
        .then((response) => {
          setLoading(false);
          notification.success({
            message: t("success"),
            description: t("deleted_successfully"),
          });
          setGetAgain((prev) => !prev);
        })
        .catch((e) => {
          setLoading(false);
          notification.error({
            message: t("error"),
            description: e.response.data.message,
          });
          console.log(e);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const onUpdate = (id, data) => {
    setLoading(true);
    try {
      REQUESTS.CUSTOM_ADS.ADS.UPDATE(id, data)
        .then((response) => {
          setLoading(false);
          notification.success({
            message: t("success"),
            description: t("updated_successfully"),
          });
          setGetAgain((prev) => !prev);
        })
        .catch((e) => {
          setLoading(false);
          notification.error({
            message: t("error"),
            description: e.response.data.message,
          });
        });
    } catch (e) {
      console.log(e);
    }
  };

  const handleMenuClick = ({ key }, record) => {
    console.log(key, record);

    switch (key) {
      case "edit":
        break;
      case "delete":
        onDelete(record.id, "one");
        break;
        // case "is_running":
        //   onUpdate(record.id, { is_running: !record.is_running });
        break;
      default:
        break;
    }
  };

  const handleBulkAction = (key, data, response) => {
    switch (key) {
      case "delete":
        confirm({
          title: t("delete"),
          content: t("delete_ads"),
          cancelText: t("cancel"),
          closable: true,
          type: "danger",
          onOk() {
            onDelete(data, "many");
            response(getAgain);
          },
          onCancel() {
            console.log("Cancel");
          },
        });
        break;
      default:
        break;
    }
  };

  return (
    <PageComponent routes={[t("custom_ads"), t("ads")]}>
      <CustomAdsTable
        getDataSource={getDataSource}
        handleMenuClick={handleMenuClick}
        bulkAction={handleBulkAction}
        getAgain={getAgain}
        loading={loading}
        onUpdate={onUpdate}
      />
    </PageComponent>
  );
};

export default CustomAdsPage;
