import IMAGES from "../../../../config/images";

import styles from "../_transcoding.module.scss";

export default function FileFolder({ item, onClick, selected }) {
    return (
        <div
            className={styles["file-folder"]}
            onClick={onClick}
            style={{
                borderColor:
                    selected?.is_file && selected?.path === item.path ? "#376cfb" : "transparent",
            }}
        >
            {item.is_file ? <img src={IMAGES.FILE} /> : <img src={IMAGES.OPEN_FOLDER} />}
            <p>{item.name}</p>
        </div>
    );
}
