import { useEffect, useState } from "react";
import { Area } from "@ant-design/plots";
import ChartComponent from "../../../../_components/chart/ChartComponent";
import REQUESTS from "../../../../api/requests";
import styles from "./chart.module.scss";
import { Empty } from "antd";
import { useTranslation } from "react-i18next";

export default function ExplicitCHart() {
  const { t } = useTranslation();

  const [isEmpty, setIsEmpty] = useState(false);

  const [selectedData, setSelectedData] = useState([
    {
      id: 0,
      name: t("all_time"),
      query: {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        day: new Date().getDate(),
        type: "year",
        value: -1,
      },
      active: true,
    },
    {
      id: 1,
      name: t("year"),
      query: {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        day: new Date().getDate(),
        type: "month",
        value: -1,
      },
      active: false,
    },
    {
      id: 2,
      name: t("month"),
      query: {
        year: new Date().getFullYear(),
        month: new Date().getMonth() + 1,
        day: new Date().getDate(),
        type: "day",
        value: -1,
      },
      active: false,
    },
  ]);

  const [perChannelData, setPerChannelData] = useState({
    data: [],
    xField: "date",
    yField: "value",
    label: {},
    point: {
      size: 5,
      shape: "circle",
      style: {
        fill: "white",
        stroke: "#5B8FF9",
        lineWidth: 2,
      },
    },
    tooltip: {
      showMarkers: false,
    },
    state: {
      active: {
        style: {
          shadowBlur: 4,
          stroke: "#000",
          fill: "l(270) 0:#ffffff 0.5:#7ec2f3 1:#1890ff",
        },
      },
    },
    interactions: [
      {
        type: "marker-active",
      },
    ],
  });

  const getPerChannel = (query) => {
    try {
      const params = JSON.stringify(query);
      REQUESTS.ADS.CHARTS.FULL_VIEW({ query: params })
        .then((response) => {
          if (!response.error) {
            setPerChannelData({
              ...perChannelData,
              data: response?.message?.data,
            });
          }
        })
        .catch((err) => {
          console.log(`err`, err);
        });
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    getPerChannel(selectedData[2].query);
  }, []);

  useEffect(() => {
    !perChannelData.data.length ? setIsEmpty(true) : setIsEmpty(false);
  }, [perChannelData]);

  const handleSelectTab = (value) => {
    const selectActive = selectedData.filter((item) => {
      if (item.name === value.name) {
        return {
          ...item,
          active: true,
        };
      } else {
        return {
          ...item,
          active: false,
        };
      }
    });

    setSelectedData(selectActive);
    getPerChannel(value.query);
  };

  return (
    <div className={styles["chart-wrapper"]}>
      <ChartComponent
        title={t("viewed_ads")}
        children={
          isEmpty ? <Empty style={{ height: 400 }} /> : <Area {...perChannelData} />
        }
        radioGroup={selectedData}
        selectedTab={(item) => handleSelectTab(item)}
      />
    </div>
  );
}
