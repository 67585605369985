import { Badge } from "antd";
import { useTranslation } from "react-i18next";

export default function Playing({ playing }) {
  const { t } = useTranslation();
  switch (playing.type) {
    case "live":
      return <Badge status="success" text={t("live")} />;
    case "timeshift":
      return <Badge status="warning" text={t("time_shift")} />;
    case "catchup":
      return (
        <>
          <Badge status="processing" text={t("catchup")} />
          <br />
          <span>{playing?.epg?.time}</span> <span> {playing?.epg?.title}</span>
        </>
      );
    default:
      return null;
  }
}
