import { Button } from "antd";
import icons from "../../../config/icons";
import styles from "../pricingPage.module.scss";
import { useTranslation } from "react-i18next";

const PricingCards = ({ item, getId }) => {
  const { t } = useTranslation();
  const {
    id,
    price_id,
    price,
    is_active,
    storage_limit,
    storage_size_in,
    traffic_limit,
    traffic_size_in,
    currency,
  } = item;

  const featuresList = [
    {
      name: t("unlimited_users"),
    },
    {
      name: t("apps_on_all_platforms"),
    },
    {
      name: t("in_app_branding"),
    },
    {
      name: "AVOD , SVOD, TVOD",
    },
    {
      name: t("management_panel"),
    },
    {
      name: t("unlimited_updates"),
    },
  ];

  const handleClick = () => {
    getId(id?.toString());
  };

  return (
    <div className={is_active ? styles["pricing-card-active"] : styles["pricing-card"]}>
      <div className={styles["currency"]}>
        <span className={styles["currency-value"]}>{`${currency.symbol} ${price}`}</span>{" "}
        / <span>{t("moth")}</span>
      </div>
      <div className={styles["traffic"]}>
        <div className={styles["bandwidth"]}>
          {`${traffic_limit} ${traffic_size_in}`}{" "}
          <span>
            {t("bandwidth")}/{t("moth")}
          </span>
        </div>
      </div>
      <div className={styles["storage-size"]}>
        {`${storage_limit} ${storage_size_in}`}
        <span>{" storage"}</span>
      </div>
      <div className={styles["features-list"]}>
        {featuresList.map((item, idx) => {
          return (
            <div className={styles["features-item"]} key={idx}>
              <span className={styles["features-icon"]}>{icons.CHECK_PRICE}</span>
              <span className={styles["features"]}>{item.name}</span>
            </div>
          );
        })}
      </div>
      <div className={styles["pricing-button-wrapper"]}>
        {!is_active && (
          <div
            className={
              !is_active ? styles["pricing-button"] : styles["pricing-button-disable"]
            }
            onClick={() => handleClick()}
          >
            {t("get_package")}
          </div>
        )}
      </div>
    </div>
  );
};

export default PricingCards;
