import styles from "./style.module.scss";
import { createContext, useEffect, useState } from "react";
import { useForm } from "antd/es/form/Form";
import { useTranslation } from "react-i18next";
import PageComponent from "../../../_components/page/PageComponent";
import { useNavigate, useSearchParams } from "react-router-dom";
import CollectionAdPage from "./components/pages/CollectionAdPage";
import VolumeAdPage from "./components/pages/VolumeAdPage";
import VideoBannerAdsPage from "./components/pages/VideoBannerAdsPAge";
import SplashScreenAdPage from "./components/pages/SplashScreenAdPage";
import PATHS from "../../../config/paths";
import REQUESTS from "../../../api/requests";
import { setEditableFormValues } from "./logic/setEditableForm";
import { notification } from "antd";
import Loading from "../../../_components/loadings/Loading";

export const AdsContext = createContext();

const AdsContentPage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [searchParam] = useSearchParams();

  const [form] = useForm();

  const type = searchParam.get("type");
  const [id] = searchParam.getAll("id");

  const [getAgain, setGetAgain] = useState(false);
  const [loading, setLoading] = useState(false);
  const [renderCurrentComponent, setRenderCurrentComponent] = useState(null);
  const [campaignId, setCampaignId] = useState("");
  const [editableData, setEditableData] = useState({});
  const [currentRoute, setCurrentRoute] = useState("");
  const [previewData, setPreviewData] = useState({
    previewLogo: "",
    previewBg: "",
    title: "Title",
    description: "Description",
  });

  useEffect(() => {
    if (type === "collection") {
      setRenderCurrentComponent(<CollectionAdPage form={form} loading={loading} />);
      setCurrentRoute(t("collection_ads"));
    } else if (type === "volume") {
      setRenderCurrentComponent(<VolumeAdPage form={form} loading={loading} />);
      setCurrentRoute(t("volume_ads"));
    } else if (type === "player_banner") {
      setRenderCurrentComponent(<VideoBannerAdsPage form={form} loading={loading} />);
      setCurrentRoute(t("video_banner_ads"));
    } else if (type === "splash_screen") {
      setRenderCurrentComponent(<SplashScreenAdPage form={form} loading={loading} />);
      setCurrentRoute(t("splash_screen_ads"));
    }
  }, [type, t]);

  const getAd = () => {
    try {
      REQUESTS.CUSTOM_ADS.ADS.GETTER({
        query: JSON.stringify({
          filter: {
            id: id,
          },
        }),
      })
        .then((res) => {
          setLoading(false);
          const data = res.data.rows;
          data.forEach((item) => {
            setEditableFormValues(item, form);
            setEditableData({
              ...item,
            });
            console.log(item);

            setPreviewData({
              previewLogo: item.logo_image,
              previewBg: item.bg_image,
              title: item.title,
              description: item.description,
              address: item.ad_address,
              phone: item.ad_phone,
              email: item.ad_email,
            });
          });
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } catch (error) {
      console.log(error);
    }
  };

  const onSave = (value) => {
    setLoading(true);

    const body = {
      ...value,
      campaign_id: editableData.campaign_id || campaignId,
      is_running: editableData.is_running || false,
      ad_type: id ? editableData.ad_type : type.split("_").join(" "),
      duration_in_seconds: editableData.duration_in_seconds || 0,
      skip_after_seconds: editableData.skip_after_seconds || 0,
      logo_image: previewData.previewLogo,
      bg_image: previewData.previewBg,
    };

    if (id) {
      try {
        REQUESTS.CUSTOM_ADS.ADS_CONTENT.UPDATE(body, id)
          .then((res) => {
            setLoading(false);
            notification.success({
              message: t("success"),
              description: "Ad has been updated successfully",
            });
            setGetAgain((prev) => !prev);
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      try {
        REQUESTS.CUSTOM_ADS.ADS_CONTENT.ADD(body)
          .then((res) => {
            setLoading(false);
            notification.success({
              message: t("success"),
              description: "Ad has been updated successfully",
            });
            console.log(res);
            if (res.data.id) {
              const searchParams = new URLSearchParams();

              searchParams.append("id", res.data.id);
              searchParams.append("type", res.data.ad_type);

              navigate(`${PATHS.CUSTOM_ADS_CONTENT}?${searchParams.toString()}`);
            }

            setGetAgain((prev) => !prev);
          })
          .catch((err) => {
            console.log(err);
          });
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (id) {
      getAd();
    }
  }, [id, getAgain]);

  return (
    <PageComponent routes={[t("custom_ads"), currentRoute]} backPath={PATHS.CUSTOM_ADS}>
      <div className={styles["ads-pages-wrapper"]}></div>
      <AdsContext.Provider
        value={{
          type,
          previewData,
          setPreviewData,
          setGetAgain,
          onSave,
          setCampaignId,
        }}
      >
        {renderCurrentComponent}
      </AdsContext.Provider>
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            zIndex: 100,
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, .6)",
          }}
        >
          <Loading
            size="large"
            style={{
              height: "100%",
            }}
          />
        </div>
      )}
    </PageComponent>
  );
};

export default AdsContentPage;
