import request from "../../../api/request";

import { getToken } from "../../../server/requests";

import { appHost } from "../../../helpers/appHost";

const host = appHost;

const URL = {
  USERS: `${host}admin/users`,

  BALANCE: `${host}payment/balance/`,

  SUBUSERS: `${host}admin/users/sub_users`,

  DEVICES: `${host}admin/users/devices`,

  REMOVE_DEVICE: `${host}admin/devices/remove`,

  MOST_WATCHED_CHANNELS: `${host}admin/users/reportChannel`,

  DOCUMENT: `${host}admin/users/document/add/${getToken()}`,

  DOCUMENT_DELETE: `${host}admin/users/document/delete`,

  PLAYLIST: `${host}admin/playlist/`,

  PLAYLISTS: `${host}admin/users/playlists`,

  PLAYLIST_ROWS: `${host}admin/playlist/rows/`,
};

const userApi = {
  GET: (query) => request("GET", URL.USERS, query),

  BALANCE: (type, query) => request("POST", `${URL.BALANCE}${type}`, query),

  MOST_WATCHED_CHANNELS: (query) => request("GET", URL.MOST_WATCHED_CHANNELS, query),

  DOCUMENT: {
    ADD: (query) => request("POST", URL.DOCUMENT, query),

    DELETE: (query) => request("POST", `${URL.DOCUMENT_DELETE}`, query),
  },

  SUBUSERS: {
    GET: (id, query) =>
      request("GET", `${URL.SUBUSERS}?id=${id}&token=${getToken()}`, query),

    EDIT: (query) => request("PUT", `${URL.SUBUSERS}`, query),

    DELETE: (query) => request("DELETE", `${URL.SUBUSERS}`, query),
  },

  DEVICES: {
    GET: (query) => request("GET", URL.DEVICES, query),

    DELETE: (query) => request("POST", URL.REMOVE_DEVICE, query),
  },

  PLAYLISTS: {
    GET: (query) => request("GET", URL.PLAYLISTS, query),

    ADD: (query) => request("POST", `${URL.PLAYLIST}add`, query),

    DELETE: (query) => request("POST", `${URL.PLAYLIST}remove`, query),

    DEFAULT: (query) => request("PUT", `${URL.PLAYLISTS}/default`, query),

    ROWS: {
      GET: (query) => request("POST", `${URL.PLAYLIST_ROWS}get`, query),

      EDIT: (query) => request("POST", `${URL.PLAYLIST_ROWS}update`, query),

      DELETE: (query) => request("POST", `${URL.PLAYLIST_ROWS}remove`, query),
    },
  },
};

export default userApi;
