import React from "react";
import backdropPng from "../../../../img/poster.png";
import notFoundPng from "../../../../img/movie.jpeg";

export default function Backdrop({ image, onChange }) {
    const imageError = (e) => (e.target.src = notFoundPng);

    const onChangeImage = (event) => {
        let input = event.target;

        let image = input.parentElement.childNodes[0];

        let reader = new FileReader();

        reader.onload = function () {
            let dataURL = reader.result;
            image.src = dataURL;
        };

        reader.readAsDataURL(input.files[0]);

        onChange(input.files[0]);
    };

    return (
        <div className="backdrop-style">
            {image ? (
                <img
                    alt="media-background"
                    className="backdrop-image"
                    onError={imageError}
                    src={image}
                />
            ) : (
                <img alt="media-background" className="backdrop-initial-image" src={backdropPng} />
            )}

            <input
                name="backdrop"
                id="bg-image"
                type="file"
                accept=".jpg,.png"
                className="image-input"
                onChange={onChangeImage}
            />

            {/* <div
                className="edit-media-background"
                onClick={() => document.getElementById("bg-image").click()}
            >
                <i className="fas fa-pencil-alt" />
            </div> */}
        </div>
    );
}
