import React from "react";
import devicesPng from "../../../../../img/devices.png";

import styles from "./_devices.module.scss";

export default function DeviceCard({ item }) {
  const returnImageWidth = () => {
    switch (item.name) {
      case "Android Mobile":
      case "Android Tv":
      case "Linux":
      case "WebOs":
        return 70;

      case "AppleTv":
        return 60;

      case "Tizen":
        return 80;

      default:
        return 50;
    }
  };

  return (
    <div className={styles["device-card"]}>
      <div className={styles["name"]}>
        <img
          src={item?.icon}
          style={{
            width: returnImageWidth(),
            height: 50,
            objectFit: "contain",
          }}
          alt="icon"
          onError={(e) => (e.target.src = devicesPng)}
        />

        <p> {item?.name}</p>
      </div>

      <div style={{ width: "20%" }}>
        <p className={styles["count"]}>{item?.count}</p>
      </div>

      {!item?.supported && item?.count == 0 && (
        <div className={styles["soon"]}>
          <span>Soon</span>
        </div>
      )}
    </div>
  );
}
