import { Tag } from "antd";
import { useEffect, useState } from "react";

import {
  getColumnSearchProps,
  getColumnDateProps,
  parseDate,
  getQueryDate,
} from "../../../../config/config";

import REQUESTS from "../../../../api/requests";

import TableComponent from "../../../../_components/table/TableComponent";

import styles from "../messages.module.scss";
import { useTranslation } from "react-i18next";

export default function HistoryTable({ id, getAgain }) {
  const { t } = useTranslation();

  const [dataSource, setDataSource] = useState([]);

  const [loading, setLoading] = useState(false);

  const [limit, setLimit] = useState(10);

  const [currentPage, setCurrentPage] = useState(1);

  const [sort, setSort] = useState(["id", "DESC"]);

  const [total, setTotal] = useState(0);

  const [date, setDate] = useState(null);

  const [search, setSearch] = useState({
    title: null,
    message: null,
  });

  const columns = [
    {
      title: "#",
      width: 60,
      dataIndex: `id`,
      align: "center",
      fixed: "left",

      key: "index",
      render: (record, text, index) => {
        return limit * (currentPage - 1) + index + 1;
      },
    },

    {
      title: t("status"),
      dataIndex: "is_read",
      key: "is_read",
      align: "center",
      width: 120,
      sorter: true,
      render: (text, record, index) => {
        return record.is_read ? (
          <Tag color="green">{t("read")}</Tag>
        ) : (
          <Tag color="volcano"> {t("unread")} </Tag>
        );
      },
    },

    {
      title: t("title"),
      dataIndex: "title",
      key: "title",
      align: "center",
      ellipsis: true,
      width: 200,

      ...getColumnSearchProps(),
    },

    {
      title: t("message"),
      dataIndex: "message",
      key: "message",
      align: "center",
      ellipsis: true,
      width: 200,
      ...getColumnSearchProps(),
    },

    {
      title: t("created_at"),
      dataIndex: "createdAt",
      key: "createdAt",
      align: "center",

      width: 120,

      render: (text, record, index) => {
        return parseDate(text);
      },

      ...getColumnDateProps(setDate),
    },
  ];

  const handleTableChange = (data) => {
    setCurrentPage(data.page);
    setLimit(data.limit);
    setSort(data.sort);

    setSearch(data.search);
  };

  const getData = () => {
    if (id) {
      setLoading(true);

      const query = {
        sort,
        limit,
        page: currentPage,
        device_id: id,
      };

      if (search.title) {
        if (query.search) {
          query.search = {
            ...query.search,
            title: search.title[0],
          };
        } else {
          query.search = {
            title: search.title[0],
          };
        }
      }

      if (search.message) {
        if (query.search) {
          query.search = {
            ...query.search,
            message: search.message[0],
          };
        } else {
          query.search = {
            message: search.message[0],
          };
        }
      }

      const queryDate = getQueryDate(date);

      if (queryDate) query.between = queryDate;

      REQUESTS.LAUNCHER.MESSAGES.GET({ query: JSON.stringify(query) })
        .then((response) => {
          setLoading(false);

          if (!response.error) {
            const data = response.message;
            if (data.rows?.length === 0 && currentPage > 1) {
              setCurrentPage((prev) => prev - 1);
              return;
            }

            setDataSource(data.rows);

            setTotal(data?.total);
            setLimit(data?.limit);
            setCurrentPage(data?.page);
          }
        })
        .catch(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      getData();
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, [limit, currentPage, sort, search, date, id, getAgain]);

  return (
    <div className={styles["history-table"]}>
      <h4> {t("history")} </h4>

      <TableComponent
        isLoading={loading}
        dataSource={dataSource}
        onChange={handleTableChange}
        columns={columns}
        rowClassName={(row) => {
          if (row.archive) return "red-row";
        }}
        notLocale={true}
        pagination={{
          page: currentPage,
          limit: limit,
          total: total,
        }}
        scroll={{
          x: 800,
        }}
      />
    </div>
  );
}
