const createLauncherAppFormData = ({ values, editable, icon }) => {
    const formData = new FormData();
    formData.append("id", editable.id);
    formData.append("name", values.name);
    formData.append("apk", values.apk);

    if (values.app_id) {
        formData.append("app_id", values.app_id);
    }

    if (values.min_version) {
        formData.append("min_version", values.min_version);
    }

    if (values.build_number) {
        formData.append("build_number", values.build_number);
    }

    if (values.version_string) {
        formData.append("version_string", values.version_string);
    }

    if (icon.file) {
        formData.append("icon", icon.file);
    }

    return formData;
};

export default createLauncherAppFormData;
