import { Select } from "antd";

import { useEffect, useState } from "react";

import REQUESTS from "../../api/requests";

export default function VodSearch({ disabled, onChange, multiple }) {
    const [search, setSearch] = useState("");

    const [list, setList] = useState([]);

    const onSearch = () => {
        let query = {
            limit: 50,
            enabled: true,
            search: { name: search },
        };

        REQUESTS.VOD.MEDIAS.GET({ query: JSON.stringify(query) })
            .then((response) => {
                console.log(response);
                if (response.message) {
                    const list = response.message.rows.map((item) => ({
                        value: item.id,
                        label: item.name,

                        ...item,
                    }));

                    setList(list);
                }
            })
            .catch((error) => {});
    };

    useEffect(() => {
        let timeout = setTimeout(() => {
            onSearch();
        }, [500]);

        return () => {
            clearTimeout(timeout);
        };
    }, [search]);

    return (
        <Select
            disabled={disabled}
            style={{ width: "100%" }}
            showSearch
            mode={multiple ? "multiple" : "default"}
            showArrow={false}
            autoClearSearchValue={false}
            placeholder="Search movie and select"
            optionFilterProp="children"
            filterOption={(input, option) =>
                (option?.label?.toLocaleLowerCase() ?? "").includes(input?.toLocaleLowerCase())
            }
            filterSort={(optionA, optionB) =>
                (optionA?.label ?? "")
                    .toLowerCase()
                    .localeCompare((optionB?.label ?? "").toLowerCase())
            }
            options={list}
            onSearch={setSearch}
            onChange={onChange}
        />
    );
}
