import React, { useEffect, useState } from "react";
import { Space, Typography, Table, notification } from "antd";
import { MenuOutlined } from "@ant-design/icons";

import { DndContext } from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

import icons from "../../../config/icons";

import TableImage from "../../../_components/table/TableImage";
import TableButtons from "../../../_components/table/TableButtons";
import REQUESTS from "../../../api/requests";
import { useTranslation } from "react-i18next";

const Row = ({ children, ...props }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props["data-row-key"],
  });
  const style = {
    ...props.style,
    transform: CSS.Transform?.toString(
      transform && {
        ...transform,
        scaleY: 1,
      }
    ),
    transition,
    ...(isDragging
      ? {
          position: "relative",
          zIndex: 9999,
        }
      : {}),
  };
  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children?.map(children, (child) => {
        if (child.key === "sort") {
          return React.cloneElement(child, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{
                  touchAction: "none",
                  cursor: "move",
                }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

export default function LauncherAppsTable({ getAgain, getDataSource, handleMenuClick }) {
  const { t } = useTranslation();

  const [dataSource, setDataSource] = useState([]);

  const [loading, setLoading] = useState(false);

  const [isUpdated, setIsUpdated] = useState(false);

  const columns = [
    {
      key: "sort",
      width: 60,
      fixed: "left",
    },
    {
      title: "#",
      width: 60,
      dataIndex: `id`,
      align: "center",
      key: "index",
      fixed: "left",
      render: (record, text, index) => {
        return index + 1;
      },
    },

    {
      title: t("name"),
      dataIndex: "name",
      key: "name",
      align: "center",
      width: 200,
      render: (text, record, index) => {
        return (
          <Space>
            <TableImage src={record.icon} />
            <span>{record.name}</span>
          </Space>
        );
      },
    },

    {
      title: t("app_id"),
      dataIndex: "app_id",
      key: "app_id",
      align: "center",
      render: (text, record, index) => (text ? text : "N/A"),
    },

    {
      title: t("apk"),
      dataIndex: "apk",
      key: "apk",
      align: "center",
      width: 230,
      render: (text, record, index) => {
        return text ? (
          <Typography.Paragraph
            ellipsis={true}
            style={{ margin: 0, width: 210 }}
            copyable={{
              tooltips: false,
            }}
          >
            {text}
          </Typography.Paragraph>
        ) : (
          "N/A"
        );
      },
    },

    {
      title: t("version"),
      dataIndex: "version_string",
      key: "version_string",
      align: "center",
      width: 120,
      render: (text, record, index) => (text ? text : "N/A"),
    },

    {
      title: t("min_version"),
      dataIndex: "min_version",
      key: "min_version",
      align: "center",
      width: 120,
      render: (text, record, index) => (text ? text : "N/A"),
    },

    {
      title: t("build_number"),
      dataIndex: "min_version",
      key: "build_number",
      align: "center",
      width: 120,
      render: (text, record, index) => (text ? text : "N/A"),
    },

    {
      title: "",
      key: "operation",
      fixed: "right",
      width: 60,
      align: "center",

      render: (text, record, index) => (
        <TableButtons
          handleMenuClick={(e) => handleMenuClick(e, record)}
          items={[
            {
              key: "edit",
              name: t("edit"),
              icon: icons.EDIT,
            },
          ]}
        />
      ),
    },
  ];

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setDataSource((previous) => {
        const activeIndex = previous.findIndex((i) => i.key === active.id);
        const overIndex = previous.findIndex((i) => i.key === over?.id);

        setIsUpdated(true);
        return arrayMove(previous, activeIndex, overIndex);
      });
    }
  };

  const updatePosition = () => {
    setLoading(true);
    const query = {
      position: JSON.stringify(dataSource.map((item) => item.id)),
    };

    REQUESTS.LAUNCHER.APPS.POSITION(query)
      .then((response) => {
        setLoading(false);
        if (!response.error) {
          notification.success({
            message: t("success"),
            description: response.message,
          });
        } else {
          notification.error({
            message: t("error"),
            description: response.message,
          });
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
        notification.error({
          message: t("error"),

          description: error.message,
        });
      });
  };

  useEffect(() => {
    setIsUpdated(false);
    setLoading(true);

    function onSuccess(data) {
      setLoading(false);
      let list = data?.map((item) => ({ ...item, key: item.id }));

      setDataSource(list);
    }

    function onError(err) {
      console.log(err, "err");
      setLoading(false);
    }

    getDataSource(onSuccess, onError);
  }, [getAgain]);

  useEffect(() => {
    if (dataSource.length > 0) {
      setLoading(false);
      if (isUpdated) {
        updatePosition();
      }
    }
  }, [dataSource]);

  return (
    <DndContext onDragEnd={onDragEnd} style={{ zIndex: 0 }}>
      <SortableContext
        items={dataSource?.map((i) => i.key)}
        strategy={verticalListSortingStrategy}
      >
        <Table
          components={
            dataSource?.length > 0 && {
              body: {
                row: Row,
              },
            }
          }
          columns={columns}
          dataSource={dataSource}
          loading={loading}
          bordered
          scroll={{ x: 1200 }}
          rowKey="key"
          pagination={false}
        />
      </SortableContext>
    </DndContext>
  );
}
