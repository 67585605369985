import { useContext } from "react";
import styles from "../style.module.scss";
import ButtonComponent from "../../../../_components/button/ButtonComponent";
import icons from "../../../../config/icons";
import { useTranslation } from "react-i18next";
import { Upload } from "antd";
import Upploader from "../../../../_components/uploader/Uploader";
import REQUESTS from "../../../../api/requests";
import { AdsContext } from "../AdsContentPage";

const AdsUpload = () => {
  const { setPreviewData, setGetAgain } = useContext(AdsContext);

  const { t } = useTranslation();

  const handleUploadLogo = (info) => {
    const formData = new FormData();

    formData.append("file", info.file.originFileObj);

    upload(formData, "logo_image");
  };

  const handleUploadBackGround = (info) => {
    console.log(info);
    const formData = new FormData();

    formData.append("file", info.file.originFileObj);

    upload(formData, "bg_image");
  };

  const upload = (formData, uploadFrom) => {
    try {
      REQUESTS.CUSTOM_ADS.UPLOAD.UPLOAD(formData)
        .then((res) => {
          if (uploadFrom === "logo_image") {
            setPreviewData((prev) => ({
              ...prev,
              previewLogo: res.data.path,
            }));
          } else if (uploadFrom === "bg_image") {
            setPreviewData((prev) => ({
              ...prev,
              previewBg: res.data.path,
            }));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles["ads-upload"]}>
      <Upploader
        type="logo"
        onChange={handleUploadLogo}
        title={t("uploads_logo")}
        accept="image/*"
      />
      <Upploader
        type="background"
        accept="image/*"
        onChange={handleUploadBackGround}
        title={t("uploads_background")}
      />
    </div>
  );
};

export default AdsUpload;
