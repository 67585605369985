import request from "../../../api/request";
import { DEMO_API_HOST } from "../../../config/statics";
import { appHost } from "../../../helpers/appHost";

const host = appHost;

const URL = {
  GROUPS: `${host}admin/users/groups`,
};

const usersGroupsApi = {
  GET: (query) => request("GET", URL.GROUPS, query),

  ADD: (query) => request("POST", URL.GROUPS, query),

  EDIT: (query) => request("PUT", URL.GROUPS, query),

  DELETE: (query) => request("DELETE", URL.GROUPS, query),
};

export default usersGroupsApi;
