import PATHS from "../../config/paths";

export default function defaultOpenKeys(pathname) {
    switch (pathname) {
        case PATHS.LIVE_TV_CATEGORIES:
        case PATHS.LIVE_TV:
            return "live-tv";

        case PATHS.QUIZ:
        case PATHS.LESSONS:
            return "lessons";

        case PATHS.USERS_GROUP:
        case PATHS.USERS:
            return "users";

        case PATHS.TVOD_PAYMENT:
        case PATHS.PAYMENT_HISTORY:
        case PATHS.PAYMENT_SUBSCRIPTION:
        case PATHS.PAYMENT_CONFIGS:
            return "payments";

        case PATHS.ADS:
            return "ads";

        case PATHS.LAUNCHER_APPS:
        case PATHS.LAUNCHER_MESSAGES:
        case PATHS.LAUNCHER_CONFIGS:
        case PATHS.LAUNCHER_DEVICES:
            return "launcher";

        case PATHS.APPLICATION_BASIC:
        case PATHS.APPLICATION_LANGUAGES:
        case PATHS.APPLICATION_USER_ICONS:
            return "application";

        case PATHS.WEB_FAQ:
        case PATHS.WEB_FEATURE:
        case PATHS.WEB_NEWS:
        case PATHS.WEB_PLATFORMS:
        case PATHS.WEB_SETTINGS:
        case PATHS.WEB_SLIDER:
        case PATHS.WEB_AVAILABLE_DEVICES:
            return "web-page";

        case PATHS.TRANSCODERS_GROUPS:
        case PATHS.TRANSCODERS_SERVERS:
        case PATHS.TRANSCODING:
        case PATHS.TRANSCODERS_RESOLUTIONS:
            return "transcoders";

        case PATHS.STREAM_SERVERS:
        case PATHS.STREAMS:
            return "streamer";

        case PATHS.CATCH_UP_SERVERS:
        case PATHS.CATCH_UP_RECORDINGS:
            return "catch-up";

        case PATHS.CONTENT_PROVIDER:
        case PATHS.MEDIA_TRANSCODING:
        case PATHS.MEDIAS:
        case PATHS.GENRES:
        case PATHS.CAST:
        case PATHS.COLLECTION:
            return "vod-manager";

        case PATHS.SETTINGS:
        case PATHS.WEB_HOOKS:
        case PATHS.CDN:
        case PATHS.DOCUMENTS:
        case PATHS.APKS:
        case PATHS.STORE_LINKS:
        case PATHS.SERVICE_NOTIFICATIONS:
        case PATHS.VERSIONS:
            return "settings";

        default:
            return "";
    }
}
