import { appHost } from "../helpers/appHost";

export let host = appHost;

export let socket_url = process.env.REACT_APP_SOCKET_HOST || "DEFAULT_REACT_SOCKET_HOST";

export let _host = host;

export const socket_host = socket_url;

export const actorForTmdbUrl = host + "test/tmdb/person/";

export const channelsUrl = host + "admin/channels/";
export const collectionUrl = host + "test/collection/";
export const collectionsUrl = host + "test/collections/";

export const deviceUrl = host + "admin/devices/";

export const emailUrl = host;
export const episodeUrl = host + "movie/episode";

export const playlistParseUrl = host + "/user/playlist/parse";

//TODO
export const streamUrl = host + "admin/streaming/channels/";
export const streamingServerUrl = host + "admin/streaming/server/";
export const soundUrl = host + "test/sound/";
export const soundsUrl = host + "test/sounds/";
export const soundtrackFromYoutubeUrl = host + "test/sounds/youtube/search?token=";
export const streamerUrl = host + "streamer/channels/";

export const tmdbUrl = host + "test/tmdb/";
export const transcoderGroupUrl = host + "admin/transcoder/groups/"; //REMOVE
export const transcoderServerUrl = host + "admin/transcoder/server/";
export const transcoderChannelUrl = host + "admin/transcoder/channels/";
export const transcodeChannelUrl = host + "transcoder/channels/";
export const transcoderLogsUrl = host + "admin/transcoder/channel_logs/";

export const usersUrl = host + "admin/users/";

const url = {
  // a
  admin: `${host}admin/`,
  archive: `${host}archive/`,
  // b

  brand_colors: `${host}v2/admin/app_color`,
  // c

  cast: `${host}movie/cast`,
  conversation: `${host}admin/conversation`,
  chat_message: `${host}admin/chat/message`,
  configs: `${host}admin/configs`,
  category: `${host}admin/category`,
  cast_fetcher: `${host}movie/cast/fetcher`,
  content_provider: `${host}admin/content_provider`,
  content_provider_login: `${host}content_provider/login`,
  content_provider_profile: `${host}content_provider/profile`,
  channels: `${host}admin/channels/`,
  counts_online: `${host}admin/users/counts/online`,
  currency: `${host}admin/currency`,
  cdn_settings: `${host}admin/cdn`,

  // d
  del_collection_movies: `${host}test/collection/movies`,
  // e
  epg: `${host}admin/epg/`,
  epgDate: `${host}admin/epg/getByDate`,

  // episodes_by_id: `${host}v2/admin/movies/episodes`,
  episodes_by_id: `${host}v2/admin/episode`,

  // f

  // g
  geolocation_api: `${host}admin/settings/geolocation_api`,

  goners_by_id: `${host}v2/admin/movies/genres`,

  // h
  hooks: `${host}admin/hooks/`,

  // i
  // j
  // k
  // l
  languages: `${host}application/languages`,
  // lessons: `${host}admin/lessons`,

  // m
  movie: `${host}movie`,
  movie_statistics: `${host}movie/statistics/`,
  movie_country: `${host}movie/country`,
  movie_fetcher: `${host}movie/fetcher`,

  movie_by_id: `${host}v2/admin/movies/by_id`,

  // n

  note: `${host}movie/not/translations`,

  notes_by_id: `${host}v2/admin/movies/nots`,

  // o
  // p
  payment_settings: `${host}admin/payment_settings`,
  payment: `${host}payment/`,
  permission_menu: `${host}admin/menu/permission`,

  // q
  quick_messages: `${host}admin/quick_messages`,

  // r
  reseller: `${host}reseller/`,
  resolution: `${host}admin/resolutions/`,

  ratting_by_id: `${host}v2/admin/movies/ratings`,

  // s
  sound: `${host}test/sound/`,
  soundtrack: `${host}test/sounds/`,
  settings: `${host}admin/settings/`,
  smtp_notifications: `${host}admin/notifications/smtp`,
  send_message: `${host}admin/chat/send/message`,

  season_by_id: `${host}v2/admin/movies/season`,

  // t
  tops: `${host}content_provider/get_tops`,

  tariff: `${host}admin/tariff/`,
  transcode_movie: `${host}movieTranscoder/transcode_movie`,
  transcode_episode: `${host}movieTranscoder/transcode_episode`,
  tvod: `${host}movie/pricing`,

  // u
  users: `${host}admin/users/`,

  // v
  // w
  widgets: `${host}admin/widgets`,
  watched_list: `${host}content_provider/watched_list`,

  // x
  // y
  // z
};

export default url;
