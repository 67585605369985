import { EllipsisOutlined } from "@ant-design/icons";
import { Dropdown, Menu, Button } from "antd";

// The TableButtons component is used to display a dropdown menu with buttons.
// From props we get the buttons that will be displayed in the dropdown menu and the function that will be
// called when a button is clicked. The function will receive the key of the button that was clicked.

export default function TableButtons({ handleMenuClick, buttons = [] }) {
    return (
        <Dropdown
            destroyPopupOnHide={true}
            trigger={["click"]}
            placement="bottomLeft"
            className="table-action-button"
            overlay={
                <Menu onClick={handleMenuClick} style={{ minWidth: 130 }}>
                    {buttons.map((item) => {
                        return (
                            <Menu.Item key={item.key} icon={item.icon}>
                                {item.text}
                            </Menu.Item>
                        );
                    })}
                </Menu>
            }
        >
            <Button icon={<EllipsisOutlined />} />
        </Dropdown>
    );
}
