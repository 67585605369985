import request from "../../../api/request";

import { DEMO_API_HOST } from "../../../config/statics";
import { appHost } from "../../../helpers/appHost";

const host = appHost;

const URL = {
  BASICS: `${host}v2/admin/basics`,
};

const basicInfoApi = {
  GET: (query) => request("GET", URL.BASICS, query),

  EDIT: (query) => request("PUT", URL.BASICS, query),
};

export default basicInfoApi;
