import dayjs from "dayjs";

export const setEditableFormValues = (data, form) => {
  // if (data.ads_campaign.start_date) {
  //   form.setFieldsValue({
  //     time_should: [
  //       dayjs(data.ads_campaign.start_date),
  //       dayjs(data.ads_campaign.end_date),
  //     ],
  //   });
  // }

  form.setFieldsValue(data);
};
