import { Button, Input, Space } from "antd";
import { useEffect, useState } from "react";
import { ColumnHeightOutlined, VerticalAlignMiddleOutlined } from "@ant-design/icons";

export default function PositionComponent({ position, onChangePosition }) {
    const [value, setValue] = useState("");

    useEffect(() => {
        let timeout = setTimeout(() => {
            setValue(position);
        }, 510);

        return () => {
            clearTimeout(timeout);
        };
    }, [position, onChangePosition]);

    return (
        <Space.Compact
            style={{
                width: 80,
            }}
        >
            <Input value={value} onChange={(e) => setValue(e.target.value)} />
            <Button
                icon={<VerticalAlignMiddleOutlined />}
                type="primary"
                onClick={(e) => onChangePosition(value)}
            ></Button>
        </Space.Compact>
    );
}
