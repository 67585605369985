import React from "react";
import MyPopup from "./MyPopup";

const titleStyle = {
  fontSize: 30,
  lineHeight: 3,
};

const subTitleStyle = {
  fontSize: 17,
  lineHeight: 3,
  color: "#a0a0a0",
  marginBottom: 20,
};

export default class QuestionPopup extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { onClose, type, title, subtitle, action } = this.props;

    let btn, icon;

    switch (type) {
      case "Delete":
        btn = "remove-button";
        icon = "fa fa-trash";
        break;

      case "Restart":
        btn = "restart-button";
        icon = "fas fa-redo";
        break;

      case "Logout":
        btn = "logout-button";
        icon = "fas fa-sign-out-alt logout-size";
        break;

      case "Start":
        btn = "start-button";
        icon = "fa fa-play-circle";
        break;

      case "Stop":
        btn = "stop-button";
        icon = "fas fa-pause-circle";
        break;

      case "Remove from order":
        btn = "stop-button";
        break;

      case "Unprotect":
        btn = "unprotect-button";
        icon = "fa fa-unlock";

        break;

      case "Protect":
        btn = "protect-button";
        icon = "fa fa-lock";

        break;

      default:
        break;
    }

    return (
      <MyPopup onClose={onClose}>
        <div className="question-popup-body">
          <p style={titleStyle}>{title}</p>

          <p style={subTitleStyle}>{subtitle}</p>
        </div>

        <button
          style={{ marginTop: 20 }}
          className={`popup-button-style ${btn}`}
          // disabled={this.state.loading}
          onClick={action}
        >
          <i className={icon} style={{ marginRight: 5 }} />
          {type}
        </button>
      </MyPopup>
    );
  }
}
