import { useNavigate } from "react-router";

import icons from "../../config/icons";

import styles from "./page-component.module.scss";

export default function PageComponent({ children, routes = [], backPath }) {
    const navigate = useNavigate();

    const onBack = () => {
        if (backPath) {
            navigate(backPath);
        }
    };

    return (
        <div className={styles["page"]}>
            <div className={styles["header"]}>
                {backPath && (
                    <div className={styles["back-button"]} onClick={onBack}>
                        {icons.BACK}
                    </div>
                )}
                {routes.length > 0 && (
                    <div className={styles["routes"]}>
                        {routes.map((item, index) => (
                            <span
                                key={index}
                                className={index === routes.length - 1 ? styles["active"] : ""}
                            >
                                {item} {index !== routes.length - 1 && "/"}{" "}
                            </span>
                        ))}
                    </div>
                )}
            </div>

            {children}
        </div>
    );
}
