import { Tabs } from "antd";
import { useEffect, useState } from "react";

import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";

import * as actions from "../../../redux-store/actions";

import REQUESTS from "../../../api/requests";

import configDashboardSettings from "../../../config/configDashboardSettings";

import ContentTab from "./ContentTab";
import NotificationTab from "./NotificationTab";
import PrimaryColorTab from "./PrimaryColorTab";
import AdminPanelLogoTab from "./AdminPanelLogo";
import GeolocationApiTab from "./GeolocationApiTab";
import AdminPanelFaviconTab from "./AdminPanelFaviconTab";
import ScheduledRestartTab from "./ScheduledRestartTab";

import PageComponent from "../../../_components/page/PageComponent";
import GeoRestrictedContent from "./GeoRestrictedContent";
import { useTranslation } from "react-i18next";

export default function GeneralSettings({}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { setDashboardSettings } = bindActionCreators(actions, dispatch);

  const [settings, setSettings] = useState([]);

  const getSettings = () => {
    try {
      REQUESTS.SETTINGS.GENERAL.GET()
        .then((response) => {
          if (!response.error) {
            const settingsList = response.message.filter((_) => _.key !== "notification");

            setSettings(settingsList);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {
      console.log(e);
    }
  };

  const getDashboardSettings = () => {
    REQUESTS.SETTINGS.GENERAL.GET().then((response) => {
      if (!response.error) {
        const dashboardSettins = configDashboardSettings(response.message);
        setDashboardSettings(dashboardSettins);
      }
    });
  };

  useEffect(() => {
    getSettings();
  }, []);

  const returnTab = (item) => {
    switch (item.key) {
      case "stream_restart":
        return <ScheduledRestartTab data={item} />;

      // case "notification":
      //   return <NotificationTab data={item} />;

      case "primary_color":
        return (
          <PrimaryColorTab data={item} getDashboardSettings={getDashboardSettings} />
        );

      case "admin_panel_logo":
        return (
          <AdminPanelLogoTab data={item} getDashboardSettings={getDashboardSettings} />
        );

      case "admin_panel_favicon":
        return (
          <AdminPanelFaviconTab data={item} getDashboardSettings={getDashboardSettings} />
        );

      case "geolocation_api":
        return <GeolocationApiTab data={item} />;

      case "show_content_type":
        return <ContentTab data={item} />;

      case "location_restriction_type":
        return <GeoRestrictedContent data={item} />;

        return;
    }
  };

  return (
    <PageComponent routes={[t("settings"), t("general_settings")]}>
      <Tabs
        tabPosition="left"
        tabBarStyle={{ background: "white" }}
        items={settings.map((_, i) => {
          const name = _?.title?.trim()?.toLowerCase()?.split(" ")?.join("_");
          return {
            label: t(name),
            key: _.key,
            children: returnTab(_),
          };
        })}
      />
    </PageComponent>
  );
}
