import { useEffect, useState } from "react";
import { Divider, ColorPicker } from "antd";
import ButtonComponent from "../../../_components/button/ButtonComponent";
import icons from "../../../config/icons";
import { useTranslation } from "react-i18next";

const AppColors = ({ colors, handleColorChange }) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(false);

  const [primary, setPrimary] = useState("#1890ff");

  const [secondary, setSecondary] = useState("#1890ff");

  const [accent, setAccent] = useState("#1890ff");

  const [card, setCard] = useState("#1890ff");

  const [background, setBackground] = useState("#1890ff");

  const colorsList = [
    {
      title: t("primary"),
      value: primary,
      onChange: (e) => {
        setPrimary(e.toHexString());
      },
    },

    {
      title: t("secondary"),
      value: secondary,
      onChange: (e) => {
        setSecondary(e.toHexString());
      },
    },

    {
      title: t("accent"),
      value: accent,
      onChange: (e) => {
        setAccent(e.toHexString());
      },
    },

    {
      title: t("card"),
      value: card,
      onChange: (e) => {
        setCard(e.toHexString());
      },
    },

    {
      title: t("background"),
      value: background,
      onChange: (e) => {
        setBackground(e.toHexString());
      },
    },
  ];

  const handleClick = () => {
    setIsLoading(true);
    const params = {
      primary_color: primary,
      secondary_color: secondary,
      accent_color: accent,
      card_color: card,
      background_color: background,
    };
    handleColorChange(params);
  };

  useEffect(() => {
    if (colors) {
      setIsLoading(false);
      const {
        accent_color,
        background_color,
        card_color,
        primary_color,
        secondary_color,
      } = colors;
      setAccent(accent_color || "");
      setBackground(background_color || "");
      setCard(card_color || "");
      setPrimary(primary_color || "");
      setSecondary(secondary_color || "");
    }
  }, [colors]);

  return (
    <div>
      <Divider>{t("setup_colors_for_application")}</Divider>
      {colorsList.map((item) => {
        return (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              gap: 15,
              marginTop: 10,
            }}
          >
            <p style={{ textAlign: "start" }}>{item.title}</p>

            <ColorPicker
              value={item?.value}
              onChangeComplete={item?.onChange}
              disabledAlpha
            />
          </div>
        );
      })}
      <div
        style={{
          width: "100%",
          marginTop: 20,
        }}
      >
        <ButtonComponent
          title={t("save")}
          style={{ display: "flex", margin: "0 auto", alignItems: "center" }}
          icon={icons.CHECK}
          type="primary"
          loading={isLoading}
          onClick={handleClick}
        />
      </div>
    </div>
  );
};

export default AppColors;
