import { useEffect, useState } from "react";

import { Button, Drawer, Form, Input, notification } from "antd";
import REQUESTS from "../../../api/requests";
import ImageUpload from "../../../components/ImageUpload";
import { useTranslation } from "react-i18next";

export default function NewsDrawer({ onClose, open, current, getData, getAgain }) {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);

  const [form] = Form.useForm();

  const [image, setImage] = useState({
    url: null,
    file: null,
  });

  const onFinish = (values) => {
    setIsLoading(true);

    const formData = new FormData();
    if (image.file) {
      formData.append("image", image.file);
    }
    formData.append("title", values.title);
    formData.append("body", values.body);

    if (current) {
      formData.append("id", current.id);

      REQUESTS.WEB_PAGE.NEWS.EDIT(formData)
        .then((response) => {
          setIsLoading(false);

          if (response.error) {
            notification.error({
              message: t("error"),
              description: response.message,
            });

            return;
          }
          getData();
          getAgain();
          onClose();
        })
        .catch((err) => {
          setIsLoading(false);
        });
    } else {
      REQUESTS.WEB_PAGE.NEWS.ADD(formData)
        .then((response) => {
          setIsLoading(false);

          if (response.error) {
            notification.error({
              message: t("error"),

              description: response.message,
            });

            return;
          } else {
            notification.success({
              message: t("success"),
              description: t("news_add_success"),
            });
            getAgain();
            getData();
            onClose();
          }
        })
        .catch((err) => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    if (!open) {
      form.resetFields();
      setImage({
        url: null,
        file: null,
      });
    }

    if (current) {
      form.setFields([
        { name: "title", value: current.title },
        { name: "body", value: current.body },
      ]);
      setImage({
        url: current?.image,
        file: null,
      });
    }
  }, [open, current]);

  const onChangeImage = (value) => {
    setImage(value);

    form.setFieldsValue({
      image: value.file,
    });
  };

  return (
    <Drawer
      title={`${current ? t("edit") : t("add")} ${t("news")}`}
      placement="right"
      onClose={onClose}
      open={open}
    >
      <Form form={form} name="news" layout="vertical" onFinish={onFinish}>
        <Form.Item
          label={t("image")}
          name="image"
          rules={[
            {
              required: current ? false : true,
              message: t("image_message"),
            },
          ]}
        >
          <ImageUpload image={image} setImage={onChangeImage} />
        </Form.Item>

        <Form.Item
          label={t("title")}
          name="title"
          rules={[
            {
              required: true,
              message: t("title_message"),
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label={t("description")}
          name="body"
          rules={[
            {
              required: true,
              message: t("description_message"),
            },
          ]}
        >
          <Input.TextArea rows={6} />
        </Form.Item>

        <Form.Item style={{ textAlign: "center" }}>
          <Button type="primary" onClick={() => form.submit()} loading={isLoading}>
            {t("save")}
          </Button>
        </Form.Item>
      </Form>
    </Drawer>
  );
}
