import { useEffect, useState } from "react";

import { Form, notification, Switch } from "antd";

import REQUESTS from "../../../../api/requests";

import ImageUpload from "../../../../components/ImageUpload";

import ButtonComponent from "../../../../_components/button/ButtonComponent";
import { useTranslation } from "react-i18next";

export default function AppleConfig({ data, selectedCurrency }) {
  const [t] = useTranslation();

  const [form] = Form.useForm();

  const [isLoading, setIsLoading] = useState(false);

  const [icon, setIcon] = useState({
    url: null,
    file: null,
  });

  const onFinish = (values) => {
    setIsLoading(true);

    const formData = new FormData();

    if (icon.file) {
      formData.append("apple_icon", icon.file);
    }

    formData.append("id", data.id);
    formData.append("currency_id", selectedCurrency);
    formData.append("apple_enabled", values.apple_enabled);

    REQUESTS.PAYMENTS.CONFIGS.UPDATE(formData)
      .then((response) => {
        setIsLoading(false);

        if (response.error) {
          notification.error({
            message: t("error"),
            description: response.message,
          });

          return;
        }

        notification.success({
          message: t("success"),
          description: t("apple_pay_config_change_message"),
        });
      })
      .catch((error) => {
        setIsLoading(false);
        notification.error({
          message: t("error"),

          description: error,
        });
      });
  };

  const onChangeIcon = (value) => {
    setIcon(value);

    form.setFieldsValue({
      apple_icon: value.file,
    });
  };

  useEffect(() => {
    if (data) {
      setIcon({
        url: data.apple_icon,
        file: null,
      });

      form.setFields([
        { name: "apple_icon", value: data.apple_icon },
        { name: "apple_enabled", value: data.apple_enabled },
      ]);
    }
  }, [data]);

  return (
    <Form
      form={form}
      name="apple-pay-config"
      layout="vertical"
      onFinish={onFinish}
      style={{ maxWidth: 350 }}
    >
      <Form.Item label={t("icon")} name="apple_icon">
        <ImageUpload image={icon} setImage={onChangeIcon} />
      </Form.Item>

      <Form.Item label={t("enabled")} name="apple_enabled" valuePropName="checked">
        <Switch />
      </Form.Item>

      <ButtonComponent
        title={t("save")}
        actionType="save"
        isLoading={isLoading}
        onClick={() => form.submit()}
      />
    </Form>
  );
}
