import request from "../../../../api/request";
import { DEMO_API_HOST } from "../../../../config/statics";
import { appHost } from "../../../../helpers/appHost";

const host = appHost;

const URL = {
  CDN: `${host}v2/admin/cdn`,
  CDN_OTHERS: `${host}admin/cdn`,
};

const cdnAPi = {
  GET: (type) => request("GET", `${URL.CDN}?type=${type}`),

  GET_OTHERS: () => request("GET", URL.CDN_OTHERS),

  UPDATE_OTHERS: (query) => request("PUT", URL.CDN_OTHERS, query),

  UPDATE: (query) => request("POST", `${URL.CDN}`, query),

  SAVE: (query) => request("POST", `${URL.CDN}/save`, query),
};

export default cdnAPi;
