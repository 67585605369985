import { useEffect, useState } from "react";
import TableComponent from "../../../../../_components/table/TableComponent";

const EpgTable = ({ data }) => {
  const [tableConfigs, setTableConfigs] = useState([]);

  useEffect(() => {
    if (data?.data) {
      const configs = data.data.map((table, index) => {
        const columns = Object.keys(table).map((key) => ({
          title: key.replace(/_/g, " ").replace(/\b\w/g, (c) => c.toUpperCase()),
          dataIndex: key,
          key: key,
          align: "center",
        }));

        const dataSource = data.data.map((row, rowIndex) => ({
          ...Object.fromEntries(
            Object.keys(table).map((key) => [key, row[key] ?? "N/A"])
          ),
          key: `${index}-${rowIndex}`,
        }));

        return { name: table.name, columns, dataSource };
      });

      setTableConfigs(configs);
    }
  }, [data]);

  return (
    <div>
      {tableConfigs.map((config, index) => (
        <div key={index} style={{ marginBottom: "2rem" }}>
          <TableComponent columns={config.columns} dataSource={config.dataSource} />
        </div>
      ))}
    </div>
  );
};

export default EpgTable;
