import request from "../../../api/request";

import { appHost } from "../../../helpers/appHost";

const host = appHost;

const URL = {
  PLATFORMS: `${host}admin/platform`,
};

const webPlatformsApi = {
  GET: (query) => request("GET", URL.PLATFORMS, query),

  EDIT: (query) => request("PUT", URL.PLATFORMS, query),
};

export default webPlatformsApi;
