import { useEffect, useState } from "react";

import { Divider, Form, Space } from "antd";

import ImageUpload from "../../../../../components/ImageUpload";
import NextBackButton from "../../../../../_components/button/NextBackButton";
import { useTranslation } from "react-i18next";

const resolutionLabel = (label) => {
  return (
    <div style={{ textAlign: "center", color: "gray" }}>
      <span>Upload</span>
      <br />
      <span style={{ fontSize: 15 }}>{label}</span>
    </div>
  );
};

export default function GalleryStep({
  form,
  onChangeStep,
  editable,
  getAgain,
  onSelectTmdb,
}) {
  const { t } = useTranslation();
  const [poster, setPoster] = useState({
    url: null,
    file: null,
    status: "",
  });

  const [backdrop, setBackdrop] = useState({
    url: null,
    file: null,
    status: "",
  });

  const [imageTitle, setImageTitle] = useState({
    url: null,
    file: null,
    status: "",
  });

  const [mobileHighlight, setMobileHighlight] = useState({
    url: null,
    file: null,
    status: "",
  });

  const [tvHighlight, setTvHighlight] = useState({
    url: null,
    file: null,
    status: "",
  });

  const [cover, setCover] = useState({
    url: null,
    file: null,
    status: "",
  });

  useEffect(() => {
    if (poster?.file) {
      form.setFields([{ name: "poster", value: poster?.file }]);
    }
  }, [poster]);

  useEffect(() => {
    if (backdrop?.file) {
      form.setFields([{ name: "backdrop", value: backdrop?.file }]);
    }
  }, [backdrop]);

  useEffect(() => {
    if (imageTitle?.file) {
      form.setFields([{ name: "image_title", value: imageTitle?.file }]);
    }
  }, [imageTitle]);

  useEffect(() => {
    if (tvHighlight?.file) {
      form.setFields([{ name: "highlight_tv", value: tvHighlight?.file }]);
    }
  }, [tvHighlight]);

  useEffect(() => {
    if (cover?.file) {
      form.setFields([{ name: "poster_tv", value: cover?.file }]);
    }
  }, [cover]);

  useEffect(() => {
    if (mobileHighlight?.file) {
      form.setFields([{ name: "highlight_mobile", value: mobileHighlight?.file }]);
    }
  }, [mobileHighlight]);

  useEffect(() => {
    if (editable) {
      setPoster({
        url: editable?.poster,
        file: null,
      });

      setBackdrop({
        url: editable?.backdrop,
        file: null,
      });

      setImageTitle({
        url: editable?.image_title,
        file: null,
      });

      setTvHighlight({
        url: editable?.highlight_tv,
        file: null,
      });

      setCover({
        url: editable?.poster_tv,
        file: null,
      });

      setMobileHighlight({
        url: editable?.highlight_mobile,
        file: null,
      });
    }
  }, [editable, getAgain]);

  useEffect(() => {
    if (onSelectTmdb) {
      const posterUrl = form?.getFieldValue("poster");
      const backdropUrl = form?.getFieldValue("backdrop");
      const imageTitleUrl = form?.getFieldValue("image_title");

      setPoster({
        file: null,
        url: posterUrl,
      });

      setBackdrop({
        file: null,
        url: backdropUrl,
      });

      setImageTitle({
        file: null,
        url: imageTitleUrl,
      });

      form.setFields([
        { name: "poster", value: posterUrl },
        { name: "backdrop", value: backdropUrl },
        { name: "image_title", value: imageTitleUrl },
      ]);
    }
  }, [onSelectTmdb]);

  useEffect(() => {
    if (!imageTitle?.file) {
      form.setFields([{ name: "image_title", value: undefined }]);
    }

    if (!mobileHighlight?.file) {
      form.setFields([{ name: "highlight_mobile", value: undefined }]);
    }

    if (!tvHighlight?.file) {
      form.setFields([{ name: "highlight_tv", value: undefined }]);
    }

    if (!cover?.file) {
      form.setFields([{ name: "poster_tv", value: undefined }]);
    }
  }, [getAgain, setImageTitle, setMobileHighlight, setTvHighlight, setCover]);

  const onChangeImageTitle = (image) => {
    if (image?.file) {
      form.setFields([{ name: "image_title", value: image?.file }]);
    } else {
      form.setFields([{ name: "image_title", value: "removed" }]);
    }

    setImageTitle(image);
  };

  const onChangeImageBackdrop = (image) => {
    if (image?.file) {
      form.setFields([{ name: "backdrop", value: image?.file }]);
    } else {
      form.setFields([{ name: "backdrop", value: "removed" }]);
    }

    setBackdrop(image);
  };

  const onChangeImagePoster = (image) => {
    if (image?.file) {
      form.setFields([{ name: "poster", value: image?.file }]);
    } else {
      form.setFields([{ name: "poster", value: "removed" }]);
    }

    setPoster(image);
  };

  const onChangeImageCover = (image) => {
    if (image?.file) {
      form.setFields([{ name: "poster_tv", value: image?.file }]);
    } else {
      form.setFields([{ name: "poster_tv", value: "removed" }]);
    }

    setCover(image);
  };

  const onChangeImageMobileHighlight = (image) => {
    if (image?.file) {
      form.setFields([{ name: "highlight_mobile", value: image?.file }]);
    } else {
      form.setFields([{ name: "highlight_mobile", value: "removed" }]);
    }

    setMobileHighlight(image);
  };

  const onChangeImageTvHighlight = (image) => {
    if (image?.file) {
      form.setFields([{ name: "highlight_tv", value: image?.file }]);
    } else {
      form.setFields([{ name: "highlight_tv", value: "removed" }]);
    }

    setTvHighlight(image);
  };

  return (
    <div>
      <Divider orientation="left" plain>
        <b>{t("galleries")}</b>
      </Divider>

      <div style={{ display: "flex", gap: 20 }}>
        <div>
          <Form.Item
            label={t("poster")}
            name="poster"
            rules={[
              {
                required: true,
                message: t("choose_poster_message"),
              },
            ]}
          >
            <ImageUpload
              label={resolutionLabel("1080 x 1920")}
              image={poster}
              setImage={onChangeImagePoster}
              style={{ width: 150, height: 180 }}
            />
          </Form.Item>

          <Form.Item label={t("mobile_highlight")} name="highlight_mobile">
            <ImageUpload
              label={resolutionLabel("1920 x 1080")}
              image={mobileHighlight}
              setImage={onChangeImageMobileHighlight}
              style={{ width: 150, height: 150 }}
            />
          </Form.Item>
        </div>

        <div>
          <Form.Item label={t("image_title")} name="image_title">
            <ImageUpload
              label={resolutionLabel("950 x 250")}
              image={imageTitle}
              setImage={onChangeImageTitle}
              style={{ width: 590, height: 180 }}
            />
          </Form.Item>

          <div style={{ display: "flex", gap: 10 }}>
            <Form.Item
              label={t("backdrop")}
              name="backdrop"
              rules={[
                {
                  required: true,
                  message: t("backdrop_message"),
                },
              ]}
            >
              <ImageUpload
                label={resolutionLabel("1920 x 1080")}
                image={backdrop}
                setImage={onChangeImageBackdrop}
                style={{ width: 190, height: 150 }}
              />
            </Form.Item>

            <Form.Item label={t("tv_highlight")} name="highlight_tv">
              <ImageUpload
                label={resolutionLabel("1920 x 1080")}
                image={tvHighlight}
                setImage={onChangeImageTvHighlight}
                style={{ width: 190, height: 150 }}
              />
            </Form.Item>

            <Form.Item label={t("cover")} name="poster_tv">
              <ImageUpload
                label={resolutionLabel("1920 x 1080")}
                image={cover}
                setImage={onChangeImageCover}
                style={{ width: 190, height: 150 }}
              />
            </Form.Item>
          </div>
        </div>
      </div>
      <Divider />

      <div style={{ display: "flex", justifyContent: "space-between", marginBottom: 20 }}>
        <NextBackButton type="back" onClick={() => onChangeStep("back")} />
        <NextBackButton type="next" onClick={() => onChangeStep("next")} />
      </div>
    </div>
  );
}
